angular.module('AccountSettingsService', [])
.factory('AccountSettings', ['$http', function($http){

	var referrals = [
		{
			name: 'Hedgeco.net',
			value: 'hedgeco.net'
		},{
			name: 'Search Engine',
			value: 'search-engine'
		},{
			name: 'Magazine',
			value: 'magazine'
		},{
			name: 'Social Media',
			value: 'social-media'
		},{
			name: 'Other',
			value: 'other'
		},
	];

	return {
		//General
		referrals  : referrals,
	};

}]);
