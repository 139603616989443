angular.module('HomeCtrl')
.controller("NotificationsCtrl", function($scope, $http, Dashboard) {

    $scope.notifications = [
        {
            date : '06/17/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $100,000 to Pawley\'s Growth 2x Leverage'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files recieved by Interactive Brokers - system trading restored'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files delayed by Interactive Brokers - system trading delayed'
        },
        {
            date : '01/21/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $30,000 to SMArtX Long/Short 500'
        },
        {
            date : '01/01/2016',
            account : '51576234',
            type : 'Model',
            event : 'Pawley\'s Growth trading paused'
        },
        {
            date : '12/18/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $100,000 to RAIF 1x Leverage'
        },
        {
            date : '12/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Added $250,000 to account via ACAT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $50,000 to Pawley\'s 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Allocation',
            event : 'Allocated $40,000 to Cognios at 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Added $100,000 to account via EFT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Open Account at Schwab'
        },
        {
            date : '06/17/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $100,000 to Pawley\'s Growth 2x Leverage'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files recieved by Interactive Brokers - system trading restored'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files delayed by Interactive Brokers - system trading delayed'
        },
        {
            date : '01/21/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $30,000 to SMArtX Long/Short 500'
        },
        {
            date : '01/01/2016',
            account : '51576234',
            type : 'Model',
            event : 'Pawley\'s Growth trading paused'
        },
        {
            date : '12/18/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $100,000 to RAIF 1x Leverage'
        },
        {
            date : '12/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Added $250,000 to account via ACAT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $50,000 to Pawley\'s 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Allocation',
            event : 'Allocated $40,000 to Cognios at 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Added $100,000 to account via EFT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Open Account at Schwab'
        },
        {
            date : '06/17/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $100,000 to Pawley\'s Growth 2x Leverage'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files recieved by Interactive Brokers - system trading restored'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files delayed by Interactive Brokers - system trading delayed'
        },
        {
            date : '01/21/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $30,000 to SMArtX Long/Short 500'
        },
        {
            date : '01/01/2016',
            account : '51576234',
            type : 'Model',
            event : 'Pawley\'s Growth trading paused'
        },
        {
            date : '12/18/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $100,000 to RAIF 1x Leverage'
        },
        {
            date : '12/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Added $250,000 to account via ACAT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $50,000 to Pawley\'s 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Allocation',
            event : 'Allocated $40,000 to Cognios at 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Added $100,000 to account via EFT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Open Account at Schwab'
        },
        {
            date : '06/17/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $100,000 to Pawley\'s Growth 2x Leverage'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files recieved by Interactive Brokers - system trading restored'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files delayed by Interactive Brokers - system trading delayed'
        },
        {
            date : '01/21/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $30,000 to SMArtX Long/Short 500'
        },
        {
            date : '01/01/2016',
            account : '51576234',
            type : 'Model',
            event : 'Pawley\'s Growth trading paused'
        },
        {
            date : '12/18/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $100,000 to RAIF 1x Leverage'
        },
        {
            date : '12/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Added $250,000 to account via ACAT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $50,000 to Pawley\'s 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Allocation',
            event : 'Allocated $40,000 to Cognios at 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Added $100,000 to account via EFT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Open Account at Schwab'
        },
        {
            date : '06/17/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $100,000 to Pawley\'s Growth 2x Leverage'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files recieved by Interactive Brokers - system trading restored'
        },
        {
            date : '02/03/2016',
            account : '51576234',
            type : 'System',
            event : 'Files delayed by Interactive Brokers - system trading delayed'
        },
        {
            date : '01/21/2016',
            account : '51576234',
            type : 'Account',
            event : 'Allocated $30,000 to SMArtX Long/Short 500'
        },
        {
            date : '01/01/2016',
            account : '51576234',
            type : 'Model',
            event : 'Pawley\'s Growth trading paused'
        },
        {
            date : '12/18/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $100,000 to RAIF 1x Leverage'
        },
        {
            date : '12/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Added $250,000 to account via ACAT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'System',
            event : 'Allocated $50,000 to Pawley\'s 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Allocation',
            event : 'Allocated $40,000 to Cognios at 1x Leverage'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Added $100,000 to account via EFT'
        },
        {
            date : '11/15/2015',
            account : '51576234',
            type : 'Account',
            event : 'Open Account at Schwab'
        }
    ];

    $scope.paginate = function (value) {  
        var begin, end, index;  
        begin = ($scope.currentPage - 1) * $scope.numPerPage;  
        end = begin + $scope.numPerPage;  
        index = $scope.notifications.indexOf(value);  
        return (begin <= index && index < end);  
    };



    function init(){

        console.log('Init NotificationsCtrl');
        $scope.totalItems = $scope.notifications.length;
        $scope.numPerPage = 10;
        $scope.currentPage = 1;
        Dashboard.getNotifications()
        .then(function(res){
            $scope.notifications = res.data.payload.notifications;
            debugger
        });
    }

    // set up initial values and get some data
    init();

});
