angular
  .module('BillingModule')
  .controller('NonBillableAccountsCtrl', function($scope, $q, NgTableParams, Dashboard, Billing, SmartXFactory, Tabs){
    var vm = this;
    var accountApi = SmartXFactory.getAccountAPI();

    vm.openAccount = openAccount;
    vm.refresh = init;

    init();

    function init () {

      // if table has already been initialized, reload it to refresh the data
      // this will trigger a call of the table's 'getData' method
      if (vm.nonBillableAccountsTable){
        return vm.nonBillableAccountsTable.reload();
      }

      vm.nonBillableAccountsTable = new NgTableParams({
        page: 1,
        count: 25,
        sorting: {
          nonBillableAum: 'desc'
        },
        filter: {
          /* brokerageId: '',
          targetId: initialFilter.targetId ? initialFilter.targetId : '',
          enterpriseId: '',
          permission: initialFilter.permission ? initialFilter.permission : '' */
        }
      }, {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
            
          var query = {
            pageNumber: page,
            pageSize: size,
            sortKey: sort,
            sortDescending: direction === 'desc' ? true : false,
            // billingPeriod: "quarterly"
          };

          _.extend(query, filter);

          console.log("Search Params: ", query);

          vm.loading = true;
          vm.accountsQuery = query;

          return $q.when(Billing.getAllNonBillableAccounts(query))
                  .then(function(res) {
                    params.total(res.data.metaData.totalRecordCount);
                    $scope.totalValue = res.data.metaData.totalValue;
                    // var brokersList = res.metaData.brokerages;
                    // var enterprisesList = res.metaData.enterprises;
  
                    return formatAccounts(res.data);
                  })
                  .catch(function(err) {
                    // toastr.error(err.message);
                    Dashboard.toastError(err.message, err);
                    $q.reject();
                  })
                  .finally(function(){
                    vm.loading = false;
                  });
        },
      });
    }

    function formatAccounts (nonBillableAccounts) {
      var formattedAccounts = nonBillableAccounts.data.map(function(account){
        return account;
      });

      return formattedAccounts;
    }

    function refresh () {
      init(true);
    }

    function openAccount(account) {

			account.fetchingDetails = true;
			$q.when(accountApi.getAccountById(account.accountId))
			.then(function(res){
				Tabs.addTab('main', account.accountId, null, res.data);
			})
			.catch(function(err){
				Dashboard.toastError("Failed to load account details", err);
			})
			.finally(function(){
				account.fetchingDetails = false;
			});
		}
  })
	.controller('BillingHomeCtrl', function($q, SmartXFactory, $scope, Dashboard, Billing, FeatureFlags){

		var vm = this;
		var parentTab = $scope.tab;
    var totalAccountsCount = Dashboard.getTotalAccountsCount();

    vm.isEnabled = FeatureFlags.isEnabled;

		vm.viewOptions = {
			selected: 'overview',
      list: [
        {
          id: 'overview',
          name: 'Overview'
        },
        {
          id: 'blotter',
          name: 'Fee Review'
        },
        // {
        //   id: 'setup',
        //   name: 'Setup'
        // },
        // {
        //   id: 'feeSchedules',
        //   name: 'Fee Schedules'
        // },
        {
          id: 'households',
          name: 'Account Groups'
        },
        {
          id: 'exclusions',
          name: 'Exclusions'
        },
        {
          id: 'funds',
          name: 'Insufficient Funds',
          featureFlag: true,
          flagName: 'insufficientFunds'
        }
			]
		};

    // console.log(parentTab);
		init();

		function init(){
      vm.loadingDateOptions = true;
      Billing.loadDateOptions()
      .then(function(){
        $scope.$broadcast('dateOptionsSet');
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        vm.loadingDateOptions = false;
      });

      // skip this step if we've already set it
      if (!angular.isDefined(totalAccountsCount)) {

        vm.loadingAccountsCount = true;
        $q.when(SmartXFactory.getAccountsWithMetadata({
          page: 1,
          pageSize: 10,
          status: 'openAndFundedAndUnallocated,rejected,closed,openAndAllocated,applicationSentToClientToSign,pending,applicationPendingBrokerApproval,applicationNotInGoodOrder,signed,inactive,unknown,openNotFunded,deleted,active'
        }))
        .then(function(res) {
          vm.activeAccountsCount = res.metaData.totalRecordCount;
          Dashboard.setTotalAccountsCount(vm.activeAccountsCount);
          $scope.$broadcast('gotAccountsCount', vm.activeAccountsCount);
        })
        .catch(function(err){
          console.error(err);
        })
        .finally(function(){
          vm.loadingAccountsCount = false;
        });

      }
		}

		
  });