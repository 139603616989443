angular.module('SecurityAdministrator')
  .run(function(formlyConfig) {

    formlyConfig.setType({
      name: 'typeahead',
      template: '<input type="text" placeholder="{{ to.placeholderText || \'Enter an item name\' }}" ng-model="model[options.key]" typeahead-on-select="to.handleItemSelect($item, $model, $label);" uib-typeahead="item as item.name for item in to.options | filter:$viewValue | limitTo:8" class="form-control">',
      wrapper: ['bootstrapLabel'],
    });

    formlyConfig.setType({
      name: 'typeahead-async',
      templateUrl: 'typeahead-async.html',
      // template: '<input type="text" placeholder="{{ to.placeholderText || \'Enter an item name\' }}" ng-model="model[options.key]" typeahead-on-select="to.handleItemSelect($item, $model, $label);" uib-typeahead="item as item.name for item in to.options | filter:$viewValue | limitTo:8" class="form-control">',
      wrapper: ['bootstrapLabel'],
    });
  })
  .controller("ProfileAssignmentsCtrl", function($filter, $element, $scope, NgTableParams, $timeout, $modal, $templateCache, Dashboard, toastr, SmartXFactory, Tabs, $q){
    var vm = this;
    var userApi = new SmartX.UserApi();

    // data
    vm.loading = false;
    vm.user = getUserFromTab($scope.tab);

    vm.objectTypes = {
      'reportedModel': 'Model',
      'account': 'Account',
      'enterpriseAccount': 'Enterprise Account',
      'enterpriseTarget': 'Enterprise Target'
    };

    vm.permissions = {
      'fullAccess': 'Full Access',
      'readOnly': 'Read Only',
      'readOnlyRebalance': 'Read and Rebalance',
      'noAccess': 'No Access',
    };

    vm.accessOptions = {
      'fullAccess': 'Full Access',
      'readOnlyRebalance': 'Read and Rebalance',
      'noAccess': 'No Access',
      'readOnly': 'Read Only'
    };

    vm.typeFilter = {  
      'type': {
        id: 'select',
        placeholder: 'Select Type'
      }
    };

    vm.permissionFilter = {
      'permission': {
        id: 'select',
        placeholder: 'Select Permission'
      }
    };

    vm.permissionFilterOptions = [
      {
        id: '',
        title: 'All'
      },
      {
        id: 'fullAccess',
        title: 'Full Access'
      }, 
      {
        id: 'readOnly',
        title: 'Read Only'
      },
      {
        id: 'readOnlyRebalance',
        title: 'Read & Rebalance'
      },
      {
        id: 'noAccess',
        title: 'No Access'
      }
    ];

    vm.typeFilterOptions = [
      {
        id: '',
        title: 'All'
      },
      {
        id: 'enterpriseAccount',
        title: 'Enterprise Accounts'
      },
      {
        id: 'enterpriseTarget',
        title: 'Enterprise Targets'
      },
      {
        id: 'account',
        title: 'Account'
      }
    ];

    vm.permissionTypes = [
      {id: 'fullAccess', name: 'Full Access'},
      {id: 'readOnly', name: 'Read Only'},
      {id: 'readOnlyRebalance', name: 'Read and Rebalance'},
      {id: 'noAccess', name: 'No Access'}
    ];
    
    // methods
    vm.deleteAssignment = deleteAssignment;
    vm.showCreateAssignment = showCreateAssignment;
    vm.updatePermission = updatePermission;
    vm.clearSelection = clearSelection;
    vm.showEditPermission = showEditPermission;
    vm.refreshAssignments = refreshAssignments;
    vm.assignmentFormValid = assignmentFormValid;
    vm.cancelEdit = cancelEdit;

    vm.selected = [];
    vm.checkboxes = {
      checked: false,
      items: {}
    };

    init();

    // watchers are disabled until bulk operations need to be implemented
    // $scope.$watch(function(){
    //   return vm.usersTable && vm.usersTable.filter();
    // }, function (filters) {
    //   if (vm.usersTable){
    //     vm.filteredData = $filter('filter')(vm.userProfile, formatFilters(filters));
    //   }
    // }, true);

    // watch for check all checkbox
    // $scope.$watch(function() {
    //   return vm.checkboxes.checked;
    // }, function(value) {
    //   var filters = vm.usersTable.filter();

    //   var filteredUsers = $filter('filter')(vm.userProfile, formatFilters(filters));

    //   angular.forEach(filteredUsers, function(item) {
    //     vm.checkboxes.items[item.id] = value;
    //   });

    //   vm.selected = getSelected(vm.usersTable, vm.checkboxes);

    // });
    
    // watch for data checkboxes
    // $scope.$watch(function() {
    //   return vm.checkboxes.items;
    // }, function() {
    //   if (vm.userProfile && vm.userProfile.length > 0){
    //     var filters = vm.usersTable.filter();
    //     var filteredUsers = $filter('filter')(vm.userProfile, formatFilters(filters));
    //     var checked = 0, unchecked = 0,
    //         total = filteredUsers.length;

    //     angular.forEach(filteredUsers, function(item) {
    //       checked   +=  (vm.checkboxes.items[item.id]) || 0;
    //       unchecked += (!vm.checkboxes.items[item.id]) || 0;
    //     });
    //     if ((unchecked == 0) || (checked == 0)) {
    //       vm.checkboxes.checked = (checked == total);
    //     }
  
    //     vm.selected = getSelected(vm.usersTable, vm.checkboxes);

    //     // grayed checkbox
    //     angular.element($element[0].getElementsByClassName("assignment-select-all")).prop("indeterminate", (checked != 0 && unchecked != 0));
    //   }
      
    // }, true);

    function refreshAssignments () {
      init();
    }

    function assignmentFormValid (form) {
      if (!angular.isDefined(form[vm.selectedObjectIdType + 'Id'])) return false;

      if (!angular.isDefined(form.accessLevel)) return false;

      if (!angular.isDefined(vm.selectedItemType)) return false;

      return true;
    }

    function clearSelection () {
      vm.checkboxes.items = {};
    }

    function showEditPermission () {
      // show modal for editing fields for the selected group of accounts
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('editPermissionModal.tpl.html'),
        resolve: {
          selectedPermissions: function() {
            return vm.selected;
          },
          accessLevels: function() {
            return vm.permissions;
          }
        },
        controller: 'SecurityAdminEditPermissionCtrl',
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(complete) {
        if (complete){
          clearSelection();
          init();
        }
      });
    }

    function getSelected(table, checkboxes){
      var checkedItems = checkboxes.items;
      var selected = [];
      if (table) {
        var data = table.settings().dataset;
  
        selected = _.filter(data, function(item){
          return checkedItems[item.profileId];
        });
      }

      console.log("Items Selected: ", selected);
      return selected;
    }

    function dotToNested (key, value) {
      var obj = {};
      var container = obj;
      key.split('.').map(function(k, i, values) {
        container = (container[k] = (i == values.length - 1 ? value : {}));
      });

      return obj;
    }

    function cancelEdit (assignment, permissionOptions) {
      assignment.permission = assignment.originalPermission;
      assignment.editing = false;

      permissionOptions.selected = assignment.originalPermission; 
    }

    function formatFilters (filters) {
      filters = _.reduce(angular.copy(filters), function(obj, v, k){
        if (k.split('.').length > 1) {
          v = dotToNested(k, v);
          _.extend(obj, v);
        } else {
          obj[k] = v;
        }

        return obj;
      }, {});

      return filters;
    }

    function getUserFromTab(tab){
      // tab is the object associated with the tab running this controller
      if (tab.data) return tab.data.user;
      else return null;
    }

    function updatePermission (assignment) {
      assignment.updating = true;

      $q.when(userApi.updateAssignment(assignment.profileId, assignment.permission))
      .then(function(res){
        console.log("Update permission result: ", res);
        toastr.success('Permission updated');
        assignment.editing = false;
        delete assignment.originalPermission;
        init();
      })
      .catch(function(err){
        console.error(err);
        toastr.error(err.message);
      })
      .finally(function(){
        assignment.updating = false;
      });
    }

    function deleteAssignment (assignment) {

      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('confirmDelete.html'),
        resolve: {
          assignment: function () {
            assignment.friendlyType = vm.objectTypes[assignment.type] || assignment.type;
            return assignment;
          },
          user: function () {
            return vm.user;
          }
        },
        controller: function ($uibModalInstance, assignment, user){
          var vm = this;
          
          vm.user = user;
          vm.assignment = assignment;
          vm.confirm = confirm;
          vm.cancel = cancel;          
          
          function cancel() {
            $uibModalInstance.dismiss('cancel');
          }

          function confirm() {
            $uibModalInstance.close(true);
          }
        },
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(confirmed) {
        if (confirmed) {
          assignment.deleting = true;

          $q.when(userApi.deleteAssignment(assignment.profileId))
          .then(function(res){
            toastr.success('Assignment removed');
            init();
          })
          .catch(function(err){
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            assignment.deleting = false;
          });
        }
      });

      // assignment.deleting = true;

      // $q.when(userApi.deleteAssignment(assignment.profileId))
      // .then(function(res){
      //   console.log("Delete result: ", res);
      //   toastr.success('Assignment removed');
      //   init();
      // })
      // .catch(function(err){
      //   console.error(err);
      //   toastr.error(err.message);
      // })
      // .finally(function(){
      //   assignment.deleting = false;
      // });
    }

    function showCreateAssignment () {
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('createAssignmentModal.tpl.html'),
        resolve: {
          user: function () {
            return vm.user;
          },
          userApi: function () {
            return userApi;
          },
          enterprisesApi: function () {
            return SmartXFactory.getEnterprisesAPI();
          },
          itemTypes: function () {
            return [
              {id: 'accounts', name: 'Account'},
              {id: 'enterpriseAccounts', name: 'Enterprise Account'},
              {id: 'enterpriseTargets', name: 'Enterprise Target'}
            ];
          },
          permissionTypes: function () {
            return vm.permissionTypes;
          }
        },
        controller: function ($scope, user, $uibModalInstance, userApi, enterprisesApi, itemTypes, permissionTypes, toastr, Dashboard,EnterpriseFactory){
          var vm = this;
          var userAccess = Dashboard.getUserAccess();
          vm.creating = false;
          vm.loadingEnterprises = false;
          vm.user = user;
          vm.newAssignmentForm = {
            accessLevel: '',
          };
          vm.itemTypes = itemTypes;
          vm.permissionTypes = permissionTypes;
          vm.selectedItemType = '',
          vm.submit = submit;
          vm.cancel = cancel;
          vm.placeholderText = 'Enter an item name';
          vm.handleItemSelect = handleItemSelect;

          vm.model = {};
          vm.fields = [
            {
              "key": "type",
              "type": "select",
              "templateOptions": {
                "label": "Type",
                "options": [{"id": undefined, "name": "Select a type"}].concat(vm.itemTypes),
                "valueProp": "id",
                "labelProp": "name",
                required: true,
                validation: {
                    show: true
                }
              },
              watcher: {
                listener: function(field, newValue, oldValue, scope, stopWatching) {
                  console.log("listener scope: ", scope);
                  if(newValue) {
                    vm.selectedItemType = newValue;
                  }
                }
              }
            },
            {
              "key": "accessLevel",
              "type": "select",
              "templateOptions": {
                "label": "Access Level",
                "options": [{"id": undefined, "name": "Select an access level"}].concat(vm.permissionTypes),
                "valueProp": "id",
                "labelProp": "name",
                required: true,
                validation: {
                    show: true
                }
              }
            },
            {
              "key": "item",
              "type": "typeahead",
              "hideExpression": function () {
                return vm.model.type === 'accounts';
              },
              "templateOptions": {
                "label": "Item",
                "options": [],
                "valueProp": "id",
                "labelProp": "name",
                required: true,
                validation: {
                    show: true
                },
                placeholderText: 'Enter an item name',
                handleItemSelect: handleItemSelect,
              },
              watcher: {
                expression: function (field, scope){
                  return scope.model.type;
                },
                listener: function(field, newVal, oldValue, scope, stopWatching) {
                  console.log("listener scope: ", scope);
                  if(newVal) {
                    switch (newVal) {
                      case 'reportedModel':
                        vm.items = userAccess.reportedModelAccess;
                        field.templateOptions.options = vm.items;
                        field.templateOptions.placeholderText = 'Enter a model name';
                        break;
                      /* case 'accounts':
                        vm.items = userAccess.accountAccess;
                        field.templateOptions.options = vm.items;
                        field.templateOptions.placeholderText = 'Enter an account number';
                        break; */
                      case 'enterpriseAccounts':
                      case 'enterpriseTargets':
                        vm.items = vm.enterpriseOptions;
                        field.templateOptions.options = vm.items;
                        field.templateOptions.placeholderText = 'Enter an enterprise name';
                        break;
                    }
                  }
                }
              }
            },
            {
              "key": "accountId",
              "type": "typeahead-async",
              "hideExpression": function () {
                return vm.model.type != 'accounts';
              },
              // validators: {
              //   valueSelected: {
              //     expression: function (viewValue, modelValue) {
              //       return !viewValue || modelValue.id;
              //     },
              //     message: 'No account selected'
              //   }
              // },
              "templateOptions": {
                "label": "Item",
                "options": [],
                "valueProp": "id",
                "labelProp": "name",
                required: true,
                validation: {
                    show: true
                },
                placeholderText: 'Search for account #',
                onSelect: function (item){
                  vm.model.accountId = item.id;
                },
                onClear: function () {
                  vm.model.accountId = '';
                }
              }
            },
            {
              "key": "createEnterpriseAccount",
              "type": "checkbox",
              hideExpression: function () {
                return vm.model.type != 'enterpriseTargets';
              },
              templateOptions: {
                label: 'Include account access'
              }
            },
            {
              "key": "createEnterpriseTarget",
              "type": "checkbox",
              hideExpression: function () {
                return vm.model.type != 'enterpriseAccounts';
              },
              templateOptions: {
                label: 'Include target access'
              }
            }
          ];
          
          init();

          function init() {
            var enterpriseLookupOptions = EnterpriseFactory.getEnterpriseLookupOptions();
            if (enterpriseLookupOptions) {
              vm.enterpriseOptions = enterpriseLookupOptions;
            } else loadEnterprises();
          }

          function handleItemSelect(item) {
            var objectIdType;
            switch (vm.selectedItemType) {
              case 'accounts':
                objectIdType = 'account';
                break;
              case 'enterpriseAccounts':
              case 'enterpriseTargets':
                objectIdType = 'enterprise';
                break;
            }
            vm.selectedObjectIdType = objectIdType;
            if (objectIdType) {
              vm.newAssignmentForm[objectIdType + 'Id'] = item ? item.id : '',
              vm.model[objectIdType + 'Id'] = item ? item.id : '';
            }
          }

          function loadEnterprises() {
            vm.loadingEnterprises = true;
            EnterpriseFactory.loadEnterpriseLookup()
            .then(function(enterprises){
              vm.enterpriseOptions = enterprises;
            })
            .catch(function(err){
              console.error(err);
              toastr.error(err.message);
            })
            .finally(function(){
              vm.loadingEnterprises = false;
            });
          }

          function cancel() {
            $uibModalInstance.dismiss('cancel');
          }

          function format (form) {
            delete form.item;
            delete form.type;
            return form;
          }

          function submit(newAssignmentForm) {
            vm.creating = true;

            var payload = [ format(angular.copy(newAssignmentForm)) ];
            var assignmentType;
            var method;
            console.log("Form payload: ", payload);

            switch (vm.selectedItemType) {
              case 'accounts':
                assignmentType = 'Account';
                method = 'createAccountAssignment';
                break;
              case 'enterpriseAccounts':
                assignmentType = 'enterpriseaccounts';
                method = 'create' + assignmentType + 'Assignment';
                break;
              case 'enterpriseTargets':
                assignmentType = 'enterprisetargets';
                method = 'bulkCreate' + assignmentType + 'Assignment';
                break;
            }

            if (!assignmentType) return;

            if (_.contains(['enterprisetargets', 'enterpriseaccounts'], assignmentType)) {

              // add user id to bulk payload items
              payload = payload.map(function(assignment){
                var fields = _.pick(assignment, [
                  'enterpriseId',
                  'accessLevel'
                ]);
                fields.userId = vm.user.id;
                return fields;
              });

              $q.when(userApi.bulkCreateAssignments(assignmentType, payload))
              .then(function(res){
                console.log('assignment create result: ', res);
                if (vm.model.createEnterpriseTarget || vm.model.createEnterpriseAccount) {

                  // creating secondary assignment
                  vm.creatingSecondary = true;
                  var type = vm.model.createEnterpriseTarget ? 'enterprisetargets' : 'enterpriseaccounts';

                  console.log("Creating second assignment: ", type);

                  $q.when(userApi.bulkCreateAssignments(type, payload))
                  .then(function(_res){
                    console.log("Secondary assignment result: ", _res);
                    var success = _.property(['data', '0', 'status'])(_res);
                    if (!success){
                      var msg = _.property(['data', '0', 'message'])(_res);
                      throw new Error(msg || 'Error creating ' + (type === 'enterprisetargets' ? 'Enterprise Target ' : 'Enterprise Account ') + 'assignment.');
                    }
                    $uibModalInstance.close(res.data);
                  })
                  .catch(function(err){
                    Dashboard.toastError(err.message, err);
                  })
                  .finally(function(){
                    vm.creatingSecondary = false;
                  });
                  
                } else {
                  $uibModalInstance.close(res.data);
                }
              })
              .catch(function(err){
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                vm.creating = false;
              });

            } else {
              $q.when(userApi[method](vm.user.id, payload))
              .then(function(res){
                console.log('assignment create result: ', res);
                $uibModalInstance.close(res.data);
              })
              .catch(function(err){
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                vm.creating = false;
              });
            }
          }
        },
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(newAssignment) {
        if (newAssignment) {
          toastr.success("Assignment created");
          init();
        }
      });
    }

    function formatProfile (profile) {
      profile = _.reduce(profile, function(fullProfile, items, type){
        if (!items.length) return fullProfile;

        var itemsWithType = _.map(items, function(item){
          item.type = type;
          return item;
        });

        return fullProfile.concat(itemsWithType);
      }, []);

      return profile;
    }

    function init() {

      var userId = vm.user.id;
      
      if (userId) {
        vm.loading = true;
        $q.when(userApi.getAssignments(userId))
        .then(function(res){
          vm.assignments = res.data;

          var userProfile = formatProfile(res.data.userProfile);
          vm.userProfile = userProfile.map(function(item){
            item.permission = item.permission || item.accessLevel;
            return item;
          });

          vm.usersTable = new NgTableParams({
            filter: {
              type: '',
              permission: ''
            }
          },{
            dataset: userProfile
          });
        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          vm.loading = false;          
        });
      } else {
        toastr.error('Unable to load a profile.  No user was specified.');
      }
    }
  });