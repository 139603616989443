/*
Chart is no longer displayed on account details page
 */
var Chart = angular.module('ChartCtrl', []);
Chart.controller("ChartCtrl", function ($scope, $rootScope, Dashboard, Chart, Models, Tabs, $timeout) {

  var parentTab = $scope.$parent.tab,
      tabType = $scope.tab.type,
      performanceChart,
      isDST = moment().isDST(),
      DST_offset = isDST ? 0 : 3600;

  if (parentTab.active){
    $scope.visited = true;
  } else {
    $scope.visited = false;
  }

  $scope.timeframes = Chart.timeframes;

  $scope.chartConfig = {
    title : {
      text: null
    }
  };

  $scope.performanceChart = {
    series: [{ data: null}]
  };

  $scope.$parent.$on('tabSwitched::' + tabType, function(event, tab){
    if (tab.type === 'main'){
      if (tab === $scope.$parent.tab){
        var switchTime = new Date().getTime() / 1000; // save the time

        if (!$scope.visited && typeof $scope.chartConfig.series === 'undefined'){
          $timeout(function(){
            if (typeof performanceChart !== 'undefined'){
              $scope.chartConfig = performanceChart;
              $scope.visited = true;
              $scope.$parent.loading = false;

            }

          }, 100);

        }

      }
    }
  });

  // get daily performance for an account
  // event is broadcasted from the main controller
  // this will run every time the user changes their selected account or there is a refresh
  $scope.$parent.$on('accountLoaded', function(event, accountID){
    
    debugger;
    $scope.getChartData(accountID, $scope.buildChart);
  });

  $scope.$parent.$on('accountLoadedWithAllocations', function(event, allocations){
    

    var payload = [],
        lastTimestamp = null;
    for (var index in allocations){
      var allocation = allocations[index];
      var timezoneOffset = new Date(allocation.asOf*1000).getTimezoneOffset(),
          formattedTime = moment(allocation.asOf*1000).local().valueOf(),
          hour =  moment(formattedTime).hour(),
          minutes = moment(formattedTime).minutes();

      if(hour <=9 && minutes < 29){
        continue
      }else{
        lastTimestamp = formattedTime;
        if(lastTimestamp != null && !isNaN(lastTimestamp)) payload.push([formattedTime, allocation.pnl]);
      }
    }

    lastHour = moment(lastTimestamp).hour(),
    lastMinutes = moment(lastTimestamp).minutes();
    while(lastHour < 16){
      var newTimestamp = moment(lastTimestamp).add(1, 'm');
      newTimestamp = newTimestamp.unix()*1000;
      if(lastTimestamp != null && !isNaN(lastTimestamp)) payload.push([newTimestamp, null]);

      lastTimestamp = newTimestamp;
      lastHour = moment(newTimestamp).hour(),
      lastMinutes = moment(newTimestamp).minutes();
    }


    $scope.buildChart(payload);
  });

  $scope.getChartData = function(accountID, callback){

    Dashboard.getDailyPerformance(accountID)
    .then(function(result){
      var parsedResponse = JSON.parse(result.data),
          payload = JSON.parse(parsedResponse.payload),
          series = payload.payload.account,
          values = [],
          timestamps = [],
          firstPoint = Object.keys(series)[0], // extract the 9:30 timestamp from the first data point
          firstPointTime = ( moment( Number(firstPoint)).unix() - DST_offset) * 1000,
          chartData = [[firstPointTime, 0]]; // add the initial point starting at 0

      console.log("Account Performance Data\n-------------------------\n", parsedResponse);
      $scope.$parent.chartError = false;
      //$scope.loading = false; // triggers the account view to reappear

      if( payload !== null && series != null ){
        for (var time in series){

          var balance = series[time] === null ? null : Number(series[time]);
          var formattedTime = ( moment( Number(time) ).unix() - DST_offset) * 1000;
          chartData.push([ formattedTime , balance]);

        }

        if (callback){

          // offset the time of the old first point so that there aren't two entries for 9:30:00
          chartData[1][0] = chartData[1][0] + 1000;

          // callback is the buildChart function
          callback(chartData);
        } else {
          $scope.chartData = chartData;
        }
      } else {

        $scope.$parent.loading = false;
        var msg = "There was a problem loading the performance data for this account.";
        $scope.$emit('chartError', msg);
      }

    });

  };

  $scope.addTabForModel = function(model){

      if(typeof model != 'undefined'){
        Models.currentModel = model;
        if(typeof Models.currentModel.data == 'undefined'){
          model.title = model.fund_profile.title;
          model.data = {};
          model.data.node = {};
          model.data.node = model.fund_profile;
        }
        Tabs.addTab('model');
      }
  };



  $scope.buildChart = function(chartData){

    if (typeof chartData !== 'undefined' && chartData.length){

      if (typeof performanceChart === 'undefined'){

        performanceChart = {
          options: {
            chart: {
              type: 'area',
              events: {
                load: function(event) {}
              }
            },
            pointSize: 1,
            credits : {
                enabled: false
            },
            style: {
              width: '100%'
            },
            yAxis: {
              title: {
                  text: null
              },
              labels: {
                  align: 'right',
                  format: '${value:,.0f}',
                  formatter: function() {
                    var point = this;
                    return "$" + Highcharts.numberFormat(point.value, 0, '.', ',');
                  }
              },
              plotLines: [{
                  color: 'rgba(29,55,82,.4)',
                  value: chartData[0][1],
                  //width: 2
              }]
            },
            tooltip: {

              formatter: function() {
                var point = this,
                    balance = "$" + Highcharts.numberFormat(point.y, 2, '.', ','),
                    date = Highcharts.dateFormat('%l:%M %p', point.x);


                return '<span style="color:#166AAB">Time: </span><b>' + date + '</b><br/><span style="color:#166AAB">Balance</span>: <b>' + balance + '</b><br/>';
              }
            }
          },
          title: {
              text: ""
          },
          xAxis: {
              tickWidth: 0,
              gridLineWidth: 1,
              type: 'datetime',
              tickAmount: 390,
          },

          legend: {
              enabled: false
          },

          exporting: {
              enabled: false
          },

          plotOptions: {

              area: {
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
              },
              series: {
                  threshold: 0 //chartData[0][1]
              }
          },

          series: [{
            id: 'Model Data',
            name: 'Today PnL',
            type:'area',
            lineWidth: 2,
            connectNulls: true,
            marker: { enabled: false },
            threshold: 0, //chartData[0][1],
            data: chartData,
            negativeColor: '#D85B08',
            color:'#8CB843'
          }],
          func: function(chart) {

            $scope.performanceChart = chart; // save the chart instance for future method calls
          }

        };

        if ($scope.visited || $scope.tab.active){
          if (typeof $scope.chartConfig.series !== 'undefined'){
            $scope.chartConfig.series[0].setData($scope.chartData);
          } else {
            $timeout(function(){
              $scope.chartConfig = performanceChart;
            }, 1);
          }

        }
      } else {
        if (angular.isDefined($scope.chartConfig.series) && angular.isDefined($scope.chartConfig.series.length)) {
          $scope.chartConfig.series[0].data = chartData;
        }
        // } else {
          
        //   $scope.chartConfig.series = [{
        //     id: 'Model Data',
        //     name: 'Today PnL',
        //     type:'area',
        //     lineWidth: 2,
        //     connectNulls: true,
        //     //marker: { radius: 2 },
        //     threshold: chartData[0][1],
        //     data: chartData,
        //     negativeColor: '#D85B08',
        //     color:'#8CB843'
        //   }];
        // }
      }

      $scope.$parent.chartLoaded = true;
      $scope.$parent.refreshing = false;

    } else {
      $rootScope.showLoader = false;
      $scope.$parent.chartLoaded = true;
      $scope.$parent.refreshing = false;
      $scope.$emit('chartError', "error loading chart");
    }
  };

  // was used when multiple model lines were being added to the chart
  $scope.clearChart = function(){
    // remove all but the first line
    $scope.modelsData = {};
    $scope.$parent.$broadcast('accountLoaded', $scope.$parent.selectedAccount.accountInfo.nid);
  };

});
