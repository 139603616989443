angular.module('ExclusionsCtrl', [])
.controller('ExclusionsCtrl', function ($scope,Dashboard) {

	var vm = this;
	
	vm.accessAccounts = Dashboard.getAccessAccounts();
    vm.accessAccountNames = _.pluck(vm.accessAccounts, 'name');	

    vm.changeAccount = function(refreshing){

        if(typeof $scope.selectedAccount == 'undefined'){
            return false;
        }
	}
	
});