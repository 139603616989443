angular
	.module('AccountHistory', [])
	.directive('hcvDatepicker', function(){
		// Runs during compile
		return {
			restrict: 'A',
			require:'ngModel',
			link: function($scope, element, iAttrs, ctrl) {

				//http://www.daterangepicker.com/
				jQuery(element).daterangepicker({
					autoUpdateInput: false,
					locale: {
						cancelLabel: 'Clear'
					}
				}, function(start, end, label) {
					// alert("A new date range was chosen: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
					if (label == null && start != null && end != null){
						label = $scope.dateFilter.label;
					}
					ctrl.$setViewValue(label);
					ctrl.$render();
					$scope.dateFilter = {
						label: label,
						start: +start.format('x'),
						end: +end.format('x')
					};
					$scope.$apply();
				});

				jQuery(element).on('keyup', function(e){
					if(e.keyCode == 13){
						jQuery('input[name="daterange"]').data('daterangepicker').clickApply();
					}
				});

				jQuery(element).on('apply.daterangepicker', function(ev, picker) {
					var label = picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY');

					$scope.dateFilter = {
						label: label,
						start: +picker.startDate.format('x'),
						end: +picker.endDate.format('x')
					};
					$scope.$apply();
				});

				jQuery(element).on('cancel.daterangepicker', function(ev, picker) {

					$scope.dateFilter = {
						label: "",
						start: null,
						end: null
					};
					$scope.$apply();
				});

				$scope.$watch('dateFilter', function(newVal, oldVal){
					if (newVal && oldVal && newVal.label != null && !_.isEmpty(newVal)){
						console.log(newVal, oldVal);
						$scope.filterByDate(newVal);
					}
				}, true);
			}
		};
	})
	.controller("AccountHistoryCtrl", function historyCtrl($scope, $timeout, $filter, Dashboard, NgTableParams, SmartXFactory){

		$scope.mappedAccounts = [];
		$scope.selectAccountHistory = [];
		$scope.history = {};
		$scope.dateFilter = {};
		$scope.selectedGUID = null;

		var parentTab = $scope.tab;
		var accountApi = SmartXFactory.getAccountAPI();
		console.log("Parent tab: ", parentTab);

		init();

		$scope.$on('update-accountHistory', function(event, account){
			$scope.selectedAccount = account;
			$scope.selectAccount(account);
		});

		$scope.activityFilter = {
			'activity': {
				id: "custom-select",
				placeholder: "Select Activity",
			}
		};

		function init(account){

			// var activeAccount = Dashboard.getActiveAccount();

			if (parentTab.data && !account){
				$scope.selectedAccount = parentTab.data;
				$scope.selectedAccountName = parentTab.data.name;
			} else if (account){
				$scope.selectedAccount = account;
			}
			
			$scope.loadingHistory = true;

			accountApi.getAccountActivity($scope.selectedAccount.id)
			.then(function(res){
				console.log("History for account: ", $scope.selectedAccount);

				var activities = res.data.ledger;
				var activityTypes = _.uniq( _.map(activities, function(entry){ return entry.activity; }) );

				console.log(activityTypes);

				$scope.selectAccountHistory = activities;
				$scope.activityTypes = [{"id": "", "title": "- Select Activity Type -"}].concat(_.map(activityTypes, function(type){ return {"id": type, "title": type}; }));

				$scope.accountHistoryParams = new NgTableParams({
													filter: {activity: "" },
															sorting : { 'activityTimestamp.timestamp': "desc" },
													}, {dataset: activities});
				
				$scope.filterByDate();
			})
			.catch(function(err){
				Dashboard.toastError(err.message, err);
			})
			.finally(function(){ 
				$timeout(function(){ $scope.loadingHistory = false; });
			}); 
		}

		function getActivityTypes(filteredData){

			var data = filteredData || $scope.selectAccountHistory;
			var activityTypes = _.uniq( _.map(data, function(entry){ return entry.activity; }) );
			
			$scope.activityTypes = [{"id": "", "title": "- Select Activity Type -"}].concat(_.map(activityTypes, function(type){ return {"id": type, "title": type}; }));
		}

		$scope.clearDateFilter = function(){
			$scope.dateFilter = {
				label: "",
				start: null,
				end: null
			};
		};

		$scope.filterByDate = function(newVal){

			if (!newVal) newVal = $scope.dateFilter;

			var start = newVal.start;
			var end = newVal.end;

			if (start == null && end == null){
				var filteredData = $scope.selectAccountHistory;
			} else {
				var filteredData = _.filter($scope.selectAccountHistory, function(day){
					var timestamp = day.activityTimestamp.timestamp * 1000;
					return (timestamp >= start && timestamp <= end);
				});
			}

			if (filteredData.length){
				getActivityTypes(filteredData);
			} else {
				$scope.activityTypes = [{"id": "", "title": "- Select Activity Type -"}];
			}

			// see if we should apply the current select filter to the new dataset
			// if not then clear the current filter
			var currentFilter = $scope.accountHistoryParams.filter();
			var filter = $filter('filter')(filteredData, currentFilter).length ? currentFilter : {activity: ''};

			$scope.accountHistoryParams.settings().dataset = filteredData;
			$scope.accountHistoryParams.filter(filter);
			$scope.accountHistoryParams.reload();
		};

		$scope.generateCSV = function(){

			var rows = [],
				filename = ($scope.selectedAccountName + '_activity_ledger.csv').replace(/ /g, '_');

			var filter = $scope.accountHistoryParams.filter();

			// filter our dataset so the csv data matches what was in the table
			var tableData = filter ? $filter('filter')($scope.accountHistoryParams.settings().dataset, filter) : $scope.accountHistoryParams.settings().dataset;

			var data = _.sortBy(tableData, function(activity){ return activity.activityTimestamp.timestamp; });
			
			_.each(data, function(entry, index){

				var row = {};

				_.extend(row, {
					"Date" : moment(entry.activityTimestamp.timestamp * 1000).format('MM/DD/YYYY'),
					"Amount" : entry.amount,
					// "Type" : entry.type,
					"Event" : entry.activity,
					"Ticker": entry.symbol ? entry.symbol : ''
				});

				rows.push(row);

			});

			Dashboard.JSONtoCSV(rows, filename);
		};

		$scope.selectAccount = function(account){
			$scope.selectedAccount = account;
			init($scope.selectedAccount);
		};

	});
