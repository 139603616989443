angular.module('TargetWeightGroups')
.directive('subTargetRow', ['$templateCache', function($templateCache) {
  return {
    restrict: 'E',
    scope: {
      component: '=',
      init: '='
    },
    template: $templateCache.get('subTarget.tpl.html'),
    link: link,
    controller: subTargetRowCtrl,
    controllerAs: 'vm'
  };

  function link(scope, elem, attr) {

  }


  function subTargetRowCtrl($scope, $rootScope, $log, $modal, Dashboard, TargetWeightFactory) {
    var vm = this;

    vm.loadSubTarget      = loadSubTarget;
    vm.toggleSubTargets   = toggleSubTargets;

    // console.log(vm, $scope);
    // debugger;


    function loadSubTarget(component) {
      console.log(component);
      debugger;

      TargetWeightFactory.setActiveTarget(component);
    }

    function toggleSubTargets(component)
    {
      if(typeof component.expanded == 'undefined'){
        component.expanded = true;
      }else{
        component.expanded = !component.expanded;
      }
    }

  }
}]);
