angular.module('TargetWeightModalCtrl', [])
  .controller('TargetWeightModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', '$modal', 'component', 'activeTarget', 'toastr', 'Dashboard', 'TargetWeightFactory', 'SweetAlert', 'symbols', 'models', 'enterpriseId', 'targetName', '$timeout', 'FeatureFlags', 'Amplitude', function($scope, $rootScope, $uibModalInstance, $modal, component, activeTarget, toastr, Dashboard, TargetWeightFactory, SweetAlert, symbols, models, enterpriseId, targetName, $timeout, FeatureFlags, Amplitude) {

    var vm = this;

    vm.component = component;
    vm.activeTarget = activeTarget;
    vm.symbols = symbols;
    vm.models = models;
    vm.isNew = false;
    vm.advanceMode = true;
    vm.advanceModeTerm = 'Advance Mode';

    vm.isEnabled = FeatureFlags.isEnabled;

    vm.format = 'dd-MMMM-yyyy';
    vm.form = {
      name: null,
      symbol: null,
      targetWeightGroup: null,
      leverage: null,
      type: null,
      matchModelLeverage: true,
      percentTarget: null,
      percentThreshold: null
    };

    vm.dropdownOptions = {
      enableSearch: true
    };
    vm.dropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      enableSearch: true,
      smartButtonMaxItems: 1,
      smartButtonTextConverter: function(itemText, originalItem) {
        return itemText;
      }
    };
    vm.accountsModel = [];
    vm.accountList = [];
    vm.errorMsg = null;
    vm.symbols = [];
    vm.models = [];
    vm.targets = [];

    vm.accessAccounts = Dashboard.getAccessAccounts();
    vm.accessAccountGUIDs = Object.keys(vm.accessAccounts);

    init();

    vm.cancel = cancel;
    vm.submit = submit;
    vm.toggleAdvanceMode = toggleAdvanceMode;
    vm.onChangeMatchLeverage = onChangeMatchLeverage;

    function onChangeMatchLeverage (newVal, oldVal) {
      if (newVal === false) {

        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'trackLeverageWarning.html',
          controller: function ($scope, $uibModalInstance, modelName) {
            $scope.modelName = modelName;
            $scope.ok = function () {
              $uibModalInstance.close();
            };
          
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          },
          size: 'md',
          resolve: {
            modelName: function () {
              return component.name;
            }
          }
        });
    
        modalInstance.result.then(function () {
          vm.form.matchModelLeverage = newVal;
        }, function () {
          vm.form.matchModelLeverage = oldVal;
        });
      } else {
        vm.form.matchModelLeverage = newVal;
      }
    }

    function init() {

      if (typeof vm.activeTarget != 'undefined' && vm.activeTarget != null) {
        vm.form.targetWeightGroup = vm.activeTarget.name;
      }else{
        vm.isNew = true;
        vm.form.isNew = true;
        if (targetName) vm.form.targetWeightGroup = targetName;
      }

      if (typeof vm.component != 'undefined' && vm.component != null) {

        console.log(vm.component);

        if (typeof vm.component.name == 'undefined') {
          vm.isNew = true;
          vm.form.isNew = true;
        }
        if (vm.component.type.toLowerCase() == 'symbols') {
          vm.form.symbol = vm.component.name;
        } else {
          vm.form.name = vm.component.name;
        }

        vm.form.type = vm.component.type;
        vm.form.leverage = (vm.form.type == "cash" || vm.form.type == 'targetWeightGroup') ? null : vm.component.leverage;
        vm.form.matchModelLeverage = vm.component.matchModelLeverage;
        vm.form.percentTarget = vm.component.newPercentTarget;

        if (typeof vm.component.percentLowerPrecision != 'undefined') {

          vm.form.percentLowerThreshold = vm.component.percentLowerThreshold;
          vm.form.percentUpperThreshold = vm.component.percentUpperThreshold;
          vm.form.percentLowerPrecision = vm.component.percentLowerPrecision;
          vm.form.percentUpperPrecision = vm.component.percentUpperPrecision;
        } else if (typeof vm.component.defaults != 'undefined') {

          vm.form.percentLowerThreshold = vm.component.defaults.DefaultLowerThreshold;
          vm.form.percentUpperThreshold = vm.component.defaults.DefaultUpperThreshold;
          vm.form.percentLowerPrecision = vm.component.defaults.DefaultLowerPrecision;
          vm.form.percentUpperPrecision = vm.component.defaults.DefaultUpperPrecision;
        }
      }

      if (vm.targets.length == 0) {        
        if (component.type === 'targetWeightGroup') {
          vm.loadingTargets = true;
          // TargetWeightFactory.get(null, {enterpriseId: enterpriseId })
          TargetWeightFactory.getTargets()
          .then(function(res){

            var response = res.data;
            var targets = _.sortBy(response.data, function(target){
              return target.name.toLowerCase();
            });

            targets.forEach(function(target){
              vm.targets.push({
                id: target.id,
                name: target.name
              });
            });

            $scope.$apply();
          })
          .catch(function(err){
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            $timeout(function(){
              vm.loadingTargets = false;
            });
          });
        }
      }
    }

    vm.getSymbolSearchResults = function(term) {
      vm.loadingSymbols = true;
      var len = term.length;
      if (len) {

        var matches = _.filter(vm.symbols, function(symbol) {
          var match = false;

          if (typeof symbol.AssetName == 'undefined') {
            return match;
          }

          if (symbol.AssetName.toLowerCase() == term.toLowerCase()) {
            match = true;
          } else if (len > 1 && symbol.AssetName.toLowerCase().slice(0, len) == term.toLowerCase()) {
            match = true;
          } else if (len > 2 && symbol.Company.toLowerCase().indexOf(term.toLowerCase()) != -1) {
            match = true;
          }

          return match;
        });

        return matches;
      } else {
        vm.loadingSymbols = false;
        return [];
      }
    };

    vm.getModelSearchResults = function(term) {
      vm.loadingModels = true;
      var len = term.length;

      if (len) {

        var matches = _.filter(vm.models, function(model) {
          var match = false;

          if (typeof model.Name == 'undefined') {
            return match;
          }

          if (model.Name.toLowerCase() == term.toLowerCase()) {
            match = true;
          } else if (len > 2 && model.Name.toLowerCase().indexOf(term.toLowerCase()) != -1) {
            match = true;
          }

          return match;
        });

        return matches;
      } else {
        vm.loadingSymbols = false;
        return [];
      }
    };

    vm.getTargetSearchResults = function(term) {
      vm.loadingTargets = true;
      var len = term.length;

      if (len) {

        var matches = _.filter(vm.targets, function(target) {
          var match = false;

          if (typeof target.name == 'undefined') {
            return match;
          }

          if (target.name.toLowerCase() == term.toLowerCase()) {
            match = true;
          } else if (len > 2 && target.name.toLowerCase().indexOf(term.toLowerCase()) != -1) {
            match = true;
          }

          return match;
        });

        return matches;
      } else {
        vm.loadingTargets = false;
        return [];
      }
    };


    vm.onSecuritySelected = function($item, $model, $label) {
      console.log("item: ", $item);
      console.log("model: ", $model);
      console.log("label: ", $label);

      vm.selectedSecurity = $item;
    };

    function submit() {

      // debugger;
      if (!vm.isNew && (typeof vm.activeTarget == 'undefined' || vm.activeTarget == null)) {
        debugger;
        SweetAlert.swal("No target selected", "Please select a target.", "error");
        return false;
      } else if (vm.form.type.toLowerCase() == 'symbols' && (typeof vm.form.symbol == 'undefined' || vm.form.symbol.length == 0)) {
        SweetAlert.swal("Invalid Symbol", "Please enter a symbol.", "error");
        return false;
      } else if (vm.form.type.toLowerCase() == 'model' && (typeof vm.form.name == 'undefined' || vm.form.name.length == 0)) {
        SweetAlert.swal("Invalid Model", "Please select a model.", "error");
        return false;
      } else if (typeof vm.form.percentTarget == 'undefined') {
        SweetAlert.swal("Invalid Amount", "Your target amount should be a number between 0-100.", "error");
        return false;
      } else if (vm.form.type.toLowerCase() == 'model' && typeof vm.form.leverage == 'undefined' || parseFloat(vm.form.leverage) > 4 || parseFloat(vm.form.leverage) < 0.01) {
        SweetAlert.swal("Invalid Leverage", "Leverage should be between a number between 1-4.", "error");
        return false;
      }
      vm.form.percentTarget = parseFloat(vm.form.percentTarget);

      var temp = vm.form;
      temp.type = vm.form.type;
      temp.leverage = parseFloat(vm.form.leverage);
      temp.matchModelLeverage = vm.form.matchModelLeverage;
      temp.percentTarget = vm.form.percentTarget;
      temp.percentLowerThreshold = parseFloat(vm.form.percentLowerThreshold);
      temp.percentUpperThreshold = parseFloat(vm.form.percentUpperThreshold);
      temp.percentLowerPrecision = parseFloat(vm.form.percentLowerPrecision);
      temp.percentUpperPrecision = parseFloat(vm.form.percentUpperPrecision);

      switch (temp.type.toLowerCase()) {
        case 'models':
        case 'model':
          temp.name = vm.form.name;
          temp.id = (typeof vm.selectedSecurity != 'undefined') ? vm.selectedSecurity.Id : (vm.component.modelId || vm.component.id);
          break;
        case 'targetweightgroup':
          Amplitude.logEvent('Target Editor:Sub-target add clicked');
          temp.name = (typeof vm.selectedSecurity != 'undefined') ? vm.selectedSecurity.name : vm.form.name;
          temp.id = (typeof vm.selectedSecurity != 'undefined') ? vm.selectedSecurity.id : (vm.component.id || vm.component.twgId);
          if (!temp.id){
            SweetAlert.swal("No target selected", "Please select a target.", "error");
            return false;
          }
          break;
        case 'symbols':
        case 'symbol':
          // debugger;
          temp.name = vm.form.name;
          temp.id = (typeof vm.selectedSecurity != 'undefined') ? vm.selectedSecurity.Id : (vm.component.id || vm.component.symbolId);
          temp.assetType = (typeof vm.selectedSecurity != 'undefined') ? vm.selectedSecurity.assetType : vm.component.assetType;
          break;
      }

      $uibModalInstance.close(temp);

    }

    function createTarget(payload) {
      TargetWeightFactory.create({
          components: payload
        })
        .then(function(response) {

          var errors = [];
          console.log(response);
          debugger;
          if (response.data.status == 'success') {

            vm.errorMsg = null;
            $uibModalInstance.dismiss('cancel');
            $rootScope.$broadcast('update-target');
          } else if (response.data.status == 'fail') {
            for (var i = 0, len = response.data.msg.length; i < len; i++) {
              errors.push(response.data.msg[i].description);
            }
            toastr.error(errors.join(", "), 'Error');
            vm.errorMsg = errors.toString();
          }

        });
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function toggleAdvanceMode() {
      vm.advanceMode = !vm.advanceMode;
      if (vm.advanceMode)
        vm.advanceModeTerm = 'Simplified Mode';
      else
        vm.advanceModeTerm = 'Advance Mode';
    }

    function dataFailure(data, cb) {
      console.log(data);
      var msg = [];

      debugger;
      if (typeof data.msg != 'undefined') {
        for (var i = 0; i < data.msg.length; i++) {
          msg.push(data.msg[i].description);
        }
        vm.errorMsg = msg.join('. ');
      } else {
        vm.errorMsg = 'Error getting data from middleware.';
      }

      vm.retryCount++;
      if (vm.retryCount < 3) {
        debugger;
        cb();
      }
    }

  }]);
