angular.module('SecurityAdministrator')
.controller('SecurityAdminEditAccountCtrl', securityAdminEditAccountCtrl);

function securityAdminEditAccountCtrl ($q, SmartXFactory, Dashboard, $uibModalInstance, selectedAccounts, toastr) {

  var vm = this;
  var accountApi = SmartXFactory.getAccountAPI();

  vm.selectedAccounts = selectedAccounts;
  vm.saving = false;
  vm.propertiesForm = {
    isApm: '',
    modelAssignmentRestricted: ''
  };

  vm.errors = [];
  vm.submit = submit;
  vm.cancel = cancel;
  vm.reset = reset;
  vm.loadProperties = loadProperties;  

  init();

  function loadProperties() {
    // load account properties such as isApm, modelRestricted etc
  }

  function init() {
    loadProperties();
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function reset() {
    var userNameInput = document.getElementById('userName');
    vm.newUser = null;
    vm.success = false;
    vm.newUserForm = {};
    userNameInput.focus();
    userNameInput.scrollIntoView();
  }

  function submit(isApm) {

    if (angular.isUndefined(isApm)) return false;

    if (vm.complete && !vm.errors.length){
      return $uibModalInstance.close(true);
    }
    
    vm.saving = true;
    vm.complete = false;
    vm.completed = 0;
    vm.errors = [];

    var payload = vm.selectedAccounts.map(function(account){
      return {
        accountId: account.id,
        isApm: isApm
      };
    });

    // debug failure messages
    // payload = payload.concat([{
    //   accountId: '2c0eed95-b1cd-4e7e-b5d2-012592816545',
    //   isApm: isApm
    // }]);

    $q.when(accountApi.bulkUpdateAccountProperties(payload))
    .then(function(res){
      vm.complete = true;
      vm.errors = res.data.filter(function(result){
        return !result.updateSuccessful; 
      })
      .map(function(error){
        error.account = _.find(vm.selectedAccounts, {id: error.accountId});
        error.message = error.errors.join('.  ');
        return error;
      });
    })
    .catch(function(err){
      toastr.error(err.message, {
        timeOut: 0,
        extendedTimeOut: 0
      });
    })
    .finally(function(){
      vm.saving = false;
    });

  }
}