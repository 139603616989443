angular
.module('AccountSummary', [])
.controller("AccountSettingsCtrl", function($scope, $rootScope, $http, $uibModal, Dashboard, Brokerage, SettingsFactory, Models, Tabs, DRUPAL_API_URL) {

	var vm = this;
    var slides = vm.slides = [];
    var currIndex = 0;

    $rootScope.$on('dashboard-account-access-set', function(event, args) {

        vm.accessGroups =  args.groups;
        vm.accessAccounts =  args.accounts;
        vm.accessModels =  args.models;

        vm.platformName = args.platform.name;

        console.log(vm.platform.name);
    });

    vm.hasAccounts = false;
    vm.sortTopModelsBy = 'YTD';

    vm.myInterval = 10000;
    vm.noWrapSlides = false;
    vm.active = 0;
    vm.settingsPayload;
	
	vm.models = {};
	vm.fields = {};
	vm.forms = {};

    function init(){
		
       
       vm.selectedAccount = Dashboard.getActiveAccount();
       vm.fields = vm.settingsPayload.managementFees;
       debugger;
    }

    vm.addTabForModel = function(guid){

        if(typeof guid != 'undefined'){

            var url = `${DRUPAL_API_URL}/ng_api/model-info/` + guid,
            req = {
                method: 'GET',
                url: url,
                headers: {'Content-Type': 'application/x-www-form-urlencoded' }
            };

            $http(req)
            .then(function(result){
                var model = result;
                Models.currentModel = model;
                Tabs.addTab('model');
            });
        }
    };
   
   
   

      vm.settingsPayload = {
         managementFees: [
            {
               "className": "row",
               "fieldGroup": [
                  {
                     "className": "section-label col-xs-12",
                     "template": "<hr /><div><strong>Signee 1:</strong></div>"
			      },
		       ]
	        },

            {
               className: 'section-label col-xs-12',
               template: "<hr class='management-fees-hr' /><h3><strong>Management Fees:</strong></h3>"
		    },
            {
               key: 'hasManagementFee',
               type: 'radio',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: "Do you want to automatically bill your advisory fees to your client's account?",
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               }
            },
            {
               key: 'managementFeePeriod',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: "Monthly",
               templateOptions: {
                  label: 'Payment Period',
                  required: true,
                  options: Brokerage.feePeriodOpts
               },
               hideExpression: "!model.hasManagementFee",
               expressionProperties: {
                  "templateOptions.disabled": "true"
               }
            },
            {
               key: 'managementFeeType',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: "Arrears",
               templateOptions: {
                  label: 'Fee Type',
                  required: true,
                  options: Brokerage.feeTypeOpts
               },
               hideExpression: "!model.hasManagementFee",
               expressionProperties: {
                  "templateOptions.disabled": "true"
               }
            },
            {
               className: 'section-label col-md-3 col-xs-12 text-center',
               template: "<a href ng-click='toggleTieredFees()' classs='col-xs-12 text-center' style='line-height: 80px;'>{{model.hasTieredFeeSchedule ? 'Remove' : 'Add'}} Tiered Fee Schedule</a>",
               controller: /*@ngInject*/ function ($scope) {
                  $scope.toggleTieredFees = function () {

                     console.log($scope);
                     console.log($scope.model.hasTieredFeeSchedule);
                     $scope.model.hasTieredFeeSchedule = (typeof $scope.model.hasTieredFeeSchedule == 'undefined') ? true : !$scope.model.hasTieredFeeSchedule;
                     $scope.model.managementFee = null;
                  };
               },
               hideExpression: "!model.hasManagementFee" // "true"
		    },
            {
               key: 'hasTieredFeeSchedule',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: false,
               templateOptions: {
                  label: 'Add Tiered Fee Schedule',
                  required: true,
                  options: Brokerage.yesNoOpts
               },
               hideExpression: "true" // "!model.hasManagementFee"
            },
            {
               key: 'managementFee',
               type: 'input',
               className: 'col-md-3 col-xs-12 percentage-val',
               templateOptions: {
                  label: 'Management Fee',
                  placeholder: '2.5',
                  required: true
               },
               hideExpression: "!model.hasManagementFee || model.hasTieredFeeSchedule",
               validators: {
                  float: {
                     expression: function (viewValue, modelValue) {
                        var value = modelValue || viewValue;

                        var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
                        if (!floatRegex.test(value))
                           return false;

                        val = parseFloat(value);

                        if (val >= 100 || val <= 0)
                           return false;

                        return !isNaN(val);
                     },
                     message: '$viewValue + " is not a valid percentage value between 0-100."'
                  }
               }
            },
            {
               key: 'managementFeeTable',
               type: 'account_fees_table',
               className: 'col-xs-12',
               hideExpression: "!model.hasManagementFee || !model.hasTieredFeeSchedule",
               controller: /*@ngInject*/ function ($scope) {

                  $scope.feeTableData = defaultTierPayload = [{
                     feeAmount: null,
                     startingLimit: 0,
                     endingLimit: null
                   }, {
                        feeAmount: null,
                        startingLimit: null,
                        endingLimit: null
                   }];

                  $scope.feesTableErrorMsg;
                  $scope.feeTableCalculation = '';
                  $scope.isCompany = false;
                  $scope.showTieredFees = false;
                  $scope.feeTableBlendedTotal = null;
                  $scope.feeTableFlatTotal = null;



                  $scope.addNewFeeRow = function (row) {
                     //		debugger;

                     var endingLimit = (typeof row.endingLimit == 'undefined') ? 0 : parseFloat(row.endingLimit) + 0.01;

                     console.log($scope.feeTableData);
                     //		debugger
                     if ((typeof row.feeAmount == 'undefined' || typeof row.endingLimit == 'undefined' || typeof row.startingLimit == 'undefined') || (row.feeAmount == null || row.endingLimit == null || row.startingLimit == null)) {
                        $scope.feesTableErrorMsg = "Incomplete row information.";
                        return;
                     } else {
                        $scope.feesTableErrorMsg = undefined;
                     }

                     $scope.feeTableData.push({
                        feeAmount: null,
                        startingLimit: endingLimit,
                        endingLimit: null
                     });
                  };

                  $scope.removeFeeRow = function (index) {
                     //		debugger;
                     $scope.feeTableData.splice(index, 1);
                     calculateBlendedRate();
                  };

                  $scope.validateRow = function (row, index) {
                     console.log(row);
                     $scope.feesTableErrorMsg = undefined;
                     row.amountError = false;
                     row.feeError = false;

                     $scope.feeTableFlatRate = undefined;
                     var startingLimit = parseFloat(row.startingLimit);
                     var endingLimit = parseFloat(row.endingLimit);
                     var feeAmount = parseFloat(row.feeAmount);

                     if (startingLimit > 0 && endingLimit > 0) {
                        if (startingLimit >= endingLimit) {
                           $scope.feesTableErrorMsg = "Ending Limit must be greater than the Starting limit.";
                           row.amountError = true;
                        }

                     }

                     if (hasValidFeeAmount(feeAmount)) {
                        $scope.feesTableErrorMsg = "Enter a fee amount between 0.01% - 99.99%.";
                       

                     var lastRow = $scope.feeTableData[$scope.feeTableData.length - 1];

                     if (index == $scope.feeTableData.length - 1 && (parseFloat(lastRow.endingLimit) > 0 && parseFloat(lastRow.feeAmount) > 0)) {
                        var _endingLimit = (endingLimit - startingLimit) / 2 + startingLimit;
                        var abbrNum = abbreviateNumber(_endingLimit, 0);

                        row.flatCalculation = abbrNum + ' * ' + $filter('number')(parseFloat(lastRow.feeAmount)) + '%';

                        $scope.feeTableFlatRate = parseFloat(lastRow.feeAmount);
                     } else {
                        row.flatCalculation = null;
                     }

                     if (typeof $scope.feeTableData[index + 1] != 'undefined' && $scope.feeTableData[index].endingLimit != null) {

                        //			debugger;
                        $scope.feeTableData[index + 1].startingLimit = (typeof $scope.feeTableData[index].endingLimit == 'undefined') ? 0 : Number($scope.feeTableData[index].endingLimit.replace(/[^0-9\.]+/g, "")) + 0.01;
                        calculateBlendedRate();

                     } else {
                        if ($scope.feeTableData[index].endingLimit != null && $scope.feeTableData[index].startingLimit != null && $scope.feeTableData[index].feeAmount != null) {

                           console.log($scope.feeTableData[index]);
                           //				debugger
                           $scope.addNewFeeRow($scope.feeTableData[index]);

                        }

                        calculateBlendedRate();
                     }
                     
                     $scope.model.tieredSchedule = $scope.feeTableData;
                  }

                  $scope.toggleTieredFees = function () {
                     $scope.showTieredFees = !$scope.showTieredFees;
                  };


                  function abbreviateNumber(number, fractionSize) {

                     if (number === null) return null;
                     if (number === 0) return "0";

                     if (!fractionSize || fractionSize < 0)
                        fractionSize = 1;

                     var abs = Math.abs(number);
                     var rounder = Math.pow(10, fractionSize);
                     var isNegative = number < 0;
                     var key = '';
                     var powers = [
                        {
                           key: "Q",
                           value: Math.pow(10, 15)
                                        },
                        {
                           key: "T",
                           value: Math.pow(10, 12)
                                        },
                        {
                           key: "B",
                           value: Math.pow(10, 9)
                                        },
                        {
                           key: "M",
                           value: Math.pow(10, 6)
                                        },
                        {
                           key: "K",
                           value: 1000
                                        }
					];

                     for (var i = 0; i < powers.length; i++) {

                        var reduced = abs / powers[i].value;

                        reduced = Math.round(reduced * rounder) / rounder;

                        if (reduced >= 1) {
                           abs = reduced;
                           key = powers[i].key;
                           break;
                        }
                     }

                     return (isNegative ? '-' : '') + abs + key;
                  }





                  function hasValidFeeAmount(feeAmount) {
                     return (isNaN(feeAmount) || feeAmount == null || feeAmount >= 100 || feeAmount < 0.001);
                  }

                  function calculateBlendedRate() {
                     if ($scope.feeTableData.length > 1) {
                        var fees = [];
                        var endingAmounts = [];
                        var total = 0;

                        $scope.feeTableBlendedRate = 0;
                        var calculationStr = '';

                        $scope.feeTableBlendedTotal = 0;
                        $scope.feeTableFlatTotal = 0;


                        for (var i = 0; i < $scope.feeTableData.length; i++) {
                           var tier = $scope.feeTableData[i];
                           if (i >= 1) {
                              var lastTier = $scope.feeTableData[i - 1];
                           }

                           if (tier.feeAmount != null && tier.startingLimit != null && tier.endingLimit != null && (i == 0 || tier.startingLimit > 0)) {

                              var fee = parseFloat(tier.feeAmount) / 100;
                              var limit = endingLimit = (tier.startingLimit != null && tier.endingLimit != null) ? parseFloat(tier.endingLimit) : parseFloat(lastTier.endingLimit);
                              var startingLimit = (tier.startingLimit != null && tier.endingLimit != null) ? parseFloat(tier.startingLimit) : parseFloat(lastTier.startingLimit);


                              var abbrNum = abbreviateNumber(limit, 0);
                              if (i == $scope.feeTableData.length - 1) { // Last Row

                                 limit = (limit - startingLimit) / 2 + startingLimit;

                                 $scope.feeTableFlatTotal = limit * ($scope.feeTableFlatRate / 100);
                                 $scope.feeTableBlendedAmount = parseInt(limit.toFixed(0));
                              }

                              if (fee == 0 || limit == 0 || fee == null || limit == null) {
                                 return;
                              }

                              fees.push(fee);
                              endingAmounts.push(limit);


                              var tierAmount = endingLimit - startingLimit;
                              total += tierAmount;
                              //					var abbrNum = abbreviateNumber(limit, 0);
                              if (i == $scope.feeTableData.length - 1) tierAmount = parseInt(limit.toFixed(0)) - parseInt(tierAmount.toFixed(0));
                              $scope.feeTableBlendedRate += (fee * tierAmount);

                              var rowLimit = '$' + abbrNum; //$filter('currency')(limit)
                              calculationStr += (rowLimit + ' * ' + $filter('number')(fee));
                              if (i < $scope.feeTableData.length - 1) {
                                 calculationStr += '<br/>';
                              }
                              $scope.feeTableBlendedTotal += tierAmount * fee;
                              //					debugger;

                              console.log(parseInt(tierAmount.toFixed(0)) + ' * ' + fee + ' = ' + (tierAmount * fee).toFixed(0));


                              if (!isNaN(limit)) {

                                 tier.blendedCalculation =
                                    '($' + abbreviateNumber(limit, 0) + ' - ' + abbreviateNumber(startingLimit, 0) + ')' + ' * ' + $filter('number')(fee, 4) + '%';

                              }
                              if (fees.length >= 2) {
                                 //				if(total > 0){ $scope.feeTableBlendedAmount = total; }
                                 if ($scope.feeTableBlendedRate > 0) {
                                    //					debugger;
                                    $scope.feeTableBlendedRate = $scope.feeTableBlendedRate / $scope.feeTableBlendedAmount * 100;
                                 }
                                 $scope.feeTableCalculation = calculationStr;

                              }

                           } else if (tier.feeAmount != null && tier.endingLimit == null && typeof lastTier != 'undefined' && (i == 0 || tier.startingLimit > 0)) {

                              var fee = parseFloat(tier.feeAmount) / 100;
                              var startNum = Number.isInteger(lastTier.startingLimit) ? lastTier.startingLimit : Number(lastTier.startingLimit.replace(/[^0-9\.]+/g, ""));
                              var endNum = Number.isInteger(lastTier.endingLimit) ? lastTier.endingLimit : Number(lastTier.endingLimit.replace(/[^0-9\.]+/g, ""));

                              var limit = (endingLimit = (endNum - startNum) / 2) + endNum;

                              var abbrNum = abbreviateNumber(limit, 0);
                              if (i == $scope.feeTableData.length - 1) { // Last Row

                                 tier.flatCalculation = abbrNum + ' * ' + $filter('number')(fee, 4) + '%';
                                 $scope.feeTableFlatRate = fee;

                                 $scope.feeTableFlatTotal = limit * fee;
                                 $scope.feeTableBlendedAmount = parseInt(limit.toFixed(0));
                              }

                              fees.push(fee);
                              endingAmounts.push(limit);


                              var tierAmount = limit;
                              total += tierAmount;
                              if (i == $scope.feeTableData.length - 1) {
                                 tierAmount = parseInt(limit.toFixed(0)) - parseInt(tierAmount.toFixed(0));
                                 //						debugger
                              }
                              $scope.feeTableBlendedRate += (limit * fee);

                              var rowLimit = '$' + abbrNum; //$filter('currency')(limit)
                              calculationStr += (rowLimit + ' * ' + $filter('number')(fee));
                              if (i < $scope.feeTableData.length - 1) {
                                 calculationStr += '<br/>';
                              }
                              $scope.feeTableBlendedTotal += tierAmount * fee;
                              //					debugger;

                              console.log(parseInt(tierAmount.toFixed(0)) + ' * ' + fee + ' = ' + (tierAmount * fee).toFixed(0));


                              if (!isNaN(limit)) {

                                 tier.blendedCalculation =
                                    '($' + abbreviateNumber(limit, 0) + ' - ' + abbreviateNumber(tier.startingLimit, 0) + ')' + ' * ' + $filter('number')(fee, 4) + '%';

                              }
                           }

                        }


                     }
                  }


               };
               },
            },
            {
               key: 'isFeesCumlative',
               type: 'select',
               className: 'col-md-6 col-xs-12',
               defaultValue: false,
               templateOptions: {
                  label: "Would you like to use blended management fees?",
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               hideExpression: "!model.hasManagementFee || !model.hasTieredFeeSchedule",
            }
         ]
      };
   
   
   
   

    // set up initial values and get some data
    init();


});