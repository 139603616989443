angular.module('Directives').directive('targetDetailsList', targetDetailsList)

function targetDetailsList($templateCache){
	return {
		restrict: 'E',
		scope: {
			details: '=details',
		},
		bindToController: true,
		controller: targetDetailsListCtrl,
		controllerAs: 'vm',
		template: $templateCache.get('targetDetailsList.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {}
	};
}

function targetDetailsListCtrl($scope, $filter, toastr) {
	var vm = this

}