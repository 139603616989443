angular
	.module('PendingDetail', [])
	.controller("PendingDetailCtrl", function pendingDetailCtrl($rootScope, $scope, filterFilter, Tabs, Dashboard, NgTableParams){

		var vm = this;

		vm.mappedAccounts = [];
    	vm.isLoading = false;
		vm.accountStatuses = [
			{id:'Active', title:'Active'},
			{id:'Application Not in Good Order', title:'Application Not in Good Order'},
			{id:'Application Pending Broker Approval', title:'Application Pending Broker Approval'},
			{id:'Application Sent to Client to Sign', title:'Application Sent to Client to Sign'},
			{id:'Closed', title:'Closed'},
			{id:'Deleted', title:'Deleted'},
			{id:'Inactive', title:'Inactive'},
			{id:'Open and Allocated', title:'Open and Allocated'},
			{id:'Open and Funded and Unallocated', title:'Open and Funded and Unallocated'},
			{id:'Open Not Funded', title:'Open Not Funded'},
			{id:'Pending', title:'Pending'},
			{id:'Rejected', title:'Rejected'},
			{id:'Signed', title:'Signed'},
			{id:'Unknown', title:'Unknown'}
		];
		vm.accountBrokerageFirms = [
			{id:'Schwab', title:'Charles Schwab'},
			{id:'TD Ameritrade', title:'TD Ameritrade'},
			{id:'Interactive Brokers', title:'Interactive Brokers'},
		];

		vm.accessAccounts = {};
		vm.tableParams = new NgTableParams({
	      sorting : { startedTracking: "desc" },
	      filter : { status: 'Pending' }
	    }, {
	      dataset: vm.mappedAccounts
	    });
		vm.totalItems = 0;
    	vm.pageSize = 50;
    	vm.currentPage = 1;
    	vm.sortType = 'title'; 	 // set the default sort type
		vm.sortReverse = false;  // set the default sort order
    	

    	vm.init = function(){
			console.log("initializing accounts summary");
      
        	vm.getPendingAccountDetail();
		}

		vm.getPendingAccountDetail = function()
		{

			vm.isLoading = true;
			Dashboard.getAccountPerformanceForGUID( '528392c0-e43e-44b9-a106-b05fa81067b6' ) // guid
	        .then(function(res){
	            //console.log(res.data);
	            $scope.loading = false;
	            if(res.data.status == 'success'){
	            	vm.isLoading = false;
	            }
	        });
        	
		}

	    vm.addTab = function(type, account, sortedIndex){

	    	var accountIndex = vm.mappedAccounts.indexOf(account);
	    	
	    	Tabs.addTab(type, accountIndex);

	    	Dashboard.currentAccount = Dashboard.accounts[accountIndex];

	    	// this updates the selected account in the existing allocate or liquidate tab
	    	Dashboard.notifyObservers('accountSummary-' + type);
	    };


		vm.init();
	});