var pantheon_environment = window.Drupal ? window.Drupal.settings.pantheon_environment : (window.location.host.includes('localhost') ? 'dev' : resolveHost(window.location.host));
var environment;

console.log(pantheon_environment);

switch (pantheon_environment){
  case 'live':
    environment = 'prod';
    break;
  case 'ids':
    environment = 'staging-jwt';
    break;
  case 'test':
    environment = 'test';
    break;
  case 'smartx-v2':
    environment = 'prodTest';
    break;
  case 'dev':
    environment = 'staging';
    break;
  default:
    environment = 'staging';
}
if (pantheon_environment === 'kevin-perf') environment = 'test';

console.log("Host: ", window.location.host);
console.log("SmartX.js Environment: ", environment);

const ENV = {
    "version": "1.0", // smx-js version
    "apiVersion": "3.1", // middleware api
    "cmsApiVersion": "3.0", // drupal api
    "timeout": 30000,
    "devProtocol" : "https://",
    "prodProtocol": "https://",
    "includeProtocol": false, // disable adding of the protocol when building urls

    "environment": process.env.NODE_ENV, // should be set manually or by GH action prior to build
    // these are exposed via .env file imported by webpack
    "drupalApiUrl": `${process.env.DRUPAL_API_URL}/api`,
    "smartxApiUrl": `${process.env.SMX_API_URL}api`
};

window.ENV = ENV;

function resolveHost(host){
  var resolvedHost;
  if (host.match(/hedgecovest/g) != null){
    resolvedHost = host.split('-hedgecovest')[0];
  } else if (host.match(/demo/g) != null) {
    resolvedHost = "smartx-v2";
  } else {
    resolvedHost = "live";
  }

  console.log("Resolved Host: ", resolvedHost);
  return resolvedHost;
}

export default ENV;
