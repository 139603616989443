angular.module('Directives')
.directive('loader', function($templateCache){
	return {
		restrict: 'AE',
		scope: {
			loading: '=visible', 
			message: '=message'
		},
		template: $templateCache.get('loader.html')
  };
});