angular.module('SettingsService', [])
.factory('SettingsFactory', ['$http', 'DRUPAL_API_URL', function($http, DRUPAL_API_URL){

	function createExclusion(exclusions){

		var req = {
			method: 'POST',
			url:`${DRUPAL_API_URL}/ng_api/v2/exclusion`,
			data:  exclusions,
			headers: {'Content-Type': 'application/json'}
		};

		return $http(req);
    }


    function getPlatformSettings(){
		return $http.get(`${DRUPAL_API_URL}/ng_api/v2/settings`);
    }

    return {
		get    				: getPlatformSettings,
		create 				: createExclusion,
    };

}]);