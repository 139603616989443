angular.module('LoginModal', [])
  .controller('LoginModalCtrl', function($scope, $uibModalInstance, $http, DRUPAL_API_URL) {

    console.log("Opening login modal...");

    $scope.loginUsername = "";
    $scope.loginPassword = "";
    $scope.loginMsg = "";
    $scope.login = function() {


      var data = {
        "username": $scope.loginUsername,
        "password": $scope.loginPassword
      };

      var req = {
  			method: 'POST',
  			url: `${DRUPAL_API_URL}/ng_api/v2/user/refresh/token`,
  			data:   jQuery.param(data),
  			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  		};

  		$http(req)
  	    .success(function(res){

          if (res.status == 'success') {
    	    	// debugger;
            // console.log(res);
            $scope.loginMsg = undefined;
            $uibModalInstance.close(res);
          }else{

            $scope.loginMsg = res.msg;
            // console.log(res);
          }


  	    }).error(function(msg, code){

  	    	// debugger;
          $scope.loginMsg = 'Please refresh the page.';
          // console.log(msg, code);
          // $uibModalInstance.close(code);

  	    });


    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
