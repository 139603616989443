angular.module('PerformanceChart', [])
.directive('performanceChart', function($timeout){
    return {
		restrict: 'E',
		scope : {
			data : '=data'
		},
		template : '<highchart id="modelChart-{{model.data.node.nid}}" config="chart" style="float: left; width:100%" height="400"></highchart>',
		controller: ['$scope', '$q',
			function ($scope, $q){

				$scope.chart = {
					title: {
					  text: null,
					  style: {
						display: 'none'
					  }
					}
				};

				function createSeries(startDate){
					var modelData,
				        leverage,
				        startModel,
				        performance,
				        newSeries,
				        benchmarkPerformance = $scope.model.benchmark,
				        startBenchmark = 1000,
				        startingBalance = 1000,
				        benchmarkData = [],


				    models = $scope.data.models;
				    
						newSeriesArr = [];

				    // prepare the benchmark
				    if (_.keys(models).length) {

				    	leverage = 1;

				    	if (startDate){
					      	benchmark = _.pick(
					      				benchmarkPerformance, 
					      				function(value, key, object) {
										  return Number(key) >= startDate;
										}
									);
							}
							
							debugger

					    for (var day in benchmark) {

					        if (typeof benchmark[day] !== 'undefined') {
					          benchmark[day] = !isNaN(benchmark[day].pnl) ? benchmark[day].pnl : benchmark[day] / 100;
					          var timestamp = day*1000,
					              currentBenchmark = (startBenchmark + ( parseFloat(benchmark[day] * leverage) ) * (startBenchmark));

					          benchmarkData.push( {x: timestamp, y: currentBenchmark, value: benchmark[day]} );

					          startBenchmark = currentBenchmark;
					        } 
					    }

					    var firstBenchmarkPoint = benchmarkData[0].x;

				        var newFirstBenchmarkPoint = moment(firstBenchmarkPoint).subtract(1, 'month').valueOf();

				        benchmarkData.unshift([newFirstBenchmarkPoint, startingBalance]);

				        var benchmarkSeries = {
		                    id: 'Benchmark',
		                    name: 'S&P 500 TR',
		                    type:'line',
		                    lineWidth: 2,
		                    connectNulls: true,
		                    marker: { 
		                    	enabled: false 
		                    },
		                    data: benchmarkData,
		                    color:'#2c557f',
		                };

		                newSeriesArr.push(benchmarkSeries);
		            }


	                // process the models
				    angular.forEach(models, function(model, rid){
				      modelData = [];
				      leverage = 1;
				      startModel = 1000;
				      startingBalance = 1000;

				      model = model.performance ? model.performance : model

				      if (startDate){
				      	model = _.pick(
				      				model, 
				      				function(entry, key) {
				      					if (angular.isDefined(entry.range)){
				      						return entry.range.start.timestamp >= startDate;
				      					} else {
											return Number(key) >= startDate;
				      					}
									}
								);
				      }

				      for (var day in model) {

				        if (typeof model[day] !== 'undefined') {
									var pnl;

									if (model[day].pnl){
										pnl = !isNaN(model[day].pnl.percent) ? model[day].pnl.percent : model[day] / 100;
									} else {
										pnl = model[day] / 100;
									}

									var timestamp = model[day].range ? model[day].range.start.timestamp * 1000 : day * 1000,
											currentModel = (startModel + ( parseFloat(pnl * leverage) ) * (startModel));

									modelData.push( {x: timestamp, y: currentModel, value: pnl} );

									startModel = currentModel;
								} 
							}
							
							debugger

			      	var firstModelPoint = modelData[0].x;

			        var newFirstModelPoint = moment(firstModelPoint).subtract(1, 'month').valueOf();

			        modelData.unshift([newFirstModelPoint, startingBalance]);


				      newSeries = {

				        id: rid,
				        name: $scope.modelNames[rid] || $scope.data.modelNames[rid],
				        type:'line',
				        individual: true,
				        lineWidth: 2,
				        connectNulls: true,
				        data: modelData,
				        marker: {
							enabled: false
						},
				        visible: $scope.data.showAllModels ? true : false

					  };
					  
					  // keep the same color for the initial series
					  if ($scope.data.initialModel && $scope.data.initialModel.guid == newSeries.id){
						  newSeries.color = '#D85B08';
					  }

				      newSeriesArr.push(newSeries);
				    });

					return newSeriesArr;
				}

				$scope.$watch('data.models', function(newData, oldData){
				

					if ( angular.isDefined(newData) ){

						$scope.model = angular.isDefined($scope.data.model) ? $scope.data.model : undefined;
						$scope.modelNames = angular.isDefined($scope.data.modelNames) ? $scope.data.modelNames : [];


						console.log("Performance chart directive scope", $scope);

						var modelData = $scope.data.modelData,
							benchmarkData = $scope.data.benchmarkData,
							startingBalance = 1000;
							startDate = $scope.data.startDate;

						var chart = {
							chart: {                                    
								type: 'area',
								events: {
									load: function(event) {
										var chart = this;
										$scope.data.model.modelChartInstance = chart;

										$timeout(function() {
											chart.reflow();
										});
									}
								},
								zoomType: 'x'
							},

							title : {
								text : 'Simulated Model Performance'
							},
							credits : {
								enabled: false
							},
							style: {
								width: '100%',
								height: 300
							},
							yAxis: {
								title: {
									text: null
								},
								labels: {
									align: 'right',
									format: '${value:,.0f}',
									formatter: function() {
										return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
									}
								},
							},
							tooltip: {
			
								formatter: function() {

									var s  = '<span style=""><b>' + Highcharts.dateFormat('%B %e, %Y', this.x) + '</b></span>';

									_.each(this.points, function(point){
										var currentBalance = Highcharts.numberFormat(point.y, 2, '.', ',');
										var percent = ((point.y - startingBalance) / startingBalance) * 100;

										s += '<br/><b><span style="color:' + point.series.color + '">' + point.series.name + ': ' + '$' + currentBalance + '</b> (' + percent.toFixed(2) + '%)</span>';
									});
									

									return s;
								},
								
								crosshairs: true,
								shared: true
							},
				            title: {
				                text: null,
				                style : {
				                    display : 'none'
				                }
				            },
				            xAxis: {
				                tickWidth: 0,
				                gridLineWidth: 1,
				                type: 'datetime',
				                tickAmount: 390,
				            },

				            legend: {
				                enabled: true
				            },

				            exporting: {
				                enabled: false
				            },
				            plotOptions: {
				                series: {
				                    marker: {
				                        enabled: false
				                    }
				                }
				            },

				            func: function(chart) {

				                $scope.data.model.modelChartInstance = chart;

				                $timeout(function() {
				                    chart.reflow();
				                });
				            }
			        	};

			        	chart.series = [{
				                    id: 'Model Data',
				                    name: angular.isDefined($scope.model) ? $scope.model.title : 'Simulated Portfolio Performance',
				                    type:'line',
				                    lineWidth: 2,
				                    individual: false,
				                    connectNulls: true,
				                    marker: { 
				                    	enabled: false 
				                    },
				                    data: modelData,
				                    color: '#D85B08',
				                    
				                },
				                {
				                    id: 'Benchmark',
				                    name: $scope.data.benchmarkName, //'S&P 500',
				                    type:'line',
				                    lineWidth: 2,
				                    connectNulls: true,
				                    marker: { 
				                    	enabled: false 
				                    },
				                    data: benchmarkData,
				                    color:'#2c557f',
				                    
				                }];
				        var numPoints = chart.series[0].data.length;


				        $q.when(createSeries(startDate))
				        .then(function(modelsToCompare){
				        	chart.series = (modelsToCompare.length ? [] : chart.series).concat(modelsToCompare);
				        	$scope.chart = chart;
				        });
			        }
			    });
	    	}
    	]
   	};
});