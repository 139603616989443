var Menu = angular.module('SideMenu', ['LoginModal']);
Menu.controller('MenuCtrl', function ($q, FeatureFlags, IdentityFactory, $window, $location, TargetWeightFactory, EnterpriseFactory, $http, Models, Dashboard, Tabs, $scope, $rootScope, $timeout, SweetAlert, FNAME, USER, SMARTX, EDITOR_ENABLED, toastr, RebalancerFactory, SmartXFactory, DRUPAL_API_URL) {

		var $ = jQuery;

		var menu = $("#hcv-dashboard-menu");
		var collapseBtn = $('#collapse-menu');
		// var collapseIcon = collapseBtn.find('i.fa');
		// var showingLoginModal = false;

		var userApi = new SmartX.UserApi();
		var modelApi = new SmartX.ModelApi();
		var allocationApi = new SmartX.AllocationApi();

		var blurTimer;
		this.blurTimeAbandoned = 200; // time in ms for when menu is consider no longer in focus

		$scope.isDrupalUser = _.contains(_.values(Drupal.settings.roles), "authenticated user");

		$scope.userGuide = "https://smartx.atlassian.net/servicedesk/customer/portals";
		$scope.year = moment().year();
		$scope.version = APP_VERSION || '';

		$scope.FNAME = FNAME;
		$scope.profileLink = IdentityFactory.getProfileLink();
		$scope.passwordLink = IdentityFactory.getPasswordLink();

		// $scope.profileLink = (window.location.protocol === 'https:' ? 'https://identity-uat.smartxadvisory.com/Manage' : 'http://identity-server.hedgecovest.local/Manage') + '?ReturnUrl=' + encodeURIComponent(window.location.origin + '/v3/dashboard');

		// TODO: figure out what support needs to be added from this in order to completely migrate to identity server
		$http.get(`${DRUPAL_API_URL}/api/3.0/drupalMetadata`)
		.then(function(res){
			var result = res.data;
			var userMetaData = {
				// username: result.user.username,
				// clientID: result.user.clientID,
				// completed_profile: result.user.completedProfile,
				// active_accounts: null,
				// show_tour: result.user.showTour,
				// portfolios: result.user.savedPortfolios,
				// isRia: result.user.isRia,
				// ria_logo: null,
				// customPortalLogo: result.user.customPortalLogo,

				// drupal variables set through site administration menu
				// maintenance disclaimers, on-going issues, etc
				show_dashboard_dialog: result.drupalVariables.showDashboardDialog,
				dashboard_dialog_title: result.drupalVariables.dashboardDialogTitle,
				dashboard_dialog_message: result.drupalVariables.dashboardDialogMessage,

				// data loaded from drupal's enterprise object
				// enterprise: result.enterprise.title,
				// sendIMAEmail: result.enterprise.sendIMAEmail,
				
				// can possibly be feature flagged
				// leverage_enabled: result.enterprise.leverageEnabled,
				// exclusionsEnabled: result.user.exclusionsEnabled,
				// taxHarvestingEnabled: result.user.taxHarvestingEnabled,
				// clientModeEnabled: result.enterprise.clientModeEnabled,
				// guided_portfolios_enabled: result.enterprise.guidedPortfoliosEnabled, 
				// accountSnapshotEnabled: result.enterprise.accountSnapshotEnabled, 
				// accountSnapshotDisabled: result.enterprise.accountSnapshotDisabled, 
				// apmDisabled: result.enterprise.apmDisabled, 

				// logo application hierarchy 
				// if no logo is set for the user or the user's enterprise, use the default SMArtX logo.
				logo: result.user ? (result.user.customPortalLogo || result.enterprise.logo || result.user.riaLogo || 'SMArtX-logo-new_0.png') : 'SMArtX-logo-new_0.png'
			};

			if ( angular.isDefined(userMetaData.show_dashboard_dialog) && userMetaData.show_dashboard_dialog){

				SweetAlert.swal({
					title: userMetaData.dashboard_dialog_title,
					text: userMetaData.dashboard_dialog_message,
					type: 'info'
				});
			}

			$scope.EXCLUSIONS_ENABLED = userMetaData.exclusionsEnabled;

			angular.extend(USER, userMetaData);

			// fix for BUG-1142. https://github.com/angular-ui-tree/angular-ui-tree/issues/309#issuecomment-152146209
			$scope.USER = angular.extend(($scope.USER || {}), USER);
			console.log("Set USER Object: ", $scope.USER);
		});

		// $scope.CLIENT_MODE_ENABLED = (CLIENT_MODE_ENABLED == 'on');
		// $scope.GUIDED_PORTFOLIOS_ENABLED = (GUIDED_PORTFOLIOS_ENABLED == 'on');
		// $scope.EXCLUSIONS_ENABLED = (EXCLUSIONS_ENABLED == 'on');
		$scope.EDITOR_ENABLED = EDITOR_ENABLED == 'on';
		// $scope.Tour = TourService;
		$scope.hasAccounts = false;
		$scope.openSub = false;
		$scope.accessGroups = undefined;
		$scope.hasProfile = !!$scope.FNAME;
		$scope.toggleClientMode = toggleClientMode;
		// $scope.enterClientMode = enterClientMode;
		// $scope.exitClientGroup = exitClientGroup;
		// $scope.showClientModal = showClientModal;
		// $scope.deleteGroup = deleteGroup;
		$scope.processAccountAccess = processAccountAccess;
		$scope.markAsRead = markAsRead;
		$scope.showClientGroupDialog = false;
		$scope.isSwitchingGroups = false;
		$scope.groupId = null;
		$scope.groupName = null;
		$scope.clientGroups = [];

		$rootScope.SMARTX = $scope.SMARTX = angular.isDefined(SMARTX) ? SMARTX : false;

		$rootScope.$on('dashboard-refresh-access', function () {

			var updateTabs = false;
			
			// getAccountAccess(updateTabs);
		});

		$rootScope.$on('dashboard-check-rebalance', function () {

			loadAccountsNeedingRebalance();
		});

		$scope.$on('profileSaved', function () {
			$scope.hasProfile = true;
			$scope.setWidth();
		});

		$scope.$on('FirstNameSet', function (event, name) {
			$scope.FNAME = name;
			$scope.hasProfile = true;
			$scope.setWidth();

		});

		$scope.$on("HasActiveAccount", function (event, count) {

			if (count > 0) {
				$scope.hasAccounts = true;
			} else {
				$scope.hasAccounts = false;
			}
		});

		$scope.$on('account-rebalance-set', function(e, count) {
			$scope.numberOfAccountsToRebalance = count;
		});


		// fall back to this logo when no enterprise is set
		$scope.logo = 'SMArtX-logo-new.png'; //'site_logo_1.png';

		menu.on('menufocus', function () {
			clearTimeout(blurTimer);
		});

		menu.on('menublur', function () {
			blurTimer = setTimeout(function () {
				menu.menu("collapseAll", null, true);
			}, blurTimeAbandoned);
		});

		var $menuWrap = $('body.node-type-ngdashboard #hcv-dashboard-menu-wrap');

		$scope.menuOpen = false;

		$scope.setWidth = function () {
			if ($scope.FNAME !== "") {
				if ($(window).width() > 768 && $(window).width() < 1500) {
					//$scope.menuCollapsed = true;
					$scope.menuFolded = true;
					$scope.openSub = false;
					// debugger
				} else if ($(window).width() < 768 || $(window).width() >= 1500) {
					//$scope.menuCollapsed = false;
					$menuWrap.removeClass('folded');
					$scope.menuFolded = false;
					$scope.openSub = true;
					// debugger
				}
			}
			if ($scope.hasProfile) {
				calculateContentOffset();
			}
		};

		init();

		function setApiVersion (version){
			// set the api version in the footer
			if (version) jQuery('#clientApiVersion').text(version);
		}

		function initOlarkChat () {
			$http.get('/misc/olark.js')
			.then(function(res){
				var olarkSnippet = res.data;
				// $('body').append(olarkSnippet);
				const olarkChat = document.getElementById('olarkChat');
				console.log(olarkChat);
				olarkChat.textContent = olarkSnippet;
			});

			// const scriptTag = document.createElement('script');
			// scriptTag.src = 'misc/olark.js';
			// scriptTag.setAttribute('nonce','3f7ab5e1-18d5-49fb-94d7-cce73eae7a2a');
			// scriptTag.setAttribute('data-cfasync', false);
			// document.body.appendChild(scriptTag);
		}

		function loadDrupalEnterprise(id) {
			var promise = new Promise(function(resolve, reject) {
				$http.get(`${DRUPAL_API_URL}/api/3.0/enterprise/${id}`)
				.then(function(res){
					resolve(res);
				})
				.catch(function(err){
					reject(err);
				});
			});
			
			return promise;
		}

		function initCustomChat(chatCode) {

			var loadJivoChat;

			// append the script to the body of the page
			jQuery('body').append(chatCode);

			// get username and e-mail
			IdentityFactory.getProfile()
			.then(function(res){
				// console.log("User's IS profile: ", res);

				// init the chat with the required username and e-mail
				if (window.loadJivoChat) window.loadJivoChat(res.data.preferred_username, res.data.email);
			})
			.catch(function(err){
				console.error(err);
			});
		}

		function setCustomPageTitle (title) {
			if (title) jQuery('title').text(title);
		}

		// if it comes in like this due to how smartx-js transpiles the router.ts file
		// importing sentry in hcv_angular.module and calling Sentry.init prevented things from working when it was also initialized in smartxjs
		function initLogging (user) {
			try {
				if (typeof __SENTRY__ !== 'undefined' && __SENTRY__.hub) {
					var Sentry = __SENTRY__.hub;
				}
				
				if (angular.isDefined(Sentry)) {
					Sentry.configureScope(function(scope){
						if (user.profile) {
							var username = user.profile.preferred_username || user.profile.name;
							scope.setUser({ 'username': username });
						}
					});
				}
			} catch (e) {
				console.error('Error initializing logging');
			}
		}

		function getUser () {
			var userMock = {};
			// if (window.location.hostname === "localhost") return Promise.resolve(userMock);
			return userApi.get();
		}

		function getUserAccess () {
			// return Promise.reject(); // test failed access
			var accessMock = {};
			// if (window.location.hostname === "localhost") return Promise.resolve(accessMock);
			return userApi.getUserAccess();
		}

	
		function getModelMinimums () {
			// return Promise.reject(new Error('Test Error')); // to debug retry logic
			return modelApi.getMinimums();
		}

		function withRetry (method, maxRetries) {
			var numTries = 0;
			
			return new Promise(function retry (resolve, reject){
				method()
				.then(function(res){
					return resolve(res);
				})
				.catch(function(err){
					numTries++;
					if (numTries < maxRetries) {
						console.log("request failed. attempts: " + numTries + '/' + maxRetries);
						retry(resolve, reject);
					} else {
						return reject(err);
					}
				});
			});
		}


		function init() {

			// hide it initially, will be unhidden if user has access
			// olark('api.box.hide');
			
			// var updateTabs = true;
			$scope.setWidth();
			$scope.isLoadingUser = true;
			$scope.userLoaded = false;

			$scope.loadingMsg = 'Verifying Session...';

			IdentityFactory.getUser()
			.then(function(res) {
				console.log("Identity user: ", res);
				if (res && !res.expired) {
					// toastr.success('User Loaded!');
					$scope.hasProfile = true;

					initLogging(res);

					IdentityFactory.setActiveUser(res);
					SmartXFactory.setToken(res.access_token);

					initLogging(res);

					$scope.loadingMinimums = true;
					withRetry(getModelMinimums, 3)
					.then(function(res){
						try {
							Models.setMinimums(res.data);
						} catch (e) {
							toastr.error('Error caching model minimums list.');
							console.error(e);
						}
					})
					.catch(function(err){
						err.message = 'Couldn\'t load model minimums: ' + err.message;
						Dashboard.toastError(err.message, err);
					})
					.finally(function(){
						$scope.loadingMinimums = false;
					});

					var decoded = jwt_decode(res.access_token);
					var profile = res.profile;
					console.log("Decoded jwt: ", decoded);

					var smx_type = decoded && decoded.smx_type;

					// // log them out if they don't have the advisor type
					// if (!_.contains(smx_type, 'advisor')){
					// 	IdentityFactory.logout();
					// 	return;
					// }
					
					// here is where we can set impersonators id once it is available from the jwt token
					if (decoded.original_user) SmartX.Router.setImpersonatorId(decoded.original_user);

					try {
						amplitude.getInstance().setUserId(profile.smartx_user_guid);
					} 
					catch (e) {
						console.error(e);
					}

					$scope.loadingMsg = 'Loading Your Dashboard...';
					$scope.accessLoaded = false;

					Promise.all([ getUser(), getUserAccess() ])
						.then(function(res){
							var user = res[0];
							var access = res[1];

							try {
								setApiVersion(res[0].version);
							} catch (e) {
								console.error("error setting api version");
							}

							if (_.property(['data','isSecurityAdministrator'])(user)) {
								$scope.securityAdministrator = true;
							}

							Dashboard.setReplicatorUser(user.data);
							Dashboard.setUserAccess(access.data);

							$scope.userLoaded = true;
							$scope.FNAME = _.property(['data', 'firstName'])(user) || _.property(['firstName'])(profile);

							loadAccountsNeedingRebalance();
							loadFundingSleeves();

							// for amplitude
							try {
								setUserProperties(_.property(['data'])(user));
								setViewportProperties();
							} catch (e) {
								console.error(e);
							}

							return loadDrupalEnterpriseData(user.data)
									.then(function(){
										handleUserAccess(access);

										console.log("got enterprise data");

										$timeout(function() {
											finalizeUserLoaded(user);
										}, 1000);

										console.log('data load complete');
									})
									.catch(function(err){

										$scope.errorLoadingEnterprise = true;

										// make sure we catch something
										if (angular.isDefined(Sentry)) {
											Sentry.withScope(function(scope) {
												scope.setTag("Session Load Error", "Error loading enterprise details");
												Sentry.captureException(err ? err.message : 'no msg');
											});
										}

										Dashboard.toastError('Error loading enterprise details', err);
										return Promise.reject(err);
									});
						})
						.catch(function(err){
							console.error(err);
							$timeout(function(){
								$scope.isLoadingUser = false;

								// data could be used to pass any custom error message
								if (!$scope.userLoaded) {

									$scope.errorLoadingUser = true;
									var userId = profile.smartx_user_guid;

									console.error("Failed to load profile for user:", userId); // should generate a sentry log
									Dashboard.toastError('Failed to load user profile');

									$rootScope.$broadcast('dashboard-init-failed', "Some special error text");
								} else {
									broadcastEmptyTabs();
								}
							});
						});

					// userApi.getUserAccess()
					// 	.then(handleUserAccess)
					// 	.catch(function(err){
					// 		console.error(err);
					// 		toastr.error(err.message);
					// 	})
					// 	.finally(function(){
					// 		loadAccountsNeedingRebalance();
					// 	});


				} else {
					var search = window.location.search.replace('?', '').split('&');
					var params = {};
					var jumpto = null;

					search.forEach(function(query) {
						var pieces = query.split('=');
						var k = pieces[0];
						var v = pieces[1];
						params[k] = v;
					});

					jumpto = params.jumpto;
					
					if (jumpto) {
						if (jumpto === 'account') {
							var acctParam = $location.search();
							try {
								if (acctParam.accountId) {
									window.localStroage.setItem('smxJumptoAccountId', acctParam.accountId);
								}

								if (acctParam.accountNumber) {
									window.localStorage.setItem('smxJumptoAccountNumber', acctParam.accountNumber);
								}
							} catch (e) {
								console.error(e);
							}
						}
						params = {
							redirect_uri: window.location.origin + "/callback?jumpto=" + jumpto
						};
					} else {
						params = '';
					}
					IdentityFactory.login(params);
				}
			})
			.catch(function(err){
				Dashboard.toastError("Error loading user session", err);
				$timeout(function(){
					$scope.isLoadingUser = true;
				});
			});
		}

		function loadDrupalEnterpriseData (user) {
			var topLevelEnterpriseId = user.topLevelEnterprise ? user.topLevelEnterprise.id : null;
			var enterpriseId = user.enterprise ? user.enterprise.id : null;

			var req = [ loadDrupalEnterprise (enterpriseId) ];

			if (topLevelEnterpriseId !== enterpriseId) {
				EnterpriseFactory.setTopLevelEnterpriseId(topLevelEnterpriseId);
				req.push(loadDrupalEnterprise(topLevelEnterpriseId));
			}

			// $scope.loadingMsg = 'Loading Enterprise Settings...';

			return Promise.all(req)
				.then(function(res){
					var enterpriseResponse = res[0];
					var topLevelEnterpriseResponse = res[1] || null;

					if (topLevelEnterpriseResponse) {

						var topLevelLogo = _.property(['data', 'logo'])(topLevelEnterpriseResponse);

						// if the user's enterprise is not found in drupal, use the top level enterprise logo
						if (enterpriseResponse.data && !enterpriseResponse.data.logo && topLevelLogo) {
							enterpriseResponse.data.logo = topLevelLogo;
						}

						handleTopLevelDrupalEnterprise(topLevelEnterpriseResponse);
					} else {
						if (!_.property(['data', 'customChatCode'])(enterpriseResponse)){
							var chatDisabled = FeatureFlags.isEnabled('noChat');
							if (!chatDisabled) initOlarkChat();
						}
					}

					handleDrupalEnterprise(enterpriseResponse);

					return Promise.resolve();
				})
				.catch(function(err){

					// make sure we catch something
					if (angular.isDefined(Sentry)) {
						Sentry.withScope(function(scope) {
              scope.setTag("Session Load Error", "Failed to load enterprise configuration");
							Sentry.captureException(err ? err.message : 'no msg');
            });
					}

					Dashboard.toastError('Failed to load enterprise configuration', err);
					return Promise.reject(err);
				});
		}

		function finalizeUserLoaded (user) {
			console.log('hiding loader');
			console.log('User Loaded: ', $scope.userLoaded);
			console.log('Has Profile: ', $scope.hasProfile);

			$scope.isLoadingUser = false;
			$scope.userLoaded = true;
			$scope.$broadcast('smx-user-loaded', user);
		}

		function handleDrupalEnterprise (res) {
			console.log('updating user properties');

			// fix for BUG-1142. https://github.com/angular-ui-tree/angular-ui-tree/issues/309#issuecomment-152146209
			$scope.USER = angular.extend(($scope.USER || {}), {
				enterprise: res.data,
				accountSnapshotDisabled: res.data ? res.data.accountSnapshotDisabled : true,
			});

			// inject custom css if present
			if (res.data && res.data.customCss) {
				injectCustomCss(res.data.customCss);
			}
		}

		function injectCustomCss(css) {
			var customStyles = document.createElement('style');
				customStyles.textContent = css;

			document.head.appendChild(customStyles);
		}

		function handleTopLevelDrupalEnterprise (res) {
			// console.log('top level enterprise response: ', res);

			var topLevelEnterprise = res.data; 
			$scope.topLevelEnterprise = topLevelEnterprise;

			if (topLevelEnterprise) {

				EnterpriseFactory.setTopLevelDrupalEnterprise(topLevelEnterprise);

				if (topLevelEnterprise.disclaimer) {
					Dashboard.setCustomDisclaimer(topLevelEnterprise.disclaimer);
				}

				// inject custom css if present
				if (topLevelEnterprise.customCss) {
					injectCustomCss(topLevelEnterprise.customCss);
				}
		
				if (topLevelEnterprise.customChatCode) {
					// olark('api.box.hide');
					// jQuery('#olark').remove();
					// jQuery('#hbl-live-chat-wrapper').remove();
					$scope.customChat = true;
					initCustomChat(topLevelEnterprise.customChatCode);
				} else {
					initOlarkChat();
				}

				if (topLevelEnterprise.pageTitle) {
					$scope.customTitle = topLevelEnterprise.pageTitle;
					setCustomPageTitle($scope.customTitle);
				}

				if (topLevelEnterprise.logoutRedirect) {
					IdentityFactory.setLogoutRedirect(topLevelEnterprise.logoutRedirect);
				}

				if (topLevelEnterprise.hideProfileLink) {
					$scope.hideProfileLink = topLevelEnterprise.hideProfileLink;
				}

				if (topLevelEnterprise.customUserGuide) {
					$scope.userGuide = topLevelEnterprise.customUserGuide;
				}

				if (topLevelEnterprise.addAccountDisabled) {
					$scope.addAccountDisabled = topLevelEnterprise.addAccountDisabled;
				}

				var hasCustomBlock = false;
				if (topLevelEnterprise.contactBlock && topLevelEnterprise.contactBlock.trim().length) {
					hasCustomBlock = true;
				}

				EnterpriseFactory.setContactInfo(topLevelEnterprise, hasCustomBlock);
			}
		}

		function handleUserAccess (res){
			// if (res.data && res.data.client_ip) SmartX.Router.setClientIp(res.data.client_ip);
			
			// force update
			$timeout(function() {
				Dashboard.accessGroups = res.groups;
				$scope.accessPayload = res.data;
				$scope.accessGroups = res.groups;

				Dashboard.setAccessAccounts($scope.accessPayload.accountAccess.map(function(account){
					if (!account.permission) account.permission = 'fullAccess';
					return account;
				}));

				Dashboard.setPlatform($scope.accessPayload.platform);

				// feature flags designated through the admin ui
				FeatureFlags.setUserFeatures($scope.accessPayload.feature);

				EnterpriseFactory.setEnterpriseAccess($scope.accessPayload.enterpriseAccess);
				EnterpriseFactory.setUserEnterprise($scope.accessPayload.enterprise);
				EnterpriseFactory.setEnterpriseRelationships($scope.accessPayload.enterpriseRelationships);

				// access to enterprises outside of the user's normal parent / child enterprise hierarchy AKA (cousins)
				EnterpriseFactory.setForeignEnterpriseAccess($scope.accessPayload.foreignEnterpriseAccess);

				Models.setReportedModelList($scope.accessPayload.reportedModelAccess);
				Models.setModelList($scope.accessPayload.modelAccess);

				$rootScope.$broadcast('dashboard-account-access-set', $scope.accessPayload);

				Dashboard.setUserAccounts($scope.accessPayload.accountAccess);

				TargetWeightFactory.getTargets()
				.then(function(response){
					var targets = response.data != [] ? response.data.data : response.data;
					TargetWeightFactory.setAdvisorTargets(targets);
				})
				.catch(function(err){
					console.error(err);
				});

				/* if (typeof updateTabs != 'undefined' && updateTabs)  */
				groupTabsHandlerForUser(USER.username, res);
			});
		}

		function setViewportProperties () {
			var vwidth = window.innerWidth;
			var vheight = window.innerHeight;
			var swidth = screen.width;
			var sheight = screen.height;

			try {
				amplitude.getInstance().setUserProperties({
					// viewportHeight: vheight,
					// viewportWidth: vwidth,
					// resolutionWidth: swidth,
					// resolutionHeight: sheight,
					viewport: vwidth + ' x ' + vheight,
					resolution: swidth + ' x ' + sheight
				});
			} catch (e) {
				console.error(e.message);
			}
		}

		function setUserProperties (user) {
			try {
				amplitude.getInstance().setUserProperties({
					username: user.userName,
					email: user.email,
					enterprise: _.property(['enterprise','name'])(user),
					"parent enterprise": _.property(['topLevelEnterprise', 'name'])(user),
					url: window.location.href
				});
			} catch (e) {
				console.error(e.message);
			}
		}


		function loadAccountsNeedingRebalance() {

			// $q.when(RebalancerFactory.getListOfRebalanceAccounts())
			// 	.then(function (response) {
			// 		var accounts = response.data;
			// 		$scope.numberOfAccountsToRebalance = accounts.length;
			// 		RebalancerFactory.setNumberOfAccountsToRebalance(accounts.length);
			// 		$rootScope.$broadcast('account-rebalance-set', $scope.accessPayload);
			// 	})
			// 	.catch(function (err) {
			// 		Dashboard.toastError("Failed to get accounts to rebalance", err);
			// 		$rootScope.$broadcast('account-rebalance-set');
			// 	});
		}

		function loadFundingSleeves () {

			$scope.loadingNonzeroFundingSleeves = true;   
	
			$q.when(allocationApi.getAccountsWithNonzeroFunding())
			.then(function(res){
				$scope.numberOfNonzeroFunding = _.property(['data','length'])(res);
			})
			.catch(function(err){
				// $scope.numberOfNonzeroFunding = 25;
				console.error(err);
			})
			.finally(function(){
				$scope.loadingNonzeroFundingSleeves = false;
			});
		}

		$scope.isEnabled = FeatureFlags.isEnabled;

		function setViewportProperties () {
			var vwidth = window.innerWidth;
			var vheight = window.innerHeight;
			var swidth = screen.width;
			var sheight = screen.height;

			try {
				amplitude.getInstance().setUserProperties({
					// viewportHeight: vheight,
					// viewportWidth: vwidth,
					// resolutionWidth: swidth,
					// resolutionHeight: sheight,
					viewport: vwidth + ' x ' + vheight,
					resolution: swidth + ' x ' + sheight
				});
			} catch (e) {
				console.error(e.message);
			}
		}

		function groupTabsHandlerForUser() {
			broadcastEmptyTabs();
		}

		function broadcastEmptyTabs() {
			Tabs.setTabs([]);
			$rootScope.$broadcast('dashboard-refresh-tabs');
		}

		function markAsRead(ev) {
			ev.stopPropagation();
			ev.target.parentNode.className += " done";

			return false;
		}

		function exitClientGroup() {
			$scope.isSwitchingGroups = true;

			Tabs.saveTabs();
			Dashboard.getAccountAccess()
				.then(function (result) {

					groupTabsHandlerForUser(USER.username, result);

				});
		}

		function processAccountAccess(result) {

			if (result.data.statusCode == 200) {

				Dashboard.setAccessAccounts(result.data.data.accountAccess);
				$scope.accessPayload = result.data.data;
				$rootScope.$broadcast('dashboard-account-access-set', $scope.accessPayload);

			} else {
				console.log('No Group data');
			}

			$timeout(function () {
				$scope.isSwitchingGroups = false;
			}, 1000);
		}

		function toggleClientMode() {

			$scope.showClientGroupDialog = !$scope.showClientGroupDialog;
		}

		function logEvent(event){
			try {
				amplitude.getInstance().logEvent(event);
			} catch (e) {
				console.error(e);
			}
		}

		function calculateContentOffset() {

			// debugger;
			if ($('.hamburger').css('display') == 'block') {

				// hides the side menu completely when hamburger is visible
				$menuWrap.removeClass('folded').css('margin-left', '-220px');
				$scope.menuFolded = false;
				$scope.openSub = true;

				// remove margin/padding from content
				$('body').css('margin-left', 0);
				$('#ng-dashboard, footer').css('padding-left', 0);


			} else if ($(window).width() >= 1500) {
				$menuWrap.removeClass('folded').css('margin-left', 0);
				$scope.menuFolded = false;
				$scope.openSub = true;

				$('#ng-dashboard, footer').css('padding-left', '210px');

			} else {
				$menuWrap.addClass('folded').css('margin-left', 0);
				$('#ng-dashboard, footer').css('padding-left', '60px');
				$scope.menuFolded = true;
				$scope.openSub = false;


			}
		}


		$(window).on('resize', function () {
			if ($scope.hasProfile) {
				calculateContentOffset();
			}

		});

		// $scope.startShepherdTour = function () {
		// 	var currentTab = Tabs.getActiveTab();

		// 	$window.scrollTo(0, 0)
		// 	$scope.shepherdTour = new Shepherd.Tour({
		// 		defaults: {
		// 			classes: 'shepherd-element shepherd-open shepherd-theme-arrows shepherd-theme-dark',
		// 			showCancelLink: true,
		// 			scrollTo: false
		// 		}
		// 	});

		// 	if (!$scope.Tour.inProgress()) {
		// 		switch (currentTab.type) {
		// 		case 'model':
		// 		case 'home':
		// 		case 'main':
		// 		default:
		// 			TourService.getHomeTour($scope.shepherdTour);
		// 			break;
		// 		}
		// 	} else {
		// 		SweetAlert.swal("Cancelled", "You should only take one tour at a time", "warning");
		// 	}

		// };


		collapseBtn.on('click', function () {

			$('#ng-dashboard').toggleClass('folded');
			$(this).closest('#hcv-dashboard-menu-wrap').toggleClass('folded');

			if ($('#hcv-dashboard-menu-wrap').hasClass('folded')) {
				$timeout(function(){
					$scope.menuFolded = true;
					if ($scope.openSub) {
						$scope.toggleSubmenu(); // auto-close submenu (if open) when side menu is folded
					}
				});
			} else {
				$scope.menuFolded = false;
			}
		});

		$scope.toggleSubmenu = function () {
			$scope.openSub = !$scope.openSub;

			if ($scope.openSub) {

				if ($scope.menuFolded) {
					$scope.foldMenu();
				}

			}
		};

		$scope.foldMenu = function () {

			$('#ng-dashboard').toggleClass('folded');
			$('#hcv-dashboard-menu-wrap').toggleClass('folded');

			if ($('#hcv-dashboard-menu-wrap').hasClass('folded')) {
				$scope.menuFolded = true;
			} else {
				$scope.menuFolded = false;
			}
		};

		$scope.slideOut = function () {
			if ($('.hamburger').is(':visible')) {
				var leftMargin = jQuery('#hcv-dashboard-menu-wrap').css('margin-left');
				var menuWidth = jQuery('#hcv-dashboard-menu-wrap').width();

				if (parseInt(leftMargin, 10) !== 0) {
					// slide out the menu and add left margin to the body
					jQuery('#hcv-dashboard-menu-wrap').css('margin-left', 0);
					jQuery('.icon-bar').addClass('menu-open');
					jQuery("body").css("margin-left", jQuery('#hcv-dashboard-menu-wrap').width() + "px");
					$scope.menuOpen = true;

				} else {
					// slide out the menu and subtract the menu width from the body margin
					jQuery('#hcv-dashboard-menu-wrap').css('margin-left', -1 * (menuWidth + 10) + 'px');
					jQuery('.icon-bar').removeClass('menu-open');
					jQuery("body").css("margin-left", 0);
					$scope.menuOpen = false;

				}
			}

		};

		$scope.logout = function () {
			IdentityFactory.logout();
		};

		$scope.addTab = function (type, data) {

			var tab = Tabs.addTab(type, null, null, data);

			if (tab) {
				tab.new = true;

				if (type === 'holdings') {

					// update the selected account in the holdings view
					Dashboard.notifyObservers('main');
				}

				$scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);
			}

			logEvent("sideNav:"+type);

			return tab;
		};
		$scope.sampleItem = {
			msg: 'Allocated $100,000 to Pawley\'s Growth 2x Leverage',
			timestamp: 1472149376601
		};

		// this causes the timeFromNow filter to rerun so that the 'time ago' is always based on the current time
		$scope.updateCurrentTime = function () {
			$scope.currentTime = new Date();
		};

		$scope.viewUserGuide = function (){
			logEvent("sideNav:viewUserGuide");
		};

	})
	.directive('onOutsideClick', function ($document, $timeout) {
		return {
			restrict: 'A',
			link: function ($scope, element, attr) {

				angular.element($document).on('click', function (event) {

					// ignore this if hamburger menu is visible
					if (!jQuery('.hamburger').is(':visible')) {

						// check to see if the event target is a child element of the side menu
						var isClickedElementChildOfSideMenu = element
							.find(event.target)
							.length > 0;

						// if so, do nothing
						if (isClickedElementChildOfSideMenu || element[0] === event.target)
							return;

						if ($scope.$eval(attr.isOpen)) {
							$scope.$eval(attr.onOutsideClick);
							if ($scope.openSub) {

								// wrappped with timeout to stop a digest error when opening the exclusions tab for the first time after logging in
								$timeout(function(){
									$scope.toggleSubmenu(); // auto-close submenu (if open) when side menu is folded
								},1);
							}
						}
					}
				});
			}
		};
	});
