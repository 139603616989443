angular
	.module('ProtectedPortfolio', [])
	.controller('ProtectedPortfolioCtrl', function($scope, Dashboard, SmartXFactory){

		var vm = this;
		var parentTab = $scope.tab;

    console.log(parentTab);
    
    vm.accounts = Dashboard.getUserAccounts();

		vm.setAccount = setAccount;
		$scope.th_account = {
			selected: undefined
		};

		init();

		function init(){
      vm.allocationId = parentTab.data.allocation.id;
      vm.selectedAccount = parentTab.data.allocation.account;
		}

		function setAccount(account){
			vm.selectedAccount = account;
			$scope.$broadcast('account-changed', vm.selectedAccount);
		}

		$scope.$on('change-editor-account', function(event, account){
			setAccount(account);
		});


		$scope.th_select_account = function($item, $model, $label, $event){

	        console.log($item);
	        console.log($model);
	        console.log($label);

	        setAccount($item);
	    };
  })
  .directive('protectedPortfolio', protectedPortfolio);

  function protectedPortfolio ($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('protected-positions.tpl.html'),
      scope: {},
      controller: ProtectedPortfolioCtrl,
      controllerAs: 'vm',
      bindToController: {
        allocationId: '='
      }
    };
  }

  function ProtectedPortfolioCtrl($timeout, $q, NgTableParams, PortfolioEditorFactory, $scope, $uibModal, toastr, SweetAlert, $filter, Allocations, SmartXFactory, Dashboard) {
    var vm = this;
    var tableConfig = {
      count: 10,
    };

    var protectedPositionsTableConfig = {
      sorting: {
        requestTimestamp: "desc"
      }
    };

    //methods
    vm.refresh = refresh;

    // props
    vm.adjustments = [];
    vm.lmv = 0;
    vm.smv = 0;
    vm.netExp = 0;
    vm.grossExp = 0;
    vm.showPositionFilters = false;

    vm.getCurrentTotalAllocated = function() {

      var data = vm.holdings;
      if (!data || !data.length) {
        vm.lmv = vm.smv = vm.grossExp = vm.netExp = 0;
        return;
      }

      var total = 0,
        grossExp = 0,
        netExp = 0,
        lmv = 0,
        smv = 0,
        pendingChanges = [];

      for (var i = 0; i < data.length; i++) {

        var item = data[i];

        if (typeof item == 'undefined' && (typeof item.value == 'undefined' || item.value == null)) {
          continue;
        }

        var val = parseFloat(item.percentOfPortfolio * 100);

        // use mathjs version of sign method to support IE
        val = val.toFixed(2);
        if (math.sign(val) > 0) {
          lmv += (val / 100);
        } else if (math.sign(val) < 0) {
          smv += (val / 100);
        }

        total += Math.abs(parseFloat(val));
      }

      grossExp = lmv - smv;
      netExp = lmv + smv;

      console.log(lmv, smv, grossExp, netExp);

      // to round or not to round
      vm.lmv = lmv; //.toFixed(3);
      vm.smv = smv; //.toFixed(3);
      vm.grossExp = grossExp; //.toFixed(3);
      vm.netExp = netExp; //.toFixed(3);

      total = Number(parseFloat(total).toFixed(2));

      vm.currentTotalAllocated = parseFloat(total); //.toFixed(2);
    };

    init();

    function init() {

      //var portfolioId = vm.selectedAccount.id;
      var protectedAllocationId = vm.allocationId;
      getPortfolio(protectedAllocationId);
    }

    function refresh(){
      $scope.$broadcast('account-changed', vm.selectedAccount);
    }

    function getPortfolio(protectedAllocationId) {

      vm.loadingPortfolio = true;

      Allocations.getHoldings(protectedAllocationId).then(handlePortfolioResponse)
        .catch(function(err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          vm.loadingPortfolio = false;
          $scope.$apply();
        });
    }

    function getProtectedAllocationId (account){
      var protectedAllocation = _.find(account.allocations, function(allocation) { return allocation.type === 'protected';});
      if (protectedAllocation) {
        return protectedAllocation.id;
      } else {
        return null;
      }
    }

    $scope.$on('account-changed', function(event, account) {

      vm.loadingPortfolio = true;

      // Get account allocations
      SmartXFactory.getAllocationAPI().getAllAllocations({accountId: account.id, status: 'open'})
      .then(function(res){
        account.allocations = res.data;
        var protectedAllocationId = getProtectedAllocationId(account);
        if (protectedAllocationId) {
          getPortfolio(protectedAllocationId);
        } else {
          $timeout(function() {
            vm.holdings = [];
            // vm.totalPortfolioValue = 0
            vm.accountAvailableCash = 0;
            vm.equity = 0;
            vm.getCurrentTotalAllocated();
            vm.loadingPortfolio = false;
            vm.tableParams = new NgTableParams(protectedPositionsTableConfig, {
              dataset: vm.holdings
            });
          });
        }
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
      });   
    });

    function handlePortfolioResponse(response) {
      console.log("Get portfolio response:", response);
      debugger;

      $timeout(function(){
        vm.loadingPortfolio = false;
      });

      if (response.data.statusCode !== 200) {
        // var msg = response.data.msg[0] ? response.data.msg[0].description : 'An unexpected error occurred. Try again.';
        // toastr.error(msg);
      }
      // vm.totalPortfolioValue = _.reduce(response.data.positions, function(total, position) { return total += position.value }, response.data.equity)

      var holdings = _.map(response.data.positions, function(holding) {
        holding.value = Number(holding.value);
        holding.percentOfPortfolio = (holding.value / response.data.equity);
        // holding.newValue = '';
        // holding.value = holding.price * holding.quantity;
        //delete holding.symboindustry;

        return holding;
      });

      vm.accountAvailableCash = response.data.cash;
      vm.equity = response.data.equity;

      var cashPosition = {
        ticker: '$CASH',
        value: vm.accountAvailableCash,
        percentOfPortfolio: (vm.accountAvailableCash / vm.equity),
        assetType: '--'
      };

      vm.holdings = _.sortBy(holdings, 'value').reverse();

      vm.holdings.unshift(cashPosition);

      // vm.totalPortfolioValue = _.reduce(holdings, function(total, position) { return total += position.value }, 0)

      // vm.getCurrentTotalAllocated();

      vm.originalHoldings = angular.copy(holdings);

      vm.tableParams = new NgTableParams(protectedPositionsTableConfig, {
        dataset: vm.holdings
      });
    }
  }