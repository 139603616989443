angular.module('Rebalancer',  [])
.controller('RebalanceAccountListCtrl', function($rootScope, $scope, SmartXFactory, Tabs){
	var vm = this;
	
	vm.token = SmartXFactory.getToken();
	vm.env = SmartXFactory.getEnv();

	$scope.$on('smx-token-updated', function(e, token) {
		vm.token = token;
	});

	$scope.$on('$destroy', function() {
		document.removeEventListener('rebalancer:showResults', showResultsHandler);
	});

	init();

	function init(){
		document.addEventListener('rebalancer:showResults', showResultsHandler);
	}

	function showResultsHandler (e) {
		Tabs.addTab('rebalanceRequestDetails', null, null, e.detail);
		$scope.$apply();
	}
})
.controller('RebalanceAccountListCtrlV2', function($scope, SmartXFactory){
	var vm = this;
	var token = SmartXFactory.getToken();
	
	vm.token = token;

	$scope.$on('smx-token-updated', function(token) {
		vm.token = token;
	});
	
	init();

	function init(){}
});