var Liquidation = angular.module('LiquidationCtrl', []);
Liquidation.controller("LiquidationCtrl", function(AcrDisclaimer, $scope, $http, $location, Models, $timeout, SweetAlert, Dashboard, Liquidations, toastr, SmartXFactory) {

    // for loading the last selected account / model from localStorage
    // var lastAccount = JSON.parse(localStorage.getItem('LiquidationLastSelectedAccount')),
    //     lastModel = JSON.parse(localStorage.getItem('LiquidationLastSelectedModel')),
    //     currentAccount = lastAccount ? lastAccount : Liquidations.currentAccount,
    //     currentModel = lastModel ? lastModel : Liquidations.currentModel;

    var accountApi = SmartXFactory.getAccountAPI();
    var allocationApi = SmartXFactory.getAllocationAPI();
    var modelApi = SmartXFactory.getModelAPI();

    console.log(AcrDisclaimer);

    function init(){
        var accounts = SmartXFactory.accounts || [];
        var activeAccount = Dashboard.selectedLiquidationAccount || Dashboard.getActiveAccount() || accounts[0];
        var currentAccount = activeAccount ? activeAccount : $scope.defaultAccount,
            currentModel = Liquidations.currentModel;

        var updateLiquidationCallback = {
            name : 'updateLiquidationAccount',
            fn : updateLiquidationAccount
        };

        $scope.accounts = SmartXFactory.accounts ? SmartXFactory.accounts.filter(function(account) { return account.status === 'active' && account.permission == 'fullAccess';}) : [];
        $scope.hasError = false;
        $scope.liquidationAmounts = Liquidations.defaultPercentages;
        $scope.selectedLiquidationAmount = '';
        $scope.customLiquidationSelected = false;
        $scope.customLiquidationAmount = '';
        $scope.errorFetchingAccountModels = false;
        $scope.viewOnlyAccount = false;
        $scope.liquidationError = null;

        Liquidations.currentModel = Dashboard.selectedLiquidationModel ? Dashboard.selectedLiquidationModel : currentModel;

        // if the tab was initialized from clicking liquidate from the main view
        if (Dashboard.selectedLiquidationModel){
            if (angular.isDefined(currentAccount) && typeof currentAccount/*.accountInfo*/ !== 'undefined'){
                currentModel = Dashboard.selectedLiquidationModel;
                currentAccount = Dashboard.selectedLiquidationAccount;
            } else {
                currentAccount = Dashboard.selectedLiquidationAccount;
            }
        }

        // only pre-load an account if it has fullAccess
        if (currentAccount && (currentAccount.permission == 'fullAccess' || currentAccount.permission == 'accountMaintenance')){
            $scope.selectedAccount = currentAccount;
        } else if (!angular.isDefined($scope.selectedAccount)) {
            var account = Dashboard.currentAccount ? Dashboard.currentAccount : $scope.defaultAccount;
            $scope.selectedAccount = account;
            currentAccount = $scope.selectedAccount;
        }

        Dashboard.registerObserverCallback(updateLiquidationCallback);
        getData(currentAccount, currentModel);
    }

    // set up initial values and get some data
    init();

    /* methods called from the template */

    $scope.setAccount = function(account, guid){
        $scope.selectedAccount = account;
        Dashboard.setActiveAccount($scope.selectedAccount);
        changeAccount();
    };


    $scope.onSelectAllocation = function(guid, model){
        model.guid = guid;
        $scope.selectedAllocation = model;

        changeModel();
    };

    $scope.setLiquidationAmount = function(val){

        // Clear out custom liquidation amount
        if( $scope.customLiquidationAmount !== '' ){

            $scope.customLiquidationAmount = '';

        }

        $scope.customLiquidationSelected = false;
        $scope.selectedLiquidationPercentage = val.percentage? val.percentage : val;

        angular.forEach($scope.liquidationAmounts, function(amount, key) {

            if(amount.percentage == $scope.selectedLiquidationPercentage) {

                amount.selected = true;
                amount.value = $scope.selectedAllocationNetLiq * (amount.percentage / 100);
                $scope.selectedLiquidationAmount = amount.value;

            } else {
                amount.selected = false;
            }

        });

        return $scope.selectedLiquidationAmount;
    };

    $scope.validateCustomLiquidationAmount = function()
    {

        $scope.customLiquidationAmount = parseFloat($scope.customLiquidationAmount);
        if(!isNaN($scope.customLiquidationAmount) && angular.isNumber($scope.customLiquidationAmount)){
            $scope.liquidationError = null;
        }else{
            $scope.liquidationError = 'Your custom liquidation amount is not a valid number.';
            $scope.$broadcast('invalidCustomLiquidationAmount');
        }
    };

    $scope.selectCustomTypeFor = function(type){
        // set all presets to false when custom is selected
        angular.forEach($scope.liquidationAmounts, function(amount){
            amount.selected = false;
        });

        $scope.customLiquidationSelected = true;
    };

    function calculateTargetEquity (redemptionPercentage, allocationValue) {
        if (!allocationValue || isNaN(allocationValue)) {
            var msg = 'Unable to calculate target equity from current sleeve equity.  Sleeve equity: ' + (angular.isDefined(allocationValue) ? allocationValue : 'Unknown');
            SweetAlert.swal("Error", msg, "error");
            throw new Error(msg);
        }
        var dollarAmount = allocationValue * (1 - redemptionPercentage);
        return dollarAmount;
    }

    $scope.liquidateModel = function(){

        if ($scope.selectedAccount && $scope.selectedAllocation){
            if ($scope.selectedAccount.id !== $scope.selectedAllocation.account.id){
                Dashboard.toastError('Account does not have access to this sleeve. Please exit the liquidation tab and try again.');
                return false;
            }
        }


        // Catch if custom input is valid
        if( $scope.customLiquidationSelected 
            && (typeof $scope.customLiquidationAmount == 'undefined' 
                || $scope.customLiquidationAmount == null  
                || $scope.customLiquidationAmount == "" 
                || isNaN($scope.customLiquidationAmount)) 
                || $scope.customLiquidationAmount < 0
                || $scope.customLiquidationAmount > 100)
        {
            SweetAlert.swal("Error", "Please enter a valid percentage.", "error");
            return false;
        }

        // v2
        $scope.selectedAllocationNetLiq = $scope.selectedAllocation.value;

        var liquidationAmount = $scope.customLiquidationSelected ? (Number($scope.customLiquidationAmount) / 100) * $scope.selectedAllocationNetLiq : $scope.setLiquidationAmount($scope.selectedLiquidationPercentage),
            liquidationPercentage = $scope.customLiquidationSelected ? $scope.customLiquidationAmount : $scope.selectedLiquidationPercentage;

        $scope.selectedLiquidationPercentage = liquidationPercentage;
        console.log("percentage: " + liquidationPercentage + ', amount: ' + liquidationAmount);

        var allocationId = $scope.selectedAllocation.id;

        var targetEquity = calculateTargetEquity(liquidationPercentage / 100, _.property(['statistics', 'value'])($scope.selectedAllocation));

        SweetAlert.swal({
           title: "Are you sure you want to liquidate "+ liquidationPercentage +"% from "+$scope.selectedAllocation.model.name +"?",
           text: AcrDisclaimer,
           type: "warning",
           html: true,
           showCancelButton: true,
           confirmButtonColor: "#DD6B55",
           confirmButtonText: "Yes",
           cancelButtonText: "No",
           closeOnConfirm: false,
           closeOnCancel: true,
           showLoaderOnConfirm: true,

           allowEscapeKey: true,
        },
        function(isConfirm){
            if (isConfirm){
                var liquidationAmount = $scope.selectedLiquidationPercentage > 100 ? 100 : $scope.selectedLiquidationPercentage;

                $scope.processingLiquidation = true;
                // allocationApi.redeem(allocationId, {amount: parseFloat(liquidationPercentage/100) })
                allocationApi.updateTargetEquity(allocationId, targetEquity)
                .then(function(result){

                    console.log("Redemption result: ", result);
                    var errors = [];
                    
                    if (result.statusCode == 200 && errors.length == 0){
                        SweetAlert.swal({
                            title : liquidationPercentage +"% was liquidated from your allocation to " + $scope.selectedAllocation.model.name +".",
                            type : "success"
                        });

                        resetLiquidationAmounts();
                    } else {
                        var msg = "Liquidation failed for model "+$scope.selectedAllocation.model.name +".";
                        if (errors.length > 0){
                            msg = msg + ' ' + errors.join();
                        }
                        SweetAlert.swal({
                            title : msg,
                            type : "warning"
                        });
                        console.log('Uh oh!! Could not sync:(');
                    }
                })
                .catch(function(err){
                    console.error(err);
                    SweetAlert.swal({
                        title: '',
                        text : err.message,
                        type : "warning"
                    });
                })
                .finally(function(){
                    $timeout(function(){
                        $scope.processingLiquidation = false;
                    });
                });

            } else {
                jQuery('.sweet-alert').remove();
            }
        });
    };

    /* end methods called by template */

    /* helper methods */

    function setMinimumInvestment(model){
        $scope.minimumInvestment = (model && model.minimumAllocation) ? (Models.getMinimumById(model.id) || model.minimumAllocation) : 'N/A';
    }

    function changeAccount(updateSelectedModel){

        var accountID = $scope.selectedAccount.id,
            title = $scope.selectedAccount.name;

        Liquidations.currentAccount = $scope.selectedAccount;

        console.log(Liquidations.currentAccount);

        if (Dashboard.cachedAccountData[accountID]){

        } else {

            $scope.loadingAllocations = true;
            allocationApi.getInflatedAllocations({accountid: $scope.selectedAccount.id, status: 'open'})
            .then(function(res){
                console.log("Allocations Response: ", res);

                var allocations = res.data;

                $scope.selectedAccount.allocations = allocations.filter(function(allocation){
                    return allocation.status === 'open' && allocation.model;
                });

                if ($scope.selectedAccount.allocations.length){
                    $scope.noAllocationsForActiveAccount = false;
                    $scope.selectedAllocation = $scope.selectedAccount.allocations[0];

                    loadMinAllocation($scope.selectedAllocation.model.id);

                } else {
                    toastr.info('This account has no active allocations', 'Info');
                    $scope.noAllocationsForActiveAccount = true;
                }

                if (updateSelectedModel) updateSelectedModel();
            })
            .catch(function(res){
                $scope.loadingAllocations = false;
                $scope.noAllocationsForActiveAccount = true;
            })
            .finally(function(){
                $timeout(function(){
                    $scope.loadingAllocations = false;
                });
            });
        }
    }

    function changeModel(){

        // fall back to first allocation in the list if selectedAlloc not found
        Liquidations.currentModel = $scope.selectedAllocation ? $scope.selectedAllocation.model : $scope.selectedAccount.allocations[0].model;
        $scope.selectedAllocation = $scope.selectedAllocation || $scope.selectedAccount.allocations[0];
        $scope.loadingMin = true;
        modelApi.getModelById(Liquidations.currentModel.id)
        .then(function(model){
             $timeout(function(){
                setMinimumInvestment(model);
            });
        })
        .catch(function(err){
            // toastr.error(err.message);
            // console.error(err);
            Dashboard.toastError(err.message, err);
        })
        .finally(function(){
            $timeout(function(){
                $scope.loadingMin = false;
            });
        });

    }

    function resetLiquidationAmounts(){
        $scope.customLiquidationSelected = false;
        angular.forEach($scope.liquidationAmounts, function(amount){
            amount.selected = false;
        });
        $scope.selectedLiquidationPercentage = $scope.customLiquidationAmount = $scope.selectedLiquidationAmount = undefined;
    }

    // function lookUpAccount(currentAccount){
    //     var selected = $scope.accounts.filter(function(account){
    //         return currentAccount.accountInfo.nid === account.accountInfo.nid;
    //     });

    //     return selected[0];
    // }

    // load initial account data
    function getData(currentAccount, currentModel){

        var accountID,
            account,
            hcv_models,
            modelIDs;

        if ( angular.isDefined(currentAccount) ){
            $scope.selectedAccount = currentAccount ? currentAccount : $scope.defaultAccount;
        } else if (!angular.isDefined($scope.selectedAccount)) {
            $scope.selectedAccount = Dashboard.currentAccount ? Dashboard.currentAccount : $scope.defaultAccount;
        }

        if(typeof $scope.selectedAccount != 'undefined'){

            accountID = $scope.selectedAccount.id;

            // enter here if we've already received model data this session
            if (Dashboard.cachedAccountData[accountID]){

                $scope.hcv_models = Dashboard.cachedAccountData[accountID].models;

                if($scope.hcv_models.length>0){

                    $scope.errorFetchingAccountModels = false;
                    $scope.statistics = Dashboard.cachedAccountData[accountID].statistics;
                    $scope.selectedAllocation = typeof getCurrentModel(currentModel) !== 'undefined'? getCurrentModel(currentModel) : $scope.hcv_models[0];


                    Dashboard.getAllocations(accountID)
                    .then(function(res){
                        $scope.allocations = res.data.allocations;
                        try {
                            rid = $scope.selectedAllocation.field_fund_data_id.und[0].value;
                        } catch (e){
                            $scope.selectedAllocation = $scope.hcv_models[0];
                            rid = $scope.selectedAllocation.field_fund_data_id.und[0].value;
                        }
                        $scope.modelValues = res.data.breakdowns;

                        $scope.selectedAllocationNetLiq = $scope.modelValues[rid].netLiquidationValue;
                        setMinimumInvestment();
                    })
                    .catch(function(res){
                        $scope.loading = false;
                        $scope.errorFetchingAccountModels = true;
                    });
                } else {
                    $scope.errorFetchingAccountModels = true;
                }

            } else {

                if ($scope.selectedAccount.allocations && $scope.selectedAccount.allocations.length){
                    var defaultModel = $scope.selectedAccount.allocations[0];
                    $scope.accountAllocations = $scope.selectedAccount.allocations.filter(function(allocation){ return allocation.type == 'model';});
                    $scope.selectedAllocation = Dashboard.selectedLiquidationModel || defaultModel;
                    
                    if ($scope.selectedAllocation.model) loadMinAllocation($scope.selectedAllocation.model.id);

                } else {
                    changeAccount();
                }
            }
        }/* else{
            $scope.hasError = true;
            $scope.errorHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No account found.<br/>First <a href="#" ng-click="addTab(\'open-brokerage-account\');">create an account</a> to proceed.</h3></div>';
        } */

    }

    function loadMinAllocation(id){
        $scope.loadingMin = true;
        modelApi.getModelById($scope.selectedAllocation.model.id)
        .then(function(model){
            $timeout(function(){
                setMinimumInvestment(model);
            });
        })
        .catch(function(err){
            // toastr.error(err.message);
            // console.error(err);
            Dashboard.toastError(err.message, err);
        })
        .finally(function(){
            $timeout(function(){
                $scope.loadingMin = false;
            });
        });
    }

    function compareTitle(a,b) {
        if (a.title.trim() < b.title.trim())
            return -1;
        if (a.title.trim() > b.title.trim())
            return 1;
        return 0;
    }

    function getCurrentModel(currentModel){
        console.log("getting current model");

        var model;
        if (currentModel){
            if (typeof currentModel.index !== 'undefined'){
                model = $scope.hcv_models[currentModel.index];

                return model;
            } else {

                return currentModel;
            }
        } else {
            return $scope.hcv_models[0];
        }
    }



    function updateSelectedModel(origin){

        // only set the allocation if the selected account id matches the account id of the allocation
        if (Dashboard.selectedLiquidationModel){
            var liquidationModelAccount = Dashboard.selectedLiquidationModel.account;
            if (liquidationModelAccount.id === $scope.selectedAccount.id) {
                $scope.selectedAllocation = Dashboard.selectedLiquidationModel;
                Liquidations.currentModel = _.property(["model"])($scope.selectedAllocation);
            }
        }

        changeModel();
    }

    function updateLiquidationAccount(origin, models){

        console.log("liquidation account update initialized from ", origin);

        if (origin === 'main-liquidate' || origin === 'accountSummary-liquidate'){

            // if selected account doesn't match selected account from main
            // set it and then update the model
            // otherwise update the model only
            if (angular.isDefined(models) && models.length){

                hcv_models = [];

                for (var model in models){
                    hcv_models.push(models[model].fund_profile);
                }
                //console.log(hcv_models);
                $scope.hcv_models = hcv_models;

            }

            if (origin === 'accountSummary-liquidate'){
                Dashboard.selectedLiquidationModel = null;
            }

            if ($scope.selectedAccount !== Dashboard.selectedLiquidationAccount) {
                $scope.selectedAccount = Dashboard.selectedLiquidationAccount || Dashboard.currentAccount;
                changeAccount(updateSelectedModel);
            } else {
                updateSelectedModel();
            }


        }

    }

    function getModelIndex(){

        var model = $scope.hcv_models
                .map(function(model) {
                    return model.nid;
                }).indexOf($scope.selectedAllocation.nid);
                //
        return model;
    }

    function lockViewOnlyAccounts(){

        $scope.viewOnlyAccount = false;
        if( typeof $scope.selectedAccount.accountInfo.field_is_viewer.und != 'undefined' && parseInt($scope.selectedAccount.accountInfo.field_is_viewer.und[0].value) === 1 ){
            console.log('warning!! view only account', $scope.selectedAccount.accountInfo.field_is_viewer.und);

            $scope.loading = false;
            $scope.viewOnlyAccount = true;

            return true;
        }
        return false;
    }


	window.onload = function(){
	    jQuery('#zone-branding-wrapper').addClass('container');

	    // if loader is still showing after 5 seconds
	    // hide and show connection error
	    jQuery('.m-app-loading').fadeOut();
	};

    /* end helper methods */

    /* events */

    $scope.$on('$destroy', function(){
        var callbacks = Dashboard.getObserverCallbacks();

        // unregister any functions that were pushed in to the observer callback array
        // otherwise the function is added to the array with every new tab instance
        // which leads to calling the registered function multiple times
        // could also check to see if the function is in the array before it is registered
        // that would prevent registering the same function multiple times
        callbacks.forEach(function(cb){
            console.log(cb);
            if (cb.name === 'updateLiquidationAccount'){
                Dashboard.removeObserverCallback(cb);
            }
        });
    });

    /* end events */

})
.filter('allocated', function(){

    return function(model, ids){
        var nid = model.nid;
        if (ids.indexOf(nid) !== -1){
            return model;
        }

    };
})
.directive('focusOn', function() {
   return function(scope, elem, attr) {
      scope.$on(attr.focusOn, function(e) {
          elem[0].focus();
      });
   };
});

Number.prototype.formatMoney = function(c, d, t){
var n = this,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

