angular.module('Rebalancer')
	.filter('formatStatus', function () {
		return function (input) {

			try {
				//split camelCase status into words
				var words = input.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1);

				// capitalize first letter of first word
				words[0] = words[0].charAt(0).toUpperCase() + words[0].substr(1);

				var formattedStatus = words.join(' ');

				return formattedStatus;
			} catch (e) {
				console.error(e);
				return input;
			}


		};
	})
	.directive('rebalanceAccountListByInvestmentV3', ['$templateCache', 'RebalancerFactory', function ($templateCache, RebalancerFactory) {
		return {
			restrict: 'E',
			scope: {
				models: '=',
				accountsList: '='
			},
			template: $templateCache.get('rebalanceAccountsByInvestmentV3.tpl.html'),
			controller: rebalanceAccounts,
			controllerAs: 'vm',
			bindToController: true
		};

		function rebalanceAccounts(SmartXFactory, $scope, $rootScope, $modal, Dashboard, NgTableParams, Tabs, $filter, toastr, $uibModal, $timeout, $q) {
			var vm = this;
			var accountApi = SmartXFactory.getAccountAPI();

			vm.targets = [];
			vm.isFiltersVisible = true;
			vm.statuses = ['Active', 'Inactive', 'Pending'];

			vm.rowCount = "10";

			vm.errorMsg;
			vm.submitChanges = submitChanges;

			vm.rebalRequestProgress = 0;

			vm.accessAccounts = Dashboard.getAccessAccounts();
			vm.accessAccountNames = Object.keys(vm.accessAccounts);

			vm.toggleFilters = toggleFilters;
			vm.processingRebalance = false;
			vm.showingExtraColumns = false;
			vm.selectedRebalanceLogic = "bestFit"; // default trade logic

			vm.showActiveRequests = false;

			vm.requestsCollapsed = true;
			vm.unbalancedCollapsed = true;
			vm.completedCollapsed = true;
			vm.checkingRebalanceStatuses = false;

			vm.searchText = '';

			vm.completedRequestStatuses = [{
				id: '',
				title: "- Select Status -"
			}, {
				id: 'complete',
				title: "Complete"
			}, {
				id: 'failed',
				title: "Failed"
			}, {
				id: 'completeWithErrors',
				title: "Complete With Errors"
			}, {
				id: 'expired',
				title: "Expired"
			}, {
				id: 'cancelled',
				title: "Cancelled"
			}];

			vm.requestedByFilterValues = [{
				id: '',
				title: "- Select User -"
			}];

			
			document.addEventListener('refreshRebalancer', function () {
				vm.refresh();
			});

			function completedRequestsPaged() {
				// ENABLE THESE LINES TO RETAIN HISTORY TABLE FILTERS UPON REFRESH
				// if (vm.completedPagedParams){
				// 	vm.completedPagedParams.reload();
				// 	return;
				// }

				vm.completedPagedParams = new NgTableParams({
					page: 1,
					count: 25,
					sorting: {
						'requestDate': 'desc'
					},
					filter: {
						'status': '',
						'requestedBy': ''
					}
				}, {
					getData: function (params) {
						vm.loadingHistory = true;

						var page = params.page();
						var size = params.count();
						var sorting = params.sorting();
						var filter = params.filter();
						var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
						var direction = _.values(sorting).length ? _.values(sorting)[0] : '';

						var query = {
							page: page,
							pageSize: size,
							sort: sort,
							sortDirection: direction
						};

						_.extend(query, filter);

						// strip fields with null/undefined or empty string values
						query = _.pick(query, function (v) {
							return v && v !== '';
						});

						console.log("Search Params: ", query);

						return $q.when(RebalancerFactory.getRebalanceHistory(query))
							.then(function (res) {
								params.total(res.data.totalRecordCount);
								var completedRequests = res.data.results;
								return completedRequests;
							})
							.catch(function (err) {
								Dashboard.toastError(err.message, err);
								$q.reject();
							})
							.finally(function () {
								vm.loadingHistory = false;
							});
					},

				});
			}

			function populateUserFilter() {
				$q.when(RebalancerFactory.getRebalanceHistoryUsers())
					.then(function (res) {
						var historyUsers = res.data.requestedBys.sort(function (a, b) {
							return a.toLowerCase().localeCompare(b.toLowerCase());
						});
						if (vm.requestedByFilterValues.length == 1) {
							historyUsers.forEach(function (requestedBy) {
								vm.requestedByFilterValues.push({
									id: requestedBy,
									title: requestedBy
								});
							});
						}
					});
			}

			var workingStatuses = ['pending', 'working', /* 'rebalancing'*/];

			// stop the recursive checkActiveStatuses function when the tab is closed
			$scope.$on('$destroy', function () {
				vm.checkingRebalanceStatuses = false;
			});

			vm.targetsFilter = {
				'targetName': {
					id: 'select',
					placeholder: 'Select Target'
				}
			};

			vm.targetOptions = [{
				id: '',
				title: 'All'
			}];

			$scope.$watch('vm.searchText', function (newVal) {
				if (vm.tableParams) {
					var currentFilter = vm.tableParams.filter();
					vm.tableParams.filter({ $: newVal, targetName: currentFilter.targetName });
				}
			});

			init();

			function init() {

				vm.loading = true;
				vm.loadingHistory = true;
				vm.loadingActive = true;
				vm.completedCollapsed = true;
				vm.requestsCollapsed = true;

				vm.checkingRebalanceStatuses = false;

				completedRequestsPaged();
				populateUserFilter();

				return $q.all([RebalancerFactory.getListOfRebalanceAccounts(), RebalancerFactory.getRebalanceActiveRequests()])
					.then(function (response) {

						console.log("rebalance request res: ", response);

						var needsRebalance = response[0].data;
						var activeRebalanceRequests = response[1].data;
						var targets = [];

						if (response[0].statusCode !== 200) {
							var msg = response.data.msg[0] ? response.data.msg[0].description : 'An unexpected error occurred. Try again.';
							Dashboard.toastError(msg);
							return;
						}

						vm.accountsNeedRebalance = needsRebalance.map(function (account) {
							account.timestamp = account.neededSince.timestamp;
							account.name = account.account.name;
							try {
								try {
									account.brokerName = $filter('formatBrokerName')(account.account.brokerage ? account.account.brokerage.name : (account.account.custodian ? account.account.custodian.name : ''));
								} catch (e) {
									console.error(e);
								}

								var targetIds = _.map(vm.targetOptions.concat(targets), 'id');

								if (!_.contains(targetIds, account.targetName)) {
									if (account.targetName === 'No Target') {
										vm.targetOptions.push({
											id: account.targetName,
											title: account.targetName
										});
									} else {
										targets.push({
											id: account.targetName,
											title: account.targetName
										});
									}
								}
							}
							catch (e) {
								console.error("Account Targets not found for ", account.account.brokerageAccountNumber);
							}
							return account;
						});

						vm.updateSelectedRows();

						var sortedTargets = _.sortBy(targets, 'title');

						sortedTargets.forEach(function (target) {
							vm.targetOptions.push(target);
						});

						RebalancerFactory.setNumberOfAccountsToRebalance(needsRebalance.length);

						vm.unbalancedCollapsed = needsRebalance.length ? false : true;

						if (!activeRebalanceRequests.length) {
							vm.requestsCollapsed = true;
						}

						vm.tableParams = new NgTableParams({
							count: vm.rowCount,
							filter: { '$': vm.searchText, targetName: '' },
						}, {
							total: vm.accountsNeedRebalance.length,
							getData: function (params) {

								var dataset;
								var results;

								dataset = params.filter() ? $filter('filter')(vm.accountsNeedRebalance, params.filter()) : vm.accountsNeedRebalance;

								// sort before slicing for main-3633
								dataset = params.sorting() ? $filter('orderBy')(dataset, params.orderBy()) : dataset;

								params.total(dataset.length);
								vm.filteredData = dataset;
								results = dataset.slice((params.page() - 1) * params.count(), params.page() * params.count());
								return results;
							}
						});

						vm.tableParams.settings({
							counts: (vm.accountsNeedRebalance.length > 10) ? [10, 25, 50, 100] : []
						});
						vm.tableParams.reload();

						vm.inProcessParams = new NgTableParams({}, { counts: [], dataset: activeRebalanceRequests });

						if (activeRebalanceRequests.length) {

							// don't start it again if we're already checking
							if (!vm.checkingRebalanceStatuses) {
								vm.checkingRebalanceStatuses = true;
								checkActiveStatuses();
							}
						} else {
							vm.checkingRebalanceStatuses = false;
						}

						return null;
					})
					.catch(function (err) {
						Dashboard.toastError(err.message, err);
						return Promise.reject(err);
					})
					.finally(function () {
						$timeout(function () {
							vm.loading = false;
							vm.loadingActive = false;
							$rootScope.$broadcast('dashboard-check-rebalance');
						});
					});
			}

			vm.refresh = init;

			vm.updateRowCount = function (count) {
				vm.tableParams.count(count);
			};

			vm.showErrors = function (request) {
				vm.errors = [];
				request.accounts.forEach(function (account) {
					var errMsg = account.message.length ? account.message.split('. ').join('\n') : '';
					var error = {
						account: account.name,
						msg: errMsg.length ? errMsg : 'No Message Returned.'
					};
					vm.errors.push(error);
				});

				var modalInstance = $modal.open({
					animation: true,
					templateUrl: 'requestErrorsModal.html',
					resolve: {
						errors: function () {
							return vm.errors;
						},
						request: function () {
							return request;
						}
					},
					controller: function (errors, request, $uibModalInstance) {
						var vm = this;
						vm.errors = errors;
						vm.request = request;

						vm.close = function () {
							$uibModalInstance.dismiss('cancel');
						};
					},
					controllerAs: 'vm'
				});
			};

			vm.checkRebalanceStatus = function (requestId) {

				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'rebalanceRequestStatusModal.html',
					controllerAs: 'vm',
					controller: function ($uibModalInstance) {
						var vm = this;

						initModal();

						function initModal() {

							vm.loading = true;
							RebalancerFactory.getRebalanceStatus(requestId)
								.then(function (response) {

									vm.loading = false;

									if (response.data.status == 'fail') {
										Dashboard.toastError(response.data.msg[0].description);
										return;
									}

									var statusObject = response.data.payload.requests[0];

									vm.status = statusObject.status;
									vm.createdOn = statusObject.createdOn;
									vm.percentDone = statusObject.percentDone;
									vm.countAccounts = statusObject.countAccounts;

								})
								.catch(function () {
									vm.loading = false;
								});
						}

						vm.close = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}
				});
			};

			vm.cancelRebalanceRequest = function (request) {

				var payload = {
					requestId: request.id
				};

				request.cancelling = true;
				RebalancerFactory.cancelRebalanceRequest(payload)
					.then(function (response) {
						console.log("Cancel request response: ", response);

						if (response && response.statusCode == 500) {
							Dashboard.toastError(response.data.ErrorMessage);
							return;
						}

						toastr.success("Rebalance request " + request.id + " was successfully cancelled.");
						vm.refresh();

						$rootScope.$broadcast('dashboard-check-rebalance');
					})
					.catch(function (err) {
						Dashboard.toastError(err.message, err);
					})
					.finally(function () {
						request.cancelling = false;
					});
			};

			vm.authorizeRebalanceTrades = function (blotter) {

				var requestId = blotter ? blotter.id : vm.rebalanceId;
				var logic = vm.selectedRebalanceLogic;

				var payload = {
					"id": requestId,
					"logic": logic
				};

				vm.authorizingRebalance = true;
				if (blotter) blotter.authorizing = true;

				RebalancerFactory.authorizeRebalance(payload)
					.then(function (response) {
						console.log("Rebal Auth: ", response);
						vm.authorizingRebalance = false;
						toastr.success("The rebalance request " + requestId + " has completed.");
						vm.refresh();
						resetRebalancer();
						$rootScope.$broadcast('dashboard-check-rebalance');
					})
					.catch(function (err) {
						Dashboard.toastError(err.message, err);
					})
					.finally(function () {
						vm.authorizingRebalance = false;
						if (blotter) blotter.authorizing = false;
					});
			};

			vm.addTab = function (type, data) {
				Tabs.addTab(type, null, null, data);
			};

			vm.openAccount = function (account) {

				account.fetchingDetails = true;
				accountApi.getAccountById(account.id)
					.then(function (res) {
						Tabs.addTab('main', account.id, null, res.data);
					})
					.catch(function (err) {
						Dashboard.toastError('Failed to load account details', err);
					})
					.then(function () {
						$timeout(function () {
							account.fetchingDetails = false;

						});
					});
			};

			$scope.$on('refreshRebalancerData', function(){
				// vm.refresh();
				console.log('Listened to refresh event from Vue');
			});

			function resetRebalancer() {
				vm.showRebalanceResults = false;
				vm.rebalRequestStarted = false;
				vm.rebalRequestProgress = 0;
				vm.accountCount = 0;
				vm.processingRebalance = false;
			}

			vm.toggleProgress = function () {
				vm.processingRebalance = !vm.processingRebalance;
			};

			vm.toggleColumns = function () {

				vm.showingExtraColumns = !vm.showingExtraColumns;

				vm.cols.forEach(function (col) {
					var title = col.title(),
						showing;

					if (title == 'Precision' || title == 'Threshold') {

						showing = col.show();

						if (showing) {
							col.show(false);
						} else {
							col.show(true);
						}
					}
				});
			};

			function checkActiveStatuses() {

				var activeTab = Tabs.getActiveTab();

				if (!vm.checkingRebalanceStatuses) return;

				// if they aren't on the rebalancer tab, don't ping the job
				if (activeTab.type !== 'rebalanceAccountsListByInvestmentV3') {
					$timeout(function () { checkActiveStatuses(); }, 5000);
				} else {
					RebalancerFactory.getRebalanceStatuses()
						.then(function (response) {

							if (response.data.statusCode != 200) {
								Dashboard.toastError(response.data.data.ErrorMessage);
								return;
							}

							var jobs = response.data.data;
							var activeRequests = vm.inProcessParams.settings().dataset;

							var working = _.filter(activeRequests, function (job) {
								return _.contains(workingStatuses, job.requestStatus);
							});

							// update percent complete
							_.each(jobs, function (job) {
								var rowIndex = _.findIndex(vm.inProcessParams.settings().dataset, function (currentRow) {
									return currentRow.webRequestId == job.webRequestId;
								});
								if (rowIndex !== -1) { }
								if (rowIndex !== -1 && job.jobStatus == 'complete') {
									vm.inProcessParams.settings().dataset[rowIndex].requestStatus = 'pendingApproval';
								}

								if (rowIndex !== -1 && job.jobStatus == 'failed') {
									vm.inProcessParams.settings().dataset.splice(rowIndex, 1);
									vm.inProcessParams.reload();
									if (!vm.inProcessParams.settings().dataset.length) vm.requestsCollapsed = true;
								}
							});

							// stop if everything is complete
							if (!working.length) {
								return;
							}

							if (working.length && vm.checkingRebalanceStatuses) {
								$timeout(function () { checkActiveStatuses(); }, 5000);
							} else {
								vm.checkingRebalanceStatuses = false;
							}
						})
						.catch(function (err) {
							Dashboard.toastError(err.message, err);
						});
				}
			}

			vm.refreshAccountList = function () {
				init();
			};

			$scope.$watch('vm.blotterSelectAll', function (newVal, oldVal) {
				if (angular.isDefined(newVal) && newVal != oldVal) {

					_.each(vm.inProcessParams.settings().dataset, function (row) {

						if (newVal === true) {
							row.selected = true;
						} else {
							row.selected = false;
						}
					});
				}
			});

			function everySelected(data) {
				var tradableAccounts = _.filter(data, function (account) {
					return account.account ? account.account.isTrading : true;
				});
				var passes = tradableAccounts.length ? _.every(tradableAccounts, { selected: true }) : false;
				return passes;
			}

			$scope.$watch(function () {
				return vm.tableParams ? vm.tableParams.page() : false;
			}, function (newVal, oldVal) {
				if (newVal) console.log("change from page " + oldVal + " to page " + newVal);
			});

			$scope.$watch('vm.filteredData', function (newVal, oldVal) {
				if (newVal && newVal.length) {

					// check to see if at least one is unselected. If so, unselect the select all checkbox
					var found = _.find(newVal, function (account) {
						return account.account.isTrading && angular.isDefined(account.selected) && !account.selected;
					});

					if (found) {
						vm.handleSelectAll(false, false);
					} else {
						// If select all = false and they check the only unselected row, then consider all selected
						vm.selectAll = everySelected(newVal);
					}
				}
			}, true);

			vm.clearSelected = function () {
				vm.accountsNeedRebalance = vm.accountsNeedRebalance.map(function (account) {
					account.selected = false;
					return account;
				});

				vm.selectedAccounts = [];
			};

			vm.handleSelectAll = function (val, updateAll) {
				if (!angular.isDefined(updateAll)) updateAll = true;

				if (updateAll) {

					_.each($filter('filter')(vm.accountsNeedRebalance, vm.tableParams.filter()), function (row) {

						if (val === true && row.account.isTrading) {
							row.selected = true;
						} else {
							row.selected = false;
						}
					});

					vm.updateSelectedRows();
				} else {
					vm.selectAll = false;
					vm.updateSelectedRows();
				}
			};

			vm.updateSelectedRows = function () {

				$timeout(function () {
					vm.selectedAccounts = vm.accountsNeedRebalance.filter(function (account) {
						return account.selected;
					});

					console.log("Selected Accounts: ", vm.selectedAccounts);
				});
			};

			function submitChanges(accounts) {

				if (vm.creatingRequest || !(vm.selectedAccounts || []).length) return false;

				var payload = {
					accounts: vm.selectedAccounts.map(function (account) { return account.account.id; })
				};

				console.log("Request payload: ", payload);

				vm.creatingRequest = true;
				RebalancerFactory.createRebalanceRequest(payload)
					.then(function (response) {
						console.log("Rebalance request response: ", response);
						vm.creatingRequest = false;
						vm.rebalanceRequestErrors = [];
						if (response.data.status == 'fail') {
							Dashboard.toastError(response.data.msg[0].description);
							return;
						}

						var errors = response.data.data.result.filter(function (rebalanceRequest) {
							return !rebalanceRequest.accepted;
						})
							.map(function (rebalanceRequest) {
								var accountId = rebalanceRequest.accountId;
								var account = _.find(_.pluck(vm.selectedAccounts, 'account'), { id: accountId });
								rebalanceRequest.message = angular.isArray(rebalanceRequest.message) ? rebalanceRequest.message : [rebalanceRequest.message];
								if (account) {
									var accountNumber = account.brokerageAccountNumber;
									var accountName = account.name;
									return _.extend(rebalanceRequest, { accountNumber: accountNumber, accountName: accountName });
								} else return rebalanceRequest;
							});

						if (errors.length) {
							vm.rebalanceRequestErrors = errors;
							debugger;
							toastr.warning("One or more items in your rebalance request were unable to be processed.");
						} else {
							toastr.success("Check the active rebalance requests section for updates.", "Rebalance request created.", {
								timeOut: 0,
								extendedTimeOut: 0,
								closeButton: true,
								allowHtml: true,
								closeHtml: '<button class="toast-close">&times;</button>'
							});
						}

						var rebalId = response.data.id;
						vm.rebalanceId = rebalId;

						vm.requestsCollapsed = false;
						vm.selectAll = false;

						vm.updateSelectedRows();

						vm.refresh();
						return null;
					})
					.catch(function (err) {
						$timeout(function () {
							vm.creatingRequest = false;
							Dashboard.toastError(err.message, err);
						});
					});

			}

			function toggleFilters() {
				vm.isFiltersVisible = !vm.isFiltersVisible;
			}
		}
	}]);
