angular.module('SecurityAdministrator')
.controller('SecurityAdminCreateEnterpriseCtrl', securityAdminCreateEnterpriseCtrl);

function securityAdminCreateEnterpriseCtrl (currentUser, $uibModalInstance, enterprisesApi, toastr, enterpriseUsers, EnterpriseFactory, $q){
  var vm = this;
  vm.creating = false;
  vm.loadingEnterprises = false;
  vm.currentUser = currentUser;
  vm.newEnterpriseForm = {
    name: '',
    parentId: '',
  };
  vm.submit = submit;
  vm.cancel = cancel;
  vm.enterpriseUsers = enterpriseUsers;
  vm.handleEnterpriseSelect = handleEnterpriseSelect;
  vm.copyExistingUser = false;

  init();

  function init() {
    loadEnterprises();
  }

  function loadEnterprises() {
    vm.loadingEnterprises = true;
    EnterpriseFactory.loadEnterpriseLookup()
    .then(function(enterprises){
      vm.enterpriseOptions = enterprises.map(function(enterprise){
        return {
          id: enterprise.id,
          name: enterprise.name
        };
      });
    })
    .catch(function(err){
      console.error(err);
      toastr.error(err.message);
    })
    .finally(function(){
      vm.loadingEnterprises = false;
    });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function handleEnterpriseSelect(item){
    vm.newEnterpriseForm.parentId = item.id;
  }

  function submit(newEnterpriseForm) {
    vm.creating = true;
    var payload = angular.copy(newEnterpriseForm);

    $q.when(enterprisesApi.create(payload))
    .then(function(res){
      $uibModalInstance.close(res);
    })
    .catch(function(err){
      toastr.error(err.message);
    })
    .finally(function(){
      vm.creating = false;
    });
  }
}