import { UserManager, OidcClient, WebStorageStateStore } from 'oidc-client';

angular.module('AuthModule')
.controller('LoginCtrl', function(IDS_URL) {

  var identityBaseUrl = IDS_URL;

  var config = {
    client_id: "smartx-clientapi-webclient",
    // authority: 'http://localhost:5000/',
    authority: identityBaseUrl,
    redirect_uri: window.location.origin + '/identity/callback.html',
    silent_redirect_uri: window.location.origin + "/identity/silent-renew.html",
    post_logout_redirect_uri : window.location.origin + "/login",

    response_type: "id_token token",
    scope: "offline_access openid email profile smartx_profile roles",
    
    revokeAccessTokenOnSignout: true,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 60,
    silentRequestTimeout: 10000, // 10000
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    checkSessionInterval: 2000,
    metadataUrl: identityBaseUrl + ".well-known/openid-configuration",

    userStore: new WebStorageStateStore({ store: window.localStorage })
  };

  var mgr = new UserManager(config);
  var client = new OidcClient(config);

  var search = window.location.search.replace('?', '').split('&');
  var params = {};
  var jumpto = null;

  var isApDomain = window.location.host === 'advisoryplatform.americanportfolios.com';

  search.forEach(function(query) {
    var pieces = query.split('=');
    var k = pieces[0];
    var v = pieces[1];
    params[k] = v;
  });

  jumpto = params.jumpto;

  if (jumpto === 'account') {
    if (params.accountId) window.localStorage.setItem('smxJumptoAccountId', params.accountId);
    else if (params.accountNumber) window.localStorage.setItem('smxJumptoAccountNumber', params.accountNumber);
  }

  // add new providers here when setting up sso integrations. 
  // key: the provider name from the browser url
  // value: the provider name that the identity server understands
  var providers = {
    "ap" : "AmericanPortfoliosOIDC",
    "hantz": "hantzgroup",
    "okta": "okta-oidc"
  };

  if (isApDomain || params.provider && params.provider === 'ap') {

    signInWithProvider(providers["ap"]);

  } else if (params.provider) {

    var provider = providers[params.provider] || params.provider;
    signInWithProvider(provider);

  } else {
    var config = {
      client_id: "smartx-clientapi-webclient",
      // authority: 'http://localhost:5000',
      authority: IDS_URL,
      redirect_uri: window.location.origin + '/identity/callback.html',
      silent_redirect_uri: window.location.origin + "/identity/silent-renew.html",
      post_logout_redirect_uri : window.location.origin + "/login",

      response_type: "id_token token",
      scope: "offline_access openid email profile smartx_profile roles",
      
      revokeAccessTokenOnSignout: true,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: 60,
      silentRequestTimeout: 10000, // 10000
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession: true,
      checkSessionInterval: 2000,
      
      userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    var mgr = new UserManager(config);

    Log.logger = console;
    var query = params;
    
    mgr.getUser()
    .then(function(res) {
      console.log("Identity user: ", res);
      if (res && !res.expired) {
        var decoded = jwt_decode(res.access_token);
        var smx_type = (decoded && decoded.smx_type) || '';

        console.log("Roles: ", smx_type);

        // if (!smx_type){
        //   document.getElementById('group-loader').style.display = "none";
        //   document.getElementById('no-roles').style.display = "block";
        //   throw new Error('No user roles detected');
        // }

        // check to see if they have the manager role before going to the manager portal
        if ((window.location.host === 'managerPortalHost' && smx_type.indexOf('manager') > -1) // attempting to go to the prod manager portal and have the manager role
            || (smx_type.indexOf('manager') > -1 && smx_type.indexOf('advisor') == -1) // or if they have the manager role and no advisor role
            // this should allow users with advisor and manager roles to use both portals.
            // to get to the manager portal, they would have to use the manager portal url.
            // by default they will go to the advisor portal
            // maybe there should be a setting to set the preferred landing place after they log in
          )
        {
          window.location = window.location.origin + "/v3/dashboard"; // "/mngr/v2/";
        } else {

          if (query.accountId) {
            jumpto += ('&accountId='+query.accountId);
          }
          else if (query.accountNumber) {
            jumpto += ('&accountNumber='+query.accountNumber);
          }

          window.location = window.location.origin + "/v3/dashboard" + (jumpto ? '?jumpto=' + jumpto : '');
        }
      } else {
        if (jumpto) {
          var params = {
            redirect_uri: window.location.origin + "/callback?jumpto=" + jumpto/*  + "##" */,
          };
        } else {
          var params = {
            extraQueryParams: {
              loginUrl: window.location.origin + "/login"
            }
          };
        }
        
        // mgr.signinRedirect(params);

        // var identityBaseUrl = 'http://localhost:5000/';

        client.createSigninRequest()
        .then(function(res){
          // console.log(res)
          var nonce = res.state._nonce;
          var state = res.state._id;
          var redirect_uri = res.state._redirect_uri /* + '?provider=ap' */;
          var uri;
          var externalLogin;
          var loginUrl = encodeURIComponent(window.location.origin + "/login");

          if (jumpto) {
            redirect_uri = redirect_uri + '?jumpto=' + jumpto;
          }

          // adding this because encoding the url with "?provider=ap&jumpto=targetEdit" wasn't working
          // the &jumpto=targetEdit was being left off when returning to 'v3/dashboard'
          // this can be used in the callback page to prepend "?provider=ap" to the login uri used in the error message link
          // window.localStorage.setItem('smx_provider', 'ap');
          
          uri = encodeURIComponent(redirect_uri);
          externalLogin = config.authority + 'Account/Login?loginUrl=' + loginUrl + '&returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dsmartx-clientapi-webclient%26response_type%3Did_token%2520token%26scope%3Dsmartx_profile%2520email%2520profile%2520openid%2520offline_access%2520roles%26state%3D' + state + '%26nonce%3D' + nonce + '%26redirect_uri%3D' + uri;
          window.location.replace(externalLogin);
        });
      }
    })
    .catch(function(err){
      console.error(err);
    });
  }

  function signInWithProvider (provider) {
    client.createSigninRequest()
    .then(function(res){
      // console.log(res)
      var nonce = res.state._nonce;
      var state = res.state._id;
      var redirect_uri = res.state._redirect_uri /* + '?provider=ap' */;
      var uri;
      var externalLogin;

      if (jumpto) {
        redirect_uri = redirect_uri + '?jumpto=' + jumpto;
      }

      // adding this because encoding the url with "?provider=ap&jumpto=targetEdit" wasn't working
      // the &jumpto=targetEdit was being left off when returning to 'v3/dashboard'
      // this can be used in the callback page to prepend "?provider=ap" to the login uri used in the error message link
      // window.localStorage.setItem('smx_provider', 'ap');
      
      uri = encodeURIComponent(redirect_uri);
      externalLogin = identityBaseUrl + 'Account/ExternalLogin?provider=' + provider + '&returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dsmartx-clientapi-webclient%26response_type%3Did_token%2520token%26scope%3Dsmartx_profile%2520email%2520profile%2520openid%2520offline_access%26state%3D' + state + '%26nonce%3D' + nonce + '%26redirect_uri%3D' + uri;
      window.location.replace(externalLogin);
    });
  }

  function retrySignin () {
    if (jumpto) {
      var params = {
        redirect_uri: window.location.origin + "/callback?jumpto=" + jumpto/*  + "##" */,
      };
    } else {
      var params = '';
    }

    mgr.signoutRedirect();
  }
});