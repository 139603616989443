angular.module('SecurityAdministrator')
.controller('SecurityAdminEditPermissionCtrl', securityAdminEditPermissionCtrl);

function securityAdminEditPermissionCtrl ($q, SmartXFactory, Dashboard, accessLevels, $uibModalInstance, selectedPermissions, toastr) {

  var vm = this;
  var accountApi = SmartXFactory.getAccountAPI();
  var userApi = new SmartX.UserApi();

  vm.selectedPermissions = selectedPermissions;
  vm.accessLevels = accessLevels;

  vm.errors = [];
  vm.saving = false;

  vm.submit = submit;
  vm.cancel = cancel;

  init();

  function init() {}

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function permissionUpdateRequest (type, payload) {
    return function () {
      return userApi.bulkAssignments('put', type, payload)
        .then(function(res){
          console.log("Update result: ", res);
          return {
            success: true,
            type: type,
            result: res
          };
        })
        .catch(function(err){
          console.error(err);
          return {
            success: false,
            type: type,
            error: err.message
          };
        });
    };
  }

  function submit(accessLevel) {

    if (angular.isUndefined(accessLevel)) return false;

    if (vm.complete && !vm.errors.length){
      return $uibModalInstance.close(true);
    }
    
    vm.saving = true;
    vm.complete = false;
    vm.completed = 0;
    vm.errors = [];

    var payload = vm.selectedPermissions.map(function(permission){
      var permissionUpdate = {
        profileId: permission.profileId,
        accessLevel: vm.selectedAccessLevel
      };

      // switch (permission.type) {
      //   case 'account':
      //     permissionUpdate.accountId = permission.accountId;
      //     break;
      //   case 'enterpriseAccount':
      //   case 'enterpriseTarget':
      //     permissionUpdate.enterpriseId = permission.enterpriseId;
      //     break;
      //   case 'target':
      //     permissionUpdate.targetId = permission.targetId;
      //     break;
      // }

      return permissionUpdate;
    });

    // debug failure messages
    // payload = payload.concat([{
    //   accountId: '2c0eed95-b1cd-4e7e-b5d2-012592816545',
    //   isApm: isApm
    // }]);

    // group updates by objectType because each one has its own endpoint
    // var groupedPayload =  _.groupBy(payload, function(permission){
    //   return permission.type;
    // });

    // console.log(groupedPayload);

    // create a request for each type of permission
    // var permissionUpdateRequests = [];
    // _.each(groupedPayload, function(payload, type){
    //   permissionUpdateRequests.push(permissionUpdateRequest(type, payload));
    // });

    // send it
    vm.loading = true;
    // $q.when(Promise.all(permissionUpdateRequests))
    $q.when(userApi.bulkUpdateAssignments(payload))
    .then(function(results){
      console.log(results);
      // var errors = results.filter(function(result){
      //   return !result.success;
      // });

      // if (errors.length) {
      //   vm.errors = errors;
      // }
    })
    .catch(function(err){
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      vm.loading = false;
    });



    // $q.when(accountApi.bulkUpdateAssignments(payload))
    // .then(function(res){
    //   vm.complete = true;
    //   vm.errors = res.data.filter(function(result){
    //     return !result.updateSuccessful; 
    //   })
    //   .map(function(error){
    //     error.account = _.find(vm.selectedPermissions, {id: error.accountId});
    //     error.message = error.errors.join('.  ');
    //     return error;
    //   });
    // })
    // .catch(function(err){
    //   toastr.error(err.message, {
    //     timeOut: 0,
    //     extendedTimeOut: 0
    //   });
    // })
    // .finally(function(){
    //   vm.saving = false;
    // });

  }
}
