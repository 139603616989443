angular.module('Directives')
.constant('TEST_ACCOUNT', {
  "id": "c6af980e-ce40-47f3-be0f-8c59d88077eb",
  "allocationId": "0b322bb6-8889-4fb2-81a4-ec1e4c5e9239",
  "sodDataDate": "2022-02-01T00:00:00",
  "longPositionCount": 0,
  "shortPositionCount": 0,
  "openingCashValue": 596,
  "openingDividendValue": 0,
  "openingInterestValue": 0,
  "openingFeeValue": 0,
  "openingEquity": 596,
  "accountId": "3849a4ad-fd4e-4712-acc1-e78afb8d88e2",
  "accountName": "Sunshine 511 Holdings",
  "brokerageAcctNum": "U2096529",
  "enterpriseId": "566e141f-7117-404e-b2eb-7fd1d97d678c",
  "enterpriseName": "Rapoport Capital",
  "parentEnterpriseId": "749ef242-b7fd-4f48-95f1-4707c1a43d8d",
  "parentEnterpriseName": "Demo Advisor"
})
.directive('fundingSleeves', function($templateCache, TEST_ACCOUNT) {
	return {
		restrict: 'E',
		scope: {},
        template: $templateCache.get('fundingSleevesTable.tpl.html'),
		controller: fundingSleevesCtrl,
		controllerAs: 'vm',
    };

  function fundingSleevesCtrl (NgTableParams, Dashboard, $q, $scope, $filter, SmartXFactory, Tabs, Amplitude) {
    var vm = this;
    var allocationApi = SmartXFactory.getAllocationAPI();

    vm.selectedAccount = null;
    vm.refresh = init;
    vm.addTab = addTab;
    vm.nonzeroAccountFundingTable = new NgTableParams({
      count: 25,
      filter: {},
      sorting: {
        closingCashValue: "desc"
      }
    }, {
      dataset: []
    });

    vm.accountNameFilter = {
      'accountName': {
        id: 'text',
        placeholder: 'Search account name'
      }
    };

    vm.accountNumberFilter = {
      'brokerageAcctNum': {
        id: 'text',
        placeholder: 'Search #'
      }
    };

    vm.enterpriseFilter = {
      'enterpriseName': {
        id: 'text',
        placeholder: 'Search enterprise'
      }
    };

    vm.parentEnterpriseFilter = {
      'parentEnterpriseName': {
        id: 'text',
        placeholder: 'Search enterprise'
      }
    };

    $scope.$watch('vm.selectedAccount', function(newVal, oldVal){
      console.log("Account selection changed: ", newVal, oldVal);
      if (angular.isDefined(newVal) && newVal !== oldVal) {
        var filter = vm.nonzeroAccountFunding.filter();

        if (newVal && newVal.id){
          filter.accountIds = [newVal.id];
        } else if (newVal === null){
          filter.accountIds = [];
        }

        // trigger an api call with the updated accountIds filter
        vm.nonzeroAccountFunding.filter(filter);
      }
    });

    $scope.$on('accountFunding:export', function(){
      exportData();
    });
    
    init();

    function init () { 
      
      loadFundingSleeves()
      .then(updateTable)
      .catch(function(err){
        Dashboard.toastError(err.message,err);
      });
     }

    function loadFundingSleeves (query) {

      vm.loadingAccounts = true;  

      return $q.when(allocationApi.getAccountsWithNonzeroFunding(query))
      .then(function(res){
        vm.accountsWithNonzeroFunding = res.data;
        return $q.resolve(res.data);
      })
      .catch(function(err){
        // return $q.resolve([ TEST_ACCOUNT ]);
        return $q.reject(err);
      })
      .finally(function(){
        vm.loadingAccounts = false;
      });
    }

    // function format (data) {
    //   var accounts = data.map(function(account){
    //     return account;
    //   });

    //   console.log("Formatted accounts: ", accounts);

    //   return accounts;
    // }

    function updateTable (data) {

      var paginationOpts;
      var filter = vm.nonzeroAccountFundingTable.filter();
      var sorting = vm.nonzeroAccountFundingTable.sorting();

      vm.nonzeroAccountFundingTable = new NgTableParams({
        count: 25,
        filter: filter,
        sorting: sorting
      }, {
        dataset: data
      });
      
      paginationOpts = (data.length > 25) ? [25, 50, 100]  : [];
      vm.nonzeroAccountFundingTable.settings({ counts: paginationOpts});
    }

    function addTab(type, account) {
      var accountId = account.accountId;
      account.loading = true;
      $q.when(SmartXFactory.getAccountAPI().get(accountId))
      .then(function(res){
        var account = res.data.data;
        Tabs.addTab(type, accountId, null, account);
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        account.loading = false;
      });

      Amplitude.logEvent('Account Funding:View Account Details');
    }

    function exportData () {

      var currentFilters = vm.nonzeroAccountFundingTable.filter();
      var currentSort = vm.nonzeroAccountFundingTable.sorting();
      var currentDataset = vm.nonzeroAccountFundingTable.settings().dataset;
      
      var filteredData = $filter('filter')(currentDataset, currentFilters);
      
      if (_.keys(currentSort).length) {
        var sortKey = _.keys(currentSort)[0];
        var reverse = currentSort[sortKey] === 'desc' || false;
        filteredData = $filter('orderBy')(filteredData, sortKey, reverse);
      }

      var formattedData = filteredData.map(function(row){
        return {
          'Account Name': row.accountName,
          'Account Number': row.brokerageAcctNum,
          'Enterprise': row.enterpriseName,
          'Parent Enterprise': row.parentEnterpriseName,
          'Value': row.closingCashValue,
          'As Of': row.asOfTimestamp
        };
      });

      Dashboard.JSONtoCSV(formattedData, 'account_funding_data_' + moment().format("MM_DD_YYYY") + '.csv');
    }
  }
});