angular.module('BillingModule')
.directive('households', function($templateCache) {
	return {
		restrict: 'E',
		scope: {
    },
    template: $templateCache.get('households.tpl.html'),
		controller: householdsCtrl,
		controllerAs: 'vm',
	};

  function householdsCtrl ($scope, $q, NgTableParams, $modal, $templateCache, Billing, Dashboard, Tabs){

    var vm = this;
    var accountApi = new SmartX.AccountApi();

    vm.canModify = false;

    vm.showCreateHouseholdForm = showCreateHouseholdForm;

    vm.viewHouseholdAccounts = viewHouseholdAccounts;
    vm.viewingAccounts = false;
    vm.exitGroupAccounts = exitGroupAccounts;
    vm.refreshData = init;
    vm.viewAccountDetails = viewAccountDetails;

    vm.selectedDataView = 'qtd';

    vm.households1 = [
      {
        name: 'IRA Household 1',
        feeSchedule: 'Schedule 1',
        billableAUM: 1000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      },
      {
        name: 'ETH Moon Trust',
        feeSchedule: 'Schedule 3',
        billableAUM: 100000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      },
      {
        name: 'Nakamoto Retirement',
        feeSchedule: '--',
        billableAUM: 1000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      }
    ];

    $scope.$watch('vm.selectedDataView', function(newVal, oldVal){
      if (newVal && newVal !== oldVal) init();
    });

    init();

    function init () {


      if (vm.householdTable){
        vm.householdTable.reload();
        return;
      }

      vm.householdTable = new NgTableParams({
        page: 1,
        count: 10,
        sorting: {
          firmName: 'asc'
        },
        filter: {
          // billingApprovalStatusType: '',
          // billingPaidStatusType: '',
          // billingFeeType: '',
        }
      }, {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
            
          var query = {
            pageNumber: page,
            pageSize: size,
            sortKey: sort,
            sortDescending: direction === 'desc' ? true : false,
            datePeriodType:  (vm.selectedDataView === 'qtd' ? 'quarterly' : 'monthly'),
          };

          _.extend(query, filter);

          console.log("Search Params: ", query);

          vm.loading = true;
          vm.householdsQuery = query;
          
          return $q.when(Billing.getAllFirmsGroupAum(query))
                  .then(function(res) {  
                    params.total(res.data.metaData.totalRecordCount);
                    return formatGroup(res.data.data);
                  })
                  .catch(function(err) {
                    // toastr.error(err.message);
                    Dashboard.toastError(err.message, err);
                    $q.reject();
                  })
                  .finally(function(){
                    vm.loading = false;
                  });
        },
      });
    }

    function formatGroup (households) {
      return households;
    }

    function exitGroupAccounts () {
      vm.viewingAccounts = false;
    }

    function viewAccountDetails (household) {
      household.loadingAccount = true;
			$q.when(accountApi.getAccountById(household.accountId))
			.then(function(res){
				Tabs.addTab('main', household.accountId, null, res.data);
			})
			.catch(function(err){
				Dashboard.toastError("Failed to load account details", err);
			})
			.then(function(){
				household.loadingAccount = false;
			});
    }
  
    function viewHouseholdAccounts (group) {
      vm.viewingAccounts = true;
      vm.selectedGroup = group;
      
      vm.householdAccountsTable = new NgTableParams({
        page: 1,
        count: 10,
        sorting: {
        },
        filter: {
        }
      }, 
      {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
            
          var query = {
            pageNumber: page,
            pageSize: size,
            sortKey: sort,
            sortDescending: direction === 'desc' ? true : false,
            datePeriodType:  (vm.selectedDataView === 'qtd' ? 'quarterly' : 'monthly'),
            groupId: group.groupId
          };

          _.extend(query, filter);

          console.log("Search Params: ", query);

          vm.loadingGroupData = true;
          vm.householdAccountsQuery = query;
          
          return $q.when(Billing.getAccountsByGroupId(query))
                  .then(function(res) {  
                    params.total(res.data.metaData.totalRecordCount);
                    return formatGroup(res.data.data);
                  })
                  .catch(function(err) {
                    // toastr.error(err.message);
                    Dashboard.toastError(err.message, err);
                    $q.reject();
                  })
                  .finally(function(){
                    vm.loadingGroupData = false;
                  });
        },
      });
    }

    function showCreateHouseholdForm () {
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('createHouseholdForm.tpl.html'),
        resolve: {},
        controller: 'CreateHouseholdFormCtrl',
        controllerAs: 'vm'
      });
      
      modalInstance.result.then(function(newSchedule) {});
    }
  }
});