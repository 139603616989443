angular
	.module('PortfolioEditor', ['PortfolioEditorFactory'])
	.controller('PortfolioEditorCtrl', function($scope, SmartXFactory){

		var vm = this;
		var parentTab = $scope.tab;

		console.log(parentTab);

		vm.setAccount = setAccount;
		$scope.th_account = {
			selected: undefined
		};

		init();

		function init(){
			
			if (SmartXFactory.activeAccounts) {
				var accounts = SmartXFactory.activeAccounts.filter(function(account){
					return account.permission === 'fullAccess';
				});

				vm.accounts = accounts;
			}

			if (parentTab.selectedAccount) {

				if (vm.accounts) {
					var selectedAccount = _.where(vm.accounts, {id: parentTab.selectedAccount.id})[0];
				}

				vm.selectedAccount = selectedAccount || parentTab.selectedAccount;
				
			} else vm.selectedAccount = vm.accounts ? vm.accounts[0] : undefined;
		}

		function setAccount(account){
			vm.selectedAccount = account;
			$scope.$broadcast('account-changed', vm.selectedAccount);
		}

		$scope.$on('change-editor-account', function(event, account){
			setAccount(account);
		});


		$scope.th_select_account = function($item, $model, $label, $event){

			console.log($item);
			console.log($model);
			console.log($label);

			setAccount($item);
		};
	});