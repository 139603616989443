angular.module('ExclusionsCtrl')
	.directive('exclusionsTable', ['$templateCache', function ($templateCache) {
		return {
			restrict: 'E',
			scope: {
				models: '='
			},
			template: $templateCache.get('exclusionsTable.tpl.html'),
			link: link,
			controller: exclusionsTableCtrl,
			controllerAs: 'vm'
		};

		function link(scope, elem, attr) {
			console.log(scope);
			console.log(elem);
			console.log(attr);
		}



		function exclusionsTableCtrl(FeatureFlags, $timeout, $scope, $log, $modal, Dashboard, ExclusionsFactory, NgTableParams, $filter, $templateCache, toastr, USER, $q) {
			var vm = this;
			var tab = $scope.$parent.tab;

			vm.exclusionKindFilterData = [
				{id: '',                    title: 'Select Method'}, 
				{id: 'freeze',              title: 'Freeze'}, 
				{id: 'doNotHold',           title: 'Do Not Hold'}, 
				{id: 'closingOnly',         title: 'Closing Only'}, 
				{id: 'taxGroupClosingOnly', title: 'Tax Group Closing Only'}];

			vm.statusFilterData = [
				{id: '',              title: 'Select Status'},
				{id: 'notYetStarted', title: 'Not Yet Started'},
				{id: 'started',       title: 'Started'},
				{id: 'readyToEnd',    title: 'Ready To End'},
				{id: 'finished',      title: 'Finished'}];

			vm.addBackOnEndFilterData =[
				{id: '',      title: 'Select On Expiration'},
				{id: 'true',  title: 'Rebalance Immediately'},
				{id: 'false', title: 'Rebalance on Update'}]

			$timeout(function(){
				vm.exclusionTypes = ExclusionsFactory.getExclusionTypes();

				vm.substitutionTypes = ExclusionsFactory.getSubstitutionTypes();

				vm.isEnabled = FeatureFlags.isEnabled;
				vm.features = Dashboard.getUserFeatures();

				vm.exclusions = [];
				
				vm.filtersVisible = false;

				vm.tableParams = new NgTableParams({
					count: 25,
					filter: { status: '', method: '' },
					sorting : {symbol: 'asc'}
				}, {
					dataset: []
				});
				
				vm.accessAccounts = Dashboard.getUserAccounts();
				vm.accessAccountNames = _.pluck(vm.accessAccounts, 'name');
				
				vm.add = add;
				vm.edit = edit;
				vm.delete = deleteExclusion;
				vm.close = close;
				vm.toggleFilters = toggleFilters;			
				
				$scope.$on('update-exclusions', function(event, args) {
					vm.completedPagedParams.reload();
				});

				init();
			});

			// update grid if switching to an existing exclusions tab
			$scope.$on('exclusions::update-filter', function(event, account) {
				if (!account) { 
					// clear account data before reinitializing so that the exclusions request is not filtered by account
					vm.selectedAccount = null;
					tab.data = null; 
					init();
				} else {
					vm.filtersVisible = true;
					vm.tableParams.filter({
						'account.brokerageAccountNumber': account ? $filter('sanitizeSmartXAccountName')(account.brokerageAccountNumber, 'number') : ''
					});
				}
			});

			function completedRequestsPaged(accNum){
				vm.completedPagedParams = new NgTableParams(
				{
					page: 1,
					count: 25,
					sorting : {'startDate': "desc"},
					filter: {
						status: '', 
						exclusionKind: '',
						addBackOnEnd: '',
						brokerageAcctNum: accNum
					},
				}, 
				{	
					getData: function (params) {

						vm.loadingHistory = true;

						var page = params.page();
						var size = params.count();
						var sorting = params.sorting();
						var filter = params.filter();
						var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
						var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
						
						var query = {
							page: page,
							pageSize: size,
							sort: sort,
							sortDirection: direction
						};
	
						_.extend(query, filter);

						// strip fields with null/undefined or empty string values
						query = _.pick(query, function(v) { return v && v !== '' ; } );

						console.log('Search Params: ', query);

						return 	$q
						.when(ExclusionsFactory.getExclusionsWithMetadata(query))
						.then(function(res) {  

							params.total(res.totalRecordCount);

							return 	res.results.map(function(exclusion){
									exclusion.status = _.find(vm.statusFilterData, { id: exclusion.status } ).title;
									exclusion.method =  _.find(vm.exclusionKindFilterData, { id: exclusion.exclusionKind }).title;
									exclusion.onAddBackOnEnd = _.find(vm.addBackOnEndFilterData, { id: String(exclusion.addBackOnEnd) }).title;

									return exclusion;
								}
							)
						})
						.catch(function(err) {
							Dashboard.toastError(err.message, err);
							$q.reject();
						}) 
						.finally(function(){ vm.loadingHistory = false;	});
					},
				});
			}
			
			function init() {
				completedRequestsPaged(tab.data ? tab.data.brokerageAccountNumber : '');
			}

			function add() {
				console.log("Add exclusion");
				//			debugger;

				var modalInstance = $modal.open({
					animation: true,
					template: $templateCache.get('exclusionModal.tpl.html'),
					size: 'lg',
					backdrop: 'static',
					resolve: {
						exclusion: function () {
							return null;
						}
					},
					controller: 'ExclusionModalCtrl',
					controllerAs: 'vm'
				});
			}

			function edit(exclusion, guid) {
				console.log("Edit exclusion");
				console.log(exclusion);
				//			debugger;

				var modalInstance = $modal.open({
					animation: true,
					template: $templateCache.get('exclusionModal.tpl.html'),
					size: 'lg',
					resolve: {
						exclusion: function () {
							return exclusion;
						}
					},
					controller: 'ExclusionModalCtrl',
					controllerAs: 'vm'
				});
			}

			function deleteExclusion(exclusion) {

				var id = exclusion.id;
				exclusion.deleting = true;
				ExclusionsFactory.delete(id)
				.then(function(response) {
					if (response.data && !_.isEmpty(response.data.data)){
						// display validation errors that come through inside the data array
						toastr.info(response.data.data.join('\n'));
					} else {
						toastr.success('Exclusion Deleted.');
						vm.completedPagedParams.reload();
					}				
				})
				.catch(function(err){
					// toastr.error(err.message);
					// console.error(err);
					Dashboard.toastError(err.message, err);
				})
				.finally(function(){
					exclusion.deleting = false;
				});
			}

			function toggleFilters() {
				vm.filtersVisible = !vm.filtersVisible;
			}
		}
	}]);