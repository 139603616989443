angular.module("FavoriteModels", []).factory("FavoriteModels", [
    "$http",
    "$filter",
    "USER",
    "IdentityFactory",
    "ASSET_SHOP_API_URL",
    function ($http, $filter, USER, IdentityFactory, ASSET_SHOP_API_URL) {

        //MS-12
        var favoriteModels = [];

        function getFavoriteModels() {
            return new Promise(function(resolve, reject) {
                IdentityFactory.getUser()
                .then((res) => {
                    fetch(`${ASSET_SHOP_API_URL}/favorites`, {
                        headers: {
                            Authorization: `Bearer ${res.access_token}`,
                        },
                    }).then((body) => {
                        body.json()
                        .then((json) => {
                            console.log("fave models before filter", json.data);
                            const favoriteModelIds = json.data.filter((x) => x.isActive);
                            console.log("fave models after filter", favoriteModelIds);
                            favoriteModels = favoriteModelIds.map((x) => x.modelId);
                            resolve();
                        });
                    });
                });
            });
        }

        function isModelAFavorite(modelId) {
            const favModelsToLower = favoriteModels.map((x) => x.toLowerCase());

            return favModelsToLower.includes(modelId.toLowerCase());
        }

        function addModelToFavorites(modelId) {
            return new Promise(function(resolve, reject) {
                IdentityFactory.getUser()
                .then((res) => {
                    const baseURL = "https://api.smartxadvisory.com/api/4.1/asset-shop";
                    fetch(`${baseURL}/favorites/${modelId}/toggle`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${res.access_token}`
                        },
                    })
                    .then(() => {
                        getFavoriteModels()
                        .then(() => {
                            resolve();
                        });
                    });
                });
            });
        }

        return {
            favoriteModels: favoriteModels,
            isModelAFavorite: isModelAFavorite,
            getFavoriteModels: getFavoriteModels,
            addModelToFavorites: addModelToFavorites,
        };
    },
]);
