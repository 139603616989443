angular.module('ExclusionsService', [])
.factory('ExclusionsFactory', ['$http', '$q', 'SMX_API_URL', function($http, $q, SMX_API_URL){

	var exclusionsApi = new SmartX.ExclusionsApi();

	var exclusionTypes = [
		{
			value: 'freeze', 
			text: 'Hold Existing Shares',
			shortText: 'Hold Existing Shares'
		},
		{
			value: 'doNotHold', 
			text: 'Liquidate Existing Shares Immediately',
			shortText: 'Liquidate Immediately'
		},
		{
			value: 'closingOnly',
			text: 'Liquidate Existing Shares as Needed',
			shortText: 'Liquidate as Needed'
		},
		// {
		// 	value: 'taxGroupClosingOnly', text: 'Tax Group Closing Only', shortText: 'Tax Group Closing'
		// }
	];

	var substitutionTypes = [
		{
			value: 'subToCash', text: 'Hold Cash'
		},
		{
			value: 'subToSymbol', text: 'Hold Security'
		},
		{
			value: 'longExposure', text: 'Reallocate to the rest of the portfolio'
		}
	];

	function getExclusionTypes () {
		return exclusionTypes;
	}

	function getSubstitutionTypes () {
		return substitutionTypes;
	}

	function createExclusionRequest(exclusion){
		// var formattedExclusion = {
		// 	accountId: exclusion.id,
		// 	symbolId: exclusion.symbolId,
		// 	startDate: new Date(exclusion.startDate * 1000), // moment(exclusion.startDate * 1000).add(1, 'day'),
		// 	holdExisting: exclusion.holdExisting,
		// 	// closingOnly: exclusion.closingOnly,
		// 	// addBackOnEnd: exclusion.addBackOnEnd
		// }

		// if (exclusion.endDate) formattedExclusion.endDate = new Date(exclusion.endDate * 1000);
		// if (exclusion.substituteSymbolId) formattedExclusion.substituteSymbolId = exclusion.substituteSymbolId;

		return exclusionsApi.create(exclusion);
	}

	function getAccountExclusions(id) {
		return exclusionsApi.getAccountExclusions(id);
	}

	function createExclusion(exclusions){
		var reqs = exclusions.map(createExclusionRequest);
		return Promise.all(reqs);
  }

	function update (id, endDate) {
		return exclusionsApi.updateAccountExclusion(id, endDate);
	} 

	function deleteExclusion(id){
		return exclusionsApi.delete(id);
	}

	function getAllExclusions(){
		return exclusionsApi.get();
	}

	function getAccountTradeBreaks (id) {
		return exclusionsApi.getAccountTradeBreaks(id);
	}

	function getExclusionsByTaxGroup (taxGroupId) {
		var api = new SmartX.ExclusionsApi({
			version: "4.1"
		});
		console.log(api);
		return api.getExclusionsByTaxGroup(taxGroupId);
	}
	
	function getExclusionsWithMetadata (params) {
		var url = `${SMX_API_URL}api/4.1/accounts/exclusions`;
		return $http.get(url, { 
			params: params, 
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': 'Bearer ' + SmartX.Router.getToken()
			} 
		})
		.then(function(res){
			var data = angular.isDefined(_.property(['data', 'data'])(res)) ?  (_.property(['data', 'data'])(res) ||  _.property(['data'])(res)) : _.property(['data'])(res);
			var error;

			if (!data) {
				throw new Error('Error retrieving accounts data');
			}

			if (data.errorMessage){
				error = new Error(data.errorMessage);
				return $q.reject(error);
			}
			return $q.resolve(data);
		})
		.catch(function(error){
			return $q.reject(error);
		});
	}

    return {
		getAccountExclusions: getAccountExclusions,
		get    				: getAllExclusions,
		create 				: createExclusion,
		delete 				: deleteExclusion,
		update				: update,
		getExclusionTypes: getExclusionTypes,
		getSubstitutionTypes: getSubstitutionTypes,
		getAccountTradeBreaks: getAccountTradeBreaks,
		getExclusionsWithMetadata: getExclusionsWithMetadata,
		getExclusionsByTaxGroup: getExclusionsByTaxGroup
    };

}]);