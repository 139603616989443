angular.module('SmartXFactory', [])
.factory('SmartXFactory', ['Dashboard', '$http', '$q', 'SMX_API_URL', function(Dashboard, $http, $q, SMX_API_URL){

	var accountAPI,
		allocationAPI,
		modelAPI,
		taxHarvestingAPI,
		cmsAPI,
		accounts,
		activeAccounts,
		peopleAPI,
		documentAPI,
		rebalancerAPI,
		jobAPI,
		benchmarkAPI,
		symbolAPI,
		targetWeightGroupAPI,
		ReportedModelAPI,
		enterprisesAPI,
		flagsAPI,
		performanceAPI;

	function ping () {
		return SmartX.Router.ping();
	}

	function setToken(token){
		return SmartX.Router.setToken(token);
	}

	function getEnv () {
		var env = _.property(['environment'])(window.ENV);
		return env;
	}

	// function setRefreshToken(token){
	// 	return SmartX.Router.setRefreshToken(token);
	// }

	// function getNewToken () {
	// 	return SmartX.Router.getNewToken()
	// }

	function getToken(){
		return SmartX.Router.getToken();
	}

	function getPerformanceAPI(){
		if (typeof performanceAPI !== 'undefined') return performanceAPI; 
		var platform = Dashboard.getPlatform();
		return new SmartX.PerformanceApi(platform.id);
	}

	function getBenchmarkAPI(){
		return (typeof benchmarkAPI !== 'undefined') ? benchmarkAPI : new SmartX.BenchmarkApi();
	}

	function getFlagsAPI(){
		return (typeof flagsAPI !== 'undefined') ? flagsAPI : new SmartX.FlagsApi();
	}

	function getEnterprisesAPI(){
		return (typeof enterprisesAPI !== 'undefined') ? enterprisesAPI : new SmartX.EnterprisesApi();
	}

	function getRebalancerAPI(){
		return (typeof rebalancerAPI !== 'undefined') ? rebalancerAPI : new SmartX.RebalancerApi();
	}

	function getJobAPI(){
		return (typeof jobAPI !== 'undefined') ? jobAPI : new SmartX.JobApi();
	}

	function getSymbolAPI(){
		return (typeof symbolAPI !== 'undefined') ? symbolAPI : new SmartX.SymbolApi();
	}

	function getAccountAPI(){
		return (typeof accountAPI !== 'undefined') ? accountAPI : new SmartX.AccountApi();
	}

	function getAllocationAPI(){
		return (typeof allocationAPI !== 'undefined') ? allocationAPI : new SmartX.AllocationApi();
	}
	
	function getModelAPI(){
		return (typeof modelAPI !== 'undefined') ? modelAPI : new SmartX.ModelApi();
	}

	function getPeopleAPI(){
		return (typeof peopleAPI !== 'undefined') ? peopleAPI : new SmartX.PeopleApi();
	}

	function getDocumentsApi(){
		return (typeof documentAPI !== 'undefined') ? documentAPI : new SmartX.DocumentsApi();
	}

	function getReportedModelAPI(){
		return (typeof ReportedModelAPI !== 'undefined') ? ReportedModelAPI : new SmartX.ReportedModelApi();
	}

	function getTaxHarvestingAPI(){
		return (typeof taxHarvestingAPI !== 'undefined') ? taxHarvestingAPI : new SmartX.TaxHarvestingApi();
	}

	function getCmsAPI(){
		return (typeof cmsAPI !== 'undefined') ? cmsAPI : new SmartX.CmsApi();
	}

	function getTargetWeightGroupAPI(){
		return (typeof targetWeightGroupAPI !== 'undefined') ? targetWeightGroupAPI : new SmartX.TargetWeightGroupApi();
	}

	function setAccounts(data){
		return accounts = data;
	}

	function setActiveAccounts(data){
		activeAccounts = data;
	}

	function getAccountsWithMetadata (params) {
		var url = `${SMX_API_URL}api/4.1/accounts`;
		return $http.get(url, { 
			params: params, 
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': 'Bearer ' + getToken() 
			} 
		})
		.then(function(res){
			var data = angular.isDefined(_.property(['data', 'data'])(res)) ?  (_.property(['data', 'data'])(res) ||  _.property(['data'])(res)) : _.property(['data'])(res);
			var error;

			if (!data) {
				throw new Error('Error retrieving accounts data');
			}

			if (data.errorMessage){
				error = new Error(data.errorMessage);
				return $q.reject(error);
			}
			return $q.resolve(data);
		})
		.catch(function(error){
			return $q.reject(error);
		});
	}

	function formatCsvResponse(response){
		return {
			data: response
		};
	}

	function downloadAccounts (params) {
		
		var url = `${SMX_API_URL}api/4.1/accounts/download`;
		return $http.get(url, { 
			params: params, 
			headers: { 
				// 'Content-Type': 'application/json', 
				'Authorization': 'Bearer ' + getToken(),
				'X-SMArtX-Get-Original-Headers': 'yes'
			},
			transformResponse: formatCsvResponse
		})
		.then(function(res){
			var data = res.data.data;
			return $q.resolve(data);
		})
		.catch(function(error){
			return $q.reject(error);
		});
	}

	function getAccounts(status, refresh) {
		return new Promise(function(resolve, reject) {

			var payload;
			if (typeof accounts !== 'undefined' && !refresh) {
				if (typeof status !== 'undefined'){
					if (status == 'active') payload = accounts.filter(function (item) { return item.status.toLowerCase() === status.toLowerCase(); });
					else payload = accounts.filter(function (item) { return item.status.toLowerCase() != 'active'; });
				}
				return resolve(payload);
			} else {

				if (typeof acctsAPI === 'undefined') { acctsAPI = getAccountAPI(); }

				return acctsAPI.getAllAccounts()
					.then(function(resp) {
						payload = resp.data;
						setAccounts(resp.data);
						if (typeof status !== 'undefined'){
							if (status == 'active') payload = payload.filter(function (item) { return item.status.toLowerCase() === status.toLowerCase(); });
							else payload = payload.filter(function (item) { return item.status.toLowerCase() != 'active'; });
						}
						return resolve(payload);
					})
					.catch(function (err) {
						console.error(err.message);
						return reject(Error(err.message));
					});
			}
		});
	}

	function getAccountTargets(accountIds) {
		if (typeof acctsAPI === 'undefined') { acctsAPI = getAccountAPI(); }
		return acctsAPI.getAccountTargets(accountIds);
	}

	function getAccountStatistics(status) {
		return new Promise(function(resolve, reject) {

			if (typeof acctsAPI === 'undefined') { acctsAPI = getAccountAPI(); }

			return acctsAPI.getAccountStatistics(status)
				.then(function(resp) {
					if (typeof resp.data !== 'undefined') {
						var accountsIndex = _.indexBy(accounts, 'id');
						var accountsWithStatistics = resp.data.map(function(account){
							return _.extend({}, account, accountsIndex[account.id]);
						});
						console.log("Accounts with statistics: ", accountsWithStatistics);
						setActiveAccounts(accountsWithStatistics);
						return resolve(resp.data);
					} else {
						return reject('No models found');
					}
				})
				.catch(function (err) {
					console.error(err.message);
					return reject(Error(err.message));
				});
		});
	}

	function getAllModelWithStatistics() {
		return new Promise(function(resolve, reject) {

			if (typeof modelAPI === 'undefined') { modelAPI = getModelAPI(); }

			return modelAPI.getAllModelStatistics()
				.then(function(resp) {
					return resolve(resp);
				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getCmsModels() {
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }

			return cmsAPI.getAllModels()
				.then(function(resp) {
					if (typeof resp.data !== 'undefined') {
						return resolve(resp.data);
					} else {
						return reject('No models found');
					}
				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getModelById(id) {
		return new Promise(function(resolve, reject) {

			if (typeof modelAPI === 'undefined') { modelAPI = getModelAPI(); }

			return modelAPI.getModelById(id)
				.then(function(resp) {
					// debugger
					if (typeof resp !== 'undefined') {
						return resolve(resp);
					} else {
						return reject('No model found with guid', id);
					}

				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getCMSModelById(id, guidType) {
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }
			if (typeof guidType === 'undefined') { guidType = 'smartx_guid'; }

			return cmsAPI.getModelById(id, guidType)
				.then(function(resp) {
					if (typeof resp.data !== 'undefined') {
						return resolve(resp.data);
					} else {
						return reject('No model found with guid', id);
					}

				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getRecentlyAddedModels(limit) {
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }

			var payload;
			if (typeof modelsMeta !== 'undefined') {
				resolve(modelsMeta);
			} else {
				return cmsAPI.getRecentlyAddedModels(limit)
					.then(function(resp) {
						if (typeof resp !== 'undefined') {
							return resolve(resp);
						} else {
							return reject('No models found');
						}
					})
					.catch(function (err) {
						console.error(err);
						return reject(Error(err.message));
					});
			}
		});
	}

	function getRecentlyAddedCommentary(limit) {
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }

			return cmsAPI.getRecentlyAddedCommentary(limit)
				.then(function(resp) {
					if (typeof resp !== 'undefined' && resp.length) {
						return resolve(resp);
					} else {
						return reject('No models found');
					}
				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getRecentlyAddedWebinars(limit) {
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }

			return cmsAPI.getRecentlyAddedWebinars(limit)
				.then(function(resp) {
					if (typeof resp !== 'undefined' && resp.length) {
						return resolve(resp);
					} else {
						return reject('No models found');
					}
				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	function getCommentaryForModel(modelId, limit) {
		if (typeof limit === 'undefined') { limit = 25; }
		return new Promise(function(resolve, reject) {

			if (typeof cmsAPI === 'undefined') { cmsAPI = getCmsAPI(); }

			return cmsAPI.getModelCommentary(modelId, limit)
				.then(function(resp) {
					if (typeof resp.data !== 'undefined') {

						return resolve(resp.data);
					} else {
						// reject('No Commentary Found For Node ' + modelId);
						return resolve({
							count: 0,
							data: [],
							status: 'success'
						});
					}
				})
				.catch(function (err) {
					console.error(err);
					return reject(Error(err.message));
				});
		});
	}

	return {
		ping: ping,
		setToken : setToken,
		getEnv: getEnv,
		// setRefreshToken: setRefreshToken,
		// getNewToken: getNewToken,
		getToken : getToken,
		getAccountAPI : getAccountAPI,
		getAllocationAPI : getAllocationAPI,
		getModelAPI : getModelAPI,
		getPeopleAPI : getPeopleAPI,
		getDocumentsApi: getDocumentsApi,
		getTaxHarvestingAPI: getTaxHarvestingAPI,
		getReportedModelAPI: getReportedModelAPI,
		getCmsAPI: getCmsAPI,
		getRecentlyAddedModels: getRecentlyAddedModels,
		getRecentlyAddedCommentary: getRecentlyAddedCommentary,
		getRecentlyAddedWebinars: getRecentlyAddedWebinars,
		getCommentaryForModel: getCommentaryForModel,
		setAccounts: setAccounts,
		getAccounts: getAccounts,
		getAccountStatistics: getAccountStatistics,
		getAllModelWithStatistics: getAllModelWithStatistics,
		getCmsModels: getCmsModels,
		getCMSModelById: getCMSModelById,
		getModelById: getModelById,
		getRebalancerAPI: getRebalancerAPI,
		getJobAPI: getJobAPI,
		getBenchmarkAPI: getBenchmarkAPI,
		getTargetWeightGroupAPI: getTargetWeightGroupAPI,
		getSymbolAPI: getSymbolAPI,
		getPerformanceAPI: getPerformanceAPI,
		getEnterprisesAPI: getEnterprisesAPI,
		getAccountTargets: getAccountTargets,
		getAccountsWithMetadata: getAccountsWithMetadata,
		downloadAccounts: downloadAccounts,
		get accounts() {
			return accounts;
		},
		get activeAccounts() {
			return activeAccounts;
		},
		getFlagsAPI: getFlagsAPI
	};
}]);
