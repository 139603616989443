angular.module('TabsService', ['DashboardService'])
  .config(['$locationProvider', function($locationProvider){
    // $locationProvider.html5Mode({
    //   enabled: true,
    //   requireBase: false
    // });
  }])
  .factory('Tabs', [
    '$location', 
    '$rootScope', 
    '$q', 
    '$timeout', 
    'Dashboard', 
    'Models', 
    'SweetAlert', 
    '$filter', 
    function($location, $rootScope, $q, $timeout, Dashboard, Models, SweetAlert, $filter) {

    var tabs = [];
    var storedTabs;
    var modelTabs = [];
    var viewpointTabs = [];
    var activeTab;
    var lastDashTab;
    var lastActive;
    var userTabs = 'tabs-' + Drupal.settings.username;
    var user = Drupal.settings.username;

    // tabs we only want one instance of
    var singletonTabs = [
      'accountsSummaryPaged',
      'allocate',
      'liquidate',
      'profile',
      'open-brokerage-account',
      'accountHistory',
      'holdings',
      'search',
      'portfolio-builder',
      'notifications',
      'manageGroups',
      'accountsSummary',
      'pendingAccounts',
      'welcome',
      'portfolios',
      'addAccount',
      'viewpoint',
      // 'exclusions',
      'webinars',
      'settings',
      'accountSettings',
      'pendingAccountDetail',
      'targets',
      'targetEdit',
      'portfolio-editor',
      'targetWeights',
      'rebalanceAccountsListByInvestment',
      'rebalanceAccountsListByInvestmentV2',
      'harvesting',
      'closedLots',
      'model-report'
    ];

    function getJumpto(params) {

      // console.log(window.location);
      // console.log($location);
      // if (hash) {
      //   var params = hash.split('&');
      //   var jumpto = params.filter(function(param){
      //     return param.indexOf('jumpto=') > -1;
      //   });

      //   if (jumpto.length) {
      //     var destination = jumpto[0].split('=')[1];
      //     return destination;
      //   } else {
      //     return null;
      //   }
      // } else {
      //   return null;
      // }
      return params.jumpto;
    }

    function getJumptoAccountNumber(params) {
      var id;
      if (params.accountNumber) {
        id = params.accountNumber;
      } /* else {
        if (window && window.localStorage) {
          id = window.localStorage.getItem('smxJumptoAccountNumber');
        }
      } */

      return id;
    }

    function getJumptoAccountId(params) {
      var id;
      if (params.accountId) {
        id = params.accountId;
      }/*  else {
        if (window && window.localStorage) {
          id = window.localStorage.getItem('smxJumptoAccountId');
        }
      } */

      return id;
    }

    var loadTabs = function() {

      var username = Dashboard.getUserClientID();

      storedTabs = angular.isDefined(tabs) ? angular.fromJson(tabs) : undefined;

      tabs = storedTabs || [];

      if (tabs.length) {

        if (typeof tabs == 'string') {
          tabs = angular.fromJson(tabs);
        }

        tabs = tabs.map(function(tab, index) {
          tab.index = index;
          return tab;
        });
      }
      var startTime = new Date().getTime() / 1000;

      if (typeof storedTabs != 'undefined' && angular.isDefined(tabs) != null && Object.keys(angular.fromJson(tabs)).length > 0) {

        var initialTab = {
          title: Dashboard.accounts ? Dashboard.accounts[0] : 'Account',
          type: "main",
          index: 0,
          created: startTime,
          lastActive: startTime,
        };

      } else {

        var jumpto = getJumpto($location.search());
        var jumptoAccountNumber = getJumptoAccountNumber($location.search());
        var jumptoAccountId = getJumptoAccountId($location.search());

        if (jumpto === 'account' && (jumptoAccountId || jumptoAccountNumber)){

          if (jumptoAccountId) {
            initialTab = {
              title: 'Account',
              type: 'main',
              index: 0,
              created: startTime,
              lastActive: startTime,
              selectedAccountGuid: jumptoAccountId
            };
          } else if (jumptoAccountNumber) {
            initialTab = {
              title: 'Account',
              type: 'main',
              index: 0,
              created: startTime,
              lastActive: startTime,
              jumptoAccountNumber: jumptoAccountNumber
            };
          }

        } else if (jumpto && titles[jumpto]) {
          var initialTab = {
            title: titles[jumpto],
            type: jumpto,
            index: 0,
            created: startTime,
            lastActive: startTime,
          };
        } else {
          // default to the welcome view if there are no tabs to load
          var initialTab = {
            title: 'Home',
            type: "welcome",
            // type: "target-portfolio",
            index: 0,
            created: startTime,
            lastActive: startTime,
          };
        }
      }

      if (!tabs.length) {
        tabs.push(initialTab);
        tabs[tabs.length - 1].active = true;
        activeTab = tabs[0];
      }
      tabs.forEach(function(tab) {

        // build the modelTabs array of from the saved model tabs
        // this could also be saved / loaded as part of the user's settings
        if (tab.type === 'model') {

          modelTabs.push(tab.modelId);

        } else if (tab.type === 'viewpoint') {

          viewpointTabs.push(tab.nid);

        } else if (tab.type === 'main') {

          lastDashTab = tab;

        }
        if (tab.active) {

          activeTab = tab;

        }
      });


      return tabs;
    };

    var setTabs = function(data) {
      tabs = data;
    };

    var getTabs = function() {
      return tabs;
    };

    var getActiveTab = function() {

      tabs.some(function(tab) {
        //console.log('tab', tab);
        if (tab.active) {
          activeTab = tab;
          return true;
        }
      });

      return activeTab;
    };

    var getLastActive = function() {
      return lastActive;
    };

    var setLastActive = function(tab) {
      lastActive = tab;
      return lastActive;
    };

    var setLastDashboard = function(tab) {
      lastDashTab = tab;
    };

    var getLastDashboard = function() {
      return lastDashTab;
    };

    var setActiveTab = function(tab) {
      var index = tabs.indexOf(tab);

      if (index == -1 && Number(tab) === tab && tab % 1 === 0) index = tab.index;

      // using a timeout so that this assignment is delayed until after any tab directive code that might set the active property has finished
      $timeout(function() {
        tabs[index].active = true;
      });
      activeTab = tabs[index];

      return activeTab;
    };

    var titles = {
      // 'accountsSummary'                   : 'Accounts Summary', deprecated by main-4093
      'accountsSummaryPaged'              : 'Accounts Summary',
      'accountSettings'                   : 'Account Settings',
      'exclusions'                        : 'Exclusions',
      'accountHistory'                    : 'Account History',
      'select-brokerage'                  : 'Select Brokerage',
      'addAccounts'                       : 'Add Account',
      'manageGroups'                      : 'Manage Account',
      'pendingAccountDetail'              : 'Pending Detail',
      'targets'                           : 'Targets',
      'targetEdit'                        : 'Edit Target',
      'portfolio-editor'                  : 'Portfolio Editor',
      'viewpoint'                         : 'Viewpoint',
      'rebalanceAccountsListByInvestment' : 'Rebalancer',
      'rebalanceAccountsListByInvestmentV2' : 'Rebalancer',
      'rebalanceAccountsListByInvestmentV3' : 'NEW! Rebalancer',
      'portfolio-builder'                 : 'Builder',
      'pendingAccounts'                   : 'Pending Accounts',
      'rebalanceRequestDetails'           : 'Rebalance Details',
      'rebalanceRequestDetailsV2'         : 'NEW! Request Details',
      // 'substitutions'                     : 'Substitutions',
      'harvesting'                        : 'Tax Harvesting',
      'harvestMonitor'                    : 'Harvest Opportunities',
      'editTaxGroup'                      : 'Tax Group',
      'closedLots'                        : 'Closed Lots',
      'search'                            : 'Products',
      'billing-overview'                  : 'Billing',
      'nonBillableAccounts'               : 'Non-billable Accounts',
      'fundingSleeves'                    : 'Account Funding',
      'modelComparison'                   : 'Transition Analysis',
      'alertsManager'                     : 'Alerts Manager'
    };

    var addTab = function(type, accountGuid, viewpoint, data) {

      var tabOpen;

      if (tabs.length < 15) {

        // check if we are adding a singleton tab
        if (singletonTabs.indexOf(type) !== -1) {

          tabs.forEach(function(tab, index) {

            // switch to existing singleton tab
            if (tab.type === type) {

              tabOpen = true;
              tabs[index].active = true;

              if (tab.type == 'viewpoint') {

                tab.title = 'Viewpoint: ' + viewpoint.title;
                tab.body = (typeof viewpoint.body != 'undefined') ? viewpoint.body : '';
                tab.modelNID = viewpoint.nid;

                $rootScope.$broadcast('dashboard-refresh-viewpoint', viewpoint);
              }

              if (tab.type == 'portfolio-editor') {
                if (data) {
                  $rootScope.$broadcast('change-editor-account', data);
                }
              }

              if (tab.type == 'exclusions') {
                $rootScope.$broadcast('exclusions::update-filter', data);
              }

              // update the data and reinit target edit tab if already open
              if (tab.type == 'targetEdit') {

                if (angular.isDefined(data) && data.type) tab.data ? tab.data.type = data.type : tab.data = data;

                if (angular.isDefined(data) && !angular.isDefined(data.type)) {

                  tab.data = data;
                  $rootScope.$broadcast('update-targets');
                  
                } else if (angular.isDefined(tab.data)) {

                  $rootScope.$broadcast('update-targets');
                } else {

                  $rootScope.$broadcast('update-targets', {
                    newAccount: true
                  });
                }
                return;
              }

              // update selected account in history view
              if (tab.type == 'accountHistory') {
                if (angular.isDefined(data) && !angular.isDefined(data.type)) {
                  tab.data = data;
                  $rootScope.$broadcast('update-accountHistory', data);
                }
              }

              return;
            }
          });

          if (tabOpen) return {
            exists: true,
            type: type
          };
        }

        var startTime = new Date().getTime() / 1000,
          newTab = {
            type: type,
            active: true,

            // TODO: handle this better
            // when tabs.length is 1 (one item in the tabs array),
            // setting the index to tabs.length - 1 on the next new tab will result in two tabs with a 0 index (0, 1 - 1) -> (0, 0)
            // so wait until the length is at least two before we start subtracting one to get the index
            index: tabs.length < 2 ? 1 : tabs.length - 1,
            created: startTime,
            lastActive: startTime
          };

        if (angular.isDefined(accountGuid) && accountGuid != null) {
          newTab.selectedAccountGuid = accountGuid;
        }

        // attach any additional data.
        if (angular.isDefined(data) && data != null) {
          newTab.data = data;
        }

        if (titles[type]) {
          newTab.title = titles[type]; // get the human friendly tab title
        } else if (typeof data !== 'undefined' && data.name) {
          newTab.title = type == 'main' ? data.name : type.replace(/-/g, ' ');
        } else {
          newTab.title = type.replace(/-/g, ' ');
        }

        if (typeof(Dashboard.accounts) != 'undefined' && Dashboard.accounts[0] && type === 'main') {
          newTab.title = $filter('sanitizeSmartXAccountName')(Dashboard.getAccountNameByGUID(accountGuid), 'name');


        } else if (type === 'model') {

          addModelTab(newTab);
          return newTab;

        } else if (type === 'targetEdit') {

          newTab.title = 'Edit Target';

          if (data) {
            newTab.data = data;
          }

        } else if (type == 'rebalanceRequestDetails') {
          newTab.data = data;
        } else if (type === 'portfolio-editor') {

          if (data) {
            var selectedAccount = data;
            selectedAccount.id = data.id;
            selectedAccount.name = data.name;

            newTab.selectedAccount = selectedAccount;
          }
        } else if (type === 'viewpoint') {

          if (angular.isDefined(viewpoint)) {

            if (viewpoint._field_data) {
              newTab.title = 'Viewpoint: ' + viewpoint._field_data.nid.entity.title;
              newTab.body = (typeof viewpoint._field_data.nid.entity.body.und != 'undefined' && viewpoint._field_data.nid.entity.body.und.length) ? viewpoint._field_data.nid.entity.body.und[0].value : '';
              newTab.nid = viewpoint._field_data.nid.entity.nid;
              newTab.related = viewpoint._field_data.nid.entity.field_field_admin_fund_fm_profile;
            } else if (viewpoint.object) {
              newTab.title = 'Viewpoint: ' + viewpoint.title;
              newTab.body = (typeof viewpoint.body.und != 'undefined' && viewpoint.body.und.length) ? viewpoint.body.und[0].value : '';
              newTab.nid = viewpoint.nid;
              newTab.related = viewpoint.field_field_admin_fund_fm_profile;
            } else if (viewpoint.node) {
              newTab.title = 'Viewpoint: ' + viewpoint.node.title;
              newTab.body = (typeof viewpoint.node.body.und != 'undefined' && viewpoint.node.body.und.length) ? viewpoint.node.body.und[0].value : '';
              newTab.nid = viewpoint.nid;
              newTab.related = viewpoint.field_field_admin_fund_fm_profile;
            } else if (viewpoint.nid) {
              newTab.title = 'Viewpoint: ' + viewpoint.title;
              newTab.nid = viewpoint.nid;
            }

          } else {
            newTab.title = "Viewpoint";
          }

        } else if (type === 'pendingAccounts') {

          newTab.title = 'Pending Accounts';

        } else if (type === 'rebalanceAccountsListByInvestment') {

          newTab.title = 'Rebalance Accounts';

        } else if (type === 'rebalanceAccountsListByInvestmentV2') {

          newTab.title = 'Rebalance Accounts';

        } else if (type === 'rebalanceAccountsListByInvestmentV3') {

          newTab.title = 'NEW! Rebalance Accounts';

        } else {
          if (newTab.type === 'portfolio-builder') {
            newTab.title = 'Builder';
          } else {}
        }

        tabs.push(newTab);
        activeTab = tabs[tabs.length - 1];

        if (type === 'main') {
          newTab.title = $filter('sanitizeSmartXAccountName')(newTab.title, 'name');
          lastDashTab = newTab;
        }

        return newTab;

      } else {
        SweetAlert.swal({
          title: 'Error',
          text: 'You have reached the maximum number of tabs. You must close at least one tab before opening a new one.',
          type: 'error',
          allowOutsideClick: true
        });
      }

      // scroll to top whenever a new tab is opened
      window.scrollTo(0, 0);
    };

    var addModelTab = function(newTab) {

      // add new model tab if the nid isn't already in the modelTabs array
      var model = Models.currentModel;
      var modelGuid = model.guid || model.reported_guid; // reported_guid is present when clicking on a model link from within the webinars page
      var modelHasGuid = angular.isDefined(modelGuid) && modelGuid !== null;

      if (modelHasGuid) { // will have a guid when coming from the account page
        var modelId = modelGuid.toLowerCase();
      } else if (model.id) {
        var modelId = model.id;
      }

      var title = model.name || model.title;


      if (modelTabs.indexOf(modelId) == -1) {

        newTab.title = title;
        newTab.modelId = modelId;
        newTab.model = (typeof model.data == 'object') ? model.data : model;

        modelTabs.push(newTab.modelId);
        tabs.push(newTab);


      } else {
        // find the index of the model tab with the same nid and set it to active
        tabs.forEach(function(tab, index) {
          if (tab.type === 'model') {

            if (tab.modelId === modelId) {
              tabOpen = true;
              tabs[index].active = true;
              return;
            }
          }
        });

        // exit without adding a new tab
        return;
      }

    };

    var removeTab = function(index) {

      var promise = $q(function(resolve, reject) {
        if (tabs) {
          resolve(tabs.splice(index, 1)); // returns the removed tab
        } else {
          reject("unable to remove tab");
        }
      });

      return promise;

    };

    var getModelTabs = function() {
      return modelTabs;
    };

    var deleteModelId = function(idx) {


      modelTabs.splice(idx, 1);
    };

    var saveTabs = function() {

      var username = Dashboard.getUserClientID();

      if (typeof tabs == 'undefined' || tabs == null) {
        tabs = {};
      }

      Dashboard.setUserGroupStorage(angular.toJson(tabs))
        .then(function(storageResult) {

          if (typeof storageResult.data != 'undefined') {
            storageResult = angular.fromJson(storageResult.data);
            $rootScope.$broadcast('dashboard-refresh-tabs', storageResult.payload);
          }
        });
    };

    return {
      addTab: addTab,
      removeTab: removeTab,
      loadTabs: loadTabs,
      setTabs: setTabs,
      getTabs: getTabs,
      getActiveTab: getActiveTab,
      setActiveTab: setActiveTab,
      getLastDashboard: getLastDashboard,
      setLastDashboard: setLastDashboard,
      saveTabs: saveTabs,
      getLastActive: getLastActive,
      setLastActive: setLastActive,
      getModelTabs: getModelTabs,
      deleteModelId: deleteModelId
    };
  }]);
