angular.module('SecurityAdministrator')
  .controller("SecurityAdministratorCtrl", function($scope, $element, $q, NgTableParams, $timeout, $modal, $templateCache, Dashboard, toastr, SmartXFactory, Tabs, EnterpriseFactory){
    var vm = this;

    vm.viewOptions = {
      selected: 'users',
      list: [
        {
          id: 'users',
          name: 'Users'
        },
        {
          id: 'enterprises',
          name: 'Enterprises'
        },
        {
          id: 'accounts',
          name: 'Accounts'
        }
        // {
        //   id: 'repCodeAssignment',
        //   name: 'Rep Code Assignment'
        // }
      ]
    };

    vm.loading = false;

    $scope.$on('setLoading', function(e, loading){
      vm.loading = loading;
    });
    
    // methods
    // vm.showCreateUser = showCreateUser;
    // vm.showCreateEnterprise = showCreateEnterprise;
    // vm.showAssignRepCode = showAssignRepCode;
    // vm.showEditUser = showEditUser;
    // vm.viewProfile = viewProfile;
    // vm.deleteUser = deleteUser;
    // vm.loadEnterpriseTab = loadEnterpriseTab;
    // vm.refreshUsers = refreshUsers;
    // vm.selectedEnterprises = [];
    // vm.selectedUsers = [];

    vm.initRepCodeAssignments = initRepCodeAssignments;
    vm.addToQueue = addToQueue;
    vm.selectedChild = '';
    vm.selectedRepCode = '';
    vm.fromEnterprise = '';
    vm.toEnterprise = '';
    vm.repCode = '';
    vm.handleItemSelect = handleRepCodeSelect;
    vm.handleChildSelect = handleChildSelect;

    function handleRepCodeSelect(item){
      vm.selectedRepCode = item;
    }

    function handleChildSelect (item) {
      vm.selectedChild = item;
    }

    function buildAssignmentPayload () {
      var form = [];
      var user = Dashboard.getReplicatorUser();
      var enterpriseId = user.enterprise.id;
      form = vm.queue.map(function(item) {
        return {
          toEnterpriseId: item.childEnterprise.id,
          fromEnterpriseId: enterpriseId,
          repCodeId: item.repCode.id
        };
      });
      
      return form;
    }

    vm.submitRepCodeAssignments = submitRepCodeAssignments;
    function submitRepCodeAssignments () {
      vm.creating = true;
      vm.errors = [];
      var payload = buildAssignmentPayload();

      $q.when(EnterpriseFactory.assignRepCodes(payload))
      .then(function(res){
        console.log("Process Completed: ", res);
        //toastr.success("User created");
        var response = res.data;

        var errors = _.filter(response, function(item){
          return item.status === false;
        });

        if (errors.length) {
          toastr.error("Error Assigning 1 or more Rep Codes");
          vm.errors = errors;
        } else {
          toastr.success("Assignment created");
          vm.queue = [];
          // $uibModalInstance.close(res);
        }
      })
      .catch(function(err){
        toastr.error(err.message);
        console.error(err);
      })
      .finally(function(){
          vm.creating = false;
      });
    }

    function initRepCodeAssignments() {
      var user = Dashboard.getReplicatorUser();
      var enterpriseId = user.enterprise.id;
      var userEnterprise = _.find(vm.enterprisesList, function(enterprise){
        return enterprise.id === enterpriseId;
      });

      vm.repCodes = userEnterprise.repCode;
      vm.childEnterprises = userEnterprise.childEnterprise;

      vm.queue = [];

      vm.repCodeAssignmentsTable = new NgTableParams({},{
        dataset: vm.queue
      });
    }

    vm.repCodeAssignment = {
      fromEnterprise: '',
      repCode: '',
      toEnterprise: ''
    };

    function resetAssignmentForm() {
      _.extend(vm.repCodeAssignment, {
        fromEnterprise: '',
        repCode: '',
        toEnterprise: ''
      });
    }

    function addToQueue (assignment) {
      vm.queue.push({
        fromEnterprise: assignment.fromEnterprise,
        repCode: assignment.repCode,
        toEnterprise: assignment.toEnterprise,
        result: '',
        message: ''
      });

      // vm.selectedRepCode = '';
      // vm.selectedChild = '';
      // vm.toEnterprise = '';
      // vm.repCode = '';
      // vm.fromEnterprise = '';
      resetAssignmentForm();
      vm.repCodeAssignmentsTable.reload();
    }

    // init();

    // vm.refreshEnterprises = refreshEnterprises;
    // function refreshEnterprises () {
    //   loadEnterprises();
    // }

    // function loadEnterprises() {
    //   vm.loading = true;
    //   console.log(vm.enterprisesTable);
    //   EnterpriseFactory.loadEnterpriseLookup()
    //   .then(function(enterprises){
    //     vm.enterprisesList = enterprises;
    //     if (vm.enterprisesTable){
    //       vm.enterprisesTable.settings().dataset = enterprises;
    //       vm.enterprisesTable.reload();
    //     }
    //   })
    //   .catch(function(err){
    //     console.error(err);
    //     toastr.error(err.message);
    //   })
    //   .finally(function(){
    //     vm.loading = false;
    //   });
    // }

    vm.sideMenu = [
      {
        title: 'Users',
        active: true
      },
      {
        title: 'Enterprises',
        active: false
      },
      {
        title: 'Permissions',
        active: false
      }
    ];
  });