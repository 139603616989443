angular.module('RedemptionModal', [])
.controller('RedemptionModalCtrl', ['$scope', '$uibModalInstance', 'Tabs', 'account', function ($scope, $uibModalInstance, Tabs, account) {

    console.log("Opening redemption request modal...");

    console.log("Redemption Ctrl scope: ", $scope);

    var vm = this;

    vm.account = account;
    vm.openCma = openCma;
    vm.withdrawalDisabled = true;
    vm.invalidAmount = false;
    vm.invalidAmountMsg = 'The amount entered is above the account\'s available funds';

    vm.ok = function (redemptionAmount, redemptionNotes) {

        console.log($scope);

        if (redemptionAmount) {
            var data = {
                "amount": redemptionAmount,
                "notes" : redemptionNotes
            };
            $uibModalInstance.close(data);
        }
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.$watch('vm.redemptionAmount', function (newVal, oldVal){
        if (angular.isDefined(newVal)) {

            // check against the model minimum for the SMA model
            var modelMinimum;
            
            modelMinimum = Models.getMinimumById(modelId);

            if (newVal > 0 && (account.statistics.value - newVal) < modelMinimum){
                vm.modelMinimumWarning = true;
            } else {
                vm.modelMinimumWarning = false;
            }

            validateAmount(newVal);
        }
    });

    function formatAmount (amount) {
        // replace dollar sign, commas, and trim extra whitespace
        return amount.toString().replace(/,/g, '').replace('$', '').trim();
    }

    function validateAmount (amount){
        var availableCash = account.statistics.cash;

        amount = formatAmount(amount);

        // if (amount <= availableCash) {
        //     vm.withdrawalDisabled = false
        //     vm.invalidAmount = false
        // } else {
        //     // show withdraw and cma link
        //     vm.withdrawalDisabled = true
        //     vm.invalidAmount = true
        // }
    }

    function openCma () {
        Tabs.addTab('cash-management');
    }
}]);