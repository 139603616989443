import 'angular';
import 'angular-route';
import 'angular-animate';
import 'angular-sanitize';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import './styles/hcv.angular.scss';
import 'bootstrap';
import 'sweetalert/dist/sweetalert.css';
import 'angular-sweetalert';
import 'angular-ui-bootstrap';
import './lib/ngMask.min.js';
import 'angular-toastr';
import 'angular-toastr/dist/angular-toastr.css';
import 'ui-select';
import 'api-check';
import 'angular-formly';
import 'angular-formly-templates-bootstrap';
import 'angular-drag-and-drop-lists';
import 'angular-switcher/dist/angular-switcher';
import 'angularjs-dropdown-multiselect';
import 'angular-bindonce';
// import './lib/highcharts/drilldown.js';
// import './lib/highcharts/highstock.src.js';
// import './lib/highcharts/sankey.js';
// import './lib/highcharts/organization.js';
import 'highcharts-ng';
import 'angular-touch';
import 'angularjs-slider';
import 'angular-cache';
import 'angular-wizard';
import 'moment';
import 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './lib/angular-scrollable-table.js';
import './lib/angular-scrollable-table.css';
import 'angular-bootstrap-grid-tree';
import 'angular-masonry';
import 'ng-idle';
import './ng-table/ng-table.min.js';
import './ng-table/ng-table.min.css';
import 'oidc-client';
import 'papaparse/papaparse.min.js';
import 'font-awesome/css/font-awesome.min.css';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/theme-fresh.css';
import agGrid from 'ag-grid';
import ENV from './smartx/env';
import './lib/amplitude.min.js';
import { init as initFS } from '@fullstory/browser'

window.ENV = ENV;

console.log('smartx-js env: ', ENV);

// app files
import './modules';
import './services';
import './filters';
import './controllers';
import './directives';
import './styles/legacy/formalize.css';
import './styles/legacy/global.scss';



google.charts.load('1', {'packages':['corechart', 'treemap']});

// it comes in like this due to how smartx-js transpiles the router.ts file
// importing sentry in hcv_angular.module and calling Sentry.init prevented things from working when it was also initialized in smartxjs
// if (typeof __SENTRY__ !== 'undefined' && __SENTRY__.hub) {
//   var Sentry = __SENTRY__.hub;
// }
if (_.contains(['live','test'], Drupal.settings.pantheon_environment)) {
  console.log("Logging Enabled");

  init_logging();
}

function init_logging () {
  if (angular.isDefined(Sentry)) { 
    Sentry.init({
      dsn: 'https://584368dfaf72427fa37ea9c9bfd62309@o527931.ingest.sentry.io/5644961',
      release: 'client-ui@3.16.16',
      environment: Drupal.settings.pantheon_environment,
      maxBreadcrumbs: 25,
      ignoreErrors: [
        /Can\'t execute code from freed script/
      ],
      integrations: [
        new Sentry.Integrations.CaptureConsole({
          levels: ['error']
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: false
        }),
        new Sentry.Integrations.BrowserTracing({
          tracingOrigins: [
            'api.smartxadvisory.com', 
            'smartx.smartxadvisory.com/api', 
            'smartx.managedaccounts.io/api'
          ]
        })
      ],
      tracesSampleRate: 1.0,
      // integrations: [new Sentry.Integrations.Breadcrumbs({
      //   console: false
      // })]
      // beforeSend: (event, hint): any => {
      //   console.log(event)
      // }
      beforeSend: function (event) {
        var requestError = function(event){
          return event.category === 'xhr' && event.data && event.data.status_code >= 400;
        };

        if (event.breadcrumbs && event.breadcrumbs.find(requestError)) {
          event.tags = { exceptionType: 'httpRequest' };
        } else {
          event.tags = { exceptionType: 'runtimeException' };
        }
        return event;
      }
    });

    Sentry.configureScope(function(scope){
      scope.setUser({'username': Drupal.settings.username});
    });
  }
}

var checkLocalStorage = function() {
  if (!store.enabled) {
    alert('Local storage is not supported by your browser. Please disable "Private Mode", or upgrade to a modern browser.');
    return;
  }
};

var checkUser = function(user, firstName) {
  var userStorage = store.get('HCV_storage');

  // if the user is already in local storage, load their object
  // otherwise create a new one
  if (angular.isDefined(userStorage)) {
    if (angular.isDefined(firstName)) {
      // true if userStorage is defined and the user exists
      if (angular.isDefined(userStorage) && angular.isDefined(userStorage[user])) {
        return userStorage;
        // if the storage object exists but doesn't contain the current user
      } else {
        userStorage[user] = {};
      }
    } else {
      userStorage = {};
    }

  } else {
    userStorage = {};
    userStorage[user] = {};
  }

  return userStorage;
};

// required for new version of AgGrid
agGrid.initialiseAgGridWithAngular1(angular);

console.log('NODE_ENV: ', process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
  try {
    initFS({ orgId: 'o-1EZ41F-na1' })
  } catch (e) {
    console.error(e)
  }
}

var plugins = [
  'ngAnimate',
  'ngRoute',
  'ngSanitize',
  'oitozero.ngSweetAlert',
  'ui.bootstrap',
  'ngMask',
  'toastr',
  'ui.select',
  'formly', 
  'formlyBootstrap',
  'agGrid',
  'dndLists',
  'ngTable',
  'wu.masonry',
  'switcher',
  'angularjs-dropdown-multiselect',
  'pasvaz.bindonce',
  'highcharts-ng',
  'ngTouch',
  'rzModule', 
  'angular-cache',
  'mgo-angular-wizard',
  'scrollable-table'
];

var app = angular.module('dashboard', plugins.concat([
    'Filters',
    'AllocationHoldings',
    'ModelReportModule',
    'TargetPortfolios',
    'Identity',
    'BillingService',
    'FeatureFlagsService',
    'SecurityAdministrator',
    'BillingModule', 'FeeBlotterFactory',
    'AssignModelToAccountModal',
    'AlertsManagerCtrl',
    'Amplitude',
    'RebalanceRequestDetailsV2',
    'AuthModule',
    'Interceptors', 'Rebalancer', 'BuilderService', 'SettingsService', 'AccountSummary', 'AccountFunding','AccountHistory', 'PendingAccounts', 'PendingDetail', 'HoldingsService', 'colorValue', 'PerformanceChart', 'PortfoliosCtrl', 'infoPopup', 'accountWelcome', 'AllocationService', 'LiquidationService', 'ChartService', 'DashboardService', 'BrokerageService', 'ProfileService', 'SearchFiltersService', 'SideMenu', 'TabsCtrl', 'TabsService', 'ModelService', 'MainCtrl', 'ChartCtrl', 'AllocationCtrl', 'LiquidationCtrl', 'SearchCtrl', 'templates', /* 'OpenBrokerageCtrl',*/ 'HomeCtrl', 'HoldingsCtrl', 'ModelCtrl', 'BuilderCtrl', 'ViewpointCtrl', 'agGrid', 'dndLists', 'AddAccountCtrl', 'AddAccountService', 'editPortfolio', 'ExclusionsCtrl', 'ExclusionsService', 'TargetWeightService', 'TargetWeightModalCtrl', 'TargetWeightCtrl', 'TargetWeightGroups', 'WebinarsCtrl', 'PortfolioEditorFactory', 'AssignTargetToAccountModal', 'PortfolioEditor', 'RebalanceRequestDetails', 'RebalancerFactory', 'HarvestingModule', 'PDFViewerCtrl', 'CRDViewerCtrl', 'SmartXFactory', 'EnterpriseFactory', 'Directives', 'ProtectedPortfolio', 'ModelComparisonCtrl', 'FavoriteModels','Constants'
  ]))
  .value('HTTP_REQUEST_TIMEOUT', Drupal.settings.api_timeout * 100)
  .value('USER', {})
  .value('DASHBOARD_INFO', {})
  .value('FNAME', Drupal.settings.fname)
  .value('API_URL', Drupal.settings.api_url)
  .value('EDITOR_ENABLED', 'on') // Drupal.settings.editor_enabled
  .value('Dashboard_Config', {
    'AllocationView': Drupal.settings.dashboardv2_allocation_view
  })
  .value('AcrDisclaimer', "Our trading system uses the Target Value, staged orders, and real-time portfolio values to create new transactions. Transactions may vary from the initial request, but our trading system will get the sleeve value as close as possible to your target value.")
  .value('HCV_storage', {})
  // .config(['RollbarProvider', function(RollbarProvider) {
  //   if (window.location.hostname.indexOf('localhost') == -1) {
  //     RollbarProvider.init({
  //       accessToken: "b1d45d050678437fb9987dc81953dfa7",
  //       captureUncaught: true,
  //       payload: {
  //         environment: 'dev',
  //         person: {
  //           id: Drupal.settings.username,
  //           username: Drupal.settings.username
  //         }
  //       }
  //     });
  //   }
  // }])
  .config(function(uiSelectConfig) {
    uiSelectConfig.dropdownPosition = 'up';
  })
  .config(function ($provide) {
    $provide.decorator('$exceptionHandler', function () {
      return function (exception, cause) {
        console.error(exception);
        if (cause){
          console.error(cause);
        }
        // if (angular.isDefined(Sentry)) Sentry.captureException(exception);
      };
    });
  })
  .config(function($httpProvider) {
    // $httpProvider.interceptors.push('ApiResponseInterceptor');
    // $httpProvider.interceptors.push('timeoutHttpIntercept');
  })
  .value('SMARTX', true)
  .config(function(toastrConfig) {
    angular.extend(toastrConfig, {
      autoDismiss: true,
      containerId: 'toast-container',
      maxOpened: 0,
      newestOnTop: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      preventOpenDuplicates: true,
      target: 'body',
      timeOut: 5000,
    });
  })
  .config(function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto):/);
  })
  .config(function($animateProvider) {
    // disable ngAnimate classes on drag and drop list items
    //http://stackoverflow.com/questions/21249441/disable-nganimate-for-some-elements/24542131#24542131
    $animateProvider.classNameFilter(/^((?!(dnd-list-item)).)*$/);
  })
  .config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
    $locationProvider.hashPrefix('');
    $routeProvider
      .when('/v3/dashboard', {
        templateUrl: 'home.html', // Path to your main application template (index.html)
      })
      .when('/login', {
        templateUrl: 'login.html', // Path to your login template,
        controller: 'LoginCtrl'
      })
      // .when('/identity/callback', {
      //   templateUrl: './identity/callback.html'
      // })
      .otherwise({
        redirectTo: '/v3/dashboard' // Default route
      });
  }])
  .run(function(formlyConfig, HCV_storage, USER, $window, $rootScope) {

    $rootScope.$on('$routeChangeStart', function (event, next, current) {
      // Check if the next route matches the static path.
      console.log('Next route: ', next);
      console.log('Current route: ', current);
      console.log('Next $$route: ', next.$$route);
      // if (next.$$route.originalPath === '/identity/callback') {
      //   console.log('We matched on the callback route');
      //   // Prevent route activation.
      //   event.preventDefault();
      // }
    });

    var attributes = [
      'date-disabled',
      'custom-class',
      'show-weeks',
      'starting-day',
      'init-date',
      'min-mode',
      'max-mode',
      'format-day',
      'format-month',
      'format-year',
      'format-day-header',
      'format-day-title',
      'format-month-title',
      'year-range',
      'shortcut-propagation',
      'datepicker-popup',
      'show-button-bar',
      'current-text',
      'clear-text',
      'close-text',
      'close-on-date-selection',
      'datepicker-append-to-body',
      'day-format',
      'month-format',
      'year-format',
      'year-range',
      'day-header-format',
      'day-title-format',
      'month-title-format',
      'date-format',
      'date-options',
      'hour-step',
      'minute-step',
      'show-meridian',
      'meridians',
      'readonly-time',
      'readonly-date',
      'hidden-time',
      'hidden-date',
      'mousewheel',
      'show-spinners'
    ];

    var bindings = [
      'datepicker-mode',
      'min-date',
      'max-date',
      'date-disabled',
      'day-format',
      'month-format',
      'year-format',
      'year-range',
      'day-header-format',
      'day-title-format',
      'month-title-format',
      'date-format',
      'date-options',
      'hour-step',
      'minute-step',
      'show-meridian',
      'readonly-time',
      'readonly-date',
      'hidden-time',
      'hidden-date'
    ];
    var ngModelAttrs = {};

    if (typeof amplitude !== 'undefined'){
      try {
        amplitude.getInstance().init(process.env.AMPLITUDE_KEY);
      }
      catch (e) {
        console.error(e);
      }
    }

    if (_.isEmpty(HCV_storage)) {
      HCV_storage[USER] = {};
    }

    Highcharts.setOptions({
      global: {
        useUTC: false
      },
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });

    angular.forEach(attributes, function(attr) {
      ngModelAttrs[attr.camelize()] = {
        attribute: attr
      };
    });

    angular.forEach(bindings, function(binding) {
      ngModelAttrs[binding.camelize()] = {
        bound: binding
      };
    });

    formlyConfig.setType(
      [{
          name: 'account_type',
          templateUrl: 'account_type.html'
        }, {
          name: 'dndList',
          templateUrl: 'dndList.html'
        }, {
          name: 'ima_docs',
          templateUrl: 'ima_docs.html'
        }, {
          name: 'ib_docs',
          templateUrl: 'ib_docs.html'
        }, {
          name: 'filter_checkboxes',
          templateUrl: 'filter_checkboxes.html'
        }, {
          name: 'filter_category_checkboxes',
          templateUrl: 'filter_category_checkboxes.html'
        }, {
          name: 'filter_ranges',
          templateUrl: 'filter_ranges.html'
        }, {
          name: 'filter_radio',
          templateUrl: 'filter_radio.html'
        }, {
          name: 'account_fees_table',
          templateUrl: 'fees-table.html'
        }, {
          name: 'account_fees_table_recap',
          templateUrl: 'feeTableCalculationRecap.html'
        }, {
          name: 'what_are_blended_rates',
          templateUrl: 'whatAreBlendedRates.html'
        }, {
          name: 'multiselect',
          extends: 'select',
          defaultOptions: {
            ngModelAttrs: {
              'true': {
                value: 'multiple'
              }
            }
          }
        }, {
          name: 'datepicker',
          templateUrl: 'datepicker.html',
          wrapper: ['bootstrapLabel', 'bootstrapHasError'],
          defaultOptions: {
            ngModelAttrs: ngModelAttrs,
            templateOptions: {
              datepickerOptions: {
                format: 'MM/dd/yyyy',
                initDate: new Date()
              }
            }
          },
          controller: ['$scope', function($scope) {
            $scope.datepicker = {};

            $scope.datepicker.opened = false;

            $scope.datepicker.open = function($event) {
              $scope.datepicker.opened = true;
            };
          }]
        }, {
          name: 'matchField',
          apiCheck: function() {
            return {
              data: {
                fieldToMatch: formlyExampleApiCheck.string
              }
            };
          },
          apiCheckOptions: {
            prefix: 'matchField type'
          },
          defaultOptions: function matchFieldDefaultOptions(options) {
            return {
              extras: {
                validateOnModelChange: true
              },
              expressionProperties: {
                'templateOptions.disabled': function(viewValue, modelValue, scope) {
                  var matchField = find(scope.fields, 'key', options.data.fieldToMatch);
                  if (!matchField) {
                    throw new Error('Could not find a field for the key ' + options.data.fieldToMatch);
                  }
                  var model = options.data.modelToMatch || scope.model;
                  var originalValue = model[options.data.fieldToMatch];
                  var invalidOriginal = matchField.formControl && matchField.formControl.$invalid;
                  return !originalValue || invalidOriginal;
                }
              },
              validators: {
                fieldMatch: {
                  expression: function(viewValue, modelValue, fieldScope) {
                    var value = modelValue || viewValue;
                    var model = options.data.modelToMatch || fieldScope.model;
                    return value === model[options.data.fieldToMatch];
                  },
                  message: options.data.matchFieldMessage || '"Must match"'
                }
              }
            };

            function find(array, prop, value) {
              var foundItem;
              array.some(function(item) {
                if (item[prop] === value) {
                  foundItem = item;
                }
                return !!foundItem;
              });
              return foundItem;
            }
          }
        },
        {
          name: 'toggleRadioBtns',
          templateUrl: 'toggle-radio-btns.html'
        },
        {
          name: 'help_link',
          templateUrl: 'help-link.html'
        },


        {
          name: 'upload',
          extends: 'input',
          wrapper: ['bootstrapLabel', 'bootstrapHasError'],
          link: function(scope, el, attrs) {
            el.on("change", function(changeEvent) {
              var file = changeEvent.target.files[0];
              if (file) {
                console.log('scope.id', scope.id);
                var fd = new FormData();
                // use key on backEnd
                fd.append('uploadFile', file);
                scope.$emit('fileToUpload', fd);
                var fileProp = {};
                for (var properties in file) {
                  if (!angular.isFunction(file[properties])) {
                    fileProp[properties] = file[properties];
                  }
                }
                scope.fc.$setViewValue(fileProp);
              } else {
                scope.fc.$setViewValue(undefined);
              }
            });
            el.on("focusout", function(focusoutEvent) {
              // dont run validation , user still opening pop up file dialog
              if ($window.document.activeElement.id === scope.id) {
                // so we set it untouched
                scope.$apply(function(scope) {
                  scope.fc.$setUntouched();
                });
              } else {
                // element losing focus so we trigger validation
                scope.fc.$validate();
              }
            });

          },
          defaultOptions: {
            templateOptions: {
              type: 'file'
            }
          }
        }

      ]
    );
  });

  /* HELPER FUNCTIONS */
  if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      'use strict';
      if (typeof start !== 'number') {
        start = 0;
      }
      
      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }

  String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  String.prototype.camelize = function(string) {
    if (typeof string == 'undefined') return '';

    string = string.replace(/[\-_\s]+(.)?/g, function(match, chr) {
      return chr ? chr.toUpperCase() : '';
    });
    // Ensure 1st char is always lowercase
    return string.replace(/^([A-Z])/, function(match, chr) {
      return chr ? chr.toLowerCase() : '';
    });
  };

  Array.prototype.move = function(old_index, new_index) {
    if (new_index >= this.length) {
      var k = new_index - this.length;
      while ((k--) + 1) {
        this.push(undefined);
      }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this; // for testing purposes
  };
  /* HELPER FUNCTIONS */



