// angular.module('formatBrokerName', [])
angular.module('Filters')
.filter('formatBrokerName', function(){
	var brokerNames = {
		schwab : 'Charles Schwab',
		tdAmeritrade: 'TD Ameritrade',
		interactiveBrokers: 'Interactive Brokers',
		fidelity: 'Fidelity',
		apex: 'Apex'
	}
	return function (input) {
		if (typeof brokerNames[input] != 'undefined') return brokerNames[input];
		else return input;
	};
})