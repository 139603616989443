/**
* Reporting Factory Module
*
* Endpoints for getting report data. These endpoints are located in hcv_reporting.module
* Deprecated. Endpoints are now called from smartx-js
*/
angular.module('ReportingFactory', []).factory('ReportingFactory', ['$http', function($http){

	var ReportingFactory = this;

	return ReportingFactory;
}]);