angular.module('Directives')
.directive('statisticsTable', ['$templateCache', function($templateCache){
	
	return {
		scope: {
			statistics: '=',
			portfolio: '=',
			benchmarkName: '=',
			exclude: '=?'
		},
		restrict: 'E', // E = Element, A = Attribute, C = Class, M = Comment
		template: $templateCache.get('statisticsTable.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {
			if (angular.isDefined($scope.exclude) && angular.isDefined($scope.statistics)){
				$scope.exclude.forEach(function(exclusion){
					delete $scope.statistics[exclusion]
				})
			}
		}
	};
}]);