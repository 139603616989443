
angular.module('HomeCtrl')
  .controller("WelcomeCtrl", function($q,$window, FeatureFlags, EnterpriseFactory, Brokerage, $scope, $rootScope, $http, $uibModal, Dashboard, Models, Tabs, USER, RebalancerFactory, SmartXFactory, toastr, $timeout) {

    var slides = $scope.slides = [];
    var currIndex = 0;
    var acctsAPI;
    var performanceApi = SmartXFactory.getPerformanceAPI();
    var accountApi = SmartXFactory.getAccountAPI();

    // this event is emitted once the user's access has been set from MenuCtrl
    $rootScope.$on('dashboard-account-access-set', function(event, access) {
      $scope.accessGroups = access.groups;
      $scope.accessAccounts = access.accounts;
      $scope.accessModels = access.models;
      $scope.platformName = access.platform.name;
    });

    // $rootScope.$on('account-rebalance-set', function () {
    //   var numberOfAccountsToRebalance = RebalancerFactory.getNumberOfAccountsToRebalance();
    //   $timeout(function(){
    //     $scope.numberOfAccountsToRebalance = angular.isDefined(numberOfAccountsToRebalance) ? numberOfAccountsToRebalance : '--';
    //   });
    // });

    $scope.hasAccounts = false;
    $scope.sortTopModelsBy = 'YTD';

    $scope.myInterval = 10000;
    $scope.noWrapSlides = false;
    $scope.active = 0;
    $scope.numberOfAccounts = undefined;
    $scope.numberOfPendingAccounts = undefined;
    $scope.numberOfAccountsToRebalance = undefined;
    $scope.numberOfUnassignedAccounts = undefined;
    $scope.welcomePayload = {};
    $scope.trendingProducts = {
      topYtd: [],
      recentlyAddedModels: [],
      topQtd: [],
      recentManagerCommentary: []
    };
    $scope.welcomeBlocks = [];
    $scope.modelCarousel = [];
    $scope.ytdWinners = [];
    $scope.qtrWinners = [];
    $scope.dailyWinners = [];
    $scope.logEvent = logEvent;


    function init() {

      $scope.platform = Dashboard.getPlatform();

      if (typeof $scope.platform == 'undefined') {
        console.warn("Could not get welcome payload due to missing platform");
        return;
      }

      $scope.summaryView = FeatureFlags.isEnabled('newAccountSummary') ? 'accountsSummaryPaged' : 'accountsSummary'; // defaults to 'accountsSummaryPaged'
      $scope.noTargetFilter = FeatureFlags.isEnabled('newAccountSummary') ? { targetId: 'noTarget' } : { targetName: 'No Target' };
      $scope.activeAccountsFilter = { status: 'active' };

      $scope.supportEmail = $scope.platform.name == "SMArtX" ? "support@smartxadvisory.com" : "support@smartxadvisory.com";
      $scope.loadingWelcomePayload = true;

      // getAccountsFromFactory();
      getAccountCounts();
      getRecentlyAddedModelsFromFactory();
      getRecentlyAddedCommentaryFromFactory();
      getTopPerformingModels();

      Dashboard.getWelcomePayload()
        .then(function(res) {

          $scope.welcomePayload = res.data;

          var maxItem = 5;
          for (var key in $scope.welcomePayload.topPerformingModels) {
            if (!$scope.welcomePayload.topPerformingModels.hasOwnProperty(key)) continue;
            var obj = $scope.welcomePayload.topPerformingModels[key];
            obj.information.guid = key;
          }

          // var numberOfAccountsToRebalance = RebalancerFactory.getNumberOfAccountsToRebalance();

          var maxTrendingModels = 3;
          $scope.ytdWinners = angular.copy($scope.welcomePayload.topPerformingModels);
          $scope.ytdWinners = _.values($scope.ytdWinners);
          $scope.ytdWinners = $scope.ytdWinners.sort(function(a, b) {
            return parseFloat(b.statistics.ytd) - parseFloat(a.statistics.ytd);
          });
          $scope.ytdWinners = $scope.ytdWinners.splice(0,maxTrendingModels);
          // console.log('YTR Winners', $scope.ytdWinners);

          $scope.qtrWinners = angular.copy($scope.welcomePayload.topPerformingModels);
          $scope.qtrWinners = _.values($scope.qtrWinners);
          $scope.qtrWinners = $scope.qtrWinners.sort(function(a, b) {
            return parseFloat(b.statistics.qtd) - parseFloat(a.statistics.qtd);
          });
          $scope.qtrWinners = $scope.qtrWinners.splice(0,maxTrendingModels);

          $scope.dailyWinners = angular.copy($scope.welcomePayload.topPerformingModels);
          $scope.dailyWinners = _.values($scope.dailyWinners);
          $scope.dailyWinners = $scope.dailyWinners.sort(function(a, b) {
            return parseFloat(b.statistics.today) - parseFloat(a.statistics.today);
          });
          $scope.dailyWinners = $scope.dailyWinners.splice(0,maxTrendingModels);

        // $scope.numberOfAccountsToRebalance = numberOfAccountsToRebalance;
        $scope.loadingWelcomePayload = false;

        setCustomContactInfo();

      })
      .catch(function(res) {
        $scope.loadingWelcomePayload = false;
        console.error("error getting welcome payload");
      });

      function setCustomContactInfo () {
        var customContactInfo = EnterpriseFactory.getContactInfo() || {};
        if (customContactInfo.block) {
          $scope.welcomePayload.userEnterpriseContact.block = customContactInfo.block;
        } else {
          if (customContactInfo.phone || customContactInfo.email) {
            $scope.welcomePayload.userEnterpriseContact.name = customContactInfo.name;
            $scope.welcomePayload.userEnterpriseContact.phone = customContactInfo.phone;
            $scope.welcomePayload.userEnterpriseContact.email = customContactInfo.email;
          }
        }
        
      }

      // $scope.loadingWelcomeResearch = true;
      // Dashboard.getWelcomeResearchPayload()
      //   .then(function(res) {

      //     $scope.loadingWelcomeResearch = false;
      //     // console.log("Welcome research payload: ", res);
      //     $scope.welcomeResearchPayload = res.data;

      //     if($scope.welcomeResearchPayload && $scope.welcomeResearchPayload.managerCommentary.length){
      //       $scope.welcomeResearchPayload.managerCommentary = $scope.welcomeResearchPayload.managerCommentary.splice(0,maxTrendingModels);
      //     }

      //   })
      //   .catch(function(res) {
      //     $scope.loadingWelcomeResearch = false;
      //     console.error("error getting welcome research payload");
      //   });

      $scope.welcomeBlocks = USER.welcome_blocks ? USER.welcome_blocks.split(',') : [];
    }
    
    function logEvent(event){
      try {
        amplitude.getInstance().logEvent(event);
      } catch (e) {
        console.error(e);
      }
    }

    $scope.addTab = function (type, data) {

			var tab = Tabs.addTab(type, null, null, data);

			if (tab) {
				tab.new = true;

				if (type === 'holdings') {

					// update the selected account in the holdings view
					Dashboard.notifyObservers('main');
				}

        if (tab.exists) {
          if (tab.type === 'accountsSummaryPaged') {
            $rootScope.$broadcast('refresh-accounts-summary', data);
          }
        }

				$scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);

			}

			return tab;
		};

    // pageSection is a string that tells us which section of the page triggered this
    $scope.addTabForModel = function(model, pageSection) {

      var models = Models.getModelList();
      var result = _.find(models, {id: model.guid}); // Models.getReportedModelId(model.model ? model.model.name : (model.information ? model.information.name : model.name));
      var reportedId;

      if (result) {
        reportedId = result.reportedModelId;
      }

      // OLD LOGIC: the structure of the model object differs based on whether it comes from the replicator or from a drupal node
      // var id = model.id || (model.model ? (model.model.reportedModelId || model.model.id) : (model.smartx_guid || model.reported_guid));

      // guid is the model id returned by the performance engine response
      var id = model.guid;

      if (!id) id = model.information ? model.information.guid : null;

      if (!angular.isDefined(id) || id == null) {
        toastr.error("Model ID not found.");
      } else {
        
        Models.currentModel = model.model ? model.model : (model.information ? model.information : model);
        Models.currentModel.guid = id;
        
        if (reportedId) Models.currentModel.reported_guid = reportedId;

        Tabs.addTab('model');
        logEvent("welcome:"+pageSection);
      }

    };

    $scope.addTabForViewpoint = function(viewpoint) {

      if (typeof viewpoint != 'undefined') {

        Models.currentViewpoint = viewpoint;
        $window.scrollTo(0, 0);
        Tabs.addTab('viewpoint', null, viewpoint);
        logEvent("welcome:recentCommentary");
      }
    };

    $scope.enterpriseWelcomeBlockContains = function(blockName) {
      return ($scope.welcomeBlocks.length > 0) ? $scope.welcomeBlocks.indexOf(blockName) >= 0 : true;
    };

    // set up initial values and get some data
    init();

    function getAccountCounts () {
      if(typeof SmartXFactory.getToken() !== 'undefined') {

        $scope.loadingCounts = true;
        $q.when(accountApi.getAccountCounts())
        .then(function(res){
          var counts = res.data;

          $scope.numberOfUnassignedAccounts = counts.noTargetCount;
          $scope.numberOfAccountsToRebalance = counts.rebalanceCount;
          $scope.numberOfAccounts = counts.activeCount;

          USER.active_accounts = $scope.numberOfAccounts;

          if (counts.rebalanceCount) {
            RebalancerFactory.setNumberOfAccountsToRebalance(counts.rebalanceCount);
          }

          $rootScope.$broadcast('HasActiveAccount', USER.active_accounts);

        })
        .catch(function(err){
          // combined data assignments from the catch blocks of the original calls to SmartXFactory.getAccountsWithMetadata
          Dashboard.toastError(err.message, err);
          USER.active_accounts = 0;
          $scope.numberOfUnassignedAccounts = '--';
          $scope.numberOfAccountsToRebalance = '--';
          $scope.numberOfAccounts = '--';
          $rootScope.$broadcast('HasActiveAccount', null);
        })
        .finally(function(){
          $scope.loadingCounts = false;
        });
      } else {
        $scope.numberOfAccounts = '--';
        $scope.numberOfPendingAccounts = '--';
        $scope.numberOfAccountsToRebalance = '--';
      }
    }

    function getAccountsFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        $scope.loadingAccounts = true;
        $scope.loadingTargets = true;

        // SmartXFactory.getAccountTargets()
        SmartXFactory.getAccountsWithMetadata({
          status: 'active',
          hasTargets: false,
          permission: 'fullAccess'
        })
        .then(function(res){
          // var metadata = res.data.data;
          // var unassignedAccounts = accountTargets.filter(function(account){
          //   return !account.targets.length && account.account.permission === 'fullAccess';
          // });

          $timeout(function(){
            $scope.numberOfUnassignedAccounts = res.metaData ? res.metaData.totalRecordCount : '--';
          });
        })
        .catch(function(err){
          console.error(err);
          $scope.numberOfUnassignedAccounts = 0;
        })
        .finally(function(){
          $scope.loadingAccounts = false;
        });

        var params = {
          status: 'active',
        };

        // SmartXFactory.getAccounts()
        //.then(function(resp) {
        SmartXFactory.getAccountsWithMetadata(params)
        .then(function(res){
          var total = res.metaData.totalRecordCount;
          console.log('Active accounts', total);

          // SmartXFactory.setAccounts(resp);
          $scope.numberOfAccounts = total;
          USER.active_accounts = $scope.numberOfAccounts;

          EnterpriseFactory.setActiveAccountEnterprises(res.metaData.enterprises);
          Brokerage.setActiveAccountBrokerages(res.metaData.brokerages);

          $rootScope.$broadcast('HasActiveAccount', USER.active_accounts);

          // Dashboard.setUserAccounts(resp);
        })
        .catch(function (err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
          $scope.numberOfAccounts = '--';
          $scope.numberOfPendingAccounts = '--';
          USER.active_accounts = 0;
          $rootScope.$broadcast('HasActiveAccount', null);

        })
        .finally(function() {
          $scope.loadingTargets = false;
        });

      } else {
        $scope.numberOfAccounts = '--';
        $scope.numberOfPendingAccounts = '--';
      }

    }

    function getRecentlyAddedModelsFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        // var models = Models.getModelList()
        var performanceApi = SmartXFactory.getPerformanceAPI();

        performanceApi.modelsSummary()
        .then(function(res){
          Dashboard.setModelSummaries(res);

          var models = res.data.payload;
          // var missingStartDateCount = 0;
          models = _.map(models, function(model, id){
            var drupalProfile = model.profile;
            model.information.id = id;

            model = model.information;
            model.hcvStartDate = moment(model.hcvPerformanceStartDate * 1000).format();
            model.startDate = moment(model.performanceStartDate * 1000).format();
            model.profile = drupalProfile;
            model.createdInProfile = drupalProfile.created;
            model.profileFieldStartDate = _.property(['field_start_date', 'und', 0, 'value'])(drupalProfile);

            // if (!model.profileFieldStartDate) {
            //   missingStartDateCount++;
            // }

            return model;
          });

          // console.log("# Missing Start Date: ", missingStartDateCount);

          $scope.trendingProducts.recentlyAddedModels = _.sortBy(models, function(model){
            var timestamp = model.profileFieldStartDate || model.createdInProfile;
            // if start tracking is in the future, default to the createdOn
            // start tracking is set to a future date for some static models
            if (moment(timestamp * 1000).unix() > moment().unix()) timestamp = model.createdOn;
            return -moment(timestamp * 1000).unix();
          }).slice(0, 10);

          // console.log("Recent models: ", $scope.trendingProducts.recentlyAddedModels);
        })
        .catch(function(err){
          console.error(err);
        })
        .finally(function(){
          $scope.$apply();
        });

        // if (models && models.length && models[0].createdOn) {
        //   $scope.trendingProducts.recentlyAddedModels = _.sortBy(models, function(model){
        //     var timestamp = model.startTrackingDate
        //     // if start tracking is in the future, default to the createdOn
        //     // start tracking is set to a future date for some static models
        //     if (moment(model.startTrackingDate).unix() > moment().unix()) timestamp = model.createdOn
        //     return -moment(timestamp).unix()
        //   }).slice(0, 10)

        //   console.log($scope.trendingProducts.recentlyAddedModels)
        // } else {
        //   console.log(Models.getModelList());

        //   SmartXFactory.getRecentlyAddedModels(10)
        //   .then(function(resp) {

        //     $scope.trendingProducts.recentlyAddedModels = resp;
        //     console.log('SmartXFactory/getRecentlyAddedModels', resp);
        //     $scope.$apply();

        //   })
        //   .catch(function (err) {
        //     console.log(err.message);
        //   });
        // }
      }
    }

    function getRecentlyAddedCommentaryFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {
        var platformModelIds = _.pluck(Models.getModelList(), 'id');
        $scope.loadingWelcomeResearch = true;
        SmartXFactory.getRecentlyAddedCommentary(10)
        .then(function(resp) {

          $timeout(function(){

            // check to see if the related models for the commentary are in our list of platform models
            // this will exclude commentaries related to models that we do not have access to
            var filteredCommentaries = resp.map(function(commentary){
              try {              
                var relatedModels = commentary.related_models;
                var filteredRelatedModels = relatedModels.filter(function(_model){
                  return _.contains(platformModelIds, _model.smartx_guid);
                });
                commentary.related_models = filteredRelatedModels;
              } catch (e) {
                console.error(e);
              }

              return commentary;
            })
            .filter(function(commentary){
              return commentary.related_models && commentary.related_models.length;
            });

            $scope.trendingProducts.recentManagerCommentary = filteredCommentaries;
            // $scope.trendingProducts.recentManagerCommentary = resp;
            console.log('SmartXFactory/getRecentlyAddedCommentary', resp);
          });
        })
        .catch(function (err) {
          console.log(err.message);
        })
        .finally(function(){
          $timeout(function(){
            $scope.loadingWelcomeResearch = false;
          });
        });

      }

    }


    function getTopPerformingModels () {
      if(typeof SmartXFactory.getToken() !== 'undefined') {

        performanceApi.modelsSorted({
          sortBy: 'ytd',
          limit: 5,
          order: 'desc'
        })
        .then(function(ytdResponse) {
          var results = ytdResponse.data.models;
          $timeout(function(){
            $scope.trendingProducts.topYtd =  _.sortBy(results, function (result) { return -result.statistics.ytd; });
            performanceApi.modelsSorted({
              sortBy: 'qtd',
              limit: 5,
              order: 'desc'
            })
            .then(function(qtdResponse) {
              var results = qtdResponse.data.models;
              $timeout(function(){
                $scope.trendingProducts.topQtd = _.sortBy(results, function (result) { return -result.statistics.qtd; });
                return Promise.resolve($scope.trendingProducts.topQtd);
              });
            })
            .catch(function(err){
              return Promise.reject(err);
            });
          });

          return null;
        })
        .catch(function(err){
          console.error(err);
        });
      }
    }


  })
  .filter('htmlToPlaintext', function() {
    return function(text) {
      return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
  });
