angular.module('FeatureFlagsService', [])
.factory('FeatureFlags', function(Dashboard, EnterpriseFactory){

  var features;

  var featureDefinitions = {
    taxharvest: "c4534d71-4ae5-44ca-9d5e-512cd05ef951",
    symbolsubstitution: "85c08ef6-b409-4027-bf69-27093d9fbbbf",
    symbolexclusion: "9e736908-0766-4453-82b5-ad9e0f433039",
    dealbook: "9e9d952e-6618-4ed7-b1af-ec9de9192ff8",
    noChat: "3e3e6c67-7a02-4455-afee-cab1c4780e6c",
    noAccountOpening: "ce595474-6226-4990-8e95-50661adfc939",
    noModifyLeverage: "d2a12893-3890-449c-b4a5-7c1cad826f93",
    newAccountSummary: "cb32f081-fc4c-4a9a-bb3a-17712ef20fc9",
    noTargetFromProposal: "b5a23f4b-b2b9-430e-80ae-ec26ae1105d9",
    cmadefaultrules: "3c05e07d-9f43-42dd-9690-494c73f49073",
    billing: "498fe0c9-5bb0-42bb-bba1-6d55e5a18fff",
    fundingsleevesummary:"46f96de6-72da-446c-aeb4-e7f49bad51db",
    enterpriseMultiSelect: "c8ab70c8-d077-4ca5-afb0-92d7720dbc8d",
    sleeveTransfer: "9308e41c-1383-4ec5-a0c7-0854f3343fbc",
    alerts: "9fd0b429-de61-42b2-b3b3-14cae0987fda",
    insufficientFunds: "9dc36f1d-2701-435a-a2dd-b18bcaf4c720",
    taxTransition: "9af05cff-66a6-4063-af20-df476ed95c41"
  };

  function setUserFeatures (_features) {
    // console.log("Set feature flags: ", _features);
    features = _features;
  }

  function getUserFeatures () {
    return features;
  }

  function getFeaturesByType(type){
    var groupedFeatures = _.groupBy(features, 'relationshipType');
    return groupedFeatures[type];
  }

  // customList allows a subset of the entire features list to be passed in
  // if no customList is supplied, the default will be the list of features that were set from the GET users/access response
  // example: get a list by type and use that as the customList
  function getAssignmentsByFeature (featureId, customList) {
    var items = customList || features;
    var assignmentFeatures = _.map(items, 'feature');
    return assignmentFeatures.filter(function(feature){ return feature.id === featureId; });
  }

  function getAssignmentsByOwnerId (ownerId, customList) {
    var items = customList || features;
    return items.filter(function(feature){ return feature.ownerId === ownerId; });
  }

  function normalizeName (name) {
    return name ? name.toLowerCase().replace(/ /g, '') : '';
  }

  function isEnabled (feature) {
    
    // look up the id in the feature definitions list
    var featureId = featureDefinitions[feature];

    // console.log("Feature Id for " + feature + ": " + featureId);

    var enterpriseAssignment = _.find(features, function(feature) {
      return feature.type === 'enterprise' && feature.feature.id === featureId;
    });

    var userAssignment = _.find(features, function(feature) {
      return feature.type === 'user' && feature.feature.id === featureId;
    });

    var userIsActive = userAssignment ? userAssignment.isActive : false;
    var enterpriseIsActive = enterpriseAssignment ? enterpriseAssignment.isActive : false;

    switch (feature) {
      case 'noModifyLeverage':
        var topLevelDrupalEnterprise = EnterpriseFactory.getTopLevelDrupalEnterprise();

        return topLevelDrupalEnterprise ? topLevelDrupalEnterprise.leverageDisabled : false;

      // enterprise or user level
      case 'taxharvest':
      case 'symbolsubstitution':
      case 'symbolexclusion':
      case 'alerts':
      case 'billing':
      case 'cmadefaultrules':
      case 'fundingsleevesummary':
      case 'dealbook':
      case 'enterpriseMultiSelect':
      case 'sleeveTransfer':
      case 'insufficientFunds':
      case 'taxTransition':
        if (userAssignment && userIsActive === false) return false;
        else return enterpriseIsActive || (userAssignment && userIsActive) || false;

      // enterprise level only
      case 'noAccountOpening':
      case 'noChat':
      case 'noTargetFromProposal':
        return enterpriseIsActive;
      
      // hardcoded on
      case 'newAccountSummary':
        return true;
    }
  }
  
  function getAllowedByBroker (featureName, brokerageName) {
    var featureId = featureDefinitions[featureName];
    var brokerFeatures = _.filter(features, function (feature) { 
      return feature.type === 'brokerage' && feature.feature.id === featureId; 
    });
    console.log(brokerFeatures);
    var brokerageAssignment = _.find(
                                brokerFeatures,
                                function(feature) {
                                  return normalizeName(feature.owner.name) === normalizeName(brokerageName);
                                }
                              );

    return brokerageAssignment ? brokerageAssignment.isActive : false;
  }

  return {
    getUserFeatures: getUserFeatures,
    setUserFeatures: setUserFeatures,
    getFeaturesByType: getFeaturesByType,
    getAssignmentsByFeature: getAssignmentsByFeature,
    getAllowedByBroker: getAllowedByBroker,
    isEnabled: isEnabled,
    getAssignmentsByOwnerId: getAssignmentsByOwnerId
  };  
});