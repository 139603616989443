angular.module('SecurityAdministrator')
.directive('securityAdminEnterprises', function($templateCache) {                                                                                                                                       
  return {
    restrict: 'E',
    scope: {},
    template: $templateCache.get('securityAdminEnterprises.tpl.html'),
    controller: securityAdminEnterprisesCtrl,
    controllerAs: 'vm'
  };                                                                                  
  
  function securityAdminEnterprisesCtrl (EnterpriseFactory, $templateCache, $modal, Dashboard, SmartXFactory, toastr, NgTableParams, $scope, $q, $element, Tabs){
    var vm = this;
    var userApi = new SmartX.UserApi();
    var enterprisesApi = SmartXFactory.getEnterprisesAPI();

    vm.showCreateEnterprise = showCreateEnterprise;
    vm.showAssignRepCode = showAssignRepCode;
    // vm.loadEnterpriseTab = loadEnterpriseTab;
    vm.viewRepCodes = viewRepCodes;
    vm.selectedEnterprises = [];
    // vm.initRepCodeAssignments = initRepCodeAssignments;
    // vm.addToQueue = addToQueue;
    vm.selectedChild = '';
    vm.selectedRepCode = '';
    vm.fromEnterprise = '';
    vm.toEnterprise = '';
    vm.repCode = '';
    // vm.handleItemSelect = handleRepCodeSelect;
    // vm.handleChildSelect = handleChildSelect;
    vm.refreshEnterprises = refreshEnterprises;
    vm.loading = false;


    vm.enterpriseCheckboxes = {
      checked: false,
      items: {}
    };

    vm.nameFilter = {
      'name': {
        id: "text",
        placeholder: "Enter an enterprise"
      }
    };

    vm.parentFilter = {
      'parent.name': {
        id: "text",
        placeholder: "Enter a parent enterprise"
      }
    };


    init();

    // watch for check all checkbox
    $scope.$watch(function() {
      return vm.enterpriseCheckboxes.checked;
    }, function(value) {
      angular.forEach(vm.enterprisesList, function(item) {
        vm.enterpriseCheckboxes.items[item.id] = value;
      });

      vm.selectedEnterprises = getSelected('enterprise');
      // console.log('Selected Enterprises: ', vm.selectedEnterprise);
    });
    
    // watch for data checkboxes
    $scope.$watch(function() {
      return vm.enterprisesList && (vm.enterprisesList.length && vm.enterpriseCheckboxes.items);
    }, function(values) {

      if (!angular.isDefined(vm.enterprisesList)) return false;

      var checked = 0, unchecked = 0,
          total = vm.enterprisesList.length;
      angular.forEach(vm.enterprisesList, function(item) {
        checked   +=  (vm.enterpriseCheckboxes.items[item.id]) || 0;
        unchecked += (!vm.enterpriseCheckboxes.items[item.id]) || 0;
      });
      if ((unchecked == 0) || (checked == 0)) {
        vm.enterpriseCheckboxes.checked = (checked == total);
      }

      vm.selectedEnterprises = getSelected('enterprise');
      // console.log('Selected Enterprises: ', vm.selectedEnterprise);

      // grayed checkbox
      angular.element($element[0].getElementsByClassName("enterprise-select-all")).prop("indeterminate", (checked != 0 && unchecked != 0));
    }, true);

    function getSelected(type){
      var checkedItems = vm[type + 'Checkboxes'].items;
      // var filters = vm.usersTable.settings().filter();
      var table = vm[type + 'sTable'];
      var selected = [];

      if (table){
        var data = vm[type + 'sTable'].settings().dataset;
      // data = $filter('filter')(data)(filters);

        selected = _.filter(data, function(item){
          return checkedItems[item.id];
        });
      }

      // console.log("Items Selected: ", selected);
      return selected;
    }

    function refreshEnterprises () {
      loadEnterprises();
    }

    function loadEnterprises() {
      vm.loading = true;
      // $scope.$emit('setLoading', true);
      EnterpriseFactory.loadEnterpriseLookup()
      .then(function(enterprises){
        vm.enterprisesList = enterprises;
        if (vm.enterprisesTable){
          vm.enterprisesTable.settings().dataset = enterprises;
          vm.enterprisesTable.reload();
        }
      })
      .catch(function(err){
        console.error(err);
        toastr.error(err.message);
      })
      .finally(function(){
        vm.loading = false;
        // $scope.$emit('setLoading', false);
      });
    }

    function showCreateEnterprise () {
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('createEnterpriseModal.tpl.html'),
        resolve: {
          currentUser: function () {
            return Dashboard.getReplicatorUser();
          },
          userApi: function () {
            return userApi;
          },
          enterprisesApi: function () {
            return SmartXFactory.getEnterprisesAPI();
          },
          enterpriseUsers: function(){
            return vm.enterpriseUsers;
          }
        },
        controller: 'SecurityAdminCreateEnterpriseCtrl',
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(newUser) {
        if (newUser) {
          toastr.success("Enterprise created");
          refreshEnterprises();
        }
      });
    }

    function viewRepCodes (enterprise) {

      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'repCodesListModal.html',
        resolve: {
          enterprisesApi: function (){
            return SmartXFactory.getEnterprisesAPI();
          },
          enterprise: function (){
            return enterprise;
          }
        },
        controller: function(enterprise, enterprisesApi, $q, $uibModalInstance, NgTableParams) {

          var vm = this;

          vm.enterpriseName = enterprise.name;
          vm.loading = true;

          vm.repCodeTypes = {
            'primaryRepCode': 'Primary',
            'masterRepCode': 'Master'
          }; 

          $q.when(enterprisesApi.get(null, {enterpriseId: enterprise.id}))
          .then(function(res){
            var data = _.property(['data', 'data'])(res);
            var repCodes = data.repCode;

            vm.repCodes = repCodes;

            vm.repCodesTable = new NgTableParams({},{
              dataset: vm.repCodes,
              counts: []
            });
          })
          .catch(function(err){
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            vm.loading = false;
          });

          vm.cancel = $uibModalInstance.dismiss;

        },
        controllerAs: 'vm'
      });
    }

    function init () {
      vm.loading = true;
      // $scope.$emit('setLoading', true);

      EnterpriseFactory.loadEnterpriseLookup()
      .then(function(res){
        var enterprises = res;
        vm.enterprisesList = enterprises;
        vm.enterprisesTable = new NgTableParams({},{
          dataset: enterprises
        });
      })
      .catch(function(err){
        console.error(err);
        toastr.error(err.message);
      })
      .finally(function(){
        vm.loading = false;
        // $scope.$emit('setLoading', false);
      });
    }

    function showAssignRepCode () {
      var sampleResponseError = [
        {
          "id": "4c0d0a8f-70a3-4c0a-91e6-11486acd18fa",
          "status": true,
          "message": "Success"
        },
        {
          "id": "4c0d0a8f-70a3-4c0a-91e6-11486acd18fa",
          "status": false,
          "message": "The RepCode Id 4c0d0a8f-70a3-4c0a-91e6-11486acd18fa does not exist for Enterprise Test Advisors"
        },
        {
          "id": "4c0d0a8f-70a3-4c0a-91e6-11486acd18fa",
          "status": false,
          "message": "The RepCode Id 4c0d0a8f-70a3-4c0a-91e6-11486acd18fa does not exist for Enterprise Test Advisors"
        }
      ];

      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('assignRepCodeModal.tpl.html'),
        resolve: {
          userEnterprise: function () {
            var user = Dashboard.getReplicatorUser();
            var enterpriseId = user.enterprise.id;
            var userEnterprise = _.find(vm.enterprisesList, function(enterprise){
              return enterprise.id === enterpriseId;
            });
            return userEnterprise;
          },
          enterprises: function () {
            return vm.enterprisesList;
          },
          enterprisesApi: function () {
            return SmartXFactory.getEnterprisesAPI();
          },
        },
        controller: 'SecurityAdminAssignRepCodeCtrl',
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(complete) {
        if (complete) {
          // toastr.success("Enterprise created!");
          init();
        }
      });
    }
  }
});