// color number strings based on their value
// pass in a value and it'll color it red if it's < 0, black if not
angular.module('colorValue', [])
.directive("colorValue",function(){

	return{
	    scope: {
	    	colorValue: '='
	    },
	    link:function(scope,element){

	    	// prevents cells from getting stuck on the color that was set the first time
	    	// this causes the cell to be repainted each time the data changes
	    	scope.$watch('colorValue', function(newVal, oldVal){
	    		if (newVal !== oldVal){
	    			colorValues();
	    		}
	    	});

	    	// set the initial color
	    	colorValues();

	    	function colorValues(){
	    		var value = scope.colorValue, 
			    	isDown = (parseFloat(value, 10) < 0);

			    if ( isDown ){

			      	element[0].style.color = '#f26203';

				} else {

		      		element[0].style.color = '#000';
		      	}
	    	}
	    }
	};
       
});