// angular.module('TimeFromNowFilter', [])
angular.module('Filters')
.filter('timeFromNow', function(){

    function timeFilter(time, currentTime){

		var timeFromNow;
		
    	if (angular.isDefined(currentTime)){
    		timeFromNow = moment(time).from(currentTime);
    	} else {
			timeFromNow = moment(time).fromNow();
    	}

        return timeFromNow;
    }

    

    return timeFilter;
});