angular.module('correlationMatrix', [])
.directive('correlationMatrix', correlationMatrix);

function correlationMatrix(Dashboard, $templateCache){
  return {
    restrict: 'E',
    template : $templateCache.get('correlationMatrix.html'),
    controller : function($scope){

      //console.log("Correlation matrix directive controller $scope: ", $scope);


      $scope.$watch('modelContributions', function(newVal, oldVal){

        if (newVal !== oldVal){
          $scope.matrix = calculateCorrelations($scope.monthlyPerformanceStream, $scope.modelContributions, $scope.correlationBenchmark);
        }
      });

      function calculateCorrelations(portfolio, models, benchmark){

        var matrix = [
          {
            name: 'Portfolio',
            data: [1,]
          },
        ];
        
        _.each(models, function(model, fid){
          var obj = {};

          obj.name = $scope.modelNames[fid] || fid;
          obj.data = []
          var portfolioCorrelation = pearsonCorrelation([_.values(portfolio), _.values(model)], 0, 1);
          matrix[0].data.push('')
          obj.data.push(portfolioCorrelation);

          //benchmark = models[fid].monthly_performance.benchmark_hist;

          var startPadding  = false;
          _.some(models, function(model, id){

            var correlation = pearsonCorrelation([_.values(model), _.values(models[fid])], 0, 1);
            
            if(startPadding){
              obj.data.push('');
            } else {

              if (correlation == 1){
                startPadding = true;
              }
              
              obj.data.push(correlation);
            }
            
          });
          //var benchmarkCorrelation = pearsonCorrelation([_.values(benchmark), _.values(models[fid])], 0, 1);
          
          if (startPadding){
            obj.data.push(''); // we do this to create the empty table cells
          }
          //obj.data.push(benchmarkCorrelation);

          matrix.push(obj);
        });

        var bench = {
          name: $scope.benchmarkName,
          data: []
        }

        bench.data.push(pearsonCorrelation([_.values(portfolio), _.values(benchmark)], 0, 1));
        matrix[0].data.push('');

        _.each(models, function(model, id){
          bench.data.push(pearsonCorrelation([_.values(benchmark), _.values(model)], 0, 1));
        });

        bench.data.push(1);

        matrix.push(bench);

        return matrix;
      }

        /**
     *  Calculate the person correlation score between two items in a dataset.
     *
     *  @param  {object}  prefs The dataset containing data about both items that
     *                    are being compared.
     *  @param  {string}  p1 Item one for comparison.
     *  @param  {string}  p2 Item two for comparison.
     *  @return {float}  The pearson correlation score.
     */
      function pearsonCorrelation(prefs, p1, p2) {
        var si = [];

        prefs[p2] = prefs[p2].map(Number);
        prefs[p1] = prefs[p1].map(Number);

        for (var key in prefs[p1]) {
          if (prefs[p1].hasOwnProperty(key) && prefs[p2][key] !== null){
            if (prefs[p2][key]) si.push(key);
          }
        }

        var n = si.length;

        if (n == 0) return 0;

        var sum1 = 0;
        for (var i = 0; i < si.length; i++) sum1 += prefs[p1][si[i]];

        var sum2 = 0;
        for (var i = 0; i < si.length; i++) sum2 += prefs[p2][si[i]];

        var sum1Sq = 0;
        for (var i = 0; i < si.length; i++) {
          sum1Sq += Math.pow(prefs[p1][si[i]], 2);
        }

        var sum2Sq = 0;
        for (var i = 0; i < si.length; i++) {
          sum2Sq += Math.pow(prefs[p2][si[i]], 2);
        }

        var pSum = 0;
        for (var i = 0; i < si.length; i++) {
          pSum += prefs[p1][si[i]] * prefs[p2][si[i]];
        }

        var num = pSum - (sum1 * sum2 / n);
        var den = Math.sqrt((sum1Sq - Math.pow(sum1, 2) / n) *
            (sum2Sq - Math.pow(sum2, 2) / n));

        if (den == 0) return 0;

        return num / den;
      }    
    }
  };
}
