var Tabs = angular.module('TabsCtrl', []);

Tabs.controller('TabsController', function($scope, Tabs, $rootScope, $timeout, $q, IdentityFactory, FeatureFlags){
  $scope.taxTransitionEnabled = FeatureFlags.isEnabled('taxTransition')
  $scope.jwttoken = "";
  IdentityFactory.getUser().then((res) => {
    $scope.jwttoken = res.access_token
  })

  var getActiveTab;

  $scope.$on('smx-token-updated',function(e, token){
    $scope.jwttoken = token;
  })

  $rootScope.$on('dashboard-load-tabs', function() {
    console.log("loading tabs...");
    $scope.tabs = Tabs.loadTabs();
  });

  $rootScope.$on('dashboard-refresh-tabs', function(event, args) {

    if(typeof args != 'undefined'){
      if(typeof args.storage_data != 'undefined' && args.storage_data != null){
        Tabs.setTabs(args.storage_data);
      }else{
        Tabs.setTabs([]);
      }
    }else{
        Tabs.setTabs([]);
    }

    $scope.tabs = Tabs.loadTabs();

  });

  $rootScope.$on('dashboard-init-failed', function(event, data){

    // console.log(data);

    // data can be used to pass custom error information if necessary
    Tabs.setTabs([
      {
        title: 'Error',
        type: "init-failed"
      }
    ]);

    $scope.tabs = Tabs.loadTabs();
  });

  $scope.$watch('tabs.length', function(newVal, oldVal){

    // true when any tab other than the last remaining one is deleted
    if (newVal < oldVal && newVal !== 0) {

      $timeout(function(){

        getActiveTab().then(function(tab){

          $scope.$broadcast('tabSwitched::' + tab.type, tab);

        })
        .catch(function(err){
          if (err){
            throw new Error(err);
          }
        });
      });

    } else if (newVal > oldVal){

      if ($scope.tabs.length < 10){
        $timeout(function(){
          // var containerWidth = jQuery('.tabs-container').width();
          jQuery('.nav-tabs').animate({scrollLeft: 20000 }, 300);
        }, 100);
      }
    }
  });


  $scope.calcTabWidth = function(){

    var tabCount = $scope.tabs.length + 1;
    var containerWidth = jQuery('.tabs-container').width();

    var tabWidth = containerWidth / tabCount;

    $scope.tabWidth = tabWidth;

    return {
      "width" : tabWidth,
      "opacity" : 1
    };
  };

  getActiveTab = function getActiveTab(){
    var activeTab,
        promise;

    // find the active tab in the tabs array
    $scope.tabs.some(function(tab){
      if (tab.active) {
        activeTab = tab;
        return true;
      }
    });

    promise = $q(function(resolve, reject){
                if (activeTab){

                  // activeTab will be passed to the .then callback
                  resolve(activeTab);
                } else {

                  // this message will be passed to the .catch callback
                  reject('there was a problem getting the active tab');
                }
              });

    return promise;
  };

  $scope.removeTab = function(index){

    var modelIDs,
        idx,
        self = this;

    self.tab.closing = true;

    $timeout(angular.bind(self, function(){
      if (this.tab.type === 'model'){
        modelIDs = Tabs.getModelTabs();
        idx = modelIDs.indexOf(this.tab.modelId); // index of the model ID to be deleted from the modelTabs array
        Tabs.deleteModelId(idx);
      }

      Tabs.removeTab(index)
      .then(function(){
        Tabs.setLastActive(Tabs.getActiveTab());
      });
    }));
  };

  $scope.switchTab = function(tab){
    var selectedTab = tab;
    var lastTab = Tabs.getLastActive() ? Tabs.getLastActive() : ''; // get the tab we were on before we clicked this one
    var sameTab = lastTab === tab;
    var switchTime;

    if ( !sameTab ){

        switchTime = new Date().getTime() / 1000; // get the current time
        lastTab.lastActive = switchTime; // set the last active time of the previous tab we were on

        if (tab.type === 'main') {
          Tabs.setLastDashboard(selectedTab);
        }

        selectedTab.active = true;
        Tabs.setActiveTab(selectedTab);
        Tabs.setLastActive(selectedTab);
        $scope.$broadcast('tabSwitched::' + selectedTab.type, selectedTab); // pass the details of the tab we just switched to
    }

  };

});
