angular.module('BillingModule')
.run(function(formlyConfig) {
  formlyConfig.setType([{
    name: 'tiered-fees-table',
    templateUrl: 'tiered-fees-table.html',
  },{
    name: 'account_fees_table_recap',
    templateUrl: 'feeTableCalculationRecap.html'
  }, {
    name: 'what_are_blended_rates',
    templateUrl: 'whatAreBlendedRates.html'
  }]);
})
.controller('CreateFeeScheduleFormCtrl', function ($uibModalInstance){
  var vm = this;

  vm.flatCalculation;
  vm.feeTableCalculation;
  vm.feeTableData = [{
    feeAmount: null,
    startingLimit: 0,
    endingLimit: null
  }, {
    feeAmount: null,
    startingLimit: null,
    endingLimit: null
  }];

  vm.feesTableErrorMsg;
  vm.feeTableCalculation = '100';
  vm.isCompany = false;
  vm.showTieredFees = false;
  vm.feeTableBlendedTotal = null;
  vm.feeTableFlatTotal = null;

  vm.model = {
    frequency: "monthly",
    type: "managementFee",
    feeModel: "flat"
  };

  vm.fields = [
    {
      key: "name",
      type: "input",
      templateOptions: {
        label: 'Schedule Name',
        placeholder: ''
      }
    },
    {
      key: "frequency",
      type: "select",
      templateOptions: {
        label: "Frequency",
        options: [
          {
            name: "Monthly",
            value: "monthly"
          },
          {
            name: "Quarterly",
            value: "quarterly"
          }
        ]
      }
    },
    {
      key: "type",
      type: "select",
      templateOptions: {
        label: "Type",
        options: [
          {
            name: "Management Fee",
            value: "managementFee"
          },
          {
            name: "Service Fee",
            value: "serviceFee"
          }
        ]
      }
    },
    {
      key: "feeModel",
      type: "select",
      templateOptions: {
        label: "Fee Model",
        options: [
          {
            name: "Flat",
            value: "flat"
          },
          {
            name: "Tiered",
            value: "tiered"
          }
        ]
      }
    },
    {
      key: 'managementFeeTable',
      type: 'tiered-fees-table',
      className: 'form-group col-xs-12',
    },
    // {
    //   key: "inAdvance",
    //   type: "checkbox",
    //   templateOptions: {
    //     label: "In Advance"
    //   }
    // },
    // {
    //   key: "rebateOnClose",
    //   type: "checkbox",
    //   templateOptions: {
    //     label: "Rebate On Close"
    //   }
    // }
  ];
});