angular
  .module('PortfolioEditor')
  .directive('autoFocus', function($timeout) {
    return {
      restrict: 'A',
      link: function(_scope, _element, attributes) {
        $timeout(function() {
          var autoFocus = _scope.$eval(attributes.autoFocus);
          if (autoFocus) {
            _element[0].focus();
          }
        }, 10);
      }
    };
  })
  .directive('portfolioEditor', ['$templateCache', function($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('portfolio-editor.tpl.html'),
      scope: {},
      link: link,
      controller: PortfolioEditorCtrl,
      controllerAs: 'vm',
      bindToController: {
        selectedAccount: '='
      }
    };

    function link() {}

    function PortfolioEditorCtrl($timeout, $q, NgTableParams, PortfolioEditorFactory, $scope, $uibModal, toastr, SweetAlert, $filter, Dashboard, TargetWeightFactory, SmartXFactory) {

      var vm = this;
      var tableConfig = {
        count: 10,
      };

      var tradeHistoryTableConfig = {
        sorting: {
          requestTimestamp: "desc"
        },
        filter: {
          status: ''
        }
      };

      var allocationApi = SmartXFactory.getAllocationAPI();
      var accountApi = SmartXFactory.getAccountAPI();

      //methods
      vm.buildAdjustments = buildAdjustments;
      vm.resetPortfolioChanges = resetPortfolioChanges;
      vm.showChangesModal = showChangesModal;
      vm.getTradeHistory = getTradeHistory;
      vm.removePositionFromPortfolio = removePositionFromPortfolio;
      vm.undoPositionChanges = undoPositionChanges;
      vm.getRowClass = getRowClass;
      vm.refresh = refresh;
      vm.updateSelectedRows = updateSelectedRows;
      vm.liquidateSelectedPositions = liquidateSelectedPositions;
      vm.confirmUpdateTarget = confirmUpdateTarget;

      // bindings
      vm.adjustments = [];
      vm.lmv = 0;
      vm.smv = 0;
      vm.netExp = 0;
      vm.grossExp = 0;
      vm.showingTradeHistory = false;
      vm.showingPortfolioEditor = true;
      vm.showPositionFilters = false;
      vm.statusFilter = {  
        'status': {
          id: 'select',
          placeholder: 'Select Status'
        }
      };

      vm.statusFilterOptions = [{
        id: '',
        title: 'All'
      }];

      $scope.$watch('vm.selectAllRows', function(newVal, oldVal) {
        if (angular.isDefined(newVal) && newVal != oldVal) {

          _.each(vm.tableParams.settings().dataset, function(row) {

            // skip new positions that haven't been submitted yet
            if (row.percentOfPortfolio){
              if (newVal === true) {
                row.selected = true;
              } else {
                row.selected = false;
              }
            }
          });

          updateSelectedRows();
        }
      });

      function getDefaultTargetDetails () {
        return $q.when(TargetWeightFactory.getTargetDetailByGUID(vm.selectedAccount.targetWeightId))
          .then(function(res){
            return Promise.resolve(res.data);
          })
          .catch(function(err){
            console.error(err.message);
            Dashboard.toastError(err.message, err);
            return Promise.reject(err);
          });
      }

      function findSymbolInTarget (targetDetails, symbolId) {
        var symbol = targetDetails.find(function(detail){
          return detail.target.id === symbolId;
        });

       return symbol;
      }

      function findSymbolInHoldings (holdings, symbolId){
        var symbol = holdings.find(function(holding){
          return holding.symbol.id === symbolId;
        });

        return symbol;
      }

      function confirmUpdateTarget (){

        if (Number(vm.netExp) > 1){
          var formattedExposure = $filter('number')(vm.netExp * 100, 2) + "%";
          SweetAlert.swal({
            title: 'Update not possible.  The net exposure is '+formattedExposure+', but the default target cannot exceed 100%.',
            type: 'warning'
          });

          return false;
        }

        SweetAlert.swal({
          title: "Updating Default Target",
          text: "This process will use the current weights of the positions held in this account's managed portfolio in order to update this account's default target with matching details.  If the account's default target already contains details, they will be scaled accordingly to accomodate the new details.  Click 'Yes' to confirm.",
          type: "warning",
          html: true,
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          closeOnConfirm: false,
          closeOnCancel: true,
          showLoaderOnConfirm: true,
          allowEscapeKey: true,
        },
        function(isConfirm){
          if (isConfirm){
            updateDefaultTarget();
          }
        });
      }

      function updateDefaultTarget () {
        var defaultTargetId = vm.selectedAccount.targetWeightId;

        // https://smartx.atlassian.net/browse/BUG-2988
        getTradablePortfolioValue()
        .then(function(res) {
          console.log('Tradable portfolio is: ', res); 
          var tradablePortfolioValue = res;

          // adjust apm holdings to include a tradable percent of portfolio
          // this will be based on the position value / (total account value - (protected sleeve + funding sleeve))
          vm.holdings = vm.holdings.map(function(holding) {
            holding.tradablePercentOfPortfolio = holding.marketValue / tradablePortfolioValue;
            return holding;
          });

          getDefaultTargetDetails(defaultTargetId)
          .then(function(res){
            var existingDetails = res;
            var payload = [];

            // override the existing detail's percent with the APM holding percent
            var defaultTargetNonCashDetails = existingDetails.filter(function(detail){
              return detail.target.type !== 'cash';
            })
            .map(function(detail){
              var symbolHolding = vm.holdings.find(function(position){
                return position.symbol.id === detail.target.id;
              });

              var updatedDetail = _.extend(detail, {
                percent: symbolHolding ? Number((symbolHolding.tradablePercentOfPortfolio * 1e4).toFixed(0)) / 1e4 : detail.percent
              });

              return updatedDetail;
            });

            var cashDetail = existingDetails.find(function(detail){
              return detail.target.type === 'cash';
            });

            // if (defaultTargetNonCashDetails.length) {
            //   var confirmed = confirm("This account's default target has already been set. Do you wish to override it?");
            //   if (!confirmed) return false;
            // }



            payload = payload.concat(vm.holdings.filter(function(position){
                return !findSymbolInTarget(existingDetails, position.symbol.id);
              })
              .map(function(holding){

              // target detail payload item
              var obj = {
                action: 'create',
                type: "symbol",
                target: {
                  id: holding.symbol.id,
                  leverage: 1,
                  matchModelLeverage: true
                },
                percent: Number((holding.tradablePercentOfPortfolio * 1e4).toFixed(0)) / 1e4, // 4 decimal places
                precision: {
                  upper: 0.04,
                  lower: 0.04
                },
                threshold: {
                  upper: 0.2,
                  lower: 0.2
                }
              };

              return obj;
            }));

            // nonCashPercentage of the managed portfolio
            var nonCashPercentage = payload.reduce(function(total, detail){
              return total += parseFloat((detail.percent * 1e4).toFixed(2));
            }, 0);

            // nonCashPercentage of the account's default target
            // var existingNonCashDetailsPercentage = defaultTargetNonCashDetails.reduce(function(total, detail){
            //   return total += parseFloat((detail.percent * 1e4).toFixed(2));
            // }, 0);

            console.log("Non cash percent: ", nonCashPercentage);
            nonCashPercentage = Number(math.format(nonCashPercentage, {precision: 14}));

            var cashPercentage = Number(math.format(1e4 - nonCashPercentage, {precision:14}));

            console.log("Cash Percent: ", cashPercentage); 
            if (defaultTargetNonCashDetails.length) {
              debugger;
              payload = payload.concat(defaultTargetNonCashDetails.map(function(detail){

                /* 
                // we can use this if we only want to scale the existing details when we run out of cash
                // default to just using the existing weight
                var detailPercent = Number((detail.percent * 1e4).toFixed(0)) / 1e4;

                // unless the total existing details would exceed the currently remaining cash percentage
                // in that case, scale each of the existing details down to fill the remaining cash
                if (existingNonCashDetailsPercentage > cashPercentage) {
                  // scale it down if maintaining the existing weights would exceed the cash percentage
                  detailPercent = Number((detail.percent * (cashPercentage / 1e4) * 1e4).toFixed(0)) / 1e4;
                }
                */

                // scale down existing details to fill remaining cash
                var detailPercent;
                var existingHolding = findSymbolInHoldings(vm.holdings, detail.target.id);

                // use the existing position percentage for any existing apm holding
                // otherwise scale the target detail percent based on account's remaining cash percentage
                if (existingHolding) {
                  detailPercent = Number((detail.percent * 1e4).toFixed(0)) / 1e4;
                } else {
                  detailPercent = Number((detail.percent * (cashPercentage / 1e4) * 1e4).toFixed(0)) / 1e4;
                }

                var obj = {
                  action: "modify",
                  id: detail.id,
                  type: detail.target.type,
                  threshold: detail.threshold,
                  precision: detail.precision,
                  target: detail.target,
                  percent: detailPercent
                };

                return obj;
              }));
            }

            // recalculate the cash after adding in existing details to the payload
            var finalNonCashPercentage = payload.reduce(function(total, detail){
              return total += parseFloat((detail.percent * 1e4).toFixed(2));
            }, 0);

            console.log("Non cash percent: ", nonCashPercentage);
            finalNonCashPercentage = Number(math.format(finalNonCashPercentage, {precision: 14}));

            var finalCashPercentage = Number(math.format(1e4 - finalNonCashPercentage, {precision:14}));

            var cash = {
              id: cashDetail.id,
              action: 'modify',
              type: 'cash',
              target: {
                id: null,
                leverage: null,
                matchModelLeverage: true
              },
              percent: finalCashPercentage / 1e4,
              threshold: cashDetail.threshold,
              precision: cashDetail.precision
            };

            payload.push(cash);

            TargetWeightFactory.updateDetails(defaultTargetId, payload)
            .then(function(res){
              console.log("Target update result: ", res.data);
              SweetAlert.swal({
                title: "Update successful.  Refresh the account details to see the changes.",
                type: "success"
              });
            })
            .catch(function(err){
              console.error(err);
              SweetAlert.swal({
                title: err.message,
                type: "error"
              });
            });
          })
          .catch(function(err){
            console.error(err);
            SweetAlert.swal({
              title: err.message,
              type: "error"
            });
          });
        })
        .catch(function(err){
          console.error(err);
          SweetAlert.swal({
            title: 'Error updating default target',
            type: "error"
          });
        });



      }

      function calculateTradableValue (accountValue, allocations) {
        var protectedSleeve = allocations.find(function(allocation) {
          return allocation.type === 'protected';
        });

        var fundingSleeve = allocations.find(function(allocation) {
          return allocation.type === 'funding';
        });

        var protectedSleeveValue = _.property(['statistics','value'])(protectedSleeve) || _.property(['value'])(protectedSleeve) || 0; 
        var fundingSleeveValue = _.property(['statistics', 'value'])(fundingSleeve) || _.property(['value'])(fundingSleeve) || 0;

        var tradableValue = accountValue - (protectedSleeveValue + fundingSleeveValue);

        return tradableValue;
      }

      function getTradablePortfolioValue () {
        var accountValue = vm.selectedAccount.value || _.property(['statistics', 'value'])(vm.selectedAccount);

        if (!accountValue) {
          return Promise.reject('No account value');
        }

        
        // get the protected + funding sleeve values
        var promise = new Promise(function(resolve, reject) {

          accountApi.getAccountStatisticsForId(vm.selectedAccount.id)
          .then(function(res){
            var accountValue = _.property(['data','statistics','value'])(res);
          
            // make allocation statistics call to get the protected and funding sleeve values
            if (accountValue) {
              allocationApi.getAllocationStatistics({accountId: vm.selectedAccount.id, status: 'open'})
              .then(function(res) {
                var allocations = res.data;  
                var tradableValue = calculateTradableValue(accountValue, allocations);

                return resolve(tradableValue);
              });
            } else {
              reject(new Error('Error getting account value'));
            }
          })
          .catch(function(err){
            console.error(err);
            reject(new Error('Error getting tradable portfolio value'));
          });
        });

        return promise;
      }

      function updateSelectedRows() {
        
        var selectedRows = vm.tableParams.settings().dataset
                            .map(function(row){

                              // clear out the 0 value if the row is deselected
                              if (row.newValue === 0 && !row.selected) row.newValue = '';
                              return row;
                            })
                            .filter(function(row) {
                              return row.selected;
                            });
        
        vm.selectedRows = selectedRows;
        console.log(vm.selectedRows);
        buildAdjustments();
      }

      function liquidateSelectedPositions(){
        vm.selectedRows.forEach(function(row){
          row.newValue = 0;
        });

        buildAdjustments();
      }

      function groupByPositionState(item) {
        if (item.percentOfPortfolio == 0) {
          return 'New Positions';
        } else {
          return 'Current Positions';
        }
      }

      groupByPositionState.title = "Position Type";

      vm.getCurrentTotalAllocated = function() {

        var data = vm.holdings;
        if (!data || !data.length) {
          vm.lmv = vm.smv = vm.grossExp = vm.netExp = 0;
          return;
        }

        var total = 0,
          grossExp = 0,
          netExp = 0,
          lmv = 0,
          smv = 0,
          pendingChanges = [];

        for (var i = 0; i < data.length; i++) {

          var item = data[i];

          if (typeof item == 'undefined' || (typeof item.newValue == 'undefined' || item.newValue == null) && (typeof item.percentOfPortfolio == 'undefined' || item.value == null)) {
            continue;
          }

          var val = (typeof item != 'undefined' && typeof item.newValue == 'string' && !isNaN(parseFloat(item.newValue))) ? parseFloat(item.newValue) : (parseFloat(item.percentOfPortfolio) * 100);


          if (item.RequestResultValue == 1) {
            pendingChanges.push(item);
          }

          // use mathjs version of sign method to support IE
          val = val.toFixed(2);
          if (math.sign(val) > 0) {
            lmv += (val / 100);
          } else if (math.sign(val) < 0) {
            smv += (val / 100);
          }

          total += Math.abs(parseFloat(val));
        }

        vm.pendingChanges = pendingChanges;

        grossExp = lmv - smv;
        netExp = lmv + smv;

        console.log(lmv, smv, grossExp, netExp);

        // to round or not to round
        vm.lmv = lmv; //.toFixed(3);
        vm.smv = smv; //.toFixed(3);
        vm.grossExp = grossExp; //.toFixed(3);
        vm.netExp = netExp; //.toFixed(3);

        total = Number(parseFloat(total).toFixed(2));

        vm.currentTotalAllocated = parseFloat(total); //.toFixed(2);
      };

      init();

      function init() {

        var portfolioId = vm.selectedAccount.id;

        // vm.managedPortfolio = vm.selectedAccount.allocations.find(function(allocation){
        //   return allocation.type === 'managedPortfolio' || allocation.type === 'account';
        // });

        // vm.sleeveValue = vm.managedPortfolio && vm.managedPortfolio.statistics ? vm.managedPortfolio.statistics.value : null;

        getPortfolio(portfolioId);
      }

      function refresh(){
        $scope.$broadcast('account-changed', vm.selectedAccount);
      }

      function getPortfolio(portfolioId) {

        vm.loadingPortfolio = true;

        $q.when(PortfolioEditorFactory.getPortfolio(portfolioId))
          .then(handlePortfolioResponse)
          .catch(function(err) {
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            vm.loadingPortfolio = false;
          });
      }

      $scope.$on('account-changed', function(event, data) {

        vm.loadingPortfolio = true;
        
        vm.statusFilterOptions.splice(1);// remove the current status filter options

        getPortfolio(data.id);
        getTradeHistory(data.id);

      });

      vm.removeOrder = function(index) {
        vm.tableParams.settings().dataset.splice(index, 1);
        vm.tableParams.reload();
      };

      vm.resetOrderManager = function() {
        vm.tableParams.settings().dataset = [];
        vm.tableParams.reload();
      };

      function removePositionFromPortfolio(index, position) {

        // if we added a new position that already has a pending trade, allow them to remove it
        if (vm.isPending(position) && position.value) return false;

        if (position.percentOfPortfolio == 0) {
          vm.holdings.splice(index, 1);
          vm.tableParams.reload();
        } else if (position.percentOfPortfolio) {
          position.newValue = "0";
        }


        buildAdjustments();
      }

      function undoPositionChanges(index, position) {

        if (position.newValue === '') {
          return false;
        }

        if (position.newValue !== '') {
          position.newValue = '';
        }

        buildAdjustments();
      }

      function updateStatusFilterOptions(status){
        var statuses = _.pluck(vm.statusFilterOptions, 'id');

        if (_.contains(statuses, status)) return; 
        else {
          vm.statusFilterOptions.push({
            id: status,
            title: $filter('formatStatus')(status)
          });
        }
      }

      // function getInProgressTrades (trades) {
      //   var pendingStatuses = [ 'pending', 'new' ];
      //   var pendingTrades = trades.filter(function(trade){
      //     return _.contains(pendingStatuses, trade.status);
      //   });
      //   console.log('Pending Trades', pendingTrades);
      //   return pendingTrades;
      // }

      function getTradeHistory(id) {

        vm.loadingTrades = true;
        id = id ? id : vm.selectedAccount.id;

        vm.pendingTrades = [];

        $q.when(PortfolioEditorFactory.getPortfolioTradeHistory(id))
          .then(function(res) {

            if (res.data.data == undefined) {
              return;
            }

            var trades = res.data.data;
            vm.trades = _.map(trades, function(trade) {

              trade.requestTimestamp = trade.requestTimestamp.timestamp * 1000;
              trade.status = _.contains(['CompleteWithFills', 'filled'], trade.results.status) ? 'Complete' : $filter('formatStatus')(trade.results.status); //  == 'new' ? 'Requested' : 'Completed' */;


              if (trade.status === 'Requested' || trade.status === 'New' || trade.status === 'Pending'){
                vm.pendingTrades.push(trade);
              } 

              updateStatusFilterOptions(trade.status);

              return trade;
            });

            console.log('Pending Trades: ', vm.pendingTrades);

            console.log("APM Change History: ", vm.trades);

            vm.tradeHistoryTable = new NgTableParams(tradeHistoryTableConfig, {
              dataset: vm.trades
            });
          })
          .catch(function(err) {
            Dashboard.toastError('Failed to load trade history', 'Error', err);          
          })
          .finally(function(){
            vm.loadingTrades = false;
          });
      }

      vm.isPending = function(holding){
        var isPending = false;
        return isPending;
      };

      function handlePortfolioResponse(response) {
        console.log("Get portfolio response:", response);

        $timeout(function(){
          vm.loadingPortfolio = false;
        });

        var holdings = _.map(response.data.data, function(holding) {
          holding.percentOfPortfolio = Number(holding.percentOfPortfolio);
          holding.newValue = '';
          holding.value = holding.price * holding.quantity;
          //delete holding.symboindustry;

          return holding;
        });

        vm.accountAvailableCash = vm.selectedAccount.statistics ? vm.selectedAccount.statistics.cash : (vm.selectedAccount.cash ? vm.selectedAccount.cash : null);
        vm.accountValue = vm.selectedAccount.statistics ? vm.selectedAccount.statistics.value : (vm.selectedAccount.value ? vm.selectedAccount.value : null);

        vm.holdings = _.sortBy(holdings, 'percentOfPortfolio').reverse();

        vm.getCurrentTotalAllocated();

        vm.originalHoldings = angular.copy(holdings);

        vm.tableParams = new NgTableParams(tableConfig, {
          dataset: vm.holdings
        });
      }

      function clearNewRowForm() {
        vm.selectedSecurity = null;
        vm.selectedSymbol = null;
        vm.symbolPercentage = null;
      }

      function buildAdjustments() {
        var originalHoldings = angular.copy(vm.originalHoldings);
        console.log(originalHoldings);
        var changes = _.filter(angular.copy(vm.holdings), function(obj) {

          // findWhere returns undefined if the object from current holdings is not found in the original holdings
          // !undefined = true
          // so if it's not found in the original holdings, then we can assume it's an adjustment
          console.log("Looking for item: ", obj);
          return !_.find(originalHoldings, function(holding){
            return angular.equals(holding, obj);
          });
          // return !_.findWhere(originalHoldings, obj);
        });

        changes = changes.filter(function(trade){
          return angular.isDefined(trade.newValue) && trade.newValue !== '' && trade.newValue !== null && trade.newValue != trade.percentOfPortfolio;
        });

        vm.adjustments = changes;
        vm.getCurrentTotalAllocated();

      }

      function getRowClass(model) {


        var rowClasses = {
          'pending-change': model.percentOfPortfolio != 0 && model.newValue !== '' && model.newValue != null,
          'new-position': model.percentOfPortfolio == 0 && model.newValue != 0
        };

        return rowClasses;
      }

      function resetPortfolioChanges($event, model) {

        for (var i = vm.holdings.length - 1; i >= 0; i--) {
          var item = vm.holdings[i];

          if (vm.adjustments.indexOf(item) > -1 || item.percentOfPortfolio == 0) {
            vm.tableParams.settings().dataset.splice(i, 1);
          } else {
            item.newValue = '';
          }
        }

        if (!vm.holdings.length) {
          vm.noPositions = true;
        }

        vm.adjustments = [];

        vm.getCurrentTotalAllocated();

        vm.tableParams.reload();
      }

      function showChangesModal() {

        if (!vm.adjustments.length) return false;

        // skip validation when available cash is negative TS-1142
        if (vm.accountAvailableCash >= 0 && vm.netExp > 1) {
          SweetAlert.swal({
            title: "Over Allocated",
            text: 'The portfolio net exposure can not exceed 100%.',
            type: "warning"
          });

          return;
        }

        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'portfolioChangesModal.html',
          controllerAs: 'vm',
          controller: function(accountId, $uibModalInstance, adjustments, $q, PortfolioEditorFactory) {
            var vm = this;
            vm.adjustments = adjustments;
            console.log("changes modal ctrl: ", vm);

            vm.submitChanges = function() {
              vm.processing = true;

              PortfolioEditorFactory.savePortfolioChanges(accountId, adjustments)
                .then(function(response) {
                  vm.processing = false;
                  modalInstance.close();
                  onSaveChangesSuccessHandler(response);
                })
                .catch(function(response) {
                  vm.processing = false;
                  onSaveChangesErrorHandler(response);
                });
            };

            vm.cancel = function() {
              $uibModalInstance.dismiss('cancel');
            };
          },
          resolve: {
            adjustments: function() {
              return vm.adjustments;
            },
            accountId: function() {
              return vm.selectedAccount.id;
            }
          }
        });
      }

      function onSaveChangesSuccessHandler(response) {

        if (response.status == 'fail') {
          toastr.error(response.message);

          return;
        } else {
          toastr.success('Order submitted');
        }

        vm.adjustments = [];
        getPortfolio(vm.selectedAccount.id);
        getTradeHistory(vm.selectedAccount.id);
      }

      function onSaveChangesErrorHandler(err) {
        vm.processing = false;
        Dashboard.toastError(err.message, err);
      }

      //vm.matches = [];

      vm.addToPortfolio = function(symbol, percentage) {

        if (symbol == undefined || symbol == null || symbol == '') {
          return;
        }

        var newRow = {
          percentOfPortfolio: 0,
          newValue: percentage,
          symbol: {
            id: symbol.id,
            ticker: symbol.ticker,
            companyName: symbol.companyName
          }
        };

        var currentSymbols = _.map(vm.tableParams.settings().dataset, function(row){
          return row.symbol.ticker;
        });

        if (currentSymbols.indexOf(newRow.symbol.ticker) !== -1) return;

        // figure out if it's already in the table
        // if so ignore it if it has the same percentage
        // otherwise update the percentage of the existing one
        vm.tableParams.settings().dataset.unshift(newRow);

        // build adjustments / figure out changes

        buildAdjustments();
        clearNewRowForm();

        vm.tableParams.sorting({});
        vm.tableParams.page(1);
        vm.tableParams.reload();
      };

    }
  }]);