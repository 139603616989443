angular.module('PortfoliosService', [])
.factory('Portfolios', [ 'Dashboard', '$filter', '$http', function(Dashboard, $filter, $http){

	function getCumulativeReturnFromStream(stream){

        var cumulativeReturn;

        var returns = _.values(stream);

        var percentAsFloat  = returns.reduce(function(currentTotal, newValue){
                                if (angular.isDefined(newValue)){
                                  return currentTotal * ( (newValue / 100) + 1 );
                                } else {
                                  return currentTotal * 1;
                                }
                              }, 1 );

        cumulativeReturn = (percentAsFloat - 1) * 100;

        return cumulativeReturn;
    }

    function calculateWindowPerformance(portfolioPerformance, timeframe, startValue){
        startValue = startValue || 1000000;
        var performanceTimestamps = Object.keys(portfolioPerformance),
            firstDate = performanceTimestamps[0] * 1000,
            lastDate = performanceTimestamps[performanceTimestamps.length - 1] * 1000,
            d = new Date(),
            year = d.getFullYear(),
            month = moment.utc(lastDate).month(),
            time,
            MTDstartValue,
            YTDstartValue,
            dollarValues = [],
            lookBack = moment.utc(lastDate).month() === moment.utc().month() ? 2 : 1;

        _.each(portfolioPerformance, function(value, timestamp){

            time = moment.utc(timestamp * 1000).format(),
            startValue = startValue + (value / 100) * startValue;

            // if we are in the last month of the previous year, use that as our next year's starting value
            var utcTime = moment.utc(time);
            if (!angular.isDefined(YTDstartValue) && utcTime.month() === 11 && utcTime.year() === year - 1) {
                YTDstartValue = startValue;
            }
            if (!angular.isDefined(MTDstartValue) && utcTime.format() == moment.utc(lastDate).subtract(lookBack, 'months').format()){
                MTDstartValue = startValue;
            }

            dollarValues.push(startValue);
        });
        
        return {
            dailyDollarValues: dollarValues,
            MTDstartValue: MTDstartValue,
            YTDstartValue: YTDstartValue,
            lastDate: lastDate
        };
    }

	return {
		calculateWindowPerformance    : calculateWindowPerformance,
        getCumulativeReturnFromStream : getCumulativeReturnFromStream
	};
}]);