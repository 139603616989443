angular.module('TourService', [])
.factory('TourService', function(USER){

    var takingTour = false;

    Shepherd.on('complete', function(){
        takingTour = false;
        
    });

    Shepherd.on('start', function(){
        takingTour = true;
        
    });

    Shepherd.on('cancel', function(){
        takingTour = false;
        
    });

    var inProgress = function(){
        return takingTour;
    };

    var getHomeTour = function(shepherdTour){
        shepherdTour.addStep('dashboard-home', {
            title: 'Dashboard',
            text: ['This is your main accounts panel. Click here to view your account value as well as allocations you\'ve made. This will automatically refresh so you can always know where your investments are.'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.dashboard', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-home',
            showCancelLink: true,
            buttons: [{
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        shepherdTour.addStep('dashboard-tabs', {
            title: 'Page Tabs',
            text: ['When you select an item from the menu, a new tab will open up, you can keep them open for easy access.'],
            attachTo: {element: '.nav-tabs li:first-child', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-tabs',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        shepherdTour.addStep('dashboard-allocate', {
            title: 'Allocate',
            text: ["To invest in a new model use the Allocate panel. You will have options that control the size of your allocation as well as the amount of leverage you can use."],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.allocate', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-allocate',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        shepherdTour.addStep('dashboard-liquidate', {
            title: 'Liquidate',
            text: ['You can liquidate any percentage of your model Allocation using the Liquidate panel. Select the model then select the percentage you want to liquidate.'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.liquidate', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-liquidate',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        shepherdTour.addStep('dashboard-holdings', {
            title: 'Holdings',
            text: ['Gain insight into your investments with a detailed breakdown of your account holdings.'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.holdings', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-holdings',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        shepherdTour.addStep('dashboard-profile', {
            title: 'Profile',
            text: ['We are required to keep an accurate investor information for all of our clients. Click here to make sure yours is up to date.'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.profile', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-profile',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Next',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        if (!USER.is_ria){
            shepherdTour.addStep('dashboard-add-account', {
                title: 'Add Brokerage Account',
                text: ['Opening up another investment account? Click here to use our new streamlined process.'],
                attachTo: {element: '#hcv-dashboard-menu .tab-item.add-account', on: 'right'},
                classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-add-account',
                showCancelLink: true,
                buttons: [{
                    text: 'Back',
                    classes: 'shepherd-button-secondary',
                    action: shepherdTour.back
                }, {
                    text: 'Next',
                    action: shepherdTour.next,
                    classes: 'shepherd-button-example-primary'
                }]
            });
        }
        shepherdTour.addStep('dashboard-tour', {
            title: 'Take A Tour',
            text: ['Explore the features of any page'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.tour', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-tour',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Exit',
                action: shepherdTour.next,
                classes: 'shepherd-button-example-primary'
            }]
        });

        /*
        shepherdTour.addStep('dashboard-portfolio-builder', {
            title: 'Take A Tour',
            text: ['Our Portfolio Builder&#153 gives you the ability to create and backtest a custom portfolio of multiple models.'],
            attachTo: {element: '#hcv-dashboard-menu .tab-item.portfolio-builder', on: 'right'},
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text active-step-dashboard-tour',
            showCancelLink: true,
            buttons: [{
                text: 'Back',
                classes: 'shepherd-button-secondary',
                action: shepherdTour.back
            }, {
                text: 'Close',
                action: shepherdTour.cancel,
                classes: 'shepherd-button-example-primary'
            }]
        });
        */
        
        shepherdTour.start();
    };

	return {
		getHomeTour 		: getHomeTour,
        inProgress          : inProgress
		//getAllocateTour 	: getAllocateTour,
		//getLiquidateTour 	: getLiquidateTour,
		//getProfileTour 	: getProfileTour,
		//getAddAccountTour	: getAddAccountTour,
		//getHoldingsTour 	: getHoldingsTour,
		//getSearchTour		: getSearchTour,
	};
});