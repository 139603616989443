var Search = angular.module('SearchCtrl', []);
Search.directive('toggler', ['$timeout', function($timeout) {
    // Runs during compile
    return {
      restrict: 'A',
      link: function($scope, element, iAttrs, controller) {
        element.bind('click', function() {
          jQuery('#filters-col').toggleClass('col-md-3 col-0');
          jQuery('#table-col').toggleClass('col-md-9 col-md-12');
        });
      }
    };
  }])
  .controller('SearchCtrl', function(Amplitude, USER, $q, CacheFactory, $rootScope, $scope, $http, $timeout, toastr, Dashboard, SearchFilters, Tabs, Models, $interpolate, $templateCache, SweetAlert, $uibModal, SmartXFactory, IdentityFactory, FeatureFlags) {

    var search = this;
    var parentTab = $scope.$parent.tab;
    var filterTypes = SearchFilters.filterTypes;
    var columnDefs = SearchFilters.columnDefs;
    // var rangeFilterColumnRefs = SearchFilters.rangeFilterColumnRefs;
    var platform = USER.enterprise; /* Drupal.settings.white_label_name; */
    var _selectedRows;
    var filterModel = {};

    var performanceApi = SmartXFactory.getPerformanceAPI();
    $scope.platformId = performanceApi.platform;
    var modelMinimumsDict = Models.getMinimums();


    if (typeof parentTab !== 'undefined') {

      // tracking the first instance of this controller because formly creates multiple instances with multiple scopes
      // parentTab is undefined for all the instances created by formly
      // so if it is defined, then we can assume it's the first instance
      $scope.firstOne = true;
      var tabType = $scope.tab.type;
    }

    $scope.visited = false;
    $scope.gridExpanded = false;
    $scope.filteredModels = [];
    $scope.taxTransitionEnabled = FeatureFlags.isEnabled('taxTransition')



    console.log('Tax transition enabled: ', $scope.taxTransitionEnabled)

    $scope.$watch('showingFilters', function(newVal, oldVal){
      $scope.$broadcast('resizeTable', $scope.showingFilters);
    });

    // only set up a listener on the scope of the first instance of this controller
    if ($scope.firstOne) {

      $scope.$on('tabSwitched::' + tabType, function(event, tab) {

        console.log("tab switched to " + tab.type);
        if (tab.type === 'search') {
          search.refreshSlider();

          // var sortCol = Dashboard.getSearchSort();
          // if (angular.isDefined(sortCol)) {
          //   console.log('Sorting search table on column ' + sortCol);
          //   switch (sortCol) {
          //     case 'ytd':
          //       $scope.selectedSort = $scope.sortOptions[2];
          //       break;
          //     case 'qtd':
          //       $scope.selectedSort = $scope.sortOptions[3];
          //       break;
          //     case 'today':
          //       $scope.selectedSort = $scope.sortOptions[6];
          //       break;
          //     default:
          //       $scope.selectedSort = $scope.sortOptions[2];
          //   }
          //   $scope.changeSort();
          // }
        }
      });
    }

    $scope.searchText = "";
    $scope.isCollapsed = false;
    $scope.filterModel = {};
    // $scope.updateDataSource = updateDataSource;

    search.rangeFilters = angular.copy(SearchFilters.rangeFilters);
    $scope.rangeFilters = search.rangeFilters;

    search.updateRangeFilterModel = function(name) {
      $scope.$broadcast('filter-changed', $scope.filterModel);
    };

    $scope.data = {};

    // row-selected event is emitted from the searchTable.js directive
    $scope.$on('row-selected', function(event, rows) {
      $scope.selectedRows = rows;
      $scope.selectedModelIDs = rows ? rows.map(function(row) {
        return row.guid;
      }) : [];
    });

    $scope.setSliders = function(filterName, min, max) {

      var filter = search.rangeFilters[filterName];

      if (!angular.isDefined(max)) {
        max = filter.ceil;
      } else if (!angular.isDefined(min)) {
        min = filter.floor;
      }

      filter.min = min;
      filter.max = max;

      if (filter.min !== filter.floor || filter.max !== filter.ceil) {
        updateRangeFilters(filterName, 'add');
      } else {
        updateRangeFilters(filterName, 'remove');
      }
    };

    $scope.hcv_products = {
      showing: '',
      updateFilter: function() {

        if ($scope.hcv_products.showing === '') {

          $scope.hcv_products.clearFilter();

        } else {

          if ($scope.filterModel.model_type) {

            $scope.filterModel.model_type[0] = $scope.hcv_products.showing;

          } else {

            $scope.filterModel.model_type = [$scope.hcv_products.showing]; // this will become traditional, alternative, composite, etc
          }

          $scope.hcv_products.syncFilterModel();
        }

      },
      clearFilter: function() {

        $scope.removeFilter('model_type', $scope.filterModel.model_type[0]);
        $scope.hcv_products.syncFilterModel();

      },
      syncFilterModel: function() {

        $timeout(function() {

        });
      }
    };

    search.refreshSlider = function() {

      $timeout(function() {
        $scope.$broadcast('rzSliderForceRender');
      }, 200);

    };

    // runs when a new search tab is created from the menu
    // or when the search tab is active on page load
    if ($scope.tab.active) {

      // this triggers a refresh of the sliders
      // in order to ensure that they start in the right position when the view loads
      search.refreshSlider();
    }

    $scope.settings = {
      tableRowHeight: 40,
      tableHeaderHeight: 30
    };

    $scope.gridOptions = {

      // constants
      columnDefs: columnDefs,
      debug: true,
      rowSelection: 'multiple',
      enableColResize: true,
      enableSorting: true,
      enableFilter: true,
      groupHeaders: false,
      pinnedColumnCount: 2,
      unSortIcon: false,
      floatingTopRowData: [],
      showToolPanel: false,
      toolPanelSuppressValue: true,
      toolPanelSuppressPivot: true,
      angularCompileRows: true,
      angularCompileHeaders: true,
      headerHeight: $scope.settings.tableHeaderHeight,
      rowHeight: $scope.settings.tableRowHeight,
      overlayLoadingTemplate: $templateCache.get('search-loader.html'),
      //pagination: true,

      // filter methods
      isExternalFilterPresent: isExternalFilterPresent,
      doesExternalFilterPass: doesExternalFilterPass,

      // event handlers
      onSelectionChanged: selectionChangedFunc,
      onRowClicked: rowClickedFunc,
      onModelUpdated: modelUpdatedFunc,

      // misc
      headerCellRenderer: headerCellRendererFunc
    };

    $scope.searchModels = [];



    // function updateDataSource() {
    //   var filteredRows = filterData($scope.filterModel, $scope.searchModels);
    // }

    function resetRangeFilter(filter) {
      search.rangeFilters[filter].min = search.rangeFilters[filter].floor;
      search.rangeFilters[filter].max = search.rangeFilters[filter].ceil;
    }

    function headerCellRendererFunc(params) {
      if (params.colDef.headerName == '') {
        var eHeader = document.createElement('i');
        eHeader.className = "fa fa-times-circle";
        eHeader.setAttribute('uib-tooltip', 'Clear selected rows');
        eHeader.setAttribute('tooltip-placement', 'right');
        eHeader.setAttribute('ng-click', 'deselectRows()');

        return eHeader;
      } else {
        return params.colDef.headerName;
      }
    }

    $scope.deselectRows = function() {
      console.time("deselect rows");
    };

    $scope.removeFilter = function(type, _filter) {

      console.time("remove filter");

      // get the index of the selected filter from the filter array for that type
      var filterIndex = $scope.filterModel[type].indexOf(_filter);
      var filterType = filterTypes[type];

      // remove the filter
      $scope.filterModel[type].splice(filterIndex, 1);

      // delete that filter type from the model if its array is empty
      if (!$scope.filterModel[type].length) {
        delete $scope.filterModel[type];
      }

      if (type === 'model_type') {
        $scope.hcv_products.showing = ''; // reset the radio back to all models
      } else if (type === 'rangeFilters') {

        search.rangeFilters[_filter].min = search.rangeFilters[_filter].options.floor;
        search.rangeFilters[_filter].max = search.rangeFilters[_filter].options.ceil;
        //resetRangeFilter(_filter);

      } else {

        // unselect the corresponding checkbox
        _.each(SearchFilters[filterType], function(filter) {
          if (filterType !== 'rangeFilters') {
            if (filter.value === _filter) {
              filter.selected = false;
            }
          }
        });
      }

      // add timeout so that the filter is removed / reset in view before updating the grid
      $timeout(function() {

      });
      console.timeEnd("remove filter");
    };

    $scope.agGridHeight = 1100;

    $scope.formData = {
      gridOptions: $scope.gridOptions
    };

    // $scope.sortOptions = SearchFilters.sortOptions;

    $scope.resultCountOptions = [
      15,
      30,
      45,
      90,
      250
    ];

    $scope.tableActions = [
      'Build Portfolio',
      'Save as CSV',
      'Create New Target'
    ];

    $scope.selectedResultCount = 250;
    // $scope.selectedSort = $scope.sortOptions[2]; // default for sort dropdown
    $scope.totalResults = 0;
    $scope.selectedTableAction = 'Build Portfolio';
    $scope.floatedRows = [];
    $scope.sortDirection = 'desc';
    $scope.sortModel = [];

    $scope.initSearch = function() {

      $timeout(function() {
        $scope.getData();
      });
    };

    function getModelType(model) {

      var modelType;

      if (model.field_traditional_strategy.und) {
        if (model.field_traditional_strategy.und[0].value == "1") {
          modelType = "Traditional Models";
        } else if (model.field_hedgecovest_product.und[0].value == "1") {
          modelType = 'Composite Models';
        } else {
          modelType = "Alternative Models";
        }
      } else {
        if (model.field_hedgecovest_product.und[0].value == "1") {
          modelType = 'Composite Models';
        } else {
          modelType = "Alternative Models";
        }
      }

      return modelType;
    }

    function getYearsTracked(startDate) {

      var yearsTracked = moment().diff(startDate, 'years');

      return yearsTracked;
    }

    var extraFields = ["changed", "comment", "created", "data", "field_available_for_cash_account", "field_datacollection_start", "field_firm_crd", "field_fund_activity_history", "field_fund_currency_type", "field_fund_leverage_multiple", "field_fund_onboarding_checklist", "field_hide_due_diligence", "field_include_in_newsletter", "field_remove_default_disclosure", "field_start_date", "field_visibility_status", "language", "name", "path", "picture", "promote", "rdf_mapping", "revision_timestamp", "revision_uid", "sticky", "tnid", "translate", "type", "uid", "uuid", "vid", "vuuid", "status", "nid"];

    // takes a fund profile and removes the unnecessary fields
    function formatProfile(profile){
      extraFields.forEach(function(field){
        delete profile[field];
      });

      return profile;
    }
    var modelTypes = {};

    function processSearchData(result) {

      var searchModels = [];
      for (var i = result.models.length - 1; i >= 0; i--) {
        var obj = result.models[i],
            el_model = obj,
            searchModelObj;

        el_model.profile = formatProfile(el_model.profile);

        try {
          var el_data = obj.statistics;
          var guid = obj.guid;
        } catch (e) {
          console.log(e);
          continue;
        }

        if (!_.isEmpty(el_data)) {

          var annualizedReturn = el_data.annualizedReturn ? el_data.annualizedReturn : {};
          var minimumAllocation = Models.getMinimumById(guid) || (el_model.information.minimumAllocation ? Number(el_model.information.minimumAllocation) : 0);

          searchModelObj = {
            'title': el_model.information.name.replace(/^\s+|\s+$/g, ''),
            'data': el_model,
            'guid': guid,
            'id': guid,
            'smartx_guid': guid,
            'reported_guid': el_model.profile.field_fund_model_guid.und[0] ? el_model.profile.field_fund_model_guid.und[0].value : null,
            'ytd': Number(el_data.ytd),
            'today': Number(el_data.today),
            'yearsTracked': getYearsTracked(el_model.information.performanceStartDate * 1000),
            'no_symbols': 'n/a',
            'replicatability': 'n/a',
            'mtd': Number(el_data.mtd),
            'management_fee': el_model.information.feePercentage, // convert percentage to basis points
            'average_gain': Number(el_data.avg_gain),
            'average_loss': +el_data.avg_loss,
            'cagr': +el_data.avg_annual_return,
            'highest_return': (el_data.highest_return) ? +el_data.highest_return : '--',
            'profitable_per': 'n/a',
            'sharpe': +el_data.sharpe,
            'sortino': +el_data.sortino,
            'max_drawdown': (el_data.max_drawdown) ? +el_data.max_drawdown : '--',
            'correlation': +el_data.correlation,
            'standard_deviation': Number(el_data.std_dev),
            'minimum_allocation': minimumAllocation,
            'symbol': 'n/a',
            'model_type': getModelType(el_model.profile),
            '1-yr': (annualizedReturn != null && annualizedReturn["1"]) ? annualizedReturn["1"] * 100 : '--',
            '3-yr': (annualizedReturn != null && annualizedReturn["3"]) ? annualizedReturn["3"] * 100 : '--',
            '5-yr': (annualizedReturn != null && annualizedReturn["5"]) ? annualizedReturn["5"] * 100 : '--'
          };

          // fields correspond to fields on the fund profile
          // this loop checks for the values of those fields
          SearchFilters.fields.forEach(function(field) {

            var fund = el_model.profile['field_' + field],
              activeFilters = new Array();

            if (SearchFilters[field + '_dictionary'] !== null) {

              if (typeof fund !== 'undefined' && fund.und) {

                // use the key to look up the formatted label in the field dictionary.
                // und is an array of objects with one key value pair so take the first key from Object.keys
                // this should make it work with multiple types of keys (numeric id or string)
                var fieldData = fund.und.map(function(fund, index) {
                  var key = Object.keys(fund)[0];
                  var label = SearchFilters[field + '_dictionary'][fund[key]];
                  // if (angular.isUndefined(label)){
                  //   debugger;
                  // }

                  return label;
                });

                searchModelObj[field] = fieldData;

                if (field == 'secondary_model_type') {
                  fieldData.forEach(function(type) {
                    if (Object.keys(modelTypes).indexOf(type) < 0) {
                      if (angular.isUndefined(type)){
                        console.log("Model Types: ", modelTypes);
                        console.log("field data: ", fieldData);
                        console.log("Fund: ", fund);
                        // debugger;
                      }
                      modelTypes[type] = [];
                    }
                  });
                }

                if (field == 'model_category_2') {

                  // get top level classifications for the model
                  // will enter else block if no secondary model type is set in drupal
                  if (el_model.profile.field_secondary_model_type && el_model.profile.field_secondary_model_type.und) {
                    var types = el_model.profile.field_secondary_model_type.und.map(function(fund, index) {

                      var key = Object.keys(fund)[0];

                      return SearchFilters.secondary_model_type_dictionary[fund[key]];
                    });

                    // add model category to parent category
                    fieldData.forEach(function(category) {
                      types.forEach(function(type) {
                        modelTypes[type].push(category);
                      });
                    });
                  } else {
                    console.warn("No secondary model type set for model: ", el_model.profile.title);
                    return;
                  }
                }



                for (var i = 0; i < fieldData.length; i++) {
                  var key = fieldData[i];
                  activeFilters.push(key);
                }

                var unique = activeFilters.filter(onlyUnique);

                // converts field name into camel case so it can be looked up in the SearchFilters service
                // ex: fund_type -> fundType, fund_style_box -> fundStyleBox, secondary_model_type -> secondaryModelType
                var field = field.replace(/_([a-z0-9])/g, function(item) {

                  var _pos = field.indexOf(item),
                    nextLetter = field.charAt(_pos + 1),
                    needle = '_' + nextLetter,
                    replaceWith = nextLetter.toUpperCase();

                  return replaceWith;
                });

                for (var i = 0; i < SearchFilters[field].length; i++) {

                  if (unique.indexOf(SearchFilters[field][i].name) >= 0) {
                    SearchFilters[field][i].hasResults = true;
                  }
                }
              } else {
                searchModelObj[field] = null;
              }
            }
          });

          searchModels.push(searchModelObj);
        } else {

          searchModelObj = {
            'title': el_model.profile.title.replace(/^\s+|\s+$/g, ''),
            'data': el_model.profile,
            'ytd': 'N/A',
            'today': 'N/A',
            'no_symbols': 'N/A',
            'replicatability': 'N/A',
            'mtd': 'N/A',
            'average_gain': 'N/A',
            'average_loss': 'N/A',
            'cagr': 'N/A',
            'highest_return': 'N/A',
            'management_fee': 'N/A',
            'profitable_per': 'N/A',
            'sharpe': 'N/A',
            'sortino': 'N/A',
            'correlation': 'N/A',
            'standard_deviation': 'N/A',
            'minimum_allocation': 'N/A',
            'symbol': 'N/A',
            'model_type': 'N/A',
            '1-yr': 'N/A',
            '3-yr': 'N/A',
            '5-yr': 'N/A'
          };

          if (searchModelObj.data)
            $scope.searchModels.push(searchModelObj);
        }
      }

      $timeout(function(){
        $scope.searchModels = searchModels;

        $scope.modelTypesGroups = modelTypes;
        $scope.totalResults = $scope.searchModels.length;

        // Cache the search response
        Models.saveSearchResponse(result);

        $scope.loading = false;
        $scope.$broadcast('search-data-ready', searchModels);
      },1000);
    }

    $scope.getData = function() {

      $scope.searchModels = Array();
      loadUserPreset();

      $scope.loading = true;
      console.log("Getting search data...");

      performanceApi.modelsSearch()
      //Dashboard.getSearchData()
        .then(handleSearchResponse)
        .catch(function(res) {
          $scope.refreshingModelList = false;
          $scope.loading = false;
        })
        .finally(function(){
          $scope.$apply();
        });
    };

    function handleSearchResponse(response) {

      if ($scope.refreshingModelList) $scope.refreshingModelList = false;



      processSearchData(response.data);
    }


    $scope.searchTableForInput = function() {
      $scope.$broadcast('filter-serach-for-text', { text: $scope.searchText });
    };

    $scope.refreshModelList = function() {

      $scope.refreshingModelList = true;
      $scope.loading = true;
      console.log("Getting search data...");

      performanceApi.modelsSearch()
        .then(handleSearchResponse)
        .catch(function(res) {
          $scope.refreshingModelList = false;
          $scope.loading = false;
        })
        .finally(function(){
          $scope.$apply();
        });
    };

    //  https://smartx.atlassian.net/browse/AS-99
    IdentityFactory.getUser()
    .then(function(res) {
      $scope.smartx_user_guid = res.profile.smartx_user_guid
      $scope.smartx_token = res.access_token
    });



    $scope.performancePlatform = Dashboard.getPlatform()
    
    //  Create Event Listener for asset Shop
    document.getElementById("model-search-vue").addEventListener('_ModelSearchAppliedFilter', function (event) {
      Amplitude.logEvent(event.detail[0]);
    });
    document.getElementById("model-search-vue").addEventListener('AssetShopCreateComparison', function (event) {
      $scope.assetShopComparisonData = event.detail[0];
      $scope.toolbarAction('Asset Shop Comparison');
    });
    document.getElementById("model-search-vue").addEventListener('AssetShopComparisonClick', function (event) {
      Amplitude.logEvent('Model Search:Asset Shop:Comparison Click');
    })
    document.getElementById("model-search-vue").addEventListener('_AssetShopBuildPortfolio', function (event) {
      if (event.detail[0].length == 0) { return; }

      const modelList = $scope.searchModels
      const selectedModelIds = event.detail[0].map((x) => {
        return x.id
      })
      const selectedModels = modelList.filter((x) => {
        if (selectedModelIds.includes(x.id)) return true
        else return false
      })


      SearchFilters.selectedModelIDs = selectedModelIds
      SearchFilters.setSelectedRows(selectedModels);

      Amplitude.logEvent('Model Search:Toolbar Action:Build Portfolio');


      $q.when($scope.addTab('portfolio-builder'))
        .then(function () { });

      $scope.$broadcast('table-cleared', {});
    })

    //Create Event Listener for Model Detail screen 
    document.getElementById("model-search-vue").addEventListener('_AssetShopOpenModelInfo', function (event) {
      $scope.addModelTabForId(event.detail[0].id);
      const modelList = $scope.searchModels;
      const selectedModel = modelList.filter((x) => {
        return x.id == event.detail[0].id;
      });
      Models.currentModel = selectedModel[0];

      $scope.addModelTabForId(selectedModel[0].id);
      $scope.$apply();
    });

    //Create Event Listener for Create Target
    document.getElementById("model-search-vue").addEventListener('_AssetShopCreateTarget', function (event) {
      if (event.detail[0].length == 0) { return; }

      const modelList = $scope.searchModels
      const selectedModelIds = event.detail[0].map((x) => {
        return x.id
      })
      const selectedModels = modelList.filter((x) => {
        if (selectedModelIds.includes(x.id)) return true
        else return false
      })

      const selectedRows = selectedModels.map((x) => {
        return {
          guid: x.id,
          title: x.title
        }
      })

      $scope.createTargetGroup(selectedRows);
      $q.when($scope.addTab('targetEdit'))
        .then(function () {
          console.timeEnd("adding a edit target tab.");
        });
      $scope.$broadcast('table-cleared', {});


      SearchFilters.selectedModelIDs = selectedModelIds
      SearchFilters.setSelectedRows(selectedModels);

      Amplitude.logEvent('Model Search:Toolbar Action:Create New Target');


      $q.when($scope.addTab('targetEdit'))
        .then(function () { });

      $scope.$broadcast('table-cleared', {});
    })

    $scope.toolbarAction = function(action) {

      var rows;
      action = action || $scope.selectedTableAction;

      switch (action) {
        case 'Asset Shop Comparison':
          Tabs.addTab('modelComparison', null, null, $scope.assetShopComparisonData);
          Amplitude.logEvent('Model Search:Toolbar Action:Asset Shop Comparison');
          $scope.$apply();
          break;
          
        case 'Allocate':
          $scope.addTab('allocate');
          $scope.gridOptions.api.deselectAll();
          Amplitude.logEvent('Model Search:Toolbar Action:Allocate');
          break;

        case 'Build Portfolio':

          if ($scope.selectedRows.length == 0) { return; }

          SearchFilters.selectedModelIDs = $scope.selectedModelIDs;
          SearchFilters.setSelectedRows($scope.selectedRows);

          Amplitude.logEvent('Model Search:Toolbar Action:Build Portfolio');


          $q.when($scope.addTab('portfolio-builder'))
          .then(function() {});

          $scope.$broadcast('table-cleared', {});
          break;

        case 'Save as CSV':

          var rows = [];
          var data;
          console.log($scope.data);

          Amplitude.logEvent('Model Search:Toolbar Action:Save as CSV');

          if ($scope.data.selectedRows && $scope.data.selectedRows.length){
            data = $scope.data.selectedRows;
          } else {
            data = $scope.filteredModels.length ? $scope.filteredModels : $scope.searchModels;
          }

          _.each(data, function(model, index){

            var row = {};

            _.extend(row, {
              "Model"                       : model.title,
              "YTD"                         : model.ytd,
              "MTD"                         : model.mtd,
              // "Today (%)"   : model.today,
              "Avg. Ann."                   : model.cagr,
              "Sharpe Ratio"                : model.sharpe,
              "Sortino Ratio"               : model.sortino,
              "Std Dev"                     : model.standard_deviation,
              "Correlation"                 : model.correlation,
              "Track Record (in years)"     : model.yearsTracked,
              "Min. Allocation"             : model.minimum_allocation,
              "Fee"                         : model.management_fee,
            });

            var hiddenColumnNames = SearchFilters.columnDefs.filter(function(column){
              return !column.show && column.headerName != "";
            })
            .map(function(column){
              return column.headerName;
            });

            hiddenColumnNames.forEach(function(name){
              delete row[name];
            });

            rows.push(row);

          });

          Dashboard.JSONtoCSV(rows, 'model_search_results.csv');
          $scope.$broadcast('table-cleared', {});
          break;

        case 'Create New Target':

          if ($scope.selectedRows.length == 0) { return; }

          Amplitude.logEvent('Model Search:Toolbar Action:Create Target');

          // check to see if selection contains SMA models first
          var smaModel = $scope.selectedRows.find(function(model){

            var sma = Models.getModelList().find(function(_model){
              return _model.id === model.id && _model.type && _model.type.toLowerCase() === 'sma';
            });

            return sma;
          });

          if (smaModel) {
            SweetAlert.swal("", "SMA models cannot be used in target portfolios", "info");
            return false;
          }

          $scope.createTargetGroup($scope.selectedRows);
          $q.when($scope.addTab('targetEdit'))
            .then(function() {
              console.timeEnd("adding a edit target tab.");
            });
          $scope.$broadcast('table-cleared', {});
          break;

      }
    };

    $scope.$on('selectionChanged', function(event, data) {
      console.log("event: ", event);
      console.log("data: ", data);
    });

    $scope.createTargetGroup = function(rows) {
      console.log("Currently selected rows: ", rows);

      var payload = {
        "id": null,
        "name": "Target-" + new Date().getTime(),
        "details": []
      };

      var cash = 0;
      var allocated = 0;
      var percent = parseFloat(100 / rows.length);

      rows.forEach(function(model) {
/*         var slice = {
          "modelId": model.guid,
          "name": model.title,
          "percent": parseFloat((percent / 100).toFixed(4)),
          "percentUpperThreshold": 0.20,
          "percentLowerThreshold": 0.20,
          "percentUpperPrecision": 0.04,
          "percentLowerPrecision": 0.04,
          "leverage": 1,
          "matchModelLeverage": true,
          "type": "model"
        }; */

        var slice = {
          "name": model.title,
          "percent": parseFloat((percent / 100).toFixed(4)),
          /* "percentUpperThreshold": 0.20,
          "percentLowerThreshold": 0.20,
          "percentUpperPrecision": 0.04,
          "percentLowerPrecision": 0.04,
          "leverage": model.selectedLeverage,
          "matchModelLeverage": true, */
          "type": "model",
          "target": {
            "id": model.guid,
            "type": "model",
            "name": model.title,
            "leverage": 1,
            "matchModelLeverage": true
          },
          "threshold": {
            "upper": 0.2,
            "lower": 0.2
          },
          "precision": {
            "upper": 0.04,
            "lower": 0.04
          }
        };

        allocated += parseFloat(percent.toFixed(4));
        payload.details.push(slice);
      });

      cash = (100 - allocated) / 100;
      // payload.details.cash.percent = cash;

      payload.details.push({
        //type: 'cash',
        percent: cash,
/*      percentUpperThreshold: 0.2,
        percentLowerThreshold: 0.2,
        percentUpperPrecision: 0.04,
        percentLowerPrecision: 0.04 */
        "target": {
          "type": "cash",
          "name": "$CASH",
          "leverage": null,
          "matchModelLeverage": null
        },
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        }
      });

      Tabs.addTab('targetEdit', null, null, payload);
    };

    /* listeners */
    $scope.$on('search::rowsAddedToBuilder', function() {
      SearchFilters.setSelectedRows([]);
    });

    function deselectRows() {
      $timeout(function() { }, 1500);
    }

    // $scope.changeSort = function() {

    //   $scope.sortModel = [{
    //     colId: $scope.selectedSort.id,
    //     sort: $scope.sortDirection
    //   }];
    // };

    $scope.clearSort = function() {

    };

    $scope.toggleSortDirection = function() {
      if ($scope.sortDirection === 'asc') {
        $scope.sortDirection = 'desc';
      } else {
        $scope.sortDirection = 'asc';
      }

      $scope.sortModel = [{
        colId: $scope.selectedSort.id,
        sort: $scope.sortDirection
      }];
    };


    // filters array of rows based on current filters
    // part of the improved paging implementation
    // filtering would be doen thru here rather than using ag-grid filtering
    // function filterData(filterModel, data) {
    //   var filterTypes = ['fund_type', 'account_compatibility', 'fund_style_box', 'model_type', 'model_category_2', /*'model_category'*/ , 'secondary_model_type', 'sector_focus', 'asset_class', 'portfolio_construction', 'market_capitalization', 'regions'];
    //   var rangers = [{
    //       id: 'yearsTracked',
    //       colName: 'Track Record (in years)'
    //     },
    //     {
    //       id: 'cagr',
    //       colName: 'CAGR'
    //     },
    //     {
    //       id: 'standard_deviation',
    //       colName: 'Standard Deviation'
    //     },
    //     {
    //       id: 'sortino',
    //       colName: 'Sortino'
    //     },
    //     {
    //       id: 'sharpe',
    //       colName: 'Sharpe'
    //     },
    //     {
    //       id: 'correlation',
    //       colName: 'Correlation'
    //     },
    //     {
    //       id: 'minimum_allocation',
    //       colName: 'Min. Allocation'
    //     },
    //     {
    //       id: 'management_fee',
    //       colName: 'Fee'
    //     }
    //   ];

    //   var filterTerms = {};
    //   var searchTextPresent = ($scope.searchText && $scope.searchText.length);
    //   var filterPresent = filterModel && Object.keys(filterModel).length > 0 || searchTextPresent;
    //   if (!filterPresent) {
    //     return data;
    //   }

    //   var resultOfFilter = [];

    //   var activeFilters = _.reduce($scope.filterModel, function(activeFilters, filterTerms, filterType) {
    //     if (_.contains(filterTypes, filterType)) {
    //       return activeFilters.concat(filterTerms);
    //     } else {
    //       return activeFilters;
    //     }
    //   }, []);

    //   // watch this and filter dataset when it changes
    //   $scope.activeFilters = _.flatten(activeFilters);

    //   // this loop can be placed into a function and called whenever the activeFilters array changes
    //   for (var i = 0; i < data.length; i++) {
    //     var item = data[i];

    //     if (searchTextPresent) {
    //       if (item.title.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1) {
    //         var searchTextMatch = true;
    //       } else {
    //         var searchTextMatch = false;
    //         continue
    //       }
    //     }

    //     var rowProperties = [];

    //     _.each(filterTypes, function(filterType) {
    //       if (!_.isEmpty(item[filterType]) && item[filterType].length) {
    //         rowProperties.push(item[filterType]);
    //       }
    //     });

    //     rowProperties = _.flatten(rowProperties);

    //     var match = _.some(activeFilters, function(term) {
    //       return _.contains(rowProperties, term);
    //     });
    //     var passed = true;
    //     if ($scope.filterModel['rangeFilters'] && $scope.filterModel['rangeFilters'].length) {
    //       rangers.forEach(function(field) {
    //         var inRange = valueInRange(item[field.id], search.rangeFilters[field.colName]);

    //         if (!inRange) {
    //           passed = false;
    //         }
    //       });
    //     }

    //     if (match && passed) {
    //       resultOfFilter.push(item);
    //     }

    //   }

    //   return resultOfFilter;
    // }


    // $scope.changeResultCount = function() {
    //   updateDataSource();
    // };

    $scope.addTab = function(type) {
      var tab;

      if (type === 'allocate') {

        Dashboard.selectedAllocationModel = Models.currentModel;
        Dashboard.selectedAllocationAccount = Dashboard.currentAccount;
        Dashboard.selectedAllocationModel = SearchFilters.selectedRows[0].data.node;
        if (SearchFilters.selectedRows.length == 1) {
          tab = Tabs.addTab(type);
          Dashboard.notifyObservers('search');
        } else if (!SearchFilters.selectedRows.length) {
          SweetAlert.swal("You must select at least one model before making an allocation");
          return false;
        } else {
          SweetAlert.swal("Only one allocation at a time is allowed");
          return false;
        }

      } else if (type === 'portfolio-builder') {
        tab = Tabs.addTab(type);
        Dashboard.notifyObservers('portfolio-builder');

      } else if (type === 'targetEdit') {
        Dashboard.notifyObservers('targetEdit');
      }

      if (tab) {
        $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);
      }
    };

    $scope.addModelTabForId = function(modelId) {

      setTimeout(function() {
        var type = 'model',
          tab = Tabs.addTab(type);

        if (tab) {
          $scope.$apply();
          $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);
        }
      }, 1);
    };

    $scope.resetSliders = function() {

      // search.rangeFilters = angular.copy(SearchFilters.rangeFilters); // deep extend?

      // reset to the starting values
      _.each(search.rangeFilters, function(_filter, key){
        _filter.min = SearchFilters.rangeFilters[key].min;
        _filter.max = SearchFilters.rangeFilters[key].max;
      });

      $scope.rangeFilters = search.rangeFilters;
      search.refreshSlider();

    };

    $scope.clearFilters = function() {

      // clear all checkboxes
      var filters = ['fundType', 'accountCompatibility', 'fundStyleBox', 'modelType', 'modelCategory2' /*'modelCategory'*/ , 'secondaryModelType', 'regions', 'assetClass', 'marketCapitalization', 'sectorFocus', 'portfolioConstruction']; //, 'futuresFocus'
      var rowData;

      filters.forEach(function(filter) {
        for (var i = 0; i < SearchFilters[filter].length; i++) {
          SearchFilters[filter][i].selected = false;
        }
      });

      $scope.filterModel = {};
      $scope.hcv_products.showing = ''; // reset it back to all
      $scope.resetSliders();
      filterModel = $scope.filterModel;

      rowData = $scope.searchModels;
    };

    function modelUpdatedFunc(event) {
      $scope.updateTableHeight(rowCount);
      $scope.totalResults = rowCount;
    }

    function selectionChangedFunc(event) {

      $scope.selectedRows = Array();
      $scope.selectedModelIDs = [];

      for (var i = selectedRows.length - 1; i >= 0; i--) {
        var row = selectedRows[i];
        $scope.selectedRows.push(row.guid);
        $scope.selectedModelIDs.push(row.guid);
      }

    }

    function updateTextFilter(filterType, key, label) {

      if (key && key.name && label) {
        Amplitude.logEvent('Model Search:Filter:' + label + ':' + key.name);
      }

      if (!$scope.filterModel[filterType]) {
        $scope.filterModel[filterType] = [];
      }

      if (key.selected) {
        $scope.filterModel[filterType].push(key.value);
      } else if (!key.selected) {

        var filterIndex = $scope.filterModel[filterType].indexOf(key.value);
        $scope.filterModel[filterType].splice(filterIndex, 1);

        if (!$scope.filterModel[filterType].length) {

          delete $scope.filterModel[filterType];
        }
      }
      filterModel = $scope.filterModel;
      $timeout(function() { });

    }

    function rowClickedFunc(params) {
      Models.currentModel = params.data;
    }

    $scope.updateTableHeight = function(rowCount) {
      var count = Math.min(rowCount, $scope.selectedResultCount),
        height = (count * $scope.settings.tableRowHeight) + ($scope.settings.tableHeaderHeight * 3);
      $scope.agGridHeight = height;
      return height;
    };


    $scope.updateTableHeightAfterFilter = function() {

      var count = $scope.totalResults;

      if (count) {
        var height = (count * $scope.settings.tableRowHeight) + ($scope.settings.tableHeaderHeight * 3);
        $scope.agGridHeight = height;

        return height;
      } else {
        var height = $scope.selectedResultCount * $scope.settings.tableRowHeight;
        $scope.agGridHeight = height;

        return height;
      }
    };

    $scope.toggleToolPanel = function() {

      var modalInstance = $uibModal.open({
        animation: true,
        template: $templateCache.get('search.customize.modal.tpl.html'),
        controller: 'SearchCustomizeModalCtrl'
      });

      modalInstance.result
        .then(function(data) {
          showingLoginModal = false;
          console.log(data);
          // listener in searchTable.js directive
          $scope.$broadcast('search-data-ready', $scope.searchModels);
        });

    };

    $scope.arrayHasKeys = function(a) {
      return Object.keys(a).length;
    };


    function loadUserPreset() {

      var loadedPresets = store.get('smxSearchPresets');

      if (loadedPresets && !_.isEmpty(loadedPresets.payload)) {
        SearchFilters.columnDefs = loadedPresets.payload.filter(function (item) {
          return item.field !== 'today';
        })
        .map(function(item){
          delete item.filter;
          return item;
        });
      } else {
        console.warn('Failed to get/load user preset');
      }
    }

    function updateRangeFilters(filter, action) {

      if (!$scope.filterModel.rangeFilters) {
        $scope.filterModel.rangeFilters = [];
      }

      if (action === 'add') {
        if ($scope.filterModel.rangeFilters.indexOf(filter) < 0) {
          $scope.filterModel.rangeFilters.push(filter);
        }
        debugger;
      } else {
        var filterIndex = $scope.filterModel.rangeFilters.indexOf(filter);
        $scope.filterModel.rangeFilters.splice(filterIndex, 1);

        if (!$scope.filterModel.rangeFilters.length) {

          delete $scope.filterModel.rangeFilters;
        }
      }

      filterModel = $scope.filterModel;

      search.updateRangeFilterModel();
    }

    $scope.$watch('filterModel', function(newVal, oldVal) {
      if (newVal && oldVal && newVal != oldVal) {
        $scope.$broadcast('filter-changed', newVal);
      }
    }, true);

    $scope.searchFilterFields = [{
      className: 'clearfix',
      fieldGroup: [{
          key: 'filter_modelType2',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Model Type',
            options: SearchFilters.secondaryModelType
          },
          controller: /*@ngInject*/ function($scope, $rootScope) {

            $scope.updateFilters = function(key) {
              updateTextFilter('secondary_model_type', key, $scope.to.label);
            };

            $scope.$watch('to.options', function(newVal, oldVal) {
              if (angular.isDefined(newVal) && newVal != oldVal) {
                $rootScope.$broadcast('categoryChanged', $scope.to.options);
              }
            }, true);

          }
        },
        {
          key: 'filter_modelCategory2',
          className: 'col-xs-12',
          type: 'filter_category_checkboxes',
          templateOptions: {
            label: 'Model Category',
            options: _.sortBy(SearchFilters.modelCategory2, 'name'),
          },
          controller: /*@ngInject*/ function($scope) {

            var categories = angular.copy($scope.to.options);
            $scope.activeCategories = [];
            $scope.$on('categoryChanged', handleCategoryChanged);

            function handleCategoryChanged(event, data) {

              var selectedCategories = data.filter(function(option) {
                  return option.selected;
                })
                .map(function(option) {
                  return option.value;
                });

              // activeCategories could be used to filter from the template using ng-if
              $scope.activeCategories = selectedCategories;

              // loop thru model categories
              // if any are selected that don't match up with the top level selection,
              //unselect it so that it is removed from the active filters list

              if (selectedCategories.length) {
                $scope.to.options.forEach(function(option) {

                  var match = false;

                  selectedCategories.forEach(function(category) {

                    var optionInSelected = _.intersection(option.classification.split(', '), selectedCategories).length;

                    var rx = new RegExp(category); // ex: /US Equity/
                    if (rx.test(option.classification)) {
                      match = true;
                    } else if (option.selected) {
                      if (!optionInSelected) {
                        option.selected = false;
                        $scope.updateFilters(option);
                      }
                    }
                  });
                });
              } else {
                $scope.to.options.forEach(function(option) {
                  if (option.selected) {
                    option.selected = false;
                    $scope.updateFilters(option);
                  }
                });
              }

            }
            $scope.optionInCategory = function(option, activeCategories) {

              if (activeCategories.length) {
                var match = false;


                activeCategories.forEach(function(category) {

                  var rx = new RegExp(category); // ex: /US Equity/

                  // added check to see if the option is included in the modelTypes list
                  // this filters out any filters model type / options combinations that don't have any results
                  if (rx.test(option.classification) && modelTypes[category] && modelTypes[category].indexOf(option.name) >= 0) {
                    match = true;
                  }
                });

                return match;
              } else {
                return true;
              }
            };

            $scope.updateFilters = function(key) {
              updateTextFilter('model_category_2' /*'model_category'*/ , key, $scope.to.label);
            };
          }
        },
        {
          key: 'rangeFilters',
          className: 'col-xs-12',
          type: 'filter_ranges', // maps to filter_range.html template defined in 'search.html'
          model: search,
          templateOptions: {
            label: 'Advanced Search',
            options: SearchFilters.keyStats
          },
          controller: /*@ngInject*/ function($scope) {

            $scope.checkValue = function(filterName, modelValue, highValue, pointerType) {

              var filterName = filterName;

              console.log("model value: ", modelValue);
              console.log("high value: ", highValue);
              console.log("pointer type: ", pointerType);

              // if the value is not the default starting value
              if (modelValue !== this.floor || highValue !== this.ceil) {
                updateRangeFilters(filterName, 'add');
                debugger;
              } else {
                updateRangeFilters(filterName, 'remove');
              }

              Amplitude.logEvent('Model Search:Advanced Search:'+filterName);
            };

            $scope.toggleVisibility = function(open) {

              if (open) {

                $timeout(function() {
                  $scope.$broadcast('rzSliderForceRender');
                }, 200);
                return true;
              } else {
                return false;
              }
            };
          }
        },
        {
          key: 'filter_fundType',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Model Strategy',
            options: SearchFilters.fundType
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('fund_type', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_accountCompatibility',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Account Compatibility',
            options: SearchFilters.accountCompatibility
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('account_compatibility', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_styleBoxes',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Style Boxes',
            options: SearchFilters.fundStyleBox
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('fund_style_box', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_regions',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Regions',
            options: SearchFilters.regions
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('regions', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_assetClass',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Asset Class',
            options: SearchFilters.assetClass
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('asset_class', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_marketCapitalization',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Market Capitalization',
            options: SearchFilters.marketCapitalization
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('market_capitalization', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_sectorFocus',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Sector Focus',
            options: SearchFilters.sectorFocus
          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('sector_focus', key, $scope.to.label);
            };
          }
        },
        {
          key: 'filter_portfolioConstruction',
          className: 'col-xs-12',
          type: 'filter_checkboxes',
          templateOptions: {
            label: 'Portfolio Construction',
            options: SearchFilters.portfolioConstruction

          },
          controller: /*@ngInject*/ function($scope) {
            $scope.updateFilters = function(key) {
              updateTextFilter('portfolio_construction', key, $scope.to.label);
            };
          }
        }
      ]
    }];

    $scope.showingFilters = true;
    $scope.toggleFilters = function toggleFilters() {
      $scope.showingFilters = !$scope.showingFilters;
    };

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    function isExternalFilterPresent() {
      return true;
    }

    function doesExternalFilterPass(node) {

      // console.log("Checking if external filter passes");
      // debugger
      // var passed = true;

      // if ($scope.filterModel.rangeFilters && $scope.filterModel.rangeFilters.length) {
      //   rangeFilterColumnRefs.forEach(function(column) {
      //     var inRange = valueInRange(node.data[column.id], search.rangeFilters[column.colName]);

      //     if (!inRange) { passed = false; }
      //   });
      // }

      // return passed;
    }

    function valueInRange(value, range) {
      if (value >= range.min && value <= range.max) { return true;}
      else if (range.options.floor === range.min && range.options.ceil === range.max) { return true; }
      else if (range.options.floor === range.min || range.options.ceil === range.max) {

        if (range.options.floor === range.min) {
          return value <= range.max;
        } else if (range.options.ceil === range.max) {
          return value >= range.min;
        }

      } else if (range.options.floor === range.max || range.options.ceil === range.min) {

        if (range.options.floor === range.max) {
          return value <= range.max;
        } else if (range.options.ceil === range.min) {
          return value >= range.min;
        }

      }
      else { return false; }
    }

    // clean up
    $scope.$on('$destroy', function() {
      SearchFilters.clearSelectedRows();
      SearchFilters.selectedModelIDs = [];
    });
  })
  .controller('SearchCustomizeModalCtrl', function($scope, $uibModalInstance, $http, SearchFilters) {

    console.log("Opening search customize modal...");
    $scope.errorMsg = '';
    $scope.saveTableColumnsPreset = false;
    $scope.checkboxes = [];
    $scope.tableColumns = {
      selected: null,
      columns: {
        "Table Columns": []
      }
    };

    $scope.models = {
      selected: null,
      lists: {
        "A": []
      }
    };

    $scope.itemMovedFunc = function(list, item, index) {
      console.log(list, item, index);

      var index = $scope.tableColumns.columns["Table Columns"].indexOf(item);
      $scope.tableColumns.columns["Table Columns"].splice(index, 1);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.dropCallback = function(index, item, external, type) {

      var idx = -1;
      for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
        var column = $scope.tableColumns.columns["Table Columns"][i];
        if (column.field == item.field) {
          idx = i;
        }
      }
      if (idx > -1) {
        $scope.tableColumns.columns["Table Columns"].splice(idx, 1);
      }

      return item;
    };

    $scope.updateTable = function() {

      var checkboxIdx = -1;
      var columns = $scope.tableColumns.columns["Table Columns"];
      var titleIdx = -1;

      for (var i = 0; i < columns.length; i++) {
        var column = columns[i];
        if (column.field == 'checkbox') {
          checkboxIdx = i;
        }
      }
      if (checkboxIdx !== 0) {
        columns.move(checkboxIdx, 0);
      }

      for (var i = 0; i < columns.length; i++) {
        var column = columns[i];
        if (column.field == 'title') {
          titleIdx = i;
        }
      }
      if (titleIdx !== 1) {
        columns.move(titleIdx, 1);
      }

      if ($scope.saveTableColumnsPreset == true) {
        console.log('Save search table preset for user.', columns);
        SearchFilters.saveSearchPresetforUser(columns);
      }

      for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
        var column = $scope.tableColumns.columns["Table Columns"][i];
        var checkitem = $scope.checkboxes[column.field];
        if (typeof checkitem != 'undefined') {
          console.log(column, checkitem);
          column.show = checkitem;
          column.hide = !checkitem;
        }
      }
      SearchFilters.columnDefs = $scope.tableColumns.columns["Table Columns"];
      $uibModalInstance.close($scope.tableColumns.columns["Table Columns"]);
    };

    $scope.selectModel = function(item) {
      item.selected = !item.selected;
      if (item.selected == false) {
        item.hide = true;
      }
    };


    function init() {

      var cols = SearchFilters.columnDefs;
      console.log('Table columns', cols);

      if (cols.length > 0) {
        $scope.tableColumns.columns["Table Columns"] = [];
        for (var i = 0; i < cols.length; i++) {
          var column = cols[i];
          if (typeof column.field == 'undefined') {
            continue;
          }

          if (typeof column.hide != 'undefined' && column.hide == true) {
            $scope.checkboxes[column.field] = false;
            column.hide = true;
          } else {
            column.selected = true;
            $scope.checkboxes[column.field] = true;
            column.hide = false;
          }
          $scope.tableColumns.columns["Table Columns"].push(column);
        }
      }
    }

    init();
  });
