angular
  .module('PendingAccounts', [])
  .controller("PendingAccountsCtrl", function pendingAccountsCtrl(FeatureFlags, EnterpriseFactory, $filter, $rootScope, $scope, Brokerage, filterFilter, Tabs, Dashboard, NgTableParams, $q, TargetWeightFactory, toastr, $uibModal, SmartXFactory) {

    var vm = this;
    var modalInstance;

    vm.mappedAccounts = [];
    vm.isLoading = false;
    vm.isLoadingModal = [];
    //vm.accountStatuses = $q.defer();

    vm.isEnabled = FeatureFlags.isEnabled;

    vm.testFilter = {
      'status': {
        id: "custom-status-select",
        placeholder: "Select status",
      }
    };


    var statuses = Brokerage.accountStatuses;

    vm.statuses = _.reduce(statuses, function(statuses, status){
      statuses[status.id] = status.title;
      return statuses;
    }, {});

    console.log("Statuses: ", vm.statuses);

    vm.accountBrokerageFirms = [{
			id: "",
			title: "- Select Firm -"
    }];
    
    var topLevelDrupalEnterprise = EnterpriseFactory.getTopLevelDrupalEnterprise();

    if (topLevelDrupalEnterprise){
      vm.addAccountDisabled = topLevelDrupalEnterprise.addAccountDisabled;
    }

    $rootScope.$on('refresh-accounts-summary', function(event, args) {
      getAccountsFromFactory();
    });

    vm.init = function() {
      console.log("initializing accounts summary");
      getAccountsFromFactory();
    };

    vm.getPendingAccountSummary = function() {

      vm.isLoading = true;
      Dashboard.getPendingAccountsSummary()
        .then(function(result) {
          var summary = result;

          if (summary.data.statusCode == 200) {

            vm.accountsSummary = summary.data.data;
            vm.isLoading = false;
            vm.mappedAccounts = [];
            vm.isLoadingModal = [];

						// Exit if no pending accounts
						if(vm.accountsSummary.length == 0){ return; }

            for (var key in vm.accountsSummary) {

              var account = vm.accountsSummary[key];

              if (account.status == 'pending' ||
                account.status == 'account application sent to client to sign' ||
                account.status == 'account application not in good order' ||
                account.status == 'account application pending Broker approval') {
                vm.mappedAccounts.push(account);
                vm.isLoadingModal[account.information.guid] = false;
              }
            }

            vm.totalItems = Object.keys(vm.mappedAccounts).length;

            vm.tableParams = new NgTableParams({
              sorting: {
                startedTracking: "desc"
              },
              filter: {
                status: 'pending'
              }
            }, {
              dataset: vm.mappedAccounts
            });
            vm.tableParams.settings({
              counts: (vm.mappedAccounts.length > 25) ? [25, 50, 100] : []
            });

          } else if (summary.data.statusCode == 500) {
            if (summary.data.errorMessage) {
              toastr.error(summary.data.errorMessage);
            } else {
              toastr.error('An unexpected error occurred');
            }
            vm.isLoading = false;
          } else if (summary.data.statusCode == 'fail') {
            if (summary.data.msg) {
              toastr.error(summary.data.msg[0].description);
            } else {
              toastr.error('An unexpected error occurred');
            }
            vm.isLoading = false;
          }
        })
        .catch(function(err) {
          console.error(err);
        })
        .finally(function() {
          vm.isLoading = false;
        });
    };

    vm.addTab = function(type, account) {

      var accountIndex = vm.mappedAccounts.indexOf(account);
      if (angular.isDefined(account)) {
        Tabs.addTab(type, account.id, null, account);
      } else {
        Tabs.addTab(type, null);
      }

      // this updates the selected account in the existing allocate or liquidate tab
      Dashboard.notifyObservers('accountSummary-' + type);
    };

    vm.editDefaultTarget = function(account) {
      var target = {id: account.targetWeightId, isDefaultTarget: true, accessLevel: account.permission, enterprise: account.enterprise };
      TargetWeightFactory.setActiveTarget(target);
      Tabs.addTab('targetEdit');
    };



    vm.activeAccount = undefined;
    vm.activeAccountPeople = [];
    vm.resendEmail = function(account) {
      vm.activeAccount = account;

      console.log('Query account people info', vm.activeAccount);
      vm.isLoadingModal[vm.activeAccount.id] = true;
      var peopleAPI = SmartXFactory.getPeopleAPI();
      var documentAPI = SmartXFactory.getDocumentsApi();

      modalInstance = $uibModal.open({
        templateUrl: 'resend-email.html',
        size: 'md',
        controller: 'PendingAccountsModalCtrl',
        controllerAs: 'vm',
        resolve: {
          activeAccount: function () {
            return vm.activeAccount;
          },
          activeAccountPeople: function () {
            return peopleAPI.get('', {accountId: vm.activeAccount.id})
              .then(function(result) {
                var idx = 0,
                    activeEmail = vm.activeAccount.primaryEmail,
                    person = result.data.data;

                vm.isLoadingModal[vm.activeAccount.id] = false;
                return person;
              });
          },
          accountDocuments: function () {

            // return acctsAPI.getAccountDocumentsForAccountId(vm.activeAccount.id)
            return documentAPI.get('', {accountId: vm.activeAccount.id})
              .then(function(result) {
                return (result.status === 200 && result.data.statusCode === 200) ? result.data.data : [];
              });
          }
        }
      });
    };

    function getAccountsFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        vm.loading = true;
        var accounts = SmartXFactory.getAccounts('Pending', true)
        .then(function (resp) {
          setAccountsData(resp);
        }, function(err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        });

      }else{
        vm.loading = false;
        vm.accountsSummary = vm.mappedAccounts = [];
      }

    }

    function setAccountsData (data) {

        vm.accountsSummary = vm.mappedAccounts = data.map(function(account){
          account.brokerageName = account.custodian ? account.custodian.name : account.brokerage;
          if (!account.statistics) {
            account.statistics = {
              "value": 0,
              "cash": 0,
              "exposure": {
                "long": {
                  "dollar": 0,
                  "percent": 0
                },
                "short": {
                  "dollar": 0,
                  "percent": 0
                },
                "net": {
                  "dollar": 0,
                  "percent": 0
                },
                "gross": {
                  "dollar": 0,
                  "percent": 0
                }
              },
              "returns": {
                "today": {
                  "dollar": 0,
                  "percent": 0
                },
                "mtd": {
                  "dollar": 0,
                  "percent": 0
                },
                "qtd": {
                  "dollar": 0,
                  "percent": 0
                },
                "ytd": {
                  "dollar": 0,
                  "percent": 0
                }
              }
            };
          }
          return account;
        });

        var statuses = _.reduce(vm.mappedAccounts, function(statuses, account){
          var entry = {};
          entry.id = account.status;
          entry.title = vm.statuses[account.status] || 'Unknown';
          statuses.push(entry);
          return statuses;
        }, []);

        var accountStatuses = _.uniq(statuses, function(item, key, a) { 
            return item.id;
        });

        vm.accountStatuses = [{"id": "", "title": "- Select Account Status -"}].concat(accountStatuses);

        vm.totalItems = vm.mappedAccounts.length;

        vm.isLoadingModal = vm.mappedAccounts.map(function(item){
          var obj = {};
          return obj[item.id] = false;
        });

        vm.mappedAccounts.forEach(function(account){
          account.brokerageName = account.custodian ? account.custodian.name : account.brokerage;
          var brokerIds = _.map(vm.accountBrokerageFirms, 'id');
          if (!_.contains(brokerIds, account.brokerageName)){
            vm.accountBrokerageFirms.push({
              id: account.brokerageName,
              title: $filter('formatStatus')(account.brokerageName)
            });
          }
        });

        vm.tableParams = new NgTableParams({
          sorting: {
            'createdOn.timestamp': "desc"
          },
          filter: {status: "pending", brokerageName: "" }
        }, {
          dataset: vm.mappedAccounts
        });
        vm.tableParams.settings({
          counts: (vm.mappedAccounts.length > 25) ? [25, 50, 100] : []
        });

        vm.numPerPage = vm.mappedAccounts.length;
        vm.loading = false;

        $scope.$apply();
    }


    vm.init();
  })
  .controller("PendingAccountsModalCtrl", function($uibModalInstance, $scope, activeAccount, activeAccountPeople, accountDocuments, Dashboard, toastr) {

    var vm = $scope;

    vm.activeAccount = activeAccount;
    vm.activeAccountPeople = activeAccountPeople.map(function(item){
      var personId = item.id;
      item.documents = accountDocuments.filter(function(docs) {
        return docs.person.id === personId;
      });
      return item;
    });
    vm.activeAccountDocuments = accountDocuments;

    console.log('PendingAccountsModalCtrl', activeAccount, activeAccountPeople, accountDocuments);
    vm.isLoading = false;

    vm.firstNameInput = vm.activeAccountPeople.firstName;
    vm.lastNameInput = vm.activeAccountPeople.lastName;

    vm.closeModal = function () {
      vm.activeAccount = undefined;
      vm.activeAccountPeople = [];
      $uibModalInstance.dismiss('cancel');
    };

    vm.resendEmailInput = vm.activeAccountPeople.map(function(item) {
      return item.email;
    });

    vm.updateEmailAndSend = function (person, idx) {

      vm.isLoading = true;
      var payload = {};
      var activePeople = vm.activeAccountPeople;
      var validEmails = activePeople.map(function(person) { return person.email; });
      payload.email = vm.resendEmailInput[idx];
      payload.firstName = person.firstName;
      payload.lastName = person.lastName;
      payload.accountId = person.account.id;

      if (validEmails.indexOf(payload.email) == -1){
        toastr.error("The e-mail address provided does not match a person associated with this account.");
        vm.isLoading = false;
        return false;
      }

      Dashboard.updateBrokerageAccountEmailForPerson(vm.activeAccount.id, person.id, payload)
        .then(function(result) {

          console.log('updateBrokerageAccountEmailForPerson', result);
          vm.isLoading = false;
          
          if (result.data && result.data.status == "success") {
            toastr.success(result.data.message[0]);
            vm.closeModal();
          } else {
            try {
              toastr.error(result.data.message[0]);
            } catch (e) {
              console.error(e);
              toastr.error("An unexpected error occurred.");
            }
          }
        });

    };
  })
  .controller("PendingAccountsPagedCtrl", function pendingAccountsCtrl(FeatureFlags, EnterpriseFactory, $filter, $rootScope, $scope, Brokerage, filterFilter, Tabs, Dashboard, NgTableParams, $q, TargetWeightFactory, toastr, $uibModal, SmartXFactory) {

    var vm = this;
    var modalInstance;

    vm.mappedAccounts = [];
    vm.isLoading = false;
    vm.isLoadingModal = [];

    vm.isEnabled = FeatureFlags.isEnabled;

    vm.statusFilterTouched = false;

    vm.statusFilter = {
      'status': {
        id: "custom-status-select",
        placeholder: "Select status",
      }
    };


    var statuses = [
      {"id": "", "title": "- Select Account Status -"},
      {
        id: 'pending',
        title: 'Pending'
      },
      {
        id: 'inactive',
        title: 'Inactive'
      },
      {
        id: 'closed',
        title: 'Closed'
      },
      {
        id: 'rejected',
        title: 'Rejected'
      }
    ]; // Brokerage.accountStatuses;

    vm.accountStatuses = statuses;

    vm.statuses = _.reduce(statuses, function(statuses, status){
      statuses[status.id] = status.title;
      return statuses;
    }, {});

    var topLevelDrupalEnterprise = EnterpriseFactory.getTopLevelDrupalEnterprise();

    if (topLevelDrupalEnterprise){
      vm.addAccountDisabled = topLevelDrupalEnterprise.addAccountDisabled;
    }

    vm.accountBrokerageFirms = [{
			id: "",
			title: "- Select Firm -"
		}];

    $rootScope.$on('refresh-accounts-summary', function(event, args) {
      getAccountsFromFactory();
    });

    vm.init = function() {
      console.log("initializing accounts summary");
      getAccountsFromFactory();
    };

    function updateBrokerList (brokers) {
      if (!brokers) brokers = [];
      vm.accountBrokerageFirms.splice(1);
      brokers.forEach(function(broker){
        vm.accountBrokerageFirms.push({
          id: broker.id,
          title: broker.name
        });
      });
    }

    vm.addTab = function(type, account) {
      if (angular.isDefined(account)) {
        Tabs.addTab(type, account.id, null, account);
      } else {
        Tabs.addTab(type, null);
      }

      // this updates the selected account in the existing allocate or liquidate tab
      Dashboard.notifyObservers('accountSummary-' + type);
    };

    vm.editDefaultTarget = function(account) {
      var target = {id: account.targetWeightId, isDefaultTarget: true, accessLevel: account.permission, enterprise: account.enterprise };
      TargetWeightFactory.setActiveTarget(target);
      Tabs.addTab('targetEdit');
    };

    function isOnlyFilter (filterKey, ignoreList) {
      var filters = vm.tableParams.filter();
      ignoreList = ignoreList || [];
      var activeFilters = _.filter(_.keys(filters), function(key){
        return !_.isEmpty(filters[key]) && !_.contains(ignoreList, key);
      });

      return activeFilters.length === 1 && activeFilters[0] === filterKey;
    }


    vm.activeAccount = undefined;
    vm.activeAccountPeople = [];
    vm.resendEmail = function(account) {
      vm.activeAccount = account;

      console.log('Query account people info', vm.activeAccount);
      vm.isLoadingModal[vm.activeAccount.id] = true;
      var peopleAPI = SmartXFactory.getPeopleAPI();
      var documentAPI = SmartXFactory.getDocumentsApi();

      modalInstance = $uibModal.open({
        templateUrl: 'resend-email.html',
        size: 'md',
        controller: 'PendingAccountsModalCtrl',
        controllerAs: 'vm',
        resolve: {
          activeAccount: function () {
            return vm.activeAccount;
          },
          activeAccountPeople: function () {
            return peopleAPI.get('', {accountId: vm.activeAccount.id})
              .then(function(result) {
                var idx = 0,
                    activeEmail = vm.activeAccount.primaryEmail,
                    person = result.data.data;

                vm.isLoadingModal[vm.activeAccount.id] = false;
                return person;
              });
          },
          accountDocuments: function () {

            // return acctsAPI.getAccountDocumentsForAccountId(vm.activeAccount.id)
            return documentAPI.get('', {accountId: vm.activeAccount.id})
              .then(function(result) {
                return (result.status === 200 && result.data.statusCode === 200) ? result.data.data : [];
              });
          }
        }
      });
    };

    var initialBrokers = [];
    function getAccountsFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        if (vm.tableParams){
          return vm.tableParams.reload();
        }

        vm.tableParams = new NgTableParams({
          page: 1,
          count: 25,
          sorting: {
            createdOn: 'desc'
          },
          filter: {
            brokerageId: '',
            status: ''
          }
        }, {
          getData: function (params) {
              var page = params.page();
              var size = params.count();
              var sorting = params.sorting();
              var filter = params.filter();
              var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
              var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
                
              var query = {
                page: page,
                pageSize: size,
                sort: sort,
                sortDirection: direction
              };
  
              _.extend(query, filter);

              if (query.status === '' && vm.statusFilterTouched){
                query.status = 'pending,closed,inactive,rejected';
              } else if (query.status !== ''){
                vm.statusFilterTouched = true;
              }

              // don't make the initial request
              // wait for the status filter to be changed before loading anything
              if (!vm.statusFilterTouched) return false;
  
              vm.isLoading = true;
              return SmartXFactory.getAccountsWithMetadata(query)
                      .then(function(res) {
                        params.total(res.metaData.totalRecordCount);
                        var brokersList = res.metaData.brokerages;

                        // update the list on the initial load and store the initial list
                        if (vm.accountBrokerageFirms.length === 1) {
                          updateBrokerList(brokersList);
                          initialBrokers = brokersList;
                        } else {
                          if (isOnlyFilter('brokerageId') || filter.brokerageId == ''){
                            updateBrokerList(initialBrokers);
                          }

                          if (isOnlyFilter('status')){
                            updateBrokerList(brokersList);
                          }
                        }
  
                        // if ($scope.accountEnterprises.length === 1) {
                        //   var enterprisesList = res.metaData.enterprises;
                        //   updateAccountEnterprises(enterprisesList);
                        // }
      
                        return res.accounts;
                      })
                      .catch(function(err) {
                        // console.error(err);
                        // toastr.error('Error loading accounts');
                        Dashboard.toastError("Failed to load non-active accounts", err);
                        $q.reject();
                      })
                      .finally(function(){
                        vm.isLoading = false;
                      });
          },
        });

      }else{
        vm.loading = false;
        vm.accountsSummary = vm.mappedAccounts = [];
      }

    }

    vm.init();
  });


