angular.module('Directives')
.directive("colorText",function(){
  return{
    scope: {
      index : '=',
      months: '='
    },
    template: '<span>{{ monthlyReturn }}</span>',
    link:function(scope,element){
      
      scope.$watch('months', function(){
        scope.monthlyReturn = scope.getReturn(scope.index, scope.months);
        var percentReturn = scope.monthlyReturn.slice(0, -1);
        var returnPresent = !isNaN( percentReturn );
        if ( returnPresent ){

          
          if (percentReturn < 0) {

            element[0].style.color = '#f26203';
            
          } else {
            element[0].style.color = '#000';
          }
        }
      });
    },
    controller : function($scope){
      $scope.getReturn = function(i, months){
    // add a leading 0 for numbers less than 10

        i = i < 10 ? '0' + i : i;
        if (months !== null){
          if (angular.isDefined(months[i])){
            return Number(months[i]).toFixed(2) + '%';
          } else {
            return '-';
          }
        } else {
          return '-';
        }
          
      };  
    }    
  };      
});