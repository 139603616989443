angular.module('TargetWeightGroups')
  .directive('showAdvisorTargets', ['$templateCache', function($templateCache) {
    return {
      restrict: 'E',
      scope: {
        models: '='
      },
      template: $templateCache.get('showAdvisorTargets.tpl.html'),
      link: link,
      controller: showAdvisorTargetsCtrl,
      controllerAs: 'vm'
    };

    function link(scope, elem, attr) {
      // console.log(scope);
      // console.log(elem);
      // console.log(attr);
    }


    function showAdvisorTargetsCtrl(Amplitude, ngTableEventsChannel, EnterpriseFactory, $timeout, $scope, $rootScope, $log, Dashboard, TargetWeightFactory, NgTableParams, Tabs, $filter, $templateCache, toastr, $sce, SweetAlert, $uibModal, $modal) {
      var vm = this;
      var user = Dashboard.getReplicatorUser();
      var initialDropdownFilterOptions = {
        createdBy: [],
        enterprise: []
      };

      console.log("User: ", user);

      vm.targets = [];
      vm.isFiltersVisible = true;
      vm.statuses = ['Active', 'Inactive', 'Pending'];
      vm.tableParams = new NgTableParams({
        count: 25,
        sorting: {
          name: "asc"
        }
      }, {
        filterOptions: { 
          filterFn: subscribedAccountsFilter,
          filterComparator: applyComparer
        },
        debugId: 'targetTable',
        dataset: []
      });
      vm.errorMsg;
      vm.retryCount = 0;


      vm.accessAccounts = Dashboard.getAccessAccounts();
      vm.accessAccountNames = Object.keys(vm.accessAccounts);


      vm.add = add;
      vm.edit = edit;
      vm.close = close;
      vm.toggleFilters = toggleFilters;
      vm.filterTargetType = filterTargetType;
      vm.cloneTarget = cloneTarget;
      vm.refresh = refresh;
      vm.openSubscribedAccountsModal = openSubscribedAccountsModal;
      vm.hideViewOnlyTargets = false;
      vm.filterTargetList = filterTargetList;

      vm.targetNameFilter = {
        'name': {
          id: "text",
          placeholder: "Enter Name",
        }
      };

      vm.createdByFilter = {
        'createdBy': {
          id: 'select',
          placeholder: 'Select User'
        }
      };
  
      vm.createdByFilterOptions = [
        {
          id: '',
          title: 'All Users'
        }
      ];

      vm.enterpriseFilter = {
        'enterprise.name': {
          id: 'select',
          placeholder: 'Select Enterprise'
        }
      };
  
      vm.enterpriseFilterOptions = [
        {
          id: '',
          title: 'All Enterprises'
        }
      ];

      vm.subscribedAccountsFilterDef = {
        'subscribedAccounts': {
          id: 'text',
          placeholder: 'Acct #'
        }
      };

      $scope.$watch('vm.tableParams', function(newVal){
        if (newVal) createListener(newVal);
      });

      function createListener (tableInstance) {
        ngTableEventsChannel.onAfterReloadData(function(tableParams, filteredData){

          var dataset = tableParams.settings().dataset;
          if (dataset && dataset.length) {
            var filters = tableParams.filter();
            var filterValues = _.values(filters).filter(function(filterValue) {
              return !_.isEmpty(filterValue) && filterValue !== null;
            });
  
            if (filterValues.length) {
              if (filters['enterprise.name']) {
                var enterpriseUsers = _.uniq(dataset.filter(function(target){
                  return target.enterprise.name === filters['enterprise.name'];
                }).map(function(target){
                  return target.createdBy;
                }));
  
                vm.createdByFilterOptions.splice(1);
                enterpriseUsers.forEach(function(user){
                  vm.createdByFilterOptions.push({
                    id: user,
                    title: user
                  });
                });
  
                if (filters.createdBy && !_.contains(enterpriseUsers, filters.createdBy)) {
                  tableParams.filter(_.extend(filters, {'createdBy': '' }));
                  tableParams.reload();
                }
  
                console.log("Updated enterprise user list: ", vm.createdByFilterOptions);
              } else {
                setDropdownFilterOptions(dataset);
              }
  
              if (filters.createdBy){
                // vm.enterpriseFilterOptions.splice(1)
                var enterpriseNames = _.uniq(
                  dataset.filter(function(target){
                    return target.createdBy === filters.createdBy;
                  })
                  .map(function(target){
                    return target.enterprise.name;
                  })
                );
  
                vm.enterpriseFilterOptions.splice(1);
                enterpriseNames.sort().forEach(function(name){
                  vm.enterpriseFilterOptions.push({
                    id: name,
                    title: name
                  });
                });
  
                // if (filters['enterprise.name'] !== userEnterprise.enterprise.name){
                //   tableParams.filter(_.extend(filters, {'enterprise.name': userEnterprise.enterprise.name }));
                //   tableParams.reload()
                // }
              }
            } else {
              if (angular.isDefined(dataset[0].createdBy)) setDropdownFilterOptions(dataset);
            }
          }
        }, $scope, tableInstance);
      }

      function filterData(data, filters){
        if (!_.isEmpty(filters) && data.length){
          var filteredData = $filter('filter')(data, filters);
          $scope.filteredData = filteredData;
          return filteredData;
        }
  
        return [];
      }

      function subscribedAccountsFilter (data, filterValues) {

        var filters = angular.copy(vm.tableParams.filter());
        if (angular.isDefined(filters['enterprise.name'])) {
          var enterpriseName = filters['enterprise.name'];
          filters = _.extend(filters, {enterprise: {name: enterpriseName }});
          delete filters['enterprise.name'];
          delete filters['subscribedAccounts'];
        }

        // override the default filtering behavior and use an exact equals comparison for createdBy filter
        if (!_.isEmpty(filters.createdBy)){
          data = $filter('filter')(data, {createdBy: filters.createdBy}, true); // true is shorthand to invoke the equals comparison
          delete filters.createdBy;
        }

        data = $filter('filter')(data, filters);

        // override the default filtering behavior and use an exact equals comparison for enterprise name filter
        if (filters.enterprise && !_.isEmpty(filters.enterprise.name)){
          data = $filter('filter')(data, {enterprise: filters.enterprise}, true); // true is shorthand to invoke the equals comparison
          delete filters['enterprise.name'];
        }

        if (filterValues.subscribedAccounts && filterValues.subscribedAccounts.trim().length) {
          return data.filter(function(target){
            if (target.subscribedAccounts) return _.find(target.subscribedAccounts, function(account) { 
              return account.brokerageAccountNumber.substring(0, filterValues.subscribedAccounts.length) === filterValues.subscribedAccounts;
            });
            else return false;
          });
        } else return data;
      }

      function applyComparer(actual, expected){
        debugger;
        return angular.equals(actual, expected);
      }



      // default view
      vm.radioModel = 'Top Level';


      var updateTargetsListener = $rootScope.$on('update-targets', function(event, args) {});

      // updates the advisor targets list without making an api call
      var updateAdvisorTargetsListener = $rootScope.$on('updateAdvisorTargets', function(event, advisorTargets){
        //vm.allTargets = advisorTargets;
        setupTargetTable(advisorTargets, []);
        TargetWeightFactory.setAdvisorTargets(advisorTargets, false); // prevent the broadcast
      });


      var accountAccessSetListener = $rootScope.$on('dashboard-account-access-set', function(event, args) {

        vm.accessGroups = args.groups;
        vm.accessAccounts = args.accounts;
        console.log("access groups: ", vm.accessGroups);
        console.log("access accounts: ", vm.accessAccounts);
        vm.accessAccountNames = Object.keys(vm.accessAccounts);
      });

      // clean up
      $scope.$on('$destroy', function(){
        updateTargetsListener();
        accountAccessSetListener();
        updateAdvisorTargetsListener();
      });

      init();

      function filterTargetList () {
        
        var dataset = vm.tableParams.settings().dataset;
        var filters = vm.tableParams.filter();
        var accessFilter = {
          accessLevel: vm.hideViewOnlyTargets ? 'fullAccess' : ''
        };
        var filters = _.extend(filters, accessFilter);
        if (!_.isEmpty(filters) && dataset.length){
          var filteredData = $filter('filter')(dataset, filters);
          $scope.filteredData = filteredData;
        }
      }

      function setDropdownFilterOptions (targets) {
        vm.createdByFilterOptions.splice(1);
        vm.enterpriseFilterOptions.splice(1);

        var targetsSortedByEnterprise = _.sortBy(targets, function(target){
          return target.enterprise ? target.enterprise.name : null;
        });
        
        targetsSortedByEnterprise.forEach(function(result){
          var createdBy = result.createdBy;
          var enterprise = result.enterprise;
          var _index = _.findIndex(vm.createdByFilterOptions, {'title': createdBy});
          if (_index < 0) vm.createdByFilterOptions.push({id: createdBy, title: createdBy});
          if (enterprise) {
            var enterpriseIndex = _.findIndex(vm.enterpriseFilterOptions, {'title': enterprise.name});
            if (enterpriseIndex < 0) vm.enterpriseFilterOptions.push({id: enterprise.name, title: enterprise.name || ''});
          }
        });
      }

      // $scope.$watch(checkForData, function(newVal, oldVal){
      //   // mash data together when both have arrived
      //   if (newVal){
      //     mashData()
      //   }
      // })

      function mashData () {
        vm.allTargets = vm.allTargets.map(function(target){
          var accounts = _.find(vm.targetsAccounts, {id: target.id });
          if (accounts) {
            target.subscribedAccounts = accounts.subscribedAccounts;
            target.subscribedAccountsCount = accounts.subscribedAccountsCount;
          } 
          return target;
        });

        if (vm.tableParams && vm.tableParams.settings() && vm.tableParams.settings().dataset ) {
          vm.tableParams.settings().dataset = vm.allTargets;
          vm.tableParams.reload();
        }
      }

      function checkForData () {
        return angular.isDefined(vm.targetsAccounts) && angular.isDefined(vm.allTargets);
      }

      function init(refreshing) {

        console.log(vm.targets);
        vm.errorMsg = undefined;
        vm.loading = true;

        // get a fresh list when they refresh
        // otherwise keep serving the cached list that was loaded initially
        var useCache = refreshing ? false : true;

        // vm.multiEnterprise = EnterpriseFactory.isMultiEnterpriseUser()
        // vm.loadingAccounts = true;
        // TargetWeightFactory.getTargetsAccounts()
        // .then(function(res){
        //   console.log("targets accounts response ", res.data.data);
        //   vm.targetsAccounts = res.data.data;

        //   if (checkForData()) {
        //     mashData();
        //   }
        // })
        // .catch(function(err){
        //   // toastr.error(err.message);
        //   // console.error(err);
        //   Dashboard.toastError(err.message, err);
        // })
        // .finally(function(){
        //   $timeout(function(){
        //     vm.loadingAccounts = false;
        //   });
        // });

        TargetWeightFactory.getTargets(null, useCache)
          .then(function(response) {

            // if (response.data.statusCode != 200) {
            //   dataFailure(response.data);
            //   return false;
            // }

            var targets = vm.allTargets = response.data != [] ? response.data.data : response.data;
            var accounts = [];

            // build out the created by user select filter options
            // targets.forEach(function(result){
            //   var createdBy = result.createdBy
            //   var enterprise = result.enterprise
            //   var _index = _.findIndex(vm.createdByFilterOptions, {'title': createdBy})
            //   if (_index < 0) vm.createdByFilterOptions.push({id: createdBy, title: createdBy})
            //   if (enterprise) {
            //     var enterpriseIndex = _.findIndex(vm.enterpriseFilterOptions, {'title': enterprise.name})
            //     if (enterpriseIndex < 0) vm.enterpriseFilterOptions.push({id: enterprise.name, title: enterprise.name || ''})
            //   }
            // })

            setDropdownFilterOptions(targets);

            initialDropdownFilterOptions = {
              createdBy: angular.copy(vm.createdByFilterOptions),
              enterprise: angular.copy(vm.enterpriseFilterOptions)
            };

            vm.multiEnterprise = vm.enterpriseFilterOptions.length > 2;

            // Save targets to service
            TargetWeightFactory.setAdvisorTargets(targets);
            //TargetWeightFactory.setUserDefaults(userDefaults);

            if (checkForData()) mashData();
            else setupTargetTable(targets, accounts || []);

            if (targets.length == 0) {
              vm.errorMsg = 'No targets found for this account. Please create your first target.';
            }
          })
          .catch(function(err){
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            $timeout(function(){
              vm.loading = false;
            });
          });

      }

      function refresh(){
        init(true);
      }

      function openDeleteConfirmationModal(){
        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'subscribedAccountsModal.html',
          resolve: {
            accounts: function() {
              return target.subscribedAccounts;
            },
            accessAccounts: function() {
              return vm.accessAccounts;
            },
            target: function(){
              return target;
            }
          },
          controller: function(accounts, target, accessAccounts, $uibModalInstance, Tabs, SmartXFactory, Dashboard) {
            var vm = this;
          },
          controllerAs: 'vm'
        });
      }

      function openSubscribedAccountsModal(target){
        var modalInstance = $modal.open({

          animation: true,
          templateUrl: 'subscribedAccountsModal.html',
          resolve: {
            accounts: function() {
              return target.subscribedAccounts;
            },
            accessAccounts: function() {
              return vm.accessAccounts;
            },
            target: function(){
              return target;
            }
          },
          controller: function(accounts, target, accessAccounts, $uibModalInstance, Tabs, SmartXFactory, Dashboard, toastr, $q) {
            var vm = this;
            var accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();

            vm.target = target;
            vm.accounts = null; 

            init();

            function init() {
              vm.loadingAccounts = true;

              $q.when(TargetWeightFactory.getAccountsByTarget(target.id))
              .then(function(res){
                vm.accounts = res.data.subscribedAccounts;
              })
              .catch(function(err){
                Dashboard.toastError(err, err.message);
              })
              .finally(function(){
                vm.loadingAccounts = false;
              });
            }

            vm.close = function(){
              $uibModalInstance.dismiss('cancel');
            };

            vm.exportToCsv = function(){
              var rows = vm.accounts.map(function(account){
                return {
                  "Custodian": _.property(['brokerage', 'name'])(account) || 'Unknown',
                  "Account Number": account.brokerageAccountNumber,
                  "Account Type": account.accountType,
                  "Account Name": account.name,
                  "Target": target.name
                };
              });

              var filename = target.name.replace(/ /g, '_') + "_Assigned_Accounts.csv";

              console.log(filename);
              Dashboard.JSONtoCSV(rows, filename);
            };

            vm.addTab = function(account){

              if (account.id === '00000000-0000-0000-0000-000000000000') {
                toastr.error('Account not found.');
                return;
              }

              account.fetchingDetails = true;
              accountAPI.getAccountById(account.id)
              .then(function(res){
                Tabs.addTab('main', account.id, null, res.data);
                vm.close();
              })
              .catch(function(err){
                // toastr.error(err.message);
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                $timeout(function(){
                  account.fetchingDetails = false;
                });
              });
            };
          },
          controllerAs: 'vm'
        });
      }

      function setupTargetTable(targets, accounts) {

        var temp = [];
        for (var i = 0; i < targets.length; i++) {
          var target = targets[i];
          // if (target.subscribedCount > 0) {
          //   var str = '<u><b>Subscribed Targets</b></u><br/>';
          //   for (var j = 1; j <= target.subscribedCount; j++) {
          //     str += target.subscribers[j - 1].name;
          //     if (j != target.subscribers.length) {
          //       str += '<br/>';
          //     }
          //   }
          //   target.subscribedTargetsTooltip = $sce.trustAsHtml(str);
          // }

          //if( (vm.radioModel == 'Top Level') && !target.subscribedToTargetsCount ){
          temp.push(target);
          // } else if (vm.radioModel != 'Top Level'){
          //   temp.push(target);
          // }
        }

        var currentFilters = vm.tableParams.filter();

        vm.targets = temp;
        vm.accounts = accounts;
        vm.tableParams = new NgTableParams({
          count: 25,
          filter: {
            'createdBy': currentFilters.createdBy ? currentFilters.createdBy : '',
            'enterprise.name': currentFilters['enterprise.name'] ? currentFilters['enterprise.name'] : ''
          },
          sorting: {
            name: "asc",
            symbol: "asc"
          }
        }, {
          filterOptions: { 
            filterFn: subscribedAccountsFilter,
            filterComparator: applyComparer
          },
          dataset: vm.targets
        });
        var paginationOpts = (vm.targets.length > 25) ? [25, 50, 100]  : [];
        vm.tableParams.settings({ counts: paginationOpts});
      }

      function dataFailure(data) {
        console.log(data);
        var msg = [];

        if(typeof data.msg != 'undefined'){
          for (var i = 0; i < data.msg.length; i++) {
            msg.push(data.msg[i].description);
          }
          vm.errorMsg = msg.join('. ');
        }else {
          vm.errorMsg = 'Error getting data from middleware.';
        }

        vm.retryCount++;
        if(vm.retryCount < 3){ debugger; init(); }
      }


      vm.setAccount = function(account, guid) {
        vm.selectedAccount = account;
        vm.selectedAccount.guid = guid;

      };

      vm.changeAccount = function(refreshing) {

        if (typeof vm.selectedAccount == 'undefined') {
          return false;
        }
      };


      vm.addTab = function(location) {
        Tabs.addTab(location);
      };

      vm.addNewTarget = function() {
        TargetWeightFactory.setActiveTarget(null, true);
        TargetWeightFactory.resetBreadcrumbs();
        Tabs.addTab('targetEdit', null, null, {type:'new'});

        Amplitude.logEvent('Targest home:Add new target');
      };

      vm.editTarget = function(target, trigger) {

        delete target.details;
        TargetWeightFactory.setActiveTarget(target);
        TargetWeightFactory.resetBreadcrumbs();
        Tabs.addTab('targetEdit', null, null, {type:'existing'});

        switch (trigger) {
          case 'name':
            Amplitude.logEvent('Targets Home:Target name clicked');
            break;
          case 'icon':
            Amplitude.logEvent('Targets Home:Edit icon clicked');
            break;
        }
      };

      vm.deleteTarget = function(target) {

        Amplitude.logEvent('Targets home:Delete icon clicked');

        if (target.subscribedAccountsCount) return alert('Targets with subscribed accounts cannot be deleted');
 

        if (target.subscribedToTargetsCount) {
          SweetAlert.swal({
            title: "Error",
            text: 'This target is assigned as a sub target. Please unassign it before removing it.',
            type: "warning"
          });
          return;
        }

        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'confirmDeleteModal.html',
          controller: function($scope, $uibModalInstance){
            $scope.ok = function () {
              $uibModalInstance.close();
            };
          
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });
    
        modalInstance.result.then(function () {

        

          target.deleting = true;
          TargetWeightFactory.deleteTargetWeight(target.id)
          .then(function(res) {
            console.log("Target deleted response: ", res);
            toastr.success("Target Deleted", "Success");
            target.deleted = true;
            target.deleting = false;

            angular.copy(vm.targets).forEach(function(_target, i){
              if (_target.id == target.id){
                vm.targets.splice(i, 1);
              }
            });

            vm.allTargets = vm.allTargets.filter(function(_target){
              return !_target.deleted;
            });

            // refresh the cached targets list
            TargetWeightFactory.setAdvisorTargets(vm.allTargets, false);

            vm.tableParams.settings().dataset = vm.allTargets;

            // filterTargetType();
            vm.tableParams.reload();            
          })
          .catch(function(err){
            // toastr.error(err.message, "Error");
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            target.deleting = false;
          });
        }, function () {
          console.log('Modal dismissed at: ' + new Date());
        });
      };

      vm.assignToTarget = function(target) {

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('assignTargetToAccountModal.tpl.html'),
          resolve: {
            target: function() {
              return target;
            },
            accounts: function() {
              return vm.accounts;
            }
          },
          controller: 'AssignTargetToAccountModalCtrl',
          controllerAs: 'vm'
        });


        modalInstance.result.then(function(payload) {

          var accountsToSubscribeToTarget = [];
          console.log(payload);

          for (var i = 0; i < payload.length; i++) {
            var acctObj = payload[i];
            var singleAcct = vm.accounts.filter(function(elem, i, array) {
              return elem.id == acctObj.id;
            });
            accountsToSubscribeToTarget.push(singleAcct[0]);
          }
          console.log(accountsToSubscribeToTarget);

          TargetWeightFactory.assign(target.id, accountsToSubscribeToTarget)
            .then(function(response) {

              if (typeof response.data.payload == 'undefined') {
                return false;
              }
              toastr.success(response.data.payload.results["0"].message, "Success");
              vm.loading = false;

              init();
            });


        });


      };


      function cloneTarget(target) {
        delete target.details;
        var newTarget = angular.copy(target);
        newTarget.isClone = true;
        // delete newTarget.id;
        TargetWeightFactory.setActiveTarget(newTarget);
        TargetWeightFactory.resetBreadcrumbs();
        Tabs.addTab('targetEdit', null, null, {type: 'existing'});

        Amplitude.logEvent('Targest Home:Clone icon clicked');
      }

      function filterTargetType() {
        console.log( vm.radioModel );
        setupTargetTable(vm.allTargets, vm.accounts);
      }


      function add() {

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('targetWeightModal.tpl.html'),
          resolve: {
            exclusion: function() {
              return null;
            }
          },
          controller: 'TargetWeightModalCtrl',
          controllerAs: 'vm'
        });
      }


      function save() {
        console.log("Save Target");
      }

      function edit(target, guid) {
        console.log("Edit Target");
        console.log(target);

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('targetWeightModal.tpl.html'),
          resolve: {
            exclusion: function() {
              return target;
            }
          },
          controller: 'TargetWeightModalCtrl',
          controllerAs: 'vm'
        });
      }

      function close(target) {
        console.log("Close target");
        console.log(target);
      }

      function toggleFilters() {
        vm.isFiltersVisible = !vm.isFiltersVisible;
      }

      function subscribedTargetsTooltip(target) {
        console.log(target);
      }
    }
  }]);
