var Holdings = angular.module('HoldingsCtrl', []);
Holdings.controller("holdings", function($window, $scope, $timeout, $sce, Dashboard, Tabs, $q, Holdings, $http, toastr, $filter, SmartXFactory, DRUPAL_API_URL) {

    var parentTab = $scope.tab;
    var tabType = $scope.tab.type;
    var treeHeight = 400;

    function init() {

      console.log("initializing holdings ctrl...");

      var columnDefs = [{
          headerName: 'Symbol',
          field: 'symbol.ticker',
          cellRenderer: addExternalLink,
          width: 100,
          sortingOrder: ['asc','desc']
        },
        {
          headerName: 'Company',
          field: 'symbol.companyName',
          sortingOrder: ['asc','desc']
        },
        {
          headerName: 'Quantity',
          field: 'quantity',
          sortingOrder: ['asc','desc']
        },
        {
          headerName: 'Value',
          field: 'value',
          cellRenderer: convertToCurrency,
          sort: 'desc',
          sortingOrder: ['asc','desc']
        },
        {
          headerName: 'Sector',
          field: 'symbol.industry',
          cellRenderer: convertNullToUnknown,
          sortingOrder: ['asc','desc']
        }
      ];

      if (parentTab.active) {
        $scope.visited = true;
      } else {
        $scope.visited = false;
      }

      $scope.downloadPDF = downloadPDF;
      $scope.downloadCSV = downloadCSV;

      // getAccountsFromFactory()
      // .then(function (result) {
      //   console.log('getAccountsFromFactory', result);
      // });


      //$scope.chartLoading = false;
      $scope.isPaperAccount = false;
      $scope.treeVisible = false;
      $scope.hasHoldings = false;
      $scope.loadingHoldings = false;
      $scope.settings = {
        tableRowHeight: 40,
        tableHeaderHeight: 30,
        gridHeight: 550
      };
      $scope.gridOptions = {
        columnDefs: columnDefs,
        enableSorting: true,
        headerHeight: $scope.settings.tableHeaderHeight,
        rowHeight: $scope.settings.tableRowHeight
      };

      $scope.wrapHeight = treeHeight;
      $scope.treeLevel = 0;
      $scope.getData = getData;

      var updateSelectedAccountCallback = {
        name: 'updateSelectedAccount',
        fn: updateSelectedAccount
      };

      // use to set the account in holdings tab based on the active account from the last dashboard
      Dashboard.registerObserverCallback(updateSelectedAccountCallback);

      getData();
    }

    init();

    /* methods called from template */
    function getData() {

      $scope.errorLoadingAccount = false;
      $scope.loadingHoldings = true;
      $scope.loading = true;

      // load the last selected account using the selected property of the current tab object
      // or default to the first account in the accounts array
      if ($scope.$parent.tab.selectedAccount) {
        $scope.selectedAccount = $scope.$parent.tab.selectedAccount;
      } else if (angular.isDefined(Dashboard.getActiveAccount())) {
        $scope.selectedAccount = Dashboard.getActiveAccount();
      } else {
        // $scope.selectedAccount = Dashboard.getActiveAccount() || $scope.accounts[$scope.accountIds[0]];

        // default to first account in our list is none have been selected
        // $scope.selectedAccount = Dashboard.getAccessAccounts()[0]
        // toastr.error("No active account has been set.");
      }

      if (typeof $scope.selectedAccount != 'undefined') {

        Holdings.selectedAccount = $scope.selectedAccount;
        $scope.accountName = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name, 'name');
        getHoldings();

      } else {
        $scope.loading = false;
        // $scope.errorLoadingAccount = true;
        $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">Search for an account to continue.</h3></div>';
      }
    }

    function downloadCSV(){
      var accountName = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name || $scope.selectedAccount.information.name, 'name');
      var filename = accountName.replace(/ /g, '_') + "_holdings_" + moment().format("MM_DD_YYYY");
      var rows = $scope.filteredHoldings ? $scope.filteredHoldings : $scope.holdings;

      rows = rows.map(function(holding){
        var formattedHolding = {};
        formattedHolding.symbol = holding.symbol.ticker;
        formattedHolding.name = holding.symbol.companyName;
        formattedHolding.industry = holding.symbol.industry;
        formattedHolding.quantity = holding.quantity;
        formattedHolding.value = holding.value;
        
        return formattedHolding;
      });

      Dashboard.JSONtoCSV(rows, filename + '.csv');
    }

    function downloadPDF(){
      var formattedHoldings = angular.copy($scope.holdings).map(function(holding){
        var formattedHolding = {};
        formattedHolding.symbol = holding.symbol.ticker;
        formattedHolding.name = holding.symbol.companyName;
        formattedHolding.industry = holding.symbol.industry;
        formattedHolding.value = holding.value;
        formattedHolding.quantity = holding.quantity;
        
        return formattedHolding;
      });
      var holdingsPdfData = {
        templateName: 'holdings-report.twig',
          templateHeader: 'blend-report-header.twig',
          templateFooter: 'blend-report-footer.twig',
          logoPath: (jQuery('#smartx-pdf-logo').length >= 1) ? jQuery('#smartx-pdf-logo').attr('src') : jQuery('#logo').attr('src'),
          holdings: _.sortBy(formattedHoldings, function(holding) { return -Math.abs(holding.value); }),
          account: {
            name: $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name || $scope.selectedAccount.information.name, 'name'),
            number: $scope.selectedAccount.brokerageAccountNumber || $scope.selectedAccount.information.brokerAccount || $scope.selectedAccount.information.brokerAccountNumber,
            brokerage: _.property(['custodian', 'name'])($scope.selectedAccount)
          },
          baseUrl: DRUPAL_API_URL
      };
      $scope.generatingReport = true;
      $http
      .post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, holdingsPdfData, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer'
      })
      .then(function(res) {

        $scope.generatingReport = false;

        var blob = new Blob([res.data], {
          type: "application/pdf"
        });

        var accountName = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name || $scope.selectedAccount.information.name, 'name');
        var filename = accountName.replace(/ /g, '_') + "_holdings_" + moment().format("MM_DD_YYYY");

        saveAs(blob, filename);
      })
      .catch(function(res) {
        $scope.generatingReport = false;
        $scope.errorGeneratingReport = true;
      });
    }


    function getAccountsFromFactory () {
      return new Promise(function(resolve, reject) {
        if(typeof SmartXFactory.getToken() !== 'undefined') {

          SmartXFactory.getAccounts('active')
          .then(function (resp) {
            $scope.accounts = resp;
            resolve(resp);
          }, function(err) {
            console.log(err, err.message);
            debugger;
            reject(Error(err.message));
          });

        }else{
          reject(Error('No Access Token'));
        }
      });

    }

    function getHoldings() {

      var guid = $scope.selectedAccount.id;

      var acctsAPI = SmartXFactory.getAccountAPI();
      $q.when(acctsAPI.getAccountHoldings(guid))
      .then(function (res) {
        console.log(res);

        if (res.statusCode !== 200) {

          toastr.error("Unable to get holdings for this account");
          $scope.loading = false;
          $scope.hasHoldings = false;
          $scope.loadingHoldings = false;
          $scope.errorLoadingAccount = true;
          return false;
        }

        $scope.holdings = res.data.filter(function (holding) {
          var assetType = holding.symbol ? holding.symbol.assetType.toLowerCase() : null;
          // var emptyGuid = holding.symbol && holding.symbol.id == '00000000-0000-0000-0000-000000000000';
          return /* !emptyGuid && */ (assetType == 'equity' || assetType == 'mutualfund' || assetType == 'bond');
        });

        if ($scope.holdings.length) {

          $timeout(function() {
            process($scope.holdings);
          });
        } else {
          $scope.loading = false;
          $scope.hasHoldings = false;
          $scope.loadingHoldings = false;
        }

      })
      .catch(function(err) {
        toastr.error(err.message);
        $scope.errorLoadingAccount = true;
      })
      .finally(function(){
        $scope.loading = false;
      });
    }

    $scope.drillup = function() {
      if ($scope.treeLevel > 0) {
        $scope.treeLevel--;
        if ($scope.treeLevel === 1) $scope.filterHoldings('byDirection');
        else if (!$scope.treeLevel) {
          $scope.gridOptions.api.setRowData($scope.holdings);
        }
      }
      $scope.tree.goUpAndDraw();
    };

    $scope.setAccount = function(account) {
      $scope.selectedAccount = account;
      $scope.accountName = $filter('sanitizeSmartXAccountName')(account.name, 'name');

      $scope.changeAccount();
    };

    $scope.addTab = function(type) {
      var tab = Tabs.addTab(type);
      if (tab) {
        tab.new = true;
        $scope.$parent.$broadcast('tabSwitched::holdings', tab);
      }
    };

    $scope.changeAccount = function(refreshing, origin) {

      var wrapHeight = jQuery('.chart-wrap').height();
      var wrapWidth = jQuery('.chart-wrap').width();

      // this will be true when clicking "view holdings" on an account tab
      // so that we can set the account accordingly
      if (origin) {
        $scope.selectedAccount = Dashboard.currentAccount;
        $scope.visited = true;
      }

      $scope.wrapWidth = wrapWidth;
      $scope.wrapHeight = wrapHeight;
      $scope.loading = true;
      $scope.treeVisible = false;
      $scope.loadingHoldings = true;
      $scope.errorLoadingAccount = false;
      $scope.treeLevel = 0;

      // update selected account property on tab so that it remembers the account after page refresh
      $scope.$parent.tab.selectedAccount = $scope.selectedAccount;

      getHoldings();
    };
    /* end methods called from template */

    $scope.updateTableHeight = function(data) {

      if (angular.isDefined(data)) {
        var count = Math.max(data.length, 10),
          height = (count * 55);

        $scope.settings.gridHeight = Math.min(height, $scope.settings.gridHeight);

        return height;

      }
    };

    $scope.filterHoldings = function(criteria) {

      if (criteria === 'byDirection') {
        $scope.filteredHoldings = $scope.holdings.filter(function(item) {

          if (item.industry === null) {
            item.industry = 'Unknown';
          }

          if ($scope.currentDirection === 'short') {
            if (item.value < 0) {
              return item;
            }
          } else if ($scope.currentDirection === 'long') {
            if (item.value >= 0) {
              return item;
            }
          }
        });
      }

      if (criteria === 'bySector') {

        $scope.filteredHoldings = $scope.filteredHoldings.filter(function(item) {
          if (item.symbol.industry === $scope.currentSector) {
            return item;
          } else if (item.symbol.industry === null) {
            item.industry = 'Unknown';
            return item;
          }
        });

      }
      $scope.updateTableHeight($scope.filteredHoldings);
      $scope.gridOptions.api.setRowData($scope.filteredHoldings);
    };

    $scope.selectHandler = function() {

      var selectedItem = $scope.tree.getSelection()[0];

      if (selectedItem) {
        var node = $scope.treeData.getValue(selectedItem.row, 0);
        var nodeTitleWordCount = node.split(' ').length;
        if (node === 'Long' || node === 'Short') {

          $scope.currentDirection = node.toLowerCase();
          $scope.filterHoldings('byDirection');
          $scope.treeLevel = 1;
        }
        if (node === '') { // starting state
          $scope.treeLevel = 0;
          $scope.gridOptions.api.setRowData($scope.holdings);

        } else if (nodeTitleWordCount > 1  && nodeTitleWordCount < 3 && $scope.treeLevel === 1) {
          $scope.treeLevel = 2;
          node = node.split(' '); // unk Long -> [ 'unk', 'Long']
          $scope.currentSector = node[0];
          $scope.currentDirection = node[1].toLowerCase();
          $scope.filterHoldings('bySector');

        } else if (nodeTitleWordCount > 2 && $scope.treeLevel === 1){ // handle multi word sector names
          $scope.treeLevel = 2;
          node = node.split(' '); // 'Basic Materials Long' -> [ 'Basic', 'Materials', 'Long']
          var sector = node.splice(0, node.length - 1).join(' '); // ['Basic', 'Materials', 'Long' ] -> 'Basic Materials'
          node.unshift(sector); // ['Long'] -> ['Basic Materials', 'Long']
          $scope.currentSector = node[0];
          $scope.currentDirection = node[1].toLowerCase();
          $scope.filterHoldings('bySector');
        } else if ($scope.treeLevel == 2){
          $scope.treeLevel = 3;
          $scope.drillup();
        }
      }

    };

    $scope.rollupHandler = function(row) {
      if ($scope.treeLevel === 2) {
        $scope.filterHoldings('byDirection');
        $scope.treeLevel = 1;
      } else if ($scope.treeLevel === 1) {
        $scope.gridOptions.api.setRowData($scope.holdings);
        $scope.treeLevel = 0;
      }

    };

    function resizeTree() {
      if ($scope.tree && $scope.$parent.tab.active) {

        if ($scope.redraw) {
          $timeout.cancel($scope.redraw);
        }
        $scope.redraw = $timeout(function() {

          cleanUpPreviousChart()
            .then(function() {

              // console.log("Tree Data: ", $scope.treeData);
              // console.log("Raw Holdings Data: ", $scope._holdings);
              // console.log("Sector Data: ", $scope.sectorData);

              $scope.tree.draw($scope.treeData, {
                minColor: '#97ccee',
                midColor: '#6291B7',
                maxColor: '#2c557f',
                headerHeight: 50,
                headerColor: 'rgb(246, 246, 246)',
                height: treeHeight,
                width: '100%',
                fontColor: 'black',
                highlightOnMouseOver: true,
                generateTooltip: showFullTooltip
              });

              $scope.treeLevel = 0;

              $scope.gridOptions.api.sizeColumnsToFit();

            });
        }, 100);

      }
    }

    function convertToCurrency(params) {
      if (params.value === null) {
        return 'N/A';
      } else {
        return $filter('currency')(params.value);
      }
    }

    function convertNullToUnknown(params) {
      if (params.value === null || params.value == 'unk') {
        return 'Unknown';
      } else {
        return params.value;
      }
    }

    function addExternalLink(params) {
      var assetType = _.property(['data', 'symbol', 'assetType'])(params);
      var url; 
      
      if (assetType === 'bond') return params.value;
      
      url = "http://finance.yahoo.com/quote/" + params.value;
      return '<a href="' + url + '" target="_blank" title="View this symbol on Yahoo! Finance">' + params.value + '</a>';
    }


    function process(holdings) {

      if (!holdings.length) {

        console.log("null holdings");
        $scope.holdings = {
          html: "<div>No holdings were found for this account. Please contact us if you believe this is an error.</div>"
        };

      } else {

        $scope.loadingHoldings = false;
        $scope.loadingChart = false;

        if ($scope.visited) {

          $timeout(function() {
            if (angular.isDefined($scope.tree)) {

              // prevent chart instances from piling up
              // without this, things will become gradually slower the more times the chart is rendered
              cleanUpPreviousChart()
                .then(function() {

                  buildTree(holdings);
                })
                .catch(function(err) {
                  if (err) {
                    throw new Error(err);
                  }
                });
            } else {

              buildTree(holdings);
            }

            $scope.hasHoldings = true;
          }, 100);
        }

      }
    }

    function cleanUpPreviousChart() {
      return $q(function(resolve, reject) {
        if ($scope.tree) {
          resolve($scope.tree.clearChart());
        } else {
          reject("error clearing chart");
        }
      });
    }

    function add(acc, obj) {
      return acc + obj.value;
    }

    function initGrid() {
      var gridWidth = jQuery('.holdings-rundown').width();
      $timeout(function() {
        $scope.gridOptions.api.setRowData($scope.holdings);
        $scope.gridOptions.columnApi.sizeColumnsToFit(gridWidth);

      }, 100);

    }

    function compareTitle(a, b) {
      if (a.accountInfo.title.trim() < b.accountInfo.title.trim())
        return -1;
      if (a.accountInfo.title.trim() > b.accountInfo.title.trim())
        return 1;
      return 0;
    }

    //http://stackoverflow.com/questions/12576798/angularjs-how-to-watch-service-variables
    function updateSelectedAccount(origin) {

      //console.log("pusher origin", origin);

      if (origin === 'main-holdings') {
        //console.log("updating account when coming from main view");
        $scope.selectedAccount = Dashboard.currentAccount;
        $scope.changeAccount(null, 'main');
      }

    }

    function showFullTooltip(row, size, value) {
      return '<div style="background:#FFF; color: #000; padding:10px; border-style:solid">' +
        // '<span ><b>Sector: ' + $scope.treeData.getValue(row, 0) +
        '<span>Label: ' + $scope.treeData.getFormattedValue(row, 0) + '</span><br/>' +
        '<span>Value: ' + $scope.treeData.getFormattedValue(row, 2) + '</span><br></div><br/>';

      //'$scope.treeDatatable row: ' + row + '<br>' +
    }

    function buildTree(holdings) {

      holdings = holdings || [];
      if (holdings.length) {

        var treeData = [],
          treeSeries,
          treeOptions,
          shortColor = "#eeeeee",
          longColor = "#80d9eb",
          sector,
          sectorValue,
          holding,
          holdings,
          ticker,
          sectorData,
          symbols,
          topLevel;

        var accountName = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name, 'name');
        var longValue = 0;
        var shortValue = 0;

        var longHoldings = _.filter(holdings, function(holding) {
          return (holding.marketValue) >= 0;
        });

        var shortHoldings = _.filter(holdings, function(holding) {
          return (holding.marketValue) < 0;
        });

        var totalValue = longValue + shortValue;

        var longIndustries = _.chain(longHoldings)
          .reduce(function(obj, holding) {
            holding.value = Number(holding.marketValue);
            longValue += (holding.marketValue);
            if (obj[holding.symbol.industry]) {
              obj[holding.symbol.industry] = obj[holding.symbol.industry] + (holding.marketValue);
            } else {
              if (holding.symbol.industry == null) {
                holding.symbol.industry = 'Unknown';
              }
              obj[holding.symbol.industry] = (holding.marketValue);
            }

            return obj;
          }, {})
          .pairs()
          .value();

        var shortIndustries = _.chain(shortHoldings)
          .reduce(function(obj, holding) {
            holding.value = Number(holding.marketValue);
            shortValue += (holding.marketValue);
            if (obj[holding.symbol.industry]) {
              obj[holding.symbol.industry] = obj[holding.symbol.industry] + (holding.marketValue);
            } else {
              if (holding.symbol.industry == null) {
                holding.symbol.industry = 'Unknown';
              }
              obj[holding.symbol.industry] = (holding.marketValue);
            }

            return obj;
          }, {})
          .pairs()
          .value();

        var totalValue = longValue + shortValue;
        var data = [
          ['Account Holdings', 'Parent', 'Holding Value'],
          [accountName, null, longValue + Math.abs(shortValue)],
          ['Long', accountName, longValue],
          ['Short', accountName, Math.abs(shortValue)]
        ];


        var holdingsData = {
          shortHoldings: shortHoldings,
          longHoldings: longHoldings,
          longValue: longValue,
          shortValue: shortValue,
          shortIndustries: shortIndustries,
          longIndustries: longIndustries
        };

        ['Long', 'Short'].forEach(function(direction, index) {
          holdingsData[direction.toLowerCase() + "Value"] = 0;

          if (holdingsData[direction.toLowerCase() + 'Industries'].length) {
            holdingsData[direction.toLowerCase() + 'Industries'].forEach(function(category) {

              var name = category[0]; // industry name
              var value = category[1]; // total value of industry holdings

              holdingsData[direction.toLowerCase() + "Value"] += value;
              var sectorData = {
                c: [{
                  v: name + ' ' + direction,
                  f: name
                }, {
                  v: direction
                }, {
                  v: Math.abs(value)
                }]
              };

              data.push(sectorData);

              var industryTickers = _.filter(holdingsData[direction.toLowerCase() + 'Holdings'], function(item){ 
                var industry = name;
                console.log("Getting tickers for industry...", industry);
                return item.symbol.industry == name;
              });
              console.log(industryTickers);

              industryTickers.forEach(function(holding) {

                // if (holding.symbol.ticker == 'AAPL') {
                //   debugger
                // }

                var tickerData = {
                  c: [{
                    v: holding.symbol.ticker + ' ' + direction,
                    f: holding.symbol.ticker,
                    'type': 'Symbol',
                    company: holding.symbol.companyName
                  }, {
                    v: name + ' ' + direction
                  }, {
                    v: Math.abs(holding.value), //pnl: pnl, pnlPercent: pnlPercentage
                  }]
                };

                data.push(tickerData);
              });
            });
          }
        });

        holdings = data;
        $scope._holdings = angular.copy(holdings);

        $timeout(function() {

          drawChart($scope._holdings, initGrid()); // initGrid is called after the chart is drawn

        }, 100);

      }
    }

    function drawChart(holdings, initGrid) {

      var formatter;
      $scope.treeData = google.visualization.arrayToDataTable(holdings);

      if (!angular.isDefined($scope.tree)) {
        $scope.tree = new google.visualization.TreeMap(document.getElementById('tree'));
        //https://developers.google.com/chart/interactive/docs/basic_interactivity
        google.visualization.events.addListener($scope.tree, 'select', $scope.selectHandler);
        google.visualization.events.addListener($scope.tree, 'rollup', $scope.rollupHandler);
      }

      formatter = new google.visualization.NumberFormat({
        prefix: '$',
        negativeColor: 'red',
        negativeParens: true
      });
      formatter.format($scope.treeData, 2); // Apply formatter to 3rd column (values)
      $scope.loading = false;

      $timeout(function() {

        // console.log("Tree Data: ", $scope.treeData);
        // console.log("Raw Holdings Data: ", $scope._holdings);
        // console.log("Sector Data: ", $scope.sectorData);

        if (initGrid) {
          initGrid();
        }

        try {

          $scope.tree.draw($scope.treeData, {
            highlightOnMouseOver: true,
            maxDepth: 1,
            maxPostDepth: 2,
            headerColor: 'rgb(246, 246, 246)',
            minColor: '#AFE0F6',
            midColor: '#95D5F2',
            maxColor: '#7ac9ed',
            headerHeight: 50,
            showScale: false,
            height: 400,
            useWeightedAverageForAggregation: true,
            generateTooltip: showFullTooltip
          });

          $scope.treeLevel = 0;

        } catch (e) {
          console.log('Error building tree: ', e);
        }

        // triggers the removal of the fadeIn animation class from the tree element
        // so that the tree doesn't fade in every time we switch to the holdings tab
        $timeout(function() {
          $scope.treeVisible = true;
        });

      }, 100);
    }

    /* events */

    $scope.$on('tabSwitched::' + tabType, function(event, tab) {

      if (tab.type === 'holdings') {
        //console.log(tab, parentTab);
        //console.log("lastDash, ", Tabs.getLastDashboard());
        //console.log('tab switched', tab);
        //$scope.selectedAccount = Dashboard.currentAccount;
      }
      if (tab === parentTab && !$scope.loading) {
        var gridWidth = jQuery('.holdings-wrap').width();
        if (typeof $scope.tree !== 'undefined' && $scope.treeLevel === 0) {

          $timeout(function() {
            drawChart($scope._holdings, initGrid);
          }, 100);


        } else if (typeof $scope.tree === 'undefined') {

          // timeout so we don't try building the chart before it knows
          // the size of its parent container
          $timeout(function() {
            $scope.gridOptions.api.setRowData($scope.holdings);
            $scope.updateTableHeight($scope.holdings);
            $scope.gridOptions.columnApi.sizeColumnsToFit(gridWidth);

            //jQuery(window).resize();
            buildTree($scope.holdings);

          }, 100);
        }

        $scope.visited = true;

      }
    });

    $scope.$on('$destroy', function() {
      //
      if (angular.isDefined($scope.tree)) {
        $scope.tree.clearChart();
        $scope.tree = $scope.treeMap = undefined;
      }

      var callbacks = Dashboard.getObserverCallbacks();

      // unregister any functions that were pushed into the observer callback array
      // otherwise the function is added to the array with every new tab instance
      // which leads to calling the registered function multiple times
      // could also check to see if the function is in the array before it is registered
      // that would prevent registering the same function multiple times
      callbacks.forEach(function(cb) {
        //console.log(cb);
        if (cb.name === 'updateSelectedAccount') {
          Dashboard.removeObserverCallback(cb);
        }
      });
    });

    angular.element($window).bind('resize', function() {
      //console.log('resizing');
      resizeTree();
    });


    /* end events */
  })
  .filter("sanitize", ['$sce', function($sce) {
    return function(htmlCode) {
      return $sce.trustAsHtml(htmlCode);
    };
  }])
  .filter('direction', function() {
    return function(item, direction) {
      //console.log(direction);
      if (direction === 'short') {
        if (item.position < 0) {
          return item;
        }
      } else if (direction === 'long') {
        if (item.position >= 0) {
          return item;
        }
      }
    };
  });