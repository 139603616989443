angular
  .module('ModelComparisonCtrl', [])
  .controller(
    'ModelComparisonCtrl',
    function (
      $scope,
      Tabs,
      Models,
      SearchFilters,
      $q,
      Amplitude,
      IdentityFactory,
      SmartXFactory,
      Dashboard
    ) {
      const activeTab = Tabs.getActiveTab()
      console.log('activeTab', activeTab)
      $scope.comparisonData = {
        comparisonData: activeTab.data.data,
      }
      $scope.comparisonid = activeTab.data.data.comparisonId

      var performanceApi = SmartXFactory.getPerformanceAPI();
      $scope.platformId = performanceApi.platform;

      //  https://smartx.atlassian.net/browse/AS-168
      IdentityFactory.getUser().then(function (res) {
        $scope.smartx_user_guid = res.profile.smartx_user_guid
        $scope.smartx_token = res.access_token
      })

      $scope.addModelTabForId = function (modelId) {
        setTimeout(function () {
          var type = 'model',
            tab = Tabs.addTab(type)

          if (tab) {
            $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab)
            $scope.$apply()
          }
        }, 1)
      }

      //  Create Event Listener for open model
      document
        .addEventListener('_AssetShopOpenModelInfo', function (event) {
          const modelList = Models.getModelList()
          const selectedModel = modelList.filter((x) => {
            return x.id == event.detail.id
          })
          Models.currentModel = selectedModel[0]

          $scope.addModelTabForId(selectedModel[0].id)
        })

      //  Create Event Listener for open portfolio builder
      document
      .addEventListener('_AssetShopBuildPortfolio', function (event) {
        console.log('build portfolio event', event.detail)
        if (event.detail.length == 0) {
          return
        }

          const modelList = Models.getModelList()
          const selectedModelIds = event.detail.map((x) => {
            console.log(x.id)
            return x.id
          })
          const selectedModels = modelList.filter((x) => {
            if (selectedModelIds.includes(x.id)) return true
            else return false
          })

          SearchFilters.selectedModelIDs = selectedModelIds
          SearchFilters.setSelectedRows(selectedModels)

          Amplitude.logEvent('Model Comparison Results:Build Portfolio')

          $q.when($scope.addTab('portfolio-builder')).then(function () {})

          $scope.$broadcast('table-cleared', {})
        })
      
        $scope.addTab = function (type) {
          var tab;
    
          tab = Tabs.addTab(type);
          Dashboard.notifyObservers('portfolio-builder');
    
          if (tab) {
            $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);
          }
        };
    }
  )
