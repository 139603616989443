angular.module('BuilderService', [])
.factory('Builder', ['$http', '$q','$log', 'DRUPAL_API_URL', function($http, $q, $log, DRUPAL_API_URL){

	var selectedModels = [];
	var leverageOptions = [
		{'label' : '1x', 'value' : 1},
		{'label' : '2x', 'value' : 2},
		{'label' : '3x', 'value' : 3},
		{'label' : '4x', 'value' : 4}
	];

	function mapAggregateMonths(months){

		// in case we don't start with 01 as the first key
		var months = angular.copy(months);
		var numMonths = Object.keys(months).length;
		var firstMonth = _.min(Object.keys(months));

		var values = [];
		var range = _.range(parseInt(firstMonth, 10),13);

		_.each(range, function(i){
			if (i < 10){
				i = "0" + i;
			}
			var month = months[i];

			values.push(month);
		});

		return values;

	}

	function getYTD(monthlyReturns) {

      // make sure the values are in the right order
      var values = _.sortBy(monthlyReturns, function(num, key) {
        return key;
      });

      var percentAsFloat = values.reduce(function(currentTotal, newValue) {
        if (angular.isDefined(newValue)) {
          return currentTotal * ((newValue / 100) + 1);
        } else {
          return currentTotal * 1;
        }
      }, 1);

      YTD = (percentAsFloat - 1) * 100;

      return YTD;
    }

	function generatePDF(data, filename){

		var deferred = $q.defer();
		
		$http.post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, data, { headers: { 'Content-Type': 'application/json'},responseType:'arraybuffer'})
	    .success(function(res){

	        var blob = new Blob([res.data], {type: "application/pdf"});
	        deferred.resolve(saveAs(blob, filename || 'blend_report.pdf'));

	    }).error(function(msg, code){

	        deferred.reject();
	        $log.error(msg, code);

	    });

	    return deferred.promise;
	}

	function calculateCompoundedReturn(values, parseMonths){

    	values = _.values(values);

    	var percentAsFloat  = values.reduce(function(currentTotal, newValue){
	                            if (angular.isDefined(newValue)){
	                              return currentTotal * ( (newValue / 100) + 1 );
	                            } else {
	                              return currentTotal * 1;
	                            }
	                          }, 1 );

	    YTD = (percentAsFloat - 1) * 100;

	    return YTD;

	}

	function calculateAverageAnnualReturn(returns){

		// filter out years without 12 months of returns
		returns = _.filter(returns, function(monthlyReturns, year){
		  return _.size(monthlyReturns) === 12;
		});

		// loop through each year and sum its months to find the total return for that year
		// then add all of the yearly totals to get the grand total
		var sumOfReturns = _.chain(returns)
	                    	.map(function(months){

								return calculateCompoundedReturn(months, true);

							})
	                     	.values()
	                     	.reduce(function(total, val){
	                       		return total + val;
	                     	}, 0)
	                     	.value();

	   var averageReturn = sumOfReturns / _.size(returns);
	   return averageReturn.toFixed(2);
	}

	var getPerformanceTableData = function(aggregate, benchmark){

		var performance,
			promise;

		performance = {
			"Annualized Return" : {
				"benchmark" : parseFloat(benchmark.cagr).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.cagr).toFixed(2) + '%',
			},
			"Standard Deviation" : {
				"benchmark" : parseFloat(benchmark.deviation.standard_monthly_ann).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.deviation.standard_monthly_ann).toFixed(2) + '%'
			},
			"Sharpe Ratio" : {
				"benchmark" : parseFloat(benchmark.ann_sharpe["0"]).toFixed(2),
				"aggregate" : parseFloat(aggregate.ann_sharpe["0"]).toFixed(2)
			},
			"Sortino Ratio" : {
				"benchmark" : parseFloat(benchmark.ann_sortino["0"]).toFixed(2),
				"aggregate" : parseFloat(aggregate.ann_sortino["0"]).toFixed(2)
			},
			"Avg. Return of Up Months" : {
				"benchmark" : parseFloat(benchmark.average_gain).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.average_gain).toFixed(2) + '%'
			},
			"Avg. Return of Down Months" : {
				"benchmark" : parseFloat(benchmark.average_loss).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.average_loss).toFixed(2) + '%'
			},
			"Lowest Monthly Return" : {
				"benchmark" : parseFloat(benchmark.lowest_return).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.lowest_return).toFixed(2) + '%'
			},
			"Highest Monthly Return" : {
				"benchmark" : parseFloat(benchmark.highest_return).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.highest_return).toFixed(2) + '%'
			},
			"Profitable Percentage" : {
				"benchmark" : parseFloat(benchmark.profitable_percentage).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.profitable_percentage).toFixed(2) + '%'
			},
			"Downside Deviation" : {
				"benchmark" : parseFloat(benchmark.deviation.downside["0"]).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.deviation.downside["0"]).toFixed(2) + '%'
			},
			"Correlation" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.r).toFixed(2) : '',
				"aggregate" : '--'
			},
			"R2" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.r2).toFixed(2) : '',
				"aggregate" : '--'
			},
			"Alpha" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.alpha).toFixed(2) : '',
				"aggregate" : '--'
			},
			"Beta" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.beta).toFixed(2) : '',
				"aggregate" : '--'
			}
		};

		promise = $q(function(resolve, reject){
			if (angular.isDefined(performance)){
				resolve(performance);
			} else {
				var msg = "There was a problem getting the performance data";
				reject(msg);
			}
		});

		return promise;
	};

	var loadBatch = function(){
		console.log(selectedModels);
		return selectedModels;
	};

	var saveBatch = function(batch){
		console.log(batch);
		selectedModels = batch;
	};

	var saveUserSchema = function(payload){

		console.log("Saving schema", payload);
		var req = {
			method: 'POST',
			url: `${DRUPAL_API_URL}/ng_api/save_user_schema/`,
			data:  jQuery.param(payload),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		};

		return $http(req);
	};

	var updateUserSchema = function(payload) {
		var req = {
			method: 'POST',
			url: `${DRUPAL_API_URL}/ng_api/update_user_schema/`,
			data:  jQuery.param(payload),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		};

		return $http(req);
	};

	var deleteUserSchema = function(payload){

		var req = {
			method: 'POST',
			url: `${DRUPAL_API_URL}/ng_api/delete_user_schema/`,
			data:  jQuery.param(payload),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		};

		return $http(req);
	};

	var benchmarks = [
		{
			"id": "BF6BE7BA-0CE1-40A9-8225-2354841D601C",
			"name": "S&P 500 TR",
			"symbolId": "DD9F21AD-F4D3-43D5-8F1F-4EA1EC3EEDA5",
			"isDeleted": 0,
			"createdOn": "2016-11-17 12:55:52.630",
			"createdBy": "HcvDbAdmin",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 30/70 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:05:27.987",
			"createdBy": "hedgecovest\\AWormus",
			"id": "159E3F02-62DE-310B-9D77-154E0DF5EA94",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 35/65 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 12:10:00.940",
			"createdBy": "hedgecovest\\AWormus",
			"id": "F8F6BB42-974D-38DA-BC71-7BC2AED82878",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 80/20 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:03:34.687",
			"createdBy": "hedgecovest\\AWormus",
			"id": "22DCCC3C-83DD-3E5F-8EB5-B29224E42C3F",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 50/50 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 12:10:00.917",
			"createdBy": "hedgecovest\\AWormus",
			"id": "40911342-F458-3DD0-9581-CF21DFA1E70D",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 20/80 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:03:34.683",
			"createdBy": "hedgecovest\\AWormus",
			"id": "318F43E8-AEC3-3635-828E-E3D989C43184",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 65/35 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:03:34.680",
			"createdBy": "hedgecovest\\AWormus",
			"id": "DD1B4395-A083-3EEA-9B28-E70844C4B641",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Balanced 70/30 GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 12:10:00.903",
			"createdBy": "hedgecovest\\AWormus",
			"id": "E7DE0D4D-A9CA-3E0B-852E-F747C2D63FF4",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar EM NR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:05:27.987",
			"createdBy": "hedgecovest\\AWormus",
			"id": "B1F8F6BE-0B75-315D-90F9-DC48F40CD930",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"name": "Morningstar Gbl Mkts NR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:05:27.987",
			"createdBy": "hedgecovest\\AWormus",
			"id": "DAD3D5C1-7D65-31E2-A103-B479E998C912",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},		
		{
			"name": "Morningstar Gbl Mkts xUS GR USD",
			"symbolId": "00000000-0000-0000-0000-000000000000",
			"isDeleted": 0,
			"createdOn": "2019-12-30 11:05:27.987",
			"createdBy": "hedgecovest\\AWormus",
			"id": "80F3F9F4-69F0-3A97-A475-19DCF6DBC89C",
			"updatedOn": "NULL",
			"updatedBy": "NULL"
		},
		{
			"id": "8EDD05B4-9AB7-3C89-B85D-138026EED6AA",
			"name": "Morningstar US Value TR USD"
		},
		{
			"id": "8A5C7811-6E5C-33DF-A312-2458841ED274",
			"name": "Morningstar US Growth TR USD"
		},
		{
			"id": "610AD88F-F8F8-3093-BBA5-8D07EA333D85",
			"name": "Morningstar US Large Cap TR USD"
		},
		{
			"id": "040AFC60-8860-3F77-ABD6-A6FD7C20B49C",
			"name": "Morningstar US Mid Cap TR USD"
		},
		{
			"id": "96F02713-13B5-31C9-80A0-F1BE83981639",
			"name": "Morningstar US Small Cap TR USD"
		},
		{
			"id": "777fdcd0-a625-3ec1-8d7d-5d43df032549",
			"name": "Morningstar US Core Bond Select TR USD"
		},
		{
			"id": "24ff90b8-92ea-3e33-bdf8-0eaad9db901e",
			"name": "Morningstar US Tsy & Gv Bd TR USD"
		},
		{
			"id": "8e7b23c1-25b1-3675-a178-6d0eac282360",
			"name": "Morningstar US Corp Bd TR USD"
		},
		{
			"id": "547f0ec4-0cab-3a47-b020-0d073f3a1f9e",
			"name": "Morningstar US HY Bd TR USD"
		},
		{
			"id": "db91bad4-87db-3c2f-b591-9c88286c7ce9",
			"name": "Morningstar Agg Tgt Risk TR USD"
		},
		{
			"id": "dd5b1918-41cb-3b2a-baa4-01081876b30e",
			"name": "Morningstar Mod Agg Tgt Risk TR USD"
		},
		{
			"id": "1f360f11-8f1a-3a54-b9f3-df9922f96c3f",
			"name": "Morningstar Mod Tgt Risk TR USD"
		},
		{
			"id": "7c372227-5c18-3e4b-8e33-88e511858784",
			"name": "Morningstar Mod Con Tgt Risk TR USD"
		},
		{
			"id":"9d536dd3-8e71-3ece-bec7-0404199e14a1",
			"name":"Morningstar Con Tgt Risk TR USD"
		}
	];




	return {
		updateUserSchema: updateUserSchema,
		leverageOptions : leverageOptions,
		saveUserSchema : saveUserSchema,
		deleteUserSchema : deleteUserSchema,
		loadBatch: loadBatch,
		saveBatch: saveBatch,
		getPerformanceTableData : getPerformanceTableData,
		calculateAverageAnnualReturn : calculateAverageAnnualReturn,
		generatePDF: generatePDF,
		getYTD: getYTD,
		benchmarks: benchmarks
	};

}]);