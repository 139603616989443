angular.module('ModelService', [])
.factory('Models', [ 'Dashboard', '$filter', '$http', 'SmartXFactory', 'DRUPAL_API_URL', function(Dashboard, $filter, $http, SmartXFactory, DRUPAL_API_URL){

	var platformModels;
	var currentModel;
	var currentViewpoint;
	var currentStats;
	var reportedModelList;
	var searchResponse;
	var modelMinimums = {};
	var flagsAPI = SmartXFactory.getFlagsAPI();

	function saveSearchResponse(result) {
		searchResponse = result;
	}

	function getCachedSearchResponse(){
		return searchResponse;
	}

	function getFlags(id) {
		return flagsAPI.get('models', {ids: id});
	}
	
	function formatStatistics(aggregate, benchmark){
		return {
			"Annualized Return" : {
				"benchmark" : parseFloat(benchmark.cagr).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.cagr).toFixed(2) + '%',
			},
			"Standard Deviation" : {
				"benchmark" : parseFloat(benchmark.deviation.standard_monthly_ann).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.deviation.standard_monthly_ann).toFixed(2) + '%'
			},
			"Sharpe Ratio" : {
				"benchmark" : parseFloat(benchmark.ann_sharpe["0"]).toFixed(2),
				"aggregate" : parseFloat(aggregate.ann_sharpe["0"]).toFixed(2)
			},
			"Sortino Ratio" : {
				"benchmark" : parseFloat(benchmark.ann_sortino["0"]).toFixed(2),
				"aggregate" : parseFloat(aggregate.ann_sortino["0"]).toFixed(2)
			},
			"Avg. Return of Up Months" : {
				"benchmark" : parseFloat(benchmark.average_gain).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.average_gain).toFixed(2) + '%'
			},
			"Avg. Return of Down Months" : {
				"benchmark" : parseFloat(benchmark.average_loss).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.average_loss).toFixed(2) + '%'
			},
			"Lowest Monthly Return" : {
				"benchmark" : parseFloat(benchmark.lowest_return).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.lowest_return).toFixed(2) + '%'
			},
			"Highest Monthly Return" : {
				"benchmark" : parseFloat(benchmark.highest_return).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.highest_return).toFixed(2) + '%'
			},
			"Profitable Percentage" : {
				"benchmark" : parseFloat(benchmark.profitable_percentage).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.profitable_percentage).toFixed(2) + '%'
			},
			"Downside Deviation" : {
				"benchmark" : parseFloat(benchmark.deviation.downside["0"]).toFixed(2) + '%',
				"aggregate" : parseFloat(aggregate.deviation.downside["0"]).toFixed(2) + '%'
			},
			"Correlation" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.r).toFixed(2) : '',
				"aggregate" : '--'
			},
			"R2" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.r2).toFixed(2) : '',
				"aggregate" : '--'
			},
			"Alpha" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.alpha).toFixed(2) : '',
				"aggregate" : '--'
			},
			"Beta" : {
				"benchmark" : aggregate.comparison ? parseFloat(aggregate.comparison.beta).toFixed(2) : '',
				"aggregate" : '--'
			}
		};
	}

	function formatCash(value){
		return '$'+$filter('number')(parseFloat(value), 2);
	}

	function formatPercent(value){
		return $filter('number')(parseFloat(value), 2)+'%';
	}

	// for a given model id, retrieve its fund profile from a list of models previously fetched from Drupal
	function getModelProfileById(modelId, models){

        var model = _.find(models, function(model){

            var id;

            if (model.field_smartx_model_guid){
                id = model.field_smartx_model_guid.und[0].value.toLowerCase();
            } else if (model.guid){
                id = model.guid;
            } /*else if (model.reportedModelId){
                id = model.reportedModelId
            } */
            else if (model.id){
                id = model.id;
            }

            if (modelId == id){
                return true;
            }
        });

        return model;
    }



	function getAvailableCash(){
		return ( typeof currentStats.availableCash != 'undefined' ) ? formatCash(currentStats.availableCash) : '--';
	}

	function getAcctVal(){
		if (currentStats.netLiquidationValue === null){
			currentStats.netLiquidationValue = 0;
		}
		return ( typeof currentStats.netLiquidationValue != 'undefined' ) ? formatCash(currentStats.netLiquidationValue) : 'N/A';
	}

	function getTodayCash(){
		if (currentStats.todayChangeCash === null){
			currentStats.todayChangeCash = 0;
		}
		return ( typeof currentStats.todayChangeCash != 'undefined' ) ? formatCash(currentStats.todayChangeCash) : '--';
	}

	function getTodayPercent(){
		return ( typeof currentStats.todayChangePercent != 'undefined' ) ? formatPercent(currentStats.todayChangePercent) : '--';
	}

	function getMTDCash(){
		if (currentStats.mtdChangeCash === null){
			currentStats.mtdChangeCash = 0;
		}
		return ( typeof currentStats.mtdChangeCash != 'undefined' ) ? formatCash(currentStats.mtdChangeCash) : '--';
	}

	function getMTDPercent(){
		if (currentStats.mtdChangePercent === null){
			currentStats.mtdChangePercent = 0;
		}
		return ( typeof currentStats.mtdChangePercent != 'undefined' ) ? formatPercent(currentStats.mtdChangePercent) : formatPercent(currentStats.monthToDate);
	}

	function getYTDCash(){

		if (currentStats.ytdChangeCash === null){
			currentStats.ytdChangeCash = 0;
		}
		return ( typeof currentStats.ytdChangeCash != 'undefined' )  ? formatCash(currentStats.ytdChangeCash) : '--';
	}

	function getYTDPercent(){

		return ( typeof currentStats.ytdChangePercent != 'undefined' ) ? formatPercent(currentStats.ytdChangePercent) : '--';
	}



	function getLongCash(){
		return ( typeof currentStats.longValue != 'undefined' ) ? formatCash(currentStats.longValue) : '--';
	}
	
	function getShortCash(){
		return ( typeof currentStats.shortValue != 'undefined' ) ? formatCash(currentStats.shortValue) : '--';
	}
	
	function getNetCash(){
		return ( typeof currentStats.netExposure != 'undefined' ) ? formatCash(currentStats.netExposure) : '--';
	}
	
	function getGrossCash(){
		return ( typeof currentStats.grossExposure != 'undefined' ) ? formatCash(currentStats.grossExposure) : '--';
	}

	function getLongPercent(){
		return ( typeof currentStats.longExposure != 'undefined' ) ? formatPercent(currentStats.longExposure) : '--';
	}
	
	function getShortPercent(){
		return ( typeof currentStats.shortExposure != 'undefined' ) ? formatPercent(currentStats.shortExposure) : '--';
	}
	
	function getNetPercent(){
		return ( typeof currentStats.longExposure != 'undefined' && typeof currentStats.shortExposure != 'undefined' ) ? formatPercent(currentStats.longExposure - currentStats.shortExposure) : '--';
	}
	
	function getGrossPercent(){
		return ( typeof currentStats.grossExposure != 'undefined' && typeof currentStats.netLiquidationValue != 'undefined' ) ? formatPercent(currentStats.grossPercent) : '--';
	}



    var getStats = function(statistics){
    	currentStats = statistics;
    	console.log('Current Stats');
    	console.log('====================');
    	console.log(currentStats);

		return {
			availableCash		: getAvailableCash(),
			accountValue		: getAcctVal(),
			
			todayCash			: getTodayCash(),
			todayPercent 		: getTodayPercent(),
			mtdCash 			: getMTDCash(),
			mtdPercent 			: getMTDPercent(),
			ytdCash 			: getYTDCash(),
			ytdPercent 			: getYTDPercent(),

			longCash			: getLongCash(),
			shortCash			: getShortCash(),
			netCash				: getNetCash(),
			grossCash			: getGrossCash(),

			longPercent			: getLongPercent(),
			shortPercent		: getShortPercent(),
			netPercent			: getNetPercent(),
			grossPercent		: getGrossPercent(),
			rawValues			: statistics
		};
    };


    var makePDF = function(pdfData){
    	return $http.post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, pdfData, { headers: { 'Content-Type': 'application/json'},responseType:'arraybuffer'});
    };

    var setModelList = function(modelList){
      platformModels = modelList;
    };

    var getModelList = function(){
      return angular.isDefined(platformModels) ? platformModels : [];
    };

    var setReportedModelList = function(models){
      reportedModelList = models;
    };

    var getReportedModelId = function(name){
			var reportedModels = _.map(reportedModelList, function(model){
				model.name = model.name.trim();
				return model;
			});
			var model = _.findWhere(reportedModels, {name: name.trim()});
			return model ? model.id : model;
    };

    var getReportedModelList = function(){
      return angular.isDefined(reportedModelList) ? reportedModelList : [];
    };

    var getModelIDs = function(account){

        var models = account.allocations.models,
            modelIDs = [],
            id;

        for (var model in models){
          if (model !== 'statistics'){
            id = models[model].fund_profile.nid;
            if (typeof id !== 'undefined'){
              modelIDs.push(id);
            }
          }
        }
        return modelIDs;
    };

    var getModelData = function(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/models`);
      return data;
		};
	
	function setMinimums (minimums) {
		// convert to dict for easier lookup
		modelMinimums = _.object(_.map(minimums, function(item) {
			return [item.model.id, item];
		}));
		
		console.log('Model minimums dictionary: ', modelMinimums);
	}

	function getMinimums () {
		return modelMinimums;
	}

	function getMinimumById (modelId) {
		var minimum = modelMinimums[modelId];
		if (minimum) {
			return minimum.modelMinimum;
		} else return null;
	}


	return {
		currentViewpoint 	: currentViewpoint,
		currentModel 		: currentModel,
		getStats			: getStats,
		makePDF				: makePDF,
		getModelList		: getModelList,
		setReportedModelList: setReportedModelList,
		getReportedModelList: getReportedModelList,
		setModelList        : setModelList,
		getModelIDs         : getModelIDs,
		getModelData		: getModelData,
		getModelProfileById : getModelProfileById,
		formatStatistics	: formatStatistics,
		getReportedModelId  : getReportedModelId,

		getCachedSearchResponse: getCachedSearchResponse,
		saveSearchResponse: saveSearchResponse,
		getFlags: getFlags,
		getMinimums: getMinimums,
		setMinimums: setMinimums,
		getMinimumById: getMinimumById 
	};
}]);