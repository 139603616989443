angular.module('editPortfolio', [])
.directive('editPortfolio', function($timeout, Models, $templateCache){
	return {
		restrict: 'E',
		scope: {
			models: '='
		},
        template: $templateCache.get('editPortfolio.tpl.html'),
		link: link,
		controller: editPortfolioCtrl,
    	controllerAs: 'vm'
	};

	function link(scope, elem, attr){
		console.log(scope);
		console.log(elem);
		console.log(attr);
		debugger
	}

	function editPortfolioCtrl($scope, $log){
		var vm = this;


		vm.groups = [
			'Stocks & ETFs',
		    'Your Watchlist',
		    'General Investing',
		    'Alternative Models',
		    'Traditional Models',
		    'HedgeCoVest Composites'
		];
		vm.groupFilter = '';
		vm.status = {
		    isopen: false
		};




		vm.toggled = function(open) {
		    $log.log('Dropdown is now: ', open);
		};

		vm.toggleDropdown = function($event) {
		    $event.preventDefault();
		    $event.stopPropagation();
		    vm.status.isopen = !vm.status.isopen;
		};

		init();


		vm.setGroupFilter = setGroupFilter;



		function init()
		{
			vm.groupFilter = vm.groups[0];

			// console.log(models);
			console.log(vm.models);
			debugger;
		}

		function setGroupFilter(choice)
		{
			vm.groupFilter = choice;
		}
	}
});