angular.module('Directives')
.directive('dashboardPanel', function($compile, $templateCache){
    return {
		restrict: 'E',
		replace: true,
		scope : true,
		template : '<div></div>',
		link: function (scope, element, attrs){

			var html = $templateCache.get(scope.tab.type + '.html');
			var template = $compile(html)(scope);
			element.html(template);
		}
    }
    
});