angular.module('SearchFiltersService', [])
.factory('SearchFilters', ['$http', '$filter', 'USER', 'DRUPAL_API_URL', function($http, $filter, USER, DRUPAL_API_URL){

  var selectedRows = [];
  var selectedModelIDs = [];
  var platform = USER.enterprise; /* Drupal.settings.white_label_name; */
  var fields = [];

  // these are set in HomeCtrl using the search_options response
  var secondary_model_type_dictionary;
  var accountCompatibility, account_compatibility;
  var fund_style_box_dictionary = {};
  var model_type_2_dictionary = {};
  var secondaryModelType = [];
  var model_category_2_dictionary = {};
  var modelCategory2 = [];
  var fundStyleBox = [];
  var fund_type_dictionary = {};
  var fundType = [];
  var regions_dictionary = {};
  var regions = [];
  var asset_class_dictionary = {};
  var assetClass = [];
  var market_capitalization_dictionary = {};
  var marketCapitalization = [];
  var sector_focus_dictionary = {};
  var sectorFocus = [];
  var portfolio_construction_dictionary = {};
  var portfolioConstruction = [];
  var futures_focus_dictionary;
  var futuresFocus;

  // var rangeFilters = [
  //   {
  //     id: 'yearsTracked',
  //     colName: 'Track Record (in years)'
  //   },
  //   {
  //     id: 'cagr',
  //     colName: 'Avg. Annual Return'
  //   },
  //   {
  //     id: 'standard_deviation',
  //     colName: 'Standard Deviation'
  //   },
  //   {
  //     id: 'sortino',
  //     colName: 'Sortino'
  //   },
  //   {
  //     id: 'sharpe',
  //     colName: 'Sharpe'
  //   },
  //   {
  //     id: 'correlation',
  //     colName: 'Correlation'
  //   },
  //   {
  //     id: 'minimum_allocation',
  //     colName: 'Min. Allocation'
  //   },
  //   {
  //     id: 'management_fee',
  //     colName: 'Fee'
  //   }
  // ];

  var modelType = [
    {
      name: 'All Models',
      value: ''
    },
    {
      name: 'Alternative Models',
      value: 'Alternative Models'
    },
    {
      name: 'Traditional Models',
      value: 'Traditional Models'
    },
    {
      name: 'Composite Models',
      value: 'Composite Models'
    },
  ];

  // labels for advanced search sliders are pulled from here
  var keyStats = [
    {name : 'Track Record (in years)'},
    {name : 'Sharpe'},
    {name : 'Avg. Annual Return'},
    {name : 'Standard Deviation'},
    {name : 'Correlation'},
    {name : 'Sortino'},
    {name : 'Fee'},
    {name : 'Min. Allocation'}
  ];

  var rangeFilters = {
        'Sharpe' : {
            min: -3,
            max: 3,
            options: {
              id: 'Sharpe',
              ceil: 3,
              floor: -3,
              step: 0.1,
              precision: 1
            },
        },
        'Avg. Annual Return' : {
            min: -15,
            max: 15,
            options: {
              id: 'Avg. Annual Return',
              floor: -15,
              ceil: 15,
              step: 5,
              translate: function(value){
                return value + '%';
              }
            },
        },
        'Sortino' : {
          min: -3,
            max: 3,
            options: {
              floor: -3,
              ceil: 3,
              id: 'Sortino',
              step: 0.1,
              precision: 1
            },
        },
        'Correlation' : {
          options: {
            floor: -1,
            ceil: 1,
            id: 'Correlation',
            step: 0.10,
            precision: 1,
          },
          min: -1,
          max: 1,
        },
        'Min. Allocation' : {
          options: {
            floor: 5000,
            ceil: 250000,
            id: 'Min. Allocation',
            step: 5000,
            translate: function(value){
              return $filter('currency')(value, '$');
            }
          },
          min: 5000,
          max: 250000,
        },
        'Track Record (in years)' : {
            options: {
              floor: 1,
              ceil: 30,
              step: 1,
              id: 'Track Record (in years)'
            },
            min: 1,
            max: 30,
        },
        'Standard Deviation' : {
            options: {
              floor: 5,
              ceil: 50,
              id: 'Standard Deviation',
              step: 1,
              translate: function(value){
                return value + '%';
              }
            },
            min: 5,
            max: 50,
        },
        'Fee' : {
            options: {
              floor: 0,
              ceil: 4,
              step: 1,
              id: 'Fee',
              step: 0.1,
              precision: 1,
            },
            min: 0,
            max: 4,
        }
    };


    var filterTypes = {
        "asset_class" : "assetClass",
        "fund_type" : "fundType",
        "account_compatibility": "accountCompatibility",
        "fund_style_box" : "fundStyleBox",
        "secondary_model_type" : "secondaryModelType",
        "regions" : "regions",
        "market_capitalization" : "marketCapitalization",
        "sector_focus" : "sectorFocus",
        "portfolio_construction" : "portfolioConstruction",
        "model_type" : "modelType",
        "model_category": "modelCategory",
        "model_category_2":"modelCategory2",
        "rangeFilters" : "rangeFilters"
    };

    // var rangeFilterColumnRefs = [
    //   {
    //     id: 'yearsTracked',
    //     colName: 'Track Record (in years)'
    //   },
    //   {
    //     id: 'cagr',
    //     colName: 'Avg. Annual Return'
    //   },
    //   {
    //     id: 'standard_deviation',
    //     colName: 'Standard Deviation'
    //   },
    //   {
    //     id: 'sortino',
    //     colName: 'Sortino'
    //   },
    //   {
    //     id: 'sharpe',
    //     colName: 'Sharpe'
    //   },
    //   {
    //     id: 'correlation',
    //     colName: 'Correlation'
    //   },
    //   {
    //     id: 'minimum_allocation',
    //     colName: 'Min. Allocation'
    //   },
    //   {
    //     id: 'management_fee',
    //     colName: 'Fee'
    //   }
    // ];

    var sortOptions = [

      {
        colName: "Title",
        id: "title"
      },
      {
        colName: "MTD",
        id: "mtd"
      },
      {
        colName: "YTD",
        id: "ytd"
      },
      {
        colName: "Avg Gain",
        id: "average_gain"
      },
      {
        colName: "Avg Loss",
        id: "average_loss"
      },
      {
        colName: "CAGR",
        id: "cagr"
      },
      {
        colName: "Highest Return",
        id: "highest_return"
      },
      {
        colName: "Sharpe",
        id: "sharpe"
      },
      {
        colName: "Sortino",
        id: "sortino"
      },
      {
        colName: "Correlation",
        id: "correlation"
      },
      {
        colName: "Standard Deviation",
        id: "standard_deviation"
      },
      {
        colName: "Min. Allocation",
        id: "minimum_allocation"
      },
      {
        colName: "1-yr",
        id: "1-yr"
      },
      {
        colName: "3-yr",
        id: "3-yr"
      },
      {
        colName: "5-yr",
        id: "5-yr"
      }
  ];

  // for processing search_options in HomeCtrl
  function transformDictionaryToArray(dict){
        var items = [];

        _.each(dict, function(value, key){
            var itemData = {};

            itemData.id = key;
            itemData.name = value;
            itemData.value = value;

            items.push(itemData);
        });

        console.log("transformed items: ", items);
        return items;
    }

  function transformModelCategoriesToArray(categories){
        var items = [];

        _.each(categories, function(category, key){
            var itemData = {};

            itemData.id = key;
            itemData.name = category.name;
            itemData.classification = category.related_model_types.join(', ');
            itemData.value = category.name;
            itemData.selected = false;

            items.push(itemData);
        });

        console.log("model category 2 items: ", items);
        return items;
    }

    // get all the search filter options from drupal
  function loadSearchFilterOptions(){

    return $http.get(`${DRUPAL_API_URL}/ng_api/v2/search_options`);

  }

  // set advanced filters back to starting values
  function resetRangeFilter(filter){
    rangeFilters[filter].min = rangeFilters[filter].floor;
    rangeFilters[filter].max = rangeFilters[filter].ceil;
  }

    function setSelectedRows(rows){
      selectedRows = rows;
    }

    function clearSelectedRows(){
      selectedRows = [];
    }

    function getSelectedRows(){
      return selectedRows;
    }

    function titleCellRendererFunc(params) {
      var tooltipContent = '';
      var html = '<div class="tooltip-wrap clearfix">';
      html += '<a class="clearfix" href="#" ng-click="addModelTabForId(\'' + params.data.guid + '\');">';
      html += params.value;
      html += '</a>';
      html += '<div>';

      //console.log(html);

      return html;
  }

  function numberSort(val1, val2) {

      val1 = val1 == '--' ? 0 : val1;
      val2 = val2 == '--' ? 0 : val2;

      if ( isNaN( parseFloat(val1, 10) - parseFloat(val2, 10) ) ){
        return 0;
      } else {
        return parseFloat(val1, 10) - parseFloat(val2, 10);
      }
    }

    function asPercent(params) {

      if (isNaN(params.value)) {

        return '--';

      } else {

        return Number(params.value).toFixed(2) + '%';

      }
    }

    function asNumber(params) {
      if (isNaN(params.value)) {
        return '--';
      } else {
        return Number(params.value).toFixed(2);
      }
    }


    function saveSearchPresetforUser(input) {
      console.log("Save search payload: ", input);
      store.set('smxSearchPresets', {payload: input});
    }

  var columnDefs = [{
      headerName: '',
      width: 30,
      checkboxSelection: true,
      suppressSorting: true,
      suppressMenu: true,
      pinned: true
    },
    {
      headerName: "Model",
      field: "title",
      sortable: "title",
      width: 280,
      pinned: true,
      format: 'text'
    },
    {
      headerName: "YTD",
      field: "ytd",
      title: "YTD",
      width: 85,
      sortable: "ytd",
      headerTooltip: 'YTD',
      comparator: numberSort,
      sort: 'desc',
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "MTD",
      field: "mtd",
      title: "MTD",
      width: 85,
      sortable: "mtd",
      headerTooltip: '30 Day',
      comparator: numberSort,
      cellRenderer: asPercent,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Std Dev",
      title: "Std Dev",
      field: "standard_deviation",
      width: 95,
      sortable: "standard_deviation",
      headerTooltip: 'Standard Deviation',
      comparator: numberSort,
      cellRenderer: asPercent,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Sharpe Ratio",
      title: "Sharpe Ratio",
      field: "sharpe",
      width: 85,
      sortable: "sharpe",
      headerTooltip: 'Sharpe',
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: ''
    },
    {
      headerName: "Avg. Ann.",
      title: "Avg. Ann.",
      field: "cagr",
      width: 120,
      sortable: "cagr",
      headerTooltip: 'Average Annual',
      comparator: numberSort,
      cellRenderer: asPercent,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Correlation",
      title: "Correlation",
      field: "correlation",
      width: 85,
      sortable: "correlation",
      headerTooltip: 'Correlation to S&P 500',
      cellRenderer: asNumber,
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: ''
    },
    {
      headerName: "Sortino Ratio",
      title: "Sortino Ratio",
      field: "sortino",
      width: 85,
      sortable: "sortino",
      headerTooltip: 'Sortino',
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: ''
    },
    {
      headerName: "Avg Gain",
      title: "Avg Gain",
      field: "average_gain",
      width: 95,
      sortable: "average_gain",
      headerTooltip: 'Avg Gain',
      comparator: numberSort,
      cellRenderer: asPercent,
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Avg Loss",
      title: "Avg Loss",
      field: "average_loss",
      width: 95,
      sortable: "average_loss",
      headerTooltip: 'Avg Loss',
      comparator: numberSort,
      cellRenderer: asPercent,
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Min. Allocation",
      title: "Min. Allocation",
      field: 'minimum_allocation',
      width: 75,
      sortable: "minimum_allocation",
      cellRenderer: asPercent,
      hide: false,
      format: 'number',
      leadingCharacter: '$',
      trailingCharacter: ''
    },
    {
      headerName: "Fee",
      title: "Fee",
      field: 'management_fee',
      width: 75,
      sortable: "management_fee",
      cellRenderer: asPercent,
      hide: false,
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Highest Monthly Return",
      title: "Highest Monthly Return",
      field: "highest_return",
      width: 95,
      sortable: "highest_return",
      headerTooltip: 'Highest Return',
      comparator: numberSort,
      cellRenderer: asPercent,
      hide: true,
      format: 'number',
      trailingCharacter: '%'
    },
    {
      headerName: "Track Record (in years)",
      title: "Track Record (in years)",
      field: 'yearsTracked',
      sortable: "yearsTracked",
      format: 'number',
      width: 75,
      hide: true
    }
  ];


  return {

    loadSearchFilterOptions         : loadSearchFilterOptions,
    fields                          : fields,

    fundType                        : fundType,
    fund_type_dictionary            : fund_type_dictionary,

    accountCompatibility            : accountCompatibility,
    account_compatibility           : account_compatibility,

    fundStyleBox                    : fundStyleBox,

    model_category_2_dictionary     : model_category_2_dictionary,
    modelCategory2                  : modelCategory2,

    secondary_model_type_dictionary     : secondary_model_type_dictionary,
    model_type_2_dictionary             : model_type_2_dictionary,
    secondaryModelType                  : secondaryModelType,

    fund_style_box_dictionary           : fund_style_box_dictionary,

    regions                       : regions,
    regions_dictionary            : regions_dictionary,

    assetClass                    : assetClass,
    asset_class_dictionary        : asset_class_dictionary,

    marketCapitalization              : marketCapitalization,
    market_capitalization_dictionary  : market_capitalization_dictionary,

    sectorFocus                   : sectorFocus,
    sector_focus_dictionary       : sector_focus_dictionary,

    portfolioConstruction               : portfolioConstruction,
    portfolio_construction_dictionary   : portfolio_construction_dictionary,

    // rangeFilterColumnRefs       : rangeFilterColumnRefs,
    sortOptions                 : sortOptions,
    columnDefs                  : columnDefs,

    modelType                 : modelType,

    selectedRows              : selectedRows,
    selectedModelIDs          : selectedModelIDs,
    keyStats                  : keyStats,
    rangeFilters              : rangeFilters,
    filterTypes               : filterTypes,
    setSelectedRows           : setSelectedRows,
    clearSelectedRows         : clearSelectedRows,
    getSelectedRows           : getSelectedRows,

    saveSearchPresetforUser   : saveSearchPresetforUser
  };
}]);
