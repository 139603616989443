angular.module('HomeCtrl')
.controller("ManageGroupsCtrl", function($rootScope, $scope, $http, $uibModal, Dashboard, Groups, toastr, $templateCache) {


    $scope.$on('modal.closing', function(event, reason, closed) {
        debugger
       return true;
    });


    $rootScope.$on('dashboard-account-access-set', function(event, args) {

        $scope.accessGroups =  args.groups;
        $scope.accessAccounts =  args.accounts;
        console.log("access groups: ", $scope.accessGroups);
        console.log("access accounts: ", $scope.accessAccounts);
        buildAccessNamesArray();

        $scope.processingGroupModification = false;
    });

    $scope.paginate = function (value) {  
        var begin, end, index;  
        begin = ($scope.currentPage - 1) * $scope.numPerPage;  
        end = begin + $scope.numPerPage;  
        index = $scope.accessAccountNames.indexOf(value);  
        return (begin <= index && index < end);  
    };



    var _selected, modalInstance;

    $scope.tataSelectedAccount = undefined;
    $scope.processingGroupModification = false;
    $scope.groupAccounts = [];
    $scope.accessGroups = [];
    $scope.accessAccounts =[];
    $scope.accessAccountNames = [];
    $scope.accessGroupNames = [];
    $scope.formData = {};

    $scope.states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Dakota', 'North Carolina', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    
    $scope.modelOptions = {
        debounce: {
          default: 500,
          blur: 250
        },
        getterSetter: true
    };

    $scope.taAccountSelected = function($item, $model, $label, $event)
    {

        var index = $scope.groupAccounts.indexOf($item);
        console.log( $item, $model, $label, $event );

        if (index == -1 && $scope.groupAccounts.indexOf($item) === -1) {
            $scope.groupAccounts.push($item);
        }
        $scope.taSelectedAccount = undefined;
    };

    $scope.removeAcount = function($item)
    {

        var index = $scope.groupAccounts.indexOf($item);
        if (index > -1) {
            $scope.groupAccounts.splice(index, 1);
        }
    };

    $scope.removeGroupFromAccount = function(group, account, accountGUID, index){

        $scope.processingGroupModification = true;
        //example of using the /group/:groupId/accounts/:accountId endpoint with PUT and DELETE 

        Groups.deleteAccounts(group.id, accountGUID)
        .then(function(response){
            
            // delete account.groups[index];
            // console.log("Account was deleted!", account, $scope.accessAccounts);

            $rootScope.$broadcast('dashboard-refresh-access');
            toastr.success(group.name + ' was successfully removed from ' + $scope.accessAccounts[accountGUID].information.name + '!');
        });

    };

    $scope.addGroupToAccount = function(group, accountGUID){

        $scope.processingGroupModification = true;

        Groups.addAccounts(group.guid, accountGUID)
        .then(function(response){

            console.log("account was added to group");

            $rootScope.$broadcast('dashboard-refresh-access');
            toastr.success(group.name + ' was successfully added to ' + $scope.accessAccounts[accountGUID].information.name + '!');
        });
        
    };

    $scope.taOnSelectHandler = function(group, accountGUID)
    {
        $scope.addGroupToAccount(group, accountGUID);
        $scope.formData[accountGUID] = "";
    }

    $scope.closeModal = function()
    {
        console.log(modalInstance);
        debugger;
    }

    $scope.createNewGroupModal = function()
    {

        modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          template: $templateCache.get('newGroupModal.html'),
          size: 'lg',
          // controller: 'ManageGroupsCtrl'
          controller: function($scope, $rootScope, $uibModalInstance, Groups, Dashboard) {


            $scope.accessAccounts =[];
            $scope.accessAccountNames = [];
            $scope.accessGroupNames = [];
            $scope.groupAccounts = [];


            $scope.close = function(){
                $uibModalInstance.dismiss('cancel');
            };

            $scope.createNewGroup = function(name){
          
                $scope.processingGroupModification = true;
                var members = $scope.groupAccounts.map(function(account){
                    return account.guid;
                });

                debugger
                Groups.create(name, members)
                .then(function(res){
                    
                    $rootScope.$broadcast('dashboard-refresh-access');
                    toastr.success('new group successfully created!');
                    $scope.groupAccounts = [];
                    $scope.groupName = '';

                });
            };


            $scope.taAccountSelected = function($item, $model, $label, $event)
            {

                var index = $scope.groupAccounts.indexOf($item);
                console.log( $item, $model, $label, $event );

                if (index == -1 && $scope.groupAccounts.indexOf($item) === -1) {
                    $scope.groupAccounts.push($item);
                }
                $scope.taSelectedAccount = undefined;
            };



            function init(){

                $scope.numPerPage = 10;
                $scope.currentPage = 1;
                $scope.accessGroups = Dashboard.getAccessGroups();
                $scope.accessAccounts = Dashboard.getAccessAccounts();
                $scope.totalItems = (typeof $scope.accessAccounts != 'undefined') ? $scope.accessAccounts.length : {};
                
                buildAccessNamesArray();
            }

            function buildAccessNamesArray ()
            {
                $scope.accessGroupNames = [];
                $scope.accessAccountNames =[];

                for (var key in $scope.accessGroups) {

                    var obj = $scope.accessGroups[key];
                    obj.guid = key;
                    $scope.accessGroupNames.push(obj);
                };
                
                for (var key in $scope.accessAccounts) {

                    var obj = $scope.accessAccounts[key];
                    obj.guid = key;
                    $scope.accessAccountNames.push(obj);
                };
            }

            init();

          }
        });

        modalInstance.result.then(function (selectedItem) {
          
        }, function () {
          
          console.log('Modal dismissed at: ' + new Date());
        });
    };


    function init(){

        $scope.numPerPage = 10;
        $scope.currentPage = 1;
        $scope.accessGroups = Dashboard.getAccessGroups();
        $scope.accessAccounts = Dashboard.getAccessAccounts();
        $scope.totalItems = (typeof $scope.accessAccounts != 'undefined') ? $scope.accessAccounts.length : {};
        
        buildAccessNamesArray();
    }

    function buildAccessNamesArray ()
    {
        $scope.accessGroupNames = [];
        $scope.accessAccountNames =[];

        for (var key in $scope.accessGroups) {

            var obj = $scope.accessGroups[key];
            obj.guid = key;
            $scope.accessGroupNames.push(obj);
        };
        
        for (var key in $scope.accessAccounts) {

            var obj = $scope.accessAccounts[key];
            obj.guid = key;
            $scope.accessAccountNames.push(obj);
        };
    }

    // set up initial values and get some data
    init();

})
.filter('custom', function() {
  return function(input, search) {
    if (!input) return input;
    if (!search) return input;
    var expected = ('' + search).toLowerCase();
    var result = {};
    angular.forEach(input, function(value, key) {
      var actual = ('' + value.name).toLowerCase();
      if (actual.indexOf(expected) !== -1) {
        if (typeof result[key] == 'undefined'){ result[key] = {}; }
        result[key].name = value;
      }
    });
    return result;
  }
});
