angular
  .module('AccountFunding', [])
  .controller("AccountFundingCtrl", function($scope) {

    var vm = this;

    vm.exportAccountData = exportAccountData;
    vm.downloading = false;

    function exportAccountData(){
        $scope.$broadcast('accountFunding:export');
    }
    
});