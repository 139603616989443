angular.module('accountWelcome', ['HTMLCompiler'])
.directive("checklist",function($interpolate, USER, $templateCache, Dashboard){

	function getChecklistTemplate(){
		var template;

		if (USER.is_ria){
			template = $templateCache.get('riaAccountChecklist.html');
		} else {
			template = $templateCache.get('accountChecklist.html');
		}
		return template;
	}
	
	return {
		restrict: 'E',
		scope : true,
		template: 	getChecklistTemplate(),

		link: function(scope, element){
			scope.is_ria = USER.is_ria ? true : false;

			scope.checklist = {

		        steps : [
		            'Complete Profile',
		            //'Create Portfolio - Optional',
		            'Open Brokerage',
		            //'Fund Account',
		            'Start Trading'
		        ],
		        riaSteps : [
		            'Complete Profile',
		            //'Create Portfolio - Optional',
		            'Onboard Clients',
		        ],
		        riaMessages : [
		            '<a href="#" ng-click="addTab(\'profile\');">First complete your profile before linking it to a brokerage account.</a>',
		            'Call us at (561) 835-8690 when you are ready to onboard clients.'
		        ],
		        messages : [
		            '<a href="#" ng-click="addTab(\'profile\');">First complete your profile before linking it to a brokerage account.</a>',
		            //'<a href="#" ng-click="addTab(\'portfolio-builder\');">Create a weighted portfolio with leverage and backtest it against the S&P.</a>',
		            '<a href="#" ng-click="addTab(\'open-brokerage-account\');">Add a new account and link it to IB.</a> New accounts normally take 24-48 hours to be approved.',
		            //'Visit <a  href="https://www.interactivebrokers.com/en/home.php" target="_blank">www.interactivebrokers.com</a> to fund your account.',
		            'Make your first trade!'
		        ],
		        complete : {
		            step1 : false,
		            step2 : false,
		            step3 : false,
		            step4 : false
		        },
		        percent : [
		            30,
		            65,
		            90,
		            100
		        ],
		        stepsComplete : 0,
		        percentage : function(){
		            return (scope.checklist.stepsComplete > 0) ? scope.checklist.percent[scope.checklist.stepsComplete] : 20;
		            //return (scope.checklist.stepsComplete > 0) ? parseInt((scope.checklist.stepsComplete / scope.checklist.steps.length) * 100) : 20;
		        },
		    };

		    scope.$on("UserProfileCompleted", function(event, status){
		        if(status == "complete"){
		            scope.checklist.stepsComplete = 1;
		           //console.log(status);
		        }
		    });

		    if( (parseInt(USER.completed_profile, 10) == 1) ){
		        scope.checklist.stepsComplete = 1;
		    }

	        if( (parseInt(USER.active_accounts, 10) > 0) ){
		        scope.checklist.stepsComplete = 2;
		       //console.log('Found ' + parseInt(USER.active_accounts, 10) + ' Active Accounts');

		        scope.$emit('HasActiveAccount', (parseInt(USER.active_accounts, 10)));
		       //console.log("$emit HasActiveAccount");
		    }else{
		       //console.log('Found No Active Accounts');

		        scope.$emit('HasActiveAccount', 0);
		       //console.log("$emit Has No Active Account");
		    }

		    scope.pendingAccounts = Dashboard.pendingAccounts;

	        if(typeof scope.pendingAccounts != 'undefined' && Object.size(scope.pendingAccounts) > 0){
	            scope.checklist.messages[1] = 'You have ' + Object.size(scope.pendingAccounts) + ' pending accounts. <a href="#" ng-click="addTab(\'open-brokerage-account\');">Add another brokerage account.</a>';
	        }
		}
	};
});