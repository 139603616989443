angular.module('Directives')
.directive('performanceTable', ['$templateCache', function($templateCache){
	// Runs during compile
	return {
		// name: '',
		// priority: 1,
		// terminal: true,
		scope: {
			portfolioPerformance: "=performance",
			benchmarkYTD: "=?benchmarkYtd",
			aggregateYTD: "=?portfolioYtd",
			benchmarkName: "=?benchmarkName",
			type: "@type",
			yearsInPortfolio: "=?years",
			fee: "=?fee"

		}, // scope value of {} = isolate, true = child, false/undefined = no change
		// controller: function($scope, $element, $attrs, $transclude) {},
		// require: 'ngModel', // Array = multiple requires, ? = optional, ^ = check parent elements
		restrict: 'E', // E = Element, A = Attribute, C = Class, M = Comment
		template: $templateCache.get("monthlyPerformanceTable.tpl.html"),
		link: function($scope, iElm, iAttrs, controller) {
			$scope.range = function(start, end) {
		      var arr = [];
		      for (var i = start; i < end; i++) {
		        arr.push(i);
		      }
		      return arr;
		    };
		}
	};
}]);