angular.module('Directives')
.directive('accountRundown', accountRundown)

function accountRundown($templateCache) {
	return {
		restrict: 'E',
		scope: {
			statistics: '=statistics',
		},
		bindToController: true,
		controller: function(){},
		controllerAs: 'vm',
		template: $templateCache.get('accountRundown.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {}
	};
}