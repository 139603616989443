angular.module('RebalanceRequestDetailsV2', [])
.directive('rebalanceRequestDetailsV2', ['$filter', 'RebalancerFactory', '$templateCache', 'Tabs', 'Dashboard', function($filter, RebalancerFactory, $templateCache, Tabs, Dashboard){
	return {
		template: $templateCache.get('rebalanceRequestDetailsV2.tpl.html'),
		controller: requestDetailsControllerV2,
		controllerAs: 'vm',
		restrict: 'E',
		scope: {
			requestId: '=',
			status: '=',
			accounts: '='
		},
		bindToController: true,
		link: function($scope, iElm, iAttrs, controller) {},
	};

	function requestDetailsControllerV2(NgTableParams, toastr, $rootScope, $timeout, $scope, $modal, SmartXFactory){
		var vm = this;
		// var parentTab = $scope.$parent.tab;
		var token = SmartXFactory.getToken();
		// vm.parentTab = parentTab;
		vm.token = token;
	}
}]);