angular
	.module('PortfolioEditorFactory', [])
	.factory('PortfolioEditorFactory', ['$http', '$window', '$q', 'SmartXFactory', 'Dashboard', 'DRUPAL_API_URL', function($http, $window, $q, SmartXFactory, Dashboard, DRUPAL_API_URL){

		console.log(this);

		var service = this;
		var api = `${DRUPAL_API_URL}/ng_api/v2/`;
		var symbolsUniverse = [];
		var modelsUniverse = [];
		var symbolApi = SmartXFactory.getSymbolAPI();
		var accountApi = SmartXFactory.getAccountAPI();

		function convertCsvResponsetoJSON(response){
			var config = {
				header: true,
				delimiter: '|',
				newline: '\n',
				skipEmptyLines: true
			};

			// take out extra quotes to get rid of unterminated quote error
			response = response.replace(/"/g, '');

			var parsedResponse = Papa.parse(response, config).data;
			console.log("parsed response: ", parsedResponse);

			return parsedResponse;
		}

		service.submitOrders = function(id, payload){
			return accountApi.apm.updateHoldings(id, payload);
		};

		service.getPortfolioTradeHistory = function(id, query){
			return accountApi.apm.getHistory(id, query);
		};

		service.getPortfolio = function(id, query){
			return accountApi.apm.getHoldings(id, query);
		};

		service.savePortfolioChanges = function(id, changes){

			var payload = changes.map(function(trade){
				return {
					"ticker": trade.symbol.ticker,
					"symbolId": trade.symbol.id,
					"quantity": +trade.newValue / 100,
					"type": "percentage"
				};
			});

			var promise = new Promise(function(resolve, reject){

				//$q.all(requests)
				$q.when(accountApi.apm.updateHoldingsBatch(id, payload))
				.then(function(res){
					resolve(res);
				})
				.catch(function(err){
					reject(err);
				})
				.then(function(){
					service.sendLoggingEmail(id, changes);
				});
			});

			return promise;
		};

		service.getSymbols = function(symbol, assetType){

			if(symbolsUniverse.length > 0){
				return $q.resolve(symbolsUniverse);
			}

			symbol = symbol && symbol != '*' ? symbol : '';

			if (assetType){
				return symbolApi.get(undefined, { assetTypes: assetType, startswith:symbol });
			} else {
				return symbolApi.get();
			}
		};

		service.searchAllSymbols = function(symbol, assetType){

			if(symbolsUniverse.length > 0){
				return $q.resolve(symbolsUniverse);
			}

			symbol = symbol && symbol != '*' ? symbol : '';

			if (assetType){
				return symbolApi.searchAllSymbols({ assetTypes: assetType, startswith:symbol });
			} else {
				return symbolApi.searchAllSymbols();
			}
		};

		service.saveSymbols = function(payload){
			symbolsUniverse = payload;
		};

		service.saveModels = function(payload){
			modelsUniverse = payload;
		};

		service.getAllowedTargets = function(targetId){

			var url = `${DRUPAL_API_URL}/ng_api/v1/mngr/target/${targetId}/allowedTargets`;
			var targets = $http.get(url);

			return targets;
		};

		service.sendLoggingEmail = function(id, data, file){

			console.log("sending logging email");
			console.log("Id: ", id);
			console.log("Data: ", data);

			var user = Dashboard.getReplicatorUser();
			if (user) {
				var username = user.userName;
				var userId = user.id;
			}

			console.log('Current User: ', user);

			if (file){
				Upload.upload({
					url: HCV_API.v2Path + '/mail',
					method: 'POST',
					file: file,
					data: {
						'token'  : token,
						'to': 'jon@hedgecovest.com',
						'subject': 'Position Upload',
						'msg': 'New portfolio upload for ' + activeModel.information.name + '  ' + activeModel.ModelId
					}
				})
				.then(function(res){
					console.log("file upload result: ", res);
				});

			} else {

				var payload = {
					'to': 'jon@hedgecovest.com',
					'subject': 'Managed Portfolio Trade Request',
					'msg': 'New trade request for account: ' + id + ' by ' + userId + '<br/><br/>' + JSON.stringify(data, null, 2) + '<br/>'
				};

				$http.post(`${DRUPAL_API_URL}/ng_api/v2/mail`, jQuery.param(payload), {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})
				.then(function(res){
					console.log(res);
				});
			}
		};

		return service;
	}]);

