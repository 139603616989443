angular.module('SecurityAdministrator')
.controller('SecurityAdminCreateUserCtrl', securityAdminCreateUserCtrl);

function securityAdminCreateUserCtrl (Tabs,currentUser, $uibModalInstance, userApi, toastr, enterpriseUsers, EnterpriseFactory, $timeout, $q, Dashboard){
  var vm = this;
  vm.creating = false;
  vm.success = false;
  vm.copyExistingUser = false;
  vm.loadingEnterprises = false;

  vm.loginLink = window.location.host + '/login';
  vm.currentUser = currentUser;
  vm.newUserForm = {
    enterpriseId: '',
  };
  vm.selectedEnterpriseUser = '';

  vm.submit = submit;
  vm.cancel = cancel;
  vm.closeCompleted = closeCompleted;
  vm.enterpriseUsers = enterpriseUsers;
  vm.handleEnterpriseSelect = handleEnterpriseSelect;
  vm.handleUserSelect = handleUserSelect;
  vm.selectedEnterpriseUser = '';
  vm.viewProfile = viewProfile;
  vm.reset = reset;

  init();

  function init() {
    loadEnterprises();
  }

  function viewProfile (user) {
    Tabs.addTab('assignments', null, null, {
      user: user
    });
  }

  function loadEnterprises() {
    vm.loadingEnterprises = true;
    EnterpriseFactory.loadEnterpriseLookup()
    .then(function(enterprises){
      vm.enterpriseOptions = enterprises.map(function(enterprise){
        return {
          id: enterprise.id,
          name: enterprise.name
        };
      });
    })
    .catch(function(err){
      console.error(err);
      toastr.error(err.message);
    })
    .finally(function(){
      vm.loadingEnterprises = false;
    });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function format (form) {
    if (_.isEmpty(form.enterpriseId)) form.enterpriseId = vm.currentUser.enterprise.id;
    return form;
  }

  function handleUserSelect(item) {
    vm.newUserForm.existingUserId = item.id;
  }

  function handleEnterpriseSelect(item){
    vm.newUserForm.enterpriseId = item.id;
  }

  function copyProfile (newUserResponse) {

    // since the results come back as array, we need to look at the first item when creating a single user
    // if status === false then we failed and need to a reject with an error
    if (newUserResponse.data[0].status === false) {
      return Promise.reject(new Error(newUserResponse.data[0].message));
    }

    if (newUserResponse.data[0].status && vm.copyExistingUser && vm.newUserForm.existingUserId) {
      var newUserId = newUserResponse.data[0].id;

      return new Promise(function(resolve, reject) {
        vm.copyingProfile = true;
        userApi.copyProfile({
          from: vm.newUserForm.existingUserId,
          to: newUserId,
          replace: true
        })
        .then(function(res){
          console.log('Profile copy result: ', res);
          $uibModalInstance.dismiss(res.data);
          toastr.success('Profile copied');
          resolve({
            newUser: newUserResponse,
            copyResult: res
          });
        })
        .catch(function(err){
          console.error(err);
          toastr.error(err.message);
          reject(err);
        })
        .finally(function(){
          $timeout(function(){
            vm.copyingProfile = false;
          });
        });
      });
      
    } else {
      // pass through the create response if not copying
      return Promise.resolve(newUserResponse);
    }
  }

  function closeCompleted () {
    $uibModalInstance.close(vm.newUser);
  }

  // vm.success = true;
  // vm.newUser = {
  //   userName: 'TestUser001'
  // };

  function reset() {
    var userNameInput = document.getElementById('userName');
    vm.newUser = null;
    vm.success = false;
    vm.newUserForm = {};
    userNameInput.focus();
    userNameInput.scrollIntoView();
  }

  function submit(newUserForm) {
    vm.creating = true;
    vm.copyingProfile = false;
    var payload = format(angular.copy(newUserForm));

    payload.createIdentityUser = true;
    payload.roles = [
      "advisor"
    ];

    $q.when(userApi.create([payload]))
    // .then(copyProfile)
    .then(function(res){
      console.log("Process Completed: ", res);
      toastr.success("User created");
      vm.success = true;
      vm.newUser = res.data[0] || newUserForm;
    })
    .catch(function(err){
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      vm.creating = false;
    });
  }
}