angular.module('AssignModelToAccountModal', [])
  .controller('AssignModelToAccountModalCtrl', ['$uibModalInstance', 'Dashboard', 'SweetAlert', 'selectedAccount', '$q', 'SmartXFactory', function($uibModalInstance, Dashboard, SweetAlert, selectedAccount, $q, SmartXFactory) {

    var vm = this;
    var modelApi = SmartXFactory.getModelAPI();

    vm.selectedAccount = selectedAccount;
    vm.isSmaAccount = selectedAccount.internalType === 'sma';

    vm.selectedModels = [];
    vm.modelsList = [];
    vm.errorMsg = null;
    vm.showEmpty = true;
    vm.loadingModels = false;


    vm.dropdownAccountOptions = {
      enableSearch: true
    };

    vm.dropdownTargetOptions = {
      enableSearch: true,
      smartButtonMaxItems: 1,
      selectionLimit: 1,
      closeOnSelect: true,
      buttonClasses: 'btn btn-default smaModelSelectBtn',
      smartButtonTextConverter: function(itemText, originalItem) {
        return itemText;
      }
    };

    vm.targetBtnText = {
      buttonDefaultText: 'Select Model'
    };

    vm.cancel = cancel;
    vm.submit = submit;
    vm.toggleShowEmptyAccounts = toggleShowEmptyAccounts;

    init();

    function init() {
        // load sma models
        vm.loadingModels = true;
        
        $q.when(modelApi.getAllModels({ type: 'sma', status: 'active' }))
        .then(function(res){
          console.log("SMA Model Options: ", res);

          res.forEach(function(model, index){
            
            var existsInSleeves;
            
            existsInSleeves = _.find(vm.selectedAccount.allocations, function(sleeve){
                return sleeve.model && sleeve.model.id === model.id;
            });

            // exclude sma model that is already being used
            if (!existsInSleeves) {
              vm.modelsList.push({
                id: model.id,
                label: model.name || 'Model ' + index
              });
            }
          });

        })
        .catch(function(err){
          Dashboard.toastError('Couldn\'t load SMA strategies', err);
        })
        .finally(function(){
          vm.loadingModels = false;
        });

    }

    function submit() {
        var title = '',
            text = 'Assign model to this account?';

        if (!vm.selectedModels.length) {
            SweetAlert.swal({
            title: 'No model selected',
            text: '',
            type: "warning",
            });

            return false;
        }

        SweetAlert.swal({
            title: title,
            text: text,
            type: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        },
        function(isConfirm) {
            if (isConfirm) {
                $uibModalInstance.close({
                    selectedModel: vm.selectedModels[0]
                });
            }
        });

    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function toggleShowEmptyAccounts() {
      console.log(vm.showEmpty);
    }
}]);
