angular.module('TargetWeightCtrl', [])
.controller('TargetWeightsCtrl', function ($scope, Dashboard) {

	var vm = this;

	vm.accessAccounts = Dashboard.getAccessAccounts();
	vm.accessAccountNames = Object.keys(vm.accessAccounts);



    vm.changeAccount = function(refreshing){

        if(typeof $scope.selectedAccount == 'undefined'){
            return false;
        }
	}

});
