angular.module('HarvestingFactory', [])
.factory('HarvestingFactory', [ 'Dashboard', '$http', 'SmartXFactory', '$timeout', function(Dashboard, $http, SmartXFactory, $timeout){

	var taxHarvestingApi = SmartXFactory.getTaxHarvestingAPI();
	var accountApi = SmartXFactory.getAccountAPI();
	var symbolLotMock = [
		{
			"account": {
				"id": "b7939053-7630-4a6c-890c-97bad39da6de",
				"name": "BRAD BELL INDIVIDUAL",
				"accountTitle": "BRAD BELL INDIVIDUAL",
				"status": "active",
				"brokerageAccountNumber": "945023906",
				"accountType": "individual",
				"internalType": "uma",
				"isApm": true,
				"brokerage": {
					"id": "c743b162-273b-48b7-b84b-b5ca007a01c5",
					"name": "tdAmeritrade"
				},
				"createdOn": {
					"timestamp": 1608193083,
					"friendly": "12/17/2020 03:18:03"
				},
				"isTrading": true,
				"enterprise": {
					"id": "daff2cbe-ce37-496c-9b20-ef339e1dc460",
					"name": "Legacy Wealth Partners"
				},
				"isDemo": false,
				"isExcluded": false,
				"isBlackListed": false,
				"defaultTargetId": "00000000-0000-0000-0000-000000000000",
				"accessLevel": "noAccess"
			},
			"canVspTrade": false,
			"symbol": {
				"id": "5277b66f-b803-4f66-afc0-de6a33ec5ba6",
				"ticker": "SSO",
				"assetType": "equity",
				"companyName": "ProShares Ultra S&P500",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "326c0093-3f12-4480-8109-6bccb660fddf",
				"isActive": true
			},
			"lotDetails": [
				{
					"wouldWash": false,
					"quantity": 34,
					"costPerShare": 101.22,
					"vspPrice": 101.22,
					"costBasis": 506.1,
					"currentPrice": 142.84,
					"currentValue": 714.2,
					"isLotBad":false,
					"reasonLotIsBad":"No reason, just because",
					"gains": {
						"shortTermGains": 208.1,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 1
				},
				{
					"wouldWash": false,
					"openedOn": {timestamp:1631678400}, 
					"quantity": 3,
					"costPerShare": 87.21,
					"vspPrice": 87.21,
					"costBasis": 261.63,
					"currentPrice": 142.84,
					"currentValue": 428.52,
					"isLotBad":false,
					"reasonLotIsBad":"No reason, just because of you ",
					"gains": {
						"shortTermGains": 166.89,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 2
				},
				{
					"wouldWash": true,
					"openedOn": {
						"timestamp": 1631678400,
						"friendly": "03/29/2021 00:00:00"
					},
					"quantity": 14,
					"costPerShare": 101.22,
					"vspPrice": 101.22,
					"costBasis": 506.1,
					"currentPrice": 142.84,
					"currentValue": 714.2,
					"gains": {
						"shortTermGains": 208.1,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 3
				},
				{
					"wouldWash": false,
					"openedOn": {
						"timestamp": 1631678400,
						"friendly": "09/15/2021 00:00:00"
					},
					"quantity": 11,
					"costPerShare": 123.83,
					"vspPrice": 123.83,
					"costBasis": 123.83,
					"currentPrice": 142.84,
					"currentValue": 142.84,
					"gains": {
						"shortTermGains": 19.01,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 4,
					"isLotBad":true,
					"reasonLotIsBad":"No reason, just because or you "
				},
				{
					"wouldWash": false,
					"quantity": 35,
					"costPerShare": 101.22,
					"vspPrice": 101.22,
					"costBasis": 506.1,
					"currentPrice": 142.84,
					"currentValue": 714.2,
					"gains": {
						"shortTermGains": 208.1,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 5,
					"isLotBad": true,
					"reasonLotIsBad": "It's bad"
				},
				{
					"wouldWash": false,
					"openedOn": null,
					"quantity": 1,
					"costPerShare": 125.84,
					"vspPrice": 125.84,
					"costBasis": 125.84,
					"currentPrice": 142.84,
					"currentValue": 142.84,
					"gains": {
						"shortTermGains": 17,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 6
				},
				{
					"wouldWash": false,
					"openedOn": {
						"timestamp": 0,
						"friendly": "03/29/2021 00:00:00"
					},
					"quantity": 5,
					"costPerShare": 101.22,
					"vspPrice": 101.22,
					"costBasis": 506.1,
					"currentPrice": 142.84,
					"currentValue": 714.2,
					"gains": {
						"shortTermGains": 208.1,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 7
				},
				{
					"wouldWash": false,
					"openedOn": {
						"timestamp": 1634702400,
						"friendly": "10/20/2021 00:00:00"
					},
					"quantity": 2,
					"costPerShare": 133.01,
					"vspPrice": 133.01,
					"costBasis": 266.02,
					"currentPrice": 142.84,
					"currentValue": 285.68,
					"gains": {
						"shortTermGains": 19.66,
						"shortTermLosses": 0,
						"longTermGains": 0,
						"longTermLosses": 0
					},
					"lotSequenceId": 8,
					"isLotBad":true,
					"reasonLotIsBad":"No reason, just because or you ",
				}
			],
			"canHarvest": true,
			"accountingMethod": "FirstInFirstOut"
		}
	];
	
	var opportunitiesMock = [
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11",
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "0aab9c27-843d-43aa-888b-186b4b057812",
				"ticker": "FISV",
				"assetType": "equity",
				"companyName": "Fiserv, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "d1ae5432-9c6a-4790-a96c-e12cad3a8193",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": -53,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -951.8468,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"9aa2b606-2912-4c7c-82f9-010a3258cdcf",
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "65e07744-2ded-446a-9c09-2a2ebed415ba",
				"ticker": "IVV",
				"assetType": "equity",
				"companyName": "iShares Core S&P 500 ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "83007229-64b4-4921-8004-c3a75877d8cd",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": 26,
			"gains": {
				"shortTermGains": 698.3144,
				"shortTermLosses": -38.125,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "a66738f1-32b2-4244-916a-1378f41b33df",
				"ticker": "UPRO",
				"assetType": "equity",
				"companyName": "ProShares UltraPro S&P 500",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "2c8f1b3a-4114-4e83-ab6f-a28cdee9c106",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 1755,
			"gains": {
				"shortTermGains": 31387.122,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "d6dc947a-ac4f-4d61-91a3-f8156ca88296",
				"ticker": "INGR",
				"assetType": "equity",
				"companyName": "Ingredion, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "e3a2bf26-7abd-4a5e-a3fb-87787f50f2ac",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -82,
			"gains": {
				"shortTermGains": 1715.5466,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -398.9054
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "6d254a26-37af-4128-abfe-53139bd40f8e",
				"ticker": "CLH",
				"assetType": "equity",
				"companyName": "Clean Harbors, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "b55ed234-c18f-478f-852f-62c64ffa45ca",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -11,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -431.31,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11",
				"9aa2b606-2912-4c7c-82f9-010a3258cdcf"
			],
			"symbol": {
				"id": "b924806c-f03e-4877-aea8-c29d08e410cc",
				"ticker": "QUAL",
				"assetType": "equity",
				"companyName": "iShares Edge MSCI USA Quality Factor ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "2c4ee2ff-8b21-423a-8924-47d7ce2e9c29",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": 30,
			"gains": {
				"shortTermGains": 197.688,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "107f9a10-0426-45cb-bfaa-976d3ed620f4",
				"ticker": "TNA",
				"assetType": "equity",
				"companyName": "Direxion Daily Sm-Cap BUL",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "9a267c94-f160-4c4d-86a2-da9d556bcf6e",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 1697,
			"gains": {
				"shortTermGains": 21421.7401,
				"shortTermLosses": -3417.4187,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "dd6e52a8-5f95-4bec-901d-d1d628e7471a",
				"ticker": "OXY",
				"assetType": "equity",
				"companyName": "Occidental Petroleum Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "4d70cf84-4f72-4e97-839e-87afab0ebd71",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -346,
			"gains": {
				"shortTermGains": 15203.1708,
				"shortTermLosses": 0,
				"longTermGains": 599.791,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "6392725e-d757-496c-a4b7-5b060445a25b",
				"ticker": "DBI",
				"assetType": "equity",
				"companyName": "Designer Brands Inc.",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "11e0f490-e794-4748-983f-825e30412169",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -480,
			"gains": {
				"shortTermGains": 2143.584,
				"shortTermLosses": -458.4,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11",
				"9aa2b606-2912-4c7c-82f9-010a3258cdcf"
			],
			"symbol": {
				"id": "f947ea05-45e6-4c32-b8d6-b0042b2d9f86",
				"ticker": "USMV",
				"assetType": "equity",
				"companyName": "iShares Edge MSCI Min Vol USA ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "4a980b1c-5d36-4ec5-8473-59f4a4b5ac03",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": 16,
			"gains": {
				"shortTermGains": 3.213,
				"shortTermLosses": -19.0706,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "ac831aff-6e7c-4f4a-a4de-f37cc90e848f",
				"ticker": "SU",
				"assetType": "equity",
				"companyName": "Suncor Energy, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "91e46f05-9891-4474-8ed0-b3d2272fdee5",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -135,
			"gains": {
				"shortTermGains": 34.3036,
				"shortTermLosses": -433.8764,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "22a528d5-8d39-47d5-b9c3-9c992e59da48",
				"ticker": "DIA",
				"assetType": "equity",
				"companyName": "SPDR DJ Industrial Aver",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "47be6dc3-ac67-4441-819b-04118e8d6423",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -1585,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -43818.1174,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b",
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "01fd2874-5cfd-4150-8622-715ae40d8f63",
				"ticker": "FDX",
				"assetType": "equity",
				"companyName": "FedEx Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "1f2655f7-b98b-49f7-b889-a3419271dd13",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": -38,
			"gains": {
				"shortTermGains": 2775.658,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -37.6232
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"9aa2b606-2912-4c7c-82f9-010a3258cdcf",
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "9df655ba-1c61-4c1d-b1c6-80783da30e9d",
				"ticker": "IEMG",
				"assetType": "equity",
				"companyName": "iShares Core MSCI Emerging Markets ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "92800f28-5cf4-4c1d-ae97-895d83d0e9dc",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": 61,
			"gains": {
				"shortTermGains": 182.1142,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b",
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "0f265089-5712-42b6-ae99-5610fdd248ac",
				"ticker": "CCK",
				"assetType": "equity",
				"companyName": "Crown Holdings, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "0402b90c-3188-483f-aab7-31f512b01667",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": -116,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -1847.1092,
				"longTermGains": 0,
				"longTermLosses": -2429.72
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "f951b785-2dab-4233-a4a9-4a3b18ef8936",
				"ticker": "ADM",
				"assetType": "equity",
				"companyName": "Archer-Daniels Midland CP",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "6bc4185f-5574-4ff8-b214-e2e6982939c2",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -115,
			"gains": {
				"shortTermGains": 892.3886,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -464.577
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11",
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "9a0dc76e-7efa-4955-8500-5e9b753523b2",
				"ticker": "GPK",
				"assetType": "equity",
				"companyName": "Graphic Packaging Holding Co",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "e2c94362-1e6a-4e03-b58a-7359a4eadc6f",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": -503,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -1137.973,
				"longTermGains": 0,
				"longTermLosses": -2544.0692
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "cc9ec5c0-0a3c-4f1e-a120-4fb6e1db373c",
				"ticker": "K",
				"assetType": "equity",
				"companyName": "Kellogg Co",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "af3b4c79-2461-4350-9a7d-a28ec863fa79",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -76,
			"gains": {
				"shortTermGains": 346.0584,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -682.48
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "73f97628-1d29-4d03-8139-11c2ba020a41",
				"ticker": "BAC",
				"assetType": "equity",
				"companyName": "Bank of America Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "f567d1a5-e0e4-460f-9bee-3d12f187ae58",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -102,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -431.4498,
				"longTermGains": 0,
				"longTermLosses": -3339.99
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "88b4bdaf-eb44-43b8-b7d0-a37fa06640a0",
				"ticker": "FAF",
				"assetType": "equity",
				"companyName": "First American Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "f461e208-5a33-47dd-b8c9-3ca109473131",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -21,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -521.1024,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "7754eace-9276-4838-a0b9-510a05ca0ad6",
				"ticker": "TSN",
				"assetType": "equity",
				"companyName": "Tyson Foods, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "a4441621-202e-438a-b2a2-e6c421c957ba",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -42,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -105.8358,
				"longTermGains": 0,
				"longTermLosses": -1326.7884
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "9e850603-ea8b-4eb8-a437-c2cef4e7c755",
				"ticker": "ITE",
				"assetType": "equity",
				"companyName": "SPDR Barclays Intermediate TER",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "7e444295-ab26-45ec-9489-e21e4c1e1810",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -3495,
			"gains": {
				"shortTermGains": 1922.5996,
				"shortTermLosses": -10415.1,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "e8380b82-23da-4d56-abbf-f28b9b141557",
				"ticker": "KO",
				"assetType": "equity",
				"companyName": "Coca-Cola Co",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "22ff2be3-3134-4cf4-8c89-e97d74f7d3aa",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -229,
			"gains": {
				"shortTermGains": 407.9636,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -1507.713
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b",
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "2bd10237-0367-499c-a7a7-2b405372f607",
				"ticker": "LKQ",
				"assetType": "equity",
				"companyName": "LKQ Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "1d8c65d6-e713-4c03-8ae7-ad5c732edba3",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": -323,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -2373.7606,
				"longTermGains": 0,
				"longTermLosses": -2706.43
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "309f8489-f9f8-4474-8ab8-fec37b5c032a",
				"ticker": "PHYTX",
				"assetType": "mutualFund",
				"companyName": "Principal Fds, Inc. High Yield Fd Institutional Cl",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "c59c3b60-94d3-43ff-898b-1ab65b58f57a",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 6995.493,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -839.459,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "fd34ba4e-cc81-495f-bc42-d2973922eb04",
				"ticker": "STWD",
				"assetType": "equity",
				"companyName": "Starwood Property, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "03f653b3-a5e0-4413-b822-fd41878fa959",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -195,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -20.436,
				"longTermGains": 0,
				"longTermLosses": -481.65
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "ccde1d9b-6021-4063-bc69-5aed63f1b9de",
				"ticker": "PSA",
				"assetType": "equity",
				"companyName": "Public Storage",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "e98d9a38-9e4a-4138-8a44-5df36eb9361f",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -8,
			"gains": {
				"shortTermGains": 387.2408,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -133.1752
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "3ae3cdb0-e4d2-43a3-8f6a-839bff3f09ec",
				"ticker": "UDOW",
				"assetType": "equity",
				"companyName": "Proshares Ultrapro DOW 30",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "081e521a-3e50-4111-8340-9769928de792",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 961,
			"gains": {
				"shortTermGains": 30172.3248,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "c45e9724-1761-4601-9313-8fbff6501bc2",
				"ticker": "MCK",
				"assetType": "equity",
				"companyName": "McKesson Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "72050242-3f78-40be-bbed-6dcc379e6d9f",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 19,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -2.28,
				"longTermGains": 2754.43,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "1b015be2-8e22-427f-95c4-59a7ff1414ef",
				"ticker": "TQQQ",
				"assetType": "equity",
				"companyName": "ProShares UltraPro QQQ",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "e46b2b96-c2da-4d01-a5aa-8bdc6954ff6c",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 1499,
			"gains": {
				"shortTermGains": 36733.8942,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "366d5f07-73ae-427c-8533-5c9507f81c3a",
				"ticker": "BOCT",
				"assetType": "equity",
				"companyName": "Innovator S&P 500 Buffer ETF - October",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "735c4c6a-db97-433e-95fb-da2a48b00b6e",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 5607,
			"gains": {
				"shortTermGains": 8523.7612,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "dd0f41fe-d884-4eb7-9219-d2c0f93ae2e2",
				"ticker": "TROW",
				"assetType": "equity",
				"companyName": "T Rowe Price, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "96897c76-b082-4b2f-8e2a-d8345dbd6d9a",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -61,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -450.308,
				"longTermGains": 0,
				"longTermLosses": -1649.8182
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "def62ace-a7cd-459c-8c22-8095f7ab240a",
				"ticker": "KNX",
				"assetType": "equity",
				"companyName": "Knight-Swift Transportation Holdings, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "66743233-1a2d-4dd7-984a-e8cf9f023655",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -28,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -354.5612,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "f1c099fc-a690-4ea3-8aee-693ac57977b6",
				"ticker": "BLK",
				"assetType": "equity",
				"companyName": "BlackRock, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "4e628acc-1f5f-469b-8fb8-f88a4d08b923",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -16,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -360.7984,
				"longTermGains": 0,
				"longTermLosses": -1558.1888
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "1da4409d-6b6c-4c49-a3a8-8aba9a33cb15",
				"ticker": "IJH",
				"assetType": "equity",
				"companyName": "iShares Core S&P Mid-Cap ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "c5fab616-81cd-490a-8c0a-e64e41e0d8a6",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 3,
			"gains": {
				"shortTermGains": 50.0314,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "3dabd6e9-e147-4138-a100-c1f7ea6b2bc2",
				"ticker": "OHYFX",
				"assetType": "mutualFund",
				"companyName": "JPMorgan High Yield Fund I Class",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "0d305d92-cdc4-408b-9133-a0b5b278f280",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 6927.763,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -554.22086,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "d77fd88d-e3a2-489d-b596-2d1589c4cd7a",
				"ticker": "CMA",
				"assetType": "equity",
				"companyName": "Comerica, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "b5542e1b-e40c-4564-a26a-d32e21761f61",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -81,
			"gains": {
				"shortTermGains": 72.5922,
				"shortTermLosses": -559.2806,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "3b6bfeff-49a7-4da3-8f43-c650b9956b87",
				"ticker": "AGDYX",
				"assetType": "mutualFund",
				"companyName": "AB High Income Fund, Inc. - Advisor Class",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "cd4c52b8-5340-43d1-b49c-6a92e37e0426",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 6093.398,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -365.60396,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "0562c20e-b3fc-42f2-929d-491026f81fbd",
				"ticker": "IEFA",
				"assetType": "equity",
				"companyName": "iShares Core MSCI EAFE ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "d8b75f92-f4a9-4429-abc9-0cff962fe1b2",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 53,
			"gains": {
				"shortTermGains": 205.4438,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "6943c283-1568-44ec-87f3-eca31f98558d",
				"ticker": "IXN",
				"assetType": "equity",
				"companyName": "iShares Global Tech ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "7e157d30-e087-4630-804f-36a750a745ed",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 8,
			"gains": {
				"shortTermGains": 230.7312,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "0a8dacdf-0080-4b4f-a879-88dcdc5fa235",
				"ticker": "ROST",
				"assetType": "equity",
				"companyName": "Ross Stores, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "4e49cc9b-d637-4019-9bcc-7f6078d4efcf",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -151,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -3318.3004,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "454d7e14-5cb3-452b-9b26-af8369479423",
				"ticker": "BHYIX",
				"assetType": "mutualFund",
				"companyName": "BlackRock High Yield Bond Portfolio - Institutional",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "8a1e3fd1-ff3c-4465-b628-91e2d29fca74",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 6504.9,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -390.294,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "ef36fbe3-657d-49f7-9ee2-7c1ef8ddc0df",
				"ticker": "CHRW",
				"assetType": "equity",
				"companyName": "CH Robinson Worldwide, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "49cabb22-091b-4b36-9fca-430c272b3540",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -43,
			"gains": {
				"shortTermGains": 654.5504,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "d92e6531-50ab-45ee-997f-ff3fe72b638d",
				"ticker": "DLHRX",
				"assetType": "mutualFund",
				"companyName": "BNY Mellon High Yield Fund Class I",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "816194ea-c2ba-4bc9-b04a-1c9001d6cb3b",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 8170.918,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -490.2552,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "7df02743-3785-4769-ae17-e338006195cf",
				"ticker": "USB",
				"assetType": "equity",
				"companyName": "US Bancorp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "daf8e140-af9b-4409-b40a-83da6a5b93da",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -92,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -1092.04,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "4224c896-fcea-4cce-a337-f284d4559fb3",
				"ticker": "ITOT",
				"assetType": "equity",
				"companyName": "iShares Core S&P Total US Stock Market ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "b29c745f-6c7e-44bc-ad23-dc20a994a7a3",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 20,
			"gains": {
				"shortTermGains": 60.312,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11",
				"9aa2b606-2912-4c7c-82f9-010a3258cdcf"
			],
			"symbol": {
				"id": "8513a661-80e6-4b41-8f3c-8fa1a4858188",
				"ticker": "SIZE",
				"assetType": "equity",
				"companyName": "iShares Edge MSCI USA Size Factor ETF",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "f4534ae5-fc9a-4b5a-93d7-842c79c1a1a2",
				"isActive": true
			},
			"lotCount": 8,
			"areLotsValid": true,
			"quantity": 20,
			"gains": {
				"shortTermGains": 13.8,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "0e773637-9b64-40b6-8526-8e649a80578f",
				"ticker": "RYHGX",
				"assetType": "mutualFund",
				"companyName": "Rydex Sers Fds, High Yield Strategy Fund H Class",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "225a30c2-7cdf-47d6-b304-e1c9d8f4cefd",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 1989.187,
			"gains": {
				"shortTermGains": 716.10744,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "17cd8995-85b3-4649-91bc-480d13dfdd00",
				"ticker": "CAH",
				"assetType": "equity",
				"companyName": "Cardinal Health, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "5f10a5f8-209e-4f0f-944d-38b5243a43b4",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -89,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -96.12,
				"longTermGains": 0,
				"longTermLosses": -919.014
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "4c4ee63e-0bec-4f6b-ab26-ebf326095016",
				"ticker": "PHIYX",
				"assetType": "mutualFund",
				"companyName": "PIMCO Funds High Yield Fund Institutional Shares",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "7f826c0b-fe79-4016-852b-80c34a6bbf8e",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 5602.654,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -224.10624,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "c7dbc972-421d-41cf-8730-7386e2665b88",
				"ticker": "MRTN",
				"assetType": "equity",
				"companyName": "Marten Trans",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "1a0bf788-4cf1-4c3c-9031-85dbbe939089",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -26,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -196.2168,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"0b6eb36a-5fdd-4e5c-9f60-01932f374a11"
			],
			"symbol": {
				"id": "6dc135be-6b42-43da-b445-62762bbf4455",
				"ticker": "SKY",
				"assetType": "equity",
				"companyName": "Skyline Champion Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "23bc4e4b-59ce-416c-93a9-4c45fe6bdb85",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -20,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -108.138,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "2502e03d-e077-4e8d-8cd6-b0d40e97709e",
				"ticker": "NHILX",
				"assetType": "mutualFund",
				"companyName": "Neuberger Berman High Income Bond Fund Institutional  Class",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "9ad5d372-e2c0-4ccd-94c5-aca5d093810a",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 5851.37,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -351.0822,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "d2a42e8c-4e3b-4e7a-a89a-e83af9f9657e",
				"ticker": "KHYIX",
				"assetType": "mutualFund",
				"companyName": "DWS High Income Fund - Class Institutional",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "bee6e93b-e40d-462c-8289-ceb9d0b47822",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 10500.572,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -210.01136,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "47ef50c7-d225-4724-9043-3f1445cedd06",
				"ticker": "RF",
				"assetType": "equity",
				"companyName": "Regions Financial Corp",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "bcbd3795-8114-4cba-87d6-d133362263a6",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -324,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -1194.7824,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "6d295e7d-80e8-4f63-b596-fd6a31bb36f3",
				"ticker": "PHYZX",
				"assetType": "mutualFund",
				"companyName": "PGIM High Yield Fund Class Z",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "7c3ee9f6-a0fd-46b8-a33b-c0e2cddf0a15",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": 9090.332,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -363.61344,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "7e69aa8a-ec81-427c-a160-3ccdd758a1f3",
				"ticker": "SPY",
				"assetType": "equity",
				"companyName": "SPDR S&P 500",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "1ecea2ad-6c10-448a-8a54-f3f417ad0b3e",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -1432,
			"gains": {
				"shortTermGains": 0,
				"shortTermLosses": -48040.3064,
				"longTermGains": 0,
				"longTermLosses": 0
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "14d84d50-8b4f-4bb6-903e-5971fde01d97",
				"ticker": "TJX",
				"assetType": "equity",
				"companyName": "TJX Companies, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "2d7cbfba-4454-427e-9434-1e5a1becc19b",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -331,
			"gains": {
				"shortTermGains": 17034.4186,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -5102.365
			},
			"containsOptions": false
		},
		{
			"accountIds": [
				"88d20aa8-ff0c-4208-93ee-0134f72b160b"
			],
			"symbol": {
				"id": "2138a9bf-c09c-4383-bc32-d4bf77959dce",
				"ticker": "VTR",
				"assetType": "equity",
				"companyName": "Ventas, Inc",
				"industry": "unknown",
				"multiplier": 1,
				"defaultSymbolAliasId": "74203cc2-cad7-4797-8839-e6af999994c9",
				"isActive": true
			},
			"lotCount": 4,
			"areLotsValid": true,
			"quantity": -48,
			"gains": {
				"shortTermGains": 750.2976,
				"shortTermLosses": 0,
				"longTermGains": 0,
				"longTermLosses": -234.9648
			},
			"containsOptions": false
		}
	];
	
	function getMockData(data, loadTime){
		return new Promise(function(resolve){
			$timeout(function() { resolve(data); }, loadTime);
		});
	}

	var factory = {
		getTaxGroups: function(){
			return taxHarvestingApi.groups.get();
		},
		createTaxGroup: function(payload){
			return taxHarvestingApi.groups.create(payload);
		},
		updateTaxGroup: function(id, payload){
			return taxHarvestingApi.groups.update(id, payload);
		},
		deleteTaxGroup: function(id){
			return taxHarvestingApi.groups.delete(id);
		},
		getHarvestRules: function(id){
			if (id) return taxHarvestingApi.rules.get(id);
			else return taxHarvestingApi.rules.get();
		},
		createHarvestRule: function(payload){
			return taxHarvestingApi.rules.create(payload);
		},
		updateHarvestRule: function(id, payload){
			return taxHarvestingApi.rules.update(id, payload);
		},
		deleteHarvestRule: function(id){
			return taxHarvestingApi.rules.delete(id);
		},
		getGroupAccounts: function(id){
			// return taxHarvestingApi.groups.accounts(id)
			return taxHarvestingApi.accounts.get(id);
		},
		addAccountsToGroup: function(id, payload){
			// return taxHarvestingApi.groups.addAccountToGroup(id, payload)
			return taxHarvestingApi.accounts.add(id, payload);
		},
		updateGroupAccount: function(groupId, payload, accountId){
			// return taxHarvestingApi.groups.updateGroupAccount(groupId, accountId, payload)
			return taxHarvestingApi.accounts.update(groupId, payload, accountId);
		},
		deleteAccountFromGroup: function(groupId, accountId){
			return taxHarvestingApi.accounts.delete(groupId, accountId);
		},
		getHarvestOpportunitiesForGroup: function(id){
			//return getMockData({data: {data: opportunitiesMock}}, 0 );
			return taxHarvestingApi.groups.opportunities(id);
		},
		getOpportunitiesForSymbol: function(id, symbolId){
			//return getMockData({data: {data: symbolLotMock}}, 0 );
			return taxHarvestingApi.groups.opportunities(id, symbolId);
		},
		submitHarvestTrades: function(payload){
			return taxHarvestingApi.groups.harvest(payload);
		},
		getTaxLots: function(id, status){
			if (status) {

				return taxHarvestingApi.groups.lots(id, {status: status});
			} else {
				return taxHarvestingApi.groups.lots(id);
			}
		},
		getSubstitutions: function(groupId){
			return taxHarvestingApi.groups.substitution(groupId);
		},
		createSubstitutions: function(groupId, payload){
			return taxHarvestingApi.groups.substitution(groupId, payload);
		},
		updateSubstitutions: function(groupId, payload, substitutionId){
			return taxHarvestingApi.groups.substitution(groupId, payload, substitutionId);
		},
		getHarvestRequests: function (params) {
			return taxHarvestingApi.groups.getHarvestRequests(params);
		},
		createTaxLotHarvestExclusion: function (payload) {
			return accountApi.createTaxLotHarvestExclusion (payload);
		},
		getHarvestSummary: function (id) {
			return taxHarvestingApi.groups.getHarvestSummary(id);
		},
		getHarvestDetails: function (id) {
			return taxHarvestingApi.groups.getHarvestDetails(id);
		},
		getHarvestRequestDetails: function (params) {
			return taxHarvestingApi.groups.getHarvestRequestDetails(params);
		},
		accountTypeCanHarvest: function(account){
			account = account.account ? account.account : account;
			return account.accountType.toLowerCase().indexOf('ira') === -1;/*  && account.accountType.indexOf('trustTestamentary') === -1 */
		}		
	};

	return factory;
}]);