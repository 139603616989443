angular.module('SubstitutionsCtrl',  [])
.directive('onEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.onEnter);
                });

                event.preventDefault();
            }
        });
    };
})
.controller('SubstitutionsCtrl', function($scope, $http, NgTableParams, Dashboard, PortfolioEditorFactory, toastr, DRUPAL_API_URL){
	var vm = this;

	vm.title = "Security Master";

	var securities = [{'ticker': 'FB'}, {'ticker': 'AMZN'}, {'ticker': 'NFLX'}, {'ticker': 'GOOGL'}];

	vm.tableParams = new NgTableParams({}, {
      dataset: securities
    });

    vm.getSymbolSearchResults = getSymbolSearchResults;
    vm.onSymbolSelected = onSymbolSelected;
    vm.onEnter = onEnter;
    vm.saveChanges = saveChanges;
    
    init();

    function saveChanges(){

    	var api = `${DRUPAL_API_URL}/ng_api/v2/`;
    	var url = api + 'securityMaster';

    	$http.post(url, {securities: 'some object with selected securities would go here <-'})
    	.then(function(res){
    		console.log(res);
    		toastr.success("Settings succesfully saved");
    	})
    	.catch(function(err) {
    		console.log(err);
    		var message;
    		toastr.success("Error saving settings");
    	});
    }

    function getSecurityMaster(){
    	var api = `${DRUPAL_API_URL}/ng_api/v2/`;
    	var url = api + 'securityMaster';
    	$http.get(url)
    	.then(function(res) {
    		console.log(res);
    	})
    	.catch(function(err) {
    		console.log(err);
    	});
    }

    function onEnter($item){
    	console.log($item);
	  	// onSymbolSelected runs before this so selectedSecurity should be set
	  	console.log("On enter selected security: ", vm.selectedSecurity);
	}

    function init(){

    	vm.loadingAccounts = true;
    	Dashboard.getAccountsSummaryByStatus('active')
    	.then(function(res){
    		console.log(res);
    		vm.loadingAccounts = false;
    	})
    	.catch(function(err){
    		console.log(err);
    		vm.loadingAccounts = false;
    	});

    	getSymbolUniverse();
    	getSecurityMaster();
    }

    function getSymbolUniverse() {

        vm.isLoadingAssets = true;
        PortfolioEditorFactory.getSymbols('*', 'equity,mutual fund')
          .then(function(response) {

            if (typeof response.data == 'undefined' || response.data.length == 0) {
              debugger;
              dataFailure(response.data, getSymbolUniverse);
              return false;
            }

            if (response.data.length > 0) {

              PortfolioEditorFactory.saveSymbols(response.data);
              vm.symbols = response.data;
              console.log('Symbol Universe', response.data);
              vm.isLoadingAssets = false;
            }
          })
          .catch(function(response) {
            // vm.loading = false;
            
            //dataFailure('', getSymbolUniverse);
          });
    }

    function onSymbolSelected($item, $model, $label) {
        console.log("item: ", $item);
        console.log("model: ", $model);
        console.log("label: ", $label);

        vm.selectedSecurity = $item;
     }

    function getSymbolSearchResults(term) {
        vm.loadingSymbols = true;
        var len = term.length;
        var exactMatch;
        if (len) {

          var matches = _.filter(vm.symbols, function(symbol) {
            var match = false;

            if (symbol.AssetName.toLowerCase() == term.toLowerCase()) {
              exactMatch = symbol;
              match = true;
            } else if (len > 1 && symbol.AssetName.toLowerCase().slice(0, len) == term.toLowerCase()) {
              match = true;
            } else if (len > 2 && symbol.Company.toLowerCase().indexOf(term.toLowerCase()) != -1) {
              match = true;
            }

            return match;
          });

          if (typeof exactMatch != 'undefined') {
            var idx = matches.indexOf(exactMatch);
            matches.move(idx, 0);
          }


          return matches;
        } else {
          vm.loadingSymbols = false;
          return [];
        }
    }
});