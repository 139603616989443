angular.module('Interceptors', [])
.factory('ApiResponseInterceptor', ['$q', '$injector', '$timeout', '$rootScope', function($q, $injector, $timeout, $rootScope){

  var retries = {},
      waitBetweenErrors = 1000,
      maxRetries = 3;

  var interceptor = {
    'responseError': function(response){
      console.error(response);
      return response;
    },
    'response' : function(response){

      var url = response.config.url;

      // only pay attention to responses coming from api calls
      if (url.indexOf('api') !== -1){

        // console.log("API Response received from: ", url);
        // console.log("Response: ", response);

        // checking the method because DELETE returns an empty string response when it is successful
        if (typeof response.data == 'string' && (response.config.method != 'DELETE' && response.config.method != 'PUT')){

          console.log("response is of type string. attempting to parse into json...");
          try {
            var parsedResponse = JSON.parse(response.data);
            console.log("parsed response: ", parsedResponse);
          } catch (e) {
            console.log("string response not parsable json");
          }
        }

        if (response.data && response.data.token && response.data.token.status == 'failed'){
          var tokenFailed = true;
          console.log(response);
          // debugger
          $rootScope.$broadcast('token-expired');
        }

        if ((response.config.method != 'DELETE' && response.config.method != 'PUT') && ((response.data == null || response.data == undefined || response.data == "") ||
                    response.data.error == "invalid_request" ||
                    tokenFailed ||
                    (response.data.msg && response.data.msg.error) ||
                    (response.data && response.data.status == 'error') ||
                    (typeof response.data == 'string'  &&  typeof parsedResponse != 'object'))
                  ){

          // debugger

          var toastr = $injector.get('toastr');

          if (!retries[response.config.url]){
            retries[response.config.url] = 0;
          }

          if (retries[response.config.url] < maxRetries){

            //toastr.info("invalid request. retrying...");

            var $http = $injector.get('$http');

            return $timeout(function(){

              retries[response.config.url]++;
              return $http(response.config);

            }, waitBetweenErrors);
          }

          toastr.error("Request failed after multiple attempts.");
          delete retries[response.config.url];

          return $q.reject(response);
        }

        if (response.data.msg == "A valid guid and access token must be provided"){
         var tokenFailed = true;
         $rootScope.$broadcast('token-expired');
       }
      }else if (response.data.msg == "A valid guid and access token must be provided"){
        var tokenFailed = true;
        console.log(response);
        $rootScope.$broadcast('token-expired');
      }

      return response;
    }
  };

  return interceptor;
}])
.factory('timeoutHttpIntercept', function ($rootScope, $q, $injector, $timeout, HTTP_REQUEST_TIMEOUT) {
  return {
    'request': function(config) {

      // https://stackoverflow.com/questions/21915834/angular-http-setting-a-promise-on-the-timeout-config
      if (!config.timeout){
        config.timeout = $timeout(function(){
                          config.timedOut = true;
                        }, HTTP_REQUEST_TIMEOUT, false); // timeout set in a .value in app.js
      }
      return config;
    },
    'responseError': function(rejection) {

      if (rejection.config){
        if(rejection.config.timedOut){
            var toastr = $injector.get('toastr'); // hack to get around circular dependency error
            toastr.error('The request timed out. Please try again.');
        }
      }

      return $q.reject(rejection);
    },
    'response': function(response) { // cancel the timeout function if response is returned in time
      $timeout.cancel(response.config.timeout);
      return response;
    }
  };
});
