angular.module('TargetPortfolios')
.value('TargetMock', {
    "isUsed": false,
    "isDefault": false,
    "ownerUserId": "c17deb58-c49c-4e34-8155-bcd8e2d39bf6",
    "enterpriseTargetWeightGroupsCount": 0,
    "threshold": {
      "upper": 0.2,
      "lower": 0.2
    },
    "precision": {
      "upper": 0.04,
      "lower": 0.04
    },
    "createdBy": "testuser001",
    "id": "e822008d-80ac-420c-b141-7a5f72c48861",
    "name": "Low Risk S&P Beater + Sectors",
    "enterprise": {
      "id": "1689a71e-f29e-4e76-a554-bef0fdbc84e5",
      "name": "Test Advisors"
    },
    "accessLevel": "fullAccess",
    "$$hashKey": "object:1393",
    "editable": true,
    "details": [
      {
        "id": "8f500773-4571-417e-a25b-95c7fa974164",
        "target": {
          "id": "b824b553-4bb6-42bc-877a-026580fd8083",
          "name": "Sectors",
          "type": "targetWeightGroup",
          "matchModelLeverage": false,
          "ticker": "",
          "isNew": false
        },
        "percent": 0.1,
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        },
        "details": [
          {
            "id": "6dad25ed-ba33-4851-9c3c-9532130ff1fe",
            "target": {
              "name": "$CASH",
              "type": "cash",
              "ticker": ""
            },
            "percent": 0,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            }
          },
          {
            "id": "56f9791f-4fae-407f-b117-520535d57d1f",
            "target": {
              "id": "51ad0688-fda1-41e3-9595-16095045a455",
              "name": "Energy Sector",
              "type": "targetWeightGroup",
              "matchModelLeverage": false,
              "ticker": ""
            },
            "percent": 0.5,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            },
            "details": [
              {
                "id": "7e311db0-edd3-44ce-a07d-2c2fb992ae4c",
                "target": {
                  "name": "$CASH",
                  "type": "cash",
                  "ticker": ""
                },
                "percent": 0,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              },
              {
                "id": "1d2463ce-1194-4c72-a3e8-5dad9f353e18",
                "target": {
                  "id": "8a33d6ff-049e-4843-8886-4971b788c7f7",
                  "name": "Fossil Free Indexes Energy Transition Long-Short",
                  "type": "model",
                  "leverage": 1,
                  "matchModelLeverage": true,
                  "ticker": ""
                },
                "percent": 0.5,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              },
              {
                "id": "ad9b39b0-2511-42ea-8310-87ea61ba671a",
                "target": {
                  "id": "d803f567-ec4b-41ff-b418-14481216a1c2",
                  "name": "Capital Innovations Energy Infrastructure",
                  "type": "model",
                  "leverage": 1,
                  "matchModelLeverage": true,
                  "ticker": ""
                },
                "percent": 0.5,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              }
            ]
          },
          {
            "id": "c7684208-89ce-4f6e-876e-d18db24d31be",
            "target": {
              "id": "7518fd67-6193-4a2b-aa9a-385df7674d4f",
              "name": "Financials",
              "type": "targetWeightGroup",
              "matchModelLeverage": false,
              "ticker": ""
            },
            "percent": 0.5,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            },
            "details": [
              {
                "id": "c1f12159-9a01-4cf6-a7b8-accf19e5904f",
                "target": {
                  "id": "ffe02ad1-f46b-424d-b9cc-036799c3969a",
                  "name": "Integre All Cap Domestic Equity",
                  "type": "model",
                  "leverage": 1,
                  "matchModelLeverage": true,
                  "ticker": ""
                },
                "percent": 0.5,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              },
              {
                "id": "9726d0d5-7a92-4896-9722-ac82c0a0583d",
                "target": {
                  "name": "$CASH",
                  "type": "cash",
                  "ticker": ""
                },
                "percent": 0,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              },
              {
                "id": "baf2c07e-716f-44d7-a1c7-ab18e5bb9c84",
                "target": {
                  "id": "b32f5096-9b08-42d9-b953-7eeb3166940a",
                  "name": "FastINDX ESG Financials Index  ",
                  "type": "model",
                  "leverage": 1,
                  "matchModelLeverage": true,
                  "ticker": ""
                },
                "percent": 0.5,
                "threshold": {
                  "upper": 0.2,
                  "lower": 0.2
                },
                "precision": {
                  "upper": 0.04,
                  "lower": 0.04
                }
              }
            ]
          }
        ]
      },
      {
        "id": "106687bc-9c6d-4a20-8bbd-0aad1e0f5f44",
        "target": {
          "name": "$CASH",
          "type": "cash",
          "ticker": "",
          "isNew": false
        },
        "percent": 0,
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        }
      },
      {
        "id": "01754c3b-5477-493e-9d27-88f18d6efe51",
        "target": {
          "id": "a8cf1918-04e0-4ba5-97c9-02e89f2c6800",
          "name": "Bio-Pharma",
          "type": "targetWeightGroup",
          "matchModelLeverage": false,
          "ticker": "",
          "isNew": false
        },
        "percent": 0.1,
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        },
        "details": [
          {
            "id": "7a34a2e7-aa5b-49d0-9ce7-32a1c4216f50",
            "target": {
              "name": "$CASH",
              "type": "cash",
              "ticker": ""
            },
            "percent": 0,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            }
          },
          {
            "id": "81073fd2-9ad3-4e79-bb48-38f9cfd94690",
            "target": {
              "id": "0a51fe2a-0f44-42e3-b0ef-4cd3a72f09a1",
              "name": "SMArtX Pharmaceuticals L",
              "type": "model",
              "leverage": 1,
              "matchModelLeverage": true,
              "ticker": ""
            },
            "percent": 0.5,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            }
          },
          {
            "id": "26b9481e-090c-457e-a802-225482287f27",
            "target": {
              "id": "48a504e9-6361-4f88-8d4e-2cb4c6aecd17",
              "name": "SMArtX Biotech L",
              "type": "model",
              "leverage": 1,
              "matchModelLeverage": true,
              "ticker": ""
            },
            "percent": 0.5,
            "threshold": {
              "upper": 0.2,
              "lower": 0.2
            },
            "precision": {
              "upper": 0.04,
              "lower": 0.04
            }
          }
        ]
      },
      {
        "id": "562ec8c7-812e-4e47-8e1a-56942a9e280c",
        "target": {
          "id": "25e1cd02-2507-40e4-a6fd-331a43e5b1b7",
          "name": "Alpha Vee Risk Managed Allocation - Top 5 Sector & Treasury Bonds Index",
          "type": "model",
          "leverage": 1,
          "matchModelLeverage": true,
          "ticker": "",
          "isNew": false
        },
        "percent": 0.6,
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        }
      },
      {
        "id": "dcaac551-7e97-4862-aab1-7157cbd9a685",
        "target": {
          "id": "2c6a1e6a-95f3-429c-8970-6464f1dcdc64",
          "name": "Kensington Managed Income",
          "type": "model",
          "leverage": 1,
          "matchModelLeverage": true,
          "ticker": "",
          "isNew": false
        },
        "percent": 0.2,
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        }
      }
    ],
    "models": [
      {
        "percent": 0.6,
        "target": {
          "id": "25e1cd02-2507-40e4-a6fd-331a43e5b1b7",
          "name": "Alpha Vee Risk Managed Allocation - Top 5 Sector & Treasury Bonds Index",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.2,
        "target": {
          "id": "2c6a1e6a-95f3-429c-8970-6464f1dcdc64",
          "name": "Kensington Managed Income",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.05,
        "target": {
          "id": "48a504e9-6361-4f88-8d4e-2cb4c6aecd17",
          "name": "SMArtX Biotech L",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.05,
        "target": {
          "id": "0a51fe2a-0f44-42e3-b0ef-4cd3a72f09a1",
          "name": "SMArtX Pharmaceuticals L",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.025,
        "target": {
          "id": "8a33d6ff-049e-4843-8886-4971b788c7f7",
          "name": "Fossil Free Indexes Energy Transition Long-Short",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.025,
        "target": {
          "id": "d803f567-ec4b-41ff-b418-14481216a1c2",
          "name": "Capital Innovations Energy Infrastructure",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.025,
        "target": {
          "id": "b32f5096-9b08-42d9-b953-7eeb3166940a",
          "name": "FastINDX ESG Financials Index  ",
          "type": "model",
          "leverage": 1
        }
      },
      {
        "percent": 0.025,
        "target": {
          "id": "ffe02ad1-f46b-424d-b9cc-036799c3969a",
          "name": "Integre All Cap Domestic Equity",
          "type": "model",
          "leverage": 1
        }
      }
    ]
})
.controller('TargetPortfoliosCtrl', function(TargetMock, $modal, SmartXFactory, Portfolios, $scope, $interval, $rootScope, $timeout, toastr, Tabs, Dashboard, Models, SweetAlert, Builder, $q, TargetWeightFactory){
    //  ("creating new main controller");
    var parentTab = $scope.$parent.tab;
    var lastSelectedAccount = parentTab.selectedAccount;
    var tablist = Tabs;
    var tabType = $scope.tab.type;
    var performanceApi = SmartXFactory.getPerformanceAPI();
    var account,
        chart;

    console.log("Tab Data: ", $scope.tab);

    $scope.pieChartTimestamp = Date.now();

    $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12"><p class="text-center">Please Wait<p><h3 class="text-center">Loading Your Account...</h3></div>';
    $scope.tabIndex = parentTab.index;
    $scope.refreshing = false;
    $scope.isNewAccount = false;
    //$scope.errorLoadingAccount = false;
    $rootScope.showingWelcome = false;
    $scope.multiAccount = {
        selectedAccounts : []
    };
    $scope.parentTab = parentTab;
    $scope.chartLoaded = false;
    $scope.disabled = false;
    $scope.windowPresent = true;
    $scope.performanceStatsTimeframe = "Daily";
    $scope.portfolioNames = [];

    $scope.portfolioChart = {
        title: {
          text: null,
          style: {
            display: 'none'
          }
        }
    };
    $scope.showSimulatedPortfolio = false;

    $scope.minPopover = {
      isOpen: false,
      title: '',
      content: 'Our algorithm takes the minimum allocation value of each model and the percentage contribution to your portfolio to create the minimum investment size of the portfolio.',
    };

    jQuery('html').on('click', function(e) {
      var $element = jQuery(e.target);
      if (!$element.hasClass('popover-trigger') && !$element.hasClass('popover-content')) {
        $timeout(function() {
          $scope.minPopover.isOpen = false;
        });
      }
    });

    var reportedModelList = Models.getReportedModelList();
    var modelList = Models.getModelList();
    var modelSummaries = Dashboard.getModelSummaries();
    var updates;
    var updateInterval = 60 * 1000;

    var activeTab = Tabs.getActiveTab();
    var parentTab = $scope.$parent.tab;

    Tabs.setActiveTab(activeTab);
    Tabs.setLastActive(activeTab);

    // when a new instance of this controller is created
    // setLastDashboard to this
    Tabs.setLastDashboard(parentTab);

    $scope.lastRefresh = new Date().getTime() / 1000;
    
    //$scope.benchmarks = Builder.benchmarks;
    //$scope.selectedBenchmark = 'BF6BE7BA-0CE1-40A9-8225-2354841D601C';

    $scope.benchmark = {
      value: 'BF6BE7BA-0CE1-40A9-8225-2354841D601C',
      options: Builder.benchmarks
    };

    $scope.chartTimeframe = {
        options : ['1-yr', '3-yr', '5-yr', '10-yr', 'Since Inception'],
        selected : '1-yr'
    };

    $scope.timeframes = {
        "Since Inception" : '-1',
        // "Since HCV Daily" : '-1',
        // "Since Portfolio Creation" : undefined,
        // "YTD" : moment(new Date('1/1/ ' + moment().year())).unix(),
        // "MTD" : moment( new Date( (moment().month() + 1) + '/1/' + moment().year() ) ).unix(),
        // "1-yr": moment(new Date("02-05-2022")).subtract(1, 'years').unix(), // testing
        "1-yr": moment(new Date()).subtract(1, 'years').startOf('month').unix(),
        "3-yr": moment(new Date()).subtract(3, 'years').startOf('month').unix(),
        "5-yr": moment(new Date()).subtract(5, 'years').startOf('month').unix(),
        "10-yr": moment(new Date()).subtract(10, 'years').startOf('month').unix()
    };

    $scope.chartViewToggle = {
        options : ['Aggregate', 'Individual'],
        selected : 'Aggregate' // toggle state is tied to this value
    };

    $scope.loaderMsg = "Loading Performance...";

    $scope.$parent.tab.lastActive = new Date().getTime() / 1000;

    /* events */

    $scope.$on('tabSwitched::' + tabType, function(event, tab){ // code block will run on every tab switch
        var switchTime = new Date().getTime() / 1000;

        var title = tab.title;

        if (tab.index === parentTab.index) {

            if (tab.type === 'target-portfolio'){

                if($scope.portfolioChartInstance){
                    $timeout(function(){
                            $scope.portfolioChartInstance.reflow();
                    }, 100);
                }

                if ($scope.chart_data){
                    $timeout(function(){
                        drawChart($scope.chart_data);
                    }, 100);
                }
            }

            // this is true if we switched to an existing account tab
            if (tab.type === 'main'){

                var titles = $scope.accounts.map(function(account){
                    if (angular.isDefined(account.accountInfo)){
                        return account.accountInfo.title;
                    }
                });

                Dashboard.currentAccount = $scope.accounts[titles.indexOf(title)];
                Tabs.setLastDashboard(tab);

                var lastDash = Tabs.getLastDashboard();

                // this will be run only for the active tab
                killTimer();
                var lastActive = Tabs.getLastActive();
                    activeTab = Tabs.getActiveTab();

                Tabs.setLastActive(activeTab);

                switchTime = new Date().getTime() / 1000; // save the time

                // don't do anything if we're already processing an update
                if (!$scope.refreshing && !$scope.loading){

                    // check to see if one minute has elapsed since the last time this tab was switched to
                    if (tab.type === 'main'){

                        var elapsedSeconds = switchTime - tab.lastActive;

                        // if so, refresh it
                        if ( elapsedSeconds >= 30){

                            if ($scope.visited){
                                tab.lastActive = switchTime;
                                $scope.refresh(false);

                            } else {
                                $scope.visited = true;
                                startRefreshTimer();
                                tab.lastActive = switchTime;
                            }

                            var lastTab = Tabs.setLastActive(tab);


                        // otherwise set its last active time to the current time and start its refresh timer
                        } else {

                            if (!tab.new){
                                if (parentTab.updates){
                                    killTimer();
                                    startRefreshTimer();
                                } else {
                                    startRefreshTimer();
                                }
                            }

                            tab.lastActive = switchTime;
                        }
                    } else {

                    }

                } else {
                    startRefreshTimer(); // restart the timer
                }
            }
        } else {

            //this is true if we added a new account tab
            if (tab.new && tab.type === 'main'){
                //
                killTimer();
                tab.lastActive = switchTime;
                delete tab.new;
                $scope.visited = true;
                startRefreshTimer();
            } else {
                //console.log("not in the parent");
                //console.log("active: ", activeTab);
                //console.log("parent: ", parentTab);
                //console.log("tab from event: ", tab);
            }
        }
    });

    $scope.$on('tabAdded', function(event){

        if (parentTab.updates){
            killTimer();
        } else {
            if (parentTab.active){
                startRefreshTimer();
            }
        }
    });

    $scope.$on('chartError', function(event, msg){

        if (parentTab.active){
            $scope.loading = false;
            //$scope.errorLoadingAccount = false;
            $scope.chartError = true;
            killTimer();
        } else {
            $scope.chartError = true;
        }

    });

    $scope.$on('portfolioDeleted', function(event, portfolio){

        // TODO: delete the name from the the dropdown
        // change to / another one if it exists
        // or show the empty state if there aren't any to switch to
        var portfolioIndex = $scope.portfolios.map(function(portfolio){ return portfolio.name; }).indexOf(portfolio.title);
        $scope.portfolios.splice(portfolioIndex, 1);

        $scope.deleteCurrentPortfolio(portfolio, 'builder');
    });

    $scope.$on('portfolioCreated', function(event, portfolio){
        
        //console.log(portfolio);
        // TODO: add the name to the dropdown
        // load the data for the newly created one
        // set the selectedPortfolio to the new one
        // put it into the right format, push it to the portfolios array

        $scope.portfolios.push(portfolio);
        if ($scope.portfolios.length === 1){
            $scope.setPortfolio(portfolio);
            $timeout(function(){
                $scope.changeAccount();
            });
        }

    });

    /* events */

    var redrawPie = _.debounce(function(){

      drawChart($scope.chart_data);

    }, 300);

    function onResize () {
        if ($scope.portfolioChart && $scope.$parent.tab.active){
            redrawPie();
        }
    }

    // make the pie chart responsive
    angular.element(window).on('resize', onResize);

    $scope.updateBenchmark = function () {
      console.log($scope.benchmark.value);
    };

    $scope.$on('$destroy', function(){
        // remove the event listener
        angular.element(window).off('resize', onResize);
    });


    /* event handlers */

    $scope.addTab = function(type){
        // this is a reference to $scope

        Tabs.addTab(type);

        if (type === 'holdings'){
            Dashboard.currentAccount = this.selectedAccount;
            //tab = Tabs.addTab(type);
            Dashboard.getObserverCallbacks();
            Dashboard.notifyObservers('main');
            return;

        } else if (type === 'liquidate'){
            Dashboard.selectedLiquidationModel = this.model.fund_profile;
            Dashboard.selectedLiquidationAccount = $scope.selectedAccount;
            Dashboard.notifyObservers('main-liquidate', $scope.allocations);

        } else if (type === 'allocate'){
            Dashboard.selectedAllocationModel = this.model.fund_profile;
            Dashboard.selectedAllocationAccount = $scope.selectedAccount;
            Dashboard.notifyObservers('main');
        }

        //Tabs.addTab(type);
    };

    $scope.addTabForModel = function(model){

        if (typeof model != 'undefined') {
            // var profile = model.fund_profile;
            var modelId = model.target.id;
            model = _.find(Models.getModelList(), {id: modelId});

            try {
                var reportedModelId = model.reportedModelId; // profile.field_fund_model_guid.und[0].value.toLowerCase();
                var smartxGuid = model.id; // profile.field_smartx_model_guid.und[0].value.toLowerCase();
            } catch (e){
                console.error(e);
            }

            model.id = smartxGuid;
            model.reportedModelId = reportedModelId;
            // model.name = profile.title;

            Models.currentModel = model;
            Models.currentModel.guid = model.reportedModelId;

            Tabs.addTab('model');

        }
    };

    $scope.setPortfolio = function(portfolio){
        $scope.selectedPortfolio = portfolio;
    };

    $scope.changeAccount = function(refreshing){

        // kill any leftover timers
        if (parentTab.updates){
            //killTimer();
        }

        $scope.loading = true;

        $scope.batch = $scope.selectedPortfolio.models ? $scope.selectedPortfolio.models : $scope.selectedPortfolio ;
        getOnePortfolio();
    };

    $scope.refresh = function(){
        $scope.changeAccount(true);
    };

    $scope.toggleChartView = function(){
        if ($scope.chartViewToggle.selected === 'Individual'){
            $scope.showIndividualModels();
        } else if ($scope.chartViewToggle.selected === 'Aggregate'){
            $scope.showAggregate();
        }
    };

    $scope.showIndividualModels = function(){

        // loop through the chart series
        // if it is an individual model series, set its visible property to true
        $scope.portfolioChart.series.forEach(function(series){
          if (series.id !== 'Benchmark'){
            if (series.individual){
              series.visible = true;
            } else if (!series.individual) {
              series.visible = false;
            }
          }
        });
    };

    // TODO: merge these two methods into one
    $scope.showAggregate = function(){

        // loop through the chart series
        // if it is an aggregate model series, set its visible property to true
        $scope.portfolioChart.series.forEach(function(series){
          if (series.id !== 'Benchmark'){
            if (series.individual){
              series.visible = false;
            } else if (!series.individual) {
              series.visible = true;
            }
          }
        });
    };

    $scope.togglePerformanceTimeframe = function(timeframe){

        var chartView = $scope.chartViewToggle.selected;
        if (timeframe === "All Time"){
            $scope.performanceStatsTimeframe = "Monthly";
        } else {
            $scope.performanceStatsTimeframe = "Daily";
        }
        $scope.startTime = $scope.timeframes[timeframe];
        handlePortfolioData($scope.portfolioPerformanceResponse, $scope.portfolioData, $scope.portfolioModels, $scope.startTime, chartView);
    };


    $scope.editCurrentPortfolio = function(){
        $scope.addTab('portfolio-builder');
        $timeout(function(){
            $rootScope.$broadcast( 'editPortfolio', { name: $scope.selectedPortfolio.name } );
        });
    };

    $scope.editV2 = function () {
        // show edit dialog
        var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'editPortfolioModal.html',
            resolve: {
                portfolio: function() {
                    return angular.copy($scope.selectedPortfolio);
                }
            },
            controller: function($scope, portfolio, $uibModalInstance, Builder, toastr) {
                var vm = this;

                vm.saveChanges = saveChanges;
                vm.cancel = $uibModalInstance.dismiss;
                vm.removeModel = removeModel;
                vm.portfolio = portfolio;
                vm.portfolioName = portfolio.name;
                vm.invalidTotalMsg = 'N/A';
                vm.total = sumPercentages(portfolio.models);
                
                function saveChanges () {

                    vm.portfolio.originalName = vm.portfolioName;
                    Builder.updateUserSchema(angular.copy(vm.portfolio))
                    .then(function(res){
                        toastr.success("Portfolio updated");
                        $uibModalInstance.close(angular.copy(vm.portfolio));
                    })
                    .catch(function(err){
                        console.error(err);
                    });
                }

                function removeModel (index) {
                    vm.portfolio.models.splice(index, 1);
                }

                function sumPercentages (models) {
                    return models.reduce(function(total, model){return total += Number(model.percent);}, 0);
                }

                $scope.$watch('vm.portfolio.models', function(newVal, oldVal) {
                    var total = sumPercentages(newVal);
                    vm.total = isNaN(sumPercentages(newVal)) ? vm.invalidTotalMsg : total;
                }, true);
            },
            controllerAs: 'vm'
        });

        modalInstance.result.then(function (portfolio) {
            $scope.selectedPortfolio = portfolio;
            $scope.changeAccount(false);
        })
        .catch(function(err){
            console.error(err);
        });
    };

    $scope.deleteCurrentPortfolio = function(portfolio, triggeredFrom) {
        var payload = {
          title : portfolio ? portfolio.title : $scope.selectedPortfolio.name
        };

        if (triggeredFrom === 'builder'){
            Builder.deleteUserSchema(payload)
            .then(function(result){
              var data = angular.fromJson(result.data);

                if(data.status == 'success'){

                    Dashboard.getUserSchemaData()
                    .then(function(result){
                        var data = angular.fromJson(result.data);

                        if(typeof data.result != 'undefined' && data.result !== '' && data.result !== null){

                            data.result = angular.fromJson(data.result);
                            var portfolios = data.result;
                            $scope.portfolioNames = Object.keys(data.result);
                            $scope.portfolios = [];
                            angular.forEach(portfolios, function(element, key) {
                              var el = {
                                name: key,
                                models: element,
                                benchmark: elemtn[0].benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C'
                              };
                              $scope.portfolios.push(el);
                            });

                            if ($scope.portfolios.length){

                                // if the one we deleted was the currently selected one,
                                // load the first one by default
                                // otherwise do nothing because the name has already been deleted from the dropdown
                                if (payload.title = portfolio.title) {
                                    $scope.selectedPortfolio = $scope.portfolios[0]; // TODO: load the one from local storage or default to the first one
                                    $scope.batch = $scope.selectedPortfolio.models;
                                    getOnePortfolio();
                                }
                            } else {
                                $scope.selectedPortfolio = undefined;
                                $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                                $scope.loading = false;
                                $scope.isNewAccount = true;

                            }

                        } else {
                            $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                            $scope.loading = false;
                            $scope.isNewAccount = true;
                        }
                    });

                }else{
                    SweetAlert.swal("Portfolio Builder", "Portfolio Could not be deleted!", "error");
                }
            });
        } else {

            SweetAlert.swal({
                title: "Delete "+$scope.selectedPortfolio.name,
                text: "Are you sure?<br/>Your will not be able to recover "+$scope.selectedPortfolio.name+"!",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                showLoaderOnConfirm: true,
                closeOnConfirm: false},
                function(isConfirm){

                if( isConfirm ){

                    Builder.deleteUserSchema(payload)
                    .then(function(result){
                      var data = angular.fromJson(result.data);

                      if(data.status == 'success'){

                        SweetAlert.swal("Portfolio Builder", "Portfolio deleted!", "success");
                        $rootScope.$broadcast('deletePortfolioFromBuilder', $scope.selectedPortfolio);

                        Dashboard.getUserSchemaData()
                        .then(function(result){
                            var data = angular.fromJson(result.data);

                            if(typeof data.result != 'undefined' && data.result !== '' && data.result !== null){

                                data.result = angular.fromJson(data.result);
                                var portfolios = data.result;
                                $scope.portfolioNames = Object.keys(data.result);
                                $scope.portfolios = [];
                                angular.forEach(portfolios, function(element, key) {

                                    var el = {
                                        name: key,
                                        models: element,
                                        benchmark: element[0].benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C'
                                    };

                                    $scope.portfolios.push(el);
                                });
                                if ($scope.portfolios.length){
                                    $scope.selectedPortfolio = $scope.portfolios[0]; // TODO: load the one from local storage or default to the first one
                                    $scope.batch = $scope.selectedPortfolio.models;
                                    getOnePortfolio();
                                } else {

                                }

                                $scope.getData();

                            } else {
                                $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                                $scope.loading = false;
                                $scope.isNewAccount = true;
                            }
                        });

                      }else{
                        SweetAlert.swal("Portfolio Builder", "Portfolio Could not be deleted!", "error");
                      }
                  });
                }
            });
        }
    };

    function generateProposal () {
        Tabs.addTab('portfolio-builder');
        $timeout(function() {
          $rootScope.$broadcast('editPortfolio', {name: $scope.selectedPortfolio.name, type: 'target', data: $scope.selectedPortfolio.models, pdf: true});
        });
    }

    // format the flattened details response to match the normal target details response
    function formatFlattenedDetails (details){
        var formattedDetails = details.map(function(detail){
            var formattedDetail = {};
            var detailSecurity = detail.security || {};
            formattedDetail.percent = detail.targetPercent;
            formattedDetail.target = {
                id: detailSecurity.objectId,
                name: detailSecurity.objectName,
                type: detailSecurity.type,
                leverage: detail.targetLeverage
            };

            return formattedDetail;
        });

        return formattedDetails;
    }

    $scope.generatePortfolioPDF = generateProposal;

    // $scope.$watch('selectedBenchmark', function(newVal, oldVal) {
    //   if (newVal && oldVal !== newVal) {
    //     $scope.getData();
    //   }
    // });

    function getMinimumInvestment (models) {
      var highestMin = 0;
      var totalMin = 0;
      var modelAllocationPercentage = _.reduce(models, function(modelsPercentage, model){
        return modelsPercentage += model.percent;
      }, 0);

      models.forEach(function(model){
        var minimum = parseInt((Models.getMinimumById(model.id) || model.minimumAllocation) / model.percent);
        if (minimum > highestMin) highestMin = minimum;
      });

      if (highestMin > 0) {
        totalMin = models.reduce(function(total, model){
          return Math.round(total += model.percent * highestMin);
        }, totalMin);
      }

      return totalMin / modelAllocationPercentage;
    }

    function getPortfolioFee (models) {
      var fee = 0;

      try {
        fee = _.reduce(models, function(total, model){
          return total += ((model.feePercentage / 100) * model.percent);
        }, 0);
      }
      catch (e) {
        console.error(e);
      }

      return isNaN(fee) ? 0 : fee;
    }


    // fetch all data for the selected account
    $scope.getData = function(){

      console.log("Benchmark: ", $scope.selectedBenchmark);

      $scope.loading = true; // gets set to false in ChartCtrl after the chart data is loaded
      $scope.errorFetchingPortfolios = false;
      $scope.errMsg = null;
      $scope.portfolios = [];

      // $scope.tab.data = TargetMock;

      var targetDetails;
      var targetId = $scope.tab.data.id;
      var containsSubtarget = $scope.tab.data.details.find(function(detail){
          return detail.target.type === 'targetWeightGroup';
      });

      if (containsSubtarget) targetDetails =  TargetWeightFactory.getFlattenedDetails(targetId);
      else targetDetails = Promise.resolve($scope.tab.data.details);

      return targetDetails.then(function(details){
          
          if (containsSubtarget) {
              details = formatFlattenedDetails(details.data.data.details);
          }
          
          var models = details.filter(function(detail){
              return detail.target.type === 'model';
          })
          .map(function(detail){
            if (modelSummaries){
              var summaries = _.property(['data','payload'])(modelSummaries);
              if (summaries) {
                var summary = summaries[detail.target.id];
                if (summary) _.extend(detail, summary.information);
              }
            }

            return detail;
          });

          $scope.selectedPortfolio = $scope.tab.data;
          $scope.selectedPortfolio.models = models;

          $scope.batch = $scope.selectedPortfolio.models;

          $scope.minimumInvestment = getMinimumInvestment(models);
          $scope.totalFee = getPortfolioFee(models);

          getOnePortfolio();
      })
      .catch(function(err){
          console.error(err);
          toastr.error("Error loading details");
          $scope.loading = false;
          $scope.errorFetchingPortfolios = true;
          $scope.errMsg = err.message;
      });
    };

    /* event handlers */

    /* helpers */

    function refreshChart(){

        var chart_data = [],
            schemaRemaining = 100,
            schemaTotal = 0;

        chart_data.push(['Model','Percent']);

        for (var i = 0; i < $scope.batch.length; i++) {
            var label = $scope.batch[i].target.leverage > 0 ? $scope.batch[i].target.name + ' ' + $scope.batch[i].target.leverage + 'x' : $scope.batch[i].target.name;
            var percent = $scope.batch[i].percent * 100;
            chart_data.push([label, parseInt(percent)]);
            schemaTotal += Number(percent);
            schemaRemaining -= Number(percent);
        }

        if(parseInt(schemaRemaining) > 0){
          chart_data.push(['Unallocated', parseInt(schemaRemaining)]);
        }

        $scope.chart_data = angular.copy(chart_data);

        $timeout(function(){
            console.log("pie width: ", jQuery('#pieChart').width());
            drawChart(chart_data);
        }, 50);
    }

    function showTooltip(e){

        var slice,
            selection,
            label,
            percent,
            amount,
            tooltip;

        chart.setSelection([e]);

        try {

          selection = chart.getSelection();
          sliceid = selection[0].row;
          slice = $scope.batch[sliceid];
          label = slice.label;
          percent = slice.percent;
          amount = $scope.portfolioStartingBalance / percent;


          tooltip = "<div><span class='pie-tooltip-label'>Model: " + label + "</span></div>" +
                        "<div><span class='pie-tooltip-amount'>Amount: $" + amount + "</span></div>";

          jQuery(".google-visualization-tooltip-item-list li:eq(0)").css("font-weight", "bold");
          jQuery(".google-visualization-tooltip-item-list li:eq(1)").html(tooltip).css("font-family", "Arial");

        } catch(err) {
          console.error(err);
        }
    }

    function drawChart(data_array) {
        if ($scope.tab.active){

            var data,
                options;

            if(typeof document.getElementById('pie-chart-' + $scope.pieChartTimestamp) != 'undefined'){

                if(typeof data_array  == 'object' && typeof data_array.isTrusted == 'undefined'){

                    data = google.visualization.arrayToDataTable(data_array);

                } else {

                    data = google.visualization.arrayToDataTable([['Model','Percent']]);
                }

                options = {
                    title: '',
                    animation:{
                      duration: 1000,
                      easing: 'out',
                    },
                    chartArea: {
                        left:5,
                        top: 20,
                        width: '100%',
                        height: '100%'
                    },
                    is3D: false,
                    legend: {
                      position : 'none'
                    },
                // tooltip : {
                //   isHtml : true
                // }
                };

                chart = new google.visualization.PieChart(document.getElementById('pie-chart-' + $scope.pieChartTimestamp));
                google.visualization.events.addListener(chart, 'onmouseover', showTooltip);

                chart.draw(data, options);
            }
        }
    }

    function getOnePortfolio(portfolio){

        var loadedModels = portfolio ? portfolio : $scope.selectedPortfolio.models;
        $scope.portfolioModels = []; // same as loadedModels but with the fund_profile added
        // var startingBalance = 1000000;
        // var portfolioStartDate = $scope.selectedPortfolio.models[0].startdate;
        var benchmark = $scope.benchmark.value; // $scope.selectedPortfolio.benchmark;
        // set to last day of previous month since we don't have daily bars
        // if (moment().month() == moment(portfolioStartDate * 1000).month()) {
        //     $scope.timeframes['Since Portfolio Creation'] = moment(moment().month() + 1 + '-1-' + moment().year()).unix();
        // } else {
        //     $scope.timeframes['Since Portfolio Creation'] = portfolioStartDate;
        // }
        
        $scope.portfolioData = {
            'model_ids' : [],
            'percentages' : [],
            'leverages' : [],
            // 'startdate' : portfolioStartDate,
            'benchmark': benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C' // default to S&P 500 TR
        };
        var modelIDs = [];

        $scope.modelNames = {};
        $scope.errorFetchingPortfolios = false;
        $scope.showSimulatedPortfolio = $scope.showSimulatedPortfolio ? false : true;

        loadedModels = _.sortBy(loadedModels, function(model){
          return -model.performanceStartDate;
        });

        _.each(loadedModels, function(model, key){

            $scope.portfolioData.percentages.push(model.percent * 100);
            $scope.portfolioData.leverages.push(model.target.leverage);
            $scope.portfolioData.model_ids.push(model.target.id);

            modelIDs.push(model.target.id);
            $scope.portfolioModels.push(model);
            $scope.modelNames[model.target.id] = model.target.name;
        });

        $scope.portfolioStartingBalance = 1000000;
        $scope.portfolioData.model_ids = $scope.portfolioData.model_ids.filter(function(id){

            // check reported model access for the model id in the saved portfolio
            var model = _.findWhere(modelList, {id: id});
            return angular.isDefined(model);
        });

        var payload = _.mapObject($scope.portfolioData, function(param){
            if (angular.isArray(param)) param = param.join(',');
            if (!angular.isNumber(param) && _.isEmpty(param)) param = '';
            return param;
        });

        payload.windows = _.values($scope.timeframes).join(',');

        performanceApi.portfolioPerformance(payload)
        .then(function(res){

            if (res.data.status == 'fail'){
                if (res.data.msg){
                    toastr.error(res.data.msg[0].description);
                } else {
                    toastr.error('An unexpected error occurred');
                }
                $scope.errorFetchingPortfolios = true;
                return;
            }

            var gotBenchmark = res.data.performance.payload.portfolio_benchmark.monthly_performance.stream !== null;
            if (angular.isDefined(res.data.performance.payload.portfolios) && res.data.performance.payload.portfolios !== null && gotBenchmark){

                $scope.portfolioPerformanceResponse = res;
                //  Remove timeframe selections if they are not present in the response
                const allowedValues = Object.keys(res.data.performance.payload.portfolio_benchmark.window_statistics)
                .map((x) => {
                  if (x == '-1') return x

                  return Number(x)
                })
                for (const prop in $scope.timeframes) {
                  if ($scope.timeframes.hasOwnProperty(prop)) {
                    if (!allowedValues.includes($scope.timeframes[prop])) {
                      delete $scope.timeframes[prop]
                    }
                  }
                }

                //  Remove chart timeframes if they are not present in the response
                const allowedTimeframes = Object.keys($scope.timeframes)
                $scope.chartTimeframe.options = $scope.chartTimeframe.options.filter(x => {
                  return allowedTimeframes.includes(x)
                })
                //$scope.startTime = $scope.portfolioData.startdate;
                $scope.startTime  = $scope.timeframes[$scope.chartTimeframe.selected];
                var chartView = $scope.chartViewToggle.selected;
                handlePortfolioData(res, $scope.portfolioData, $scope.portfolioModels, $scope.startTime, chartView);
            } else {
                $scope.errorFetchingPortfolios = true;
            }
        })
        .catch(function(res){
            $scope.loading = false;
            $scope.errorFetchingPortfolios = true;
        })
        .finally(function(){
            $scope.loading = false;
            $scope.$apply();
        });
    }

    function filterAggregatePerformance(aggregatePerformance, startTime) {
      var startTimeYear = moment(startTime*1000).year();
      var startTimeMonth = moment(startTime*1000).month() + 1; // month() returns 0 for January so we shift
      var sortedAggregatePerformance = _.sortBy(aggregatePerformance, function(performance){
        return Number(performance.year);
      });

      // filter out years outside of the currently selected period
      var filteredPerformance = sortedAggregatePerformance.filter(function(performance){
        return Number(performance.year) >= startTimeYear;
      })
      .map(function(performance){

        performance = _.extend({}, performance);

        // filter out months prior to the startTime's month for the starting year
        if (Number(performance.year) === startTimeYear) {
          performance.months = _.pick(performance.months, function(performanceValue, month){
            return Number(month) >= startTimeMonth;
          });
        }

        return performance;
      });

      return filteredPerformance;
    }

    function handlePortfolioData(res, portfolioData, portfolioModels, startTime, chartView){

        res = angular.isDefined(res) ? res : $scope.portfolioPerformanceResponse;

        $scope.chartViewToggle.selected = angular.isDefined(chartView) ? chartView : 'Aggregate';
        //$scope.loading = false;
        $scope.lastUpdate = Date.now();
        $scope.aggregateYTD = {};
        $scope.percentages = res.data.percentages;

        //if (res.data.performance.payload.portfolios[res.data.percentages].windows[startTime]){
        $scope.windowPresent = true;

        var payload = res.data.performance.payload,
            portfolioMonthly = angular.fromJson(res.data.portfolioMonthly),
            portfolioKey = res.data.percentages, // key for accessing the custom portfolio data
            inception = $scope.portfolioStartingBalance,
            YTD = {},
            // startDate = payload.portfolios[portfolioKey].windows[portfolioData.startdate],
            
            firstModel = Object.keys(payload.models)[0],
            performance = payload.portfolios[portfolioKey],
            performanceWindow = performance.windows[startTime] ? performance.windows[startTime] : performance.windows['-1'], // default to all time

            // Benchmark data
            benchmarkPerformance = performanceWindow.monthly_model.benchmark_stream,  // use monthly benchmark stream for each window
            // benchmarkMonthly = payload.portfolio_benchmark.monthly_performance.portfolio,
            benchmarkMonthlyPerformance = performanceWindow.monthly_model.benchmark_monthly;
            
        $scope.benchmarkName = payload.models[firstModel].information.benchmarks.length ? payload.models[firstModel].information.benchmarks[0].name : 'N/A';

        // testing
        // var currentYearIndex = _.findIndex(portfolioMonthly, function(monthly){
        //   return Number(monthly.year) === moment().year();
        // });

        // portfolioMonthly[currentYearIndex].months["02"] = 1;

        // Custom Portfolio data
        // try {
        //     var dailyPortfolioPerformance = performanceWindow.model.performance;
        // } catch (e) {
        //     var dailyPortfolioPerformance = {};
        //     // toastr.info("Daily Performance Unavailable")
        // }

        var portfolioMonthlyPerformance = performanceWindow.monthly_model.monthly_performance;
        var portfolioTrackedYears = Object.keys(portfolioMonthlyPerformance),
            // use monthly data for every timeframe
            portfolioPerformance = performanceWindow.monthly_model.performance,
            portfolioCalculations = performanceWindow.monthly_model.calculations,
            
            benchmarkCalculations = (payload.portfolio_benchmark.window_statistics[startTime] == null) ? payload.portfolio_benchmark.window_statistics['-1'] : payload.portfolio_benchmark.window_statistics[startTime]
            yearsTracked = Object.keys(portfolioMonthlyPerformance);

        // Data for the individual models that make up the custom portfolio
        $scope.modelContributions = performanceWindow.monthly_model.model_contributions;

        $scope.benchmarkPerformance = benchmarkPerformance;

        // for displaying performance date range
        $scope.lastMonthlyReturnDate = _.last(Object.keys(portfolioPerformance)) * 1000;
        $scope.firstMonthlyReturnDate = _.first(Object.keys(portfolioPerformance)) * 1000;
        $scope.today = new Date();

        // inception date
        $scope.trackingSince = Object.keys(portfolioPerformance)[0];

        var startValue = 1000000,
            // cumulativeReturn = 0,
            // dailyPerformanceTimestamps = Object.keys(dailyPortfolioPerformance),
            // firstDate = dailyPerformanceTimestamps[0] * 1000,
            // lastDate = dailyPerformanceTimestamps[dailyPerformanceTimestamps.length - 1] * 1000,
            // YTDstart,
            // time,
            MTDstartValue,
            YTDstartValue,
            // firstOfTheMonth = false,
            // d = new Date(),
            // year = d.getFullYear(),
            // month = d.getMonth(),
            dailyDollarValues = [],
            timeframe = $scope.chartTimeframe.selected;

        $q.when(Portfolios.calculateWindowPerformance(portfolioPerformance, timeframe))
        .then(function(data){

            console.log("PnL Performance: ", data);
            var currentYearMonthlyPerformance = portfolioMonthlyPerformance[moment().year()];

            // adjust depending on if the last data point is for the current month
            // static models are usually delayed
            var lookBack = moment.utc(data.lastDate).month() === moment.utc().month() ? 2 : 1;

            MTDstartValue = data.MTDstartValue;
            YTDstartValue = data.YTDstartValue;
            dailyDollarValues = data.dailyDollarValues;


            $scope.MTDvalue = dailyDollarValues[dailyDollarValues.length - lookBack] - MTDstartValue;
            $scope.lastMonthPercent = ($scope.MTDvalue / dailyDollarValues[dailyDollarValues.length - (1 + lookBack)]);
            $scope.YTDvalue = dailyDollarValues[dailyDollarValues.length - 1] - YTDstartValue; 
            $scope.aggregatePerformance = filterAggregatePerformance(portfolioMonthly, startTime);
            $scope.portfolioValue = 1000 * portfolioCalculations.nav;
            $scope.todayPnL = 'N/A';
            $scope.todayChangeCash = 'N/A';

            // sum performance for every year to get the return since inception
            _.each(portfolioMonthlyPerformance, function(months, year){
                var percentReturn = sumAggregateReturn(months, true);
                YTD[year] = {
                    percent : percentReturn,
                    portfolioValue : inception += inception * (percentReturn / 100)
                };

                $scope.aggregateYTD[year] = percentReturn;
            });

            // get cumulative return for each model in portfolio
            $scope.allocations = _.chain(portfolioModels)
                                    // .filter(function(model, id){
                                    //     // only return ones with guid
                                    //     return angular.isDefined(model.guid);
                                    // })
                                    .map(function(model,id){
                                        // var modelCalculations;
                                        var startTime = $scope.timeframes[$scope.chartTimeframe.selected];
                                        var modelContributions = performanceWindow.monthly_model.model_contributions;
                                        $scope.contributionStartTime = _.keys(modelContributions[model.target.id])[0];

                                        var cumulativeReturn = Portfolios.getCumulativeReturnFromStream(modelContributions[model.target.id]);
                                        
                                        //modelCalculations.cumulative_return = cumulativeReturn;
                                        model.returns = cumulativeReturn;
                                        
                                        return model;
                                    })
                                    .sortBy(function(model){
                                        return -model.returns;
                                    })
                                    .value();

            $scope.benchmarkYTD = {};
            _.each(benchmarkMonthlyPerformance, function(value, year){
                if (portfolioTrackedYears.indexOf(year) !== -1) {
                $scope.benchmarkYTD[year] = sumAggregateReturn(value, true);
                }

            });

            var month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : moment().month() + 1;

            // calculate YTD by finding the previous years YTD dollar amount and multiplying it by the current years return percentage
            var startOfYear;
            if (yearsTracked.length > 1){

                if (YTD[moment().year()]){
                  $scope.YTDdollars = YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue * (YTD[moment().year()].percent / 100);
                  $scope.YTDpercent = ($scope.YTDdollars / YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue) * 100;
                } else {
                  $scope.YTDdollars = '--';
                  $scope.YTDpercent = '--';
                }

                startOfYear = YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue;

            } else if (yearsTracked.length === 1) {

                $scope.YTDdollars = startValue - YTD[yearsTracked[0]].portfolioValue;
                $scope.YTDpercent = ($scope.YTDdollars / startValue) * 100;

                startOfYear = startValue;
            }

            $scope.MTDpercent = currentYearMonthlyPerformance ? currentYearMonthlyPerformance[month] : '--';
            $scope.portfolioCalculations = portfolioCalculations;
            $scope.YTD = YTD;

            var MTDfloat = currentYearMonthlyPerformance ? (currentYearMonthlyPerformance[month] / 100) : null;
            var currentValue = YTD[moment().year()] ? YTD[moment().year()].portfolioValue : null;
            if (currentValue) var YTDdollar = currentValue - startOfYear;

            $scope.MTDdollar = MTDfloat ? (currentValue + YTDdollar) * MTDfloat : '--';
            $scope.inception = portfolioCalculations.nav * 1000 - 1000000;


            //$scope.performanceSinceInception = inception - $scope.portfolioStartingBalance;
            $scope.portfolioPayload = res.data.performance.payload;
            $scope.startTime = startTime;
            refreshChart();

            benchmarkCalculations.averageAnnualReturn = Builder.calculateAverageAnnualReturn(benchmarkMonthlyPerformance);
            portfolioCalculations.averageAnnualReturn = Builder.calculateAverageAnnualReturn(portfolioMonthlyPerformance);

            Builder.getPerformanceTableData(portfolioCalculations, benchmarkCalculations)
            .then(function(stats){
                $scope.performanceStats = stats;
            });
        });
        // } else {
        //     $scope.windowPresent = false;
        //     var portfolioPerformance = {};
        //     var benchmarkPerformance = {};
        // }

        buildChart(portfolioPerformance, benchmarkPerformance, startTime);
    }

    function buildChart(model, benchmark, startTime, leverage) {

        var days = [],
            modelData = [],
            undefinedBench = [],
            undefinedModel = [],
            benchmarkData = [],
            startingBalance = 1000000,
            startModel = 1000000,
            startBenchmark = 1000000,
            inception,
            leverage = leverage ? leverage : 1;

        $scope.portfolioChart = {
            title: {
                text : null,
                stylye : {
                  display: 'none'
                }
            }
        };

        // var modelReturn  = Portfolios.getCumulativeReturnFromStream(model);
        // var benchReturn = Portfolios.getCumulativeReturnFromStream(benchmark);
        // var adjustedReturns = Portfolios.getCumulativeReturnFromStream(_.pick(model, function(_return, date){
        //   return benchmark[date] !== null;
        // }));

        for (var day in model) {

            if (typeof model[day] !== 'undefined' && benchmark[day] !== null) {
                var timestamp = day*1000,
                    currentModel = (startModel + ( parseFloat(model[day] * leverage) / 100 ) * (startModel)),
                    currentBenchmark = startBenchmark + ( parseFloat(benchmark[day] || 0) / 100 ) * startBenchmark;

                modelData.push( {x: timestamp, y: currentModel, value: model[day]} );
                benchmarkData.push( {x: timestamp, y: isNaN(currentBenchmark) ? startBenchmark : currentBenchmark , value: benchmark[day] || 0} );

                startModel = currentModel;
                startBenchmark = currentBenchmark;
            } else {
                if (model[day] == 'undefined') {
                    undefinedModel.push([model[day], model, day]);
                } else if (benchmark[day] == 'undefined'){
                    undefinedBench.push([benchmark[day], benchmark, day]);
                }
            }
        }

        if (modelData.length && benchmarkData.length){

            // add starting point to beginning of the series'
            [modelData, benchmarkData].forEach(function(arr){
                var firstPoint = arr[0].x;
                var obj = {
                    x: (arr[0].x / 1000 - (86000 * 2) ) * 1000,
                    y: 1000000,
                    value: 0.00
                };

                arr.unshift(obj);
            });

            var todayValue = modelData[modelData.length - 1].y;
            var yesterdayValue = modelData[modelData.length - 2] ? modelData[modelData.length - 2].y : 1000000;
            var todayChangeCash = todayValue - yesterdayValue;
            var todayChangePercent = todayChangeCash / todayValue;

            var monthEndingDay = moment().endOf('month');
            var today = moment().today;
            var daysLeft = monthEndingDay.diff(today, 'days');
            var daysArr = _.range(0, 7);

            var todayTime = modelData[modelData.length - 1].x;

            // add null points for the rest of the current month to extend the chart space to the right of the last point
            _.each(daysArr, function(day){
                var obj = {
                    x: ( todayTime / 1000 + day * (86400 * 2) ) * 1000,
                    y: null,
                    value: 0.00
                };

                modelData.push(obj);
                benchmarkData.push(obj);
            });

            console.log("Line data: ", modelData);
            console.log("Line Benchmark: ", benchmarkData);

            $scope.performanceStart = _.first(modelData).x;

            $timeout(function(){

                $scope.portfolioChart = {
                    
                    chart: {
                        type: 'area',
                        events: {
                            load: function(event) {
                                var chart = this;
                                $timeout(function() {
                                    chart.reflow();
                                }, 2000);
        
                                $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls
                                $scope.chartLoaded = true;
        
                                // this adds the individual model series
                                createSeries(startTime);
                            }
                        },
                        zoomType: 'x'
                    },
                    colors: ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'],
                    title : {
                        text : null,
                        style: {
                            display: 'none',
                        }
                    },
                    subtitle: {
                        text: document.ontouchstart === undefined ?
                                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                    },
                    credits : {
                        enabled: false
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        labels: {
                            align: 'right',
                            format: '${value:,.0f}',
                            formatter: function() {
                                var percent = ((this.value - startingBalance) / startingBalance) * 100;
                                return percent + '%';
                                //return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
                            }
                        },
                    },
                    tooltip: {

                        formatter: function() {

                            // Highcharts.dateFormat('%B %e, %Y', this.x) 
                            var s  = '<span style=""><b>' + moment.utc(this.x).format('MMMM YYYY') + '</b></span>';
                            _.each(this.points, function(point){
                                var currentBalance = Highcharts.numberFormat(point.y, 2, '.', ',');
                                var percent = ((point.y - startingBalance) / startingBalance) * 100;

                                s += '<br/><b><span style="color:' + point.series.color + '">' + point.series.name + ': ' + '$' + currentBalance + '</b> (' + percent.toFixed(2) + '%)</span>';
                            });

                            return s;

                        //     var point = this,
                        //         balance = "$" + Highcharts.numberFormat(point.y, 2, '.', ','),
                        //         date = Highcharts.dateFormat('%B %e, %Y', point.x),
                        //         name = this.series.name;

                        //     return '<span style="color:#166AAB">Date: </span><b>' + date + '</b><br/><span style="color:#166AAB">Model: </span><b>' + name + '</b><br/><span style="color:#166AAB">Balance</span>: <b>' + balance + '</b><br/>';
                        },

                        crosshairs: true,
                        shared: true
                    },
                    plotOptions : {
                        series : {
                            connectNulls: true
                        }
                    },
                    xAxis: {
                        tickWidth: 0,
                        //gridLineWidth: 1,
                        type: 'datetime',
                        tickAmount: 390
                    },

                    legend: {
                        enabled: true
                    },
                    exporting: {
                        enabled: false
                    },
                    useHighStock: true,
                    series: [
                        {
                            id: 'Model Data',
                            name: 'Simulated Portfolio Performance',
                            type:'line',
                            individual: false, // this field is used by toggleChartview method to determine whether or not this series should be visible
                            lineWidth: 2,
                            data: modelData,
                            visible: $scope.chartViewToggle.selected === 'Aggregate' ? true : false,
                            color: '#D85B08',
                        },
                        {
                            id: 'Benchmark',
                            name: $scope.benchmarkName,
                            type:'line',
                            lineWidth: 2,
                            //marker: { radius: 2 },
                            data: benchmarkData,
                            color:'#2c557f',
                        }
                    ],
                    func: function(chart) {

                        $timeout(function() {
                            chart.reflow();
                        });

                        $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls

                        // this adds the individual model series
                        createSeries(startTime);
                    }
                };

            }, 1);
        } else {
            $scope.windowPresent = false;
        }
    }

    function createSeries(startTime, model, benchmark){

        var modelData,
            leverage,
            startModel,
            performance,
            newSeries,
            models = $scope.modelContributions;

        angular.forEach(models, function(model, rid){

            if (rid !== '_empty_'){
                modelData = [];
                leverage = 1;
                startModel = 1000000;

                var benchmark = $scope.benchmarkPerformance;

                for (var day in model) {

                    if (typeof model[day] !== 'undefined' && 
                        typeof benchmark[day] !== 'undefined' &&
                        benchmark[day] !== null) {
                          var timestamp = day*1000,
                              currentModel = (startModel + ( parseFloat(model[day] * leverage) / 100 ) * (startModel));

                          modelData.push( {x: timestamp, y: currentModel, value: model[day]} );

                          startModel = currentModel;
                    }
                }

                // abort if we don't have any points
                // this could be caused by the benchmark and model return stream not having the same timestamps
                if (!modelData.length) return;

                // add starting point to beginning of the series'
                var firstPoint = modelData[0].x;

                var obj = {
                    x: (firstPoint / 1000 - (86000 * 2) ) * 1000,
                    y: 1000000,
                    value: 0.00
                };

                modelData.unshift(obj);

                var daysArr = _.range(0, 7);

                var todayTime = modelData[modelData.length - 1].x;

                // add null points for the rest of the current month to extend the chart space to the right of the last point
                _.each(daysArr, function(day){
                    var obj = {
                        x: ( todayTime / 1000 + day * (86400 * 2) ) * 1000,
                        y: null,
                        value: 0.00
                    };

                    modelData.push(obj);
                });

                var modelFromBatch = _.find($scope.batch, {fid: rid});

                newSeries = {

                    id: rid,
                    name: $scope.modelNames[rid],
                    type:'line',
                    individual: true,
                    lineWidth: 2,
                    connectNulls: true,
                    data: modelData,
                    visible: $scope.chartViewToggle.selected === 'Individual' ? true : false

                };

                $scope.portfolioChart.series.push(newSeries);
            } else {
                console.warn("Guid is empty ", model);
            }
        });
    }

    function mapAggregateMonths(months){

        // in case we don't start with 01 as the first key
        var months = angular.copy(months);
        var numMonths = Object.keys(months).length;
        var firstMonth = _.min(Object.keys(months));

        var values = [];
        var range = _.range(parseInt(firstMonth, 10),13);

        _.each(range, function(i){
          if (i < 10){
            i = "0" + i;
          }
          var month = months[i];

          values.push(month);
        });

        return values;

    }

    function sumAggregateReturn(values, parseMonths){

        if (parseMonths) values = mapAggregateMonths(values);

        var percentAsFloat = values.reduce(function(currentTotal, newValue){
                                  if (angular.isDefined(newValue)){
                                    return currentTotal * ( (newValue / 100) + 1 );
                                  } else {
                                    return currentTotal * 1;
                                  }
                                }, 1 );

        YTD = (percentAsFloat - 1) * 100;

        return YTD;

    }

    function startRefreshTimer(){
        var startTime = new Date().getTime() / 1000;
        var elapsedSeconds = startTime - parentTab.lastActive;
        if (parentTab.active) {
            parentTab.updates = $interval(function() {
                if ($scope.$parent.tab.active){
                    $scope.refresh();
                }

            }, updateInterval);
        }
    }

    function killTimer(){
        $interval.cancel(parentTab.updates);
        $rootScope.timerCount--;
        parentTab.updates = undefined;
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function calculateTotal() {
      $scope.schemaTotal = 0;
      $scope.minimumInvestment = 0;
      $scope.feesAndMinimums = [];

      var highest_min = 0,
        i;

      for (i = $scope.batch.length - 1; i >= 0; i--) {
        var model_min = $scope.batch[i].min; //parseInt($scope.batch[i].min.replace('$','').replace(',',''));
        var model_percent = isNaN(parseFloat($scope.batch[i].percent)) ? 0 : parseFloat(parseFloat($scope.batch[i].percent).toFixed(2));
        var schemaTotal = parseFloat(parseFloat(parseFloat($scope.schemaTotal) + model_percent).toFixed(2));

        $scope.feesAndMinimums.push({
          name: $scope.batch[i].label,
          min: 1 * ($scope.batch[i].min).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          feeCalc: (parseFloat($scope.batch[i].fee) * 100).toFixed(2) + ' * ' + (parseFloat(model_percent) / 100).toFixed(2) + ' = ' + (parseFloat($scope.batch[i].fee) * parseFloat(model_percent)).toFixed(2),
          fee: 1 * (parseFloat($scope.batch[i].fee * 100) * parseFloat(model_percent / 100)).toFixed(2)
        });

        // round to 2 decimal places
        schemaTotal = +(Math.round(schemaTotal + "e+2") + "e-2");

        $scope.schemaTotal = schemaTotal;
        if (schemaTotal <= 100) {
          //Calculate model min
          if (model_percent > 0) {
            model_min = model_min / (model_percent / 100);
            if (model_min > highest_min) {
              highest_min = model_min;
            }
          }
        }
      }

      $scope.minimumInvestment = highest_min;

      for (i = $scope.batch.length - 1; i >= 0; i--) {
        $scope.batch[i].acc_min = $scope.minimumInvestment * (model_percent / 100);
        $scope.feesAndMinimums[i].acc_min = ($scope.batch[i].acc_min).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      $scope.schemaRemaining = 100 - parseFloat(parseFloat($scope.schemaTotal).toFixed(2));
      if ($scope.showSimulatedPortfolioBtnTxt === 'Hide') {
        $scope.weightsChanged = true;
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
      }

      $scope.feesAndMinimums.push({
        name: 'Total',
        min: '',
        fee: $scope.feesAndMinimums.reduce(function(total, item) {
          return parseFloat(total) + parseFloat(item.fee);
        }, 0),
        acc_min: (parseInt($scope.minimumInvestment).toFixed(0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
    }

})
.filter('bytype', function(){

    // format allocation value based on allocation type
    return function (input, type) {

        if (type === 'AllocateFixedDollar'){
            return "$" + input;
        }

        if (type === 'RedeemPercentageOfFund'){
            return input + "%";
        }
    };
})
.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
})
.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
})
.filter('orderObjectBy', function(){
    return function(input, attribute) {
        if (!angular.isObject(input)) return input;

        var array = [];

        for(var objectKey in input) {
            array.push(input[objectKey]);
        }

        array.sort(function(a, b){
            a = parseInt(a[attribute]);
            b = parseInt(b[attribute]);
            return a - b;
        });

        return array;
    };
});