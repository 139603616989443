angular.module('BillingModule')
.run(function(formlyConfig) {
  // formlyConfig.setType([{
  //   name: 'tiered-fees-table',
  //   templateUrl: 'tiered-fees-table.html',
  // },{
  //   name: 'account_fees_table_recap',
  //   templateUrl: 'feeTableCalculationRecap.html'
  // }, {
  //   name: 'what_are_blended_rates',
  //   templateUrl: 'whatAreBlendedRates.html'
  // }]);
})
.controller('CreateHouseholdFormCtrl', function ($uibModalInstance){
  var vm = this;

  // vm.flatCalculation;
  // vm.feeTableCalculation;
  // vm.feeTableData = [{
  //   feeAmount: null,
  //   startingLimit: 0,
  //   endingLimit: null
  // }, {
  //   feeAmount: null,
  //   startingLimit: null,
  //   endingLimit: null
  // }];

  // vm.feesTableErrorMsg;
  // vm.feeTableCalculation = '100';
  // vm.isCompany = false;
  // vm.showTieredFees = false;
  // vm.feeTableBlendedTotal = null;
  // vm.feeTableFlatTotal = null;

  vm.model = {
    frequency: "monthly",
    type: "managementFee",
    feeModel: "flat"
  };

  vm.fields = [
    {
      key: "name",
      type: "input",
      templateOptions: {
        label: 'Household Name',
        placeholder: ''
      }
    },
    {
      key: "feeSchedule",
      type: "select",
      templateOptions: {
        label: "Fee Schedule",
        options: [
          {
            name: "Schedule 1",
            value: "schedule1"
          },
          {
            name: "Schedule 2",
            value: "schedule 2"
          },
          {
            name: "+New Schedule",
            value: ""
          }
        ]
      }
    },
    {
      key: "accounts",
      type: "input",
      templateOptions: {
        label: "Member Accounts",
        placeholder: "Search for account Number"
      }
    },
    // {
    //   key: "inAdvance",
    //   type: "checkbox",
    //   templateOptions: {
    //     label: "In Advance"
    //   }
    // },
    // {
    //   key: "rebateOnClose",
    //   type: "checkbox",
    //   templateOptions: {
    //     label: "Rebate On Close"
    //   }
    // }
  ];
});