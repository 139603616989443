angular.module('RebalancerFactory',  [])
.factory('RebalancerFactory', function($http, SmartXFactory, $rootScope){

	var factory = {};
	var numberOfAccountsToRebalance; // = 0;
	var rebalancerApi = SmartXFactory.getRebalancerAPI();
	var jobApi = SmartXFactory.getJobAPI();
	
	factory.getNumberOfAccountsToRebalance = function(){
		return numberOfAccountsToRebalance;
	};
	
	factory.setNumberOfAccountsToRebalance = function(value){
		numberOfAccountsToRebalance = value;
		$rootScope.$broadcast('account-rebalance-set', value);
	};

	factory.getListOfRebalanceAccounts = function(){

		return rebalancerApi.needsRebalance();
	};

	factory.getRebalanceRequests = function(){
		return rebalancerApi.rebalanceRequests();
	};

	factory.getRebalanceActiveRequests = function(){
		return rebalancerApi.rebalanceActiveRequests();
	};

	factory.getRebalanceHistory = function(params){
		return rebalancerApi.rebalanceHistory(params);
	};

	factory.getRebalanceHistoryUsers = function(){
		return rebalancerApi.rebalanceHistoryUsers();
	};

	factory.createRebalanceRequest = function(accountIds){


		// return Promise.resolve({
		//   "data": {
		//     "webRequestId": "123456",
		//     "jobStatus": "pending",
		//     "eta": {
		//       "timestamp": 0,
		//       "friendly": "string"
		//     },
		//     "link": {
		//       "method": "string",
		//       "href": "string"
		//     }
		//   },
		//   "version": "string",
		//   "statusCode": 0,
		//   "requestId": "1618314",
		//   "timestamp": 0
		// })

		// this will return a job id that is passed to get rebalance status
		return rebalancerApi.create(accountIds);
	};

	factory.getRebalanceStatus = function(rebalanceId){
		var data = {"rebalanceId": rebalanceId};
		return jobApi.get(rebalanceId);
	};

	factory.getRebalanceRequestResults = function(rebalanceId){
		var data = {"rebalanceId": rebalanceId};		

		// return Promise.resolve({
		//   "count": 0,
		//   "data": [
		//     {
		//       "account": {
		//         "id": "string",
		//         "name": "string"
		//       },
		//       "objectType": "targetWeightGroup",
		//       "type": {
		//         "id": "string",
		//         "name": "string"
		//       },
		//       "currentWeight": 0,
		//       "percentTarget": 0,
		//       "percentUpperThreshold": 0,
		//       "percentLowerThreshold": 0,
		//       "percentUpperPrecision": 0,
		//       "percentLowerPrecision": 0,
		//       "bestFitTrade": "string",
		//       "bestFitAmount": 0,
		//       "bestFitAfterTrade": 0,
		//       "minTranTrade": "string",
		//       "minTranAmount": 0,
		//       "minTranAfterTrade": 0,
		//       "status": "complete",
		//       "message": "string"
		//     }
		//   ],
		//   "version": "string",
		//   "statusCode": 0,
		//   "requestId": "string",
		//   "timestamp": 0
		// })

		return rebalancerApi.getResults(rebalanceId);
	};

	factory.authorizeRebalance = function(payload){
		return rebalancerApi.authorize(payload);
	};

	factory.getRebalanceStatuses = function(){

		// return all the rebalance jobs 
		return jobApi.get(null, {type: "rebalanceRequest"});
	};

	factory.cancelRebalanceRequest = function(payload){
		return rebalancerApi.cancel(payload.requestId);
	};

	return factory;
});