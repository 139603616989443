angular.module('PortfoliosCtrl',  ['PortfoliosService'])
.controller('portfolios', function($modal, SmartXFactory, Portfolios, $scope, $http, $interval, $rootScope, $timeout, $window, $filter, toastr, Tabs, Dashboard, Models, SearchFilters, SweetAlert, Chart, USER, Builder, $q){
    //  ("creating new main controller");
    var parentTab = $scope.$parent.tab;
    var lastSelectedAccount = parentTab.selectedAccount;
    var tablist = Tabs;
    var tabType = $scope.tab.type;
    var performanceApi = SmartXFactory.getPerformanceAPI();
    var account,
        chart;

    $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12"><p class="text-center">Please Wait<p><h3 class="text-center">Loading Your Account...</h3></div>';
    $scope.tabIndex = parentTab.index;
    $scope.refreshing = false;
    $scope.isNewAccount = false;
    //$scope.errorLoadingAccount = false;
    $rootScope.showingWelcome = false;
    $scope.multiAccount = {
        selectedAccounts : []
    };
    $scope.parentTab = parentTab;
    $scope.chartLoaded = false;
    $scope.disabled = false;
    $scope.windowPresent = true;
    $scope.performanceStatsTimeframe = "Daily";
    $scope.portfolioNames = [];

    $scope.portfolioChart = {
        title: {
          text: null,
          style: {
            display: 'none'
          }
        }
    };
    $scope.showSimulatedPortfolio = false;

    var reportedModelList = Models.getReportedModelList();
    var modelList = Models.getModelList();
    var updates;
    var updateInterval = 60 * 1000;

    var activeTab = Tabs.getActiveTab();
    var parentTab = $scope.$parent.tab;

    Tabs.setActiveTab(activeTab);
    Tabs.setLastActive(activeTab);

    // when a new instance of this controller is created
    // setLastDashboard to this
    Tabs.setLastDashboard(parentTab);

    $scope.lastRefresh = new Date().getTime() / 1000;
    
    

    $scope.chartTimeframe = {
        options : ['1-yr', '3-yr', '5-yr', '10-yr'],
        selected : '1-yr'
    };

    $scope.timeframes = {
        // "All Time" : '-1',
        // "Since HCV Daily" : '-1',
        // "Since Portfolio Creation" : undefined,
        // "YTD" : moment(new Date('1/1/ ' + moment().year())).unix(),
        // "MTD" : moment( new Date( (moment().month() + 1) + '/1/' + moment().year() ) ).unix(),
        "1-yr": moment(new Date()).subtract(1, 'years').unix(),
        "3-yr": moment(new Date()).subtract(3, 'years').unix(),
        "5-yr": moment(new Date()).subtract(5, 'years').unix(),
        "10-yr": moment(new Date()).subtract(10, 'years').unix()
    };

    $scope.chartViewToggle = {
        options : ['Aggregate', 'Individual'],
        selected : 'Aggregate' // toggle state is tied to this value
    };

    $scope.loaderMsg = "Loading Portfolios...";

    $scope.$parent.tab.lastActive = new Date().getTime() / 1000;

    /* events */

    $scope.$on('tabSwitched::' + tabType, function(event, tab){ // code block will run on every tab switch
        var switchTime = new Date().getTime() / 1000;

        var title = tab.title;

        if (tab.index === parentTab.index) {

            if (tab.type === 'portfolios'){

                if($scope.portfolioChartInstance){
                    $timeout(function(){
                            $scope.portfolioChartInstance.reflow();
                    }, 100);
                }

                if ($scope.chart_data){
                    $timeout(function(){
                        drawChart($scope.chart_data);

                    }, 100);
                }
            }

            // this is true if we switched to an existing account tab
            if (tab.type === 'main'){

                var titles = $scope.accounts.map(function(account){
                    if (angular.isDefined(account.accountInfo)){
                        return account.accountInfo.title;
                    }
                });

                Dashboard.currentAccount = $scope.accounts[titles.indexOf(title)];
                Tabs.setLastDashboard(tab);

                var lastDash = Tabs.getLastDashboard();

                // this will be run only for the active tab
                killTimer();
                var lastActive = Tabs.getLastActive();
                    activeTab = Tabs.getActiveTab();

                Tabs.setLastActive(activeTab);

                switchTime = new Date().getTime() / 1000; // save the time

                // don't do anything if we're already processing an update
                if (!$scope.refreshing && !$scope.loading){

                    // check to see if one minute has elapsed since the last time this tab was switched to
                    if (tab.type === 'main'){

                        var elapsedSeconds = switchTime - tab.lastActive;

                        // if so, refresh it
                        if ( elapsedSeconds >= 30){

                            if ($scope.visited){
                                tab.lastActive = switchTime;
                                $scope.refresh(false);

                            } else {
                                $scope.visited = true;
                                startRefreshTimer();
                                tab.lastActive = switchTime;
                            }

                            var lastTab = Tabs.setLastActive(tab);


                        // otherwise set its last active time to the current time and start its refresh timer
                        } else {

                            if (!tab.new){
                                if (parentTab.updates){
                                    killTimer();
                                    startRefreshTimer();
                                } else {
                                    startRefreshTimer();
                                }
                            }

                            tab.lastActive = switchTime;
                        }
                    } else {

                    }

                } else {
                    startRefreshTimer(); // restart the timer
                }
            }
        } else {

            //this is true if we added a new account tab
            if (tab.new && tab.type === 'main'){
                //
                killTimer();
                tab.lastActive = switchTime;
                delete tab.new;
                $scope.visited = true;
                startRefreshTimer();
            } else {
                //console.log("not in the parent");
                //console.log("active: ", activeTab);
                //console.log("parent: ", parentTab);
                //console.log("tab from event: ", tab);
            }
        }
    });

    $scope.$on('tabAdded', function(event){

        if (parentTab.updates){
            killTimer();
        } else {
            if (parentTab.active){
                startRefreshTimer();
            }
        }
    });

    $scope.$on('chartError', function(event, msg){

        if (parentTab.active){
            $scope.loading = false;
            //$scope.errorLoadingAccount = false;
            $scope.chartError = true;
            killTimer();
        } else {
            $scope.chartError = true;
        }

    });

    $scope.$on('portfolioDeleted', function(event, portfolio){

        // TODO: delete the name from the the dropdown
        // change to / another one if it exists
        // or show the empty state if there aren't any to switch to
        var portfolioIndex = $scope.portfolios.map(function(portfolio){ return portfolio.name; }).indexOf(portfolio.title);
        $scope.portfolios.splice(portfolioIndex, 1);

        $scope.deleteCurrentPortfolio(portfolio, 'builder');
    });

    $scope.$on('portfolioCreated', function(event, portfolio){
        
        //console.log(portfolio);
        // TODO: add the name to the dropdown
        // load the data for the newly created one
        // set the selectedPortfolio to the new one
        // put it into the right format, push it to the portfolios array

        $scope.portfolios.push(portfolio);
        if ($scope.portfolios.length === 1){
            $scope.setPortfolio(portfolio);
            $timeout(function(){
                $scope.changeAccount();
            });
        }

    });

    /* events */

    var redrawPie = _.debounce(function(){

      drawChart($scope.chart_data);

    }, 300);

    function onResize () {
        if ($scope.portfolioChart && $scope.$parent.tab.active){
            redrawPie();
        }
    }

    // make the pie chart responsive
    angular.element(window).on('resize', onResize);

    $scope.$on('$destroy', function(){
        // remove the event listener
        angular.element(window).off('resize', onResize);
    });


    /* event handlers */

    $scope.addTab = function(type){
        // this is a reference to $scope

        Tabs.addTab(type);

        if (type === 'holdings'){
            Dashboard.currentAccount = this.selectedAccount;
            //tab = Tabs.addTab(type);
            Dashboard.getObserverCallbacks();
            Dashboard.notifyObservers('main');
            return;

        } else if (type === 'liquidate'){
            Dashboard.selectedLiquidationModel = this.model.fund_profile;
            Dashboard.selectedLiquidationAccount = $scope.selectedAccount;
            Dashboard.notifyObservers('main-liquidate', $scope.allocations);

        } else if (type === 'allocate'){
            Dashboard.selectedAllocationModel = this.model.fund_profile;
            Dashboard.selectedAllocationAccount = $scope.selectedAccount;
            Dashboard.notifyObservers('main');
        }

        //Tabs.addTab(type);
    };

    $scope.addTabForModel = function(model){

        if (typeof model != 'undefined') {
            var profile = model.fund_profile;

            try {
                var reportedModelId = profile.field_fund_model_guid.und[0].value.toLowerCase();
                var smartxGuid = profile.field_smartx_model_guid.und[0].value.toLowerCase();
            } catch (e){
                console.error(e);
            }

            model.id = smartxGuid;
            model.reportedModelId = reportedModelId;
            model.name = profile.title;

            Models.currentModel = model;
            Models.currentModel.guid = model.reportedModelId;

            Tabs.addTab('model');

        }
    };

    $scope.setPortfolio = function(portfolio){
        $scope.selectedPortfolio = portfolio;
    };

    $scope.changeAccount = function(refreshing){

        // kill any leftover timers
        if (parentTab.updates){
            //killTimer();
        }

        $scope.loading = true;

        $scope.batch = $scope.selectedPortfolio.models ? $scope.selectedPortfolio.models : $scope.selectedPortfolio ;
        getOnePortfolio();
    };

    $scope.refresh = function(){
        $scope.changeAccount(true);
    };

    $scope.toggleChartView = function(){
        if ($scope.chartViewToggle.selected === 'Individual'){
            $scope.showIndividualModels();
        } else if ($scope.chartViewToggle.selected === 'Aggregate'){
            $scope.showAggregate();
        }
    };

    $scope.showIndividualModels = function(){

        // loop through the chart series
        // if it is an individual model series, set its visible property to true
        $scope.portfolioChart.series.forEach(function(series){
          if (series.id !== 'Benchmark'){
            if (series.individual){
              series.visible = true;
            } else if (!series.individual) {
              series.visible = false;
            }
          }
        });
    };

    // TODO: merge these two methods into one
    $scope.showAggregate = function(){

        // loop through the chart series
        // if it is an aggregate model series, set its visible property to true
        $scope.portfolioChart.series.forEach(function(series){
          if (series.id !== 'Benchmark'){
            if (series.individual){
              series.visible = false;
            } else if (!series.individual) {
              series.visible = true;
            }
          }
        });
    };

    $scope.togglePerformanceTimeframe = function(timeframe){

        var chartView = $scope.chartViewToggle.selected;
        if (timeframe === "All Time"){
            $scope.performanceStatsTimeframe = "Monthly";
        } else {
            $scope.performanceStatsTimeframe = "Daily";
        }
        $scope.startTime = $scope.timeframes[timeframe];
        handlePortfolioData($scope.portfolioPerformanceResponse, $scope.portfolioData, $scope.portfolioModels, $scope.startTime, chartView);
    };


    $scope.editCurrentPortfolio = function(){
        $scope.addTab('portfolio-builder');
        $timeout(function(){
            $rootScope.$broadcast( 'editPortfolio', { name: $scope.selectedPortfolio.name } );
        });
    };

    $scope.editV2 = function () {
        // show edit dialog
        var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'editPortfolioModal.html',
            resolve: {
                portfolio: function() {
                    return angular.copy($scope.selectedPortfolio);
                }
            },
            controller: function($scope, portfolio, $uibModalInstance, Builder, toastr) {
                var vm = this;

                vm.saveChanges = saveChanges;
                vm.cancel = $uibModalInstance.dismiss;
                vm.removeModel = removeModel;
                vm.portfolio = portfolio;
                vm.portfolioName = portfolio.name;
                vm.invalidTotalMsg = 'N/A';
                vm.total = sumPercentages(portfolio.models);
                
                function saveChanges () {

                    vm.portfolio.originalName = vm.portfolioName;
                    Builder.updateUserSchema(angular.copy(vm.portfolio))
                    .then(function(res){
                        toastr.success("Portfolio updated");
                        $uibModalInstance.close(angular.copy(vm.portfolio));
                    })
                    .catch(function(err){
                        console.error(err);
                    });
                }

                function removeModel (index) {
                    vm.portfolio.models.splice(index, 1);
                }

                function sumPercentages (models) {
                    return models.reduce(function(total, model){return total += Number(model.percent);}, 0);
                }

                $scope.$watch('vm.portfolio.models', function(newVal, oldVal) {
                    var total = sumPercentages(newVal);
                    vm.total = isNaN(sumPercentages(newVal)) ? vm.invalidTotalMsg : total;
                }, true);
            },
            controllerAs: 'vm'
        });

        modalInstance.result.then(function (portfolio) {
            $scope.selectedPortfolio = portfolio;
            $scope.changeAccount(false);
        })
        .catch(function(err){
            console.error(err);
        });
    };



    $scope.generatePortfolioPDF = function() {
      $scope.addTab('portfolio-builder');
      $timeout(function() {
        $rootScope.$broadcast('editPortfolio', {name: $scope.selectedPortfolio.name, pdf: true});
      });
    };

    $scope.deleteCurrentPortfolio = function(portfolio, triggeredFrom) {
        var payload = {
          title : portfolio ? portfolio.title : $scope.selectedPortfolio.name
        };

        if (triggeredFrom === 'builder'){
            Builder.deleteUserSchema(payload)
            .then(function(result){
              var data = angular.fromJson(result.data);

                if(data.status == 'success'){

                    Dashboard.getUserSchemaData()
                    .then(function(result){
                        var data = angular.fromJson(result.data);

                        if(typeof data.result != 'undefined' && data.result !== '' && data.result !== null){

                            data.result = angular.fromJson(data.result);
                            var portfolios = data.result;
                            $scope.portfolioNames = Object.keys(data.result);
                            $scope.portfolios = [];
                            angular.forEach(portfolios, function(element, key) {
                              var el = {
                                name: key,
                                models: element,
                                benchmark: elemtn[0].benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C'
                              };
                              $scope.portfolios.push(el);
                            });

                            if ($scope.portfolios.length){

                                // if the one we deleted was the currently selected one,
                                // load the first one by default
                                // otherwise do nothing because the name has already been deleted from the dropdown
                                if (payload.title = portfolio.title) {
                                    $scope.selectedPortfolio = $scope.portfolios[0]; // TODO: load the one from local storage or default to the first one
                                    $scope.batch = $scope.selectedPortfolio.models;
                                    getOnePortfolio();
                                }
                            } else {
                                $scope.selectedPortfolio = undefined;
                                $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                                $scope.loading = false;
                                $scope.isNewAccount = true;

                            }

                        } else {
                            $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                            $scope.loading = false;
                            $scope.isNewAccount = true;
                        }
                    });

                }else{
                    SweetAlert.swal("Portfolio Builder", "Portfolio Could not be deleted!", "error");
                }
            });
        } else {

            SweetAlert.swal({
                title: "Delete "+$scope.selectedPortfolio.name,
                text: "Are you sure?<br/>Your will not be able to recover "+$scope.selectedPortfolio.name+"!",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                showLoaderOnConfirm: true,
                closeOnConfirm: false},
                function(isConfirm){

                if( isConfirm ){

                    Builder.deleteUserSchema(payload)
                    .then(function(result){
                      var data = angular.fromJson(result.data);

                      if(data.status == 'success'){

                        SweetAlert.swal("Portfolio Builder", "Portfolio deleted!", "success");
                        $rootScope.$broadcast('deletePortfolioFromBuilder', $scope.selectedPortfolio);

                        Dashboard.getUserSchemaData()
                        .then(function(result){
                            var data = angular.fromJson(result.data);

                            if(typeof data.result != 'undefined' && data.result !== '' && data.result !== null){

                                data.result = angular.fromJson(data.result);
                                var portfolios = data.result;
                                $scope.portfolioNames = Object.keys(data.result);
                                $scope.portfolios = [];
                                angular.forEach(portfolios, function(element, key) {

                                    var el = {
                                        name: key,
                                        models: element,
                                        benchmark: element[0].benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C'
                                    };

                                    $scope.portfolios.push(el);
                                });
                                if ($scope.portfolios.length){
                                    $scope.selectedPortfolio = $scope.portfolios[0]; // TODO: load the one from local storage or default to the first one
                                    $scope.batch = $scope.selectedPortfolio.models;
                                    getOnePortfolio();
                                } else {

                                }

                                $scope.getData();

                            } else {
                                $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No accounts found.<br/><a href="#/auth/new-profile">Create a new account</a></h3></div>';
                                $scope.loading = false;
                                $scope.isNewAccount = true;
                            }
                        });

                      }else{
                        SweetAlert.swal("Portfolio Builder", "Portfolio Could not be deleted!", "error");
                      }
                  });
                }
            });
        }
    };

    // fetch all data for the selected account
    $scope.getData = function(){

        $scope.loading = true; // gets set to false in ChartCtrl after the chart data is loaded
        $scope.errorFetchingPortfolios = false;
        $scope.portfolios = [];

        Dashboard.getUserSchemaData()
        .then(function(result){

            var data = angular.fromJson(result.data);

            if(typeof data.result != 'undefined' && data.result !== '' && data.result !== null){

                data.result = angular.fromJson(data.result);
                var portfolios = data.result;
                $scope.portfolioNames = Object.keys(data.result);

                angular.forEach(portfolios, function(element, key) {
                  var el = {
                    name: key,
                    models: element,
                    benchmark: element[0].benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C' // default to S&P 500
                  };
                  $scope.portfolios.push(el);
                });

                $scope.selectedPortfolio = $scope.portfolios[0]; // TODO: load the one from local storage or default to the first one
                if( typeof $scope.selectedPortfolio != 'undefined' ){
                    $scope.batch = $scope.selectedPortfolio.models;
                    getOnePortfolio();
                } else {
                    $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No portfolios found.<br/><a href ng-click="addTab(\'portfolio-builder\');">Create a new portfolio</a></h3></div>';
                    $scope.loading = false;
                    $scope.isNewAccount = true;
                }

            } else {
                $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12" style="height: 500px;"><h3 class="text-center">No portfolios found.<br/><a href ng-click="addTab(\'portfolio-builder\');">Create a new portfolio</a></h3></div>';
                $scope.loading = false;
                $scope.isNewAccount = true;
            }
        });
    };

    $scope.createTargetGroup = function createTargetGroup(portfolio) {

      var payload = {
        "id": null,
        "name": $scope.selectedPortfolio.name.replace(/\s+/g, '-').toLowerCase() + new Date().getTime(),
        "details": []
      };

      var cash = 0;
      var allocated = 0;
      $scope.selectedPortfolio.models.forEach(function(model, index) {
        var slice = {
        //   "id": model.guid,
          "name": model.label,
          "percent": model.percent / 100,
          /* "percentUpperThreshold": 0.20,
          "percentLowerThreshold": 0.20,
          "percentUpperPrecision": 0.04,
          "percentLowerPrecision": 0.04,
          "leverage": model.selectedLeverage,
          "matchModelLeverage": true, */
        //   "type": "model",
          "target": {
            "id": model.guid,
            "type": "model",
            "name": model.label,
            "leverage": model.selectedLeverage,
            "matchModelLeverage": true
          },
          "threshold": {
            "upper": 0.2,
            "lower": 0.2
          },
          "precision": {
            "upper": 0.04,
            "lower": 0.04
          }
        };

        allocated += Number(model.percent);
        payload.details.push(slice);
      });

      cash = (100 - allocated) / 100;
    //   payload.details.cash.percentTarget = cash;

      payload.details.push({
        //type: 'cash',
        percent: cash,
/*      percentUpperThreshold: 0.2,
        percentLowerThreshold: 0.2,
        percentUpperPrecision: 0.04,
        percentLowerPrecision: 0.04 */
        "target": {
          "type": "cash",
          "name": "$CASH",
          "leverage": null,
          "matchModelLeverage": null
        },
        "threshold": {
          "upper": 0.2,
          "lower": 0.2
        },
        "precision": {
          "upper": 0.04,
          "lower": 0.04
        }
      });
      Tabs.addTab('targetEdit', null, null, payload);
    };

    /* event handlers */

    /* helpers */

    function refreshChart(){

        var chart_data = [],
            schemaRemaining = 100,
            schemaTotal = 0;

        chart_data.push(['Model','Percent']);

        for (var i = 0; i < $scope.batch.length; i++) {
            var label = $scope.batch[i].selectedLeverage > 0 ? $scope.batch[i].label + ' ' + $scope.batch[i].selectedLeverage + 'x' : $scope.batch[i].label;

            chart_data.push([label, parseInt($scope.batch[i].percent)]);
            schemaTotal += Number($scope.batch[i].percent);
            schemaRemaining -= Number($scope.batch[i].percent);
        }

        if(parseInt(schemaRemaining) > 0){
          chart_data.push(['Unallocated', parseInt(schemaRemaining)]);
        }

        $scope.chart_data = angular.copy(chart_data);

        $timeout(function(){
          drawChart(chart_data);
        }, 50);
    }

    function showTooltip(e){

        var slice,
            selection,
            label,
            percent,
            amount,
            tooltip;

        chart.setSelection([e]);

        try {

          selection = chart.getSelection();
          sliceid = selection[0].row;
          slice = $scope.batch[sliceid];
          label = slice.label;
          percent = slice.percent;
          amount = $scope.portfolioStartingBalance / percent;


          tooltip = "<div><span class='pie-tooltip-label'>Model: " + label + "</span></div>" +
                        "<div><span class='pie-tooltip-amount'>Amount: $" + amount + "</span></div>";

          jQuery(".google-visualization-tooltip-item-list li:eq(0)").css("font-weight", "bold");
          jQuery(".google-visualization-tooltip-item-list li:eq(1)").html(tooltip).css("font-family", "Arial");

        } catch(err) {
          console.error(err);
        }
    }

    function drawChart(data_array) {
        if ($scope.tab.active){

            var data,
                options;

            if(typeof document.getElementById('pie-chart') != 'undefined'){

                if(typeof data_array  == 'object' && typeof data_array.isTrusted == 'undefined'){

                    data = google.visualization.arrayToDataTable(data_array);

                } else {

                    data = google.visualization.arrayToDataTable([['Model','Percent']]);
                }

                options = {
                    title: '',
                    animation:{
                      duration: 1000,
                      easing: 'out',
                    },
                    chartArea: {
                        left:5,
                        top: 20,
                        width: '100%',
                        height: '100%'
                    },
                    is3D: false,
                    legend: {
                      position : 'none'
                    },
                // tooltip : {
                //   isHtml : true
                // }
                };

                chart = new google.visualization.PieChart(document.getElementById('pie-chart'));
                google.visualization.events.addListener(chart, 'onmouseover', showTooltip);

                chart.draw(data, options);
            }
        }
    }

    function getOnePortfolio(portfolio){

        var loadedModels = portfolio ? portfolio : $scope.selectedPortfolio.models;
        $scope.portfolioModels = []; // same as loadedModels but with the fund_profile added
        var startingBalance = 1000000;
        var portfolioStartDate = $scope.selectedPortfolio.models[0].startdate;
        var benchmark = $scope.selectedPortfolio.benchmark;
        // set to last day of previous month since we don't have daily bars
        // if (moment().month() == moment(portfolioStartDate * 1000).month()) {
        //     $scope.timeframes['Since Portfolio Creation'] = moment(moment().month() + 1 + '-1-' + moment().year()).unix();
        // } else {
        //     $scope.timeframes['Since Portfolio Creation'] = portfolioStartDate;
        // }
        
        $scope.portfolioData = {
            'model_ids' : [],
            'percentages' : [],
            'leverages' : [],
            'startdate' : portfolioStartDate,
            'benchmark': benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C' // default to S&P 500 TR
        };
        var modelIDs = [];

        $scope.modelNames = {};
        $scope.errorFetchingPortfolios = false;
        $scope.showSimulatedPortfolio = $scope.showSimulatedPortfolio ? false : true;

        loadedModels = _.sortBy(loadedModels, function(model){
          return model.fundInception * -1;
        });

        _.each(loadedModels, function(model, key){
            var reportedModel = _.find(reportedModelList, {id: model.guid});

            try {
                var modelId = reportedModel ? _.find(Models.getModelList(), {reportedModelId: reportedModel.id }).id : model.guid;
                var modelName = reportedModel ? reportedModel.name : _.find(Models.getModelList(), {id: model.guid}).name;
            } catch (e){
                console.error(e);
                console.warn("Model: ", model);
            }

            $scope.portfolioData.percentages.push(model.percent);
            $scope.portfolioData.leverages.push(model.selectedLeverage || 1);
            $scope.portfolioData.model_ids.push(modelId);
            modelIDs.push(model.guid);
        });

        performanceApi.modelProfiles()
        .then(function(result){

            var models = result.data;

            // add the fund profile for each of the portfolio models
            // another option would be to save the profile along with each model when saving the portfolio
            // fund_profile is needed so that the selected model can be stored and then retrieved to set the selected model in the allocation view
            models.forEach(function(model){

                try {
                    var guid = model.field_fund_model_guid.und[0].value.toLowerCase(); // reported model id
                    var smartxGuid = model.field_smartx_model_guid.und[0].value.toLowerCase(); // model id
                } catch(e){
                    if (e.name === 'TypeError'){
                        console.error(e);
                        console.error("No smartx model guid set in Drupal for ", model.title);
                    }
                }

                if (modelIDs.indexOf(guid) !== -1 || modelIDs.indexOf(smartxGuid) !== -1){
                    loadedModels.forEach(function(portfolio){

                        var reportedModel = _.find(reportedModelList, {id: guid});
                        var _portfolio = angular.copy(portfolio); // so we don't alter the original loaded models;
                        if (_portfolio.guid == guid || _portfolio.guid == smartxGuid){
                            
                            // _portfolio.guid = reportedModel ? _.find(Models.getModelList(), {name: reportedModel.name}).id : model.guid;
                            try {
                                _portfolio.guid = reportedModel ? _.find(Models.getModelList(), {reportedModelId: reportedModel.id}).id : model.guid;
                                _portfolio.fund_profile = model;
                                if (model.status === '1') {
                                    $scope.portfolioModels.push(_portfolio);
                                    $scope.batch.map(function(item){

                                        if (item.guid == guid || item.guid == smartxGuid){
                                            var modelName = reportedModel ? reportedModel.name : _.find(Models.getModelList(), {id: guid}).name;
                                            $scope.modelNames[_portfolio.guid] = modelName;
                                            item.label = modelName || model.title;
                                        } else {
                                            // if (item.label == "BRIndex Consumer Staples Index"){
                                            // }
                                        }

                                        return item;
                                    });
                                }
                            } catch (e) {
                                console.error(e);
                                console.error("Error looking up model: ", model);
                            }
                        }
                    });
                }
            });

            $scope.portfolioStartingBalance = 1000000;

            $scope.portfolioData.model_ids = $scope.portfolioData.model_ids.filter(function(id){

                // check reported model access for the model id in the saved portfolio
                var model = _.findWhere(modelList, {id: id});
                return angular.isDefined(model);
            });

            payload = _.mapObject($scope.portfolioData, function(param){
                if (angular.isArray(param)) param = param.join(',');
                if (!angular.isNumber(param) && _.isEmpty(param)) param = '';
                return param;
            });

            payload.windows = _.values($scope.timeframes).join(',');

            return performanceApi.portfolioPerformance(payload);
        })
        .then(function(res){

            if (res.data.status == 'fail'){
                if (res.data.msg){
                    toastr.error(res.data.msg[0].description);
                } else {
                    toastr.error('An unexpected error occurred');
                }
                $scope.errorFetchingPortfolios = true;
                return;
            }

            var gotBenchmark = res.data.performance.payload.portfolio_benchmark.monthly_performance.stream !== null;
            if (angular.isDefined(res.data.performance.payload.portfolios) && res.data.performance.payload.portfolios !== null && gotBenchmark){

                $scope.portfolioPerformanceResponse = res;
                //$scope.startTime = $scope.portfolioData.startdate;
                $scope.startTime  = $scope.timeframes[$scope.chartTimeframe.selected];
                var chartView = $scope.chartViewToggle.selected;
                handlePortfolioData(res, $scope.portfolioData, $scope.portfolioModels, $scope.startTime, chartView);
            } else {
                $scope.errorFetchingPortfolios = true;
            }
        })
        .catch(function(res){
            $scope.loading = false;
            $scope.errorFetchingPortfolios = true;
        })
        .finally(function(){
            $scope.loading = false;
            $scope.$apply();
        });
    }

    function handlePortfolioData(res, portfolioData, portfolioModels, startTime, chartView){

        res = angular.isDefined(res) ? res : $scope.portfolioPerformanceResponse;

        $scope.chartViewToggle.selected = angular.isDefined(chartView) ? chartView : 'Aggregate';
        //$scope.loading = false;
        $scope.lastUpdate = Date.now();
        $scope.aggregateYTD = {};
        $scope.percentages = res.data.percentages;


        if (res.data.performance.payload.portfolios[res.data.percentages].windows[startTime]){
            $scope.windowPresent = true;

            var payload = res.data.performance.payload,
                portfolioMonthly = angular.fromJson(res.data.portfolioMonthly),
                portfolioKey = res.data.percentages, // key for accessing the custom portfolio data
                inception = $scope.portfolioStartingBalance,
                YTD = {},
                startDate = payload.portfolios[portfolioKey].windows[portfolioData.startdate],
                
                firstModel = Object.keys(payload.models)[0],

                // Benchmark data
                benchmarkPerformance = payload.portfolios[portfolioKey].windows[startTime].monthly_model.benchmark_stream,  // use monthly benchmark stream for each window
                benchmarkMonthly = payload.portfolio_benchmark.monthly_performance.portfolio,
                benchmarkMonthlyPerformance = payload.portfolios[portfolioKey].windows['-1'].monthly_model.benchmark_monthly;

            $scope.benchmarkName = payload.models[firstModel].information.benchmarks.length ? payload.models[firstModel].information.benchmarks[0].name : 'N/A';

            // Custom Portfolio data
            try {
                var dailyPortfolioPerformance = payload.portfolios[portfolioKey].windows[startTime].model.performance;
            } catch (e) {
                var dailyPortfolioPerformance = {};
                // toastr.info("Daily Performance Unavailable")
            }

            var portfolioMonthlyPerformance = payload.portfolios[portfolioKey].windows['-1'].monthly_model.monthly_performance;
            var portfolioTrackedYears = Object.keys(portfolioMonthlyPerformance),
                // use monthly data for every timeframe
                portfolioPerformance = payload.portfolios[portfolioKey].windows[startTime].monthly_model.performance,
                portfolioCalculations = payload.portfolios[portfolioKey].windows[startTime].monthly_model.calculations,
                
                benchmarkCalculations = payload.portfolio_benchmark.calculations,

                yearsTracked = Object.keys(portfolioMonthlyPerformance);

             // Data for the individual models that make up the custom portfolio
            $scope.modelContributions = payload.portfolios[portfolioKey].windows['-1'].monthly_model.model_contributions;

            $scope.benchmarkPerformance = benchmarkPerformance;

            var percentReturn = Portfolios.getCumulativeReturnFromStream(portfolioPerformance);

            // inception date
            $scope.trackingSince = Object.keys(portfolioPerformance)[0];

            var startValue = 1000000,
                cumulativeReturn = 0,
                dailyPerformanceTimestamps = Object.keys(dailyPortfolioPerformance),
                firstDate = dailyPerformanceTimestamps[0] * 1000,
                lastDate = dailyPerformanceTimestamps[dailyPerformanceTimestamps.length - 1] * 1000,
                YTDstart,
                time,
                MTDstartValue,
                YTDstartValue,
                firstOfTheMonth = false,
                d = new Date(),
                year = d.getFullYear(),
                month = d.getMonth(),
                dailyDollarValues = [],
                timeframe = $scope.chartTimeframe.selected;

            $q.when(Portfolios.calculateWindowPerformance(portfolioPerformance, timeframe))
            .then(function(data){

                MTDstartValue = data.MTDstartValue;
                YTDstartValue = data.YTDstartValue;
                dailyDollarValues = data.dailyDollarValues;

                // custom portfolio performance statistics
                $scope.MTDvalue = dailyDollarValues[dailyDollarValues.length - 1] - MTDstartValue;
                $scope.YTDvalue = dailyDollarValues[dailyDollarValues.length - 1] - YTDstartValue; 
                $scope.aggregatePerformance = portfolioMonthly;
                $scope.portfolioValue = 1000 * portfolioCalculations.nav;
                // $scope.dailyCompoundedGrowth = portfolioCalculations;
                $scope.todayPnL = 'N/A'; //dailyPortfolioPerformance[today] ? dailyPortfolioPerformance[today].toFixed(2) : 0;
                //$scope.yesterdayPnL = dailyPortfolioPerformance[yesterday] ? dailyPortfolioPerformance[yesterday].toFixed(2) : 0;
                $scope.todayChangeCash = 'N/A'; //dailyDollarValues[dailyDollarValues.length - 2] ? dailyDollarValues[dailyDollarValues.length - 1] - dailyDollarValues[dailyDollarValues.length - 2] : dailyDollarValues[dailyDollarValues.length - 1] - 1000000;

                // sum performance for every year to get the return since inception
                _.each(portfolioMonthlyPerformance, function(months, year){
                    var percentReturn = sumAggregateReturn(months, true);
                    YTD[year] = {
                        percent : percentReturn,
                        portfolioValue : inception += inception * (percentReturn / 100)
                    };

                    $scope.aggregateYTD[year] = percentReturn;
                });

                // get cumulative return for each model in portfolio
                $scope.allocations = _.chain(portfolioModels)
                                        .filter(function(model, id){
                                            // only return ones with guid
                                            return angular.isDefined(model.guid);
                                        })
                                        .map(function(model,id){
                                            // var modelCalculations;

                                            var modelContributions = payload.portfolios[portfolioKey].windows[-1].monthly_model.model_contributions;
                                            $scope.contributionStartTime = _.keys(modelContributions[model.guid])[0];

                                            var cumulativeReturn = Portfolios.getCumulativeReturnFromStream(modelContributions[model.guid]);
                                            
                                            //modelCalculations.cumulative_return = cumulativeReturn;
                                            model.returns = cumulativeReturn;
                                            
                                            return model;
                                        })
                                        .sortBy(function(model){
                                            return -model.returns;
                                        })
                                        .value();

                $scope.benchmarkYTD = {};
                _.each(benchmarkMonthlyPerformance, function(value, year){
                  if (portfolioTrackedYears.indexOf(year) !== -1) {
                    $scope.benchmarkYTD[year] = sumAggregateReturn(value, true);
                  }

                });

                var month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : moment().month() + 1;

                // calculate YTD by finding the previous years YTD dollar amount and multiplying it by the current years return percentage
                if (yearsTracked.length > 1){

                    $scope.YTDdollars = YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue * (YTD[moment().year()].percent / 100);
                    $scope.YTDpercent = ($scope.YTDdollars / YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue) * 100;

                    var startOfYear = YTD[yearsTracked[yearsTracked.length - 2]].portfolioValue;

                } else if (yearsTracked.length === 1) {

                    $scope.YTDdollars = startValue - YTD[yearsTracked[0]].portfolioValue;
                    $scope.YTDpercent = ($scope.YTDdollars / startValue) * 100;

                    var startOfYear = startValue;
                }

                $scope.MTDpercent = portfolioMonthlyPerformance[moment().year()][month];
                $scope.portfolioCalculations = portfolioCalculations;
                $scope.YTD = YTD;

                var MTDfloat = (portfolioMonthlyPerformance[moment().year()][month] / 100);
                var currentValue = YTD[moment().year()].portfolioValue;
                var YTDdollar = currentValue - startOfYear;

                $scope.MTDdollar = (currentValue + YTDdollar) * MTDfloat;
                $scope.inception = portfolioCalculations.nav * 1000 - 1000000;


                //$scope.performanceSinceInception = inception - $scope.portfolioStartingBalance;
                $scope.portfolioPayload = res.data.performance.payload;
                $scope.startTime = startTime;
                refreshChart();

                benchmarkCalculations.averageAnnualReturn = Builder.calculateAverageAnnualReturn(benchmarkMonthlyPerformance);
                portfolioCalculations.averageAnnualReturn = Builder.calculateAverageAnnualReturn(portfolioMonthlyPerformance);

                Builder.getPerformanceTableData(portfolioCalculations, benchmarkCalculations)
                .then(function(stats){
                  $scope.performanceStats = stats;
                });
            });
        } else {
            $scope.windowPresent = false;
            var portfolioPerformance = {};
            var benchmarkPerformance = {};
        }

        buildChart(portfolioPerformance, benchmarkPerformance, startTime);
    }

    function buildChart(model, benchmark, startTime, leverage) {

        var days = [],
            modelData = [],
            undefinedBench = [],
            undefinedModel = [],
            benchmarkData = [],
            startingBalance = 1000000,
            startModel = 1000000,
            startBenchmark = 1000000,
            inception,
            leverage = leverage ? leverage : 1;

        $scope.portfolioChart = {
            title: {
                text : null,
                stylye : {
                  display: 'none'
                }
            }
        };

        var modelReturn  = Portfolios.getCumulativeReturnFromStream(model);
        var benchReturn = Portfolios.getCumulativeReturnFromStream(benchmark);
        var adjustedReturns = Portfolios.getCumulativeReturnFromStream(_.pick(model, function(_return, date){
          return benchmark[date] !== null;
        }));

        for (var day in model) {

            if (typeof model[day] !== 'undefined' && typeof benchmark[day] !== 'undefined' && benchmark[day] !== null) {
                var timestamp = day*1000,
                    currentModel = (startModel + ( parseFloat(model[day] * leverage) / 100 ) * (startModel)),
                    currentBenchmark = startBenchmark + ( parseFloat(benchmark[day]) / 100 ) * startBenchmark;

                modelData.push( {x: timestamp, y: currentModel, value: model[day]} );
                benchmarkData.push( {x: timestamp, y: isNaN(currentBenchmark) ? startBenchmark : currentBenchmark , value: benchmark[day]} );

                startModel = currentModel;
                startBenchmark = currentBenchmark;
            } else {
                if (model[day] == 'undefined') {
                    undefinedModel.push([model[day], model, day]);
                } else if (benchmark[day] == 'undefined'){
                    undefinedBench.push([benchmark[day], benchmark, day]);
                }
            }
        }

        if (modelData.length && benchmarkData.length){

            // add starting point to beginning of the series'
            [modelData, benchmarkData].forEach(function(arr){
                var firstPoint = arr[0].x;
                var obj = {
                    x: (arr[0].x / 1000 - (86000 * 2) ) * 1000,
                    y: 1000000,
                    value: 0.00
                };

                arr.unshift(obj);
            });

            var todayValue = modelData[modelData.length - 1].y;
            var yesterdayValue = modelData[modelData.length - 2] ? modelData[modelData.length - 2].y : 1000000;
            var todayChangeCash = todayValue - yesterdayValue;
            var todayChangePercent = todayChangeCash / todayValue;

            var monthEndingDay = moment().endOf('month');
            var today = moment().today;
            var daysLeft = monthEndingDay.diff(today, 'days');
            var daysArr = _.range(0, 7);

            var todayTime = modelData[modelData.length - 1].x;

            // add null points for the rest of the current month to extend the chart space to the right of the last point
            _.each(daysArr, function(day){
                var obj = {
                    x: ( todayTime / 1000 + day * (86400 * 2) ) * 1000,
                    y: null,
                    value: 0.00
                };

                modelData.push(obj);
                benchmarkData.push(obj);
            });

            $timeout(function(){

                $scope.portfolioChart = {
                    
                    chart: {
                        type: 'area',
                        events: {
                            load: function(event) {
                                var chart = this;
                                $timeout(function() {
                                    chart.reflow();
                                }, 2000);
        
                                $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls
                                $scope.chartLoaded = true;
        
                                // this adds the individual model series
                                createSeries(startTime);
                            }
                        },
                        zoomType: 'x'
                    },
                    colors: ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'],
                    title : {
                        text : null,
                        style: {
                            display: 'none',
                        }
                    },
                    subtitle: {
                        text: document.ontouchstart === undefined ?
                                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                    },
                    credits : {
                        enabled: false
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        labels: {
                            align: 'right',
                            format: '${value:,.0f}',
                            formatter: function() {
                                var percent = ((this.value - startingBalance) / startingBalance) * 100;
                                return percent + '%';
                                //return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
                            }
                        },
                    },
                    tooltip: {

                        formatter: function() {

                            var s  = '<span style=""><b>' + Highcharts.dateFormat('%B %e, %Y', this.x) + '</b></span>';

                            _.each(this.points, function(point){
                                var currentBalance = Highcharts.numberFormat(point.y, 2, '.', ',');
                                var percent = ((point.y - startingBalance) / startingBalance) * 100;

                                s += '<br/><b><span style="color:' + point.series.color + '">' + point.series.name + ': ' + '$' + currentBalance + '</b> (' + percent.toFixed(2) + '%)</span>';
                            });

                            return s;

                        //     var point = this,
                        //         balance = "$" + Highcharts.numberFormat(point.y, 2, '.', ','),
                        //         date = Highcharts.dateFormat('%B %e, %Y', point.x),
                        //         name = this.series.name;

                        //     return '<span style="color:#166AAB">Date: </span><b>' + date + '</b><br/><span style="color:#166AAB">Model: </span><b>' + name + '</b><br/><span style="color:#166AAB">Balance</span>: <b>' + balance + '</b><br/>';
                        },

                        crosshairs: true,
                        shared: true
                    },
                    plotOptions : {
                        series : {
                            connectNulls: true
                        }
                    },
                    xAxis: {
                        tickWidth: 0,
                        //gridLineWidth: 1,
                        type: 'datetime',
                        tickAmount: 390
                    },

                    legend: {
                        enabled: true
                    },
                    exporting: {
                        enabled: false
                    },
                    useHighStock: true,
                    series: [
                        {
                            id: 'Model Data',
                            name: 'Simulated Portfolio Performance',
                            type:'line',
                            individual: false, // this field is used by toggleChartview method to determine whether or not this series should be visible
                            lineWidth: 2,
                            data: modelData,
                            visible: $scope.chartViewToggle.selected === 'Aggregate' ? true : false,
                            color: '#D85B08',
                        },
                        {
                            id: 'Benchmark',
                            name: $scope.benchmarkName,
                            type:'line',
                            lineWidth: 2,
                            //marker: { radius: 2 },
                            data: benchmarkData,
                            color:'#2c557f',
                        }
                    ],
                    func: function(chart) {

                        $timeout(function() {
                            chart.reflow();
                        });

                        $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls

                        // this adds the individual model series
                        createSeries(startTime);
                    }
                };

            }, 1);
        } else {
            $scope.windowPresent = false;
        }
    }

    function createSeries(startTime, model, benchmark){

        var modelData,
            leverage,
            startModel,
            performance,
            newSeries,
            models = $scope.modelContributions;

        angular.forEach(models, function(model, rid){

            if (rid !== '_empty_'){
                modelData = [];
                leverage = 1;
                startModel = 1000000;

                var benchmark = $scope.benchmarkPerformance;

                for (var day in model) {

                    if (typeof model[day] !== 'undefined' && 
                        typeof benchmark[day] !== 'undefined' &&
                        benchmark[day] !== null) {
                          var timestamp = day*1000,
                              currentModel = (startModel + ( parseFloat(model[day] * leverage) / 100 ) * (startModel));

                          modelData.push( {x: timestamp, y: currentModel, value: model[day]} );

                          startModel = currentModel;
                    }
                }

                // abort if we don't have any points
                // this could be caused by the benchmark and model return stream not having the same timestamps
                if (!modelData.length) return;

                // add starting point to beginning of the series'
                var firstPoint = modelData[0].x;

                var obj = {
                    x: (firstPoint / 1000 - (86000 * 2) ) * 1000,
                    y: 1000000,
                    value: 0.00
                };

                modelData.unshift(obj);

                var daysArr = _.range(0, 7);

                var todayTime = modelData[modelData.length - 1].x;

                // add null points for the rest of the current month to extend the chart space to the right of the last point
                _.each(daysArr, function(day){
                    var obj = {
                        x: ( todayTime / 1000 + day * (86400 * 2) ) * 1000,
                        y: null,
                        value: 0.00
                    };

                    modelData.push(obj);
                });

                var modelFromBatch = _.find($scope.batch, {fid: rid});

                newSeries = {

                    id: rid,
                    name: $scope.modelNames[rid],
                    type:'line',
                    individual: true,
                    lineWidth: 2,
                    connectNulls: true,
                    data: modelData,
                    visible: $scope.chartViewToggle.selected === 'Individual' ? true : false

                };

                $scope.portfolioChart.series.push(newSeries);
            } else {
                console.warn("Guid is empty ", model);
            }
        });
    }

    function mapAggregateMonths(months){

        // in case we don't start with 01 as the first key
        var months = angular.copy(months);
        var numMonths = Object.keys(months).length;
        var firstMonth = _.min(Object.keys(months));

        var values = [];
        var range = _.range(parseInt(firstMonth, 10),13);

        _.each(range, function(i){
          if (i < 10){
            i = "0" + i;
          }
          var month = months[i];

          values.push(month);
        });

        return values;

    }

    function sumAggregateReturn(values, parseMonths){

        if (parseMonths) values = mapAggregateMonths(values);

        var percentAsFloat = values.reduce(function(currentTotal, newValue){
                                  if (angular.isDefined(newValue)){
                                    return currentTotal * ( (newValue / 100) + 1 );
                                  } else {
                                    return currentTotal * 1;
                                  }
                                }, 1 );

        YTD = (percentAsFloat - 1) * 100;

        return YTD;

    }

    function startRefreshTimer(){
        var startTime = new Date().getTime() / 1000;
        var elapsedSeconds = startTime - parentTab.lastActive;
        if (parentTab.active) {
            parentTab.updates = $interval(function() {
                if ($scope.$parent.tab.active){
                    $scope.refresh();
                }

            }, updateInterval);
        }
    }

    function killTimer(){
        $interval.cancel(parentTab.updates);
        $rootScope.timerCount--;
        parentTab.updates = undefined;
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function calculateTotal() {
      $scope.schemaTotal = 0;
      $scope.minimumInvestment = 0;
      $scope.feesAndMinimums = [];

      var highest_min = 0,
        i;

      for (i = $scope.batch.length - 1; i >= 0; i--) {
        var model_min = $scope.batch[i].min; //parseInt($scope.batch[i].min.replace('$','').replace(',',''));
        var model_percent = isNaN(parseFloat($scope.batch[i].percent)) ? 0 : parseFloat(parseFloat($scope.batch[i].percent).toFixed(2));
        var schemaTotal = parseFloat(parseFloat(parseFloat($scope.schemaTotal) + model_percent).toFixed(2));

        $scope.feesAndMinimums.push({
          name: $scope.batch[i].label,
          min: 1 * ($scope.batch[i].min).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          feeCalc: (parseFloat($scope.batch[i].fee) * 100).toFixed(2) + ' * ' + (parseFloat(model_percent) / 100).toFixed(2) + ' = ' + (parseFloat($scope.batch[i].fee) * parseFloat(model_percent)).toFixed(2),
          fee: 1 * (parseFloat($scope.batch[i].fee * 100) * parseFloat(model_percent / 100)).toFixed(2)
        });

        // round to 2 decimal places
        schemaTotal = +(Math.round(schemaTotal + "e+2") + "e-2");

        $scope.schemaTotal = schemaTotal;
        if (schemaTotal <= 100) {
          //Calculate model min
          if (model_percent > 0) {
            model_min = model_min / (model_percent / 100);
            if (model_min > highest_min) {
              highest_min = model_min;
            }
          }
        }
      }

      $scope.minimumInvestment = highest_min;

      for (i = $scope.batch.length - 1; i >= 0; i--) {
        $scope.batch[i].acc_min = $scope.minimumInvestment * (model_percent / 100);
        $scope.feesAndMinimums[i].acc_min = ($scope.batch[i].acc_min).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      $scope.schemaRemaining = 100 - parseFloat(parseFloat($scope.schemaTotal).toFixed(2));
      if ($scope.showSimulatedPortfolioBtnTxt === 'Hide') {
        $scope.weightsChanged = true;
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
      }

      $scope.feesAndMinimums.push({
        name: 'Total',
        min: '',
        fee: $scope.feesAndMinimums.reduce(function(total, item) {
          return parseFloat(total) + parseFloat(item.fee);
        }, 0),
        acc_min: (parseInt($scope.minimumInvestment).toFixed(0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
    }

})
.filter('bytype', function(){

    // format allocation value based on allocation type
    return function (input, type) {

        if (type === 'AllocateFixedDollar'){
            return "$" + input;
        }

        if (type === 'RedeemPercentageOfFund'){
            return input + "%";
        }
    };
})
.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
})
.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
})
.filter('orderObjectBy', function(){
    return function(input, attribute) {
        if (!angular.isObject(input)) return input;

        var array = [];

        for(var objectKey in input) {
            array.push(input[objectKey]);
        }

        array.sort(function(a, b){
            a = parseInt(a[attribute]);
            b = parseInt(b[attribute]);
            return a - b;
        });

        return array;
    };
});