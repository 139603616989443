angular.module('infoPopup', [])
.directive("moreInfo",function(){
	return {
		restrict: 'E',
		scope : {
			info : '='
		},
		template: '<div style="display: inline-block" popover-placement="{{info.placement}}" popover-title="{{info.title}}" uib-popover-template="info.templateUrl" uib-popover="{{info.content}}">' +
				   ' <i class="fa fa-question-circle"></i>' +
				   '</div>'
	};


});