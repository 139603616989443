angular.module('ExclusionsCtrl')
.controller('ExclusionModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'exclusion', 'toastr', 'Dashboard', 'ExclusionsFactory', 'SweetAlert', 'PortfolioEditorFactory', '$filter', '$q', function ($scope, $rootScope, $uibModalInstance, exclusion, toastr, Dashboard, ExclusionsFactory, SweetAlert, PortfolioEditorFactory, $filter, $q) {

	var vm = this;

	vm.exclusionTypes = ExclusionsFactory.getExclusionTypes();
	vm.selectedExclusionType = exclusion ? exclusion.exclusionKind : 'doNotHold';

	vm.substitutionTypes = ExclusionsFactory.getSubstitutionTypes();
	vm.selectedSubstitutionType = exclusion ? getSubstitutionType(exclusion) : 'subToCash'; // vm.substitutionTypes[0].value
	
	vm.exclusion = exclusion; // when editing, this will be the selected row

	vm.selectedSymbol = null;
	vm.loadingSymbolResults = false;
	vm.onSymbolSelected = onSymbolSelected;
	vm.onSubSymbolSelected = onSubSymbolSelected;
	vm.getSymbolSearchResults = getSymbolSearchResults;
	vm.selectedSecurity = null;

	// this needs to be attached to scope to be called correctly within the on-enter directive
	$scope.onEnter = onEnter;

	vm.dateOptions = {
		formatYear: 'yy',
		startingDay: 1
	};
	
	vm.calendar = {
		start : { opened : false},
		end : { opened : false}
	};
	
	vm.open = function(popup) {
		if (popup == 'start') {
			vm.calendar.start.opened = true;
		}else if (popup == 'end') {
			vm.calendar.end.opened = true;
		}
	};
	
  vm.format = 'dd-MMMM-yyyy';
	vm.form = {
		account 		: '',
		symbol 			: '',
		startDate		: new Date(),
		endDate 		: null,
		addBackOnEnd 	: true,
		substitute 		: '',
		
		existing				: false,
		subToCash: false,
		subToTicker: false,
		subToSymbolId: null,
		exclusionKind: 'doNotHold'
	};

	vm.operationType = 'Exclusion';
	vm.mode = 'basic';

	vm.minDate = new Date();
	vm.maxDate = new Date(2030, 12, 31);

	vm.dropdownOptions = {
		enableSearch: true
	};
	vm.dropdownSettings = {
    enableSearch: true, 
    searchField: '$', 
    idProp: 'id', 
    // displayProp: 'name', 
    externalIdProp: '',
    selectedToTop: true,
		scrollable: true,
		template: "<span>{{option.name}}</span><br/><span class='text-muted'>{{option.brokerageAccountNumber}}</span>"
	};

	vm.customTexts = {
    buttonDefaultText: 'Select Accounts',
    dynamicButtonTextSuffix: 'selected',
    searchPlaceholder: 'Search by name or brokerage number'
  };
	
	vm.accountsModel = [];
	vm.accountList = [];
	vm.errorMsg = null;	

	vm.accessAccounts = /* Dashboard.getUserAccounts().length ?  Dashboard.getUserAccounts() : */ Dashboard.getAccessAccounts();
	vm.accessAccountGUIDs = _.pluck(vm.accessAccounts, 'id');	

	$scope.$watch('vm.selectedExclusionType', function(newVal, oldVal){
		console.log(newVal, oldVal);
	});

	$scope.$watch('vm.operationType', function(newVal, oldVal){
		if (!vm.exclusion) {
			if (newVal === 'Exclusion'){
				console.log("exclusion selected");
				vm.form = _.extend(vm.form, {
					subToTicker: null,
					subToSymbolId: null,
					subToCash: false,
					startDate: vm.form.startDate || moment().unix() * 1000,
					endDate: vm.form.endDate || null,
					addBackOnEnd: true,
					exclusionKind: 'doNotHold'
				});

				vm.substitutionTypes = [
					// {
					// 	value: '', text: 'Select Action'
					// },
					{
						value: 'subToCash', text: 'Hold Cash'
					},
					{
						value: 'longExposure', text: 'Reallocate to the rest of the portfolio'
					}
				];

				vm.selectedSubstitutionType = 'longExposure';

			} else if (newVal === 'Substitution') {
				console.log("substitution selected");
				vm.form = _.extend(vm.form, {
					subToTicker: '',
					subToSymbolId: '',
					subToCash: false,
					startDate: vm.form.startDate || moment().unix() * 1000,
					endDate: vm.form.endDate || null,
					addBackOnEnd: true,
					exclusionKind: 'doNotHold'
				});

				vm.substitutionTypes = [
					{
						value: 'subToCash', text: 'Hold Cash'
					},
					{
						value: 'subToSymbol', text: 'Hold Security'
					},
					{
						value: 'longExposure', text: 'Reallocate to the rest of the portfolio'
					}
				];

				vm.selectedSubstitutionType = 'subToSymbol';
			}
		}
		console.log("Updated form: ", vm.form);
	});
	
	init();
	
	/////////////
	
	vm.cancel = cancel;
	vm.submit = submit;
	vm.disabled = disabled;
	vm.toggleExisting = toggleExisting;
	vm.setAccount = setAccount;
	vm.removeAccount = removeAccount;	
	vm.maxVisibleAccts = 3;	
	vm.selectedAccounts = [	
		// {	
		// 	id: "b745b54b-26f3-440e-9a55-0ab0bf022a20",	
		// 	brokerageAccountNumber: "8DI05791"	
		// },{	
		// 	id: "edd67fc2-3033-4f7a-8b52-2a96ea969fa5",	
		// 	brokerageAccountNumber: "941559786"	
		// },	
		// {	
		// 	id: "eb229731-5ad0-49a6-b290-8984edc9d4a4",	
		// 	brokerageAccountNumber: "31415942"	
		// },	
		// {	
		// 	id: "de94c8ef-b336-4f43-9463-8b47dcc12ac6",	
		// 	brokerageAccountNumber: "943314952"	
		// },	
		// {	
		// 	id: "e053d43e-594a-4634-a898-91c482c25c3b",	
		// 	brokerageAccountNumber: "941789581"	
		// },	
	];

	$scope.$watch('vm.form.symbol', function(newVal, oldVal){
		if (_.isEmpty(newVal)) {
			vm.selectedSecurity = null;
		}
	});

	$scope.$watch('vm.form.substitute', function(newVal, oldVal){
		if (_.isEmpty(newVal)) {
			vm.selectedSubSecurity = null;
		}
	});

	function getOperationType(exclusion) {
		var subToCash = exclusion.subToCash;
		var subToSymbol = exclusion.subToSymbol;
		if (subToSymbol) {
			return 'Substitution';
		} else if (!subToCash && !subToSymbol) {
			return 'Exclusion';
		} else {
			return 'Exclusion';
		}
	}

	function getSubstitutionType (exclusion) {
		if (!exclusion.subToSymbol && !exclusion.subToCash) {
			return 'longExposure';
		} else if (exclusion.subToCash) {
			return 'subToCash';
		} else if (exclusion.subToSymbol) {
			return 'subToSymbol';
		}
	}
	
	function init()
	{
		if( typeof vm.exclusion != 'undefined' && vm.exclusion != null ){
			
			vm.accountsModel.push({id: vm.exclusion.id});

			vm.selectedAccount = vm.exclusion.account;
			vm.selectedSecurity = vm.exclusion.symbol;
			
			vm.form.account 			= vm.exclusion.account.name;
			vm.form.symbol 				= vm.exclusion.symbol.ticker;
			vm.form.startDate 		= vm.exclusion.startDate ? vm.exclusion.startDate.timestamp * 1000 : '--';
			vm.form.endDate 			= vm.exclusion.endDate ? vm.exclusion.endDate.timestamp * 1000 : null;
			vm.form.addBackOnEnd 	= vm.exclusion.addBackOnEnd;
			vm.form.substitute 		= vm.exclusion.subToSymbol ? vm.exclusion.subToSymbol.ticker : null;
			vm.form.closed 				= vm.exclusion.closed;
			vm.form.subToCash     = vm.exclusion.subToCash;
			vm.operationType 			= getOperationType(vm.exclusion);
			vm.selectedExclusionType = vm.exclusion.exclusionKind;
			vm.selectedSubstitutionType = getSubstitutionType(vm.exclusion);
			
			var now = new Date();
			var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			var todayTimestamp = startOfDay / 1000;
			
			if(todayTimestamp > (vm.form.startDate / 1000)){
				vm.form.existing		= true;
				disableFormForRunningExclusion();
			}else{
				vm.form.currentExclusion = false;
			}
			
			if(vm.form.endDate != 0){

				if(todayTimestamp > vm.form.endDate){
					vm.form.expired			= true;
				}else{
					vm.form.includeExpiration = true;
				}
			}else{
				vm.form.endDate = null;
			}
			
			if(vm.form.substitute){
				vm.form.substituteSymbol = true;
			}
		}
	}
	
	function disableFormForRunningExclusion()
	{
		vm.form.holdExistingDisabled = true;
		vm.form.closingDisabled = true;
	}

	function setAccount (account) {	
		vm.selectedAccounts.push(account);	
		$scope.$broadcast('exclusionAccountAdded', account);	
	}	
	function removeAccount (account) {	
		var acctIndex = _.findIndex(vm.selectedAccounts, account);	
		vm.selectedAccounts.splice(acctIndex, 1);	
	}

  function submit() {
		console.log(vm.form);
		var temp = {};
		
		if( angular.isUndefined(vm.selectedAccount) && vm.selectedAccounts.length == 0 ){
			SweetAlert.swal("Error", "Please select an account.", "error");
			return false;
		}
		else if( vm.form.symbol.length == 0 ){
			SweetAlert.swal("Error", "Please enter a symbol.", "error");
			return false;
		}

		if(vm.exclusion != null && typeof vm.exclusion.id != 'undefined'){
			temp.id = vm.exclusion.id;
		}

		if (temp.id) {
			// only end date can be updated
			var action = {
				type: 'update',
				payload: {
					id: vm.exclusion.id,
					endDate: moment(vm.form.endDate).format(),
					addBackOnEnd: vm.form.addBackOnEnd
				}				
			};
		} else {

			var newExclusions = [];
			// build a payload for each of the selected accounts	
			vm.selectedAccounts.forEach(function(account) {	
				temp = {};	
				temp.accountId = account.id; // vm.accountsModel[i].id;	
				temp.acctNum = account.brokerageAccountNumber;
				temp.symbolId = vm.selectedSecurity ? vm.selectedSecurity.id : null;
				
				if(vm.form.startDate) {
					if (vm.form.currentExclusion) {
						temp.startDate = moment().startOf('day').unix();
					} else {
						temp.startDate = parseInt(moment(vm.form.startDate).unix()).toFixed(0);
					}
				}
				if(vm.form.endDate) temp.endDate = parseInt(vm.form.endDate.getTime() / 1000).toFixed(0);
				

				temp.exclusionKind = vm.selectedExclusionType;
				temp.addBackOnEnd = vm.form.addBackOnEnd;
				temp.ticker = vm.selectedSecurity.ticker;
				// temp.subToCash = vm.selectedSubstitutionType == 'subToCash' ? true : false;
				
				if (vm.selectedSubSecurity) {
					temp.subToTicker = vm.selectedSubSecurity.ticker;
					temp.subToSymbolId = vm.selectedSubSecurity.id;
				}

				if (vm.selectedSubstitutionType === 'longExposure'){
					temp.subToTicker = null;
					temp.subToCash = false;
					temp.subToSymbolId = null;
				} else if (vm.selectedSubstitutionType === 'subToCash') {
					temp.subToCash = true;
					temp.subToTicker = null;
					temp.subToSymbolId = null;
				} else {
					temp.subToCash = false;
				}

				if (temp.startDate) temp.startDate = moment(parseInt(temp.startDate) * 1000).format(); // (new Date(parseInt(temp.startDate) * 1000);
				if (temp.endDate) temp.endDate = new Date(parseInt(temp.endDate) * 1000);

				newExclusions.push(temp);
			});
			
			action = {
				type: 'create',
				payload: newExclusions
			};
		}

		if (vm.selectedAccounts.length) {
			var title = "Are you sure you want to exclude " + vm.form.symbol.toUpperCase() + " from " + vm.selectedAccounts.length + " account" + ((vm.selectedAccounts.length > 1) ? 's' : '') + "?";
		} else {
			var title = "Are you sure you want to exclude " + vm.form.symbol.toUpperCase() + " from account " + vm.selectedAccount.brokerageAccountNumber + "?";
		}

        SweetAlert.swal({
           title: title,
          //  text: "You will not be able to reverse this action.",
           type: "warning",
           showCancelButton: true,
           confirmButtonColor: "#DD6B55",
           confirmButtonText: "Yes",
           cancelButtonText: "No",
           closeOnConfirm: true,
           closeOnCancel: true,
           showLoaderOnConfirm: true,
           allowEscapeKey: true,
        },
        function(isConfirm){
            if (isConfirm){

							var request;

							// switch (action.type) {
							// 	case 'update':
							// 		request = ExclusionsFactory.update(action.payload.id, { endDate: action.payload.endDate, addBackOnEnd: action.payload.addBackOnEnd });
							// 		break;
							// 	case 'create':
							// 		request = ExclusionsFactory.create(action.payload);
							// 		break;
							// 	default:
							// 		break;
							// }
							
							vm.processingRequest = true;
							if (vm.selectedAccounts.length) {

								var numComplete = 0;
								var exclusionsApi = new SmartX.ExclusionsApi();

								vm.allAccountsProcessed = false;

								// loop through the payload that was created for each account
								action.payload.forEach(function(payload){

									console.log(payload);

									$q.when(exclusionsApi.create(payload))
									.then(function(res){
										// remove the completed ones from the selected accounts list
										try {
											var acctIndex = _.findIndex(vm.selectedAccounts, { id: payload.accountId });
											// vm.selectedAccounts.splice(acctIndex, 1);
											vm.selectedAccounts[acctIndex].success = true;
										} catch (e) {
											console.error(e);
										}
									})
									.catch(function(err){
										var msg = "Failed excluding from account " + payload.acctNum + ": " + err.message;
										Dashboard.toastError(msg, err);
									})
									.finally(function(){
										numComplete++;

										if (numComplete === vm.selectedAccounts.length) {
											vm.allAccountsProcessed = true;
											vm.processingRequest = false;

											// remove the successfully processed accounts from dialog
											vm.selectedAccounts = vm.selectedAccounts.filter(function(account){
												return !account.success;
											});

											if (!vm.selectedAccounts.length) {
												toastr.success('Exclusions Added', 'Success');
												$uibModalInstance.dismiss('cancel');
											}

											$rootScope.$broadcast('update-exclusions');
										}
									});

								});
							} else {
								// request // ExclusionsFactory.create(payload)
								ExclusionsFactory.update(action.payload.id, { endDate: action.payload.endDate, addBackOnEnd: action.payload.addBackOnEnd })
								.then(function(response){
									vm.errorMsg = null;
									var actionVerb = action.type === 'create' ? 'created' : 'updated';
									toastr.success('Exclusion ' + actionVerb, 'Success');

									$uibModalInstance.dismiss('cancel');
									$rootScope.$broadcast('update-exclusions');
								})
								.catch(function(err){
									Dashboard.toastError(err.message, err);
								})
								.finally(function(){
									vm.processingRequest = false;
								});
							}
				
            } else {
                jQuery('.sweet-alert').remove();
            }
        });
		
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

	// Disable weekend selection
	function disabled(date, mode) {
		return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
	}
	
	function toggleExisting()
	{
		if(vm.form.holdExisting)
		{
			vm.form.closingOnly = false;
		}
	}

	function lookUpSymbol(symbol){
		if (angular.isDefined(symbol) && symbol != null && symbol.length){
			// look up the symbol to get the symbolId
		}
	}

	function onEnter(item){
    vm.onAdd(vm.selectedSecurity);
	}

	function onSymbolSelected(item, model, label){
		vm.selectedSecurity = item;
	}

	function onSubSymbolSelected(item, model, label){
		vm.selectedSubSecurity = item;
	}

	function getSymbolSearchResults(term, type) {
		var len = term.length;
		var includeModels = vm.models && vm.models.length ? true : false;
			
		term = term.toLowerCase();

		// determine which loader to show based on the input type
		if (type === 'sub') {
			vm.loadingSubSymbolResults = true; 
		} else {
			vm.loadingSymbolResults = true;
		}

		if (includeModels){
			var exactMatch;

			if (!len) return [];

			var matches = _.filter(vm.models, function(symbol) {
				var match = false;

				symbol.companyName = symbol.companyName || '';

				if (symbol.ticker.toLowerCase() == term.toLowerCase()) {
					exactMatch = symbol;
					match = true;
				} else if (len > 1 && symbol.ticker.toLowerCase().slice(0, len) == term.toLowerCase()) {
					match = true;
				} else if (len > 2 && symbol.companyName.toLowerCase().indexOf(term.toLowerCase()) != -1) {
					match = true;
				}

				return match;
			});

			if (typeof exactMatch != 'undefined') {
				var idx = matches.indexOf(exactMatch);
				matches.move(idx, 0);
			}
		}

		// MAIN-3480: 
		// use the restricted list when looking up a substitution symbol,
		// use the unrestricted list when looking up a symbol to be excluded
		var method = type === 'sub' ? 'getSymbols' : 'searchAllSymbols';

		return PortfolioEditorFactory[method](term, 'equity,mutualFund')
		.then(function(response) {
			console.log(response);
			//	TODO: 
			//	standardize response from smartx-js so response.data works for both calls
			//  other symbol lookup response handlers in this application will also need to be updated		

			// RULES-21,22 - this wasnt working with response.data.data in dashboardv3
			// added fallback to handle both
			var resultsCatchAll = response.data.data ? response.data.data : response.data;
			var results = includeModels ? matches.concat(resultsCatchAll) : resultsCatchAll;
			return results.slice(0, 15).map(function(symbol){ symbol.assetType = $filter('formatStatus')(symbol.assetType); return symbol; });
		})
		.catch(function(err){
			// toastr.error(err.message);
			Dashboard.toastError(err.message, err);
			return [];
		})
		.finally(function(){
			vm.loadingSymbolResults = false;
			vm.loadingSubSymbolResults = false;
			$scope.$apply();
		});
	}
	
}]);