var ProfileCtrl = angular.module('ProfileCtrl', ['ProfileCtrl']);

ProfileCtrl.directive('enterpriseTree', [ 'EnterpriseFactory', function(EnterpriseFactory){
  return {
    restrict: 'E',
    template: "<div id='container' style='height: 100%; width: 100%;' ng-style='{ maxWidth: containerWidth }'></div>",
    link: function ($scope,element) {
      var userEnterprise = EnterpriseFactory.getUserEnterprise();
      var enterpriseAccess = EnterpriseFactory.getEnterpriseAccess();
      var children = _.flatten([userEnterprise.enterpriseParentId ? userEnterprise : [], enterpriseAccess.filter(function(enterprise){ return enterprise.enterpriseParentId; })]);
      var masterEnterprise = _.find(enterpriseAccess, function(enterprise){ return !enterprise.enterpriseParentId; });
      var enterpriseParentIds = [];
      // masterEnterprise.name = masterEnterprise.name
      var treeData = [_.extend(masterEnterprise, { description: 'Permission: ' + masterEnterprise.permission, height: 100 })];
      var arrays = [];
      var column = 2;

      function findParent(child, column){
        if (!column) column = 3;
        var match = false;
        children.forEach(function(_child) {
          if (_child.id === child.enterpriseParentId) {
      
            var insertPosition = _.findLastIndex(arrays, function(item){
              return item[1] == child.enterpriseParentId;
            });
      
            var itemInTree = treeData.find(function(item) { return item.id == child.enterpriseParentId; });
      
            var obj = {
              name: child.name,
              id: child.id,
              parent: child.enterpriseParentId,
              color: child.permission == 'fullAccess' ? '#359154' : '#007ad0',
              description: 'Permission: ' + child.permission,
              height: 100
            };
      
            if (itemInTree.column) obj.column = itemInTree.column;
            // else obj.column = column
      
            if (insertPosition > -1) {
              arrays.splice(insertPosition + 1, 0, [child.enterpriseParentId, child.id]);
              treeData.splice(insertPosition + 1, 0, obj);
            } else {
              arrays.push([child.enterpriseParentId, child.id]);
              treeData.push(obj);
            }
            match = true;
          }
        });
      
        if (!match) {
          column += 1;
          findParent(child, column);
        }
      }

      children.forEach(function(child) {
        if (enterpriseParentIds.indexOf(child.enterpriseParentId) < 0){
          enterpriseParentIds.push(child.enterpriseParentId);
        }
        
        if (child.enterpriseParentId === masterEnterprise.id) {
          
          var insertPosition = _.findLastIndex(arrays, function(item){
            return item[1] == child.enterpriseParentId;
          });
      
          var itemInTree = treeData.find(function(item) { return item.id == child.enterpriseParentId; });
      
          var obj = {
            name: child.name,
            id: child.id,
            parent: child.enterpriseParentId,
            color: child.permission == 'fullAccess' ? '#359154' : '#007ad0',
            description: 'Permission: ' + child.permission,
            height: 100
          };
      
          if (itemInTree.column) obj.column = itemInTree.column;
          //   else obj.column = column
      
          if (insertPosition > -1) {
            arrays.splice(insertPosition + 1, 0, [child.enterpriseParentId, child.id]);
            treeData.splice(insertPosition + 1, 0, obj);
          } else {
            arrays.push([child.enterpriseParentId, child.id]);
            treeData.push(obj);
          }
        } else {
        
          var allParentIds = _.pluck(children, 'id');
          if (_.contains(allParentIds, child.enterpriseParentId)){
            column += 1;
            findParent(child, column);
          }
        }
      });

      function getTreeWidth(data){
        var maxColumnCount = _.chain(treeData)
                            .groupBy('column')
                            .mapObject(function(items){
                                return items.length;
                            })
                            .values()
                            .max()
                            .value();

        return maxColumnCount;
      }

      console.log(arrays);
      console.log(treeData);
      Highcharts.chart('container', {
      
        chart: {
          inverted: true,
          height: '100%'
        },
      
        title: {
          text: ''
        },
        series: [{
          type: 'organization',
          name: '',
          keys: ['from', 'to'],
          data: arrays,
          levels: [{
            level: 0,
            dataLabels: {
              color: 'white'
            }
          }, {
            level: 1,
            color: 'silver',
            dataLabels: {
              color: 'white'
            }
          }, {
            level: 2,
            color: '#980104'
          }, {
            level: 4,
            color: '#359154'
          }],
          nodes: treeData,
          colorByPoint: false,
          color: '#007ad0',
          dataLabels: {
            color: 'white'
          },
          borderColor: 'white',
          nodeWidth: 65
        }],
        tooltip: {
          outside: true
        },
        credits: {
          enabled: false
        },
        // exporting: {
        //   allowHTML: true,
        //   sourceWidth: 800,
        //   sourceHeight: 600
        // }
      });

      $scope.containerWidth = jQuery('.highcharts-root').width() + 'px';
    }
    };   
}]);

ProfileCtrl.controller("ProfileCtrl", function ($scope, FNAME, $http, $timeout, $q, $rootScope, $location, toastr, Tabs, USER, Brokerage, ProfileService, Dashboard, SweetAlert, DASHBOARD_INFO, EnterpriseFactory, DRUPAL_API_URL) {

    // we will store all of our form data in this object
    $scope.viewTitle = '';
    $scope.formData = {};

    $scope.generalModel = { };
    $scope.contactModel = { };
    $scope.experienceModel = { };
    $scope.employmentModel = { };
    $scope.financialModel = { };
    $scope.regulatoryModel = { };

    $scope.riaModel = {};
    $scope.settingsModel = {};

    $scope.options = {
      formState: {
        additionalSourcesIsValid: true
      }
    };
    $scope.isLoading = true;
    $scope.DASHBOARD_INFO = DASHBOARD_INFO;


    $scope.is_ria = (USER.is_ria == true || parseInt(USER.is_ria) == 1) ? true : false;

    $scope.can_edit_settings = false;

    console.log( 'user is ria: ' + $scope.is_ria );

    var menuItems;
    if ($scope.is_ria){
        menuItems = [
            { title: 'General', complete: false, disable: false, active: true },
            { title: 'Contact', complete: false, disable: false, active: false },
            { title: 'Employment', complete: false, disable: true, active: false },
            { title: 'Experience', complete: false, disable: true, active: false },
            { title: 'Financial', complete: false, disable: true, active: false },
            { title: 'Regulatory', complete: false, disable: true, active: false },
            { title: 'RIA', complete: false, disable: false, active: false },
            { title: 'Settings', complete: false, disable: false, active: false },
            // { title: 'Enterprise', complete: false, disable: false, active: false }
        ];
    } else {
        menuItems = [
            { title: 'General', complete: false, disable: false, active: true },
            { title: 'Contact', complete: false, disable: false, active: false },
            { title: 'Employment', complete: false, disable: false, active: false },
            { title: 'Experience', complete: false, disable: false, active: false },
            { title: 'Financial', complete: false, disable: false, active: false },
            { title: 'Regulatory', complete: false, disable: false, active: false },
            { title: 'Settings', complete: false, disable: false, active: false },
            // { title: 'Enterprise', complete: false, disable: false, active: false }
        ];
    }

    $scope.profile = {
        sideMenu : menuItems,
        currentPage : 'General'
    };

    // gets called multiple times because it gets registered every time the tab is initialized
    var editStep = function(origin){
        if (origin === 'brokerage' ){

            $scope.brokerageStep = Brokerage.getStep();
            if ($scope.brokerageStep.step !== undefined){
                $scope.gotoStep($scope.brokerageStep.step);
            }
        }
    };

    $scope.saveAndExit = function(model){
        $scope.syncData(model);
        $rootScope.$broadcast('profileSaved');
        removeTab();
    };

    $scope.generalModel.f_name = null;

    if($scope.generalModel.f_name == null || typeof $scope.generalModel.f_name == 'undefined'){
        $rootScope.showingWelcome = false;
    }

    $scope.currentStep = 0;
    $scope.setCurrentStepTo = function(step){
        console.log('Current step is '+ step);
        $scope.currentStep = step;
    };

    $scope.deselectStep = function(step){
        //console.log('deselectStep '+step);
    };

    $scope.gotoStep = function(step){

        var step = angular.isDefined(step) ? step : 0;
        //console.log('stepNext-> '+$scope.currentStep);
        if( $scope.currentStep+1 <= $scope.profile.sideMenu.length ){
            $timeout(function(){

                for (var i = $scope.profile.sideMenu.length - 1; i >= 0; i--) {
                    $scope.profile.sideMenu[i].active = false;
                }

                $scope.profile.sideMenu[step].active = true;

                $scope.currentStep = step;
                Brokerage.setStep(step);
                //console.log($scope.currentStep);
            });
        }

        //console.log($scope.profile.sideMenu[step]);

    };

    $scope.stepNext = function(){
        //console.log( $scope.employmentForm );
        //console.log( $scope.employmentModel );

        if( $scope.currentStep == 1 && ($scope.is_ria == 1 || parseInt($scope.is_ria) == 1) ){
            $scope.profile.sideMenu[$scope.currentStep].active = false;
            $scope.currentStep = 6;
            $scope.profile.sideMenu[$scope.currentStep].active = true;
            $scope.profile.sideMenu[$scope.currentStep].disable = false;
        }else if($scope.currentStep !== $scope.profile.sideMenu.length -1 ){
            $scope.profile.sideMenu[$scope.currentStep].active = false;
            $scope.currentStep++;
            $scope.profile.sideMenu[$scope.currentStep].active = true;
            $scope.profile.sideMenu[$scope.currentStep].disable = false;
        }
        else{
            removeTab();
        }
    };

    var userEnterprise = EnterpriseFactory.getUserEnterprise();
    var enterpriseAccess = EnterpriseFactory.getEnterpriseAccess();
    $scope.userEnterprise = userEnterprise;
    $scope.enterpriseList = _.flatten([userEnterprise, enterpriseAccess]);

    function removeTab() {
        var lastDashTab = Tabs.getLastDashboard();

        // TODO: clean up and make it so that adding a new main tab still deletes the profile tab
        if(typeof lastDashTab == 'undefined' || Tabs.getTabs().indexOf(lastDashTab) === -1){

            lastDashTab = undefined;

            // in case the lastDashTab was deleted prior to this check,
            // look for any others in the list and set the most recent one to the lastDashTab
            // this could be moved into a watcher that sets the value of lastDashTab whenever a main tab type is deleted
            Tabs.getTabs().forEach(function(tab){
                if (tab.type === 'accountsSummary'){
                    lastDashTab = tab;
                }
            });

            if (typeof lastDashTab == 'undefined' ){
                Tabs.addTab('accountsSummary');
            }

        }
        var activeTab = $scope.$parent.tab;
        var tabs = Tabs.getTabs();
        var tabIndex = tabs.indexOf(activeTab);
        $scope.removeTab(tabIndex, lastDashTab);

    }

    $scope.stepBack = function(){

        for (var i = $scope.profile.sideMenu.length - 1; i >= 0; i--) {
            $scope.profile.sideMenu[i].active = false;
        }
        if( $scope.currentStep == 6 && $scope.is_ria ){
            $scope.currentStep = 1;
            $scope.profile.sideMenu[$scope.currentStep].active = true;
        }
        else if($scope.currentStep>0){
            $scope.currentStep = $scope.currentStep-1;
            $scope.profile.sideMenu[$scope.currentStep].active = true;
            //console.log('Back to -> ' + $scope.currentStep);
        }
    };

    var switchToLastDash = function(lastDashTab, index){

        // create a closure so that lastDashTab is accessible in the context of the 'then' method
        return function(){

            // return a promise in case we want to keep chaining
            var promise = $q(function(resolve, reject){

                if (lastDashTab){
                    resolve(Tabs.setActiveTab(lastDashTab));
                } else {
                    reject("Couldn't switch to last dashboard tab");
                }
            });

            return promise;
        };
    };

    $scope.removeTab = function(index, lastDashTab){
        if (typeof lastDashTab == 'undefined'){
            Tabs.removeTab(index);
            return false;
        }

        console.log('lastDashTab', lastDashTab);

        Tabs.removeTab(index)
        .then(switchToLastDash(lastDashTab, index))
        .then(function(tab){
            console.log(tab.title + " is now the active tab!");
        })
        .catch(function(err){
            console.log(err);
            throw new Error(err);
        });

    };

    $scope.setCurrentStep = function(index){
        $scope.currentStep = index;
    };

    var getUserData = function(){

        
        Dashboard.getUserData()
        .then(function(result){
            var obj = angular.fromJson(result);
            var hcv_user = angular.fromJson(obj.data);

            console.log("User's loaded profile: ", hcv_user);
            $scope.generalModel.email = window.Drupal.settings.username;

            // Check is user can edit custom css
            if(typeof hcv_user.user.field_custom_css_enabled !== 'undefined' && hcv_user.user.field_custom_css_enabled.und.length && hcv_user.user.field_custom_css_enabled.und[0].value === '1') {
              $scope.can_edit_settings = true;
              console.log("User can edit css");

              // Check for enterprise and custom css
              if( hcv_user.enterprise && hcv_user.enterprise.field_enterprise_custom_css ){
                $scope.settingsModel.settings_custom_css = hcv_user.enterprise.field_enterprise_custom_css.und[0].value;
              }
            }


            if( hcv_user.profile && typeof hcv_user.profile.field_profile_additional_info != 'undefined' && typeof hcv_user.profile.field_profile_additional_info.und != 'undefined' ){
                var additionalData = angular.fromJson(hcv_user.profile.field_profile_additional_info.und[0].value);

                $scope.full_name = '';

                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_first_name) ){
                    $scope.generalModel.f_name = hcv_user.profile.field_profile_first_name.und[0].value;
                    $scope.full_name += ($scope.generalModel.f_name + ' ');
                    $scope.viewTitle = 'Profile';

                    if( $scope.generalModel.f_name === '' || typeof $scope.generalModel.f_name === 'undefined' ){
                        $rootScope.showingWelcome = true;
                    }else{
                        $rootScope.showingWelcome = false;
                    }
                }else{
                    $rootScope.showingWelcome = true;
                }
                $scope.isLoading = false;

                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_middle_name) ){
                    $scope.generalModel.m_name = hcv_user.profile.field_profile_middle_name.und[0].value;
                    $scope.full_name += ($scope.generalModel.m_name + ' ');
                }

                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_last_name) ){
                    $scope.generalModel.l_name = hcv_user.profile.field_profile_last_name.und[0].value;
                    $scope.full_name += $scope.generalModel.l_name;
                }
                $scope.full_name.trim();

                if( hcvObjectFieldHasData(hcv_user.profile.field_field_profile_referred_by) ){
                    $scope.generalModel.referred = hcv_user.profile.field_field_profile_referred_by.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_wants_performance) ){
                    $scope.generalModel.recieve_performance_emails = (parseInt(hcv_user.profile.field_profile_wants_performance.und[0].value) == 1) ? 'yes' : 'no';
                }

                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_address) ){
                    $scope.contactModel.primary_address1 = hcv_user.profile.field_profile_address.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_address_2) ){
                    $scope.contactModel.primary_address2 = hcv_user.profile.field_profile_address_2.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_city) ){
                    $scope.contactModel.primary_city = hcv_user.profile.field_profile_city.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_state) ){
                    $scope.contactModel.primary_state = hcv_user.profile.field_profile_state.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_zip) ){
                    $scope.contactModel.primary_zip = hcv_user.profile.field_profile_zip.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_country1) ){
                    $scope.contactModel.country = hcv_user.profile.field_profile_country1.und[0].value;
                }
                if( hcvObjectFieldHasData(hcv_user.profile.field_profile_phone_number) ){
                    $scope.contactModel.primary_phone = hcv_user.profile.field_profile_phone_number.und[0].value;
                }


                if(USER.is_ria == false){

                    $scope.employmentModel.primary_employment_type = additionalData.primary_employment_type;
                    $scope.employmentModel.primary_employment_employer = additionalData.primary_employment_employer;
                    $scope.employmentModel.primary_employment_activity = additionalData.primary_employment_activity;
                    $scope.employmentModel.primary_employment_occupation = additionalData.primary_employment_occupation;
                    $scope.employmentModel.primary_employment_country = additionalData.primary_employment_country;
                    $scope.employmentModel.primary_employment_street = additionalData.primary_employment_street;
                    $scope.employmentModel.primary_employment_street2 = additionalData.primary_employment_street2;
                    $scope.employmentModel.primary_employment_city = additionalData.primary_employment_city;
                    $scope.employmentModel.primary_employment_state = additionalData.primary_employment_state;
                    $scope.employmentModel.primary_employment_zip = additionalData.primary_employment_zip;
                    $scope.employmentModel.primary_employment_phone = additionalData.primary_employment_phone;

                    if(typeof(additionalData.primary_employment_additional_source_1) != 'undefined'){
                        $scope.employmentModel.primary_employment_additional_source_1 = additionalData.primary_employment_additional_source_1;
                        $scope.employmentModel.primary_employment_additional_source_1_percent = parseInt(additionalData.primary_employment_additional_source_1_percent);
                    }
                    if(typeof(additionalData.primary_employment_additional_source_2) != 'undefined'){
                        $scope.employmentModel.primary_employment_additional_source_2 = additionalData.primary_employment_additional_source_2;
                        $scope.employmentModel.primary_employment_additional_source_2_percent = parseInt(additionalData.primary_employment_additional_source_2_percent);
                    }
                    if(typeof(additionalData.primary_employment_additional_source_3) != 'undefined'){
                        $scope.employmentModel.primary_employment_additional_source_3 = additionalData.primary_employment_additional_source_3;
                        $scope.employmentModel.primary_employment_additional_source_3_percent = parseInt(additionalData.primary_employment_additional_source_3_percent);
                    }
                    if(typeof(additionalData.primary_employment_additional_source_4) != 'undefined'){
                        $scope.employmentModel.primary_employment_additional_source_4 = additionalData.primary_employment_additional_source_4;
                        $scope.employmentModel.primary_employment_additional_source_4_percent = parseInt(additionalData.primary_employment_additional_source_4_percent);
                    }
                    if(typeof(additionalData.primary_employment_additional_source_5) != 'undefined'){
                        $scope.employmentModel.primary_employment_additional_source_5 = additionalData.primary_employment_additional_source_5;
                        $scope.employmentModel.primary_employment_additional_source_5_percent = parseInt(additionalData.primary_employment_additional_source_5_percent);
                    }



                    if(typeof(additionalData.primary_experience_overall) != 'undefined')
                        $scope.experienceModel.primary_experience_overall = additionalData.primary_experience_overall.replace('primary_experience_overall','');
                    if(typeof(additionalData.primary_experience_risk) != 'undefined')
                        $scope.experienceModel.experience_risk = additionalData.primary_experience_risk.replace('primary_experience_risk','');
                    if(typeof(additionalData.primary_experience_loses) != 'undefined')
                        $scope.experienceModel.experience_loses = additionalData.primary_experience_loses.replace('primary_experience_loses','');
                    if(typeof(additionalData.primary_experience_retirement) != 'undefined')
                        $scope.experienceModel.experience_retirement = additionalData.primary_experience_retirement.replace('primary_experience_retirement','');


                    $scope.experienceModel.primary_experience_education = (additionalData.primary_experience_education) ? additionalData.primary_experience_education.replace(/ /g, '_') : '';

                    $scope.experienceModel.primary_experience_overall = (additionalData.primary_experience_overall && additionalData.primary_experience_overall.indexOf('primary_experience_overall') === -1)
                                                            ? 'primary_experience_overall'+additionalData.primary_experience_overall
                                                            : additionalData.primary_experience_overall;
                    $scope.experienceModel.primary_experience_risk =  (additionalData.primary_experience_risk && additionalData.primary_experience_risk.indexOf('primary_experience_risk') === -1)
                                                            ? 'primary_experience_risk'+additionalData.primary_experience_risk
                                                            : additionalData.primary_experience_risk;
                    $scope.experienceModel.primary_experience_loses =  (additionalData.primary_experience_loses && additionalData.primary_experience_loses.indexOf('primary_experience_loses') === -1)
                                                            ? 'primary_experience_loses'+additionalData.primary_experience_loses
                                                            : additionalData.primary_experience_loses;
                    $scope.experienceModel.primary_experience_retirement =  (additionalData.primary_experience_retirement && additionalData.primary_experience_retirement.indexOf('primary_experience_retirement') === -1)
                                                            ? 'primary_experience_retirement'+additionalData.primary_experience_retirement
                                                            : additionalData.primary_experience_retirement;

                    $scope.financialModel.primary_financial_net_worth = additionalData.primary_financial_net_worth;
                    $scope.financialModel.primary_financial_liq_net_worth = additionalData.primary_financial_liq_net_worth;
                    $scope.financialModel.primary_financial_ann_net_income = additionalData.primary_financial_ann_net_income;
                    $scope.financialModel.primary_financial_ann_expenses = additionalData.primary_financial_ann_expenses;
                    $scope.financialModel.primary_financial_total_assets = additionalData.primary_financial_total_assets;

                    $scope.regulatoryModel.primary_regulatory_question1_checkbox = additionalData.primary_regulatory_question1_checkbox;
                    $scope.regulatoryModel.primary_regulatory_question1_additional_1 = additionalData.primary_regulatory_question1_additional_1;
                    $scope.regulatoryModel.primary_regulatory_question1_additional_2 = additionalData.primary_regulatory_question1_additional_2;
                    $scope.regulatoryModel.primary_regulatory_question1_additional_3 = additionalData.primary_regulatory_question1_additional_3;
                    $scope.regulatoryModel.primary_regulatory_question1_additional_4 = additionalData.primary_regulatory_question1_additional_4;
                    $scope.regulatoryModel.primary_regulatory_question1_additional_5 = additionalData.primary_regulatory_question1_additional_5;

                    $scope.regulatoryModel.primary_regulatory_question2_checkbox = additionalData.primary_regulatory_question2_checkbox;
                    $scope.regulatoryModel.primary_regulatory_question2_additional = additionalData.primary_regulatory_question2_additional;

                    $scope.regulatoryModel.primary_regulatory_question3_checkbox = additionalData.primary_regulatory_question3_checkbox;
                    $scope.regulatoryModel.primary_regulatory_question3_additional = additionalData.primary_regulatory_question3_additional;

                    $scope.regulatoryModel.primary_regulatory_question4_checkbox = additionalData.primary_regulatory_question4_checkbox;
                    $scope.regulatoryModel.primary_regulatory_question4_additional = additionalData.primary_regulatory_question4_additional;

                    $scope.regulatoryModel.primary_regulatory_question5_checkbox = additionalData.primary_regulatory_question5_checkbox;
                    $scope.regulatoryModel.primary_regulatory_question5_additional = additionalData.primary_regulatory_question5_additional;
                }else{

                    //RIA
                    $scope.riaModel.ria_firms_to_custody_client_assets = additionalData.ria_firms_to_custody_client_assets;
                    $scope.riaModel.ria_other_custody_firms = additionalData.ria_other_custody_firms;
                    $scope.riaModel.ria_assets_under_management = additionalData.ria_assets_under_management;
                    $scope.riaModel.ria_accounts_currently_managing = additionalData.ria_accounts_currently_managing;
                    $scope.riaModel.ria_use_tamps = additionalData.ria_use_tamps;
                    $scope.riaModel.ria_which_tamps = additionalData.ria_which_tamps;
                    $scope.riaModel.ria_allocate_to_alternatives = additionalData.ria_allocate_to_alternatives;
                }

                //console.log( $scope.regulatoryModel );
                // TODO: only needs to register once and ignore all future attempts
                // if (ProfileService.isNotRegistered()) {
                //     Dashboard.registerObserverCallback(editStep);
                //     //ProfileService.setRegisteredStatus(true);
                // } else if ( angular.isDefined(Brokerage.getStep()) ){
                    var editStepCallback = {
                        name : 'editStep',
                        fn : editStep
                    };
                    Dashboard.registerObserverCallback(editStepCallback);
                    if (angular.isDefined(Brokerage.getStep())){
                         var step = Brokerage.getStep().step;
                        $scope.gotoStep(step);
                    }
            }else{
                console.log( 'no profile found :(' );

                $rootScope.showingWelcome = true;
                $scope.isLoading = false;
            }
        })
        .catch(function(res){
            $scope.isLoading = false;
        });
    };

    $scope.syncData = function(model){

        if($scope.generalModel.f_name){
            $scope.$emit('FirstNameSet', $scope.generalModel.f_name);
        }

        //console.log( 'syncData', $scope.currentStep );

        //Validate Additional Emmployment Sources
        if($scope.currentStep === 2){
            console.log('Validate Additional Emmployment Sources');
            //debugger
        }

        if(jQuery.map(model, function(n, i) { return i; }).length){
            console.log('Saving Model', model);

            var url = `${DRUPAL_API_URL}/ng_api/sync_profile`,
                payload = {};

            payload.model = model;

            var req = {
              method: 'POST',
              url: url,
              data:  jQuery.param(payload),
              headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            };

            //console.log(req);
            var submitAccount = ( typeof $scope.profile.sideMenu != 'undefined' && ($scope.currentStep === ($scope.profile.sideMenu.length-1)) );
            $scope.stepNext();

            //console.log('Submittin account '+ submitAccount);
            $http(req)
            .then(function(result){

                var profileObj = JSON.parse(result.data.profile);
                console.log(profileObj);
                if(result.status == 200 && result.data.status == "success"){
                    var data = angular.fromJson(result.data);

                    if(submitAccount) {

                        toastr.success('Your profile is complete! Next open a brokerage account.', 'Profile');

                        var req = {
                          method: 'POST',
                          url: "/myhcv/hcv_submit_account_profile",
                          data:  [],
                          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                        };

                        $http(req)
                        .then(function(result){

                            var obj = jQuery.parseJSON(result.data);
                            //console.log(result, obj);

                            if(obj.field_user_profile_completed === 1){
                                $rootScope.$broadcast( 'UserProfileCompleted', 'complete' );
                            }

                            if(obj.result==='success'||obj.mailchimp_update==='success'){


                            }else if(obj.result==='updated'||obj.mailchimp_update==='updated'){


                            }else{
                                SweetAlert('Could not create paper trading account. Please contact support.');
                                //alert('Could not create paper trading account. Please contact support.');
                            }
                        });
                    }

                }else{
                    console.log('Could not sync profile. No profile object.');
                    $scope.currentStep--;
                    $scope.gotoStep($scope.currentStep);

                    SweetAlert.swal({
                        title: "Warning?",
                        text: "Uh oh!! Could not sync your profile!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Okay"},
                        function(){
                            console.log('Went back b/c step not saved.');
                    });
                }
            });
        }
    };


    $scope.generalFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'f_name',
                    className: 'col-md-4',
                    type: 'input',
                    templateOptions: {
                        label: 'First Name',
                        required: true,
                        focus: true
                    }
                },{
                    key: 'm_name',
                    className: 'col-md-4',
                    type: 'input',
                    templateOptions: {
                        label: 'Middle Name'
                    }
                },{
                    key: 'l_name',
                    className: 'col-md-4',
                    type: 'input',
                    templateOptions: {
                        label: 'Last Name',
                        required: true
                    }
                }
            ]
        },{
            template: '<hr /><h3>Sign In:</h3>'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'email',
                    className: 'col-md-12',
                    type: 'input',
                    templateOptions: {
                      label: 'Email',
                    },
                    expressionProperties: {
                        'templateOptions.disabled': function($viewValue, $modelValue, scope) {
                            return true;
                        }
                    }
                },
                /*{
                    key: 'password',
                    className: 'col-md-12',
                    type: 'input',
                    templateOptions: {
                        type: 'password',
                        label: 'Password (only enter to change)'
                    },
                    expressionProperties: {
                        'templateOptions.autocomplete ': function($viewValue, $modelValue, scope) {
                            return 'off';
                        }
                    }
                },*/
                {
                    key: 'referred',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'How did you hear about us?',
                        options: ProfileService.referrals
                    }
                },
                {
                    key: 'recieve_performance_emails',
                    type: 'radio',
                    className: 'col-md-12',
                    defaultValue: 'yes',
                    templateOptions: {
                        label: 'Would you like to receive performance emails?',
                        options: ProfileService.yesNoOpts,
                    }
                },
            ]
        }
    ];


    $scope.contactFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'country',
                    type: 'select',
                    className: 'col-md-12',
                    defaultValue: 'United States',
                    templateOptions: {
                        label: 'Country',
                        options: ProfileService.countries,
                        required: true,
                        valueProp: "name",
                        labelProp: "name"
                    }
                },{
                    key: 'primary_address1',
                    type: 'input',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Address 1',
                        required: true
                    }
                },{
                    key: 'primary_address2',
                    type: 'input',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Address 2'
                    }
                },{
                    key: 'primary_city',
                    type: 'input',
                    className: 'col-md-4',
                    templateOptions: {
                        label: 'City',
                        required: true
                    }
                },{
                    key: 'primary_state',
                    type: 'select',
                    className: 'col-md-4',
                    templateOptions: {
                        label: 'State',
                        options: ProfileService.USStates,
                        required: true
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        if ( typeof scope.model.country == 'undefined' ) return true;
                        switch(scope.model.country){
                            case 'United States':
                            case 'USA':
                            case 'US':
                            case '':
                                return false;
                                break;
                            default:
                                return true;
                                break;
                        }
                    }
                },{
                    key: 'primary_zip',
                    type: 'input',
                    className: 'col-md-4',
                    templateOptions: {
                      label: 'Zip',
                    }
                },{
                    key: 'primary_phone',
                    type: 'input',
                    className: 'col-md-12',
                    templateOptions: {
                      label: 'Phone Number',
                    }
                }
            ]
        },
    ];


    $scope.experienceFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_experience_education',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Education',
                        options: ProfileService.education,
                        required: true
                    }
                },{
                    key: 'primary_experience_overall',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Overall Experience ',
                        options: ProfileService.overallExperienceOpts,
                        required: true
                    }
                },{
                    key: 'primary_experience_risk',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'To obtain above-average returns on my Investments, I am willing to accept above-average risk. ',
                        options: ProfileService.riskExperienceOpts,
                        required: true
                    }
                },{
                    key: 'primary_experience_loses',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'If my investments lose money over the course of a year, I can easily resist the temptation to sell them.',
                        options: ProfileService.losesExperienceOpts,
                        required: true
                    }
                },{
                    key: 'primary_experience_retirement',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'When I put aside money for retirement, I do not plan on accessing it before I retire.',
                        options: ProfileService.retirementExperienceOpts,
                        required: true
                    }
                },
            ]
        },
    ];


    function hasPreviousAdditionalSource(model, field){
        return (typeof model != 'undefined' && (typeof field != 'undefined' && field != ''));
    }


    function hideAdditionalSourcesFields(model){

        if( typeof model == 'undefined' || !model.hasOwnProperty('primary_employment_type' ) ||typeof model.primary_employment_type == 'undefined' || model.primary_employment_type == '' ){
            return true;
        }else if(model.primary_employment_type == 'Employed'){
            return true;
        } else if(model.primary_employment_type == 'Self-Employed'){
            return true;
        } else
            return false;
    }


    function hideAdditionalEmploymentFields(model){
        //console.log('checking type ', model);

        if( typeof model == 'undefined' || model == '' || !model.hasOwnProperty('primary_employment_type') || model.primary_employment_type == '' ){
            return true;
        }else if(model.primary_employment_type == 'Employed'){
            return false;
        }else if(model.primary_employment_type == 'Self-Employed'){
            return false;
        }else return true;
    }

    function hcvObjectFieldHasData(object){
        if( typeof(object) != 'undefined' ){
            //console.log(object);
            if( object.hasOwnProperty('und') && object.und.length ){
                if( object.und[0].value !== null ){
                    return true;
                }
            }
        }

        return false;
    }

    $scope.employmentFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_employment_type',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Employment Type',
                        options: ProfileService.employmentType,
                        required: true
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue == 'Employed' || newValue == 'Self-Employed') {
                            scope.model.primary_employment_additional_source_1 = '';
                            scope.model.primary_employment_additional_source_1_percent = '';
                            scope.model.primary_employment_additional_source_2 = '';
                            scope.model.primary_employment_additional_source_2_percent = '';
                            scope.model.primary_employment_additional_source_3 = '';
                            scope.model.primary_employment_additional_source_3_percent = '';
                            scope.model.primary_employment_additional_source_4 = '';
                            scope.model.primary_employment_additional_source_4_percent = '';
                            scope.model.primary_employment_additional_source_5 = '';
                            scope.model.primary_employment_additional_source_5_percent = '';
                            scope.model.primary_employment_additional_sources_total = '';
                            scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                            //console.log('Cleared out additional sources', newValue);
                        }
                      }
                    }
                },{
                    key: 'primary_employment_employer',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Name of employer',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        //debugger

                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_activity',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Nature of Employer Activities',
                        options: ProfileService.employmentActivity
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_occupation',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Occupation',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_country',
                    type: 'select',
                    className: 'col-xs-12',
                    defaultValue: 'United States',
                    templateOptions: {
                        label: 'Country',
                        options: ProfileService.countries
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_street',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'Employer Address',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_street2',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'Addresss 2',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    }
                },{
                    key: 'primary_employment_city',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'City',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_state',
                    type: 'select',
                    className: 'col-xs-12',
                    //hideExpression: "!contactModel.country",
                    templateOptions: {
                      label: 'State',
                        options: ProfileService.USStates
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {

                        if(scope.model.primary_employment_type != 'Employed' && scope.model.primary_employment_type != 'Self-Employed'){
                            return true;
                        }

                        if(scope.model.primary_employment_type == 'Employed')
                            return false;
                        else if(scope.model.primary_employment_type == 'Self-Employed')
                            return false;
                        else if(scope.model.primary_employment_country == 'US')
                            return false;
                        else if(scope.model.primary_employment_country == 'United States')
                            return false;
                        else
                            return true;
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_zip',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'Zip',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    },
                    expressionProperties: {
                      "templateOptions.required": "model.primary_employment_type == 'Employed' || model.primary_employment_type == 'Self-Employed'"
                    }
                },{
                    key: 'primary_employment_phone',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'Phone Number',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalEmploymentFields(scope.model);
                    }
                }


                ,{
                    noFormControl: true,
                    template: '<h4 class="step-title col-xs-12">Please provide information on your additional sources of income.</h4>',
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalSourcesFields(scope.model);
                    }
                },
                ,{
                    key: 'primary_employment_additional_source_1',
                    type: 'select',
                    className: 'col-xs-12 col-sm-9',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Source 1',
                        options: ProfileService.additionalSourcesOfIncome
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalSourcesFields(scope.model);
                    }
                },{
                    key: 'primary_employment_additional_source_1_percent',
                    type: 'select',
                    className: 'col-xs-12 col-sm-3',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Percent',
                        options: ProfileService.additionalSourcesPercent
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalSourcesFields(scope.model);
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined' && parseInt(newValue) > 0) {
                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            //debugger
                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                scope.form.$invalid = true;
                                scope.$parent.form.$invalid = true;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                scope.form.$invalid = true;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                scope.form.$invalid = false;
                            }
                        }
                      }
                    }
                },{
                    key: 'primary_employment_additional_source_2',
                    type: 'select',
                    className: 'col-xs-12 col-sm-9',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Source 2',
                        options: ProfileService.additionalSourcesOfIncome
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_1_percent));
                    }
                },{
                    key: 'primary_employment_additional_source_2_percent',
                    type: 'select',
                    className: 'col-xs-12 col-sm-3',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Percent',
                        options: ProfileService.additionalSourcesPercent
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_1_percent));
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined' && parseInt(newValue) > 0) {
                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                scope.form.$invalid = true;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                scope.form.$invalid = true;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                scope.form.$invalid = false;
                            }
                        }
                      }
                    }
                },{
                    key: 'primary_employment_additional_source_3',
                    type: 'select',
                    className: 'col-xs-12 col-sm-9',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Source 3',
                        options: ProfileService.additionalSourcesOfIncome
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_2_percent));
                    }
                },{
                    key: 'primary_employment_additional_source_3_percent',
                    type: 'select',
                    className: 'col-xs-12 col-sm-3',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Percent',
                        options: ProfileService.additionalSourcesPercent
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_2_percent));
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined' && parseInt(newValue) > 0) {
                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                scope.form.$invalid = true;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                scope.form.$invalid = true;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                scope.form.$invalid = false;
                            }
                        }
                      }
                    }
                },{
                    key: 'primary_employment_additional_source_4',
                    type: 'select',
                    className: 'col-xs-12 col-sm-9',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Source 4',
                        options: ProfileService.additionalSourcesOfIncome
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_3));
                    }
                },{
                    key: 'primary_employment_additional_source_4_percent',
                    type: 'select',
                    className: 'col-xs-12 col-sm-3',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Percent',
                        options: ProfileService.additionalSourcesPercent
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_3_percent));
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined' && parseInt(newValue) > 0) {
                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                scope.form.$invalid = true;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                scope.form.$invalid = true;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                scope.form.$invalid = false;
                            }
                        }
                      }
                    }
                },{
                    key: 'primary_employment_additional_source_5',
                    type: 'select',
                    className: 'col-xs-12 col-sm-9',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Source 5',
                        options: ProfileService.additionalSourcesOfIncome
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_4_percent));
                    }
                },{
                    key: 'primary_employment_additional_source_5_percent',
                    type: 'select',
                    className: 'col-xs-12 col-sm-3',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Percent',
                        options: ProfileService.additionalSourcesPercent
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (!hideAdditionalEmploymentFields(scope.model.primary_employment_type) || !hasPreviousAdditionalSource(scope.model, scope.model.primary_employment_additional_source_4_percent));
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined' && parseInt(newValue) > 0) {
                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                scope.form.$invalid = true;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                scope.form.$invalid = true;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                scope.form.$invalid = false;
                            }
                        }
                      }
                    }
                },{
                    key: 'primary_employment_additional_sources_total',
                    type: 'input',
                    className: 'additional-sources-total col-xs-12',
                    //defaultValue: '',
                    templateOptions: {
                        label: 'Total',
                        disabled: true
                    },
                    controller: /*@ngInject*/ function($scope) {
                        //console.log( 'Scope options templateOptions', $scope.options.templateOptions );
                        //$scope.options.templateOptions.placeholder = 'This was changed in the controller';
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return hideAdditionalSourcesFields(scope.model);
                    },
                    validators: {
                        additionalSources : function($viewValue, $modelValue, scope){

                            var total = calculateAdditionalSources(scope.model);
                            scope.model.primary_employment_additional_sources_total = total;

                            if(total.replace("%","")<100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Under allocated by " + (100-total.replace("%","")) + "%";
                                return false;
                            }else if(total.replace("%","")>100){
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total - Over allocated by " + (total.replace("%","")-100) + "%";
                                return false;
                            }else{
                                scope.fields[scope.fields.length-1].templateOptions.label = "Total";
                                return true;
                            }
                        }
                    },
                }
            ]
        },
    ];

    function calculateAdditionalSources(model){
        var total = 0;

        if(typeof model.primary_employment_additional_source_1_percent != 'undefined' && parseInt(model.primary_employment_additional_source_1_percent) > 0)
            total += parseInt(model.primary_employment_additional_source_1_percent);

        if(typeof model.primary_employment_additional_source_2_percent != 'undefined' && parseInt(model.primary_employment_additional_source_2_percent) > 0)
            total += parseInt(model.primary_employment_additional_source_2_percent);

        if(typeof model.primary_employment_additional_source_3_percent != 'undefined' && parseInt(model.primary_employment_additional_source_3_percent) > 0)
            total += parseInt(model.primary_employment_additional_source_3_percent);

        if(typeof model.primary_employment_additional_source_4_percent != 'undefined' && parseInt(model.primary_employment_additional_source_4_percent) > 0)
            total += parseInt(model.primary_employment_additional_source_4_percent);

        if(typeof model.primary_employment_additional_source_5_percent != 'undefined' && parseInt(model.primary_employment_additional_source_5_percent) > 0)
            total += parseInt(model.primary_employment_additional_source_5_percent);

        return total + '%';
    }


    $scope.financialFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_financial_net_worth',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Net Worth',
                        options: ProfileService.netWorthOpts,
                        required: true
                    }
                },{
                    key: 'primary_financial_liq_net_worth',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Liquid Net Worth',
                        options: ProfileService.liquidNetWorthOpts,
                        required: true
                    }
                },{
                    key: 'primary_financial_ann_net_income',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Annual Net Income',
                        options: ProfileService.annualNetIncomeOpts,
                        required: true
                    }
                },{
                    key: 'primary_financial_ann_expenses',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Annual Expenses',
                        options: ProfileService.annualExpensesOpts,
                        required: true
                    }
                },{
                    key: 'primary_financial_total_assets',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Total Assets',
                        options: ProfileService.totalAssetsOpts,
                        required: true
                    }
                }
            ]
        }
    ];


    $scope.regulatoryFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_regulatory_question1_checkbox',
                    type: 'radio',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'Are you or anybody in your household affiliated with a broker/dealer?',
                        options: ProfileService.yesNoOpts,
                        required: true
                    }
                },{
                    key: "primary_regulatory_question1_additional_1",
                    type: "input",
                    templateOptions: {
                        label: "Person's Name"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question1_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question1_additional_1 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_2 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_3 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_4 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_5 = "";
                        }

                        return scope.model.primary_regulatory_question1_checkbox!=="yes";
                    }
                },{
                    key: "primary_regulatory_question1_additional_2",
                    type: "input",
                    templateOptions: {
                        label: "Relation to Account Holder(s)"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question1_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question1_additional_1 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_2 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_3 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_4 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_5 = "";
                        }

                        return scope.model.primary_regulatory_question1_checkbox!=="yes";
                    }
                },{
                    key: "primary_regulatory_question1_additional_3",
                    type: "input",
                    templateOptions: {
                        label: "Brokerage Firm Name"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question1_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question1_additional_1 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_2 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_3 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_4 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_5 = "";
                        }

                        return scope.model.primary_regulatory_question1_checkbox!=="yes";
                    }
                },{
                    key: "primary_regulatory_question1_additional_4",
                    type: "input",
                    templateOptions: {
                        label: "Brokerage Firm Address"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question1_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question1_additional_1 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_2 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_3 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_4 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_5 = "";
                        }

                        return scope.model.primary_regulatory_question1_checkbox!=="yes";
                    }
                },{
                    key: "primary_regulatory_question1_additional_5",
                    type: "input",
                    templateOptions: {
                        label: "Brokerage Firm Phone"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question1_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question1_additional_1 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_2 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_3 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_4 = "";
                            $scope.regulatoryModel.primary_regulatory_question1_additional_5 = "";
                        }

                        return scope.model.primary_regulatory_question1_checkbox!=="yes";
                    }
                }
            ]
        },{
            template: '<hr />'
        },
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_regulatory_question2_checkbox',
                    className: 'col-xs-12',
                    type: 'radio',
                    templateOptions: {
                        label: 'Are you affiliated with an Exchange or Self-Regulatory Organization?',
                        options: ProfileService.yesNoOpts,
                        required: true
                    }
                },{
                    key: "primary_regulatory_question2_additional",
                    type: "input",
                    templateOptions: {
                        label: "List firm and Exchanges"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question2_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question2_additional = "";
                        }

                        return (scope.model.primary_regulatory_question2_checkbox!=="yes"||!scope.model.primary_regulatory_question2_checkbox);
                    }
                }
            ]
        },{
            template: '<hr />'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_regulatory_question3_checkbox',
                    className: 'col-xs-12',
                    type: 'radio',
                    templateOptions: {
                        label: 'Have you ever been subject of or initiated litigation, arbitration, or some type of dispute or settlement procedure with a broker/dealer?',
                        options: ProfileService.yesNoOpts,
                        required: true
                    }
                },{
                    key: "primary_regulatory_question3_additional",
                    type: "input",
                    templateOptions: {
                        label: "Details, including the disposition of the litigation, arbitration or procedure"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question3_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question3_additional = "";
                        }
                        return scope.model.primary_regulatory_question3_checkbox!=="yes";
                    }
                }
            ]
        },{
            template: '<hr />'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_regulatory_question4_checkbox',
                    className: 'col-xs-12',
                    type: 'radio',
                    templateOptions: {
                        label: 'Have you ever been subject of an investigation or proceeding by any Regulatory or Self-Regulatory Agency?',
                        options: ProfileService.yesNoOpts,
                        required: true
                    }
                },{
                    key: "primary_regulatory_question4_additional",
                    type: "input",
                    templateOptions: {
                        label: "Enter the stock symbol(s) of the company(ies), separated by commas"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question4_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question4_additional = "";
                        }
                        return scope.model.primary_regulatory_question4_checkbox!=="yes";
                    }
                }
            ]
        },{
            template: '<hr />'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_regulatory_question5_checkbox',
                    className: 'col-xs-12',
                    type: 'radio',
                    templateOptions: {
                        label: 'Are you the director, 10% shareholder, or policy-making officer of a publicly traded company?',
                        options: ProfileService.yesNoOpts,
                        required: true
                    }
                },{
                    key: "primary_regulatory_question5_additional",
                    type: "input",
                    templateOptions: {
                        "label": "List firm and Exchanges"
                    },
                    hideExpression:  function($viewValue, $modelValue, scope) {
                        if(scope.model.primary_regulatory_question5_checkbox!=="yes"){
                            $scope.regulatoryModel.primary_regulatory_question5_additional = "";
                        }
                        return scope.model.primary_regulatory_question5_checkbox!=="yes";
                    }
                }
            ]
        }
    ];


    $scope.riaFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'ria_firms_to_custody_client_assets',
                    type: 'multiselect',
                    className: 'col-xs-12 ria_multiselect',
                    templateOptions: {
                        label: 'Which firms do you use to custody your client assets? (Hold Ctrl to select multiple options.)',
                        options: ProfileService.riaFirms,
                        required: true
                    }
                },{
                    key: 'ria_other_custody_firms',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'List other custody firms.',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        if( typeof scope.model == 'undefined' || typeof scope.model.ria_firms_to_custody_client_assets == 'undefined' || scope.model.ria_firms_to_custody_client_assets.length == 0 ){
                            return true;
                        }

                        for (var i = scope.model.ria_firms_to_custody_client_assets.length - 1; i >= 0; i--) {

                            if(scope.model.ria_firms_to_custody_client_assets[i] == 'other'){
                                return false;
                            }
                        }

                        return true;
                    }
                },{
                    key: 'ria_assets_under_management',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'What are your total assets under management?',
                        options: ProfileService.riaAUM,
                        required: true
                    }
                },{
                    key: 'ria_accounts_currently_managing',
                    type: 'select',
                    className: 'col-xs-12',
                    templateOptions: {
                        label: 'How many accounts do you manage currently?',
                        options: ProfileService.riaNumberOfAccounts,
                        required: true
                    }
                },{
                    key: 'ria_use_tamps',
                    type: 'radio',
                    className: 'col-xs-12',
                    defaultValue: 'no',
                    templateOptions: {
                        label: 'Do you use any TAMPS?',
                        options: ProfileService.yesNoOpts,
                    }
                },{
                    key: 'ria_which_tamps',
                    type: 'input',
                    className: 'col-xs-12',
                    templateOptions: {
                      label: 'If so, which?',
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {

                        if(typeof scope.model.ria_use_tamps == 'undefined'){
                            return true;
                        }

                        return ( scope.model.ria_use_tamps == 'no' ) ? true : false;
                    }
                },{
                    key: 'ria_allocate_to_alternatives',
                    type: 'radio',
                    className: 'col-xs-12',
                    defaultValue: 'no',
                    templateOptions: {
                        label: 'Do you currently allocate to alternatives?',
                        options: ProfileService.yesNoOpts,
                    }
                },
            ]
        },
    ];


    $scope.settingsFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'settings_custom_css',
                    type: 'textarea',
                    className: 'col-xs-12 settings_custom_css',
                    templateOptions: {
                        label: 'Add custom css',
                        rows: 20
                    }
                },
            ]
        },
    ];

    getUserData();

    $scope.$on('$destroy', function(){
        $scope.profile = {
            sideMenu : [
                { title: 'General', complete: false, disable: false, active: true },
                { title: 'Contact', complete: false, disable: false, active: false },
                { title: 'Employment', complete: false, disable: false, active: false },
                { title: 'Experience', complete: false, disable: false, active: false },
                { title: 'Financial', complete: false, disable: false, active: false },
                { title: 'Regulatory', complete: false, disable: false, active: false }
            ]
        };
        $scope.currentStep = 0;
        Brokerage.setStep(0);

        var callbacks = Dashboard.getObserverCallbacks();

        // unregister any functions that were pushed into the observer callback array
        // otherwise the function is added to the array with every new tab instance
        // which leads to calling the registered function multiple times.

        // could also check to see if the function is in the array before it is registered
        // which would prevent registering the same function multiple times.

        // if we find an item whose name property matches one of this controller's registered methods, remove it.
        callbacks.forEach(function(cb){
            //console.log(cb);
            if (cb.name === 'editStep'){
                Dashboard.removeObserverCallback(cb);
            }
        });
    });

});