angular.module('WebinarsCtrl', [])
  .controller("webinars", function($scope, $rootScope, $http, $location, $anchorScroll, Dashboard, Models, Tabs, $sce, SmartXFactory) {

    var vm = this;

    vm.loading = false;
    vm.upcomingWebinars = [];
    vm.recordedWebinars = [];

    vm.totalItems = 0;
    vm.currentPage = 1;
    vm.maxSize = 9;

    var accessModelIds = Models.getModelList().map(function(model){ return model.id });
    var accessReportedModelIds = Models.getReportedModelList().map(function(model) { return model.id });
    var availableModelIds = accessModelIds.concat(accessReportedModelIds)

    console.log(availableModelIds)

    vm.pageChanged = function() {
      $location.hash('webinars-view');
      $anchorScroll();
    };

    vm.addTabForModel = function(model) {

      if (typeof model.reported_guid != 'undefined' || typeof model.smartx_guid != 'undefined') {

        $location.hash('');
        Models.currentModel = model;
        Tabs.addTab('model');
      }
    };

    vm.playVideo = function(viewpoint) {
      if (typeof viewpoint != 'undefined') {
        viewpoint.autoplay = true;
        Models.currentViewpoint = viewpoint;
        Tabs.addTab('viewpoint', null, viewpoint);
      }
    }

    vm.addTab = function(type) {

      var tab = Tabs.addTab(type);

      if (tab) {
        tab.new = true;

        if (type === 'holdings') {

          // update the selected account in the holdings view
          Dashboard.notifyObservers('main');
        }

        $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);


      }

      return tab;
    };

    init();

    function init() {
      // queryWebinars();
      getRecentlyAddedWebinarsFromFactory();
    }

    function getRecentlyAddedWebinarsFromFactory () {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        vm.loading = true;
        SmartXFactory.getRecentlyAddedWebinars(100)
        .then(function(resp) {

          console.log('SmartXFactory/getRecentlyAddedWebinars', resp);

          // filter results based on the models in the user has access to

          var filteredList = resp.map(function(item){

            var relatedModels = item.related_models.filter(function(model){ 
              if (typeof model.smartx_guid != 'undefined') model.smartx_guid = model.smartx_guid.toLowerCase();
              if (typeof model.reported_guid != 'undefined') model.reported_guid = model.reported_guid.toLowerCase();
              return (availableModelIds.indexOf(model.smartx_guid) != -1 || availableModelIds.indexOf(model.reported_guid) != -1)
            })

            item.related_models = relatedModels
            debugger
            return item
          })
          .filter(function(item){
            return item.related_models.length
          })

          vm.recordedWebinars = filteredList;

          vm.totalItems = vm.recordedWebinars.length;
          vm.currentPage = 1;
          vm.loading = false;

        })
        .catch(function (err) {
          vm.loading = false;
          console.log(err.message);
          debugger;
        })
        .finally(function(){
          $scope.$apply()
        })

      }

    }

    getVimeoURLFromId = function(url) {

      var a = url.split('/');
      return $sce.trustAsResourceUrl('https://player.vimeo.com/video/' + a[a.length - 1]);
    };


  });
