angular.module('FeeBlotterFactory', [])
.factory('FeeBlotter', function(){

  var enumValuesToText = {
    "platformSXFee": "Platform Fee",
    "advisoryFee":"Advisory Fee",
    "modelMaintenanceFee":"Model Maintenance Fee",
    "modelFee":"Model Fee",
    "platform3PFee":"Platform 3P Fee",
    "modelManagerFee":"Model Manager Fee",
    "techVendorFee":"Tech Vendor Fee",
    "advisorFinderFee":"Advisor Finder Fee",
    "managerReferralFee":"Manager Referral Fee",
    "platformAdvFee":"Platform ADV Fee",
    "platform3PSupervisor":"Platform 3P Supervisor",
    "modelAllSleeves":"Model All Sleeves",
    "modelMaintenanceSX":"SmartX Model Maintenance",
    "notPaid":"Not Paid",
    "insufficientFunds":"Insufficient Funds",
    "paid":"Paid",
    "approved":"Approved",
    "disapproved":"Disapproved",
    "passiveApproval":"Passive Approval",
    "sent":"Sent",
    "ready":"Ready",
    "calculationError":"Calculation Error",
    "pending":"Pending",
    "calculated":"Calculated",
    "collected":"Collected",
    "paidOut":"Paid Out",
    "collectionMismatch":"Collection Mismatch",
    "canceled": "Canceled"
  };

  var columnDefs = [
    {
      headerName: '',
      // width: 30,
      field: 'feeInfo',
      title: '',
      format: 'text'
    },
    // {
    //   headerName: 'Approval Status',
    //   // width: 30,
    //   field: 'billingApprovalStatusType',
    //   title: 'Approval Status',
    //   // checkboxSelection: true,
    //   // suppressSorting: true,
    //   // suppressMenu: true,
    //   // pinned: true,
    //   format: 'text',
    //   align: 'left',
    //   sortable: 'billingApprovalStatusType',
    //   filter: {
    //     'billingApprovalStatusType': {
    //       id: 'select'
    //     }
    //   },
    //   // approved, disapproved, passiveApproval, sent
    //   filterData: [
    //     {id: "", title: "Select"},
    //     {id: "approved", title: "Approved"},
    //     {id: "disapproved", title: "Disapproved"},
    //     {id: "passiveApproval", title: "Passive Approval"},
    //     {id: "sent", title: "Sent"},
    //   ]
    // },
    {
      headerName: 'Paid Status',
      // width: 30,
      field: 'billingPaidStatusType',
      title: 'Paid Status',
      // checkboxSelection: true,
      // suppressSorting: true,
      // suppressMenu: true,
      // pinned: true,
      sortable:'billingPaidStatusType',
      format: 'text',
      align: 'left',
      filter: {
        'billingPaidStatusType': {
          id: 'select'
        }
      },
      filterData: [
        {id: "", title: "Select"},
        {id: "calculated", title: "Calculated"},
        {id: "calculationError", title: "Calculation Error"},
        {id: "canceled", title: "Canceled"},
        {id: "collected", title: "Collected"},
        {id: "collectionMismatch", title: "Collection Mismatch"},
        {id: "insufficientFunds", title: "Insufficient Funds"},
        {id: "paidOut", title: "Paid Out"},
        {id: "pending", title: "Pending"},
        {id: "ready", title: "Ready"},
        // {id: "notPaid", title: "Not Paid"},
        // {id: "paid", title: "Paid"},
      ]
    },
    // {
    //   headerName: "Collected Date",
    //   title: 'Collected Date',
    //   field: "collectedDate",
    //   // sortable: "collectedDate",
    //   // width: 280,
    //   // pinned: true,
    //   format: 'text',
    //   align: 'left'
    // },
    {
      headerName: "Rep Code",
      field: "repCode",
      title: "Rep Code",
      width: 85,
      sortable: "repCode",
      headerTooltip: 'Rep Code',
      sort: 'desc',
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
      // trailingCharacter: '%'
    },
    {
      headerName: "Account Number",
      field: "brokerageAccountNumber",
      title: "Account Number",
      // width: 85,
      sortable: "brokerageAccountNumber",
      // filter: {
      //   brokerageAccountNumber: 'text'
      // },
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Account Name",
      field: "accountName",
      title: "Account Name",
      // width: 85,
      sortable: "accountName",
      // filter: {
      //   brokerageAccountNumber: 'text'
      // },
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Model Name",
      field: "modelName",
      title: "Model Name",
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Paid By Acct",
      field: "paidByBrokerageAccountNumber",
      title: "Paid By Acct",
      // width: 85,
      // sortable: "paidBy",
      // filter: {
      //   brokerageAccountNumber: 'text'
      // },
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Fee Type",
      title: "Fee Type",
      field: "rollupName",
      // width: 95,
      // sortable: "billingFeeType",
      headerTooltip: 'Fee Type',
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left',
      // filter: {
      //   'billingFeeType': {
      //     id: 'select'
      //   }
      // },
      // filterData: [
      //   {id: "", title: "Select"},
      //   {id: "platformSXFee", title: "Platform SX Fee"},
      //   {id: "advisoryFee", title: "Advisory Fee"},
      //   {id: "modelMaintenanceFee", title: "Model Maintenance Fee"},
      //   {id: "modelFee", title: "Model Fee"},
      //   {id: "platform3PFee", title: "Platform 3P Fee"},
      //   {id: "modelManagerFee", title: "Model Manager Fee"},
      //   {id: "techVendorFee", title: "Tech Vendor Fee"},
      //   {id: "advisorFinderFee", title: "Advisor Finder Fee"},
      //   {id: "managerReferralFee", title: "Manager Referral Fee"},
      //   {id: "platformAdvFee", title: "Platform ADV Fee"},
      //   {id: "platform3PSupervisor", title: "Platform 3P Supervisor"},
      //   {id: "modelAllSleeves", title: "Model All Sleeves"},
      //   {id: "modelMaintenanceSX", title: "SmartX Model Maintenance"}     
      // ]
    },
    // {
    //   headerName: "Period Type",
    //   title: "Period Type",
    //   field: 'billingPeriodType',
    //   format: 'text',
    //   sortable: 'billingPeriodType',
    //   aligh: 'left'
    // },
    {
      headerName: "Billing Type",
      field: "billingType",
      title: "Billing Type",
      // width: 85,
      sortable: "billingType",
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Billing Run Type",
      field: "billingRunType",
      title: "Billing Run Type",
      // width: 20,
      sortable: "billingRunType",
      sortingOrder: ['desc', 'asc'],
      format: 'text',
      align: 'left'
    },
    {
      headerName: "Avg Daily Balance",
      title: "Avg Daily Balance",
      field: "averageValue",
      width: 85,
      // sortable: "",
      headerTooltip: 'Avg Daily Balance',
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '',
      align: 'right'
    },
    {
      headerName: "Blended AUM",
      title: "Blended AUM",
      field: "entityAumBasis",
      width: 85,
      sortable: "entityAumBasis",
      headerTooltip: 'Blented AUM',
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      leadingCharacter: '$',
      trailingCharacter: '',
      align: 'right'
    },
    {
      headerName: "Billable AUM",
      title: "Billable AUM",
      field: "entityAum",
      // width: 120,
      sortable: "entityAum",
      headerTooltip: 'Billable AUM',
      comparator: numberSort,
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      leadingCharacter: '$',
      trailingCharacter: '',
      align: 'right'
    },
    {
      headerName: "Fee Rate",
      title: "Fee Rate",
      field: "averageFeePercent",
      width: 85,
      headerTooltip: 'Fee Rate',
      cellRenderer: asNumber,
      comparator: numberSort,
      sortable: 'averageFeePercent',
      sortingOrder: ['desc', 'asc'],
      format: 'number',
      decimals: 4,
      trailingCharacter: '%',
      align: 'right'
    },
    {
      headerName: "Net Fee",
      title: "Net Fee",
      field: "thisPeriodNetFeeAmount",
      // width: 85,
      // sortable: "calculatedFee",
      headerTooltip: 'Net Fee',
      // comparator: numberSort,
      // sortingOrder: ['desc', 'asc'],
      format: 'number',
      trailingCharacter: '',
      leadingCharacter: '$',
      align: 'right'
    },
    {
      headerName: "Advance Fee Estimate",
      title: "Advance Fee Estimate",
      field: "thisPeriodAdvanceFeeEstimate",
      // width: 95,
      // sortable: "chargedFee",
      headerTooltip: 'This period\'s advance fee estimate',
      // comparator: numberSort,
      // cellRenderer: asPercent,
      format: 'number',
      leadingCharacter: '$',
      align: 'right'
    },
    {
      headerName: "Fee From",
      title: "Fee From",
      field: "displayFromDate",
      width: 95,
      sortable: "periodStartDate",
      headerTooltip: 'Fee Period Start Date',
      format: 'date',
      // cellRenderer: asPercent,
      // format: 'number',
      // trailingCharacter: '%'
    },
    {
      headerName: "Fee To",
      title: "Fee To",
      field: 'displayToDate',
      width: 75,
      sortable: "periodEndDate",
      headerTooltip: 'Fee Period End Date',
      hide: false,
      format: 'date'
    },
    {
      headerName: "AUM Date",
      title: "AUM Date",
      field: 'aumDate',
      // sortable: "aumDate",
      headerTooltip: 'The AUM value on fee calculation date',
      format: 'date'      
    },
    {
      headerName: "Date Calculated",
      title: "Date Calculated",
      field: "computationDateTime",
      width: 95,
      // sortable: "calculatedOn",
      headerTooltip: 'Fee Calculate Date',
      format: 'date'
      // comparator: numberSort,
      // cellRenderer: asPercent,
      // hide: true,
      // format: 'number',
      // trailingCharacter: '%'
    }
  ];

  function numberSort(val1, val2) {

    val1 = val1 == '--' ? 0 : val1;
    val2 = val2 == '--' ? 0 : val2;

    if ( isNaN( parseFloat(val1, 10) - parseFloat(val2, 10) ) ){
      return 0;
    } else {
      return parseFloat(val1, 10) - parseFloat(val2, 10);
    }
  }

  function asPercent(params) {

    if (isNaN(params.value)) {

      return '--';

    } else {

      return Number(params.value).toFixed(2) + '%';

    }
  }

  function asNumber(params) {
    if (isNaN(params.value)) {
      return '--';
    } else {
      return Number(params.value).toFixed(2);
    }
  }

  function saveColumnPreset(input) {
    console.log("Save search payload: ", input);
    store.set('smxFeeBlotterPresets', {payload: input});
  }

  return {
    // feeTypes: feeTypes,
    // paidStatusTypes: paidStatusTypes,
    // approvalStatusTypes: approvalStatusTypes,
    enumValuesToText: enumValuesToText,
    columnDefs: columnDefs,
    saveColumnPreset: saveColumnPreset
  };

});