angular.module('Directives')
.directive('pendingAllocationsList', pendingAllocationsList);

function pendingAllocationsList($templateCache){
	return {
		restrict: 'E',
		scope: {
			pendingAllocationRequests: '=data',
			permission: '=permission'
		},
		bindToController: true,
		controller: pendingAllocationsListCtrl,
		controllerAs: 'vm',
		template: $templateCache.get('pendingAllocationsList.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {}
	};
}

function pendingAllocationsListCtrl($scope, $filter, toastr, SweetAlert, SmartXFactory, Dashboard) {
	var allocationAPI = (typeof allocationAPI !== 'undefined') ? allocationAPI : SmartXFactory.getAllocationAPI();
	var vm = this;

	vm.cancelAllocation = cancelAllocation;
	vm.accountPermission = vm.permission;
	vm.canCancel = canCancel;

	vm.pendingAllocationRequests = vm.pendingAllocationRequests.map(function(request){
		request.canCancel = canCancel(vm.accountPermission, request);
		return request;
	});

	$scope.$watch('vm.permission', function(newVal){
		if (newVal) {
			console.log("Account permission: ", newVal);
			vm.accountPermission = newVal;
		}
	});

	function canCancel (accountPermission, allocation) {
		var allowed = accountPermission == 'fullAccess' || (accountPermission == 'accountMaintenance' && allocation.changeRequestType == 'modify');
		debugger;
		return allowed;
	}

	function cancelAllocation(allocation, index) {

		console.log("cancelling allocation: ", allocation);
		SweetAlert.swal({
			title: "Are you sure you want to cancel this transaction?",
			text: "You will not be able to reverse this action.",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			closeOnConfirm: true,
			closeOnCancel: true,
			allowEscapeKey: true,
		},
		function(isConfirm) {
			if (isConfirm) {

					allocation.deleting = true;
					allocationAPI.requests.delete(allocation.id)
					.then(function(res) {
						console.log("Cancel transaction response: ", res);

						if (res.status === 204) {
							toastr.success("Your transaction was cancelled.", "Success");
							vm.pendingAllocationRequests.splice(index, 1);
						} 
					})
					.catch(function(err){
						console.error(err);
						if (err.name !== 'TypeError') {
							Dashboard.toastError(err.message, err);
						}
					})
					.finally(function(){
						allocation.deleting = false;
					});

			} else {
				jQuery('.sweet-alert').remove();
			}
		});
	}
}