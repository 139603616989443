angular.module('BillingModule')
.controller('FeeBlotterCustomizeModalCtrl', feeBlotterCustomizeModalCtrl);

function feeBlotterCustomizeModalCtrl ($scope, $uibModalInstance, $http, FeeBlotter) {

  console.log("Opening search customize modal...");
  $scope.errorMsg = '';
  $scope.saveTableColumnsPreset = false;
  $scope.checkboxes = [];
  $scope.tableColumns = {
    selected: null,
    columns: {
      "Table Columns": []
    }
  };

  $scope.models = {
    selected: null,
    lists: {
      "A": []
    }
  };

  $scope.itemMovedFunc = function(list, item, index) {
    console.log(list, item, index);

    var index = $scope.tableColumns.columns["Table Columns"].indexOf(item);
    $scope.tableColumns.columns["Table Columns"].splice(index, 1);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.dropCallback = function(index, item, external, type) {

    var idx = -1;
    for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
      var column = $scope.tableColumns.columns["Table Columns"][i];
      if (column.field == item.field) {
        idx = i;
      }
    }
    if (idx > -1) {
      $scope.tableColumns.columns["Table Columns"].splice(idx, 1);
    }

    return item;
  };

  $scope.updateTable = function() {

    var checkboxIdx = -1;
    var columns = $scope.tableColumns.columns["Table Columns"];
    var titleIdx = -1;

    for (var i = 0; i < columns.length; i++) {
      var column = columns[i];
      if (column.field == 'checkbox') {
        checkboxIdx = i;
      }
    }
    if (checkboxIdx !== 0) {
      columns.move(checkboxIdx, 0);
    }

    for (var i = 0; i < columns.length; i++) {
      var column = columns[i];
      if (column.field == 'title') {
        titleIdx = i;
      }
    }
    if (titleIdx !== 1) {
      columns.move(titleIdx, 1);
    }

    if ($scope.saveTableColumnsPreset == true) {
      console.log('Save search table preset for user.', columns);
      FeeBlotter.saveFeeBlotterColumnPresetforUser(columns);
    }

    for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
      var column = $scope.tableColumns.columns["Table Columns"][i];
      var checkitem = $scope.checkboxes[column.field];
      if (typeof checkitem != 'undefined') {
        console.log(column, checkitem);
        column.show = checkitem;
        column.hide = !checkitem;
      }
    }
    FeeBlotter.tableColumns = $scope.tableColumns.columns["Table Columns"];
    $uibModalInstance.close($scope.tableColumns.columns["Table Columns"]);
  };

  $scope.selectModel = function(item) {
    item.selected = !item.selected;
    if (item.selected == false) {
      item.hide = true;
    }
  };


  function init() {

    var cols = FeeBlotter.tableColumns;
    console.log('Table columns', cols);

    if (cols.length > 0) {
      $scope.tableColumns.columns["Table Columns"] = [];
      for (var i = 0; i < cols.length; i++) {
        var column = cols[i];
        if (typeof column.field == 'undefined') {
          continue;
        }

        if (typeof column.hide != 'undefined' && column.hide == true) {
          $scope.checkboxes[column.field] = false;
          column.hide = true;
        } else {
          column.selected = true;
          $scope.checkboxes[column.field] = true;
          column.hide = false;
        }
        $scope.tableColumns.columns["Table Columns"].push(column);
      }
    }
  }

  init();
}