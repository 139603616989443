angular
.module('AlertsManagerCtrl', [])
.controller("AlertsManagerCtrl", function($scope, IdentityFactory) {
  var user = IdentityFactory.getActiveUser();

  $scope.auth_token = 'Bearer ' + SmartX.Router.getToken();
  $scope.smartx_user_guid = user.profile.smartx_user_guid;
  // $scope.drupal_env = process.env.NODE_ENV !== 'production' ? 'qa' :  process.env.NODE_ENV;
  // map to valid keys used in vue-app-env.json
  switch(process.env.NODE_ENV) {
    case 'production':
      $scope.drupal_env = 'live';
      break;
    case 'development':
      $scope.drupal_env = 'qa';
      break;
    default:
      $scope.drupal_env = 'qa';
  }
});
