var mod = angular.module('ModelReportModule');
console.log(mod);
mod
.controller('ModelReportContainerCtrl', ModelReportContainerCtrl)
.constant('EnterpriseReportColumns', [
  {
    title: 'Enterprise',
    field: 'enterprise',
    subfield: 'name',
    sortable: 'enterprise.name',
    filter: {
      'enterprise.name': 'text'
    },
    visible: true
  },
  // {
  //   title: 'Models',
  //   field: 'modelCount',
  //   sortable: 'modelCount',
  //   align: 'center',
  //   visible: true
  // },
  {
    title: 'Allocations',
    field: 'allocationCount',
    sortable: 'allocationCount',
    align: 'center',
    visible: true
  },
  {
    title: 'AUM',
    field: 'totalAum',
    align: 'right',
    sortable: 'totalAum',
    type: 'currency',
    visible: true
  }
])
.constant('EnterpriseModelReportColumns', [
  {
    title: 'Model',
    sortable: 'name',
    filter: {
      name: 'text'
    },
    field: 'name',
    visible: true
  },
  {
    title: 'Allocation Count',
    field: 'allocationCount',
    sortable: 'allocationCount',
    align: 'center',
    visible: true
  },
  {
    title: 'AUM',
    field: 'aum',
    sortable: 'aum',
    type: 'currency',
    align: 'right',
    visible: true
  }
])
.constant('ModelReportColumns', [
  {
    title: 'Model',
    field: 'model',
    subfield: 'name',
    sortable: 'model.name',
    filter: {
      'model.name': 'text'
    },
    visible: true
  },
  {
    title: 'Enterprise',
    field: 'enterprise',
    subfield: 'name',
    sortable: 'enterprise.name',
    filter: {
      'enterprise.name': 'text'
    },
    visible: true
  },
  {
    title: 'Allocation Count',
    field: 'allocationCount',
    sortable: 'allocationCount',
    align: 'center',
    visible: true
  },
  {
    title: 'AUM',
    field: 'totalAum',
    sortable: 'totalAum',
    type: 'currency',
    align: 'right',
    visible: true
  }
])
.constant('ModelAllocationReportColumns', [
  {
    title: 'Account',
    field: 'accountName',
    filter: {
      accountName: 'text'
    },
    sortable: 'accountName',
    visible: true
  },
  {
    title: 'Account Number',
    field: 'accountNumber',
    // subfield: 'brokerageAccountNumber',
    filter: {
      'accountNumber': 'text'
    },
    visible: true,
    align: 'center'
  },
  // {
  //   title: 'Model',
  //   field: 'model',
  //   subfield: 'name',
  //   visible: true
  // },
  {
    title: 'Value',
    field: 'aumValue',
    type: 'currency',
    sortable: 'aumValue',
    align: 'right',
    visible: true
  }
])

.directive('modelReport', modelReport);

function ModelReportContainerCtrl ($scope, $rootScope) {
  var vm = this;
  vm.title = 'Model Report';

}

function modelReport ($templateCache){
  return {
    restrict: 'E',
    template: $templateCache.get('model-report.tpl.html'),
    controller: ModelReportCtrl,
    scope: true,
    controllerAs: 'vm',
    bindToController: {}
  };
}

function ModelReportCtrl (EnterpriseFactory, SmartXFactory, Tabs, Models, Dashboard, $timeout, NgTableParams, $scope, toastr, $filter, $http, EnterpriseReportColumns, EnterpriseModelReportColumns, ModelReportColumns, ModelAllocationReportColumns){
  var vm = this;

  // the currently selected pie slice
  var selectedSlice;

  // GET /enterprise/GUID/model-report/
  var enterpriseModelReportUrl = 'https://next.json-generator.com/api/json/get/NJe1-i8m_';

  // GET /reports/model/GUID/allocations
  var modelAllocationsReportUrl = 'https://next.json-generator.com/api/json/get/V1xUGsI7O';

  var userEnterprise = EnterpriseFactory.getUserEnterprise();
  
  // data
  vm.groupings = [
    {
      text: 'Enterprise',
      value: 'enterprise'
    },
    {
      text: 'Model',
      value: 'model'
    }
  ];
  vm.selectedGrouping = vm.groupings[0];
  vm.pieContainerTitle = vm.selectedGrouping.text;
  vm.loading = false;
  vm.holdings = [];
  vm.crumbs = [{
    id: '',
    name: 'Enterprise',
    type: 'Enterprise',
    points: []
  }];

  // methods
  vm.exportData = exportData;
  vm.onModelRowClicked = onModelRowClicked;
  vm.addTab = addTab;
  vm.loadCrumb = loadCrumb;
  vm.removeCrumb = removeCrumb;

  init();

  function init() {
    vm.loading = true;
    initPieChart();

    EnterpriseFactory.getModelReport()
    .then(function(res){
      $timeout(function(){
        groupByModel(res.data);
      });
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error(err.message);
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      $timeout(function(){
        vm.loading = false;
      });
    });
  }

  function addTab(type, account) {
    var accountId = account.accountId;
    account.loading = true;
    SmartXFactory.getAccountAPI().get(accountId)
    .then(function(res){
      var account = res.data.data;
      Tabs.addTab(type, account.id, null, account);
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error(err.message);
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      $timeout(function(){
        account.loading = false;
      });
    });
  }

  function onModelRowClicked(model) {
    var id = model.reportedModelId || model.id;
    var pointIndex = _.findIndex(vm.chartConfig.series[0].data, function(model){
      return model.id === id;
    });

    var selectedSlice = vm.chartConfig.series[0].data[pointIndex];

    vm.selectedModel = {
      id: model.id,
      name: model.name
    };

    // load model allocations
    $timeout(function(){
      vm.loading = true;
      EnterpriseFactory.getModelAllocationReport(selectedSlice.id)
      .then(handleModelAllocationResponse(selectedSlice))                
      .catch(function(err){
        // toastr.error(err.message);
        // console.error(err);
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        $timeout(function(){
          vm.loading = false;
        });
      });
    });   
  }

  function groupByModel (data) {

    var formatted = data.reduce(function(arr, enterprise){
      enterprise.model.forEach(function(model){
        var hydrated = _.extend(model,
        {
          modelAum: model.aum, 
          modelAllocationCount: model.allocationCount,
          enterprise: enterprise.enterprise,
          enterpriseAum: enterprise.totalAum
        });
        arr.push(hydrated);
      });
      return arr;
    }, []);

    var grouped = _.groupBy(formatted, function(model){
      return model.id;
    });

    var formattedByModel = _.map(grouped, function(group, modelId){
      return {
        id: modelId,
        name: group[0].name,
        aum: getTotal(group, 'modelAum'),
        allocationCount: getTotal(group, 'modelAllocationCount'),
        enterprises: group.map(function(enterprise){
          delete enterprise.id;
          delete enterprise.name;

          var obj = _.extend({}, enterprise.enterprise);
          return obj;
        })
      };
    });

    vm.chartConfig.series[0].data = [];
    var data = [];
    formattedByModel.forEach(function(model){
      
      data.push({
        name: model.name,
        id: model.id,
        y:  Math.abs(model.aum),
        type: 'model',
        enterprises: model.enterprises,
      });
    });

    var title = 'Model Allocations';
    var subtitle = userEnterprise.name;

    updateChartSeries({
      id: vm.chartConfig.series[0].id,
      name: vm.chartConfig.series[0].name,
      points: data,
      title: title,
      subtitle: subtitle
    });

    vm.crumbs[0] = {
      id: vm.chartConfig.series[0].id,
      name: vm.chartConfig.series[0].name,
      points: data,
      models: formattedByModel,
      title: title,
      subtitle: subtitle,
      type: 'enterpriseModels'
    };

    vm.columns = EnterpriseModelReportColumns;
    vm.totalEquity = getTotal(formattedByModel, 'aum');
    vm.modelReportTableParams = new NgTableParams({
      sorting: {aum: 'desc'},
      filter: {}
    }, {
      dataset: formattedByModel
    });

  }

  function getTotal (data, key) {
    return data.reduce(function(total, item){
      return total += item[key];
    }, 0);
  }

  function exportData () {
    var timestamp = moment().format("MM_DD_YYYY");
    var rows = vm.modelReportTableParams.settings().dataset;

    // if (vm.selectedGrouping.value == 'enterprise'){
    var formattedRows = rows.map(function(row){
      if (row.enterprise){
        if (row.allocation && row.allocation.length){
          var modelName = row.model.name;
          var enterpriseName = row.enterprise.name;
          var allocations = row.allocation.map(function(allocation){
            // var account = _.find(Dashboard.getAccessAccounts(), function(account){
            //   return account.id === allocation.accountId;
            // });
            var obj = {
              'Enterprise Name': enterpriseName,
              'Model': modelName,
              'Account Name': allocation.accountName,
              'Brokerage Account Number': getAccountNumber(allocation), // allocation.accountNumber || (account ? account.brokerageAccountNumber : '--'), 
              'Value': allocation.aumValue
              // 'Enterprise AUM': $filter('currency')(row.totalAum),
            };
            return obj;
          });

          return allocations;
        } else {
          var obj = {
            'Enterprise Name': row.enterprise.name,
            'Model': row.model.name,
            '# Allocations': row.allocationCount,
            'Enterprise AUM': $filter('currency')(row.totalAum),
          };
          return obj;
        }
        
      } else if (row.accountName) {
        var enterpriseName = vm.selectedEnterprise.name;
        var modelName = vm.selectedModel.name;
        var obj = {
          'Enterprise': enterpriseName,
          'Account Name': row.accountName, 
          'Brokerage Account Number': getAccountNumber(row), // row.accountNumber || (account ? account.brokerageAccountNumber : '--'),
          'Model Name': modelName,
          'Allocation Value': row.aumValue
        };
      } else {
        var obj = {
          'Model Name': row.name,
          '# Allocations': row.allocationCount,
          'Model AUM': $filter('currency')(row.aum),
        };
      }

      return obj;
    });

    formattedRows = _.flatten(formattedRows);

    var filename = (rows[0].enterprise ? 'smartx_enterprise_export_' : 'smartx_enterprise_models_export_') + timestamp + '.csv';
    /* } else {
      var formattedRows = rows.map(function(row){
        if (row.model){
          return {
            'Model Name': row.model.name,
            'Enterprise': row.enterprise.name,
            '# Allocations': row.allocationCount,
            'Total AUM': $filter('currency')(row.totalAum)
          }
        } else {
          return {
            'Account Name': row.accountName,
            'Allocation Value': $filter('currency')(row.value)
          }
        }
      })

      var filename = (rows[0].enterprise ? 'smartx_models_export_' : 'smartx_' + vm.crumbs[vm.crumbs.length - (vm.crumbs.length > 1 ? 2 : 1)].name.replace(/[\s+,]/g, '_') + '_allocations_export_') + timestamp + '.csv'
    } */

    Dashboard.JSONtoCSV(formattedRows, filename);
  }

  function updateTableData (data) {

    switch (data.type){
      case 'modelAllocations':
        if (data.allocations){
          vm.columns = ModelAllocationReportColumns;
          vm.totalEquity = getTotal(data.allocations, 'aumValue');
          vm.modelReportTableParams = new NgTableParams({
            sorting: {aumValue: 'desc'},
            filter: {}
          }, {
            dataset: data.allocations
          });
        } else {
          vm.columns = ModelReportColumns;
          vm.totalEquity = getTotal(vm.holdings, 'totalAum');
          vm.modelReportTableParams = new NgTableParams({
            sorting: {totalAum: 'desc'},
            filter: {}
          }, {
            dataset: vm.holdings
          });
        }
        
        break;
      case 'enterpriseModels':
          if (data.models) {
            vm.columns = EnterpriseModelReportColumns;
            vm.totalEquity = getTotal(data.models, 'aum');
          } else {
            vm.columns = EnterpriseReportColumns;
            vm.totalEquity = getTotal(vm.holdings, 'totalAum');
          }
          
          vm.modelReportTableParams = new NgTableParams({
            sorting: {aum: 'desc'},
            filter: {}
          }, {
            dataset: data.models || vm.holdings
          });
        break;
      default:
          vm.columns = EnterpriseReportColumns;
          vm.totalEquity = getTotal(data.enterprises, 'totalAum');
          vm.modelReportTableParams = new NgTableParams({
            sorting: {totalAum: 'desc'},
            filter: {}
          }, {
            dataset: vm.holdings && vm.holdings.length ? vm.holdings : data.enterprises
          });
          break;
    }
  }

  function getAccountNumber (allocation) {
    if (allocation.accountNumber) return allocation.accountNumber;

    var account = _.find(Dashboard.getAccessAccounts(), function(account){
      return account.id === allocation.accountId;
    });
    
    return account ? account.brokerageAccountNumber : '--';
  }

  function handleModelAllocationResponse (selectedSlice){

    return function (res) {
      vm.modelAllocations = res.data;
                
      // multiple enterprises with allocation to the selected model
      if (res.data.length > 1){
        var enterprises = res.data;
        var points = enterprises.map(function(enterprise){
          return {
            id: enterprise.enterprise.id,
            name: enterprise.enterprise.name,
            model: {
              id: selectedSlice.id,
              name: selectedSlice.name
            },
            allocations: enterprise.allocation,
            y:  Math.abs(enterprise.totalAum),
            type: 'enterprise'
          };
        });

        addCrumb({
          id: selectedSlice.id,
          name: selectedSlice.name,
          enterprises: res.data.map(function(enterprise){
            enterprise.allocationCount = enterprise.allocation.length;
            return enterprise;
          }),
          title: 'Allocations to ' + selectedSlice.name,
          subtitle: 'Total Allocation Value: ' + $filter('currency')(getTotal(res.data, 'totalAum'), '$'),
          points: points
        });

        updateChartSeries({
          id: selectedSlice.id,
          name: selectedSlice.name,
          points: points,
          title: 'Allocations to ' + selectedSlice.name,
          subtitle: 'Total Allocation Value: ' + $filter('currency')(getTotal(res.data, 'totalAum'), '$')
        });  
      } else { // one enterprise with allocations to the select model
        
        var allocations = res.data[0].allocation;
        var points = allocations.map(formatModelAllocations);

        vm.selectedEnterprise = res.data[0].enterprise;
        
        allocations = allocations.map(function(allocation){
          if (!allocation.accountNumber) {
            allocation.accountNumber = getAccountNumber(allocation);
          }
          return allocation;
        });

        addCrumb({
          id: selectedSlice.id,
          name: selectedSlice.name,
          allocations: allocations,
          points: points,
          title: res.data[0].enterprise.name + ' account allocations to ' + selectedSlice.name,
          title: 'Allocations to ' + selectedSlice.name,
          subtitle: 'Total Allocation Value: ' + $filter('currency')(getTotal(allocations, 'aumValue'), '$'),
          type: 'modelAllocations'
        });

        updateChartSeries({
          id: selectedSlice.id,
          name: selectedSlice.name,
          points: points,
          title: 'Allocations to ' + selectedSlice.name,
          subtitle: 'Total Allocation Value: ' + $filter('currency')(getTotal(allocations, 'aumValue'), '$'),
        });  
      }
    };
  }

  var chart;
  function initPieChart () {
    vm.chartConfig = {
      chart: {
        type: 'pie',
        renderTo: 'modelReportPie',
        events: {
          load: function(e) {
            chart = this;
          },
          redraw: function(e) {},
        }
      },
      title: {
        text: 'Model Allocations',
        style: {
          fontSize: '14px'
        }
      },
      subtitle: {
        text: userEnterprise.name,
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: {
            linearGradient: [0, 0, 0, 60],
            stops: [
                [0, '#FFFFFF'],
                [1, '#E0E0E0']
            ]
        },
        borderWidth: 1,
        borderColor: '#AAA',
        valueDecimals: 2,
        valuePrefix: '$',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          events: {
            click: function (event){

              var selectedSlice = event.target.point;

              if (!selectedSlice || selectedSlice.type === 'allocation'){
                return;
              }

              if (selectedSlice.type === 'model'){

                vm.selectedModel = {
                  id: selectedSlice.id,
                  name: selectedSlice.name
                };

                // load model allocations
                $timeout(function(){
                  vm.loading = true;
                  EnterpriseFactory.getModelAllocationReport(selectedSlice.id)
                  .then(handleModelAllocationResponse(selectedSlice))
                  .catch(function(err){
                    // toastr.error(err.message);
                    // console.error(err);
                    Dashboard.toastError(err.message, err);
                  })
                  .finally(function(){
                    $timeout(function(){
                      vm.loading = false;
                    });
                  });
                });                
              } else if (selectedSlice.type === 'enterprise'){

                var points = selectedSlice.allocations.map(formatModelAllocations);

                vm.selectedEnterprise = {
                  id: selectedSlice.id,
                  name: selectedSlice.name
                };

                selectedSlice.allocations = selectedSlice.allocations.map(function(allocation){
                  if (!allocation.accountNumber) allocation.accountNumber = getAccountNumber(allocation); /* allocation.accountNumber || _.find(Dashboard.getAccessAccounts(), function(account){
                    return account.id === allocation.accountId;
                  }); */
                  return allocation;
                });

                addCrumb({
                  id: selectedSlice.id,
                  name: selectedSlice.name,
                  allocations: selectedSlice.allocations,
                  points: points,
                  title: 'Allocations to ' + selectedSlice.model.name,
                  subtitle: selectedSlice.name + ': Total Allocation Value ' + $filter('currency')(getTotal(selectedSlice.allocations, 'aumValue')),
                  type: 'modelAllocations'
                });

                updateChartSeries({
                  id: selectedSlice.id,
                  name: selectedSlice.name,
                  points: points,
                  title: 'Allocations to ' + selectedSlice.model.name,
                  subtitle: selectedSlice.name + ': Total Allocation Value ' + $filter('currency')(getTotal(selectedSlice.allocations, 'aumValue')),
                });  
                
              } else {
                addCrumb({
                  id: selectedSlice.id,
                  name: selectedSlice.name,
                  allocations: selectedSlice.allocations ? selectedSlice.allocations : [],
                  models: selectedSlice.models ? selectedSlice.models : [],
                  points: selectedSlice.models.map(function(model){
                    return {
                      id: model.id,
                      name: model.name,
                      y: Math.abs(model.aum),
                      type: 'model'
                    };
                  }),
                  title: 'Allocations to ' + selectedSlice.name,
                  subtitle: '',
                  type: 'enterpriseModels'
                });

                $timeout(function(){
                  updateChartSeries({
                    id: selectedSlice.id,
                    name: selectedSlice.name,
                    points: selectedSlice.models.map(function(model){
                      return {
                        id: model.id,
                        name: model.name,
                        y: Math.abs(model.aum),
                        type: 'model'
                      };
                    }),
                    title: selectedSlice.name + ' model allocations'
                  });
                });
              }                
            }
          }
        }
      },
      series: [{
        name: 'Model AUM',
        colorByPoint: true,
        data: [{
          name: '$CASH',
          y: 100
        }]
      }]
    };
  }

  function formatModelAllocations(allocation){
    var point = {
      name: allocation.accountName,
      id: allocation.allocationId,
      y: Math.abs(allocation.aumValue),
      type: 'allocation'
    };

    return point;
  }

  function loadCrumb (crumb, index) {
    // truncate the crumbs so that they end at whichever one was clicked
    vm.crumbs.splice(index + 1);
    
    $timeout(function(){
      updateChartSeries(crumb, true);
    });
    
  }

  function updateChartSeries (data, updateTable) {
    $timeout(function(){

      if (data.title){
        vm.chartConfig.title.text = data.title;
        if (data.subtitle){
          vm.chartConfig.subtitle.text = data.subtitle;
        }
      } else {
        vm.chartConfig.title.text = '';
        vm.chartConfig.subtitle.text = '';
      }

      vm.chartConfig.series[0] = {
        id: data.id,
        name: data.name,
        data: data.points
      };

      if (updateTable) updateTableData(data);
    });
  }

  function addCrumb (point) {
    $timeout(function(){
      vm.crumbs.push(point);
      updateTableData(point);
    });
  }

  function removeCrumb () {
    $timeout(function(){
      vm.crumbs.pop();
      var currentCrumb = _.last(angular.copy(vm.crumbs));
      updateChartSeries(currentCrumb, true);
    });
  }
}