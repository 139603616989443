/*
	Used for displaying a tree map of a funds holdings
*/

angular.module('modelTree', [])
.directive('modelTree', function($timeout, Models){
	return {
		restrict: 'E',
		scope: false,
		template: 	'<div id="tree-wrap-{{vm.model.nid}}"class="col-md-12 col-xs-12 text-center" ng-model="vm.model.tree">' +
						'<div class="btn b-default drillup-control" ng-class="{\'showing\' : vm.model.tree.drilled === true}"' +
							'ng-click="vm.model.drillup($event)"><i class="fa fa-arrow-left"></i><span class="back-btn-text"> Back</div>' +
						'<div id="tree-{{vm.model.nid}}" class="tree animated fadeIn"></div>' +
					'</div>',
		link: link,
		controller: modelTreeCtrl
	};

	function link(scope, elem, attr){
		console.log(scope);
		console.log(elem);
		console.log(attr);
	}

	function modelTreeCtrl(scope){
		
	}
});