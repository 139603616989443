angular.module('HarvestingModule')
.controller('editTaxGroupCtrl', function(SweetAlert, FeatureFlags, $filter, EnterpriseFactory, $q, $scope, $rootScope, NgTableParams, $http, $modal, HarvestingFactory, Dashboard, toastr, $templateCache, Tabs, $timeout, SmartXFactory){
	var vm = this;
  var parentTab = $scope.tab; 
  var groupData = parentTab.data;
  var popoverVerbiage = "<div class='col-xs-12'><ul><li>You can choose to apply these rules to lots, positions, or both lots and positions.</li><li><strong>Lots:</strong> Applying the rules to the lots will show you individual tax lots that meets your thresholds.</li><li><strong>Positions:</strong> Applying the rules to the positions will show you positions that meet your thresholds.</li></ul></div>";


  // console.log("Editing group data: ", groupData);

  vm.formData = {};
  vm.action = groupData.action.toLowerCase();
  vm.groupData = groupData.group;
  vm.groupName = groupData.group ? groupData.group.name : '';
  
  vm.filingStatuses = {
    'single': 'Single',
    'marriedFilingJointly': 'Married Filing Jointly',
    'marriedFilingSeparately': 'Married Filing Separately',
    'qualifyingWidowWithDependent': 'Qualifying Widow With Dependent'
  };
  vm.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  vm.groupAccounts = [];

  vm.addAccountInput = {
    selected: ''
  };

  vm.getDaysForMonth = getDaysForMonth;
  vm.addGroup = addGroup;
  vm.addAccountToGroup = addAccountToGroup;
  vm.removeAccountFromGroup = removeAccountFromGroup;
  vm.openRuleModal = openRuleModal;
  vm.openHarvestMonitor = openHarvestMonitor;
  vm.updateGroupAccount = updateGroupAccount;
  vm.onAccountSelected = addAccountToGroup;

  vm.accounts = [];
  vm.selectedAccounts = [];
  vm.multiselectSettings = {
    enableSearch: true, 
    searchField: '$', 
    idProp: 'id', 
    // displayProp: 'name', 
    externalIdProp: '',
    selectedToTop: true,
    scrollable: true,
    // template: '<b ng-class="{ \'text-muted disabled\': option.disabled }" ng-disabled="option.disabled" ng-attr-title="{{ option.disabled ? ((option.account.brokerage | formatBrokerName) + \' does not support Lot Level Harvesting\') : \'Queue Add to Group\' }}"><span>{{option.name}}</span><br/><span class="text-muted">{{option.brokerageAccountNumber}}</span></b>'
		template: "<span>{{option.name}}</span><br/><span class='text-muted'>{{option.account.brokerageAccountNumber}}</span>"
  };

  vm.customTexts = {
    buttonDefaultText: 'Select Accounts',
    dynamicButtonTextSuffix: 'selected',
    searchPlaceholder: 'Search by name or brokerage number'
  };
  
  vm.multiselectEvents = {
    onSelectAll: function(){

      $timeout(function(){
        vm.selectedAccounts.forEach(function(account){
          addAccountToGroup(account);
        });
      });
    },
    onDeselectAll: function(){
      angular.copy(vm.groupAccounts).forEach(function(account, index){
        removeAccountFromTable(index);
      });
    },
    onItemSelect: addAccountToGroup,
    onItemDeselect: function(item){
      var accountIndex = _.findIndex(vm.groupAccounts, function(account){
        if (account.id){
          return account.id == item.id;
        } else {
          return account.account.id == item.id;
        }
      });
      removeAccountFromTable(accountIndex);
    }
  };

  vm.accountGroupTable = new NgTableParams({
      count: 25,
      sorting: {
        name: "asc"
      },
    }, {
      dataset: [],
      counts: []
    });
  
    vm.accountTypeCanHarvest = HarvestingFactory.accountTypeCanHarvest;

  init();

  // function accountTypeCanHarvest(account){
  //   account = account.account ? account.account : account
  //   return account.accountType.toLowerCase().indexOf('ira') === -1 && account.accountType.indexOf('trustTestamentary') === -1
  // }

  function formatAccounts(taxGroupAccounts){
    var formattedAccounts = taxGroupAccounts.filter(function(taxGroupAccount){
      return taxGroupAccount.account.internalType !== 'sma';
    }).map(function(taxGroupAccount){
      var acctInfo = taxGroupAccount.account;
      var custodianName = acctInfo.custodian ? acctInfo.custodian.name : acctInfo.brokerage.name;
      var formattedAccount = {
        id: acctInfo.id, // multiselect dropdown doesn't like nested props
        name: $filter('sanitizeSmartXAccountName')(acctInfo.name,'name'),
        account: {
          id: acctInfo.id,
          name: $filter('sanitizeSmartXAccountName')(acctInfo.name,'name'),
          brokerageAccountNumber: $filter('sanitizeSmartXAccountName')(acctInfo.brokerageAccountNumber,'number'),
          accountType: acctInfo.accountType,
          isApm: acctInfo.isApm,
          brokerage: custodianName,
          status: acctInfo.status
        },
        harvestTaxes: taxGroupAccount.harvestTaxes, // || (HarvestingFactory.accountTypeCanHarvest(account) ? Dashboard.getAllowedByBroker('taxharvest', account.custodian.name) : false), // default to false if it is an IRA account
        harvestAllowedByBroker: FeatureFlags.getAllowedByBroker('taxharvest', custodianName),
        monitorWashSale: angular.isDefined(taxGroupAccount.monitorWashSale) ? taxGroupAccount.monitorWashSale : true,
        // disabled: !Dashboard.getAllowedByBroker('taxharvest', account.brokerage) // might use this if the feature is off for the account even though the broker allows it
      };

      return formattedAccount;
    });

    return formattedAccounts;
  }

  function init(refreshing){

    vm.formData.taxMonth = '12';
    vm.formData.taxDay = '31';

    // if a group was passed in from another tab, fill out the form fields with existing group data
    if (groupData.group && groupData.group.id){
      vm.formData = {
        id: groupData.group.id,
        groupName: groupData.group.name,
        filingStatus: groupData.group.filingStatus,
        taxMonth: groupData.group.taxYear.month.toString() || '1',
        taxDay: groupData.group.taxYear.day.toString() || '1',
        shortTermGoal: groupData.group.shortTermGoal,
        longTermGoal: groupData.group.longTermGoal,
        shortTermTaxRate: math.format(groupData.group.shortTermTaxRate * 100, {precision: 14})
      };

      // get accounts for the selected group
      if (!refreshing) vm.loadingGroupAccounts = true;

      HarvestingFactory.getGroupAccounts(groupData.group.id)
      .then(function(res){
        vm.loadingGroupAccounts = false;

        if (res.data.errorMessage){
          toastr.error(res.data.errorMessage);
          return;
        }
        var response = res.data;
        var formattedGroupAccounts = formatAccounts(response.data);
        vm.groupAccounts = formattedGroupAccounts;
        vm.existingGroupAccounts = angular.copy(response.data);

        // get accounts
        if (!refreshing) vm.loadingAccounts = true;

        // SmartXFactory.getAccounts('active')
        // .then(function(result){
        //   vm.accounts = formatAccounts(result);
          

        //   // check off models in multiselect that are currently in the group
        //   vm.groupAccounts = angular.copy(vm.accounts).filter(function(account){
        //     var matchingGroupAccount = _.find(vm.groupAccounts, function(groupAccount){
        //       if (groupAccount.account){
        //         return groupAccount.account.id == account.account.id;
        //       } else {
        //         return groupAccount.accountId == account.account.id;
        //       }
        //     });
            
        //     return matchingGroupAccount;
        //   });

        //   // vm.groupAccounts = angular.copy(vm.accounts)

        // })
        // .catch(function(err){
        //   // toastr.error("Unable to load accounts for enterprise.");
        //   // console.error(err);
        //   Dashboard.toastError("Failed to load accounts for enterprise.", err);
        // })
        // .finally(function(){
        //   if (!refreshing) vm.loadingAccounts = false;
        //   $scope.$apply();
        // });
      })
      .catch(function(err){
        // toastr.error(err.message);
        // console.error(err);
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        if (!refreshing) vm.loadingGroupsAccounts = false;
        $scope.$apply();
      });
    }

    //get rules
    vm.loadingRules = true;
    HarvestingFactory.getHarvestRules()
    .then(function(res){
      var response = res.data;

      vm.loadingRules = false;
      vm.rules = response.data;

      // if viewing an existing group, set the rule id
      if (groupData.group && groupData.group.lotScreenRule != null) {
        vm.formData.lotScreenRuleId = groupData.group.lotScreenRule.id != '00000000-0000-0000-0000-000000000000' ? groupData.group.lotScreenRule.id : ''; // use this to set the rule
      }

      // SmartXFactory.getAccounts('active')
      //   .then(function(result){
      //     vm.accounts = formatAccounts(result);

      //     // check off models in multiselect that are currently in the group
      //     vm.accounts.forEach(function(account){
      //       var matchingGroupAccount = _.find(vm.groupAccounts, function(groupAccount){
      //         if (groupAccount.account){
      //           return groupAccount.account.id == account.account.id;
      //         } else {
      //           return groupAccount.accountId == account.account.id;
      //         }
      //       });

      //       // if (matchingGroupAccount){
      //       //   vm.selectedAccounts.push(account);
      //       // }

      //     });
      //   })
      //   .catch(function(err){
      //     // toastr.error("Unable to load accounts for enterprise.");
      //     // console.error(err);
      //     Dashboard.toastError("Failed to load accounts for enterprise", err);
      //   })
      //   .finally(function(){
      //     vm.loadingAccounts = false;
      //     $scope.$apply();
      //   });
    })
    .catch(function(err){
      vm.loadingRules = false;
      // toastr.error("Unable to load rules for enterprise.");
      // console.error(err);
      Dashboard.toastError("Failed to load rules for enterprise", err);
    });
  }

  function getDaysForMonth(month){
    if (month){
      var year = moment().year();
      var numDays = new Date(year, Number(month), 0).getDate(); // gets the last date of the previous month
      return _.range(1, numDays + 1);
    }
  }

  function openHarvestMonitor(){
    Tabs.addTab('harvestMonitor');
  }

  function addAccountToGroup(account){

    var groupAccount = {};

    if (!account) return false;
    if (typeof account === 'string') account = JSON.parse(account);

    // prevent dupes
    if (vm.groupAccounts.length){
      var accountInGroup = vm.groupAccounts.some(function(groupAcct){
        var groupAcctId = groupAcct.account ? groupAcct.account.id : groupAcct.id;
        return groupAcctId == account.id;
      });
    }

    if (accountInGroup) {
      SweetAlert.swal('The selected account already belongs to this tax group');
      return false;
    }

    // construct something similar to the data model of the tax group accounts returned by the api
    groupAccount.account = angular.copy(account);
    groupAccount.accountId = account.id;
    groupAccount.isNew = true;
    groupAccount.harvestAllowedByBroker = FeatureFlags.getAllowedByBroker('taxharvest', account.custodian.name);
    groupAccount.harvestTaxes = vm.accountTypeCanHarvest(account) && (groupAccount.harvestAllowedByBroker ? true : false);
    groupAccount.monitorWashSale = true;


    if (vm.groupAccounts) vm.groupAccounts.push(groupAccount);
    else vm.groupAccounts = [groupAccount];

    // clear out the input
    $scope.$broadcast('accountLookup::clear');
  }

  function checkGroupMembership (account) {
    var existingGroupAccountIds = _.map(vm.existingGroupAccounts, function(member){ return (member.id || member.account.id); });
    return _.contains(existingGroupAccountIds, account.account ? account.account.id : account.id);
  }

  function removeAccountFromGroup (account) {
    var index = vm.groupAccounts.indexOf(account);
    var accountId = vm.groupAccounts[index] ? (vm.groupAccounts[index].id || vm.groupAccounts[index].account.id || vm.groupAccounts[index].guid) : null;
    var accountInGroup;

    account = vm.groupAccounts[index];
    accountInGroup = checkGroupMembership(account);

    if (account && vm.formData.id && accountInGroup) {

      account.removing = true;

      // use id which is the 'taxGroupAccountId' instead of the regular accountId
      HarvestingFactory.deleteAccountFromGroup(vm.formData.id, account.account.id)
      .then(function(res){
        toastr.success("Account removed from group.");
        vm.groupAccounts.splice(index, 1);

        var existingGroupAccountIds = _.map(vm.existingGroupAccounts, function(member){ return member.account.id; });

        // // uncheck it from the multi select if it was deleted via the table action
        var existingAccountIndex = existingGroupAccountIds.indexOf(accountId);

        if (existingAccountIndex > -1){
          vm.existingGroupAccounts.splice(existingAccountIndex, 1);
        }
        
        $rootScope.$broadcast('refresh-tax-groups');
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error("Error removing account.");
        Dashboard.toastError("Failed to remove account", err);
      })
      .finally(function(){
        account.removing = false;
      });
    } else {
      vm.groupAccounts.splice(index, 1);
      var selectedAccountIndex = _.findIndex(vm.selectedAccounts, {id: accountId});
      if (selectedAccountIndex > -1){
        vm.selectedAccounts.splice(selectedAccountIndex, 1);
      }
    }

  }

  function removeAccountFromTable(index){

    var accountId = vm.groupAccounts[index] ? (vm.groupAccounts[index].account.id || vm.groupAccounts[index].guid) : null;
    var account = vm.groupAccounts[index];

    if (!account.isNew) return false;

    if (account && vm.formData.id){

      vm.groupAccounts.splice(index, 1);

      // uncheck it from the multi select if it was deleted via the table action
      var selectedAccountIndex = _.findIndex(vm.selectedAccounts, {guid: accountId});

      if (selectedAccountIndex > -1){
        vm.selectedAccounts.splice(selectedAccountIndex, 1);
      }

      $rootScope.$broadcast('refresh-tax-groups');
    } else {
      vm.groupAccounts.splice(index, 1);
      var selectedAccountIndex = _.findIndex(vm.selectedAccounts, {id: accountId});
      if (selectedAccountIndex > -1){
        vm.selectedAccounts.splice(selectedAccountIndex, 1);
      }
    }
  }

  function addRuleToDropdown(newRule){
    if (vm.rules) vm.rules.push(newRule);
    else vm.rules = [newRule];

    vm.formData.lotScreenRuleId = newRule.id;
  }

  vm.resetForm = resetForm;
  function resetForm(){
    vm.formData = {};
    vm.groupAccounts = [];
    vm.selectedAccounts = [];

  }

  function openRuleModal(){


    var modalInstance = $modal.open({
      animation: true,
      template: $templateCache.get('taxRuleModal.tpl.html'),
      size: 'lg',
      resolve: {},
      controller: function($uibModalInstance){

        var vm = this;
        var rule;

        vm.creatingRule = false;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.rule = {};
        vm.popoverVerbiage = popoverVerbiage;
        vm.verbiages = {
          minGainAmount: "<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. For Gains, please use a positive value in both fields. This filter limits the system from flagging small gains in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossAmount:"<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. This filter limits the system from flagging small losses in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore losses where the position is only slightly down since acquisition.</span>',
          minGainPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore gains where the position is only slightly down since acquisition.</span>'
        };


        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(newRule){

          if (newRule.applyToLots || newRule.applyToPositions) {
            var payload = {
              "name": newRule.name,
              "minimumGainValue": newRule.minimumGainValue,
              "minimumGainPercent": newRule.minimumGainPercent / 100,
              "minimumLossValue": Math.abs(newRule.minimumLossValue),
              "minimumLossPercent": Math.abs(newRule.minimumLossPercent / 100),
              "minimumPositionLoss": Math.abs(newRule.minimumPositionLoss / 100),
              "minimumPositionGain": newRule.minimumPositionGain / 100,
              "applyToLots": newRule.applyToLots || false,
              "applyToPositions": newRule.applyToPositions || false
            };

            vm.creatingRule = true;
      
            HarvestingFactory.createHarvestRule(payload)
            .then(function(res){
              var response = res.data;
              var newRule = response.data;
              toastr.success('Rule created');
              $uibModalInstance.close(newRule);
            })
            .catch(function(err){
              // console.error(err);
              // toastr.error('Error creating rule');
              Dashboard.toastError("Failed to create rule", err);
            })
            .finally(function(){
              vm.creatingRule = false;
            });
          }
          else vm.showAlert = true;
        }

      },
      controllerAs: 'vm'
    });

    modalInstance.result.then(function(newRule) {      
      $timeout(function() {
        addRuleToDropdown(newRule);
      });
    });
  }

  function addAccountsToGroup(id, updating){

    var groupAccounts = vm.groupAccounts;
    var existingGroupAccountIds = _.map(vm.existingGroupAccounts, function(member){ return member.account.id; });
    var successMsg = updating ? "Group data successfully updated." : "Group successfully created.";

    

    // avoid saving ones that already exist
    groupAccounts = groupAccounts.filter(function(account){
      return account.account ? !_.contains(existingGroupAccountIds, account.account.id) : !_.contains(existingGroupAccountIds, account.id);
    });
    var reqs = groupAccounts.map(function(account){ 
      var payload = {
        accountId: account.account ? (account.account.id || account.account.accountId) : (account.account.id || account.account.accountId),
        harvestTaxes: account.harvestTaxes || false,
        monitorWashSale: account.monitorWashSale || false
      };

      return HarvestingFactory.addAccountsToGroup(id, payload);
    });

    if (reqs.length){
      $q.all(reqs)
      .then(function(res){
        vm.selectedAccounts = [];
        init(true);
        toastr.success(successMsg);
        $rootScope.$broadcast('refresh-tax-groups');
      })
      .catch(function(err){
        // console.log(err);
        Dashboard.toastError(err.message, err);
      });
    } else {
      vm.selectedAccounts = [];
      toastr.success(successMsg);
      $rootScope.$broadcast('refresh-tax-groups');
    }
  }

  function updateGroupAccount(account){

    var existingGroupAccountIds = _.map(vm.existingGroupAccounts, function(member){ return member.account.id; });

    // when harvest taxes is true, we are monitoring wash sales by default
    if (account.harvestTaxes) account.monitorWashSale = true;

    if( account.isNew ) return false;

    // only update group accounts if existing groups
    if (vm.formData.id){
      var accountId = account.account.id;
      var payload = {
        accountId: accountId,
        harvestTaxes: account.harvestTaxes || false,
        monitorWashSale: account.harvestTaxes ? true : (account.monitorWashSale || false)
      };

      HarvestingFactory.updateGroupAccount(vm.formData.id, payload, accountId)
      .then(function(res){
        //toastr.success('Account updated');
        account.isNew = false;
      })
      .catch(function(err){
        // toastr.error(err.message);
        // console.error(err);
        Dashboard.toastError(err.message, err);
      });
    }
  }

  function addGroup(groupData){
    var payload = {
      name: groupData.groupName,
      filingStatus: groupData.filingStatus,
      taxDate: {
        month: groupData.taxMonth,
        day: groupData.taxDay
      },
      income: 0,
      longTermGoal: groupData.longTermGoal,
      shortTermGoal: groupData.shortTermGoal,
      lotScreenRuleId: groupData.lotScreenRuleId,
      enterpriseId: EnterpriseFactory.getUserEnterprise().id,
      shortTermTaxRate: !_.isEmpty(groupData.shortTermTaxRate) ? math.format(groupData.shortTermTaxRate / 100, {precision: 14}) : null
    };

    if (payload.shortTermTaxRate === null) {
      Dashboard.toastError('Please enter a short term tax rate');
      return false;
    }

    vm.savingGroup = true;

    // PUT if editing an existing
    if (groupData.id){


      HarvestingFactory.updateTaxGroup(groupData.id, payload)
      .then(function(res){
        // toastr.success("Group data successfully updated.");
        // once we have the id for the new group, we can add the accounts
        addAccountsToGroup(groupData.id, true); 
        vm.savingGroup = false;
      })
      .catch(function(err){
        vm.savingGroup = false;
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
      });
    } else {

      HarvestingFactory.createTaxGroup(payload)
      .then(function(res){
        // toastr.success('Group successfully created.');

        var response = res.data;
        // once we have the id for the new group, we can add the accounts
        var newGroupId = response.data.id;
        vm.formData.id = newGroupId;
        vm.groupName = response.data.name;
        addAccountsToGroup(newGroupId);
        vm.savingGroup = false;
        $rootScope.$broadcast('refresh-tax-groups'); 
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
        vm.savingGroup = false;
      });
    }
  }
});