angular.module('SecurityAdministrator')
.directive('securityAdminAccounts', function($templateCache) {                                                                                                                                       
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('securityAdminAccounts.tpl.html'),
      controller: securityAdminAccountsCtrl,
      controllerAs: 'vm'
    };                                                                                  
    
    function securityAdminAccountsCtrl ($templateCache, $modal, Brokerage, EnterpriseFactory, Dashboard, SmartXFactory, toastr, NgTableParams, $scope, $q, $element, Tabs, $filter){
      var vm = this;
      var accountApi = new SmartX.AccountApi();
      var initialBrokers = Brokerage.getActiveAccountBrokerages();
      var initialEnterprises = EnterpriseFactory.getActiveAccountEnterprises();

      var enterpriseAccess = EnterpriseFactory.getEnterpriseAccess(); // enterprises list from users/access response
      var enterpriseAccountRelationships = EnterpriseFactory.getEnterpriseRelationships('enterpriseAccount'); // enterpriseRelationships from users/access where type is 'enterpriseAccount'
      var foreignEnterpriseAccess = EnterpriseFactory.getForeignEnterpriseAccess(); // foreignEnterpriseAccess from users/access

      console.log('Enterprise Access: ', enterpriseAccess);
      console.log('enterpriseAccount relationships', enterpriseAccountRelationships);
      console.log('Foreign Enterprise Access: ', foreignEnterpriseAccess);

      vm.userEnterprise = EnterpriseFactory.getUserEnterprise();

      vm.selected = [];
      vm.checkboxes = {
        checked: false,
        items: {}
      };

      vm.loading = false;

      vm.showEditAccounts = showEditAccounts;
      vm.showMoveAccounts = showMoveAccounts;
      vm.refreshAccounts = refreshAccounts;
      vm.viewAccount = viewAccount;
      vm.clearSelection = clearSelection;
      vm.updateIsApm = updateIsApm;
      vm.enterpriseSelected = enterpriseSelected;

      init();

      $scope.$watch(function(){
        return vm.accountsTable && vm.accountsTable.filter();
      }, function (filters) {
        if (vm.accountsTable){
          vm.activeFilters = _.filter(_.keys(filters), function(key){
            return !_.isEmpty(filters[key]) && key != 'status';
          });
          vm.activeFiltersCount = vm.activeFilters.length;
          // vm.filteredData = $filter('filter')(vm.accounts, formatFilters(filters));
        }
      }, true);
  
      // watch for check all checkbox
      $scope.$watch(function() {
        return vm.checkboxes.checked;
      }, function(value) {
        var filters = vm.accountsTable.filter();

        var filteredAccounts = vm.filteredData; // $filter('filter')(vm.accounts, formatFilters(filters));

        angular.forEach(filteredAccounts, function(item) {
          vm.checkboxes.items[item.id] = value;
        });
  
        vm.selected = getSelected(vm.accountsTable, vm.checkboxes);
  
      });

      // keep the select all checkbox in sync with what's on the current page
      $scope.$watch('vm.filteredData', function (newValue){
        var checkedItems = vm.checkboxes.items;

        if (angular.isArray(newValue)) {
          vm.checkboxes.checked = _.every(newValue, function(account){
            return checkedItems[account.id];
          });
        }
      });
      
      // watch for data checkboxes
      $scope.$watch(function() {
        return vm.checkboxes.items;
      }, function() {
        if (vm.filteredData && vm.filteredData.length > 0){
          var filters = vm.accountsTable.filter();
          var filteredAccounts = vm.filteredData; // $filter('filter')(vm.accounts, formatFilters(filters));
          var checked = 0, unchecked = 0,
              total = filteredAccounts.length;

          angular.forEach(filteredAccounts, function(item) {
            checked   +=  (vm.checkboxes.items[item.id]) || 0;
            unchecked += (!vm.checkboxes.items[item.id]) || 0;
          });
          if ((unchecked == 0) || (checked == 0)) {
            vm.checkboxes.checked = (checked == total);
          }
    
          vm.selected = getSelected(vm.accountsTable, vm.checkboxes);

          // grayed checkbox
          angular.element($element[0].getElementsByClassName("account-select-all")).prop("indeterminate", (checked != 0 && unchecked != 0));
        }
        
      }, true);

      $scope.$watch('vm.enterpriseFilter.enterpriseId.searchText', function(newVal, oldVal){
        if (angular.isDefined(oldVal) && (oldVal.length || oldVal.name) && newVal === '') {
          // the input is clear
          // remove the enterprise filter to trigger request
          var filters = vm.accountsTable.filter();
          vm.accountsTable.filter(_.extend(filters, {
            enterpriseId: ''
          }));
        }
      });

      vm.accountEnterprises = [{
        id: '',
        title: 'All Enterprises'
      }];

      vm.accountBrokerageFirms = [{
        id: "",
        title: "Select Firm"
      }];

      vm.nameFilter = {
        'name': {
          id: "debouncedText",
          placeholder: "Enter name"
        }
      };

      // vm.enterpriseFilter = {  
      //   'enterpriseId': {
      //     id: 'select',
      //     placeholder: 'Select Enterprise'
      //   }
      // };

      vm.enterpriseFilter = {
        'enterpriseId': {
          id: "enterprise-lookup",
          placeholder: "Enter an enterprise",
          searchText: ''
        }
      };

      vm.brokerageAccountNumberFilter = {
        'acctNumber': {
          id: "debouncedText",
          placeholder: "Enter #",
        }
      };

      function enterpriseSelected(item){
        var filters = vm.accountsTable.filter();
        filters.enterpriseId = item.id;
        vm.accountsTable.filter(filters);
      }

      function updateAccountEnterprises (enterprises) {

        if (!enterprises) vm.enterpriseOptions = [];
        else vm.enterpriseOptions = enterprises.map(function(enterprise){
          return {
            id: enterprise.id,
            name: enterprise.name
          };
        });

        // if (!enterprises) enterprises = [];
        // vm.accountEnterprises.splice(1);
        // enterprises.forEach(function(enterprise){
        //   vm.accountEnterprises.push({
        //     id: enterprise.id,
        //     title: enterprise.name
        //   });
        // });
      }

      function updateBrokerList (brokers) {
        if (!brokers) brokers = [];
        vm.accountBrokerageFirms.splice(1);
        brokers.forEach(function(broker){
          vm.accountBrokerageFirms.push({
            id: broker.id,
            title: broker.name
          });
        });
      }

      function isOnlyFilter (filterKey, ignoreList) {
        var filters = vm.accountsTable.filter();
        ignoreList = ignoreList || [];
        var activeFilters = _.filter(_.keys(filters), function(key){
          return !_.isEmpty(filters[key]) && !_.contains(ignoreList, key);
        });
  
        return activeFilters.length === 1 && activeFilters[0] === filterKey;
      }

      function init() {
        vm.loading = true;

        if (vm.accountsTable) vm.accountsTable.reload();
        else {


          vm.accountsTable = new NgTableParams({
            page: 1,
            count: 25,
            sorting: {
              name: 'asc'
            },
            filter: {
              brokerageId: '',
              name: '',
              acctNumber: '',
              enterpriseId: '',
            }
          }, {
            getData: function (params) {
                var page = params.page();
                var size = params.count();
                var sorting = params.sorting();
                var filter = params.filter();
                var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
                var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
                  
                var query = {
                  page: page,
                  pageSize: size,
                  sort: sort,
                  sortDirection: direction
                };

                _.extend(query, filter);

                console.log("Search Params: ", query);

                vm.loading = true;
                return SmartXFactory.getAccountsWithMetadata(query)
                        .then(function(res) {
                          var brokersList = res.metaData.brokerages;
                          var enterprisesList = res.metaData.enterprises;

                          params.total(res.metaData.totalRecordCount);

                          if (angular.isArray(initialBrokers) && !initialBrokers.length) {
                            initialBrokers = brokersList;
                          }

                          if (angular.isArray(initialEnterprises) && !initialEnterprises.length) {
                            initialEnterprises = enterprisesList;
                          }

                          if (!vm.totalAccountsCount) {
                            vm.totalAccountsCount = res.metaData.totalRecordCount;
                          }

                          if (vm.accountBrokerageFirms.length === 1) {
                            updateBrokerList(brokersList);
                            // initialBrokers = brokersList;
                          } else {
                            if (filter.brokerageId == '' || !vm.activeFiltersCount) {
                              updateBrokerList(brokersList);
                            } else if (isOnlyFilter('brokerageId')){
                              updateBrokerList(initialBrokers);
                            }
                          }

                          if (!vm.enterpriseOptions) {
                            updateAccountEnterprises(enterprisesList);
                          } else {
                            // if no filters or enterprise filter is empty
                            if (_.isEmpty(filter.enterpriseId) || !vm.activeFiltersCount){
                              updateAccountEnterprises(enterprisesList);
                            } else if (isOnlyFilter('enterpriseId')){
                              updateAccountEnterprises(initialEnterprises);
                            }
                          }

                          vm.filteredData = res.accounts;
        
                          return res.accounts;
                        })
                        .catch(function(err) {
                          // toastr.error(err.message);
                          Dashboard.toastError(err.message, err);
                          $q.reject();
                        })
                        .finally(function(){
                          vm.loading = false;
                        });
            },
          });
        }
      }

      // convert dot notation key / value pair to nested object
      // {'enterprise.name':'name'} -> { enterprise: { name: 'name' } }
      function dotToNested (key, value) {
        var obj = {};
        var container = obj;
        key.split('.').map(function(k, i, values) {
          container = (container[k] = (i == values.length - 1 ? value : {}));
        });

        return obj;
      }

      function formatFilters (filters) {
        filters = _.reduce(angular.copy(filters), function(obj, v, k){
          if (k.split('.').length > 1) {
            v = dotToNested(k, v);
            _.extend(obj, v);
          } else {
            obj[k] = v;
          }

          return obj;
        }, {});

        return filters;
      }

      function clearSelection () {
        vm.checkboxes.items = {};
      }

      function viewAccount(account) {
        Tabs.addTab('main', account.id, null, account);
      }
  
      function refreshAccounts () {
        init();
      }

      function updateIsApm (account){

        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'confirmUpdateApm.html',
          resolve: {
            account: function () {
              return account;
            },
          },
          controller: function ($uibModalInstance, $scope, account) {
            var vm = this;
            vm.account = account;
            vm.confirm = confirm;
            vm.cancel = cancel;

            function cancel() {
              vm.account.isApm = !vm.account.isApm;
              $uibModalInstance.dismiss('cancel');
            }

            function confirm () {
              $uibModalInstance.close(true);
            }
            
          },
          controllerAs: 'vm'
        });
        
        modalInstance.result.then(function(confirmed) {
          if (confirmed) {
            account.updating = true;
            $q.when(accountApi.updateAccountProperties(account.id, {isApm: account.isApm}))
            .then(function(res){
              console.log(res);
              toastr.success('Account ' + account.brokerageAccountNumber + ' updated');
            })
            .catch(function(err){
              account.isApm = !account.isApm; // reset it back to original value
              Dashboard.toastError(err.message, err);
            })
            .finally(function(){
              account.updating = false;
            });
          }
        });
      }

      function showEditAccounts () {
        // show modal for editing fields for the selected group of accounts
        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('editAccountModal.tpl.html'),
          resolve: {
            selectedAccounts: function() {
              return vm.selected;
            }
          },
          controller: 'SecurityAdminEditAccountCtrl',
          controllerAs: 'vm'
        });
  
        modalInstance.result.then(function(complete) {
          if (complete){
            // toastr.success("Enterprise created!");
            clearSelection();
            refreshAccounts();
          }
        });
      }

      function showMoveAccounts () {
        // show modal for editing fields for the selected group of accounts
        var modalInstance = $modal.open({
          animation: true,
          backdrop: 'static',
          template: $templateCache.get('moveAccountModal.tpl.html'),
          resolve: {
            selectedAccounts: function() {
              return vm.selected;
            },
            currentPage: function () {
              return vm.accountsTable.data;
            }
          },
          controller: 'SecurityAdminMoveAccountCtrl',
          controllerAs: 'vm'
        });
  
        modalInstance.result.then(function(complete) {
          if (complete){
            // toastr.success("Enterprise created!");
            clearSelection();
            refreshAccounts();
          }
        });
      }

      // function showMoveEnterprise () {
      //   var selectedUsers = vm.selectedUsers;

      //   var modalInstance = $modal.open({
      //     animation: true,
      //     templateUrl: 'moveUserModal.html',
      //     resolve: {
      //       selectedUsers: function () {
      //         return selectedUsers;
      //       },
      //       userApi: function () {
      //         return userApi;
      //       }
      //     },
      //     controller: 'SecurityAdminMoveUserCtrl',
      //     controllerAs: 'vm'
      //   });

      //   modalInstance.result.then(function(res){
      //     if (res === 'complete'){
      //       vm.selectedUsers = [];
      //       refreshUsers();
      //     }
      //   });
      // }

      function getSelected(table, checkboxes){
        var selectedItems = checkboxes.items;
        // var filters = vm.usersTable.settings().filter();
        var selected = [];
        // if (table) {
        //   var data = table.settings().dataset;
        //   // data = $filter('filter')(data)(filters);
    
        //   selected = _.filter(data, function(item){
        //     return checkedItems[item.id];
        //   });
        // }

        selected = _.chain(angular.copy(selectedItems))
                    .map(function(selected, id){
                      return {
                        id: id,
                        selected: selected
                      };
                    })
                    .filter(function(item){
                      return item.selected;
                    })
                    .value();
  
        // console.log("Items Selected: ", selected);
        return selected;
      }
    }
});