angular.module('PDFViewerCtrl', [])
.controller('PDFViewerCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'Dashboard', 'pdfURL', function ($scope, $rootScope, $uibModalInstance, toastr, Dashboard, pdfURL) {

	var vm = this;
	
	vm.pdfURL = pdfURL;	
	
	init();
	
	/////////////
	
	vm.cancel = cancel;
	
	function init()
	{
		if( typeof vm.pdfURL != 'undefined' && vm.pdfURL != null ){
			
		}
	}

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }
	
}]);