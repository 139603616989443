angular.module('SideMenu')
.directive('clientGroup', function($templateCache){

    return {
        restrict: 'E',
        scope: false,
        template: $templateCache.get('ClientMode.tpl.html')
        // link: link,
        // controller: clientGroupCtrl
    };

    function buildClientGroup()
    {

        var groups = [
            {
                name: "Group 1",
                guid : '1234'
            },
            {
                name: "Group 2",
                guid : '234'
            },
            {
                name: "Group 3",
                guid : '345'
            },
        ],
        div = '<div class="clearfix swal-wrap"><p class="text-center" style="margin-bottom:10px;">Select a family group for which you want to start a presentation.</p><ul class="client-groups clearfix">';
        angular.forEach(groups, function(group, key){
            div = div + '<li class="client-group"><a ng-click="enterClientMode(' + group.guid + ')">' + group.name + '</a></li>';
        });
        div += '</ul></div>';
        return div;
    }
});