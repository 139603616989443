angular.module('BillingModule')
.directive('feeBlotter', function($templateCache) {
	return {
		restrict: 'E',
		scope: {
      active: '=active'
    },
    template: $templateCache.get('fee-blotter.tpl.html'),
		controller: feeBlotterCtrl,
		controllerAs: 'vm',
  };
  
  function feeBlotterCtrl (USER, $uibModal, $scope, NgTableParams, FeeBlotter, $modal, $templateCache, Brokerage, $filter, $timeout, Billing, Dashboard, $q) {
    var vm = this;
    var defaultTierPayload;
    var ACCOUNTS_THRESHOLD = 2000; // force them to look up a specific account if they have more than this
    var lastQuery; // track the query params for the most recent billing records request
    var batchQueryNum = 1;
    var previousPageItem = 1;

    vm.totalPageItems = 12;
    vm.currentPageItem = 1;
    vm.beginningRange = 1;
    vm.endRange = 2000;
    vm.loading = false;
    vm.exporting = false;
    vm.exportBillingRecords = exportBillingRecords;
    vm.toggleToolPanel = toggleToolPanel;
    vm.viewFeeSchedule = viewFeeSchedule;
    vm.refresh = refresh;
    vm.disabled = disabled;
    vm.applyDateFilter = applyDateFilter;
    vm.open = open;
    vm.setAccount = setAccount;
    vm.clearAccount = clearAccount;
    vm.billingPeriodOptions = Billing.dateOptions;
    vm.activeAccountsCount = Dashboard.getTotalAccountsCount();
    vm.loadingAccountsCount =  isFinite(vm.activeAccountsCount) ? false : true; // skip the loader if we already have our count
    vm.showModal = showModal;
    vm.loadingBillingRecords = false;

    console.log("Active accounts: ", vm.activeAccountsCount);

    // fee popup calculation
    vm.flatCalculation;
    vm.feeTableCalculation;

    // MonthlyInArrears, MonthlyInAdvance, QuarterlyInAdvance, QuarterlyInArrears, 
    // AnnualInArrears
    var billingTypeMapping = {
      "monthlyInAdvance": "Monthly in Advance",
      "monthlyInArrears": "Monthly in Arrears",
      "quarterlyInAdvance": "Quarterly in Advance",
      "quarterlyInArrears": "Quarterly in Arrears",
      "annualInArrears": "Annual in Arrears"
    };

    var billingRunTypeMapping = {
      "terminationTrueUp": "Termination True Up",
      "regular": "Regular",
      "inception": "Inception",
      "termination": "Termination"
    };

    // vm.period = {
    //   options:[
    //     {value: 'monthly', text: 'Current Month'},
    //     {value: 'quarterly', text: 'Current Quarter'},
    //     {value: 'yearly', text: 'Current Year'},
    //     {value: 'priorMonth', text: 'Prior Month'},
    //     {value: 'priorQuarter', text: 'Prior Quarter'},
    //     {value: 'priorYear', text: 'Prior Year'},
    //     {value: 'custom', text: 'Custom'}
    //   ],
    //   selected: ''
    // };

    // vm.period.selected = vm.period.options[1];

    // date picker config
    // vm.formats = ['mm/DD/yyyy', 'shortDate', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy'];
    vm.format = 'MM/dd/yyyy'; // vm.formats[0];
    vm.opened = {
      start: false,
      end: false
    };
    vm.minDate = '';
    vm.maxDate = moment().format();
    vm.dateOptions = {
      formatYear: 'yyyy',
      startingDay: 7
    };

    var month = moment().month() + 1;
    var year = moment().year();
    vm.customStart = ''; // moment(month + '/01/' + year).format();
    vm.customEnd = ''; // moment().add(1, 'day').format();


    // table
    vm.tableCols = FeeBlotter.columnDefs;
    /* vm.feeTypes = FeeBlotter.feeTypes;
    vm.paidStatusTypes = FeeBlotter.paidStatusTypes;
    vm.approvalStatusTypes = FeeBlotter.approvalStatusTypes; */

    console.log(vm.tableCols);

    // this is a list of the fee tiers
    // can get this from the fee schedule of the account for the associated row
    // get the fee schedule and then turn it into a list of the tiers to be used for the calculateRates method
    vm.feeTableData = defaultTierPayload = [{
      feeAmount: 1,
      startingLimit: 0,
      endingLimit: 999999999999
}, /* {
      feeAmount: null,
      startingLimit: null,
      endingLimit: null
} */];

    // vm.flatCalculation = Brokerage.getFlatCalculation();
    // vm.feeTableCalculation = Brokerage.getFeeTableCalculation();

    // this will change depending on which row is hovered over
    // this is watched for changes, and when it does, the fee calculation is triggered
    vm.hoveredRow = null;
    vm.showFeePopup = showFeePopup;
    vm.tooltipShowing = false;

    var blotterData = [
      {
        status: "Complete",
        collectedDate: "04-01-2021",
        repCode: "ABC123",
        brokerageAccountNumber: "U123456",
        paidByAcct: "U123456",
        accountName: "Nakamoto IRA #2",
        feeType: "Management Fee",
        blendedAum: "$1,234,456",
        billableAum: "$1,100,000",
        feeRate: 0.005 * 100,
        calculatedFee: 0.005 * 1100000,
        chargedFee: 0.005 * 1100000,
        feeFrom: "03-01-2021",
        feeTo: "03-31-2021",
        aumDate: "03-31-2021",
        calculatedOn: "03-31-2021",
        frequency: "Monthly",
        feeTiers: [{
          feeAmount: 0.005 * 100,
          startingLimit: 0,
          endingLimit: 1100000
        }]
      },
      {
        feeTiers: [{
          feeAmount: 0.01 * 100,
          startingLimit: 0,
          endingLimit: 1100000
        }],
        status: "Complete",
        collectedDate: "04-01-2021",
        repCode: "ABC123",
        brokerageAccountNumber: "U123456",
        paidByAcct: "U123456",
        accountName: "Nakamoto IRA #2",
        feeType: "Management Fee",
        blendedAum: "$1,234,456",
        billableAum: "$1,100,000",
        feeRate: 0.005 * 100,
        calculatedFee: 0.005 * 1234000,
        chargedFee: 0.005 * 1100000,
        feeFrom: "03-01-2021",
        feeTo: "03-31-2021",
        aumDate: "03-31-2021",
        calculatedOn: "03-31-2021",
        frequency: "Monthly"
      },
      {
        feeTiers: [{
          feeAmount: 0.02 * 100,
          startingLimit: 0,
          endingLimit: 565000
        }],
        status: "Insufficient",
        collectedDate: "",
        repCode: "ABC123",
        brokerageAccountNumber: "U123456",
        paidByAcct: "U123456",
        accountName: "Nakamoto IRA #2",
        feeType: "Management Fee",
        blendedAum: "$1,234,456",
        billableAum: "$565,000",
        feeRate: 0.005 * 100,
        calculatedFee: 0.005 * 11080900,
        chargedFee: 0.005 * 1100000,
        feeFrom: "03-01-2021",
        feeTo: "03-31-2021",
        aumDate: "03-31-2021",
        calculatedOn: "03-31-2021",
        frequency: "Monthly"
      },
      {
        feeTiers: [{
          feeAmount: 0.015 * 100,
          startingLimit: 0,
          endingLimit: 2500500
        }],
        status: "Approved",
        collectedDate: "",
        repCode: "ABC123",
        brokerageAccountNumber: "U123456",
        paidByAcct: "U123456",
        accountName: "Nakamoto IRA #2",
        feeType: "Flow Adjustment",
        blendedAum: "$1,234,456",
        billableAum: "$1,100,000",
        feeRate: 0.005 * 100,
        calculatedFee: 0.005 * 1323000,
        chargedFee: '--',
        feeFrom: "03-01-2021",
        feeTo: "03-31-2021",
        aumDate: "03-31-2021",
        calculatedOn: "03-31-2021",
        frequency: "Monthly"
      },
      {
        feeTiers: [{
          feeAmount: 0.003 * 100,
          startingLimit: 0,
          endingLimit: 500000
        },
        {
          feeAmount: 0.001 * 100,
          startingLimit: 500001,
          endingLimit: 999999999
        }],
        status: "Complete",
        collectedDate: "04-01-2021",
        repCode: "ABC123",
        brokerageAccountNumber: "U123456",
        paidByAcct: "U123456",
        accountName: "Nakamoto IRA #2",
        feeType: "Management Fee",
        blendedAum: "$1,234,456",
        billableAum: "$1,100,000",
        feeRate: 0.005 * 100,
        calculatedFee: 0.005 * 1100000,
        chargedFee: 0.005 * 1100000,
        feeFrom: "03-01-2021",
        feeTo: "03-31-2021",
        aumDate: "03-31-2021",
        calculatedOn: "03-31-2021",
        frequency: "Monthly"
      }
    ];

    // $scope.$watch('vm.period.selected', function(newVal, oldVal){
    //   if (newVal && newVal !== oldVal) {
    //     console.log('Selected Period: ', newVal);
    //     if (newVal.value) {
    //       var filter = vm.tableParams.filter();
    //       var updatedFilter = _.extend(filter, {
    //         billingPeriod: newVal.value
    //       });
    //       vm.tableParams.filter(updatedFilter);
    //     }
    //   }
    // });

    // broadcast from 'BillingHomeCtrl.js' after the call to load the user's accounts is made
    // this will update the value in case we enter the fee blotter tab before the initial call for accounts has completed
    $scope.$on('gotAccountsCount', function(e, activeAccountsCount){
      console.log("Active accounts found: ", activeAccountsCount);
      vm.activeAccountsCount = activeAccountsCount;
      vm.loadingAccountsCount = false;
      if (vm.feeBlotterTable && activeAccountsCount){
        vm.feeBlotterTable.reload();
      }
    });

    $scope.$on('dateOptionsSet', function(){
      vm.billingPeriodOptions = Billing.dateOptions;
      setPeriods(vm.billingPeriodOptions);
      if (!vm.feeBlotterTable) {
        $q.when(loadColumnPresets())
        .then(function(){
          initColumns();
          init();
        });
      }
    });

    $scope.$watch('active', function(newVal, oldVal){
      if (newVal && newVal !== oldVal){
        if (vm.selectedAccount) return;
        else {
          $timeout(function(){
            var accountInput = jQuery('#feeBlotterAccountLookup').find('input');
            console.log(accountInput);
            accountInput.focus();
          }, 200);
        }
      }

      // // set our billing period options the first time this view is visited
      // if (newVal && !vm.billingPeriodOptions) {
      //   console.log("Date options: ", vm.billingPeriodOptions);
      //   vm.billingPeriodOptions = Billing.dateOptions;
      //   setPeriods(vm.billingPeriodOptions);
      // }
    });

    $scope.$watch('vm.selectedAccount', function(newVal, oldVal){
      if (angular.isDefined(newVal) && newVal !== oldVal) {
        var filter = vm.feeBlotterTable.filter();

        if (newVal && newVal.id){
          filter.accountIds = [newVal.id];
        } else if (newVal === null){
          filter.accountIds = [];
        }

        vm.feeBlotterTable.filter(filter);
      }
    });

    $scope.$watch('vm.period.selected', function(newVal, oldVal){
      if (oldVal && newVal && newVal !== oldVal && vm.loadingBillingRecords === false){
        init();
      }
    });

    $scope.$watch('vm.hoveredRow', function(current, previous) {
      console.log(current, previous);
      // when a new row is hovered we will re-run the fee calculation logic to update the data in the pop-up
      if (current && current !== previous) {
        var formattedResultsBlob = formatResultsBlob(current.resultsBlob);
        current.feeTiers = formattedResultsBlob;
        
        vm.feeTableData = current;
        calculateRates();
      }
    });

    $scope.$on('blotterColumnsChanged', function(event, models) {               
      var data = vm.feeBlotterTable.settings().dataset || models;

      vm.feeBlotterTable = new NgTableParams({
        sorting: { ytd: "desc" },
        filter: {
          billingApprovalStatusType: "",
          billingPaidStatusType: "",
          billingFeeType: ""
        },
        count: 25
      }, {
        counts: [25, 50, 100, 250],
        dataset: data
      });

      initColumns();
    });

    // $q.when(loadColumnPresets())
    //   .then(function(){
    //     initColumns();
    //     init();
    //   });

    function getDefaultQuery () {
      var params = vm.feeBlotterTable;
      var page = params ? params.page() : 1;
      var size = params ? params.count() : 25;
      var sorting = params ? params.sorting() : {"periodEndDate": "desc"};
      var filter = params ? params.filter() : {};
      var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
      var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
        
      var query = {
        pageNumber: page,
        pageSize: size,
        sortKey: sort,
        sortDescending: direction === 'desc' ? true : false,
        dateFilterLogic: "calculation",
        startDate: vm.period.selected.startDate,
        endDate: vm.period.selected.endDate,
        rollUpToSingleLine: true,
        accountBatch: batchQueryNum
      };

      return query;
    }

    function showModal (params) {
      var formattedBillingRun = moment(params.billingRun).format('YYYYMM');
      $modal.open({
        animation: true,
        template: $templateCache.get('averageValueModal.tpl.html'),
        size: "lg",
        resolve: {
          query: function () {
            return {accountId: params.accountId, billingRun: formattedBillingRun};
          }
        },
        controller: function (Billing, query, $uibModalInstance) {

            var slicedYear = formattedBillingRun.slice(0,4);
            var slicedMonth = Number(formattedBillingRun.slice(4,6));

            var months = {
              1: '01',
              2: '02',
              3: '03',
              4: '04',
              5: '05',
              6: '06',
              7: '07',
              8: '08',
              9: '09',
              10: '10',
              11: '11',
              12: '12',
            };

          var vm = this;
          var previousPage = 12;
          var firstRun;
          var lastRun;
          vm.loading = true;
          vm.accountNumber = params.brokerageAccountNumber;

          vm.totalItems = 120;
          vm.currentPage = 12;

          vm.maxSize = 0;

          function paginationForBillingRun(billingRun, lastOrFirst, direction) {
            vm.loading = true;
            $q.when(Billing.getAccountAumData({accountId: params.accountId, billingRun: billingRun}))
            .then(function (res) {

              if(res.errorContent) {
                return;
              }

              if(lastOrFirst == billingRun && direction == 'down') {
                previousPage = 1;
                vm.currentPage = 1;
              }

              if(lastOrFirst == billingRun && direction == 'up') {
                previousPage = 12;
                vm.currentPage = 12;
              }
              
              vm.billingPeriod = slicedYear + '-' + months[slicedMonth];

              res.data.hisImpDataList.forEach(function (data) {
                data.allocationValueDate = moment(data.allocationValueDate).format('MM/DD/YYYY');
              });

              vm.accountAumData = res.data;
              vm.averageValueData = res.data.hisImpDataList;

              if (res.data.hasCashExclusion === true) {
                vm.localExclusions = "Yes";
              } else {
                vm.localExclusions = "No";
              }

              if (res.data.hasOtherExclusion === true) {
                vm.excludeCash = "Yes";
              } else {
                vm.excludeCash = "No";
              }

            })
            .catch(function (err) {
              console.error(err);
            })
            .finally(function () {
              vm.loading = false;
            });
          }

          vm.setPage = function (pageNum) {

            vm.currentPage = pageNum;

            if(pageNum === previousPage) {
              return;
            }

            if(pageNum == 1) {
              slicedMonth = Number(firstRun.toString().slice(4,6));
              slicedYear = Number(firstRun.toString().slice(0,4));
              previousPage = pageNum;
              formattedBillingRun = slicedYear + months[slicedMonth];
              return paginationForBillingRun(firstRun, firstRun, 'down');
            }

            if(pageNum == 12) {
              slicedMonth = Number(lastRun.toString().slice(4,6));
              slicedYear = Number(lastRun.toString().slice(0,4));
              previousPage = pageNum;
              formattedBillingRun = slicedYear + months[slicedMonth];

              return paginationForBillingRun(lastRun, lastRun, 'up');
            }

            if(previousPage < pageNum) {

              var nextBillingRun;

              if(slicedMonth < 12) {
                slicedMonth = slicedMonth + 1;
                nextBillingRun = slicedYear + months[slicedMonth];
              } else {
                slicedYear = slicedYear + 1;
                slicedMonth = 1;
                nextBillingRun = slicedYear + months[slicedMonth];
              }

              previousPage = pageNum;
              formattedBillingRun = nextBillingRun;

              return paginationForBillingRun(nextBillingRun, lastRun, 'up');
             
            }

            if(previousPage > pageNum) {

              var previousBillingRun;

              if(slicedMonth > 1) {
                slicedMonth = slicedMonth - 1;
                previousBillingRun = slicedYear + months[slicedMonth];
              } else {
                slicedYear = slicedYear - 1;
                slicedMonth = 12;
                previousBillingRun = slicedYear + months[slicedMonth];
              }

              previousPage = pageNum;
              formattedBillingRun = previousBillingRun;

              return paginationForBillingRun(previousBillingRun, firstRun, 'down');
            }

          };

          if (params.billingType === "monthlyInAdvance") {
            params.billingType = "Monthly In Advance";
          }
          if (params.billingType === "monthlyInArrears") {
            params.billingType = "Monthly In Arrears";
          }
          if (params.billingType === "quarterlyInAdvance") {
            params.billingType = "Quarterly In Advance";
          }
          if (params.billingType === "quarterlyInArrears") {
            params.billingType = "Quarterly In Arrears";
          }

          vm.billingFrequency = params.billingType;
          vm.billingPeriod = moment(params.billingRun).format('YYYY-MM');

          Billing.getFirstAndLastBillingRunForAccount({accountId: params.accountId})
          .then(function (res) {

            if(res.errorContent) {
              return;
            }

            firstRun = res.data.firstRun;
            lastRun = res.data.lastRun;

          });

          $q.when(Billing.getAccountAumData(query))
          .then(function (res) {

            if(res.errorContent) {
              return;
            }

            res.data.hisImpDataList.forEach(function (data) {
              data.allocationValueDate = moment(data.allocationValueDate).format('MM/DD/YYYY');
            });

            vm.accountAumData = res.data;
            vm.averageValueData = res.data.hisImpDataList;

            if (res.data.hasCashExclusion === true) {
              vm.localExclusions = "Yes";
            } else {
              vm.localExclusions = "No";
            }

            if (res.data.hasOtherExclusion === true) {
              vm.excludeCash = "Yes";
            } else {
              vm.excludeCash = "No";
            }

          })
          .catch(function (err) {
            console.error(err);
          })
          .finally(function () {
            vm.loading = false;
          });

          vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };

        },
        controllerAs: 'vm'
      });
    }

    vm.setBatchPage = function (pageNo, direction) {

      if(pageNo === previousPageItem) {
        return;
      }

      if (previousPageItem < pageNo && pageNo > 0 && vm.endRange !== vm.activeAccountsCount) {
        batchQueryNum = batchQueryNum + 1;
        vm.feeBlotterTable.reload();
        vm.beginningRange = direction === 'forward' ? vm.endRange + 1 : vm.endRange;

        if (vm.endRange + 2000 > vm.activeAccountsCount) {
          vm.endRange = vm.activeAccountsCount;
        } else {
          vm.endRange = vm.endRange + 2000;
        }
        if (pageNo > 0) {
          vm.currentPageItem = pageNo;
        }
        previousPageItem = pageNo;
      }

      if (previousPageItem > pageNo && pageNo > 0) {
        batchQueryNum = batchQueryNum - 1;
        vm.feeBlotterTable.reload();
        vm.endRange = vm.endRange === vm.activeAccountsCount || direction === 'backward' ? vm.beginningRange - 1 : vm.beginningRange;

        if (pageNo === 1) {
          vm.beginningRange = 1;
        } else {
          vm.beginningRange = vm.beginningRange - 2000;
        }
        if (pageNo > 0) {
          vm.currentPageItem = pageNo;
        }
        previousPageItem = pageNo;
      }
    };

    function init () {

      if (vm.feeBlotterTable){
        vm.feeBlotterTable.reload();
        return;
      }

      vm.feeBlotterTable = new NgTableParams({
        page: 1,
        count: 25,
        sorting: {
          /* value: 'desc' */
          periodEndDate: 'desc'
        },
        filter: {
          billingApprovalStatusType: "",
          billingPaidStatusType: "",
          billingFeeType: "",
        }
      }, {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
          
          var query = {
            pageNumber: page,
            pageSize: size,
            sortKey: sort,
            sortDescending: direction === 'desc' ? true : false,
            dateFilterLogic: "calculation",
            startDate: vm.period.selected.startDate,
            endDate: vm.period.selected.endDate,
            rollUpToSingleLine: true,
            accountBatch: batchQueryNum
          };
          console.log("Filter Query: ", query);
          // if they have a small number of accounts, continue to fetch data for all of them when the tab loads
          // otherwise return without making a request and wait until they specify a single account
          // if (vm.loadingAccountsCount || vm.activeAccountsCount && vm.activeAccountsCount > ACCOUNTS_THRESHOLD && !vm.selectedAccount){
          //   return Promise.resolve([]);
          // }
            
          _.extend(query, filter);
            
          // strip fields with null/undefined or empty string values
          query = _.pick(query, function(v){
            return v && v !== '' ;
          });
            
          console.log("Search Params: ", query);
            
          vm.loadingBillingRecords = true;
          vm.billingRecordsQuery = query;

          lastQuery = query;
          
          return $q.when(Billing.getRollupBillingRecordsByAccount(query))
                  .then(function(res) {
                    params.total(res.data.metaData.totalRecordCount);
                    // $scope.totalValue = res.metaData.totalValue;
                    // var brokersList = res.metaData.brokerages;
                    // var enterprisesList = res.metaData.enterprises;
                    var billingRecords = formatRecords(res.data);
                    vm.noResults = billingRecords.length ? false : true;
                    return billingRecords;
                  })
                  .catch(function(err) {
                    // toastr.error(err.message);
                    Dashboard.toastError(err.message, err);
                    $q.reject();
                  })
                  .finally(function(){
                    vm.loadingBillingRecords = false;
                  });
        },
      });
    }

    function formatRecords (res, exporting) {
      return res.data.map(function(record){
        record.billingFeeType = FeeBlotter.enumValuesToText[record.billingFeeType];
        record.billingPaidStatusType = FeeBlotter.enumValuesToText[record.billingPaidStatusType] || record.billingPaidStatusType;
        record.billingApprovalStatusType = FeeBlotter.enumValuesToText[record.billingApprovalStatusType];
        record.repCode = record.repCode && record.repCode[0] ? record.repCode[0].name : '--';
        record.billingType = billingTypeMapping[record.billingType] || record.billingType;
        record.billingRunType = billingRunTypeMapping[record.billingRunType] || record.billingRunType;

        if (exporting) {
          record = {
            "Paid Status": record.billingPaidStatusType,
            "Account Name": record.accountName,
            "Account #": record.brokerageAccountNumber,
            "Rep Code": record.repCode,
            "Paid By Account #": record.paidByBrokerageAccountNumber,
            "Fee Type": record.rollupName || record.billingFeeType,
            "Period Type": record.billingPeriodType,
            "Billing Method": record.billingMethodType,
            "Billing Type": billingTypeMapping[record.billingType] || record.billingType,
            "Blended AUM": record.entityAumBasis.toFixed(2),
            "Billable AUM": record.entityAum.toFixed(2),
            "Fee Rate": (record.averageFeePercent * 100).toFixed(4) + '%',
            "Net Fee Amt.": record.thisPeriodNetFeeAmount.toFixed(2),
            "This Period Advance Fee Estimate": record.thisPeriodAdvanceFeeEstimate.toFixed(2),
            "Last Period Advance Fee Amt.": record.lastPeriodAdvanceFeeAmount.toFixed(2),
            "This Month Arrears Fee Amt.": record.thisMonthArrearsFeeAmount.toFixed(2),
            "This Period Arrears Fee Amt.": record.thisPeriodArrearsFeeAmount.toFixed(2),
            "Period Start": $filter('date')(record.periodStartDate, 'short'),
            "Period End": $filter('date')(record.periodEndDate, 'short'),
            "AUM Date": $filter('date')(record.aumDate, 'short'),
            "Calculated On": $filter('date')(record.computationDateTime, 'short'),
            "Holding Days in Period": record.holdingDaysInPeriod,
            "Mkt Days in Year": record.totalMarketDaysInYear,
            "Trading Days in Month": record.totalTradingDaysInMonth,
            "Billing Run": record.billingRun
          };
        }
        
        return record;
      });
    }

    function exportBillingRecords () {
      var totalRecords = vm.feeBlotterTable.total();
      var query = lastQuery || getDefaultQuery();

      query.pageNumber = 1;
      query.pageSize = totalRecords || 25; 

      vm.exporting = true;

      $q.when(Billing.getRollupBillingRecordsByAccount(query))
        .then(function(res) {
          
          var billingRecords = formatRecords(res.data, true);
          console.log("Billing Records: ", billingRecords);
          var start = moment(query.startDate).format('YYYYMMDD');
          var end = moment(query.endDate).format('YYYYMMDD');
          var filename = 'billing_records_' + start + '_to_' + end + '.csv';

          Dashboard.JSONtoCSV(billingRecords, filename);
        })
        .catch(function(err) {
          Dashboard.toastError(err.message, err);
          $q.reject();
        })
        .finally(function(){
          vm.exporting = false;
        });
    }

    function setPeriods (periods) {

      // add the custom option
      // periods.push({
      //   option: 'custom',
      //   startDate: null,
      //   endDate: null
      // });

      vm.period = {
        options: periods.map(function(period){
          return {
            value: period.option,
            text: $filter('formatStatus')(period.option),
            startDate: period.startDate,
            endDate: period.endDate
          };
        }),
        selected: ''
      };
  
      vm.period.selected = vm.period.options[0];
    }

    function setAccount(account) {
      vm.selectedAccount = account;
      vm.noResults = false;
    }

    function clearAccount() {
      vm.selectedAccount = null;
      // if (vm.activeAccountsCount <= ACCOUNTS_THRESHOLD){
        var filter = vm.feeBlotterTable.filter();
        filter.accountIds = [];
        vm.feeBlotterTable.filter(filter);
      // }
    }

    function loadColumnPresets () {

      var loadedPresets = store.get('smxFeeBlotterPresets');
      var toggleWidth = store.get('toggleWidth');

      if (toggleWidth && !_.isEmpty(toggleWidth.width)) {
        FeeBlotter.toggleSwitch = toggleWidth.width;
        vm.toggleSwitch = FeeBlotter.toggleSwitch;
      }
      if (loadedPresets && !_.isEmpty(loadedPresets.payload)) {
        FeeBlotter.columnDefs = loadedPresets.payload.filter(function (item) {
          return item.field !== 'today';
        });
        // .map(function(item){
        //   delete item.filter;
        //   return item;
        // });
        console.log("Loaded columns: ", FeeBlotter.columnDefs);
      } else {
        console.warn('Failed to get/load user preset');
      }
    }

    function open (picker){
      vm.opened[picker] = true;
    }

    function disabled(date, mode) {
      return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    }

    function applyDateFilter(start, end) {

      // if (start instanceof Date) start = moment(start).unix();
      // if (end instanceof Date) end = moment(end).unix();
      if (!vm.selectedAccount) {
        return alert('Must set an account');
      }

      var formattedStart = moment(start).format();
      var formattedEnd = moment(end).format();
      var filters = vm.feeBlotterTable.filter();
      var additionalFilters = vm.period.selected.value === 'custom' ? {
        startDate: moment(formattedStart).isValid() ? moment(formattedStart).startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null,
        endDate: moment(formattedEnd).isValid() ? moment(formattedEnd).endOf('day').format("YYYY-MM-DDTHH:mm:ss") : null,
        // periodType: vm.period.selected.value
      } : {
        startDate: vm.period.selected.startDate,
        endDate: vm.period.selected.endDate
      };

      if (vm.selectedAccount){
        _.extend(additionalFilters, {
          accountIds: [vm.selectedAccount.id]
        });
      }

      vm.feeBlotterTable.filter(_.extend(filters, additionalFilters));
    }

    function refresh () {
      if (vm.loadingBillingRecords) return;
      init();
    }

    function showFeePopup (row) {
      vm.feeBlotterTable.data.forEach(function(row) {
        row.showingPopup = false;
      });

      vm.tooltipShowing = false;

      if (row) {
        vm.tooltipShowing = true;
        row.showingPopup = true;
      }
    }

    // hide the pop-up on outside click
    // jQuery('html').on('click', function(e) {
    //   var $element = jQuery(e.target);
    //   console.log($element);
    //   if (!$element.hasClass('fa-info-circle') && !$element.hasClass('tooltip-trigger') && !$element.hasClass('feeCalculationTooltip')) {

    //     $timeout(function() {
    //       showFeePopup(false);
    //     });
    //   }
    // });

    function toggleToolPanel() {

      var modalInstance = $uibModal.open({
        animation: true,
        template: $templateCache.get('blotter.customize.modal.tpl.html'),
        controller: 'BlotterCustomizeModalCtrl'
      });

      modalInstance.result
        .then(function(data) {
          // $scope.$broadcast('blotter-data-ready', $scope.searchModels);
          initColumns();
        });

    }

    function initColumns() {

      var toggleColumnWidth = store.get('toggleWidth');
      var columnWidth = _.property(['width'])(toggleColumnWidth) || false; 
      vm.toggleSwitch = columnWidth || FeeBlotter.toggleSwitch;
      vm.columnDefs = FeeBlotter.columnDefs;
      vm.tableCols = [];
      for (var prop in vm.columnDefs) {
        if (vm.columnDefs.hasOwnProperty(prop)) {
          var column = vm.columnDefs[prop];
          if (typeof column.field == 'undefined') {
            column.field = 'feeInfo';
            // column.headerTemplateURL = 'searchHeaderCheckbox.html';
            column.show = true;
          }
          column.title = column.headerName;
          column.show = (typeof column.hide != 'undefined') ? !column.hide : true;
          if (column.show == true) {
            vm.tableCols.push(column);
          }
        }
      }
    }

    function formatResultsBlob(resultsBlob) {
      var sortedTiers = resultsBlob.sortedTiers;
      var numTiers = sortedTiers.length;
      var totalAum = resultsBlob.aumBasis;

      var formattedResults = sortedTiers.map(function(tier, i){
        var feeRate = tier.billingTierType === 'percent' ? tier.rateOrAmount : (tier.rateOrAmount / resultsBlob.sortedAums[i]);
        var tierData = {
          tierAum: resultsBlob.sortedAums[i],
          feeRate: tier.billingTierType === 'percent' ? tier.rateOrAmount : (tier.rateOrAmount / resultsBlob.sortedAums[i]),
          feeAmount: resultsBlob.sortedAums[i] * feeRate, // tier.billingTierType === 'percent' ? resultsBlob.sortedAums[i] * tier.rateOrAmount : tier.rateOrAmount,
          startingLimit: tier.activationLevel,
          endingLimit: sortedTiers[i + 1] ? sortedTiers[i + 1].activationLevel : totalAum
        };

        return tierData;
      }).filter(function(tier){
        return tier.tierAum;
      });

      return formattedResults;

    }

    function calculateRates() {
      
      var blendedTotal = 0;
      var billableAum = vm.feeTableData.entityAum;
      var highestTier = false;

      vm.feeTableCalculation = '';
      vm.flatCalculation = '';

      for (var i = 0; i < vm.feeTableData.feeTiers.length; i++) {

        var row = vm.feeTableData.feeTiers[i];
        var fee = row.feeRate; // parseFloat(row.feeAmount) / 100;
        var startingLimit = (isNaN(row.startingLimit)) ? Number(row.startingLimit.replace(/[^0-9\.]+/g, "")) : row.startingLimit;
        var endingLimit = (isNaN(row.endingLimit)) ? Number(row.endingLimit.replace(/[^0-9\.]+/g, "")) : row.endingLimit;

        highestTier = i == vm.feeTableData.feeTiers.length -1;

        row.blendedCalculation = '';

        if(isRowValid(row)){
          console.log( row );
        } else if (i > 0 && row.feeAmount && row.startingLimit) {
          if(i == vm.feeTableData.feeTiers.length-1){
            var lastRow = vm.feeTableData.feeTiers[i-1];
            var lastRowEndingLimit = (isNaN(lastRow.endingLimit)) ? Number(lastRow.endingLimit.replace(/[^0-9\.]+/g, "")) : lastRow.endingLimit;
            endingLimit = startingLimit + ((lastRowEndingLimit - lastRow.startingLimit) / 2);
          }
        }

         var _fee = fee * 100;

         var _total;/*  = ((endingLimit-startingLimit) * fee);
             blendedTotal += _total; */

         if(i == 0){
            row.blendedCalculation += '<table class="col-xs-12">';
         }

        if (vm.feeTableData.feeTiers.length > 1 && highestTier) {
          // if (billableAum >= startingLimit && billableAum <= endingLimit) {
          //   // subtract the previous tiers upper value from the total aum to get what's included in the highest tier
          //   var remainingAum = billableAum - vm.feeTableData.feeTiers[i-1].endingLimit;
          //   _total = remainingAum * fee;
          //   console.log("remaining aum: ", remainingAum);

          //   row.blendedCalculation += '<tr>';
          //   row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-left">($' + abbreviateNumber(remainingAum, 0) + ')' + '</td>';
          //   row.blendedCalculation += '<td>x</td>';
          //   row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('number')(_fee, 3) + '%</td>';
          //   row.blendedCalculation += '<td>=</td>';
          //   row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('currency')(_total) + '</td>';
          //   row.blendedCalculation += '</tr>';
          // }

          _total = row.feeAmount;
        } else {
          _total = ((endingLimit-startingLimit) * fee);
        }

        row.blendedCalculation += '<tr>';
        row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-left">($' + abbreviateNumber(startingLimit, 0) + ' - ' + abbreviateNumber(endingLimit, 0) + ')' + '</td>';
        row.blendedCalculation += '<td>x</td>';
        row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('number')(_fee, 3) + '%</td>';
        row.blendedCalculation += '<td>=</td>';
        row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('currency')(_total) + '</td>';
        row.blendedCalculation += '</tr>';

        blendedTotal += _total;

        var quarterlyFee = billableAum * 0.25 * (blendedTotal / billableAum).toFixed(5);
        var quarterlyFeeCalculation =  '<div class="col-xs-12 text-center"><h4>Quarterly Fee Calculation</h4></div>';
            quarterlyFeeCalculation += '<table>';
            quarterlyFeeCalculation += '<tr><td class="no-margin-lr no-padding-lr text-left">' + $filter("currency")(billableAum, "$", 2) + '</td>';
            quarterlyFeeCalculation += '<td>x</td>';
            quarterlyFeeCalculation += '<td class="no-margin-lr no-padding-lr text-right">(63 / 252)</td>';
            quarterlyFeeCalculation += '<td>x</td>';
            quarterlyFeeCalculation += '<td class="no-margin-lr no-padding-lr text-right">'+$filter('number')((Number(blendedTotal) / Number(billableAum) * 100), 3) +'%</td>';
            quarterlyFeeCalculation += '<td>=</td>';
            quarterlyFeeCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('currency')(quarterlyFee, '$', 2) + '</td></tr></table>';

         if(i<vm.feeTableData.feeTiers.length - 1){ row.blendedCalculation += '<br/>'; }
         else if(i==vm.feeTableData.feeTiers.length -1){
            row.blendedCalculation += '</table><br/><br/><table class="col-xs-12">';

            row.blendedCalculation += '<tr><td class="text-right col-xs-6">Total Fees:</td><td class="text-right">' + $filter('currency')(blendedTotal) + '</td></tr>';
            row.blendedCalculation += '<tr><td class="text-right col-xs-6">Total AUM:</td><td class="text-left"><span class="operator">÷</span><span class="pull-right">' + $filter('currency')(billableAum, '$', '2') + '</span></td></tr>';
            row.blendedCalculation += '<tr class="no-padding"><td colspan="2" class="text-right no-padding">================ </td></tr>';
            row.blendedCalculation += '<tr><td class="text-right col-xs-6">Blended Rate:</td><td class="text-right">' + $filter('number')((Number(blendedTotal) / Number(billableAum /* endingLimit */) * 100), 3) + '%</td></tr>';
            // row.blendedCalculation += '<tr><td class="text-right col-xs-6">Monthly Blended Rate:</td><td class="text-right">' + $filter('number')((Number(blendedTotal) / Number(billableAum /* endingLimit */) * 100) / 12, 3) + '%</td></tr>';
            // row.blendedCalculation += '<tr><td class="text-right col-xs-6">Quarterly Blended Rate:</td><td class="text-right">' + $filter('number')((Number(blendedTotal) / Number(billableAum /* endingLimit */) * 100) / 4, 3) + '%</td></tr>';
            // row.blendedCalculation += '<tr><td class="text-right col-xs-6">Quarterly Fee Calculation:</td><td class="text-right">' + $filter('currency')(quarterlyFee, '$', 2) + '</td></tr>';
            row.blendedCalculation += '</table><br/>';
            row.blendedCalculation += quarterlyFeeCalculation;
         }

         vm.feeTableBlendedAmount = Number(endingLimit);
         vm.feeTableCalculation += row.blendedCalculation;
         Brokerage.setFeeTableCalculation(vm.feeTableCalculation);

         if (vm.feeTableData.feeTiers.length < 2) {

          var frequency = 1;
          if(vm.hoveredRow.billingPeriodType === 'monthly') frequency = 12;
          if(vm.hoveredRow.billingPeriodType === 'quarterly') frequency = 4;


          vm.flatCalculation = '<table class="col-xs-12"><tr class="text-right">';
          vm.flatCalculation += '<td class="col-xs-3 text-right">' + abbreviateNumber(endingLimit, 0) + '</td>';
          vm.flatCalculation += '<td class="text-right col-xs-1">x</td>';
          vm.flatCalculation += '<td class="text-right col-xs-2">' + $filter('number')(_fee, 3) + '%</td>';
          if (frequency != 1) {
            vm.flatCalculation += '<td class="text-right col-xs-1">/</td>';
            vm.flatCalculation += '<td class="text-right col-xs-1">' + frequency + '</td>';
          }
          vm.flatCalculation += '<td class="text-right col-xs-1">=</td>';
          vm.flatCalculation += '<td class="text-right col-xs-4">' + $filter('currency')((endingLimit * (fee / frequency))) + '</td>';
          vm.flatCalculation += '</tr></table>';

          Brokerage.setFlatCalculation(vm.flatCalculation);
        }
      }
    }

    function isRowValid(row) {

      if (row.feeAmount != null && row.startingLimit != null && row.endingLimit != null){

        var startingVal = (isNaN(row.startingLimit)) ? Number(row.startingLimit.replace(/[^0-9\.]+/g, "")) : row.startingLimit;
        var endingVal = (isNaN(row.endingLimit)) ? Number(row.endingLimit.replace(/[^0-9\.]+/g, "")) : row.endingLimit;

        if( row.feeAmount != '' && startingVal >= 0 && endingVal >= 0 ){ return true; }
      }
      return false;
    }

    function abbreviateNumber(number, fractionSize) {

      if (number === null) return null;
      if (number === 0) return "0";

      if (!fractionSize || fractionSize < 0)
        fractionSize = 1;

      var abs = Math.abs(number);
      var rounder = Math.pow(10, fractionSize);
      var isNegative = number < 0;
      var key = '';
      var powers = [
        {
          key: "Q",
          value: Math.pow(10, 15)
        },
        {
          key: "T",
          value: Math.pow(10, 12)
        },
        {
          key: "B",
          value: Math.pow(10, 9)
        },
        {
          key: "M",
          value: Math.pow(10, 6)
        },
        {
          key: "K",
          value: 1000
        }
      ];

      for (var i = 0; i < powers.length; i++) {

        var reduced = abs / powers[i].value;

        reduced = Math.round(reduced * rounder) / rounder;

        if (reduced >= 1) {
          abs = reduced;
          key = powers[i].key;
          break;
        }
      }

      return (isNegative ? '-' : '') + abs + key;
    }

    function viewFeeSchedule (row) {
      alert("Fee Schedule!");
    }
  }
})
.controller('BlotterCustomizeModalCtrl', function($scope, $uibModalInstance, $http, FeeBlotter) {

  console.log("Opening search customize modal...");
  $scope.toggleSwitch = false;
  $scope.errorMsg = '';
  $scope.saveTableColumnsPreset = false;
  $scope.checkboxes = []; // determines if the column is selected
  $scope.tableColumns = {
    selected: null,
    columns: {
      "Table Columns": []
    }
  };

  $scope.models = {
    selected: null,
    lists: {
      "A": []
    }
  };

  $scope.itemMovedFunc = function(list, item, index) {
    console.log(list, item, index);

    var index = $scope.tableColumns.columns["Table Columns"].indexOf(item);
    $scope.tableColumns.columns["Table Columns"].splice(index, 1);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.dropCallback = function(index, item, external, type) {

    var idx = -1;
    for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
      var column = $scope.tableColumns.columns["Table Columns"][i];
      if (column.field == item.field) {
        idx = i;
      }
    }
    if (idx > -1) {
      $scope.tableColumns.columns["Table Columns"].splice(idx, 1);
    }

    return item;
  };

  $scope.updateTable = function() {

    var feeInfoIdx = -1;
    var columns = $scope.tableColumns.columns["Table Columns"];
    var titleIdx = -1;

    for (var i = 0; i < columns.length; i++) {
      var column = columns[i];
      if (column.field == 'feeInfo') {
        feeInfoIdx = i;
      }
    }
    if (feeInfoIdx !== 0) {
      columns.move(feeInfoIdx, 0);
    }

    // for (var i = 0; i < columns.length; i++) {
    //   var column = columns[i];
    //   if (column.field == 'title') {
    //     titleIdx = i;
    //   }
    // }
    // if (titleIdx !== 1) {
    //   columns.move(titleIdx, 1);
    // }

    for (var i = 0; i < $scope.tableColumns.columns["Table Columns"].length; i++) {
      var column = $scope.tableColumns.columns["Table Columns"][i];
      var checkitem = $scope.checkboxes[column.field];
      if (typeof checkitem != 'undefined') {
        console.log(column, checkitem);
        
        // determines if the column is included as part of vm.tableCols
        column.show = checkitem;
        column.hide = !checkitem;
      }
    }

    if ($scope.saveTableColumnsPreset == true) {
      console.log('Save search table preset for user.', columns);
      debugger;
      FeeBlotter.saveColumnPreset(columns);
    }
    FeeBlotter.toggleSwitch = $scope.toggleSwitch;
    store.set('toggleWidth', {width: $scope.toggleSwitch});
    FeeBlotter.columnDefs = $scope.tableColumns.columns["Table Columns"];
    $uibModalInstance.close($scope.tableColumns.columns["Table Columns"]);
  };

  $scope.selectModel = function(item) {
    item.selected = !item.selected;
    if (item.selected == false) {
      item.hide = true;
    }
  };

  $scope.selectAll = function() {
    $scope.tableColumns.columns["Table Columns"].forEach(function(column){
      if (column.field != 'feeInfo') {
        $scope.checkboxes[column.field] = true;
        column.selected = true;
        column.hide = false;
        column.show = true;
      }
    });
  };

  $scope.deselectAll = function() {
    $scope.tableColumns.columns["Table Columns"].forEach(function(column){
      if (column.field != 'feeInfo') {
        $scope.checkboxes[column.field] = false;
        column.selected = false;
        column.hide = true;
        column.show = false;
      }
    });
  };


  function init() {
    var cols = FeeBlotter.columnDefs;
    var toggle = store.get('toggleWidth');
    $scope.toggleSwitch = _.property(['width'])(toggle) || false;
    console.log('Table columns', cols);
    
    if (cols.length > 0) {
      $scope.tableColumns.columns["Table Columns"] = [];
      for (var i = 0; i < cols.length; i++) {
        var column = cols[i];
        if (typeof column.field == 'undefined') {
          continue;
        }

        if (typeof column.hide != 'undefined' && column.hide == true) {
          $scope.checkboxes[column.field] = false;
          column.hide = true;
        } else {
          column.selected = true;
          $scope.checkboxes[column.field] = true;
          column.hide = false;
        }
        $scope.tableColumns.columns["Table Columns"].push(column);
      }
    }
  }

  init();
});
