angular.module('BillingModule')
.directive('billingMetrics', function($templateCache) {
	return {
		restrict: 'E',
		scope: {
    },
    template: $templateCache.get('metricsDashboard.tpl.html'),
		controller: billingMetricsDashboardCtrl,
		controllerAs: 'vm',
	};

  function billingMetricsDashboardCtrl ($scope, Dashboard,Tabs, Billing, $q){

    var vm = this;

    vm.canModify = false;

    vm.grouping = {
      options: [
        {value: 'model', text: 'Model'},
        {value: 'repCode', text: 'Rep Code'},
        {value: 'enterprise', text: 'Enterprise'},
        {value: 'strategy', text: 'Sleeve'}
      ],
      selected: {value: 'enterprise',text: 'Enterprise'}
    };

    vm.selectedDataView = 'qtd';
    vm.addTab = Tabs.addTab;
    
    vm.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        // // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: true
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        itemMarginBottom: 5,
        x: -40,
        // y: 100,
        labelFormatter: function () {
            var newName = this.name.substring(0, Math.min(this.name.length, 35));
            console.log("Formatted enterprise name: ", newName);
            return newName.length === this.name.length ? newName : newName + "...";
        },
      },
      series: [{
        name: 'Enterprises',
        colorByPoint: true,
        data: [/* {
            name: 'Enterprise 1',
            y: 60,
        }, {
            name: 'Enterprise 2',
            y: 25
        }, {
            name: 'Enterprise 3',
            y: 15
        } */]
      }]
    };

    $scope.$watch('vm.selectedDataView', function(newVal, oldVal){
      if (newVal && newVal !== oldVal) init();
    });

    $scope.$watch('vm.grouping.selected', function(newVal, oldVal){
      if (newVal && newVal !== oldVal) {
        console.log('Selected AUM Grouping: ', newVal);
        vm.pieData = [];

        // update the pie
        loadAumOverview();
      }
    });

    $scope.$watch(function(){
      return jQuery('.panels-container').height();
    }, function(newVal, oldVal){
      console.log("Old pie height: ", oldVal);
      console.log("new pie height: ", newVal);
    });
    
    init();

    function init(){

      // load aum data for pie chart
      loadAumOverview();

      // load top level metrics
      loadBillingSummary();

    }

    function loadBillingSummary () {
      vm.loadingSummary = true;
      $q.when(Billing.getSummary({
        pageNumber: 1,
        pageSize: 9999,
        sortKey: 'billableAum',
        sortDescending: true,
        billingPeriod: /* moment().year() + moment().month(), */ (vm.selectedDataView === 'qtd' ? 'quarterly' : 'monthly'),
        billableType: "all"
      }))
      .then(function(res){
        console.log("Billing Summary", res);
        var billableAum = 0;
        var billableAumChange = 0;
        var billableAccounts = 0;
        var billableAccountsChange = 0;
        var nonbillableAccounts = 0;
        var nonbillableAccountsChange = 0;
        var billingPeriodEnd = Date;

        res.data.data.forEach(function(enterprise){
          billableAum += enterprise.billableAum;
          billableAumChange += enterprise.billableAumChange;
          billableAccounts += enterprise.billableAccounts;
          billableAccountsChange += enterprise.billableAccountsChange;
          nonbillableAccounts += enterprise.nonBillableAccounts;
          nonbillableAccountsChange += enterprise.nonBillableAccountsChange;
          billingPeriodEnd = enterprise.billingPeriodEnd;
        });

        vm.overview = {
          billableAum: billableAum,
          billableAumChangePercentage: billableAumChange ? (billableAumChange / billableAum) * 100 : 0,
          billableAccounts: billableAccounts,
          billableAccountsChangePercentage: billableAccountsChange ? billableAccountsChange / billableAccounts * 100 : 0,
          nonbillableAccounts: nonbillableAccounts,
          nonbillableAccountsChangePercentage: nonbillableAccountsChange ? nonbillableAccountsChange / nonbillableAccounts * 100 : 0,
          billingPeriodEnd: billingPeriodEnd
        };

        console.log(vm.overview);
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        vm.loadingSummary = false;
      });
    }

    function loadAumOverview () {
      vm.loadingAumOverview = true;
      $q.when(Billing.getAumOverview({
        pageNumber: 1,
        pageSize: 9999,
        billingPeriod: vm.selectedDataView === 'qtd' ? 'quarterly' : 'monthly',
        groupType: vm.grouping.selected.value
      }))
      .then(function(res){

        // res.data.data = [
        //   {
        //     "enterpriseId": "a1c5dd06-e36a-4d4f-b1c8-11c907f5c686",
        //     "enterpriseName": "American Portfolios Advisors Inc",
        //     "totalAum": 25115.65,
        //     "aumGroupItems": [
        //       {
        //         "name": "American Funds Retirement Income - Conservative",
        //         "aum": 25115.65
        //       }
        //     ]
        //   },
        //   {
        //     "enterpriseId": "b1c5dd06-e36a-4d4f-b1c8-11c907f5c686",
        //     "enterpriseName": "Another Enterprise",
        //     "totalAum": 45115.65,
        //     "aumGroupItems": [
        //       {
        //         "name": "American Funds Retirement Income - Conservative",
        //         "aum": 45115.65
        //       }
        //     ]
        //   }
        // ];

        var itemsWithAum = res.data.data.filter(function(item) {
          return item.totalAum;
        });

        switch (vm.grouping.selected.value) {
          case 'enterprise':
            itemsWithAum = itemsWithAum.map(function(item){
              return {
                name: item.enterpriseName,
                aum: item.totalAum
              };
            });
            break;
          case 'model':
          case 'strategy':
          case 'repCode':

            var aumByEntityList = [];

            // group by model name
            var flattenedAumGroupItems = itemsWithAum.reduce(function(totalItems, item) {
              var filteredGroupItems = item.aumGroupItems.filter(function(item){
                return item.aum > 0;
              });
              return totalItems.concat(filteredGroupItems);
            }, []);

            // groupByModel 
            var aumGroupedByEntity = _.groupBy(flattenedAumGroupItems, function(item, index){
              return item.name || 'Unnamed ' + (index > 0 ? index : '');
            });

            console.log('Grouped by model: ', aumGroupedByEntity);

            // sum the aum for each model using the group items
            var totalAumGroupedByEntity = _.reduce(aumGroupedByEntity, function(entities, items){
              
              items.forEach(function(item) {
                if (entities[item.name]){
                  entities[item.name] += item.aum;
                } else {
                  entities[item.name] = item.aum;
                }
              });

              return entities;
            }, {});

            console.log('AUM Grouped by Model', totalAumGroupedByEntity);

            // transform into a list
            _.each(totalAumGroupedByEntity, function(v, k){
              aumByEntityList.push({name: k, aum: v});
            });

            console.log('Aum by model list', aumByEntityList);

            itemsWithAum = aumByEntityList;
        }

        updatePie(
          _.sortBy(itemsWithAum, function(item){ return -item.aum; })
        );
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        vm.loadingAumOverview = false;
      });
    }

    function updatePie (items) {
      var totalAum = 0
      var formattedData = items.map(function(item) {
        totalAum += item.aum
        return {
          name: item.name || 'Unnamed',
          y: item.aum
        };
      });

      var totalOfOthers = 0
      
      formattedData.forEach(function(item, i) {
        if (item.y <= 0.01 * totalAum) {
          totalOfOthers += item.y
        }
      })

      var othersObject = {
        name: 'Other',
        y: totalOfOthers,
      };

      var filteredData = formattedData.filter(function(item) {
        if (item.y > 0.01 * totalAum) {
          return item
        }
      })
      
      filteredData.push(othersObject)
      vm.pieData = filteredData
      // vm.chartOptions.series[0].name = vm.grouping.selected.text;
      vm.chartOptions.series[0].data = filteredData;
    }
    
  }
});