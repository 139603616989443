angular.module('BillingModule')
.directive('billingSetup', function($templateCache) {
	return {
		restrict: 'E',
		scope: {
    },
    template: $templateCache.get('billing-setup.html'),
		controller: billingSetupCtrl,
		controllerAs: 'vm',
	};

  function billingSetupCtrl ($scope, Dashboard){}
});