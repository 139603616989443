angular.module('BillingModule')
.directive('feeSchedules', function($templateCache) {
	return {
		restrict: 'E',
		scope: {
    },
    template: $templateCache.get('fee-schedules.tpl.html'),
		controller: feeSchedulesCtrl,
		controllerAs: 'vm',
	};

  function feeSchedulesCtrl ($scope, NgTableParams, $modal, $templateCache){
    var vm = this;

    vm.canModify = false;

    vm.schedules = [
      {
        name: 'Schedule 1',
        feeRate: 0.01,
        accountsCount: 10,
        billableAUM: 1000000,
        householdsCount: 5,
        nonBillableAUM: 100200,
        frequency: 'Monthly',
        feeModel: 'Flat',
        feeType: 'Managment Fee', 
        inAdvance: 'Yes',
        rebateOnClose: 'Yes'
      },
      {
        name: 'Schedule 2',
        feeRate: 0.10,
        accountsCount: 7,
        billableAUM: 185000,
        householdsCount: 2,
        nonBillableAUM: 170000,
        frequency: 'Monthly',
        feeModel: 'Flat',
        feeType: 'Managment Fee', 
        inAdvance: 'Yes',
        rebateOnClose: 'Yes'
      },
      {
        name: 'Schedule 3',
        feeRate: 0.015,
        accountsCount: 10,
        billableAUM: 932334,
        householdsCount: 5,
        nonBillableAUM: 85000,
        frequency: 'Monthly',
        feeModel: 'Flat',
        feeType: 'Managment Fee', 
        inAdvance: 'Yes',
        rebateOnClose: 'Yes'
      },
      {
        name: 'Schedule 4',
        feeRate: 0.02,
        accountsCount: 10,
        billableAUM: 1000000,
        householdsCount: 5,
        nonBillableAUM: 0,
        frequency: 'Monthly',
        feeModel: 'Flat',
        feeType: 'Managment Fee', 
        inAdvance: 'Yes',
        rebateOnClose: 'Yes'
      }
    ];
  
    vm.households = [
      {
        name: 'IRA Household 1',
        feeSchedule: 'Schedule 1',
        billableAUM: 1000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      },
      {
        name: 'ETH Moon Trust',
        feeSchedule: 'Schedule 3',
        billableAUM: 100000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      },
      {
        name: 'Nakamoto Retirement',
        feeSchedule: '--',
        billableAUM: 1000000,
        nonBillableAUM: 314000,
        accountsCount: 25
      }
    ];
  
    vm.accounts = [
      {
        name: 'Account 1',
        brokerageNumber: '123456',
        feeSchedule: null,
        feeFrequency: null,
        household: 'Household 1',
        value: 1000000
      },
      {
        name: 'Account 2',
        brokerageNumber: '123456',
        feeSchedule: null,
        household: 'Household 2',
        feeFrequency: null,
        value: 1000000
      },
      {
        name: 'Account 2',
        brokerageNumber: '123456',
        feeSchedule: null,
        household: 'Household 3',
        feeFrequency: null,
        value: 1000000
      }
    ];
  
    vm.scheduleOptions = [
      { 
        id: null,
        name: 'Select Schedule'
      },
      {
        id: '1',
        name: 'Schedule 1'
      },
      {
        id: '2',
        name: 'Schedule 2'
      },
      {
        id: '3',
        name: 'Schedule 3'
      },
    ];
  
    vm.frequencyOptions = [
      {
        id: null,
        name: 'Select Frequency'
      },
      {
        id: 'frequency1',
        name: 'Frequency 1'
      },
      {
        id: 'frequency1',
        name: 'Frequency 2'
      },
      {
        id: 'frequency1',
        name: 'Frequency 3'
      },
      {
        id: 'frequency1',
        name: 'Frequency 4'
      }
    ];
  
    vm.showFeeScheduleForm = showFeeScheduleForm;
    
    init();

    function init() {
  
      vm.schedulesTable = new NgTableParams({}, {
        dataset: vm.schedules
      });
    }

    function showFeeScheduleForm () {
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('createFeeScheduleForm.tpl.html'),
        resolve: {},
        controller: 'CreateFeeScheduleFormCtrl',
        controllerAs: 'vm'
      });
      
      modalInstance.result.then(function(newSchedule) {});
    }
  
  }
});