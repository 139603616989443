angular
  .module('AccountSummary')
  .controller("AccountsSummaryPagedCtrl", function summaryCtrl(FeatureFlags, $timeout, Allocations, $modal, $templateCache, EnterpriseFactory, Brokerage, ngTableEventsChannel, $q, $http, $rootScope, $scope, $filter, Tabs, Dashboard, TargetWeightFactory, toastr, NgTableParams, SmartXFactory) {

    var userEnterprise = EnterpriseFactory.getUserEnterprise();
    var parentTabData = $scope.$parent.tab.data || {}; 
    var initialFilter = parentTabData.initialFilter || {};
    var initialBrokers = Brokerage.getActiveAccountBrokerages();
    var initialEnterprises = EnterpriseFactory.getActiveAccountEnterprises();
    var enterpriseApi = SmartXFactory.getEnterprisesAPI();

    // $scope.$watch logic can be bypassed by checking this value
    var bypassWatchers = false;

    $scope.isEnabled = FeatureFlags.isEnabled;
    $scope.canModify = false;

    // $scope.$watch('enterpriseDropdown.isOpen', function(newVal, oldVal) {
    //   if (angular.isDefined(newVal) && newVal !== oldVal){
    //     if (newVal) {
    //       $timeout(function(){
    //         $scope.enterpriseDropdown.autoClose = 'outsideClick';
    //       }, 1000);
    //     } else {
    //       $scope.enterpriseDropdown.autoClose = 'disabled';
    //     }
    //   }
    // });

    $scope.enterpriseDropdown = {
      isOpen: false,
      search: '',
      selectedItems: [],
      toggle: function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.isOpen = !this.isOpen;
      },
      clearSelected: function () {
        $scope.enterpriseSearchResults = $scope.enterpriseSearchResults.map(function(enterprise){
          enterprise.selected = false;
          return enterprise;
        });
      },
      onFocusInput: function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (_.property(['length'])($scope.enterpriseSearchResults)){
          this.isOpen = true;
        }
      },
      selectAllChildren: function (enterprise) {
        enterprise.children = enterprise.children.map(function(child){
          child.selected = enterprise.selectAll;
          return child;
        });
      },
      deselectAllChildren: function () {
        $scope.enterpriseSearchResults = $scope.enterpriseSearchResults.map(function(enterprise){
          enterprise.selectAll = false;
          enterprise.children = enterprise.children.map(function(child){
            child.selected = false; 
            return child;
          });
          return enterprise;
        });
      },
      getTotalAccountCount: function (enterpriseResults) {
        var totalAccountCount = enterpriseResults.reduce(function(total, enterprise){
          total += enterprise.accountCount;
          if (enterprise.children){
            enterprise.children.forEach(function(child){
              total += child.accountCount;
            });
          }

          return total;
        }, 0);

        return totalAccountCount;
      },
      onSubmit: function () {
        // get the selected enterprises by checking each child
        var selectedEnterprises = $scope.enterpriseSearchResults.reduce(function(selected, enterprise){
          var children = enterprise.children;
          var selectedChildren = children.filter(function(child){
            return child.selected;
          })
          .map(function(child){
            return child.id;
          });

          return selected.concat(selectedChildren);
        }, []);


        // build the comma separated list to pass to the accounts search
        var filters = $scope.tableParams.filter();
        filters.enterpriseId = _.uniq(selectedEnterprises).join(',');
        $scope.tableParams.filter(filters);
      }
    };

    $scope.isOpen = false;


    $scope.targetColumnVerbiage = "This column allows you to quickly identify which target portfolios are assigned to each client account. The field will show the name of the primary target portfolio utilized if only one target portfolio is assigned to the account. If you assign multiple target portfolios to a client account, you will see “Multi-Strategy”. If you assign a single model to the account’s default target portfolio, you will see the name of that model. If none of these conditions exist for a particular account, you will see “No Target”.";

    $scope.mappedAccounts = [];
    $scope.accountsSummary = undefined;
    $scope.filteredData = [];
    $scope.hideViewOnlyAccounts = initialFilter.permission ? initialFilter.permission === 'fullAccess' : false;
    $scope.filterAccountList = filterAccountList;

    $scope.enterpriseSelected = enterpriseSelected;
    $scope.exportAccountData = exportAccountData;
    $scope.downloading = false;
    $scope.getEnterprises = getEnterprises;
    $scope.loadingEnterprises = false;
    $scope.showSmaAccounts = false;
    $scope.selectedEnterprises = [];
    $scope.clearSelectedEnterprises = clearSelectedEnterprises;

    // config for ng-table account name filter
    // $scope.accountFilter = {
    //   'name': {
    //     id: "text",
    //     placeholder: "Enter Name",
    //   }
    // };

    // $scope.brokerageFilter = {
    //   'brokerageName': {
    //     id: "text",
    //     placeholder: "Filter by Brokerage",
    //   }
    // };
    
    // brokerage column dropdown filter options
    $scope.accountBrokerageFirms = [{
			id: "",
			title: "Select Firm"
    }];
    
    $scope.brokerageAccountNumberFilter = {
      'acctNumber': {
        id: "debouncedText",
        placeholder: "Enter #",
      }
    };

    $scope.accountNameFilter = {
      'name': {
        id: "debouncedText",
        placeholder: "Enter name"
      }
    };

    $scope.repCodeFilter = {
      'repCode': {
        id: "debouncedText",
        placeholder: "Code"
      }
    };

    $scope.subEnterpriseFilter = {  
      'enterpriseId': {
        id: 'select',
        placeholder: 'Select Enterprise'
      }
    };

    $scope.targetsFilter = {  
      'targetId': {
        id: 'select',
        placeholder: 'Select Target'
      }
    };
    // enterprise column dropdown filter options
    $scope.accountEnterprises = [{
      id: '',
      title: 'All Enterprises'
    }];

    // async lookup enterprise filter
    $scope.enterpriseFilter = {
      'enterpriseId': {
        id: "account-enterprise-lookup",
        placeholder: "Enter an enterprise",
        searchText: ''
      }
    };

    $scope.targetOptions = [{
      id: '',
      title: 'All'
    }, {
      id: 'noTarget',
      title: 'No Target'
    }, {
      id: 'multiStrat',
      title: 'Multi-Strategy'
    }];    

    // External enterprise dropdown
    $scope.enterpriseOptions = [{
      id: '',
      name: 'All Enterprises'
    }].concat(initialEnterprises || []);

    $scope.selectedEnterprise = $scope.enterpriseOptions[0];

    $scope.$watch('enterpriseFilter.enterpriseId.searchText', function(newVal, oldVal){

      if (bypassWatchers) return;

      if (
        angular.isDefined(oldVal) 
        && (oldVal.length || oldVal.name) 
        && $scope.selectedEnterprise.name
        && newVal === ''
      ) {
        // the input is clear
        // remove the enterprise filter to trigger request
        var filters = $scope.tableParams.filter();
        $scope.tableParams.filter(_.extend(filters, {
          enterpriseId: ''
        }));
        $scope.selectedEnterprise = {
          id: '',
          name: 'All Enterprises'
        };
      }
    });

    $scope.$watch('activeAccountsParams', function(newVal){
      if (newVal) createListener(newVal);
    });

    function createListener(tableInstance) {
      ngTableEventsChannel.onAfterReloadData(function(tableParams, filteredData){

        var dataset = tableParams.settings().dataset;
        var dataWithStatistics = dataset.filter(function(item){
          return !_.isEmpty(item.statistics);
        });

        if (dataset && dataWithStatistics.length) {
          var enterpriseFilter;
          var dataset = tableParams.settings().dataset;
          var filters = tableParams.filter();
          var children = getEnterpriseChildren($scope.selectedEnterprise.id);

          if ($scope.selectedEnterprise.id != '' && !children.length) {
            enterpriseFilter = {
              enterprise: {
                id: $scope.selectedEnterprise.id
              }
            };
            filters = _.extend(filters, enterpriseFilter);
          }

          if (children.length) {
            dataset = dataset.filter(function(account){
              return account.enterprise.id && _.contains(_.pluck(children, 'id').concat($scope.selectedEnterprise.id), account.enterprise.id);
            });
          }

          filterData(dataset, filters);
        }
      }, $scope, tableInstance);
    }

    $rootScope.$on('refresh-accounts-summary', function(event, data) {

      if (_.property(['initialFilter'])(data)) {
        initialFilter = data.initialFilter;
        if ($scope.tableParams) {

          // prevent the watcher logic from firing off extra requests when resetting external filters
          bypassWatchers = true;

          // reset external filters to initial states
          $scope.showSmaAccounts = false;
          $scope.selectedEnterprise = $scope.selectedEnterprise = {
            id: '',
            name: 'All Enterprises'
          };
          $scope.enterpriseFilter.enterpriseId.searchText = '';
          $scope.hideViewOnlyAccounts = initialFilter.permission == 'fullAccess' ? true : false;

          $timeout(function(){
            bypassWatchers = false;
          },100);

          $scope.tableParams.filter(angular.copy(initialFilter));
          return;
        }
      }

      $scope.refresh();
    });

    $scope.$watch('hideViewOnlyAccounts', function filterByPermission (newVal, oldVal){

      if (bypassWatchers) return;

      if ($scope.tableParams){
        var filters = $scope.tableParams.filter();
        _.extend(filters, {
          permission: newVal ? 'fullAccess' : '',
        });

        $scope.tableParams.filter(filters);
      }
    });

    $scope.$watch('showSmaAccounts', function filterByPermission (newVal, oldVal){

      if (bypassWatchers) return;

      if ($scope.tableParams){
        var filters = $scope.tableParams.filter();
        _.extend(filters, {
          internalType: newVal ? 'sma' : '',
        });

        $scope.tableParams.filter(filters);
      }
    });

    $scope.$watch('selectedEnterprise', function filterByEnterprise (newVal, oldVal){

      if (bypassWatchers) return;

      if ($scope.tableParams) {
        var filters = $scope.tableParams.filter();
        _.extend(filters, {
          topLevelEnterpriseId: newVal.id,
          enterpriseId: ''
        });

        // reset sub-enterprise filter
        // will be repopulated once the new results are loaded
        updateAccountEnterprises([]);

        $scope.tableParams.filter(filters);
        // filterAccountsByEnterprise
      }
    });

    $scope.$watch('enterpriseDropdown.search', function(newVal, oldVal){
      if (angular.isDefined(newVal) && oldVal !== newVal) {

        // clearing the enterprise input
        if (!$scope.selectedEnterprises.length && newVal === '') {
          $scope.selectedEnterprise = {};
        }
      }
    });

    $scope.$on('enterprise-added', function(e, data){
      if ($scope.selectedEnterprises.length >= 10) {
        return alert('Selection Limit Reached');
      }
      if (data.id) {
        data.selected = true;
        $scope.selectedEnterprises.push(data);
        $scope.tableParams.reload();
      }
    });
    
    $scope.$on('enterprise-removed', function(e, data){
      if (data.id){
        data.selected = false;
        $scope.selectedEnterprises = $scope.selectedEnterprises.filter(function(enterprise){
          return enterprise.id !== data.id;
        });

        $scope.enterpriseSearchResults = $scope.enterpriseSearchResults.map(function(enterprise){
          if (enterprise.id == data.id) {
            enterprise.selected = false;
          }

          return enterprise;
        });

        console.log("Selected Enterprises: ", $scope.selectedEnterprises);

        if (!$scope.selectedEnterprises.length) {
          $scope.enterpriseFilter.enterpriseId.searchText = '';
        }

        $scope.tableParams.reload();
      }
    });

    $scope.refresh = function (){
      init(true);
    };

    $scope.onFocusDropdown = function (){
      if (_.property(['length'])($scope.enterpriseSearchResults)){
        $scope.enterpriseDropdown.isOpen = true;
      }
    };

    init();

    function enterpriseSelected (item, e) {
      var filters = $scope.tableParams.filter();

      if ($scope.selectedEnterprises.length){
        e.stopPropagation(); 
        $scope.$emit(item.selected ? 'enterprise-removed' : 'enterprise-added', item);
      } else {
        filters.enterpriseId = item.id;
        $scope.tableParams.filter(filters);
        $scope.selectedEnterprise = item;
        $scope.enterpriseDropdown.search = item.name;
        // $scope.enterpriseDropdown.deselectAllChildren();

        // deselect any selected search results
        $scope.enterpriseSearchResults = $scope.enterpriseSearchResults.map(function(result){
          result.selected = false;
          return result;
        });
        
        // clear the selected enterprises chips
        $scope.selectedEnterprises = [];
      }
    }

    function getEnterprises(searchText) {

      if (!searchText) {
        return false;
      }

      $scope.noResults =  false;
      $scope.loadingEnterprises = true;

      return $q.when(enterpriseApi.search({name: searchText}))
        .then(function(res){
          if (res.data && !res.data.length) {
            $scope.noResults = true;
          }

          res.data = res.data.map(function(enterprise){
            var isSelected = $scope.selectedEnterprises.find(function(e){
              return e.id === enterprise.id;
            });

            if (isSelected) {
              enterprise.selected = true;
            }

            return enterprise;
          });

          $scope.enterpriseSearchResults = res.data;

          // $scope.totalEnterpriseAccountCount = $scope.enterpriseDropdown.getTotalAccountCount($scope.enterpriseSearchResults);

          return res.data;
        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $scope.loadingEnterprises = false;
          $scope.enterpriseDropdown.isOpen = true;
        });
    }

    function updateBrokerList (brokers) {
      if (!brokers) brokers = [];
      $scope.accountBrokerageFirms.splice(1);
      brokers.forEach(function(broker){
        $scope.accountBrokerageFirms.push({
          id: broker.id,
          title: broker.name
        });
      });
    }

    function updateAccountEnterprises (enterprises) {

      if (!enterprises) enterprises = [];
      $scope.accountEnterprises.splice(1);
      enterprises.forEach(function(enterprise){
        $scope.accountEnterprises.push({
          id: enterprise.id,
          title: enterprise.name
        });
      });
    }

    function formatAccounts (accounts) {
      var formattedAccounts = accounts.map(function(account){
        var targetName = 'No Target';
        if (account.hasRepCode) account.repCodeName = account.repCode[0].name;
        if (account.hasTargets) {
          if (account.targets.length > 2) {
            targetName = 'Multi-Strategy';
          } else {
            var nonCashDetail = account.targets.find(function(detail){
              return detail.type !== 'cash';
            });

            if (nonCashDetail) {
              targetName = nonCashDetail.name;
            }
          }
        }

        account.targetName = targetName;

        return account;
      });

      return formattedAccounts;
    }

    function updateTargetOptions (targets) {
      if (!targets) targets = [];
      $scope.targetOptions.splice(3);
      targets.forEach(function(target){
        $scope.targetOptions.push({
          id: target.id,
          title: target.name
        });
      });
    }

    function clearSelectedEnterprises () {
      $scope.enterpriseSearchResults = $scope.enterpriseSearchResults.map(function(enterprise){
        enterprise.selected = false;
        return enterprise;
      });

      $scope.selectedEnterprises = [];

      $scope.tableParams.reload();
    }

    function isOnlyFilter (filterKey, ignoreList) {
      var filters = $scope.tableParams.filter();
      ignoreList = ignoreList || [];
      var activeFilters = _.filter(_.keys(filters), function(key){
        return !_.isEmpty(filters[key]) && !_.contains(ignoreList, key);
      });

      return activeFilters.length === 1 && activeFilters[0] === filterKey;
    }

    // track the changes to the filters object
    $scope.$watch(function(){
      return $scope.tableParams && $scope.tableParams.filter();
    }, function(filters, oldVal){
      console.log('New filters: ', filters);
      console.log('Old Filters: ', oldVal);
      $scope.activeFilters = _.filter(_.keys(filters), function(key){
        return !_.isEmpty(filters[key]) && key != 'status';
      });
      $scope.activeFiltersCount = $scope.activeFilters.length;
    }, true);

    function init(refresh) {

      $scope.enterpriseAccess = EnterpriseFactory.getEnterpriseAccess();
      $scope.foreignEnterpriseAccess = EnterpriseFactory.getForeignEnterpriseAccess();
      
      $scope.multiEnterprise = [].concat($scope.foreignEnterpriseAccess || []).concat($scope.enterpriseAccess || []).length > 1;

      // access accounts get set in MenuCtrl
      $scope.totalItems = Dashboard.getAccessAccounts().length;
      $scope.failedToLoadSummary = false;
      $scope.pageSize = 25; // 100;
      $scope.numPerPage = 25;
      $scope.currentPage = 1;
      $scope.sortType = 'information.cash.total'; // set the default sort type
      $scope.sortReverse = true; // set the default sort order

      $scope.params = {
        page: 1,
        pageSize: 25,
        sort: 'value',
        sortDirection: 'desc'
      };

      $scope.loading = true;
      
      $q.when(TargetWeightFactory.getUsedTargets())
        .then(function(res){

          console.log('Used targets list: ', res);

          // var accountTargets = res.data.data;

          // create lookup
          // targetsLookup = _.chain(accountTargets)
          //                     .groupBy(function(item){return item.account.id;})
          //                     .mapObject(function(item){return item[0].targets;})
          //                     .value();
          var targetOptions = res.data.data;

          if ($scope.tableParams){
            return $scope.tableParams.reload();
          }

          $scope.tableParams = new NgTableParams({
            page: 1,
            count: 25,
            sorting: {
              value: 'desc'
            },
            filter: {
              brokerageId: '',
              targetId: initialFilter.targetId ? initialFilter.targetId : '',
              enterpriseId: '',
              permission: initialFilter.permission ? initialFilter.permission : ''
            }
          }, {
            getData: function (params) {
                var page = params.page();
                var size = params.count();
                var sorting = params.sorting();
                var filter = params.filter();
                var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
                var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
                  
                var query = {
                  page: page,
                  pageSize: size,
                  status: 'active',
                  sort: sort,
                  sortDirection: direction
                };
  
                _.extend(query, filter);

                if (filter.topLevelEnterpriseId && _.isEmpty(filter.enterpriseId)) {
                  query.enterpriseId = filter.topLevelEnterpriseId;
                }

                if ($scope.selectedEnterprises.length) {
                  query.enterpriseId = _.uniq(_.pluck($scope.selectedEnterprises, 'id')).join(',');
                }

                if (filter.targetId === 'noTarget' && query.internalType !== 'sma'){
                  query.hasTargets = false;
                  query.targetId = '';
                  query.internalType = 'uma,onBoarding,readOnly';
                }

                if (filter.targetId === 'multiStrat'){
                  query.hasMultiStrategy = true;
                  query.targetId = '';
                }

                if (filter.acctNumber){
                  query.acctNumber = filter.acctNumber.replace(/-/g, '');
                }

                console.log("Search Params: ", query);

                $scope.loading = true;
                $scope.accountsQuery = query;

                return SmartXFactory.getAccountsWithMetadata(query)
                        .then(function(res) {
                          params.total(res.metaData.totalRecordCount);
                          $scope.totalValue = res.metaData.totalValue;
                          var brokersList = res.metaData.brokerages;
                          var enterprisesList = res.metaData.enterprises;

                          if (enterprisesList && enterprisesList.length > 1) {
                            $scope.multiEnterprise = true;
                          }

                          if (angular.isArray(initialBrokers) && !initialBrokers.length) {
                            initialBrokers = brokersList;
                          }

                          if ($scope.targetOptions.length === 3){
                            updateTargetOptions(targetOptions);
                          }

                          if ($scope.accountBrokerageFirms.length === 1) {
                            updateBrokerList(brokersList);
                            // initialBrokers = brokersList;
                          } else {
                            if (filter.brokerageId == '' || !$scope.activeFiltersCount) {
                              updateBrokerList(brokersList);
                            } else if (isOnlyFilter('brokerageId')){
                              updateBrokerList(initialBrokers);
                            }
                          }

                          if ($scope.accountEnterprises.length === 1) {
                            updateAccountEnterprises(enterprisesList);
                          } else {
                            // if no filters or enterprise filter is empty
                            if (_.isEmpty(filter.enterpriseId) || !$scope.activeFiltersCount){
                              updateAccountEnterprises(enterprisesList);
                            } else if (isOnlyFilter('enterpriseId')){
                              updateAccountEnterprises(initialEnterprises);
                            }
                          }

                          if ($scope.showSmaAccounts){
                            $scope.showSmaModelColumn = true;
                            loadSmaSleeves(res.accounts);
                          } else {
                            $scope.showSmaModelColumn = false;
                          }
        
                          return formatAccounts(res.accounts);
                        })
                        .catch(function(err) {
                          // toastr.error(err.message);
                          Dashboard.toastError(err.message, err);
                          $scope.loadingSmaSleeves = false;
                          $q.reject();
                        })
                        .finally(function(){
                          $scope.loading = false;
                        });
            },
          });
        })
        .catch(function(err){
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $scope.loading = false;
        });
      
      // if (angular.isArray($scope.enterpriseOptions) && $scope.enterpriseOptions.length === 1) getEnterprisesSummary();
    }

    function loadSmaSleeves (accounts) {
      var ids = _.pluck(accounts, 'id').join(',');

      $scope.loadingSmaSleeves = true;

      $q.when(Allocations.getAllAllocations({
        status: "open",
        accountId: ids
      }))
      .then(function(res){
        console.log("SMA Sleeves: ", res.data);
        var smaSleeves = res.data.filter(function(sleeve){
          return sleeve.type && sleeve.type.toLowerCase() === 'sma';
        });

        var tableAccounts = $scope.tableParams.data;
        var tableAccountsWithModelName = tableAccounts.map(function(account){
          var smaSleeve = smaSleeves.find(function(sleeve){
            return sleeve.account.id === account.id;
          });

          if (smaSleeve && smaSleeve.model) {
            account.smaModelName = smaSleeve.model.name;
          }

          return account;
        });

        $scope.tableParams.data = tableAccountsWithModelName;
      })
      .catch(console.error)
      .finally(function(){
        $scope.loadingSmaSleeves = false;
      });
    }

    function getEnterprisesSummary() {
      $q.when(enterpriseApi.summary())
      .then(function(res){
        var enterprises = res.data;
        $scope.enterpriseOptions.concat(enterprises);
      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      });
    }

    function filterAccountsByEnterprise (newVal, oldVal) {
      if (angular.isDefined($scope.activeAccountsParams)){
        var enterpriseFilter;
        var enterpriseIds;
        var tableData;
        var dataset = $scope.mappedAccounts;
        var filters = $scope.activeAccountsParams.filter();
        var children = getEnterpriseChildren($scope.selectedEnterprise.id);

        if (!children.length) {
          enterpriseFilter = {
            enterprise: {
              id: $scope.selectedEnterprise.id
            }
          };
          filters = _.extend(filters, enterpriseFilter);
          dataset = dataset.filter(function(account){
            return account.enterprise.id && ($scope.selectedEnterprise.id == account.enterprise.id);
          });
        } else {
          dataset = dataset.filter(function(account){
            return account.enterprise.id && _.contains(_.pluck(children, 'id').concat($scope.selectedEnterprise.id), account.enterprise.id);
          });
        }

        filters.enterpriseName = '';

        filterData(dataset, filters);

        if ($scope.selectedEnterprise.id != '') {
          $scope.accountEnterprises.splice(1, $scope.accountEnterprises.length);
          dataset.forEach(function(account){
            enterpriseIds = _.map($scope.accountEnterprises, 'id');

            if (!_.contains(enterpriseIds, account.enterpriseName)){
              $scope.accountEnterprises.push({
                id: account.enterpriseName,
                title: account.enterpriseName
              });
            }
          });
        } else {
          $scope.accountEnterprises.splice(1, $scope.accountEnterprises.length);
          $scope.mappedAccounts.forEach(function(account){
            enterpriseIds = _.map($scope.accountEnterprises, 'id');

            if (!_.contains(enterpriseIds, account.enterpriseName)){
              $scope.accountEnterprises.push({
                id: account.enterpriseName,
                title: account.enterpriseName
              });
            }
          });
        }

        tableData = children.length ? $scope.mappedAccounts.filter(function(account){ return _.contains(_.pluck(dataset, 'id'), account.id );}) : $scope.mappedAccounts;

        $scope.activeAccountsParams = new NgTableParams({
          sorting : { 'value': "desc" },
          filter: _.extend({'brokerageName': '', enterpriseName: '', targetName: '', permission: $scope.hideViewOnlyAccounts ? 'fullAccess' : ''}, initialFilter),
          count: tableData.length <= 25 ? tableData.length : 25
        }, {
          dataset: tableData
        });
        $scope.activeAccountsParams.settings({
          counts: (tableData.length > 25) ? [25, 50, 100] : []
        });
        $scope.activeAccountsParams.reload();
      } 
    }

    function getTopLevelParent (enterprise, startingEnterpriseId) {
      var topLevelParent;
      var parent = _.find($scope.enterpriseAccess.concat(EnterpriseFactory.getUserEnterprise()), { id: enterprise.enterpriseParentId});
      if (parent && parent.id != startingEnterpriseId) return getTopLevelParent(parent, startingEnterpriseId);
      else {
        topLevelParent = parent || enterprise;
        return topLevelParent;
      }
    }

    function getEnterpriseChildren (parentEnterpriseId, children) {
      var enterpriseAccess = $scope.enterpriseAccess.concat([userEnterprise]);
      children = children || [];

      enterpriseAccess.forEach(function(enterprise){
        var id = enterprise.enterpriseParentId;
        if (children.indexOf(enterprise.id) > -1 ) return; 
        if (parentEnterpriseId === id) children.push(enterprise);
        else {
          var topLevelParent = getTopLevelParent(enterprise, parentEnterpriseId);
          if (topLevelParent.id === parentEnterpriseId) children.push(enterprise);
        }
      });

      return children;
    }

    function filterAccountList(){
      if ($scope.activeAccountsParams) {
        var dataset = $scope.activeAccountsParams.settings().dataset;
        var filters = $scope.activeAccountsParams.filter();
        if (dataset.length && dataset[0].permission) {
          var permissionFilter = {
            permission: $scope.hideViewOnlyAccounts ? 'fullAccess' : '' 
          };
        } else {
          var permissionFilter = {};
        }

        var smaFilter = {
          internalType: $scope.showSmaAccounts ? 'sma' : ''
        };

        filters = _.extend(filters, permissionFilter, smaFilter);

        filterData(dataset, filters);
      }
    }

    function filterData(data, filters){
      if (!_.isEmpty(filters) && data.length){
        var filteredData = $filter('filter')(data, filters);
        $scope.filteredData = filteredData;
        return filteredData;
      }

      return [];
    }

    function getAccountsFromFactory (refresh) {

      if(typeof SmartXFactory.getToken() !== 'undefined') {

        $scope.loading = true;
        SmartXFactory.getAccountTargets()
        .then(function(res){

          var accountTargets = res.data.data;

          // create lookup
          var targetsLookup = _.chain(accountTargets)
                              .groupBy(function(item){return item.account.id;})
                              .mapObject(function(item){return item[0].targets;})
                              .value();

          return SmartXFactory.getAccounts('active', refresh)
          .then(function(resp) {
           
            resp.forEach(function(account){
              var ids = _.map($scope.enterpriseOptions, 'id');
              if (!account.permission) account.permission = 'fullAccess';
              if (!_.contains(ids, account.enterprise ? account.enterprise.id : 'null')){
                $scope.enterpriseOptions.push({
                  id: account.enterprise.id,
                  name: account.enterprise.name || account.enterprise.id
                });
              }
              try {
                account.targets = targetsLookup[account.id] || [];
                account.targetName = account.targets.length ? (account.targets.length > 1 ? 'Multi-Strategy' : account.targets[0].name) : 'No Target';
              }
              catch (e) {
                console.error("Account Targets not found for ", account.brokerageAccountNumber);
                debugger;
              }
            });

            $scope.multiEnterprise = $scope.enterpriseOptions.length > 2;

            Dashboard.setUserAccounts(resp);

            return resp;
          })
          .catch(function(err) {
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
            return Promise.reject(err);
          });
        })
        .catch(function(err){
          console.error(err.message);
          // if accounts targets fails, get account data
          SmartXFactory.getAccounts('active', refresh)
          .then(function(resp) {
            
            resp.forEach(function(account){
              var ids = _.map($scope.enterpriseOptions, 'id');
              if (!account.permission) account.permission = 'fullAccess';
              if (!_.contains(ids, account.enterprise ? account.enterprise.id : 'null')){
                $scope.enterpriseOptions.push({
                  id: account.enterprise.id,
                  name: account.enterprise.name || account.enterprise.id
                });
              }
            });

            $scope.multiEnterprise = $scope.enterpriseOptions.length > 2;

            Dashboard.setUserAccounts(resp);

            // getAccountStatistics(resp);
          }, function(err) {
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          });
        });
      }else{
        $scope.loading = false;
        $scope.accountsSummary = $scope.mappedAccounts = [];
      }
    }

    function exportAccountData(){
      // var timestamp = moment().format("MM_DD_YYYY");
      // var rows = $scope.filteredData || $scope.activeAccountsParams.settings().dataset;
      // var formattedRows = rows.map(function(row){
      //   return {
      //     'Name': row.name,
      //     'Account Title': row.accountTitle,
      //     'Enterprise': row.enterprise ? row.enterprise.name : '--',
      //     'Rep Code': row.repCode && row.repCode.length ? row.repCode[0].name : '--',
      //     'Account Type': row.accountType,
      //     'Brokerage': row.brokerageName,
      //     'Brokerage Account Number': row.brokerageAccountNumber,
      //     'Margin Type': row.marginType,
      //     'Account Value': row.statistics.value.toFixed(2),
      //     'Available Cash': row.statistics.cash.toFixed(2),
      //     'YTD %': row.statistics.returns.ytd.percent.toFixed(2),
      //     'QTD %': row.statistics.returns.qtd.percent.toFixed(2),
      //     'MTD %': row.statistics.returns.mtd.percent.toFixed(2),
      //     'Trading': row.isTrading ? 'On' : 'Off'
      //   };
      // });

      // Dashboard.JSONtoCSV(formattedRows, 'account_summary_data_' + timestamp + '.csv');

      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('downloadAccountsModal.tpl.html'),
        controller: function ($uibModalInstance) {
          var vm = this;

          vm.ok = function () {
            $uibModalInstance.close(true);
          };

          vm.cancel = function () {
            $uibModalInstance.dismiss();
          };
        },
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(confirm) {

        if (confirm) {
          $scope.downloading = true;
          $q.when(SmartXFactory.downloadAccounts($scope.accountsQuery))
          .then(function(res){
            console.log("Accounts Data: ", res);
            Dashboard.saveCsv(res, 'account_summary_data_' + moment().format("MM_DD_YYYY") + '.csv');
          })
          .catch(function(err){
            Dashboard.toastError(err, err.message);
          })
          .finally(function(){
            $scope.downloading = false;
          });
        }
      });
    }

    $scope.addTab = function(type, account, sortedIndex, target) {

      var accountIndex;

      if (typeof account != 'undefined' && account != null) {

        accountIndex = $scope.mappedAccounts.indexOf(account);
        // var accountId = account.id || account.account.id;

        Tabs.addTab(type, account.id, null, account);
        Dashboard.setActiveAccount(account);

        account.guid = account.id; // add the guid to top level for easier access

        if (type === 'liquidate') {

          if (account.allocations){

            account = angular.copy(account);

            account.allocations = account.allocations.filter(function(allocation){
              return allocation.type == 'model' && allocation.status == 'open';
            });
          }

          Dashboard.selectedLiquidationAccount = account;
          Dashboard.notifyObservers('main-liquidate', $scope.models);

        } else if (type === 'allocate') {

          Dashboard.selectedAllocationAccount = account;
          Dashboard.notifyObservers('accountSummary');

        } else if (type === 'accountSettings') {

          Dashboard.notifyObservers('accountSummary-' + type);

        } else {

          // this updates the selected account in the existing allocate or liquidate tab
          Dashboard.notifyObservers('accountSummary-' + type);
        }
      } else if (type === 'targetEdit' || type === 'accountsSummary') {

        Tabs.addTab(type);

      } else {

        accountIndex = $scope.mappedAccounts.indexOf(account);
        Tabs.addTab(type, accountIndex);
        Dashboard.currentAccount = Dashboard.accounts[accountIndex];

        // this updates the selected account in the existing allocate or liquidate tab
        Dashboard.notifyObservers('accountSummary-' + type);
      }
    };

    $scope.editDefaultTarget = function(account) {
      var target = {
        id: account.targetWeightId, 
        isDefaultTarget: true, 
        accessLevel: account.permission, 
        enterprise: account.enterprise, 
        accountNumber: account.brokerageAccountNumber, 
        brokerage: account.custodian ? account.custodian.name : account.brokerage,
        isApm: account.isApm
      };
      TargetWeightFactory.setActiveTarget(target);
      $scope.addTab('targetEdit', null, null, target);
    };

    $scope.editAssignedTarget = function (account){
      // load the default target if the single top level detail is a model or symbol

      // filter out cash detail
      account.targetDetails = account.targets.filter(function(detail){
        return detail.type !== 'cash';
      });
      
      var target = {
        id: account.targetDetails[0].type != 'targetWeightGroup' ? account.targetWeightId : account.targetDetails[0].id,
        isDefaultTarget: account.targetDetails[0].type != 'targetWeightGroup' ? true : false,
        name: account.targetDetails[0].name, 
        accessLevel: account.permission, 
        enterprise: account.enterprise, 
        accountNumber: account.brokerageAccountNumber, 
        brokerage: account.custodian ? account.custodian.name : account.brokerage
      };

      var advisorTargets = TargetWeightFactory.getAdvisorTargets();

      if (advisorTargets.length && !target.isDefaultTarget){
        var _target = _.find(advisorTargets, function(_target){
          return _target.id === target.id;
        });

        if (_target) {
          TargetWeightFactory.setActiveTarget(_target);
          $scope.addTab('targetEdit', null, null, _target);
        } else {
          TargetWeightFactory.getTargets(target.id)
          .then(function(res){
            var target = res.data.data;
            TargetWeightFactory.setActiveTarget(target);
            $scope.addTab('targetEdit', null, null, target);
          })
          .catch(function(err){
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          });
        }
      } else {
        TargetWeightFactory.setActiveTarget(target);
        $scope.addTab('targetEdit', null, null, target);
      }
    };
  });

