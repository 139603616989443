angular.module('AssignTargetToAccountModal', [])
  .controller('AssignTargetToAccountModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', '$filter', 'toastr', 'Dashboard', 'TargetWeightFactory', 'PortfolioEditorFactory', 'SweetAlert', 'target', 'accounts', '$timeout', 'EnterpriseFactory', 'selectedAccount', '$q', 'SmartXFactory', 'editingSmaModel', function($scope, $rootScope, $uibModalInstance, $filter, toastr, Dashboard, TargetWeightFactory, PortfolioEditorFactory, SweetAlert, target, accounts, $timeout, EnterpriseFactory, selectedAccount, $q, SmartXFactory, editingSmaModel) {

    var vm = this;
    var userEnterprise = EnterpriseFactory.getUserEnterprise();
    var modelApi = SmartXFactory.getModelAPI();

    vm.editingSmaModel = editingSmaModel;
    vm.target = target;
    vm.accounts = accounts;
    vm.selectedAccount = selectedAccount;
    vm.isSmaAccount = selectedAccount.internalType === 'sma';

    vm.selectedAccounts = [];
    vm.selectedTargets = [];

    vm.accountList = [];
    vm.targetList = [];
    vm.errorMsg = null;
    vm.showEmpty = true;
    vm.loadingTargets = false;

    vm.accessAccounts = Dashboard.getAccessAccounts();
    vm.accessAccountGUIDs = Object.keys(vm.accessAccounts);

    vm.dropdownAccountOptions = {
      enableSearch: true
    };

    vm.dropdownTargetOptions = {
      enableSearch: true,
      smartButtonMaxItems: 1,
      selectionLimit: 1,
      closeOnSelect: true,
      smartButtonTextConverter: function(itemText, originalItem) {
        return itemText;
      }
    };

    vm.targetBtnText = {
      buttonDefaultText: vm.isSmaAccount ? 'Select Model' : 'Select Target'
    };


    init();

    /////////////

    vm.cancel = cancel;
    vm.submit = submit;
    vm.toggleShowEmptyAccounts = toggleShowEmptyAccounts;

    function init() {
      if (vm.isSmaAccount) {

        // vm.targetList.push({
        //   id: 'fc2e02d6-5616-4a69-a493-1b02d67ab68a',
        //   label: 'SMA Model'
        // });

        // load sma models
        vm.loadingModels = true;
        vm.modelsLoaded = false;
        $q.when(modelApi.getAllModels({ type: 'sma', status: 'active' }))
        .then(function(res){
          console.log("SMA Model Options: ", res);

          res.forEach(function(model, index){
            
            var existsInTarget;
            
            if (target) {
              existsInTarget = _.find(target.details, function(detail){
                return detail.target.id === model.id;
              });
            }

            // exclude sma model that is already being used
            if (!existsInTarget) {
              vm.targetList.push({
                id: model.id,
                label: model.name || 'Model ' + index
              });
            }
          });

          vm.modelsLoaded = true;
        })
        .catch(function(err){
          Dashboard.toastError('Couldn\'t load SMA strategies', err);
        })
        .finally(function(){
          vm.loadingModels = false;
        });

      } else {
        processAccountList();
        processTargetList();
      }
    }

    function submit() {
      var temp = (vm.selectedAccounts.length) ? vm.selectedAccounts : vm.selectedTargets,
        title = (vm.selectedAccounts.length) ? 'Assign ' + vm.target.name + ' to accounts' : 'Warning',
        text = (vm.selectedAccounts.length) 
                ? 'Do you want to assign target (' + vm.target.name + ') to ' + temp.length + ' accounts.' 
                : 'Assign ' + (vm.isSmaAccount ? 'model' : 'targets') + ' to this account?';

      if (!vm.selectedTargets.length) {
        SweetAlert.swal({
          title: 'No ' + (vm.isSmaAccount ? 'model' : 'target') + ' selected',
          text: '',
          type: "warning",
        });

        return false;
      }

      SweetAlert.swal({
          title: title,
          text: text,
          type: "info",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        },
        function(isConfirm) {
          if (isConfirm) {

            var targetsToSubscribeToAccount = [];
            for (var i = 0; i < temp.length; i++) {
              var targetObj = temp[i];
              var singleTarget = vm.targetList.filter(function(elem, i, array) {
                return elem.id == targetObj.id;
              });

              // vm.selectedTargets is the item that was selected from the ui
              if (vm.selectedTargets.length) {

                singleTarget[0].id = targetObj.id;
                singleTarget[0].type = vm.isSmaAccount ? 'model' : 'targetWeightGroup';
                singleTarget[0].percentTarget = 100;
                singleTarget[0].percentLowerThreshold = vm.target.threshold.lower;
                singleTarget[0].percentUpperThreshold = vm.target.threshold.upper;
                singleTarget[0].percentLowerPrecision = vm.target.precision.lower;
                singleTarget[0].percentUpperPrecision = vm.target.precision.upper;

                // delete singleTarget[0].id;
              }

              targetsToSubscribeToAccount.push(singleTarget[0]);
            }

            $uibModalInstance.close({
              selectedModel: vm.selectedTargets[0],
              targetsToAdd: targetsToSubscribeToAccount,
              extraCash: vm.cashAmount
            });
          }
        });

    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function toggleShowEmptyAccounts() {
      console.log(vm.showEmpty);
      processAccountList();
    }

    function processTargetList() {
      if (typeof vm.target != 'undefined' && vm.target != null) {

        var twgId = vm.target.id;

        vm.targetList = [];

        vm.loadingTargets = true;

        // Add Enterprise awareness
        //TargetWeightFactory.get(null, {enterpriseId: userEnterprise.id})
        TargetWeightFactory.getTargets()
        .then(function(res){

          var response = res.data;
          var targets = _.sortBy(response.data, function(target){
            return target.name.toLowerCase();
          });

          targets.forEach(function(target){
            vm.targetList.push({
              id: target.id,
              label: target.name
            });
          });

          $scope.$apply();
        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $timeout(function(){
            vm.loadingTargets = false;
          });
        });

        // PortfolioEditorFactory.getAllowedTargets(twgId)
        //   .then(function(response) {

        //     console.log(twgId);

        //     if (typeof response.data.payload != 'undefined' && response.data.payload.targets.length > 0) {
        //       // if (response.targets.length > 0){

        //       for (var tid in response.data.payload.targets) {
        //         var target = response.data.payload.targets[tid];

        //         if (typeof target != 'undefined') {

        //           vm.targetList.push({
        //             id: target.id,
        //             label: target.name
        //           });
        //         }
        //       }

        //       console.log(vm.targetList);
        //     }
        //   });
      }
    }

    function processAccountList() {

      vm.accountList = [];
      for (var acct in vm.accounts) {
        var account = vm.accounts[acct];

        if (typeof account != 'undefined') {
          if ((vm.showEmpty && !account.subscribed) || !vm.showEmpty) {

            vm.accountList.push({
              id: account.id,
              label: account.name
            });
          }
        }
      }
    }

  }]);
