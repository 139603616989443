angular.module('ViewpointCtrl', [])
.controller("viewpoint", function($scope, $rootScope, $http, Dashboard, Models, Tabs, $sce, toastr) {

    var vm = this;

    $rootScope.$on('dashboard-account-access-set', function(event, args) {

        vm.accessGroups =  args.groups;
        vm.accessAccounts =  args.accounts;
        vm.accessModels =  args.models;

    });

    $rootScope.$on('dashboard-refresh-viewpoint', function(event, args) {

      vm.viewpoint = Models.currentViewpoint;

      if(typeof args.object != 'undefined') { vm.payload = args.object; }
      else if (typeof args._field_data != 'undefined') { vm.payload = args._field_data.nid.entity; }
      else{ vm.payload = vm.viewpoint; }

      vm.pdfURL = null;
      vm.pubDate = null;
      vm.videoURL = null;
      vm.relatedModels = [];

          vm.body = (typeof vm.payload != 'undefined' && typeof vm.payload.body.und != 'undefined') ? vm.payload.body.und[0].value  : $scope.tab.body;
      if(typeof vm.body.und != 'undefined'){ vm.body = vm.body.und[0].value; }
          vm.title = (typeof vm.payload != 'undefined') ? vm.payload.title  : $scope.tab.title;
          vm.nid = (typeof vm.payload != 'undefined') ? vm.payload.nid : $scope.tab.modelNID;


      setRelatedModels();

      if(typeof vm.payload.field_commentary_publish_date.und != 'undefined'){
        vm.pubDate = new Date(vm.payload.field_commentary_publish_date.und[0].value).getTime();
      }

      if(typeof vm.payload.field_commentary_file_s3.und != 'undefined')
      {
        vm.pdfURL = vm.convertArticleLink(vm.payload.field_commentary_file_s3.und["0"].uri);
      }
      else if(typeof vm.payload.field_commentary_video != 'undefined' && vm.payload.field_commentary_video != '')
      {
        var url = (typeof vm.payload.field_commentary_video == 'string') ? vm.payload.field_commentary_video : vm.payload.field_commentary_video.und[0].value;
        vm.videoURL = vm.getVimeoURLFromId(url);
      }
    });

    vm.payload;
    vm.viewpoint;
    vm.body;
    vm.title;
    vm.guid;
    vm.relatedModels;
    vm.pdfURL;
    vm.pubDate;
    vm.videoURL;

    function init(){
      console.log('Init ViewpointCtrl');
      vm.viewpoint = Models.currentViewpoint;
      vm.payload = (typeof vm.viewpoint._field_data != 'undefined') ? vm.viewpoint._field_data.nid.entity : vm.viewpoint; //._field_data.nid.entity;

      vm.body = (typeof vm.payload != 'undefined' && typeof vm.payload.body != 'undefined') ? vm.payload.body  : $scope.tab.body;
      if(typeof vm.body.und != 'undefined'){ vm.body = vm.body.und[0].value; }
      vm.title = (typeof vm.payload != 'undefined' && typeof vm.payload.title != 'undefined') ? vm.payload.title  : $scope.tab.title;
      vm.nid = (typeof vm.payload != 'undefined' && typeof vm.payload.field_admin_fund_fm_profile != 'undefined') ? vm.payload.field_admin_fund_fm_profile : $scope.tab.modelNID;

      setRelatedModels();

      if(typeof vm.payload.field_commentary_publish_date != 'undefined' && typeof vm.payload.field_commentary_publish_date.und != 'undefined'){
        vm.pubDate = new Date(vm.payload.field_commentary_publish_date.und[0].value).getTime();
      }else{
        vm.pubDate = new Date(vm.payload.field_commentary_publish_date).getTime();
      }


      if(typeof vm.payload.field_commentary_file_s3 != 'undefined' && vm.payload.field_commentary_file_s3.length)
      {
        vm.pdfURL = vm.convertArticleLink(vm.payload.field_commentary_file_s3);
      }
      else if(typeof vm.payload.field_commentary_file_s3 != 'undefined' && typeof vm.payload.field_commentary_file_s3.und != 'undefined' && vm.payload.field_commentary_file_s3.und.length)
      {
        vm.pdfURL = vm.convertArticleLink(vm.payload.field_commentary_file_s3.und[0].uri);
      }
      else if(typeof vm.payload.field_commentary_video != 'undefined' && vm.payload.field_commentary_video != '')
      {
        var url = (typeof vm.payload.field_commentary_video == 'string') ? vm.payload.field_commentary_video : vm.payload.field_commentary_video.und[0].value;
        vm.videoURL = vm.getVimeoURLFromId(url);
      }
    }

    vm.convertArticleLink = function(link) {
      if (link.indexOf('commentary/') !== -1) {
        var uriComponents = link.split('commentary/');
        // handle filenames with unfriendly characters. ex: Q1+2021+SMid+Dividend+Value+Brochure.pdf
        uriComponents[1] = encodeURIComponent(uriComponents[1]);
        link = uriComponents.join('commentary/');
      }
      return $sce.trustAsResourceUrl(link.replace('s3://', 'https://managerfiles.s3.amazonaws.com/'));
    };

    vm.addTabForModel = function(model){
      Models.currentModel = model;
      Tabs.addTab('model');
    };

    vm.addTab = function(type){

        var tab = Tabs.addTab(type);

        if (tab){
            tab.new = true;

            if (type === 'holdings'){
              // update the selected account in the holdings view
              Dashboard.notifyObservers('main');
            }

            $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);


        }

        return tab;
    };



  vm.getVimeoURLFromId = function(url){
    if (url.indexOf('vimeo') >= 0) {
      var a = (typeof url == 'string') ? url.split('/') : url[0].value.split('/');
      return $sce.trustAsResourceUrl('https://player.vimeo.com/video/'+a[a.length - 1]);
    }
    else if(url.indexOf('youtube') >= 0) {
      var y = (typeof url == 'string') ? url.split('=') : url[0].value.split('=');
      return $sce.trustAsResourceUrl('https://www.youtube.com/embed/'+y[y.length - 1]);
    }
    return $sce.trustAsResourceUrl(url);
  };

    // set up initial values and get some data
    init();



  function setRelatedModels() {

    if(typeof vm.viewpoint.object != 'undefined') {

      vm.relatedModels = [];
      vm.relatedModelsNames = vm.viewpoint.field_admin_fund_fm_profile;
      vm.relatedModelsNames = vm.relatedModelsNames.split(',');

      for(var i=0; i<vm.relatedModelsNames.length; i++) {
        vm.relatedModels.push({
          nid : vm.viewpoint.object.field_admin_fund_fm_profile.und[i].nid,
          name : vm.relatedModelsNames[i]
        });
      }
    } else if (typeof vm.viewpoint.relatedModelsNames != 'undefined') {

      vm.relatedModels = [];
      vm.relatedModelsNames = vm.viewpoint.relatedModelsNames;
      vm.relatedModelsNames = vm.relatedModelsNames.split(',');

      for(var i=0; i<vm.relatedModelsNames.length; i++) {
        vm.relatedModels.push({
          nid : vm.viewpoint.field_admin_fund_fm_profile.und[i].nid,
          name : vm.relatedModelsNames[i]
        });
      }
    } else {
      vm.relatedModels = (typeof vm.viewpoint != 'undefined') ? vm.viewpoint.field_field_admin_fund_fm_profile  : $scope.tab.related;

      if (typeof vm.relatedModels != 'undefined'){

        for(var i=0; i<vm.relatedModels.length; i++) {
          vm.relatedModels[i].nid = vm.relatedModels[i].raw.nid;
          vm.relatedModels[i].name = vm.relatedModels[i].raw.node.title;
        }
      }
    }

  }
});