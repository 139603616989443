angular.module('SecurityAdministrator')
.controller('SecurityAdminAssignRepCodeCtrl', securityAdminAssignRepCodeCtrl);

function securityAdminAssignRepCodeCtrl ($q, userEnterprise, enterprisesApi, $uibModalInstance, toastr, EnterpriseFactory, enterprises){
  var vm = this;
  
  vm.creating = false;
  vm.loadingEnterprises = false;
  vm.enterprisesList = enterprises.map(function(enterprise){
    return {
      id: enterprise.id,
      name: enterprise.name
    };
  });

  vm.repCodeAssignmentForm = [];

  vm.submit = submit;
  vm.cancel = cancel;

  vm.repCodes = [];
  vm.childEnterprises = userEnterprise.childEnterprise;
  vm.handleItemSelect = handleItemSelect;
  vm.handleChildSelect = handleChildSelect;
  vm.handleFromEnterpriseSelect = handleFromEnterpriseSelect;
  
  vm.assignmentForm = {};

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function handleFromEnterpriseSelect (fromEnterprise) {
    vm.loadingEnterprise = true;
    vm.repCodes = [];

    vm.assignmentForm.fromEnterpriseId = fromEnterprise.id;
    vm.toEnterpriseList = vm.enterprisesList.filter(function(enterprise){
      return enterprise.id !== fromEnterprise.id;
    });

    $q.when(enterprisesApi.get(null, {enterpriseId: fromEnterprise.id}))
    .then(function(res){
      var enterprise = res.data.data;
      var repCodes = enterprise.repCode;
      vm.repCodes = repCodes;
    })
    .catch(function(err){
      console.error(err);
      toastr.error(err.message);
    })
    .finally(function(){
      vm.loadingEnterprise = false;
    });
  }

  function handleItemSelect(item){
    vm.assignmentForm.repCodeId = item.id;
  }

  function handleChildSelect (item) {
    vm.assignmentForm.toEnterpriseId = item.id;
  }

  function buildAssignmentPayload () {
    var form = [vm.assignmentForm];
    
    return form;
  }

  function removeAssignedRepCodeFromList(repCodeId) {
    var index = _.findIndex(vm.repCodes, function(repCode){
      return repCode.id === repCodeId;
    });

    if (index > -1){
      vm.repCodes.splice(index, 1);
    }
  }

  function submit() {
    vm.creating = true;
    vm.errors = [];
    var payload = buildAssignmentPayload();

    $q.when(EnterpriseFactory.assignRepCodes(payload))
    .then(function(res){
      console.log("Process Completed: ", res);
      //toastr.success("User created");
      var response = res.data;

      var errors = _.filter(response, function(item){
        return item.status === false;
      });

      if (errors.length) {
        toastr.error("Error Assigning 1 or more Rep Codes");
        vm.errors = errors;
      } else {
        toastr.success("Assignment created");
        vm.assignmentForm = {
          fromEnterpriseId: vm.fromEnterprise.id
        };

        removeAssignedRepCodeFromList(vm.repCode.id);
        vm.toEnterprise = '';
        vm.repCode = '';
        // $uibModalInstance.close(res);
      }
    })
    .catch(function(err){
      toastr.error(err.message);
      console.error(err);
    })
    .finally(function(){
        vm.creating = false;
    });
  }
}