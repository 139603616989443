angular.module('BrokerageService', [])
.factory('Brokerage', ['$http', function($http){

	// this will hold the value of the step that's being edited in the brokerage view
	// this could be accessed from the profile tab to determine which tab to show based on the state of the brokerage view
	var editingStep,
				flatCalculation,
				activeAccountBrokerages = [],
		feeTableCalculation;
	
	var custodians = [
		{
			id: "",
			title: "- Select Firm -"
		},
		{
			id: 'fidelity',
			title: 'Fidelity'
		},
		{
			id: 'apex',
			title: 'Apex'
		},
		{
			id: 'amplify',
			title: 'Amplify'
		},
		{
			id: 'schwab',
			title: 'Charles Schwab'
		},
		{
			id: 'tdAmeritrade',
			title: 'TD Ameritrade'
		},
		{
			id: 'interactiveBrokers',
			title: 'Interactive Brokers'
		}
	];

	var accountType = [
		{
			name: 'Individual',
			value: 'individual',
			message: 'For accounts with one person as a beneficiary. This account is taxable and has access to margin.'
		},{
			name: 'IRA',
			value: 'ira',
			message: 'Individual Retirement Accounts are for US Citizens only and are either tax-deferred or tax-exempt depending on the type of IRA.  IRAs are not marginable, but Interactive Brokers does allow for funds to be immediately available upon liquidation rather than trade settlement.'
		},{
			name: 'Joint',
			value: 'joint',
			message: 'For accounts with two persons as the beneficiaries.  This account is taxable and has access to margin.'
		},{
			name: 'Entity',
			value: 'entity',
			message: 'Select this account if you would like to open a brokerage account in the name of entity rather than a natural born person.  This option is for hedge funds, other fund structures, small business accounts, family offices, corporations, LLCs, partnerships, or any unincorporated legal structures.'
		},
	];

	var accountStatuses = [
		{
		  id: '',
		  title: ''
		},
		{
		  id: 'active',
		  title: 'Active'
		},
		{
		  id: 'application not in good order',
		  title: 'Application Not in Good Order'
		},
		{
		  id: 'application pending broker approval',
		  title: 'Application Pending Broker Approval'
		},
		{
		  id: 'application Sent to client to sign',
		  title: 'Application Sent to Client to Sign'
		},
		{
		  id: 'closed',
		  title: 'Closed'
		},
		{
		  id: 'deleted',
		  title: 'Deleted'
		},
		{
		  id: 'inactive',
		  title: 'Inactive'
		},
		{
		  id: 'open and allocated',
		  title: 'Open and Allocated'
		},
		{
		  id: 'open and funded and unallocated',
		  title: 'Open and Funded and Unallocated'
		},
		{
		  id: 'open not funded',
		  title: 'Open Not Funded'
		},
		{
		  id: 'pending',
		  title: 'Pending'
		},
		{
		  id: 'apexpending',
		  title: 'Apex Pending'
		},
		{
		  id: 'apexsuspended',
		  title: 'Apex Suspended'
		},
		{
		  id: 'apexinvestigationsubmitted',
		  title: 'Apex Investigation Submitted'
		},
		{
		  id: 'apexcomplete',
		  title: 'Apex Complete'
		},
		{
		  id: 'rejected',
		  title: 'Rejected'
		},
		{
		  id: 'signed',
		  title: 'Signed'
		},
		{
		  id: 'unknown',
		  title: 'Unknown'
		}
  
	];

	var imaDocs = [
		{
			name: 'Investment Management Agreement',
			value: 'ima-signed',
			url: '/misc/HedgeCoVest-Investment-Management-Agreement-10.20.2014.pdf#page=1&zoom=80'
		},{
			name: 'Disclosure Document',
			value: 'disclosure-signed',
			url: '/misc/docs/hcv/HCV Brochure_NEW.pdf#page=1&zoom=80'
			//url: '/misc/HCV-ADV-Part-2.pdf#page=1&zoom=80' //Updated form 4/5/16
		},{
			name: 'Authorization to Debit Fees',
			value: 'fees-signed',
			url: '/misc/Authorization-to-Debit-Fees.pdf#page=1&zoom=80'
		}
	];

	var ibDocs = [
		{
			name: 'Client FA Agreement',
			value: 'ib[Form6105]',
			url: '/misc/docs/ib/Form6105.pdf#page=1&zoom=80'
		},{
			name: 'Agreement Limiting Interactive Brokers Liability',
			value: 'ib[Form6108]',
			url: '/misc/docs/ib/Form6108.pdf#page=1&zoom=80'
		},{
			name: 'Legal Acknowledgement',
			value: 'ib[Form2109]',
			url: '/misc/docs/ib/Form2109.pdf#page=1&zoom=80'
		}
		,{
			name: 'Margin Agreement- Reg-T' ,
			value: 'ib[Form1005]',
			url: '/misc/docs/ib/Form1005.pdf#page=1&zoom=80'
		},{
			name: 'Indiv/Joint/Trust',
			value: 'ib[Form3007]',
			url: '/misc/docs/ib/Form3007.pdf#page=1&zoom=80'
		},{
			name: 'Mutual Fund Information Electronically',
			value: 'ib[Form4035]',
			url: '/misc/docs/ib/Form4035.pdf#page=1&zoom=80'
		},{
			name: 'General Disclosure on Mutal Funds',
			value: 'ib[Form4036]',
			url: '/misc/docs/ib/Form4036.pdf#page=1&zoom=80'
		},{
			name: 'OCC Risk Disclosure',
			value: 'ib[Form4003]',
			url: '/misc/docs/ib/Form4003.pdf#page=1&zoom=80'
		},{
			name: 'ISE Disclosure for Option Orders Over 500 Contracts',
			value: 'ib[Form3076]',
			url: '/misc/docs/ib/Form3076.pdf#page=1&zoom=80'
		},{
			name: 'General Agreements/Disclosures' ,
			value: 'ib_merged[Form3070,Form3071,Form2192,Form3024,Form3094,Form3074,Form9130,Form4016]',
			url: '/misc/docs/ib/Interactive_Brokers_Merged_Documents_12-21-15.pdf#page=1&zoom=80'
		}
	];

	var overallExperienceOpts = [
		{
			name: 'I am new to investing',
			value: 'primary_experience_overall1' //'i_am_new_to_investing'
		},{
			name: 'I know a little about stocks and bonds',
			value: 'primary_experience_overall2' //'I_know_a_little_about_stocks_and_bonds'
		},{
			name: 'I’m comfortable with most investment products',
			value: 'primary_experience_overall3' //'i’m_comfortable_with_most_investment_products'
		},{
			name: 'I understand capital markets, investment vehicles, and investment strategies thoroughly',
			value: 'primary_experience_overall4' //I_understand_capital_markets,_investment_vehicles,_and_investment_strategies_thoroughly'
		}
	];

	var riskExperienceOpts = [
		{
			name: 'Disagree A Lot',
			value: 'primary_experience_risk1' //'disagree_a_lot'
		},{
			name: 'Somewhat Disagree',
			value: 'primary_experience_risk2' //'somewhat_disagree'
		},{
			name: 'Neutral',
			value: 'primary_experience_risk3' //'neutral'
		},{
			name: 'Somewhat Agree',
			value: 'primary_experience_risk4' //'somewhat_agree'
		},{
			name: 'Agree A Lot',
			value: 'primary_experience_risk5' //agree_a_lot'
		}
	];

	var losesExperienceOpts = [
		{
			name: 'Disagree A Lot',
			value: 'primary_experience_loses1' //'disagree_a_lot'
		},{
			name: 'Somewhat Disagree',
			value: 'primary_experience_loses2' //'somewhat_disagree'
		},{
			name: 'Neutral',
			value: 'primary_experience_loses3' //'neutral'
		},{
			name: 'Somewhat Agree',
			value: 'primary_experience_loses4' //'somewhat_agree'
		},{
			name: 'Agree A Lot',
			value: 'primary_experience_loses5' //agree_a_lot'
		}
	];

	var retirementExperienceOpts = [
		{
			name: 'Disagree A Lot',
			value: 'primary_experience_retirement1' //'disagree_a_lot'
		},{
			name: 'Somewhat Disagree',
			value: 'primary_experience_retirement2' //'somewhat_disagree'
		},{
			name: 'Neutral',
			value: 'primary_experience_retirement3' //'neutral'
		},{
			name: 'Somewhat Agree',
			value: 'primary_experience_retirement4' //'somewhat_agree'
		},{
			name: 'Agree A Lot',
			value: 'primary_experience_retirement5' //agree_a_lot'
		}
	];

	var identification_doc_type = [
		{
			name: 'Social Security Number',
			value: 'ssn'
		},{
			name: 'Passport',
			value: 'passport'
		},{
			name: 'Birth Certificate',
			value: 'birth-certificate'
		}
	];

	var yesNoOpts = [{
			name: 'Yes',
			value: true
		},{
			name: 'No',
			value: false
		},
	];

	var genderOpts = [{
			name: 'Male',
			value: 'Male'
		},{
			name: 'Female',
			value: 'Female'
		},
	];

	var maritalOpts = [{
			name: 'Divorced',
			value: 'Divorced'
		},{
			name: 'Married',
			value: 'Married'
		},{
			name: 'Single',
			value: 'Single'
		},{
			name: 'Widowed',
			value: 'Widowed'
		},
	];

	var feePeriodOpts = [{
			name: 'Monthly',
			value: 'Monthly'
		}
//		,{
//			name: 'Quarterly',
//			value: 'Quarterly'
//		}
//		,{
//			name: 'Annually',
//			value: 'Annually'
//		}
	];

	var feeTypeOpts = [{
			name: 'Arrears',
			value: 'Arrears'
		}
//		,{
//			name: 'Upfront',
//			value: 'Upfront'
//		}
	];

	var dependentsOpts = [{
			name: '0',
			value: '0'
		},{
			name: '1',
			value: '1'
		},{
			name: '2',
			value: '2'
		},{
			name: '3',
			value: '3'
		},{
			name: '4',
			value: '4'
		},{
			name: '5',
			value: '5'
		},{
			name: '6',
			value: '6'
		},{
			name: '7',
			value: '7'
		},{
			name: '8',
			value: '8'
		},{
			name: '9',
			value: '9'
		},{
			name: '10',
			value: '10'
		},{
			name: '11',
			value: '11'
		},{
			name: '12',
			value: '12'
		},{
			name: '13',
			value: '13'
		},{
			name: '14',
			value: '14'
		},{
			name: '15',
			value: '15'
		},{
			name: '16',
			value: '16'
		},{
			name: '17',
			value: '17'
		},{
			name: '18',
			value: '18'
		},{
			name: '19',
			value: '19'
		},{
			name: '20+',
			value: '20+'
		},
	];

	var setStep = function(step){
		editingStep = {
			step : step
		};
	};

	var getStep = function(){

		if (angular.isDefined(editingStep)){

			return editingStep;

		} else {

			return undefined;
		}
	};

   
   var setFlatCalculation = function(value) {
      flatCalculation = value;
   };
   var setFeeTableCalculation = function(value) {
      feeTableCalculation = value;
   };
   var getFlatCalculation = function() {
      return flatCalculation;
   };
   var getFeeTableCalculation = function() {
      return feeTableCalculation;
   };
        
	function setActiveAccountBrokerages (brokers) {
		activeAccountBrokerages = brokers;
	}

	function getActiveAccountBrokerages () {
		return activeAccountBrokerages;
	}
			
	return {
		setActiveAccountBrokerages: setActiveAccountBrokerages,
		getActiveAccountBrokerages: getActiveAccountBrokerages,
		custodians: custodians,
		accountStatuses: accountStatuses,
		accountType : accountType,
		imaDocs : imaDocs,
		ibDocs : ibDocs,
		yesNoOpts : yesNoOpts,
		genderOpts : genderOpts,
		feePeriodOpts : feePeriodOpts,
		feeTypeOpts : feeTypeOpts,
		editingStep : editingStep,
		maritalOpts : maritalOpts,
		dependentsOpts : dependentsOpts,
		getStep : getStep,
		setStep : setStep,
		overallExperienceOpts : overallExperienceOpts,
		riskExperienceOpts : riskExperienceOpts,
		losesExperienceOpts : losesExperienceOpts,
		retirementExperienceOpts : retirementExperienceOpts,
		identification_doc_type : identification_doc_type,
       
		setFlatCalculation : setFlatCalculation,
		setFeeTableCalculation : setFeeTableCalculation,
		getFlatCalculation : getFlatCalculation,
		getFeeTableCalculation : getFeeTableCalculation
	};
}]);