angular.module('RebalanceRequestDetails', [])
.directive('rebalanceRequestDetails', ['$filter', 'RebalancerFactory', '$templateCache', 'Tabs', 'Dashboard', function($filter, RebalancerFactory, $templateCache, Tabs, Dashboard){
	return {
		template: $templateCache.get('rebalanceRequestDetails.tpl.html'),
		controller: requestDetailsController,
		controllerAs: 'vm',
		restrict: 'E',
		scope: {
			requestId: '=',
			status: '=',
			accounts: '='
		},
		bindToController: true,
		link: function($scope, iElm, iAttrs, controller) {},
	};

	function requestDetailsController(NgTableParams, toastr, $rootScope, $timeout, $scope, $modal, SmartXFactory){
		var vm = this;
		var requestId = vm.requestId;
		var parentTab = $scope.$parent.tab;
		var accountApi = SmartXFactory.getAccountAPI();
		var requestAccounts = vm.accounts;

		vm.showingExtraColumns = false;
		vm.selectedRebalanceLogic = 'best';

		vm.nameFilter = {
			name: {
				id: "select",
				placeholder: "Filter by account",
			}
		};

		vm.failedTransactions = {};
		vm.selectedGrouping = vm.status == 'pendingApproval' ? 'Transaction Level' : 'Account Level';

		vm.statusFilter = {
			status: {
				id: 'select',
				placeholder: 'Select Status'
			}
		};

		vm.statusFilterOptions = [
			{
				id: '',
				title: 'All Statuses'
			},
			{
				id: 'complete',
				title: 'Complete'
			},
			{
				id: 'failed',
				title: 'Failed'
			}
		];

	
		var blotterConfig = {filter: {}};
		vm.showFailedTransactions = showFailedTransactions;
		vm.errorsTable = new NgTableParams(blotterConfig, {counts: ['10','25','50', '100'], dataset: [] }); 

		init();

		function showFailedTransactions (id, transactions) {

			if (vm.failedTransactions[id]){
				return vm.failedTransactions[id];
			} else {
				var formattedTransactions = transactions.map(function(transaction){
					return {
						investment: transaction.investment.name,
						side: transaction.minTran.type,
						result: transaction.status,
						reason: transaction.message
					};
				});

				vm.failedTransactions[id] = formattedTransactions;
			}
		}

		function groupResultsByAccount(accounts) {
			var formattedResults = accounts.filter(function(account){
				return angular.isDefined(account.account.name);
			})
			.map(function(account){
				var accountResultSummary = {};
				accountResultSummary.id = account.account.id;
				accountResultSummary.name = $filter('sanitizeSmartXAccountName')(account.account.name, 'name');
				accountResultSummary.number = $filter('sanitizeSmartXAccountName')(account.account.brokerageAccountNumber, 'number');
				accountResultSummary.transactionsCount = account.results.length;
				accountResultSummary.detail = account.detail;
				if (accountResultSummary.detail && accountResultSummary.detail.requestResult){
					if (accountResultSummary.detail.requestResult.indexOf('Failed by UMA -') > -1){
						accountResultSummary.detail.requestResult = accountResultSummary.detail.requestResult.replace('Failed by UMA -', '').replace(/\"/g, '');
					}
				}
				accountResultSummary.errors = _.find(requestAccounts, {id: accountResultSummary.id }) ? _.find(requestAccounts, {id: accountResultSummary.id }).message : null;
				accountResultSummary.groupedResults = _.reduce(account.results, function(group, result, index){
																								if (result.status.toLowerCase() == 'failed'){
																									group.failed.push(result);
																								} else if (result.status.toLowerCase() == 'complete') {
																									group.completed.push(result);
																								} else {
																									group.other.push(result);
																								}

																								return group;
																							}, {failed: [], completed: [], other: []});
				
				return accountResultSummary;
			});

			var counts = formattedResults.length > 10 ? ['10','25','50', '100'] : [];
			var blotterConfig = {filter: {name: ''}};
			vm.accountLevelTable = new NgTableParams(blotterConfig, {counts: counts, dataset: formattedResults });
		}

		function init(){

			vm.tradeAccountNames = [{id: '', title: 'All Accounts'}];

			vm.loadingRequestDetails = true;
			vm.authorizationComplete = false;
			RebalancerFactory.getRebalanceRequestResults(requestId)
			.then(function(response){

				if (response.status == 'fail'){
					Dashboard.toastError(response.data.msg[0].description);
					return;
				}

				groupResultsByAccount(response);

				var results = response.reduce(function(detailsList, details){

					var trades = details.results.map(function(trade){
						trade.account = {
							name: $filter('sanitizeSmartXAccountName')(details.account.name, 'name'),
							brokerageAccountNumber: $filter('sanitizeSmartXAccountName')(details.account.brokerageAccountNumber, 'number'),
							id: details.account.id
						};

						trade.name = $filter('sanitizeSmartXAccountName')(details.account.name, 'name');

						return trade;
					});

					return detailsList.concat(trades);
				}, []);

				var blotterConfig = {filter: {name: '', status: ''}};

				var counts = results.length > 10 ? ['10','25','50', '100'] : [];

				// 	_.map(
				// 		_.uniq(
				// 			_.map(results, function(result) { return result.account.name })
				// 		),
				// 		function(name){
				// 			return {id: name, title: name}
				// 		}
				// 	)
				results.forEach(function(result){
					var name = result.name;
					var _index = _.findIndex(vm.tradeAccountNames, {'title': name});
					if (_index < 0) vm.tradeAccountNames.push({id: name, title: name});
				});
				
				vm.blotterTableParams = new NgTableParams(blotterConfig, {counts: counts, dataset: results }); 
			})
			.catch(function(err){
				// toastr.error(response.message);
				Dashboard.toastError(err.message, err);
			})
			.finally(function(){
				$timeout(function(){
					vm.loadingRequestDetails = false;
				});
			});
		}

		vm.openAccount = function(account){

			account.fetchingDetails = true;
			accountApi.getAccountById(account.id)
			.then(function(res){
				Tabs.addTab('main', account.id, null, res.data);
			})
			.catch(function(err){
				// toastr.error('Error fetching account details');
				// console.error(err);
				Dashboard.toastError("Failed to load account details", err);
			})
			.then(function(){
				$timeout(function(){
					account.fetchingDetails = false;

				});
			});
		};

		vm.toggleColumns = function(columns){

			vm.showingExtraColumns = !vm.showingExtraColumns;

			vm.cols.forEach(function(col){
				var title = col.title(),
					showing;

				if (title == 'Precision' || title == 'Threshold'){

					showing = col.show();

					if (showing){
						col.show(false);
					} else {
						col.show(true);
					}
				}
			});
		};

		vm.authorizeRebalanceTrades = function(){

			var logic = vm.selectedRebalanceLogic == 'best' ? 'bestFit' : 'minTransaction';

			var payload = {
				"id": requestId,
				"logic": logic
			};

			if (vm.authorizationComplete) return false;

			vm.authorizingRebalance = true;
			RebalancerFactory.authorizeRebalance(payload)
			.then(function(response){
				console.log("Rebal Auth: ", response);

				// if (response.data.status == 'fail'){
				// 	toastr.error(response.data.msg[0].description);
				// 	return;
				// }

				//if (response.data.status == 'success'){
				toastr.success("The rebalance request " + requestId + " has completed.");
				
				//resetRebalancer();
				//vm.processingRebalance = true;
				//checkRebalanceStatus(response.data.payload.rebalanceRequestId);
				vm.authorizationComplete = true;
				// removeTab();
				$rootScope.$broadcast('refresh-rebalancer-data');
				// }
			})
			.catch(function(err){
				// toastr.error(err.message);
				Dashboard.toastError(err.message, err);
			})
			.finally(function(){
				$timeout(function(){
					vm.authorizingRebalance = false;
				});
			});
		};

		function removeTab(){
			var tabs = Tabs.getTabs();
			var activeTabIndex = tabs.indexOf(parentTab);
			Tabs.removeTab(activeTabIndex);
		}
	}
}]);