angular.module('CsvGenerator', [])
.directive('csvGenerator', function(Dashboard){
  return {
    restrict: 'E',
    scope: true,
    template : '<div class="btn btn-default" ng-click="generateCSV();">Download Account Performance</div>',
    controller : function($scope, $q, Dashboard){
      console.log($scope);

      var accountID = $scope.selectedAccount.accountInfo.nid,
          accountTitle = $scope.selectedAccount.accountInfo.title,
          csvArr,
          fileName;

      // watch the accountID for changes so that it updates whenever the account switches
      $scope.$watch('selectedAccount.accountInfo.nid', function(newVal, oldVal){
        if (angular.isDefined(newVal) && newVal !== null){
          if (newVal !== oldVal){
            accountID = newVal;
            accountTitle = $scope.selectedAccount.accountInfo.title;
            // build the json object for the current account
            buildPerformanceObject(accountID);
          }
        }
      });

      $scope.generateCSV = function(){
        JSONtoCSV(csvArr, fileName);
      }

      // start here
      buildPerformanceObject();

      function JSONtoCSV(data, fileName){
        var csvString = Papa.unparse(data);
        var fileName = fileName ? fileName : 'export.csv';
        var blobObject = new Blob([csvString], {
            type: "text/csv;charset=utf-8;"
        });
        // Internet Explorer
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobObject, fileName);
        } else {
            // Chrome
            var downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blobObject);
            downloadLink.download = fileName;
            //downloadLink.target = '_blank';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
      }

      function buildPerformanceObject(){

        Dashboard.getAllocations_v2(accountID)
        .then(function(res){

          var performanceHistory = res.data.PerformanceHistory,
              brokerage = res.data.Brokerage,
              brokerageAccount = res.data.BrokerageAccount,
              accountString = brokerage + '-' + brokerageAccount,
              timeStart = performanceHistory[0].AccountPerformanceDate,
              timeEnd = performanceHistory[performanceHistory.length - 1].AccountPerformanceDate,
              timePeriod = timeStart + " to " + timeEnd;

          csvArr = [];
          fileName = accountTitle + '_' + accountString + '.csv';

          _.each(performanceHistory, function(day, index){

            var date = day.AsOfTimestamp,
                pnl = day.PnL,
                pnlPercentage = day.PnLPercentage * 100 + '%',
                closingEquity = day.ClosingEquity,
                openingEquity = day.OpeningEquity,
                pnl = day.PnL,
                pnlPercentage = day.PnLPercentage,
                positionCount = day.PositionCount,
                fees = day.Fees,
                rowObj = {};

            _.extend(rowObj, {
              "Date" : date,
              "Opening Equity" : openingEquity,
              "Closing Equity" : closingEquity,
              "PnL" : pnl,
              "PnL Percentage" : pnlPercentage,
              "Position Count" : positionCount,
              "Fees" : fees
            });

            csvArr.push(rowObj);

          });

          console.log("Finished Building Performance Object");

        });

      }

    }
  }
});
