angular.module('SecurityAdministrator')
.directive('securityAdminUsers', function($templateCache) {                                                                                                                                       
    return {
      restrict: 'E',
      scope: {},
      template: $templateCache.get('securityAdminUsers.tpl.html'),
      controller: securityAdminUsersCtrl,
      controllerAs: 'vm'
    };                                                                                  
    
    function securityAdminUsersCtrl (ngTableEventsChannel, $filter, EnterpriseFactory, $templateCache, $modal, Dashboard, SmartXFactory, toastr, NgTableParams, $scope, $q, $element, Tabs){
      var vm = this;
      var userApi = new SmartX.UserApi();

      vm.currentUser = Dashboard.getReplicatorUser();
      vm.userEnterprise = EnterpriseFactory.getUserEnterprise();
      
      // should only allow creation in identity server compatible version
      vm.createUserEnabled = true;

      vm.selectedUsers = [];
      vm.entepriseUsers = [];
      vm.selectedEnterprise = {};
      vm.loading = false;

      vm.showCreateUser = showCreateUser;
      vm.showEditUser = showEditUser;
      vm.viewProfile = viewProfile;
      vm.refreshUsers = refreshUsers;
      vm.updateUserAdmin = updateUserAdmin;
      vm.clearSelection = clearSelection;

      vm.showMoveEnterprise = showMoveEnterprise;

      vm.usernameFilter = {
        'userName': {
          id: "debouncedText",
          placeholder: "Enter a name"
        }
      };

      vm.emailFilter = {
        'email': {
          id: "debouncedText",
          placeholder: "Enter an e-mail"
        }
      };

      vm.enterpriseFilter = {
        'enterpriseId': {
          id: "user-enterprise-lookup",
          placeholder: "Enter an enterprise",
          searchText: ''
        }
      };

      vm.userCheckboxes = {
        checked: false,
        items: {}
      };

      init();

      $scope.$watch(function(){
        return Dashboard.getReplicatorUser();
      }, function(user){
        if (angular.isDefined(user)){
          vm.currentUser = user;
        }
      });
  
      // watch for check all checkbox
      $scope.$watch(function() {
        return vm.userCheckboxes.checked;
      }, function(value) {
        angular.forEach(vm.enterpriseUsers, function(item) {
          // avoid selecting our own user
          if (item.id !== vm.currentUser.id) vm.userCheckboxes.items[item.id] = value;
        });
  
        vm.selectedUsers = getSelected('user');
        // console.log('Selected User: ', vm.selectedUser);
  
      });

      // watch for data checkboxes
      $scope.$watch(function() {
        return vm.enterpriseUsers && vm.userCheckboxes.items;
      }, function() {

        if (!angular.isDefined(vm.enterpriseUsers)) return false;
        
        var checked = 0, unchecked = 0,
            total = vm.enterpriseUsers.length;
        angular.forEach(vm.enterpriseUsers, function(item) {
          checked   +=  (vm.userCheckboxes.items[item.id]) || 0;
          unchecked += (!vm.userCheckboxes.items[item.id]) || 0;
        });
        if ((unchecked == 0) || (checked == 0)) {
          vm.userCheckboxes.checked = (checked == total);
        }
  
        vm.selectedUsers = getSelected('user');
        console.log('Selected Users: ', vm.selectedUsers);
  
        // grayed checkbox
        angular.element($element[0].getElementsByClassName("user-select-all")).prop("indeterminate", (checked != 0 && unchecked != 0));
      }, true);

      function init() {
        getUsers();
        loadEnterprises();
      }

      vm.matching = function matching (item) {
        var match;
        var input = vm.enterpriseFilter.enterpriseId.searchText;
        console.log(input);
        if (input.length || input.name){
          match = (item.name.toLowerCase() === (input.length ? input : input.name).toLowerCase());
          if (match){
            console.log("we matched: ", item.name);
          }
        }
        return match;
      };

      $scope.orderByValue = function (value) {
        return value;
      };

      $scope.$watch('vm.enterpriseFilter.enterpriseId.searchText', function(newVal, oldVal){
        if (
          angular.isDefined(oldVal) 
          && (oldVal.length || oldVal.name) 
          && vm.selectedEnterprise.name
          && newVal === ''
        ) {
          // the input is clear
          // remove the enterprise filter to trigger request
          var filters = vm.usersTable.filter();
          vm.usersTable.filter(_.extend(filters, {
            enterpriseId: ''
          }));
          vm.selectedEnterprise = {};
        }
      });

      function loadEnterprises() {
        vm.loadingEnterprises = true;
        EnterpriseFactory.loadEnterpriseLookup()
        .then(function(enterprises){
          vm.enterprisesList = enterprises;

          vm.enterpriseOptions = enterprises.map(function(ent){
            return {
              id: ent.id,
              name: ent.name
            };
          });
        })
        .catch(function(err){
          Dashboard.toastError('Error loading enterprises', err);
        })
        .finally(function(){
          vm.loadingEnterprises = false;
        });
      }

      vm.enterpriseSelected = enterpriseSelected;

      function enterpriseSelected (item) {
        var filters = vm.usersTable.filter();
        filters.enterpriseId = item.id;
        vm.usersTable.filter(filters);
        vm.selectedEnterprise = item;
      }

      function getUsers(){    
        if (vm.usersTable) {
          vm.usersTable.reload();
        } else {
          vm.usersTable = new NgTableParams({},{
            getData: function (params) {
              var dataset;
              
              // get the active sort
              var sortBy = params.sorting();
              var orderBy = params.orderBy();
              var query = params.filter();
              vm.loading = true;

              // TODO: manually apply the sort to the dataset after filtering
              return $q.when(userApi.search(query))
              .then(function(res){
                vm.enterpriseUsers = res.data.map(function(user){
                  user.enterpriseId = user.enterprise.id;
                  return user;
                });

                // vm.enterpriseOptions = _.map(vm.enterpriseUsers, 'enterprise');

                dataset = params.filter() ? $filter('filter')(vm.enterpriseUsers, params.filter()) : vm.enterpriseUsers;
                params.total(dataset.length);
              
                vm.filteredData = dataset;

                var results = dataset.slice((params.page() - 1) * params.count(), params.page() * params.count());

                // return sorted results
                return params.sorting() ? $filter('orderBy')(results, params.orderBy()) : results;
              })
              .catch(function(err){
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                vm.loading = false;
              });
            }
          });
        }
      }

      function clearSelection () {
        vm.userCheckboxes.items = {};
      }

      function viewProfile (user) {
        // add tab to display user assignments
        Tabs.addTab('assignments', null, null, {
          user: user
        });
      }
  
      function refreshUsers () {
        init();
      }

      function updateUserAdmin (user) {

        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'confirmAdmin.html',
          resolve: {
            user: function () {
              return user;
            },
          },
          controller: function ($uibModalInstance, $scope, user) {
            var vm = this;
            vm.user = user;
            vm.confirm = confirm;
            vm.cancel = cancel;

            function cancel() {
              vm.user.isSecurityAdministrator = false;
              $uibModalInstance.dismiss('cancel');
            }

            function confirm () {
              $uibModalInstance.close(true);
            }
            
          },
          controllerAs: 'vm'
        });

        modalInstance.result.then(function(confirmed){
          if (confirmed) {
            user.updating = true;
            $q.when(userApi.update(user.id, {
              isSecurityAdministrator: user.isSecurityAdministrator
            }))
            .then(function(){
              toastr.success(user.userName + ' updated');
            })
            .catch(function(err){
              user.isSecurityAdministrator = !user.isSecurityAdministrator;
              Dashboard.toastError(err.message, err);
            })
            .finally(function(){
              user.updating = false;
            });
          }
        });
      }

      function showMoveEnterprise () {
        var selectedUsers = vm.selectedUsers.map(function(user){
          var enterpriseUser = _.find(vm.enterpriseUsers, {id: user.id });
          if (enterpriseUser) user.userName = enterpriseUser.userName;
          return user;
        });

        var modalInstance = $modal.open({
          animation: true,
          backdrop: "static",
          templateUrl: 'moveUserModal.html',
          resolve: {
            selectedUsers: function () {
              return selectedUsers;
            },
            userApi: function () {
              return userApi;
            }
          },
          controller: 'SecurityAdminMoveUserCtrl',
          controllerAs: 'vm'
        });

        modalInstance.result.then(function(res){
          if (res === 'complete'){

            // deselect
            vm.selectedUsers.forEach(function(user){
              delete vm.userCheckboxes.items[user.id];
            });

            vm.userCheckboxes.checked = false;
            vm.selectedUsers = [];
            refreshUsers();
          }
        });
      }

      // function deleteUser (user) {

      //   user.deleting = true;
  
      //   $q.when(userApi.delete(user.id))
      //   .then(function(res){
      //     // console.log("Delete result: ", res);
      //     toastr.success('User removed');
      //     init();
      //   })
      //   .catch(function(err){
      //     console.error(err);
      //     toastr.error(err.message);
      //   })
      //   .finally(function(){
      //     user.deleting = false;
      //   });
      // }
  
      function showEditUser (user) {
        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('editUserModal.tpl.html'),
          resolve: {
            currentUser: function (){
              return vm.currentUser;
            },
            user: function () {
              return user;
            },
            userApi: function () {
              return userApi;
            }
          },
          controller: function (user, $uibModalInstance, userApi, toastr, EnterpriseFactory, currentUser){
            var vm = this;
            // console.log("User wants to create: ", user);
            vm.saving = false;
            vm.user = user;
            vm.currentUser = currentUser;
            vm.editUserForm = user;
            vm.submit = submit;
            vm.cancel = cancel;
            vm.handleEnterpriseSelect = handleEnterpriseSelect;
            vm.editAdminDisabled = vm.user.isSecurityAdministrator;

            vm.model = {};
            vm.fields = [
              {
                "key": "email",
                "type": "input",
                "templateOptions": {
                  "label": "Type",
                  validation: {
                      show: true
                  }
                },
              }
            ];

            init();

            function init () {
              loadEnterprises();
            }

            function loadEnterprises() {
              vm.loadingEnterprises = true;
              EnterpriseFactory.loadEnterpriseLookup()
              .then(function(enterprises){
                vm.enterpriseOptions = enterprises.map(function(enterprise){
                  return {
                    id: enterprise.id,
                    name: enterprise.name
                  };
                });
              })
              .catch(function(err){
                console.error(err);
                toastr.error(err.message);
              })
              .finally(function(){
                vm.loadingEnterprises = false;
              });
            }

            function cancel() {
              $uibModalInstance.dismiss('cancel');
            }
  
            // function format (form) {
            //   form.enterpriseId = vm.user.enterprise.id;
            //   return form;
            // }

            function handleEnterpriseSelect(item){
              vm.editUserForm.enterpriseId = item.id;
            }
  
            function submit(editUserForm) {
              vm.saving = true;
  
              var payload = {
                isSecurityAdministrator: editUserForm.isSecurityAdministrator,
                email: editUserForm.email
              };

              if (editUserForm.enterpriseId) {
                payload.enterpriseId = editUserForm.enterpriseId;
              }
  
              // console.log("Form payload: ", payload);
  
              $q.when(userApi.update(user.id, payload))
              .then(function(res){
                // console.log('user edit result: ', res);
                $uibModalInstance.close(res.data);
              })
              .catch(function(err){
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                vm.saving = false;
              });
            }
          },
          controllerAs: 'vm'
        });
  
        modalInstance.result.then(function(newUser) {
          if (newUser) {
            toastr.success("User saved!");
            getUsers();
          }
        });
      }

      function getSelected(type){
        var selectedItems = vm[type + 'Checkboxes'].items;
        var selected = [];

        selected = _.chain(angular.copy(selectedItems))
                    .map(function(selected, id){
                      return {
                        id: id,
                        selected: selected
                      };
                    })
                    .filter(function(item){
                      return item.selected;
                    })
                    .value();

        // console.log("Items Selected: ", selected);
        return selected;
      }

      function showCreateUser () {
        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('createUserModal.tpl.html'),
          resolve: {
            currentUser: function () {
              return Dashboard.getReplicatorUser();
            },
            userApi: function () {
              return userApi;
            },
            enterprisesApi: function () {
              return SmartXFactory.getEnterprisesAPI();
            },
            enterpriseUsers: function(){
              return vm.enterpriseUsers;
            }
          },
          controller: 'SecurityAdminCreateUserCtrl',
          controllerAs: 'vm'
        });
  
        modalInstance.result.then(function(newUser) {
          if (newUser) {
            toastr.success("User created!");
            init();
          }
        });
      }
    }
});