angular.module('BillingModule')
.directive('billingExclusions', function($templateCache) {
	return {
		restrict: 'E',
		scope: {},
    template: $templateCache.get('billing-exclusions.tpl.html'),
		controller: billingExclusionsCtrl,
		controllerAs: 'vm',
  };

  function billingExclusionsCtrl (NgTableParams, Dashboard, Billing, SmartXFactory, $q, $scope, EnterpriseFactory) {
    var vm = this;
    // var enterpriseApi = SmartXFactory.getEnterprisesAPI();
    // var userEnterprise = EnterpriseFactory.getUserEnterprise();

    vm.selectedAccount = null;
    vm.setAccount = setAccount;
    vm.refresh = init;
    // vm.getEnterprises = getEnterprises;
    // vm.enterpriseSelected = enterpriseSelected;
    // vm.loadingEnterprises = false;
    // vm.selectedEnterprise = ''; // userEnterprise;
    vm.clearAccount = clearAccount;

    // vm.enterpriseFilter = {
    //   'enterpriseId': {
    //     id: "account-enterprise-lookup",
    //     placeholder: "Enter an enterprise",
    //     searchText: ''
    //   }
    // };

    var exclusions = [
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      },
      {
        account: {
          name: 'Account Name',
          brokerageAccountNumber: '333333',
          repCode: 'AAA111',
          enterprise: {
            name: 'Enterprise'
          }
        },
        exclusionId: '',
        exclusion: {
          security: 'GBTC'
        },
        feeSchedule: 'Fee Schedule Name',
        household: 'Household Name'
      }
    ];

    $scope.$watch('vm.selectedAccount', function(newVal, oldVal){
      console.log("Account selection changed: ", newVal, oldVal);
      if (angular.isDefined(newVal) && newVal !== oldVal) {
        var filter = vm.billingExclusionsTable.filter();

        if (newVal && newVal.id){
          filter.accountIds = [newVal.id];
        } else if (newVal === null){
          filter.accountIds = [];
        }

        // trigger an api call with the updated accountIds filter
        vm.billingExclusionsTable.filter(filter);
      }
    });
    
    init();

    function init () { 

      if (vm.billingExclusionsTable){
        vm.billingExclusionsTable.reload();
        return;
      }

      vm.billingExclusionsTable = new NgTableParams({
        page: 1,
        count: 25,
        sorting: {
          
        }
      }, {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';
            
          var query = {
            pageNumber: page,
            pageSize: size,
            sortKey: sort,
            sortDescending: direction == 'desc' ? true : false
          };

          // if (vm.selectedAccount) {
          //   filter.accountIds = [vm.selectedAccount.id];
          // }

          _.extend(query, filter);

          console.log("Search Params: ", query);

          vm.loading = true;
          vm.exclusionsQuery = query;
          
          return loadBillingExclusions(query)
                  .then(function(res){
                    console.log("Response: ", res);
                    params.total(res.metaData.totalRecordCount);          
                    var exclusions = formatExclusions(res.data);
          
                    return exclusions;
                  })
                  .catch(function(err){
                    Dashboard.toastError(err.message, err);
                  })
                  .finally(function(){
                    vm.loading = false;
                  });
        },
      });
     }

    function loadBillingExclusions (query) {

      vm.loadingExclusions = true;

      // if filtering by account, use the accounts endpoint
      // otherwise use the firms endpoint with an empty payload (will get all of the exclusions from the user's firms)
      var method = query.accountIds && query.accountIds.length 
                    ? "getExclusionsByAccount" 
                    : "getExclusionsByFirm";

      return $q.when(Billing[method](query))
      .then(function(res){
        return $q.resolve(res.data);
      })
      .catch(function(err){
        return $q.reject(err);
      })
      .finally(function(){
        vm.loadingExclusions = false;
      });
    }

    function formatExclusions (data) {
      var exclusions = data.map(function(exclusion){
        return exclusion;
      });

      console.log("Formatted exclusions: ", exclusions);

      return exclusions;
    }

    function clearAccount() {
      vm.selectedAccount = null;
    }

    // function enterpriseSelected (enterprise) {
    //   var filters = vm.billingExclusionsTable.filter();
    //   if (enterprise) filters.firmIds = [enterprise.id];
    //   else delete filters.firmIds;
    //   vm.billingExclusionsTable.filter(filters);
    //   vm.selectedEnterprise = enterprise || '';
    // }

    // function getEnterprises(searchText) {
    //   vm.noResults =  false;
    //   vm.loadingEnterprises = true;
    //   return $q.when(enterpriseApi.search({name: searchText}))
    //     .then(function(res){
    //       if (res.data && !res.data.length) {
    //         vm.noResults = true;
    //       }
    //       return res.data;
    //     })
    //     .catch(function(err){
    //       Dashboard.toastError(err.message, err);
    //     })
    //     .finally(function(){
    //       vm.loadingEnterprises = false;
    //     });
    // }

    function setAccount (account) {
      vm.selectedAccount = account;
    }
  }
});