angular.module('BillingModule')
.directive('insufficientFunds', function($templateCache) {
  return {
		restrict: 'E',
		scope: {},
    template: $templateCache.get('insufficient-funds.tpl.html'),
		controller: insufficientFundsCtrl,
		controllerAs: 'vm',
  };

  function insufficientFundsCtrl (NgTableParams, Dashboard, Billing, SmartXFactory, $q, $filter, $scope, FeatureFlags) {
    var vm = this;
    var lastQuery;
    vm.selectedAccount = null;
    vm.setAccount = setAccount;
    vm.refresh = refresh;
    vm.loading = false;
    vm.clearAccount = clearAccount;
    vm.exporting = false;
    vm.exportInsufficientFunds = exportInsufficientFunds;
    vm.loadingInsufficientFunds = false;

    $scope.$watch('vm.selectedAccount', function(newVal, oldVal){
      if (angular.isDefined(newVal) && newVal !== oldVal) {
        var filter = vm.insufficientFundsTable.filter();

        if (newVal && newVal.id){
          filter.accountIds = [newVal.id];
        } else if (newVal === null){
          filter.accountIds = [];
        }

        vm.insufficientFundsTable.filter(filter);
      }
    });

    $scope.$on('dateOptionsSet', function() {
      if (!vm.insufficientFundsTable && FeatureFlags.isEnabled('insufficientFunds')) {
        init();
      }
    });


    function init () { 
      if (vm.insufficientFundsTable){
        vm.insufficientFundsTable.reload();
        return;
      }
      
      vm.insufficientFundsTable = new NgTableParams({
        page: 1,
        count: 25,
        sorting: {
          paidByAccountId: 'desc'
        },
      }, {
        getData: function (params) {
          var page = params.page();
          var size = params.count();
          var sorting = params.sorting();
          var filter = params.filter();
          var sort = _.keys(sorting).length ? _.keys(sorting)[0] : '';
          var direction = _.values(sorting).length ? _.values(sorting)[0] : '';

          var query = {
            pageNumber: page,
            pageSize: size,
            sortDescending: direction == 'desc' ? true : false,
            sortKey: sort,
          };

          // strip fields with null/undefined or empty string values
          query = _.pick(query, function(v){
            return v && v !== '' ;
          });

          _.extend(query, filter);

          vm.loadingInsufficientFunds = true;

          lastQuery = query;
          
          return $q.when(Billing.getInsufficientFundsAccounts(query))
                  .then(function(res) {
                    params.total(res.data.metaData.totalRecordCount);
                    var insufficientFundsAccounts = formatInsufficientFunds(res.data.data);
                    return insufficientFundsAccounts;
                  })
                  .catch(function(err) {
                    Dashboard.toastError(err.message, err);
                    $q.reject();
                  })
                  .finally(function(){
                    vm.loadingInsufficientFunds = false;
                  });
        },
      }
      );
      
    }

    function formatInsufficientFunds (data, exporting) {
      var insufficientFunds = data.map(function(insufficientFund){

        if (exporting) {
          insufficientFund = {
            "Account #": insufficientFund.accountNumber,
            "Account Name": insufficientFund.accountName,
            "Fee Calculated": insufficientFund.feeAmount.toFixed(2),
            "Available Funds": insufficientFund.cash.toFixed(2),
            "Cash Needed to Complete Fee": insufficientFund.difference.toFixed(2)
          };
        }

        return insufficientFund;
      });

      return insufficientFunds;
    }

    function exportInsufficientFunds () {
      var totalRecords = vm.insufficientFundsTable.total();
      var queryExport = lastQuery;
     
      queryExport.pageNumber = 1;
      queryExport.pageSize = totalRecords || 25;

      vm.exporting = true;

      $q.when(Billing.getInsufficientFundsAccounts(queryExport))
        .then(function(res) {
          
          var insufficientFunds = formatInsufficientFunds(res.data.data, true);
          var filename = 'insufficient-funds_records' + '.csv';

          Dashboard.JSONtoCSV(insufficientFunds, filename);
        })
        .catch(function(err) {
          Dashboard.toastError(err.message, err);
          $q.reject();
        })
        .finally(function(){
          vm.exporting = false;
        });
    }

    function clearAccount() {
      vm.selectedAccount = null;
    }

    function setAccount (account) {
      vm.selectedAccount = account;
    }

    function refresh () {
      if (vm.loadingInsufficientFunds) return;
      init();
    }

  }
});