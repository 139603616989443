angular.module('Directives')
.directive('spinner', spinner);

function spinner(){
	return {
		restrict: 'E',
		scope: {
			active: '=active',
			text: '@?text'
		},
		bindToController: true,
		controller: function(){},
		controllerAs: 'vm',
		template: '<span ng-show="vm.active"> <i class="fa fa-spinner fa-spin"></i> <span ng-if="vm.text">{{ vm.text }}</span></span>',
		link: function($scope, iElm, iAttrs, controller) {}
	};
}