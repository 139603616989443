angular.module('HarvestingModule')
.directive('fixedTableHeaders', ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $timeout(function () {
        
          var container = element.parentsUntil(attrs.fixedTableHeaders);
          element.stickyTableHeaders({ 
            scrollableArea: jQuery('.scrollable-area'),
            cacheHeaderHeight: true
          });

      }, 0);
    }
  };
}])
.directive('autoScale', ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs, ctrl) {
      var containerHeight = attrs.autoScale;
      var dataToWatch = attrs.watch;
      scope.$watch(dataToWatch, function(newVal, oldVal) {
        if (newVal && newVal.length > 10){
          angular.element('.scrollableContainer').height(containerHeight);
        } else {
          angular.element('.scrollableContainer').height('auto');
        }
      });
    }
  };
}])
.directive('fixedHeader', function($timeout){
  return {
      restrict: 'A',
      link: link
  };

  function link($scope, $elem, $attrs, $ctrl) {
    var elem = $elem[0];

    // wait for data to load and then transform the table
    $scope.$watch(tableDataLoaded, function(isTableDataLoaded) {
        if (isTableDataLoaded) {
            transformTable();
        }
    });

    $scope.$on('update-table-dimensions', function(){
      transformTable();
    });

    function tableDataLoaded() {
        // first cell in the tbody exists when data is loaded but doesn't have a width
        // until after the table is transformed
        var firstCell = elem.querySelector('tbody tr:first-child td:first-child');
        return firstCell && !firstCell.style.width;
    }

    function transformTable() {
      // reset display styles so column widths are correct when measured below
      angular.element(elem.querySelectorAll('thead, tbody, tfoot')).css('display', '');

      // wrap in $timeout to give table a chance to finish rendering
      $timeout(function () {
          // set widths of columns
          angular.forEach(elem.querySelectorAll('tr:first-child th'), function (thElem, i) {

              var tdElems = elem.querySelector('tbody tr:first-child td:nth-child(' + (i + 1) + ')');
              var tfElems = elem.querySelector('tfoot tr:first-child td:nth-child(' + (i + 1) + ')');

              var columnWidth = tdElems ? tdElems.offsetWidth : thElem.offsetWidth;
              if (tdElems) {
                  tdElems.style.width = columnWidth + 'px';
              }
              if (thElem) {
                  thElem.style.width = columnWidth + 'px';
              }
              if (tfElems) {
                  tfElems.style.width = columnWidth + 'px';
              }
          });

          // set css styles on thead and tbody
          angular.element(elem.querySelectorAll('thead, tfoot')).css('display', 'block');

          angular.element(elem.querySelectorAll('tbody')).css({
              'display': 'block',
              'height': $attrs.tableHeight || 'inherit',
              'overflow': 'auto'
          });

          // reduce width of last column by width of scrollbar
          var tbody = elem.querySelector('tbody');
          var scrollBarWidth = tbody.offsetWidth - tbody.clientWidth;
          if (scrollBarWidth > 0) {
              // for some reason trimming the width by 2px lines everything up better
              scrollBarWidth -= 2;
              var lastColumn = elem.querySelector('tbody tr:first-child td:last-child');
              lastColumn.style.width = (lastColumn.offsetWidth - scrollBarWidth) + 'px';
          }
      });
    }
  }
})
.controller('HarvestMonitorCtrl', function(ExclusionsFactory, SweetAlert, $filter, $q, ngTableEventsChannel, $uibModal, $timeout, $scope, HarvestingFactory, NgTableParams, Dashboard, toastr, $templateCache, PortfolioEditorFactory){
	
  var vm = this;
  var parentTab = $scope.tab; 
  var groupData = parentTab.data;
  var groupId = groupData ? groupData.id : null;
  var newExclusions = [];
  var accessAccounts = Dashboard.getAccessAccounts();

	vm.title = "Harvesting Opportunities";
  vm.setGroup = setGroup;
  vm.groupGoals = {
    shortTerm : groupData.shortTermGoal,
    longTerm: groupData.longTermGoal
  };
  vm.updatePnlBreakdown = updatePnlBreakdown;
  vm.updateGroupSelections = updateGroupSelections;
  vm.getOpportunitiesForSymbol = getOpportunitiesForSymbol;
  vm.showHarvestingTable = showHarvestingTable;
  vm.hideHarvestingTable = hideHarvestingTable;
  vm.submitHarvestTrades = submitHarvestTrades;
  vm.refreshOpportunities = refreshOpportunities;
  vm.openSharesModal = openSharesModal;
  vm.getClosedLotsForSymbol = getClosedLotsForSymbol;
  vm.selectedLots = [];
  vm.changingPages = false;
  vm.accountGroupTable = new NgTableParams({count: 9999}, { counts: [], dataset: [] });
  vm.closedLotsTable = new NgTableParams({count: 9999}, { counts: [], dataset: [] });
  vm.lotsForSymbolTable = new NgTableParams({count: 9999}, { counts: [], dataset: [{
    account: 'account1',
    date: new Date(),
    quantity: 25,
    costPerShare: 28,
    basis: 1000,
    value: 1000,
    ltGain: 1000,
    stGain: 1000,
    pnl: 1000
  }] });
  vm.taxLots = [];
  vm.groupedClosedLots = {};
  vm.groupedLots = [];
  vm.invalidLots = [];
  vm.updatePnlValues = updatePnlValues;

  vm.lotTypeModel = 'open';

  vm.cols = [{
    label: 'Ticker',
    key: 'symbol.ticker',
    width: '100px'
  },
  {
    label: 'Lot Count',
    key: 'lotCount',
    width: 'auto'
  },
  // {
  //   label: 'Account',
  //   key: 'account.name',
  //   width: '125px'
  // },
  // {
  //   label: 'Date',
  //   key: 'openedOn.timestamp',
  //   width: '143px'
  // },
  {
    label: 'Quantity',
    key: 'quantity',
    width: '120px'
  },
  // {
  //   label: 'Cost / Share',
  //   key: 'costBasis.price',
  //   width: '110px'
  // },
  // {
  //   label: 'Basis',
  //   key: 'costBasis.total'
  // },
  // {
  //   label: 'Value',
  //   key: 'currentValues.total'
  // },
  {
    label: 'Long Term Gain',
    key: 'longTermGains',
    width: '100px'
  },
  {
    label: 'Short Term Gain',
    key: 'shortTermGains'
  }];

  vm.expansionCols = [{
    label: 'Ticker',
    key: 'symbol.ticker',
    // width: '100px'
  },
  // {
  //   label: 'Account',
  //   key: 'account.name',
  //   // width: '125px'
  // },
  {
    label: 'Date',
    key: 'openedOn.timestamp',
    // width: '143px'
  },
  {
    label: 'Quantity',
    key: 'originalQuantity',
    // width: '120px'
  },
  {
    label: 'Harvest Qty',
    key: 'quantity',
    // width: '120px'
  },
  {
    label: 'Cost / Share',
    key: 'costBasis.price',
    // width: '110px'
  },
  {
    label: 'Basis',
    // key: 'costBasis.total'
  },
  // {
  //   label: 'Value',
  //   key: 'currentValues.total'
  // },
  {
    label: 'Gain / Loss',
    key: '',
    // width: '100px'
  },
  {
    label: 'Gain Type',
    key: ''
  }];

  vm.getSymbolSearchResults = getSymbolSearchResults;
  vm.onSubSymbolSelected = onSubSymbolSelected;

  // adjust the width when we expand and collapse rows
  vm.updateTableDimensions = function(){
    $timeout(function(){
      $scope.$broadcast("renderScrollableTable");
    },1);
  };

  vm.symbolInView = function(group, groups, symbol){
    var symbolIndex = _.findIndex(groups, function(group){
      return group.symbol.ticker == symbol;
    });
    var symbolInView = (group.index <= symbolIndex);
    return symbolInView;
  };

  vm.reverse = false;
  vm.propertyName = null;
  var sortFns = {
    'longTermGain' : function (row) {
      return; 
    }
  };
  vm.sortBy = function(propertyName) {
    vm.reverse = (vm.propertyName === propertyName) ? !vm.reverse : false;
    vm.propertyName = propertyName;
    
      vm.updateTableDimensions();
    
  };

  vm.lotsForSymbol = {};
  vm.updateVspQty = updateVspQty;
  vm.nonVspVerbiage = {
    content: "Certain brokers do not support Versus Specific Purchase (i.e. lot specified) trades via FIX. Additionally, if a position has used the \"Average Price\" lot disposal methodology historically, the broker may not support lot specified trading. If lot specified trading is not available for a particular position, SmartX will allow users to designate a quantity to harvest from the position. The user will see the estimated taxable impact based on the lot disposal methodology of the client account or position based on the quantity inserted.",
    title: "Non-Vsp Trading Information"
  };

  vm.washTradeVerbiage = {
    content: 'If the system has identified that a particular lot would create a wash sale if harvested, SmartX will not let users harvest taxes on that position until the wash sale period has been cleared.',
    title: 'Wash Trade Information'
  };

  vm.openedOnDateNullVerbiage = {
    content: 'This lot does not have an open date and cannot be selected. Lot specified trades require an open date to select the lot with your custodian.',
    title: 'Invalid Lot: No Open Date'
  };

  // vm.gridOptions = {
  //   enableSorting: true,
  //   columnDefs: [
  //     {name: 'Symbol', cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.symbol.ticker + "- (" + row.entity.lots.open + ")" }}</div>'},
  //     {name: 'Account', field: 'account.name'},
  //     {name: 'Date', field: 'openedOn.timestamp'},
  //     {name: 'Quantity', field: 'quantity'},
  //     {name: 'Cost / Share', field: 'costBasis.price'},
  //     {name: 'Basis', field: 'costBasis.total'},
  //     {name: 'Value', field: 'currentValues.total '},
  //     {name: 'LT Gain', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.gains.longTermGains | currency : "$"}}</div>'},
  //     {name: 'ST Gain', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.gains.shortTermGains  | currency : "$"}}</div>'},
  //     {name: 'PnL', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.gains.longTermGains + row.entity.gains.shortTermGains | currency : "$"}}</div>'}
  //   ],
  //   data: vm.symbolTotals,
  //   expandableRowTemplate: '<div ui-grid="row.entity.subGridOptions" style="height:150px;"></div>',
  //   expandableRowHeight: 150,
  //   //subGridVariable will be available in subGrid scope
  //   onRegisterApi: function (gridApi) {
  //     vm.gridApi = gridApi
  //     gridApi.expandable.on.rowExpandedStateChanged($scope, function (row) {
  //         if (row.isExpanded) {
  //           row.entity.subGridOptions = {
  //             columnDefs: [
  //             {name: 'Account', field: 'account.name'},
  //             {name: 'Date', field: 'openedOn.timestamp'},
  //             {name: 'Quantity', field: 'quantity'},
  //             {name: 'Cost / Share', field: 'costBasis.price'},
  //             {name: 'Basis', field: 'costBasis.total'},
  //             {name: 'Value', field: 'currentValues.total '},
  //             {name: 'LT Gain', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.gains.longTermGains | currency : "$"}}</div>'},
  //             {name: 'ST Gain', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.gains.shortTermGains  | currency : "$"}}</div>'},
  //             {name: 'PnL', enableSorting: true, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.gains.longTermGains + row.entity.gains.shortTermGains | currency : "$"}}</div>'}
  //           ]};

  //           var ticker = row.entity.symbol.ticker;

  //           if (vm.groupedClosedLots[ticker]){
  //             row.entity.subGridOptions.data = vm.groupedClosedLots[ticker];
  //           }

  //           var selectedGroup = vm.selectedGroup;
  //           var taxGroupId = selectedGroup ? selectedGroup.id : groupId
  //           vm.loadingSymbolLots = true;
  //           HarvestingFactory.getOpportunitiesForSymbol(taxGroupId, row.entity.symbol.id)
  //           .then(function(res){
  //             console.log("Positions for symbol: ", res.data);
  //             var response = res.data;
  //             var positions = response.data;

  //             vm.groupedClosedLots[ticker] = positions;
  //             row.entity.subGridOptions.data = positions;

  //             vm.loadingSymbolLots = false;
  //           })
  //           .catch(function(err){
  //             console.log("err: ", err);
  //             toastr.error("Error get opportunities for symbol");
  //             vm.loadingSymbolLots = false;
  //           });
  //         }
  //     });
  //   }
  // };

  ngTableEventsChannel.onPagesChanged(hackExpanded, $scope, vm.accountGroupTable);

  function hackExpanded(){
    vm.changingPages = true;

    $timeout(function(){
      vm.changingPages = false;
    }, 200);
  }

  vm.pnlBreakdown = new NgTableParams({
    count: 25,
    sorting: {
      name: "asc"
    }
  }, {
    counts: [],
    dataset: []
  });

  vm.harvestSymbols = new NgTableParams({
    count: 25,
    sorting: {
      name: "asc"
    }
  }, {
    counts: [10,50,100,200],
    dataset: []
  });

  vm.groupedLots = {};

  function formatOpportunities(opportunities) {
    var formattedOpportunities = opportunities.map(function(opportunity){
      var accountIds = opportunity.accountIds;
      var accountNumbers = _.chain(accessAccounts)
                            .filter(function(account){return _.contains(accountIds, account.id);})
                            .map('brokerageAccountNumber')
                            .value();
      opportunity.accountNumbers = accountNumbers;
      opportunity.longTermGains = opportunity.gains.longTermGains + opportunity.gains.longTermLosses;
      opportunity.shortTermGains = opportunity.gains.shortTermGains + opportunity.gains.shortTermLosses;
      return opportunity;
    });

    return formattedOpportunities;
  }
  
  init(groupId);

  function init(groupId){

    vm.loadingOpportunities = true;

    getTaxGroupExclusions(groupId);

    // populate opportunities table and 
    HarvestingFactory.getHarvestOpportunitiesForGroup(groupId)
    .then(function(res){
      console.log("res: ", res.data);
      vm.loadingOpportunities = false;

      var response = res.data;
      var harvestSymbols = response.data;


      vm.symbolTotals = formatOpportunities(harvestSymbols);
      vm.accountGroupTable.settings().dataset = vm.symbolTotals;
      vm.accountGroupTable.reload();

      // vm.gridOptions.data = vm.symbolTotals;
     
      if (vm.selectedGroup) updatePnlBreakdown();
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error getting harvest opportunities");
      Dashboard.toastError("Failed to load harvesting opportunities", err);
      vm.loadingOpportunities = false;
    });

    // populate the dropdown
    HarvestingFactory.getTaxGroups()
    .then(function(res){
      console.log(res);

      var response = res.data;
      var selectedGroup;

      vm.groups = response.data;
      
      selectedGroup = _.find(vm.groups, {id: groupId});
      vm.selectedGroup = selectedGroup ? selectedGroup : vm.groups[0];

      vm.groupGoals = {
        shortTerm : vm.selectedGroup.shortTermGoal,
        longTerm: vm.selectedGroup.longTermGoal
      };

      updatePnlBreakdown();
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error getting tax groups.");
      Dashboard.toastError("Failed to get tax groups", err);
      vm.loadingOpportunities = false;
    });
  }

  function getTaxGroupExclusions (groupId) {
    ExclusionsFactory.getExclusionsByTaxGroup(groupId)
    .then(function(res){
      vm.currentExclusions = res.data.data;
      console.log("Current Exclusions: ", vm.currentExclusions);
    })
    .catch(function(err){
      // console.error(err.message);
      Dashboard.toastError(err.message, err);
    });
  }

  function getClosedLotsForSymbol(group){
    if (vm.groupedClosedLots[group.symbol.ticker]){
      return;
    }

    var selectedGroup = vm.selectedGroup;
    vm.loadingSymbolLots = true;
    HarvestingFactory.getOpportunitiesForSymbol(groupId, group.symbol.id)
    .then(function(res){
      console.log("Positions for symbol: ", res.data);
      var response = res.data;
      var positions = response.data;

      vm.groupedClosedLots[group.symbol.ticker] = positions;

      vm.loadingSymbolLots = false;
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error get opportunities for symbol");
      Dashboard.toastError("Failed to load opportunities for the selected symbol", err);
      vm.loadingSymbolLots = false;
    });
  }

  function getOpportunitiesForSymbol(group){

    // if (!group.expanded && (!group.lotsForSymbol || !group.lotsForSymbol.length) && !group.lotDetails) {
    //   group.expanded = true;
    // }
    if (!group.expanded) return;
    // return if we already got the data for the expanded symbol
    if (group.lotsForSymbol && group.lotsForSymbol.length) {
      // updateGroupSelections(group.symbol.ticker, group);
      $scope.$broadcast('update-table-dimensions');
      return;
    }

    var selectedGroup = vm.selectedGroup;
    group.loadingSymbolLots = true;
    HarvestingFactory.getOpportunitiesForSymbol(selectedGroup.id, group.symbol.id)
    .then(function(res){
      console.log("Positions for symbol: ", res.data);
      var response = res.data;
      var positions = response.data/* .filter(function(group) {
          return group.canHarvest;
      })*/
      .map(function(group){
        group.lotDetails = group.lotDetails.filter(function(detail){
          return Math.abs(detail.quantity) >= 1; 
        });

        return group;
      });
      // .filter(function(group){
      //   if (!group.canVspTrade && group.lotDetails.length) {
      //     if (group.lotDetails[0].lotSequenceId != 1) return false
      //   } else {
      //     return true
      //   }
      // })

      console.log("Filtered lot groups: ", positions);
      // debugger;

      positions = positions.reduce(function(lots, lotGroup) {
        var detailsWithAccount = lotGroup.lotDetails.filter(function(detail){
          return Math.abs(detail.quantity) >= 1; 
        }).map(function(detail){
          detail.account = lotGroup.account;
          detail.symbol = lotGroup.symbol;
          detail.canVspTrade = lotGroup.canVspTrade;
          detail.canHarvest = lotGroup.canHarvest;
          detail.reasonCantHarvest = lotGroup.reasonCantHarvest;
          detail.quantity = detail.quantity > 0 ? Math.floor(detail.quantity) : Math.ceil(detail.quantity);
          return detail;
        });
        return lots.concat(detailsWithAccount);
      },[]);

      positions = _.sortBy(positions, 'lotSequenceId');

      console.log("Sorted Positions: ", positions);

      if (group.selectAll){
        positions.forEach(function(position){
          position.selected = true;
          vm.selectedLots.push(position);
        });
      }

      group.lotsForSymbol = positions.map(function(position){
        if (position.gains.longTermGains != 0 || position.gains.longTermLosses != 0){
          position.gainType = 'longTerm';
        } else if (position.gains.shortTermGains != 0 || position.gains.shortTermLosses != 0) {
          position.gainType = 'shortTerm';
        }

        position.totalGain = {
          value: getPositionPnl(position),
          type: position.gainType === 'longTerm' ? 'Long Term' : 'Short Term'
        };

        return position;
      });

      group.lotsByAccount = _.chain(positions)
                              .groupBy(function(position){
                                return position.account.id;
                              })
                              .mapObject(function(lots, accountId){
                                var obj = {
                                  lots: lots.map(function(lot){ lot.quantityToHarvest = ''; return lot;}),
                                  canHarvest: lots[0].canHarvest,
                                  reasonCantHarvest: lots[0].reasonCantHarvest,
                                  quantity: getTotalQuantityForLots(lots),
                                  vspQty: '',
                                };

                                return obj;
                              })
                              .value();

      console.log(group.lotsByAccount);

      updatePnlBreakdown();

      $scope.$broadcast('update-table-dimensions');
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error get opportunities for symbol");
      Dashboard.toastError("Failed to load opportunities for the selected symbol", err);
    })
    .finally(function(){
      $timeout(function(){
        group.loadingSymbolLots = false;

      },1);
    });
  }

  vm.getQuantityByAccount = getQuantityByAccount;

  vm.toggleExpanded = function (group){
    group.expanded = !group.expanded;
    // debugger;
  };

  function getQuantityByAccount(accountLots) {
    var nonWashable = accountLots.filter(function(lot){
      return lot.wouldWash = false;
    });

    return _.reduce(nonWashable, function(totalQty, lot){
      return totalQty += lot.quantity;
    }, 0);
  }

  function getPositionPnl (position) {
    if (position.gainType === 'shortTerm') {
      return position.gains.shortTermLosses + position.gains.shortTermGains;
    } else if (position.gainType === 'longTerm') {
      return position.gains.longTermLosses + position.gains.longTermGains;
    } else {
      return 0;
    }
  }

  vm.startsWith = function(symbol, viewValue) {
    return symbol.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
  };

  vm.onHoldCashChanged = function(group){
    if (group.holdCash){
      group.substitution = undefined;
    }
  };

  vm.onSubRequiredChanged = function(group) {
    if (!group.requireSubSymbol){
      group.subSymbolInput = undefined;
      group.substitution = undefined;
      vm.missingSubSymbol = false;
    }
    if (group.requireSubSymbol){
      group.subSymbolInput = undefined;
      vm.missingSubSymbol = true;
    }
  };

  vm.onSubSymbolInputChanged = function(group) {
    group.substitution = undefined;
    vm.missingSubSymbol = true;
  };

  vm.updateAddBackOnEnd = function(group){
    if (group.exclusion) {
      vm.updatingHarvestExclusion = true;
      ExclusionsFactory.update(group.exclusion.id, {addBackOnEnd: group.addBackOnEnd})
      .then(function(res){
        var updatedExclusion = res.data.data;
        var existingIndex = _.findIndex(vm.currentExclusions, function(exclusion){
          return exclusion.id === updatedExclusion.id;
        });

        vm.currentExclusions[existingIndex] = updatedExclusion;
      })
      .catch(function(err){
        // toastr.error(err);
        // console.error(err);
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){
        $timeout(function(){
          vm.updatingHarvestExclusion = false;
        });
      });
    }
  };

  vm.lotTypeFilter = function(row){

    var lotType = 'open';

    switch(lotType){
      case 'open':
        return (row.lots.open > 0);
      case 'closed':
        return (row.lots.closed > 0);
      default:
        return true;
    }
  };

  function updateGroupSelections(symbol, group){

    if (!group.lotsForSymbol) return;

    group.lotsForSymbol.forEach(function(lot){

      if (group.selectAll) lot.selected = true;
      else lot.selected = false;
    });

    updatePnlBreakdown();
  }

  function getCurrentValues(){
    var data = vm.accountGroupTable.settings().dataset;

    var currentValues = {
      longTerm: {
        unrealized: vm.selectedGroup.gains.unrealized.longTermGain + vm.selectedGroup.gains.unrealized.longTermLoss,
        realized: vm.selectedGroup.gains.realized.longTermGain + vm.selectedGroup.gains.realized.longTermLoss,
        get total(){
          return this.unrealized + this.realized;
        }
      },
      shortTerm: {
        unrealized: vm.selectedGroup.gains.unrealized.shortTermGain + vm.selectedGroup.gains.unrealized.shortTermLoss,
        realized: vm.selectedGroup.gains.realized.shortTermGain + vm.selectedGroup.gains.realized.shortTermLoss,
        get total(){
          return this.unrealized + this.realized;
        }
      }
    };

    // data.forEach(function(opportunity){
      
    //   currentValues.longTerm.unrealized += opportunity.gains.longTermGains;
    //   currentValues.longTerm.realized += (opportunity.gains.realized ? opportunity.gains.realized.longTerm : 0);

    //   currentValues.shortTerm.unrealized += opportunity.gains.shortTermGains;
    //   currentValues.shortTerm.realized += (opportunity.gains.realized ? opportunity.gains.realized.shortTerm : 0);

    // });

    return currentValues;
  }

  // update the row values when changing quantity
  function updatePnlValues(lot, group, refreshPnlSummary){

    if (!angular.isDefined(lot.quantityToHarvest) || (lot.quantityToHarvest == "" || lot.quantityToHarvest == '-' || !angular.isNumber(lot.quantityToHarvest * 1))) {
      lot.selected = false;
    } else {

      lot.selected = true;

      var quantity = lot.quantityToHarvest;
      var costBasisPrice = lot.costPerShare;
      var currentPrice = lot.currentPrice || lot.currentValues.price;

      // lot.costBasis.total = costBasisPrice * quantity;
      // lot.currentValues.total = currentPrice * quantity;
      
      lot.costValue = costBasisPrice * quantity;
      lot.currentValue = currentPrice * quantity;

      if (lot.gainType == 'longTerm'){
        //lot.gains.longTermGains = lot.currentValues.total - lot.costBasis.total;
        lot.gains.longTermGains = lot.currentValue - lot.costValue;

      } else if (lot.gainType == 'shortTerm'){
        //lot.gains.shortTermGains = lot.currentValues.total - lot.costBasis.total;
        lot.gains.shortTermGains = lot.currentValue - lot.costValue;
      }

      group.gains.longTermGains = _.reduce(group.lotsForSymbol, function(total, lot){

        total += lot.gains.longTermGains;
        return total;
      }, 0);

      group.gains.shortTermGains = _.reduce(group.lotsForSymbol, function(total, lot){

        total += lot.gains.shortTermGains;
        return total;
      }, 0);
    }

    if (refreshPnlSummary){
      updatePnlBreakdown();
    }
  }

  function updatePnlBreakdown(lot){
    console.log("updating pnl breakdowns...");

    // if (lot) {
    //   if (lot.selected) lot.quantityToHarvest = lot.quantity
    //   else lot.quantityToHarvest = ''
    // }

    var lots = _.filter(vm.symbolTotals, function(group){
      return angular.isDefined(group.lotsForSymbol);
    }).map(function(group){
      return group.lotsForSymbol;
    });

    var selectedLots = _.filter(_.flatten(lots), function(lot){
      if (!lot.selected) lot.quantityToHarvest = '';
      return lot.selected;
    });

    vm.selectedLots = selectedLots.map(function(lot){
      if (!lot.quantityToHarvest) lot.quantityToHarvest = lot.quantity;
      lot.gainLossPerShare = lot.currentPrice - lot.costPerShare;
      lot.gainLossToHarvest = {
        shortTerm: lot.gainType === 'shortTerm' ? lot.gainLossPerShare * lot.quantityToHarvest : 0,
        longTerm: lot.gainType === 'longTerm' ? lot.gainLossPerShare * lot.quantityToHarvest : 0
      };
      return lot;
    });

    vm.pnlBreakdownData = [];

    var aggregateData = {
      shortTerm: {
        name: 'Short Term PnL',
        estimatedChangeAmount: 0,
        details: {
          realized:  vm.selectedGroup.gains.realized.shortTermGain || vm.selectedGroup.gains.realized.shortTermLoss,
          unrealized: 0
        }
      },
      longTerm: {
        name: 'Long Term Pnl',
        estimatedChangeAmount: 0,
        details: {
          realized:  vm.selectedGroup.gains.realized.longTermGain || vm.selectedGroup.gains.realized.longTermLoss,
          unrealized: 0
        }
      }
    };

    var currentValues = getCurrentValues();

    var aggregatePnlData = _.reduce(vm.selectedLots, function(aggregate, lot){

      //aggregate.shortTerm.details.realized += lot.gains.realized.shortTerm;
      aggregate.shortTerm.details.unrealized += (lot.gains.shortTermGains || lot.gains.shortTermLosses);
      aggregate.shortTerm.estimatedChangeAmount += lot.gainLossToHarvest.shortTerm;

      //aggregate.longTerm.details.realized += lot.gains.realized.longTerm;
      aggregate.longTerm.details.unrealized += (lot.gains.longTermGains || lot.gains.longTermLosses);
      aggregate.longTerm.estimatedChangeAmount += lot.gainLossToHarvest.longTerm;
      return aggregate;
    }, aggregateData);

    aggregatePnlData = angular.extend(aggregatePnlData, {
      totals : {
        name: 'Total Pnl to be Harvested',
        shortTermPnl: aggregatePnlData.shortTerm.details.realized + aggregatePnlData.shortTerm.details.unrealized,
        longTermPnl: aggregatePnlData.longTerm.details.realized + aggregatePnlData.longTerm.details.unrealized
      }
    });

    console.log("Aggregate PNL Data: ", aggregatePnlData);

    var pnlBreakdown = {
      shortTerm: {
        name: 'Short Term P&L',
        current: currentValues.shortTerm.total,
        proposedChanges: aggregatePnlData.shortTerm.estimatedChangeAmount, // /*aggregatePnlData.shortTerm.details.realized +*/ aggregatePnlData.shortTerm.details.unrealized,
        goal: vm.groupGoals.shortTerm,
        get difference() {
          //return vm.groupGoals.shortTerm - this.proposedChanges
          return this.goal - this.proposedChanges;
        },
        details: {
          realized: {
            name: 'Realized',
            current: currentValues.shortTerm.realized,
            proposedChanges: aggregatePnlData.shortTerm.details.realized,
            goal: '-',
            difference: '-',
          },
          unrealized: {
            name: 'Unrealized',
            current: currentValues.shortTerm.unrealized,
            proposedChanges: aggregatePnlData.shortTerm.details.unrealized,
            goal: '-',
            difference: '-',
          }
        }
      },
      longTerm: {
        name: 'Long Term P&L',
        current: currentValues.longTerm.total,
        proposedChanges: aggregatePnlData.longTerm.estimatedChangeAmount, // /*aggregatePnlData.longTerm.details.realized +*/ aggregatePnlData.longTerm.details.unrealized,
        goal: vm.groupGoals.longTerm,
        get difference() {
          //return vm.groupGoals.longTerm - this.proposedChanges
          return this.goal - this.proposedChanges;
        },
        details: {
          realized: {
            name: 'Realized',
            current: currentValues.longTerm.realized,
            proposedChanges: aggregatePnlData.longTerm.details.realized,
            goal: '-',
            difference: '-',
          },
          unrealized: {
            name: 'Unrealized',
            current: currentValues.longTerm.unrealized,
            proposedChanges: aggregatePnlData.longTerm.details.unrealized,
            goal: '-',
            difference: '-',
          }
        }
      },
      // total: {
      //   name: 'Total Pnl to be Harvested',
      //   current: currentValues.shortTerm.total + currentValues.longTerm.total,
      //   proposedChanges: aggregatePnlData.totals.longTermPnl + aggregatePnlData.totals.shortTermPnl,
      //   goal: vm.groupGoals.shortTerm + vm.groupGoals.longTerm,
      //   get difference() {
      //     // return this.goal - this.proposedChanges
      //     return this.goal - this.proposedChanges;
      //   },
      // }
    };

    console.log(aggregatePnlData);

    vm.aggregatePnlData = pnlBreakdown;
  }

  function setGroup(group){
    vm.selectedGroup = group;

    vm.groupGoals = {
      shortTerm : group.shortTermGoal,
      longTerm: group.longTermGoal
    };

    getTaxGroupExclusions(group.id);

    // TODO: get the opportunities for the specific group
    vm.loadingOpportunities = true;
    HarvestingFactory.getHarvestOpportunitiesForGroup(group.id)
      .then(function(res){
        console.log("res: ", res.data);
        vm.loadingOpportunities = false;

        if (res.data.errorMessage){
          toastr.error(res.data.errorMessage);
          return;
        }

        var response = res.data;

        var harvestSymbols = response.data;

        vm.symbolTotals = harvestSymbols;
        vm.accountGroupTable.settings().dataset = vm.symbolTotals;
        vm.accountGroupTable.reload();
        vm.selectedLots = [];
        vm.groupedLots = {};

        updatePnlBreakdown();
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error("Error getting harvest opportunities");
        Dashboard.toastError("Failed to load harvesting opportunities", err);
        vm.loadingOpportunities = false;
      });
  }

  function showHarvestingTable(){
    console.log("starting harvest..");
    vm.showingHarvestingTable = true;

    vm.selectedLots.forEach(function(lot){
      lot.harvestStart = moment().unix();
      lot.washDateEnd = moment().add(31, 'days').unix();
    });

    vm.lotsGroupedBySymbol = _.groupBy(vm.selectedLots, function(lot){
      return lot.symbol.ticker;
    });
    console.log("lots grouped by symbol: ", vm.lotsGroupedBySymbol);

    var harvestGroups = [];

    _.each(vm.lotsGroupedBySymbol, function(lots, symbol){
      var exclusion = getExclusionForGroup(symbol);
      var lotsForSymbol = {
        symbol: lots[0].symbol,
        lots: lots,
        addBackOnEnd: exclusion ? exclusion.addBackOnEnd : false,
        holdCash: exclusion ? exclusion.subToCash : false,
        exclusion: exclusion || null,
        requireSubSymbol: false,
        subSymbolInput: null
      };

      if (exclusion && exclusion.subToSymbol && exclusion.subToSymbol.id) {
        lotsForSymbol.substitution = exclusion.subToSymbol;
        lotsForSymbol.requireSubSymbol = true;
      }

      harvestGroups.push(lotsForSymbol);
    });

    vm.harvestGroups = harvestGroups;
  }

  function getExclusionForGroup(symbol){
    // var symbolGroup = _.find(vm.symbolTotals, function(symbolGroup){
    //   return symbolGroup.symbol.ticker === symbol;
    // });
    var groupAccountIds = _.uniq(
                            _.flatten(
                              _.map(vm.symbolTotals, function(symbolGroup){
                                return symbolGroup.accountIds;
                              })
                            )
                          );

    

    var exclusion = _.find(vm.currentExclusions.concat(newExclusions), function(exclusion){
      return exclusion.exclusionKind === 'taxGroupClosingOnly' && _.contains(groupAccountIds, exclusion.account.id) && symbol === exclusion.symbol.ticker;
    });

    return exclusion;
  }

  function hideHarvestingTable(){
    vm.showingHarvestingTable = false;
  }

  function updateVspQty (group, accountLots) {

    console.log(accountLots);

    group.vspQty = group.vspQty > group.quantity ? group.quantity : group.vspQty;

    // rounddd
    if (group.vspQty > 0) group.vspQty = Math.floor(group.vspQty);
    else if (group.vspQty < 0) group.vspQty = Math.ceil(group.vspQty);

    var lotsAffected = 0;
    var sharesFromLots = 0;
    var vspQuantity = group.vspQty;
    var nonVspLots = group.lots.filter(function(lot){ return !lot.canVspTrade; });

    group.lots = group.lots.sort(function(lot1, lot2){
      return lot1.sequenceId - lot2.sequenceId;
    })
    .map(function(lot, index){

      // no fractionals
      // lot.quantity = lot.quantity > 0 ? Math.floor(lot.quantity) : Math.ceil(lot.quantity)
      if (!lot.quantity) return;
      
      if (!lot.canVspTrade){ 
        lot.quantityToHarvest = '';
        if ((vspQuantity - sharesFromLots) > lot.quantity) {
          // lotsAffected += 1
          sharesFromLots += lot.quantity;
          lot.quantityToHarvest = lot.quantity; //  > 0 ? Math.floor(lot.quantity) : Math.ceil(lot.quantity)
          if (lot.quantityToHarvest && lot.quantityToHarvest != '') lotsAffected += 1;
        } else if ((vspQuantity - sharesFromLots) <= lot.quantity){
          if (index === 0) {
            if (vspQuantity > 0) {
              // lotsAffected += 1
              var quantityToHarvest = vspQuantity >= lot.quantity ? lot.quantity : (vspQuantity - sharesFromLots);
              lot.quantityToHarvest = quantityToHarvest;  // > 0 ? Math.floor(quantityToHarvest) : Math.ceil(quantityToHarvest)
              sharesFromLots += lot.quantityToHarvest;
              if (lot.quantityToHarvest && lot.quantityToHarvest != '') lotsAffected += 1;
            }
          } else if (vspQuantity > sharesFromLots) {
            var quantityToHarvest = vspQuantity - sharesFromLots;
            lot.quantityToHarvest = quantityToHarvest; // >= lot.quantity ? Math.floor(quantityToHarvest) : Math.ceil(quantityToHarvest)
            sharesFromLots += quantityToHarvest;
            if (lot.quantityToHarvest && lot.quantityToHarvest != '') lotsAffected += 1;
          }
        } else {
          lot.quantityToHarvest = 0;
        }
        // this triggers the ui checkbox to be selected
        // we are using the index + 1 because lotSequenceId is not always sequential.  This results in an issue like the one seen here TS-346
        lot.selected = lotsAffected >= index + 1; // lot.lotSequenceId;
      }

      return lot;
    });

    // recalculate the selected lots
    updatePnlBreakdown();

    // group.lotsForSymbol = group.lotsForSymbol.map(function(lot, index){
    //   if (!lot.canVspTrade){
    //     lot.selected = lotsAffected >= lot.lotSequenceId
    //   }
    //   return lot
    // })
  }

  vm.getShareCount = getShareCount;
  function getShareCount(lots){
    var totalShares = _.reduce(lots, function(total, lot){
      var lotShareCount = isNaN(Number(lot.quantityToHarvest)) ? 0 : Number(lot.quantityToHarvest);
      return total += lotShareCount;
    }, 0);

    return totalShares;
  }

  vm.calculateTotalShares = function(lots){
    return _.reduce(lots, function(total, lot){

      if (!angular.isDefined(lot.quantityToHarvest) || lot.quantityToHarvest == "" || lot.quantityToHarvest == '-' || !angular.isNumber(lot.quantityToHarvest * 1)) return total;

      total += Number(lot.quantityToHarvest);

      return total;
    }, 0);
  };

  function getTotalQuantityForLots (lots) {
    var qty = lots.reduce(function(total, lot) { return total += lot.quantity; }, 0 );
    return qty > 0 ? Math.floor(qty) : Math.ceil(qty); // round down to nearest qty
  }

  vm.isShareCountValid = function(updatedShareCount, originalShareCount){

      if (!updatedShareCount) return true; // bypass empty input

      // validate string
      if (typeof updatedShareCount == 'string' && updatedShareCount.length) {
        updatedShareCount = updatedShareCount.replace(/,/g, ''); // clean input.  1,000 -> 1000
        if (isNaN(parseFloat(updatedShareCount))) return false;
      }

      // compare input with lot quantity
      return updatedShareCount > 0 && Math.abs(updatedShareCount) <= Math.abs(originalShareCount);
  };

  function openSharesModal(lots){
    // TODO Show list of lots in modal with editable share counts
    var modalInstance = $uibModal.open({
      animation: true,
      template: $templateCache.get('shareCountModal.tpl.html'),
      controller: function($uibModalInstance){

        var vm = this;

        vm.lots = lots;
        vm.submit = submit;
        vm.cancel = cancel;
        vm.shareCountIsValid = function(lots){
          
          var invalidRows = lots.filter(function(lot){

            if (isNaN(parseInt(lot.updatedShareCount))){
              return false;
            }

            if (lot.quantity > 0) return lot.quantity < lot.updatedShareCount || lot.updatedShareCount < 0;
            else return lot.quantity > lot.updatedShareCount || lot.updatedShareCount > 0;
          });

          vm.sharesInvalid = invalidRows.length ? true : false;
        };

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(lots){
          $uibModalInstance.close(lots);
        }

      },
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(lots) {
      // hmmm
      lots.forEach(function(lot){
        if (lot.updatedShareCount){
          lot.quantity = Number(lot.updatedShareCount);
        }
      });
    });
  }

  function submitHarvestTrades(){
    console.log('vm.missingSubSymbol', vm.missingSubSymbol);
    console.log(vm.harvestGroups);

    // check for invalid sub symbol inputs
    // shouldn't happen - just a fallback
    var groupsWithMissingSubs = vm.harvestGroups.filter(function(group) {
      return group.requireSubSymbol === true && (!group.substitution || !group.substitution.id);
    });
    if (groupsWithMissingSubs.length) {
      console.error('Symbol lots missing a required substitution:', groupsWithMissingSubs);
      Dashboard.toastError("One or more symbols are missing a required substitution");
      return;
    } else {
      console.log('no invalid substitution symbols');
    }  
  
    var substitutions = [];
    //var symbolLots = _.flatten(_.values(vm.lotsGroupedBySymbol));
    var symbolGroups = vm.harvestGroups;
    var harvestLots = symbolGroups.reduce(function(totalLots, group){
      var lots = group.lots.map(function(lot){
        if (group.substitution){
          lot.holdCash = false;
          lot.substitution = group.substitution;
        }
        lot.subToCash = group.holdCash;
        lot.addBackOnEnd = group.addBackOnEnd;
        return lot; /* {
          openLotId: lot.id,
          shares: isNaN(Number(lot.quantity)) ? 0 : Number(lot.quantity)
        } */
      });

      totalLots = totalLots.concat(lots);
      return totalLots;
    }, []);

    substitutions = symbolGroups.filter(function(group){
        return angular.isDefined(group.substitution);
      })
      .map(function(group){
        return {
          toSubstituteSymbolId: group.symbol.id,
          toSubstituteTicker: group.symbol.ticker,
          substituteWithSymbolId: group.substitution.Id || group.substitution.id,
          substituteWithSymbolTicker: group.substitution.AssetName || group.substitution.ticker,
          addBackOnEnd: group.addBackOnEnd || false,
          holdCash: group.holdCash || false
        };
      });
    
    var payload = {
      "harvestLots": _.flatten(harvestLots),
      "substitutions": substitutions
    };

    SweetAlert.swal({
       title: "Are you sure?",
       text: "You are about to harvest <strong>" + payload.harvestLots.length + " lot(s)</strong> from <strong>" + vm.selectedGroup.name + "</strong>.",
       html: true,
       type: "warning",
       showCancelButton: true,
       allowOutsideClick: true,
       confirmButtonColor: "#DD6B55",
       confirmButtonText: "Confirm",
       showLoaderOnConfirm: true,
       closeOnConfirm: true}, 
    function(isConfirm){

      if (isConfirm){
        vm.submittingHarvestRequest = true;
        submitHarvestRequestParallel(vm.selectedGroup.id, payload.harvestLots); 
      }
    });
  }

  function onSubSymbolSelected(item, model, label, group){
    vm.selectedSubSecurity = item;
    group.substitution = item;
    if (group.substitution && group.substitution.id) {
      vm.missingSubSymbol = false;
    }
	}

	function getSymbolSearchResults(term, type, group) {
			
		term = term.toLowerCase();

    group.loadingSubSymbolResults = true;

		return PortfolioEditorFactory.getSymbols(term, 'equity,mutualFund')
		.then(function(response) {
			console.log(response);
			var results = response.data.data;
			return results.slice(0, 15).map(function(symbol){ symbol.assetType = $filter('formatStatus')(symbol.assetType); return symbol; });
		})
		.catch(function(err){
      // console.error(err);
      // toastr.error(err.message);
      Dashboard.toastError(err.message, err);
			return [];
		})
		.finally(function(){
			$timeout(function() {
        group.loadingSubSymbolResults = false;
      });
		});
	}
  
  // function processLots (taxGroupId, lots) {
  //   var lotsBySymbol = _.groupBy(lots, function(lot){
  //     return lot.symbol.ticker
  //   })

  //   var lotGroups = _.values(lotsBySymbol);
  //   var lotGroupSymbols = _.keys(lotsBySymbol);



  //   submitHarvestRequest(vm.selectedGroup.id, payload.harvestLots)
  // } 

  function checkForExclusion (exclusions, exclusion) {
    var symbolId = exclusion.symbolId;
    var accountId = exclusion.accountId;

    return _.find(exclusions, function(_exclusion){
      return _exclusion.symbol.id == symbolId && _exclusion.account.id === accountId;
    });
  }

  function createHarvestExclusion (taxGroupId, payload) {
    var promise = new Promise(function(resolve, reject){
      var existingExclusion = getExclusionForGroup(payload.ticker);
      if (existingExclusion) {
        return resolve(existingExclusion);
      } else {
        return HarvestingFactory.createTaxLotHarvestExclusion (payload)
        .then(resolve)
        .catch(reject);
      }
      
    });

    return promise;
  }

  function createHarvestRequest (taxGroupId, lots) {
    return function (exclusionResponse) {
      var newExclusion = exclusionResponse.data ? exclusionResponse.data.data : exclusionResponse; // handle the api response or the existing value
      newExclusions.push(newExclusion);
      lots = lots.filter(function(lot){
        return lot.symbol.ticker === newExclusion.symbol.ticker;
      });
      var lotsByAccount = _.groupBy(lots, function(lot){
        return lot.account.id;
      });

      var harvestRequestPayload = {
        taxGroupId: taxGroupId,
        accountSymbolExclusionRuleId: newExclusion.id,
        accountHarvestRequests: _.map(lotsByAccount, formatHarvestRequestAccount(newExclusion))
      };

      console.log("Harvest Request Payload: ", harvestRequestPayload);

      return sendHarvestRequest(harvestRequestPayload);
    };
  }

  function sendHarvestRequest (payload) {
    var promise = new Promise(function(resolve, reject) {
      return HarvestingFactory.submitHarvestTrades(payload)
        .then(resolve)
        .catch(function(err){
          // ExclusionsFactory.delete(payload.accountSymbolExclusionRuleId) // if the harvest request fails, delete the exclusion that was created
          reject(err);
        });
    });

    return promise;
  }

  function formatHarvestRequestAccount(exclusion) {
    return function (lotGroup, accountId) {
      var totalQuantity = _.reduce(angular.copy(lotGroup), function(totalQuantity, lot){
        var quantityToHarvest = angular.copy(lot.quantityToHarvest);
        if (lot.quantity > 0) quantityToHarvest *= -1;
        var quantity = quantityToHarvest < 0 ? Math.ceil(quantityToHarvest) : Math.floor(quantityToHarvest);
        return quantity += totalQuantity;
      }, 0);

      return {
        accountSymbolExclusionRuleId: exclusion.id,
        accountId: accountId,
        ticker: lotGroup[0].symbol.ticker,
        symbolId: lotGroup[0].symbol.id,
        quantity: totalQuantity,
        harvestType: lotGroup[0].canVspTrade ? 'lot' : 'lotSequence',
        lotHarvestRequests: _.map(lotGroup, function (lot) {
          var quantityToHarvest = totalQuantity < 0 ? lot.quantityToHarvest * -1 : lot.quantityToHarvest * 1;
          return {
            ticker: lot.symbol.ticker,
            symbolId: lot.symbol.id,
            vspDate: lot.openedOn.friendly,
            vspPrice: lot.vspPrice,
            costBasisPerShare: lot.costPerShare,
            isLongTerm: (lot.gains.longTermGains || lot.gains.longTermLosses) ? true : false,
            quantity: quantityToHarvest < 0 ? Math.ceil(quantityToHarvest) : Math.floor(quantityToHarvest) // round down as per marc v.
          };
        })
      };
    };
  }

  function submitHarvestRequestParallel (taxGroupId, lots, errors) {
    var lotsBySymbol = _.groupBy(lots, function(lot){
      return lot.symbol.ticker;
    });

    console.log(lotsBySymbol);

    var errors = [];
    var completed = 0;

    // loop through each symbol
    _.each(lotsBySymbol, function (lots, ticker){
      // group the lots for symbol by account in order to create a symbol exclusion for each account
      lot = lots[0];

      debugger;
      var harvestGroup = _.find(vm.harvestGroups, function(group){ return group.symbol.ticker === ticker; });

      harvestGroup.processing = true;
      harvestGroup.requestError = null;

      var exclusionPayload = {
        accountId: lot.account.id,
        ticker: lot.symbol.ticker,
        symbolId: lot.symbol.id,
        subToTicker: lot.substitution ? lot.substitution.ticker : null,
        subToSymbolId: lot.substitution ? lot.substitution.id : null,
        subToCash: lot.subToCash,
        startDate: moment().format("MM/DD/YYYY"),
        endDate: null,
        addBackOnEnd: lot.addBackOnEnd || false
      };

      // create the account exclusion and use the response to pass the exclusion id to the tax harvest request
      // harvestRequest process the selected lots for the symbol/account combination
      createHarvestExclusion(taxGroupId, exclusionPayload)
        .then(createHarvestRequest(taxGroupId, lots))
        .then(function(response){
          console.log("Harvest Request Response: ", response.data.data);
        toastr.success("Harvest request completed for ", exclusionPayload.ticker);
          harvestGroup.complete = true;
      })
      .catch(function(err){
        var error = {
          symbol: exclusionPayload.ticker,
          message: err.message
        };
        errors.push(error);

        harvestGroup.requestError = error.message;

        // toastr.error(err.message);
        // console.error(err);
        Dashboard.toastError(err.message, err);
      })
      .finally(function(){

        completed++;

        harvestGroup.processing = false;

        if (completed === _.keys(lotsBySymbol).length){
          vm.submittingHarvestRequest = false;
          if (errors.length) {

            // reject(errors.join('\n'))
            // SweetAlert.swal({
            //   title : errors.map(function(_error){ return 'Error processing harvest request for ' + _error.symbol }).join('\n') || 'Error processing Request. Please try again.',
            //   type : "warning"
            // });
          } else {
            hideHarvestingTable();
            refreshOpportunities();
            vm.selectedLots = [];
            vm.harvestGroups = [];
            SweetAlert.swal({
              title : 'Request was received.  Your trades are being placed.',
              type : "success"
            });
          }
        }
      });
      // })        
    });
  }

  function submitHarvestRequest(taxGroupId, lots, errors) {
    var lot;
    errors = errors || [];
    // var promise = new Promise(function(resolve, reject){
      if (lots.length) {
        lot = lots[0];
        console.log(lot);

        // var lotsBySymbol = _.groupBy(lots, function(lot){
        //   return lot.symbol.ticker
        // })
        var exclusionPayload = {
          accountId: lot.account.id,
          ticker: lot.symbol.ticker,
          symbolId: lot.symbol.id,
          subToTicker: null,
          subToSymbolId: null,
          startDate: moment().format("MM/DD/YYYY"),
          endDate: null,
          addBackOnEnd: false
        };
  
        createHarvestExclusion (taxGroupId, exclusionPayload)
        .then(createHarvestRequest(taxGroupId, lots))
        .then(function(response){
          console.log("Harvest Request Response: ", response.data.data);
        })
        .catch(function(err){
          errors.push({
            symbol: exclusionPayload.ticker,
            message: err.message
          });
          // toastr.error(err.message);
          // console.error(err);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          var unprocessedAccountLots = lots.filter(function(_lot){
            return _lot.account.id != lot.account.id || _lot.symbol.ticker != exclusionPayload.ticker;
          });
          if (unprocessedAccountLots.length) {
            submitHarvestRequest(taxGroupId, unprocessedAccountLots, errors);
          } else {
            vm.submittingHarvestRequest = false;
            if (errors.length) {
              // reject(errors.join('\n'))
              SweetAlert.swal({
                title : errors.map(function(_error){ return 'Error processing harvest request for ' + _error.symbol; }).join('\n') || 'Error processing Request. Please try again.',
                type : "warning"
              });
            } else {

              refreshOpportunities();
              SweetAlert.swal({
                title : 'Request was received.  Your trades are being placed.',
                type : "success"
              });
            }
          }
        });
   
      }
    /*})

    return promise; */
  }

  // $scope.$watch('vm.symbolTotals', function(newVal, oldVal) {
  //   if (newVal && newVal != oldVal && newVal.length > 10){
  //     $timeout(function(){
  //       var visible = jQuery('.scrollableContainer').length
  //       jQuery('.scrollableContainer').height('450px')
  //     })
  //   } else {
  //     jQuery('.scrollableContainer').height('auto');
  //   }
  // })
  $scope.$watch('vm.selectedLots', function(newVal, oldVal) {
    console.log(newVal);
    // validate that the quantityToHarvest is valid for each lot
    if (newVal && newVal !== oldVal) {
      vm.invalidLots = vm.selectedLots.filter(function(lot) {
        return !vm.isShareCountValid(lot.quantityToHarvest, lot.quantity);
      });
    }
  }, true);

  function refreshOpportunities(){

    var groupId = vm.selectedGroup.id;

    vm.accountGroupTable.settings().dataset = [];
    vm.loadingOpportunities = true;

    HarvestingFactory.getHarvestOpportunitiesForGroup(groupId)
    .then(function(res){
      console.log("res: ", res.data);
      vm.loadingOpportunities = false;
      if (res.data.errorMessage){
        toastr.error(res.data.errorMessage);
        return;
      }

      var response = res.data;

      var harvestSymbols = formatOpportunities(response.data);

      vm.symbolTotals = [];
      vm.filteredGroups = [];

      // clear out existing stuff from the table
      vm.symbolTotals = harvestSymbols.map(function(symbol){
        symbol.lotsForSymbol = [];
        symbol.expanded = false;
        return symbol;
      });
      
      vm.accountGroupTable.settings().dataset = vm.symbolTotals;
      vm.accountGroupTable.reload();

      // vm.gridOptions.data = vm.symbolTotals;
      // vm.gridApi.core.refresh()

      vm.selectedLots = [];
      updatePnlBreakdown();
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error getting harvest opportunities");
      Dashboard.toastError("Failed to load harvesting opportunities", err);
      vm.loadingOpportunities = false;
    });
  }
})
.directive;