angular.module('Directives')
.directive('smartxAccountLookupAsync', ['$templateCache', '$filter', '$q', 'toastr', function($templateCache, $filter, $q, toastr){
	return {
		restrict: 'E',
		scope: {
			onSelect: '=onSelect',
			selected: '=selected',
			onClear: '=onClear',
			showNoResults: '=showNoResults',
			textAlign: '@',
			excludeSma: '=',
			permissions: '@',
			status: '@status',
			placeholder: '@'
		},
		template: $templateCache.get('lookupAsync.tpl.html'),
		controller: function($scope, SmartXFactory, toastr) {

			var defaultPlaceholder = "Enter an account number";

			$scope.getResults = getResults;
			$scope.noResults = false;

			if (!$scope.placeholder) {
				$scope.placeholder = defaultPlaceholder;
			}
      
			function getResults (value) {

				$scope.noResults = false;

				var query = {
					acctNumber: value && value.replace(/-/g,''),
					permission: $scope.permissions ? $scope.permissions : 'fullAccess',
					status: $scope.status ? $scope.status : 'active',
					pageSize: 10,
					internalType: angular.isDefined($scope.excludeSma) && $scope.excludeSma ? 'uma' : ''
					// modelRestricted: false
				};

				return $q.when(SmartXFactory.getAccountsWithMetadata(query))
				.then(function(res){
								if (res.accounts && !res.accounts.length) {
									$scope.noResults = true;
								}
					return res.accounts;
				})
				.catch(function(err){
					toastr.error(err.message);
				});
			}

			$scope.th_account = {
				selected: angular.isDefined($scope.selected) ? $filter('sanitizeSmartXAccountName')($scope.selected.name, 'name') : undefined
			};

			// keep the displayed account name in sync when it changes in the parent controller
			$scope.$watch('selected', function(newVal, oldVal){
				if (angular.isDefined(newVal)){
					$scope.th_account.selected = $filter('sanitizeSmartXAccountName')(newVal.name, 'name');
					// $scope.onSelect(newVal);
				}
			});

			$scope.$on('exclusionAccountAdded', function(){
				$scope.th_account.selected = '';
			});
			
			$scope.$on('accountLookup::clear', function(){
				$scope.th_account.selected = '';
			});
		}
	};
}]);