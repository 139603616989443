var Builder = angular.module('BuilderCtrl', ['correlationMatrix']);
Builder.controller('BuilderCtrl', [
  '$scope',
  '$window',
  '$rootScope',
  '$http',
  'Dashboard',
  'Tabs',
  'SweetAlert',
  '$timeout',
  'SearchFilters',
  '$q',
  'Builder',
  '$templateCache',
  '$interpolate',
  '$uibModal',
  '$log',
  'Models',
  '$cacheFactory',
  '$sce',
  'EDITOR_ENABLED',
  'USER',
  'SmartXFactory',
  'toastr',
  '$filter',
  'EnterpriseFactory',
  'FeatureFlags',
  'Amplitude',
  'DRUPAL_API_URL',
  function (
    $scope,
    $window,
    $rootScope,
    $http,
    Dashboard,
    Tabs,
    SweetAlert,
    $timeout,
    SearchFilters,
    $q,
    Builder,
    $templateCache,
    $interpolate,
    $uibModal,
    $log,
    Models,
    $cacheFactory,
    $sce,
    EDITOR_ENABLED,
    USER,
    SmartXFactory,
    toastr,
    $filter,
    EnterpriseFactory,
    FeatureFlags,
    Amplitude,
    DRUPAL_API_URL
  ) {
    var chart;
    var tabType = $scope.tab.type;
    var modelApi = SmartXFactory.getModelAPI();
    var cmsApi = SmartXFactory.getCmsAPI();
    var getYTD = Builder.getYTD;
    var reportedModelList = Models.getReportedModelList();
    var staticModelIds = Models.getModelList()
      .filter(function (model) {
        return model.isStatic;
      })
      .map(function (model) {
        return model.id;
      });
    var performanceApi = SmartXFactory.getPerformanceAPI();
    var backtestParams = {};

    $scope.clearSearchString = function () {
      document.getElementById('searchString').value = '';
    };
    $scope.getSearchString = function () {
      const searchStringEl = document.getElementById('searchString');
      const searchClearEl = document.getElementById('searchClear');
      if (!searchStringEl) return '';
      if (searchStringEl.value.length > 0) searchClearEl.style.display = 'block';
      else searchClearEl.style.display = 'none';
      return searchStringEl.value;
    };

    var topLevelEnterpriseId = EnterpriseFactory.getTopLevelEnterpriseId();
    var userEnterprise = EnterpriseFactory.getUserEnterprise();

    $scope.benchmarks = Builder.benchmarks;
    $scope.selectedBenchmark = 'BF6BE7BA-0CE1-40A9-8225-2354841D601C'; // default to S&P 500 TR

    $scope.cashComponent = 100;

    $scope.leverageEnabled = !FeatureFlags.isEnabled('noModifyLeverage');
    $scope.createTargetEnabled = !FeatureFlags.isEnabled('noTargetFromProposal');
    $scope.resetSchemaBuilder = resetSchemaBuilder;

    $scope.$watch('schemaTotal', function (newVal, oldVal) {
      if (angular.isDefined(newVal)) {
        var cashComponent = math.format(
          Number(math.format(100 - newVal, { precision: 14 })),
          { precision: 14 }
        );
        $scope.cashComponent = $filter('number')(cashComponent, 2);
      }
    });

    init();

    function showMore() {
      $scope.modelListLimit += 50;
    }

    function init() {
      // init
      $scope.MAX_SCHEMA_SIZE = 10;
      $scope.modelListLimit = 50;
      $scope.batch = [];
      $scope.portfolio = {};
      $scope.schemaName;
      $scope.schemaTotal = 0;
      $scope.availableSelected = 0;
      $scope.minimumInvestment = 0;
      $scope.schemaRemaining = 100;
      $scope.userSchemas = [];
      $scope.userSchemasF = [];
      $scope.selectedSchema = [];
      $scope.schemaNames = [];
      $scope.selectedRows = []; // for storing a copy of the selected rows from the search tab
      $scope.feesAndMinimums = [];
      $scope.generatingReport = false;
      $scope.months = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ];
      $scope.customAdvisorFee = null;
      $scope.editorEnabled = EDITOR_ENABLED;
      $scope.modelNames = {};
      $scope.activeFilters = []; // For tracking the active filters on the model list (not complete)
      //$scope.customFeeActive = false;

      $scope.addToList = addToList;
      $scope.updateSelectedModelTotal = updateSelectedModelTotal;
      $scope.setSchemaAmountsEqual = setSchemaAmountsEqual;
      $scope.calculateTotal = calculateTotal;

      $scope.dndModelsClean = {};
      $scope.dndModelsLastSelected = null;
      $scope.dndModels = {
        selected: null,
        lists: {
          'Available Models': [],
          'Selected Models': [],
        },
      };

      $scope.dropdownStatus = {
        isopen: false,
      };

      $scope.isOpen = false;

      $scope.moreInfo = {
        title: '',
        content:
          'Our algorithm takes the minimum allocation value of each model and the percentage contribution to your portfolio to create the minimum investment size of the portfolio.',
        placement: 'top',
      };

      $scope.chartViewToggle = {
        options: ['Aggregate', 'Individual'],
        selected: 'Aggregate', // toggle state is tied to this value
      };

      $scope.leverageOptions = Builder.leverageOptions;

      // var popoverMsg = "Total should not exceed 100%";
      $scope.popoverHTML = $sce.trustAsHtml(
        '<span class="warning-popover" style="color: black;">Total should not exceed 100%</span>'
      );

      // used to look up the name when plotting individual models
      // key : model rid, value : model title
      $scope.modelNames = {};

      $scope.chartLoaded = false;
      $scope.chartLoading = false;
      $scope.validSchemeName = true;
      $scope.chartLoadingMsg = 'Building your custom portfolio...';

      $scope.portfolioChart = {
        title: {
          text: null,
          style: {
            display: 'none',
          },
        },
      };
      $scope.showSimulatedPortfolio = false;
      $scope.showSimulatedPortfolioBtnTxt = 'Show';

      if ($scope.$parent.tab.active) {
        $scope.visited = true;
      } else {
        $scope.visited = false;
      }

      $scope.chartStates = ['Aggregate', 'Individual'];
      $scope.portfolio = {
        chartView: 'Aggregate',
      };

      $scope.getModelData = getModelData;
      $scope.showMore = showMore;

      $scope.getModelData();
    }

    // turn visibility of Leverage on or off
    $scope.toggleLeverage = function () {
      $scope.leverageEnabled = !$scope.leverageEnabled;
    };

    // jQuery('html').on('click', function(e) {
    //   var $element = jQuery(e.target);
    //   if (!$element.hasClass('popover-trigger') && !$element.hasClass('popover-content')) {
    //     $timeout(function() {
    //       $scope.isOpen = false;
    //     });
    //   }
    // });

    /* event listeners */
    $scope.autoBuildPDF = false;
    $scope.$on('editPortfolio', function (event, payload) {
      $scope.resetSchemaBuilder();
      $timeout(function () {
        if (payload.type && payload.type === 'target') {
          $scope.schemaName = payload.name;
          $scope.autoBuildPDF = payload.pdf ? payload.pdf : false;
          $scope.selectedSchema = {
            title: payload.name,
            config: payload.data
              .filter(function (model) {
                return model.target.id;
              })
              .map(function (model) {
                return {
                  guid: model.target.id,
                  percent: (model.percent * 100).toFixed(2),
                  label: model.target.name,
                  selectedLeverage: model.target.leverage,
                  lock: true,
                };
              }),
          };

          $scope.userSchemasF.push($scope.selectedSchema);

          if ($scope.userSchemasF.length) {
            setPortfolio();
            // TargetWeightFactory.setProposalDetails([]) // clear out the cached details to prevent interference when generating proposal from portfolios page
          }
        } else {
          $scope.schemaName = payload.name;
          $scope.autoBuildPDF = payload.pdf ? payload.pdf : false;

          $scope.selectedSchema = $scope.userSchemasF.filter(function (item) {
            return item.title === payload.name;
          })[0];

          if ($scope.userSchemasF.length) {
            if ($scope.userSchemasF.length > 0) setPortfolio();
          }
        }
      }, 10);
    });

    // clear out previous batches from SearchFilters service so they don't show up when tab is reloaded
    $scope.$on('$destroy', function (event) {
      SearchFilters.clearSelectedRows();
      SearchFilters.selectedModelIDs = [];
    });

    $scope.$on('tabSwitched::' + tabType, function (event, tab) {
      if (tab.type === 'portfolio-builder') {
        if (!$scope.visited && angular.isDefined($scope.chart_data)) {
          $scope.visited = true;
          $timeout(function () {
            refreshChart();
          }, 100);
        } else if (!$scope.visited && !angular.isDefined($scope.chart_data)) {
          $scope.visited = true;
          $timeout(function () {
            $scope.resetSchemaBuilder();
          }, 1);
        } else if ($scope.visited && angular.isDefined($scope.chart_data)) {
          $timeout(function () {
            refreshChart();
          }, 100);
        }

        // if we switch away from this tab before the performance chart is loaded
        // it won't know how large of a container it needs to fill when it is rendered
        // so we need to reflow it when we switch back
        if (angular.isDefined($scope.portfolioChartInstance)) {
          $timeout(function () {
            $scope.portfolioChartInstance.reflow();
            refreshChart();
          }, 1);
        }
      }
    });

    $scope.resetShowing = function () {
      $scope.showSimulatedPortfolio = false;
      $scope.showSimulatedPortfolioBtnTxt = 'Show';
    };

    $scope.$on('deletePortfolioFromBuilder', function (event, portfolio) {
      var portfolioName = portfolio.name;
      var portfolioIndex = $scope.userSchemasF
        .map(function (port) {
          return port.title;
        })
        .indexOf(portfolio.name);

      $scope.userSchemasF.splice(portfolioIndex, 1);
      $scope.deleteCurrentPortfolio('portfolios');
    });

    $scope.generatePortfolioPDF = function () {
      if ($scope.chartLoaded && $scope.showSimulatedPortfolio) {
        $scope.showSimulatedPortfolio = false;
      }
      $scope.autoBuildPDF = true;
      $scope.loadingModelList = $scope.autoBuildPDF;
      setPortfolio();
    };

    $scope.blurLeverageInput = function (item) {
      if (
        item.selectedLeverage === 0 ||
        item.selectedLeverage === null ||
        item.selectedLeverage === undefined
      ) {
        item.selectedLeverage = 1;
        calculateTotal();
      }
    };

    function positionSizeInvalid() {
      if ($scope.positionSize) {
        var parsedPositionSize = $scope.positionSize
          .replace('$', '')
          .replace(',', '');
        if (parsedPositionSize > minimumInvestment) {
          return true;
        }
      }

      return false;
    }

    $scope.positionSizeInvalid = positionSizeInvalid;

    function handleSearchResponse(searchResponse) {
      var models;
      var availableModels = [];

      if (searchResponse.data && searchResponse.data.models) {
        models = searchResponse.data.models;
      } else if (searchResponse.models) {
        models = searchResponse.models;
      } else {
        models = searchResponse.data.payload;
      }
      // var availableModels = [];
      $scope.loadingModelList = $scope.autoBuildPDF;

      // add id to information object and create a list with just the model information objects
      models = _.map(models, function (model, id) {
        model.information.id = model.guid;
        model = model.information;
        return model;
      });

      models = _.sortBy(models, 'name');

      $scope.hcv_models = models || [];
      if (!$scope.batch.length) {
        for (var i = $scope.hcv_models.length - 1; i >= 0; i--) {
          try {
            var modelId =
              $scope.hcv_models[i].id || $scope.hcv_models[i].guid || null;

            var model = {
              label: $scope.hcv_models[i].name,
              min:
                Models.getMinimumById(modelId) ||
                $scope.hcv_models[i].minimumAllocation,
              fee: $scope.hcv_models[i].feePercentage, // $scope.hcv_models[i].feeTotal,
              acc_min: 0,
              percent: 0,
              fid: null,
              guid: modelId,
              selected: false,
              selectedLeverage: 1,
              lock: false,
              summary: null,
              value: null,
              fundInception: $scope.hcv_models[i].performanceStartDate, // $scope.hcv_models[i].startedTracking.timestamp * 1000,
              type: i % 2 == 0 ? 'Alternative' : 'Fixed Income',
            };

            model.static = _.contains(staticModelIds, model.guid);

            if (_.isEmpty(model.guid)) {
              console.warn('guid is missing for model: ', model.label);
            } else {
              availableModels.push(model);
            }
          } catch (e) {
            // using this to handle models that don't have a fund id
            console.error(e);
            console.error(
              'required field missing for: ',
              $scope.hcv_models[i].name
            );
          }
        }
      }

      $scope.dndModels.lists['Available Models'] = _.sortBy(
        availableModels,
        'label'
      );

      $scope.availableModels = angular.copy(
        $scope.dndModels.lists['Available Models']
      );

      console.log(SearchFilters.getSelectedRows());
      if (SearchFilters.getSelectedRows().length) {
        $scope.selectedRows = angular.copy(SearchFilters.getSelectedRows());

        buildBatchFromSearchRows('portfolio-builder');
      }

      if (
        !$scope.selectedRows.length &&
        !angular.isDefined($scope.schemaName)
      ) {
        resetSchemaBuilder();
      }

      if (angular.isDefined($scope.schemaName)) {
        // var targetDetails = TargetWeightFactory.getProposalDetails()
        // if (!targetDetails.length)
        setPortfolio();
      }
    }

    function getModelData() {
      $scope.selectedRows = [];
      $scope.loadingModelList = true;

      var searchResponse = Models.getCachedSearchResponse();
      if (searchResponse) handleSearchResponse(searchResponse);
      else {
        // this was causing digest errors without the timeout
        $timeout(function () {
          // performanceApi.modelsSearch()

          // check for the cached model data
          // should have been loaded in by HomeCtrl
          var modelSummaries = Dashboard.getModelSummaries();
          var req =
            modelSummaries && _.keys(modelSummaries).length
              ? Promise.resolve(modelSummaries)
              : performanceApi.modelsSummary();

          req.then(handleSearchResponse).catch(function (err) {
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
            $scope.loadingModelList = false;
            // $scope.$apply();
          });
        });
      }
    }

    $scope.createTargetGroup = function createTargetGroup(portfolio) {
      var smaModel = portfolio.find(function (model) {
        var sma = Models.getModelList().find(function (_model) {
          return (
            _model.id === model.guid &&
            _model.type &&
            _model.type.toLowerCase() === 'sma'
          );
        });

        return sma;
      });

      if (smaModel) {
        SweetAlert.swal(
          '',
          'SMA models cannot be used in target portfolios',
          'info'
        );
        return false;
      }

      var payload = {
        id: null,
        name:
          ($scope.schemaName ? $scope.schemaName + '-' : 'newTarget-') +
          new Date().getTime(),
        details: [],
      };

      var cash = 0;
      var allocated = 0;
      portfolio.forEach(function (model) {
        var slice = {
          // "id": model.guid,
          name: model.label,
          percent: model.percent / 100,
          /* "percentUpperThreshold": 0.20,
          "percentLowerThreshold": 0.20,
          "percentUpperPrecision": 0.04,
          "percentLowerPrecision": 0.04,
          "leverage": model.selectedLeverage,
          "matchModelLeverage": true, */
          // "type": "model",
          target: {
            id: model.guid,
            type: 'model',
            name: model.label,
            leverage: model.selectedLeverage,
            matchModelLeverage: true,
          },
          threshold: {
            upper: 0.2,
            lower: 0.2,
          },
          precision: {
            upper: 0.04,
            lower: 0.04,
          },
        };

        allocated += model.percent;
        payload.details.push(slice);
      });

      cash = (100 - allocated) / 100;

      payload.details.push({
        //type: 'cash',
        percent: cash,
        /*      percentUpperThreshold: 0.2,
        percentLowerThreshold: 0.2,
        percentUpperPrecision: 0.04,
        percentLowerPrecision: 0.04 */
        target: {
          type: 'cash',
          name: '$CASH',
          leverage: null,
          matchModelLeverage: null,
        },
        threshold: {
          upper: 0.2,
          lower: 0.2,
        },
        precision: {
          upper: 0.04,
          lower: 0.04,
        },
      });

      Tabs.addTab('targetEdit', null, null, payload);
    };

    function generatePerformanceWithFee(formData) {
      var deferred = $q.defer();

      // add fee params
      var payload = _.extend(backtestParams, {
        advisor_fee: formData.advisorFee ? formData.advisorFee : undefined,
        broker_fee: formData.brokerFee ? formData.brokerFee : undefined,
        wrap_fee: formData.wrapFee ? formData.wrapFee : undefined,
      });

      performanceApi
        .portfolioPerformance(payload)
        .then(function (res) {
          deferred.resolve(res);
        })
        .catch(function (err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function containsStatic(models) {
      return _.some(models, function (model) {
        return model.static || model.isStatic;
      });
    }

    //  Generate agg performance label
    $scope.createAggPerformanceLabel = function (aggregatePerformance) {
      const oldestYear = aggregatePerformance[aggregatePerformance.length - 1];
      const oldestMonthKey = Object.keys(oldestYear.months)
        .map((key) => key)
        .sort()[0];
      const oldestDate = `${oldestMonthKey}/${oldestYear.year.slice(-2)}`;

      const newestYear = aggregatePerformance[0];
      const newestMonthKey = Object.keys(newestYear.months)
        .map((key) => key)
        .sort()[Object.keys(newestYear.months).length - 1];
      const newestDate = `${newestMonthKey}/${newestYear.year.slice(-2)}`;

      return `(${oldestDate} - ${newestDate})`;
    };

    $scope.makePDF = function (formData, deferred) {
      var deferred = $q.defer();
      var aggregateYTD = {};
      var benchmarkYTD = {};
      var performanceStats;
      var cash = 0;
      var batchWithFees;

      var feeTypes = [
        {
          key: 'advisorFee',
          label: 'Advisor Fee',
        },
        {
          key: 'brokerFee',
          label: 'Broker Fee',
        },
        {
          key: 'wrapFee',
          label: 'Wrap Fee',
        },
      ];

      var fees = feeTypes
        .filter(function (feeType) {
          return formData[feeType.key];
        })
        .map(function (feeType) {
          feeType.amount = formData[feeType.key];
          return feeType;
        });

      // if schema total does not equal 100, add a cash item to the batch
      if ($scope.schemaTotal < 100) {
        var cash = $scope.cashComponent;
        batchWithFees = angular.copy($scope.batch).concat({
          label: 'Cash',
          percent: cash,
          fee: 0,
        });
      }

      batch = angular.copy($scope.batch);

      var advisorFee = +formData.advisorFee || 0;
      var brokerFee = +formData.brokerFee || 0;

      // format the batch with the fee information
      batchWithFees = (batchWithFees || batch).map(function (model) {
        var modelWeight = model.percent / 100;

        model.weightedFee = formData.wrapFee
          ? formData.wrapFee * modelWeight
          : model.fee * modelWeight;
        model.rolledFee = model.fee + advisorFee + brokerFee;
        model.weightedAdvisorFee = modelWeight * advisorFee;
        model.weightedBrokerFee = modelWeight * brokerFee;

        return model;
      });

      var totalWeightedFees = _.reduce(
        batchWithFees,
        function (totalFees, model) {
          totalFees.model += model.weightedFee;
          totalFees.advisor += model.weightedAdvisorFee;
          totalFees.broker += model.weightedBrokerFee;
          totalFees.total +=
            model.weightedFee +
            model.weightedAdvisorFee +
            model.weightedBrokerFee;

          return totalFees;
        },
        {
          model: 0,
          advisor: 0,
          broker: 0,
          total: 0,
        }
      );

      var includeFees = angular.isDefined(
        formData.includeFees ||
          formData.advisorFee ||
          formData.brokerFee ||
          formData.wrapFee
      );

      if (includeFees) {
        generatePerformanceWithFee(formData)
          .then(function (res) {
            var payload = res.data.performance.payload;
            var portfolioKey = res.data.percentages;
            var models = payload.models;

            // portfolio
            // var monthlyPerformance = payload.portfolios[portfolioKey].windows['-1'].monthly_model.monthly_performance;
            var portfolioPerformance =
              payload.portfolios[portfolioKey].windows['-1'].monthly_model
                .performance;
            var portfolioCalculations =
              payload.portfolios[portfolioKey].windows['-1'].monthly_model
                .calculations;
            var firstModel = Object.keys(payload.models)[0]; // using this to get benchmark data
            // var modelsMonthlyPerformance = res.data.modelMonthly;
            var portfolioMonthlyPerformance = res.data.portfolioMonthly;

            // benchmark
            var benchmarkPerformance =
              payload.portfolios[portfolioKey].windows['-1'].monthly_model
                .benchmark_stream;
            var benchmarkMonthlyPerformance =
              payload.portfolios[portfolioKey].windows['-1'].monthly_model
                .benchmark_monthly;
            var benchmarkCalculations = payload.portfolio_benchmark.calculations;
            // var benchmarkMonthly = payload.portfolio_benchmark.monthly_performance.portfolio;

            var modelReturnsSinceInception = _.mapObject(
              res.data.trackedYearsForModels,
              function (model) {
                return _.sortBy(model, function (entry) {
                  return -entry.year;
                });
              }
            );
            var modelsYTD = {};

            // calculate YTD values for each model in the portfolio
            _.each(
              modelReturnsSinceInception,
              function (performanceData, model) {
                modelsYTD[model] = {};
                _.each(performanceData, function (performance) {
                  var year = performance.year;
                  modelsYTD[model][year] = {};
                  modelsYTD[model][year].modelYTD = $scope.sumAggregateReturn(
                    performance.months,
                    true
                  );
                  modelsYTD[model][year].benchmarkYTD = !_.isEmpty(
                    performance.benchmark
                  )
                    ? $scope.sumAggregateReturn(performance.benchmark, true)
                    : 0;
                });
              }
            );

            try {
              var benchmarkName =
                models[firstModel].information.benchmarks[0].name;
            } catch (e) {
              var benchmarkName = 'N/A';
            }

            var performanceNumbers = {
              benchmarkStatistics:
                payload.models[firstModel].monthly_benchmark_statistics,
              customPortfolioStatistics: portfolioCalculations,
              customPortfolioMaxDrawdowns: _.map(
                portfolioCalculations.max_drawdowns,
                function (drawdown, index) {
                  return drawdown;
                }
              ),
              aggregatePerformance: _.sortBy(
                portfolioMonthlyPerformance,
                function (o) {
                  return -o.year;
                }
              ),
              aggPerformanceYears: _.pluck(
                portfolioMonthlyPerformance,
                'year'
              ) /* .map(function(performance) {
              return performance.year;
            }), */,
              modelsPerformance: {},
              benchmarkPerformance: {},
            };

            // calculate YTD values for the portfolio
            _.each(
              performanceNumbers.aggregatePerformance,
              function (performance) {
                aggregateYTD[performance.year] = $scope.sumAggregateReturn(
                  performance.months,
                  true
                );
              }
            );

            // get YTD values for the portfolio benchmark
            _.each(benchmarkMonthlyPerformance, function (value, year) {
              if (
                performanceNumbers.aggPerformanceYears.indexOf(
                  year.toString()
                ) !== -1
              ) {
                benchmarkYTD[year] = $scope.sumAggregateReturn(value, true);
              }
            });

            Builder.getPerformanceTableData(
              portfolioCalculations,
              benchmarkCalculations
            )
              .then(function (stats) {
                performanceStats = stats;
                delete performanceStats['Downside Deviation'];
                delete performanceStats['Profitable Percentage'];
              })
              .then(function () {
                _.each(models, function (model, rid) {
                  performanceNumbers.modelsPerformance[rid] = [];

                  //var ytd = model.monthly_performance.ytd;
                  var modelPerformance = model.monthly_performance.model;
                  // get years in descending order
                  var years = Object.keys(modelPerformance).reverse();

                  _.each(years, function (year) {
                    if (
                      performanceNumbers.aggPerformanceYears.indexOf(year) !==
                      -1
                    ) {
                      // ignore years with no performance
                      if (modelPerformance[year] !== null) {
                        performanceNumbers.modelsPerformance[rid].push({
                          year: year,
                          months: modelPerformance[year],
                          ytd: getYTD(modelPerformance[year]),
                        });
                      }
                    }
                  });
                });

                var modelContributions =
                  payload.portfolios[portfolioKey].windows['-1'].monthly_model
                    .model_contributions;
                var annualizedReturns = _.values(
                  payload.portfolios[portfolioKey].windows['-1'].monthly_model
                    .calculations.annualized
                );
                var annualizedBenchmarkReturns = _.values(
                  payload.portfolio_benchmark.calculations.annualized
                );

                // calculate the dollar value performance time series
                var performanceChartData = calculateCompoundingReturns(
                  portfolioPerformance,
                  benchmarkPerformance
                );

                console.log(batch);
                console.log(USER);

                var blendData = {
                  aggregatePerformanceLabel: $scope.createAggPerformanceLabel(
                    performanceNumbers.aggregatePerformance
                  ),
                  enterpriseId: topLevelEnterpriseId || userEnterprise.id,
                  cash: cash,
                  author: formData.advisorName || Drupal.settings.fullName,
                  client: formData.clientName || '[client name]',
                  ria_firm: Drupal.settings.ria_firm,
                  templateName: 'blend-report.twig',
                  templateHeader: 'blend-report-header.twig',
                  templateFooter: 'blend-report-footer.twig',
                  customTableOfContents: true,
                  createdAt: moment(new Date()).format('MMM DD, YYYY hh:mm A'),

                  data: performanceChartData, // time series for line chart
                  batch: batch, // model allocation percentages for pie chart
                  batchWithFees: batchWithFees, // version of batch with cash & more fee calculations for fee-table.html
                  aggregatePerformance: performanceNumbers.aggregatePerformance, // monthly perf of blend
                  partialYears: $scope.aggregatePerformance
                    .filter(function (period) {
                      return (
                        Object.keys(period.months).length != 12 &&
                        period.year != new Date().getFullYear()
                      );
                    })
                    .map(function (period) {
                      return period.year;
                    }),
                  monthlyPerformance: portfolioPerformance,
                  lastMonthlyReturnDate: $filter('date')(
                    _.last(Object.keys(portfolioPerformance)) * 1000,
                    'shortDate',
                    'UTC'
                  ),
                  firstMonthlyReturnDate: $filter('date')(
                    _.first(Object.keys(portfolioPerformance)) * 1000,
                    'shortDate',
                    'UTC'
                  ),
                  benchmarkMonthlyPerformanceStream: benchmarkPerformance,
                  aggregateYTD: aggregateYTD, // YTD #'s for the blend
                  benchmarkPerformance: benchmarkPerformance, // monthly perf of benchmark
                  benchmarkYTD: benchmarkYTD, // YTD #'s for benchmark
                  benchmarkName: $scope.benchmarkName,
                  benchmarkLongName: $scope.benchmarkLongName,
                  performanceStats: performanceStats,
                  customPortfolioMaxDrawdowns: _.sortBy(
                    performanceNumbers.customPortfolioMaxDrawdowns,
                    'max'
                  ),
                  modelsPerformance: performanceNumbers.modelsPerformance,
                  modelsCumulativeReturns: modelContributions,

                  annualizedReturns: annualizedReturns,
                  annualizedBenchmarkReturns: annualizedBenchmarkReturns,

                  correlationMatrix: $scope.matrix,
                  feesAndMinimums: $scope.feesAndMinimums,

                  portfolioModels: models,
                  modelReturns: modelReturnsSinceInception,
                  modelsYTD: modelsYTD,
                  logoPath:
                    jQuery('#smartx-pdf-logo').length >= 1
                      ? jQuery('#smartx-pdf-logo').attr('src')
                      : jQuery('#logo').attr('src'),
                  includeSummaries: formData.includeSummaries,
                  includeFees: includeFees,
                  // platform: EnterpriseFactory.getUserEnterprise(), // Dashboard.getPlatform(),
                  includeCover: true,
                  baseUrl: DRUPAL_API_URL,
                  static: containsStatic($scope.batch),
                  model: {
                    disclosures: $templateCache.get(
                      'staticModelDisclaimer.html'
                    ),
                  },
                  portfolioSize: formData.portfolioSize
                    ? formData.portfolioSize
                    : null,
                  fees: {
                    advisor: formData.advisorFee,
                    broker: formData.brokerFee,
                    wrap: formData.wrapFee,
                  },
                  totalWeightedFees: totalWeightedFees,
                  modelPerformanceFeeLabels: $scope.modelPerformanceFeeLabels,
                };

                var reqs = [];

                // get the model summaries and add them to the batch array
                // TODO: refactor this into its own call to get just the summary data for a list of models
                blendData.batch.forEach(function (model) {
                  var req = cmsApi.getModelById(model.guid, 'smartx_guid');
                  reqs.push(req);
                });

                // get the fund profile for each model in the portfolio
                $q.all(reqs).then(function (responses) {
                  responses.forEach(function (response, index) {
                    var summary;
                    if (response.status == 'failed') {
                      console.error(response.msg);
                      summary = 'N/A';
                    } else {
                      try {
                        summary = response.data.node.body.und[0].summary
                          ? response.data.node.body.und[0].summary.replace(
                              'SUMMARY - ',
                              ''
                            )
                          : 'N/A';
                      } catch (e) {
                        console.error(e);
                        summary = 'N/A';
                      }
                    }

                    blendData.batch[index].summary = summary;
                  });

                  $http
                    .post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, blendData, {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      responseType: 'arraybuffer',
                    })
                    .then(function (res) {
                      $scope.generatingReport = false;
                      $scope.modalInstance.close();

                      var blob = new Blob([res.data], {
                        type: 'application/pdf',
                      });
                      var filename = formData.filename
                        ? formData.filename + '.pdf'
                        : 'blend_report.pdf';

                      $scope.batch = [];
                      $scope.batch = $scope.dndModels.lists['Selected Models'];

                      saveAs(blob, filename);
                      deferred.resolve();
                    })
                    .catch(function (res) {
                      $scope.generatingReport = false;
                      $scope.errorGeneratingReport = true;
                      deferred.reject();
                    });
                });
              });
          })
          .catch(function (err) {
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
            deferred.reject();
          });
      } else {
        var performanceStats = angular.copy($scope.performanceStats);

        delete performanceStats['Downside Deviation'];
        delete performanceStats['Profitable Percentage'];

        console.log(batch);
        console.log(USER);

        var blendData = {
          aggregatePerformanceLabel: $scope.createAggPerformanceLabel(
            $scope.aggregatePerformance
          ),
          enterpriseId: topLevelEnterpriseId || userEnterprise.id,
          cash: cash,
          author: formData.advisorName || Drupal.settings.fullName,
          client: formData.clientName || '[client name]',
          fees: fees,
          ria_firm: Drupal.settings.ria_firm,
          templateName: 'blend-report.twig',
          templateHeader: 'blend-report-header.twig',
          templateFooter: 'blend-report-footer.twig',
          customTableOfContents: true,
          createdAt: moment(new Date()).format('MMM DD, YYYY hh:mm A'),
          data: $scope.performanceChartData, // time series for line chart
          batch: batch, // model allocation percentages for pie chart
          aggregatePerformance: $scope.aggregatePerformance, // monthly perf of blend
          partialYears: $scope.aggregatePerformance
            .filter(function (period) {
              return (
                Object.keys(period.months).length != 12 &&
                period.year != new Date().getFullYear()
              );
            })
            .map(function (period) {
              return period.year;
            }),
          monthlyPerformance: $scope.monthlyPerformanceStream,
          lastMonthlyReturnDate: $filter('date')(
            _.last(Object.keys($scope.monthlyPerformanceStream)) * 1000,
            'shortDate',
            'UTC'
          ),
          firstMonthlyReturnDate: $filter('date')(
            _.first(Object.keys($scope.monthlyPerformanceStream)) * 1000,
            'shortDate',
            'UTC'
          ),
          benchmarkMonthlyPerformanceStream:
            $scope.benchmarkMonthlyPerformanceStream,
          aggregateYTD: $scope.aggregateYTD, // YTD #'s for the blend
          benchmarkPerformance: $scope.benchmarkPerformance, // monthly perf of benchmark
          benchmarkYTD: $scope.benchmarkYTD, // YTD #'s for benchmark
          benchmarkName: $scope.benchmarkName,
          benchmarkLongName: $scope.benchmarkLongName,
          performanceStats: performanceStats,
          annualizedReturns: $scope.annualizedReturns,
          annualizedBenchmarkReturns: $scope.annualizedBenchmarkReturns,
          customPortfolioMaxDrawdowns: _.sortBy(
            $scope.customPortfolioMaxDrawdowns,
            'max'
          ),
          modelsPerformance: $scope.modelsPerformance,
          modelsCumulativeReturns: $scope.modelContributions,
          correlationMatrix: $scope.matrix,
          feesAndMinimums: $scope.feesAndMinimums,
          portfolioModels: $scope.models,
          modelReturns: $scope.modelReturnsSinceInception,
          modelsYTD: $scope.modelsYTD,
          logoPath:
            jQuery('#smartx-pdf-logo').length >= 1
              ? jQuery('#smartx-pdf-logo').attr('src')
              : jQuery('#logo').attr('src'),
          includeSummaries: formData.includeSummaries,
          includeFees: includeFees,
          // platform: EnterpriseFactory.getUserEnterprise(), // Dashboard.getPlatform(),
          includeCover: true,
          baseUrl: DRUPAL_API_URL,
          static: containsStatic($scope.batch),
          model: {
            disclosures: $templateCache.get('staticModelDisclaimer.html'),
          },
          portfolioSize: formData.portfolioSize ? formData.portfolioSize : null,
          modelPerformanceFeeLabels: $scope.modelPerformanceFeeLabels,
        };

        var reqs = [];

        // get the model summaries and add them to the batch array
        // TODO: refactor this into its own call to get just the summary data for a list of models
        blendData.batch.forEach(function (model) {
          var req = cmsApi.getModelById(model.guid, 'smartx_guid');
          reqs.push(req);
        });

        $q.all(reqs).then(function (responses) {
          responses.forEach(function (response, index) {
            var summary;
            if (response.status == 'failed') {
              console.error(response.msg);
              summary = 'N/A';
            } else {
              try {
                summary = response.data.node.body.und[0].summary
                  ? response.data.node.body.und[0].summary.replace(
                      'SUMMARY - ',
                      ''
                    )
                  : 'N/A';
              } catch (e) {
                console.error(e);
                summary = 'N/A';
              }
            }

            blendData.batch[index].summary = summary;
          });

          $http
            .post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, blendData, {
              headers: {
                'Content-Type': 'application/json',
              },
              responseType: 'arraybuffer',
            })
            .then(function (res) {
              $scope.generatingReport = false;
              $scope.modalInstance.close();

              var blob = new Blob([res.data], {
                type: 'application/pdf',
              });
              var filename = formData.filename
                ? formData.filename + '.pdf'
                : 'blend_report.pdf';

              $scope.batch = [];
              // ($scope.dndModelsLastSelected);
              $scope.batch = $scope.dndModels.lists['Selected Models'];

              saveAs(blob, filename);
              deferred.resolve();
            })
            .catch(function (res) {
              $scope.generatingReport = false;
              $scope.errorGeneratingReport = true;
              deferred.reject();
            });
        });
      }

      return deferred.promise;
    };

    $scope.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.status.isopen = !$scope.status.isopen;
    };

    $scope.range = function (start, end) {
      var arr = [];
      for (var i = start; i < end; i++) {
        arr.push(i);
      }

      return arr;
    };

    $scope.updateSelectedCount = function () {
      $timeout(function () {
        $scope.dndModelsLastSelected = $scope.dndModels.lists['Selected Models'];
        $scope.availableSelected = parseInt(
          jQuery('.dnd-models .dnd-list li.selected').length
        );
      });
    };

    $scope.removeSelected = function (model) {
      // if the model id is not in the list of

      if (!$scope.modelIDs && Object.keys($scope.modelNames).length) {
        $scope.modelIDs = Object.keys($scope.modelNames);
      } else if ($scope.modelIDs.length) {
        if (
          $scope.modelIDs.indexOf(model.field_fund_data_id.und[0].value === -1)
        ) {
          return model;
        }
      } else {
        return model;
      }
    };

    $scope.onSelectHandler = function ($item, $model) {
      var rid = $item.field_fund_data_id.und[0].value,
        title = $item.title.trim();
      if ($scope.batch.length < $scope.MAX_SCHEMA_SIZE) {
        $temp = $item;
        $temp.percent = 0;
        $scope.batch.push($temp);
        $scope.modelNames[rid] = title;
        $scope.setSchemaAmountsEqual();
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
      } else {
        SweetAlert.swal({
          title: 'Schema Builder',
          text: 'Maximum limit of ' + $scope.MAX_SCHEMA_SIZE + ' models added.',
          html: false,
          showCancelButton: false,
          showLoaderOnConfirm: true,
          types: 'warning',
        });
      }
    };

    $scope.onRemoveHandler = function ($item, $model) {
      var i = $scope.batch.indexOf($item);
      var itemTitle = $item.title.trim();

      _.each($scope.batch, function (item, index) {
        if (angular.isDefined(item)) {
          if (item.title.trim() === itemTitle) {
            i = index;
          }
        }
      });

      $scope.batch.splice(i, 1);

      $scope.setSchemaAmountsEqual();
      $scope.showSimulatedPortfolio = false;
      $scope.showSimulatedPortfolioBtnTxt = 'Show';
    };

    $scope.selectModel = function (item) {
      item.selected = !item.selected;
    };

    function calculateTotal(item) {
      $scope.schemaTotal = 0;
      $scope.minimumInvestment = 0;
      $scope.feesAndMinimums = [];

      var highest_min = 0,
        i;

      var selectedModelsList = $scope.dndModels.lists['Selected Models'];
      $scope.dndModelsLastSelected = selectedModelsList;

      for (i = $scope.batch.length - 1; i >= 0; i--) {
        if (!selectedModelsList[i]) return;

        $scope.batch[i].percent = selectedModelsList[i].percent;

        var model_min = parseFloat($scope.batch[i].min);
        var model_percent = isNaN(parseFloat($scope.batch[i].percent))
          ? 0
          : parseFloat(parseFloat($scope.batch[i].percent).toFixed(2));
        var schemaTotal = parseFloat(
          parseFloat(parseFloat($scope.schemaTotal) + model_percent).toFixed(2)
        );

        $scope.feesAndMinimums.push({
          name: $scope.batch[i].label,
          percent: model_percent * 1,
          min: model_min * 1,
          feeCalc:
            parseFloat($scope.batch[i].fee).toFixed(4) +
            ' * ' +
            (parseFloat(model_percent) / 100).toFixed(4) +
            ' = ' +
            (
              parseFloat($scope.batch[i].fee / 100) * parseFloat(model_percent)
            ).toFixed(2),
          // fee: (!isNaN($scope.batch[i].fee)) ? (parseFloat($scope.batch[i].fee*100) * parseFloat(model_percent/100)).toFixed(2) : $scope.batch[i].fee,
          fee:
            1 *
            (parseFloat($scope.batch[i].fee).toFixed(4) *
              (parseFloat(model_percent) / 100).toFixed(4)),
          feeGross: parseFloat($scope.batch[i].fee).toFixed(4),
        });

        // round to 2 decimal places
        schemaTotal = +(Math.round(schemaTotal + 'e+2') + 'e-2');
        // debugger

        $scope.schemaTotal = schemaTotal;
        if (schemaTotal <= 100) {
          //Calculate model min
          if (model_percent > 0) {
            model_min = parseInt(
              parseFloat(model_min) /
                parseFloat(parseFloat(model_percent) / 100)
            );
            if (model_min > highest_min) {
              highest_min = model_min;
            }
          }
        } else {
          //alert("Portfolio Overfilled");
          // TODO: reset the input back to the max value that brings the total to 100
        }
      }

      $scope.minimumInvestment = highest_min;

      for (var j = 0; j < $scope.feesAndMinimums.length; j++) {
        var modelName = $scope.feesAndMinimums[j].name;
        var __min = parseFloat(
          $scope.minimumInvestment * ($scope.feesAndMinimums[j].percent / 100)
        );
        var batchItem = $scope.batch.filter(function (item) {
          return item.label === modelName;
        })[0];
        batchItem.acc_min = __min;
        batchItem.acc_minCalc =
          $scope.minimumInvestment +
          ' * ' +
          ($scope.feesAndMinimums[j].percent / 100).toFixed(4) +
          ' = ';
        $scope.feesAndMinimums[j].acc_min = Math.round(__min);
      }

      $scope.schemaRemaining =
        100 - parseFloat(parseFloat($scope.schemaTotal).toFixed(2));
      if ($scope.showSimulatedPortfolioBtnTxt === 'Hide') {
        $scope.weightsChanged = true;
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
      }

      var modelAllocationPercentage = $scope.schemaTotal / 100;
      $scope.minimumInvestment =
        parseFloat(
          $scope.feesAndMinimums.reduce(function (total, item) {
            return parseFloat(total) + parseFloat(item.acc_min);
          }, 0)
        ).toFixed(0) / modelAllocationPercentage;

      $scope.feesAndMinimums.push({
        name: 'Total',
        min: '',
        fee: parseFloat(
          $scope.feesAndMinimums.reduce(function (total, item) {
            return parseFloat(total) + parseFloat(item.fee);
          }, 0)
        ).toFixed(4),
        acc_min: parseFloat($scope.minimumInvestment).toFixed(0),
      });

      refreshChart();
    }

    function addToList() {
      for (
        var i = $scope.dndModels.lists['Available Models'].length - 1;
        i >= 0;
        i--
      ) {
        if ($scope.dndModels.lists['Available Models'][i].selected === true) {
          //De-select selected items
          $scope.dndModels.lists['Available Models'][i].selected = false;

          //Remove selected items from Available Models
          var selected_item = $scope.dndModels.lists['Available Models'].splice(
            i,
            1
          );
          selected_item = selected_item[0];

          //Add selected items to Selected Models
          $scope.dndModels.lists['Selected Models'].push(selected_item);
          $scope.modelNames[selected_item.guid] = selected_item.label;
        }
      }

      $scope.updateSelectedModelTotal();
    }

    $scope.removeFromList = function () {
      for (
        var i = $scope.dndModels.lists['Selected Models'].length - 1;
        i >= 0;
        i--
      ) {
        if ($scope.dndModels.lists['Selected Models'][i].selected === true) {
          //De-select selected items
          $scope.dndModels.lists['Selected Models'][i].selected = false;

          //Remove selected items from Selected Models
          var selected_item = $scope.dndModels.lists['Selected Models'].splice(
            i,
            1
          );
          selected_item = selected_item[0];

          //Add selected items to Available Models
          $scope.dndModels.lists['Available Models'].push(selected_item);

          //$scope.modelNames.splice(selected_item.fid,1);
        }
      }

      $scope.updateSelectedModelTotal();
    };

    $scope.itemMovedFunc = function (list, item, index) {
      var index = list.indexOf(item);
      list.splice(index, 1);
    };

    function updateSelectedModelTotal(list, listName, item, $index, $event) {
      var total = 0,
        selectedModelCount,
        modelIndex;

      if (angular.isDefined(item)) {
        $scope.modelNames[item.guid] = item.label;
      }

      $scope.batch = [];
      $scope.batch = $scope.dndModels.lists['Selected Models'];

      $scope.availableSelected = 0;
      $scope.setSchemaAmountsEqual();
      // $scope.calculateTotal();
    }

    $scope.lockModel = function (event, model) {
      event.stopPropagation();

      if (typeof model.lock != 'undefined') model.lock = !model.lock;
      else model.lock = true;
    };

    function setSchemaAmountsEqual() {
      var equalAmount = Math.floor((1 / $scope.batch.length) * 10000) / 100;

      var unlockedBatch = [];
      var lockedAmount = 0;
      for (var i = $scope.batch.length - 1; i >= 0; i--) {
        if ($scope.batch[i].lock == false) {
          $scope.batch[i].percent = parseFloat(
            parseFloat(equalAmount).toFixed(2)
          );
          unlockedBatch.push($scope.batch[i]);
        } else {
          lockedAmount += parseFloat(
            parseFloat($scope.batch[i].percent).toFixed(2)
          );
        }
      }

      if (lockedAmount > 0) {
        var unlockedAmount = 100 - lockedAmount;
        /*
          calculation change for MAIN-4076 to avoid the rounding up caused by using toFixed(2)
          example:
          unlockedAmount = 66.75
          unlockedBatch length = 4
          unlockedAmount / unlockedBatch.length = 16.6675
          multiply by 100 to get 1666.75 and then round down = 1666.00
          divide by 100 to get back to two decimal places = 16.66
        */
        equalAmount =
          Math.floor((unlockedAmount / unlockedBatch.length) * 100) / 100; // equalAmount / parseFloat(parseFloat(unlockedBatch.length).toFixed(2));

        for (var i = $scope.batch.length - 1; i >= 0; i--) {
          if ($scope.batch[i].lock == false) {
            $scope.batch[i].percent = parseFloat(
              parseFloat(equalAmount).toFixed(2)
            );
          }
        }
      }

      calculateTotal();
    }

    $scope.validateSchemaName = function () {
      $scope.schemaName = $scope.schemaName.replace(/\s/g, '_');
      if (jQuery.inArray($scope.schemaName, $scope.schemaNames) >= 0) {
        $scope.validSchemeName = false;
      } else {
        $scope.validSchemeName = true;
      }
    };

    $scope.validateSchema = function () {
      var confirmAllocation = $templateCache.get('CreateNewPortfolio.html');
      if (parseInt($scope.schemaTotal) > 100) {
        SweetAlert.swal({
          title: 'Schema Builder',
          text:
            'Failed! Over allocated by ' +
            (parseInt($scope.schemaTotal) - 100) +
            '%',
          html: false,
          showCancelButton: false,
          showLoaderOnConfirm: true,
          type: 'warning',
        });
      } else {
        $scope.portfolioData = {};
        SweetAlert.swal(
          {
            title: $interpolate(confirmAllocation)($scope.portfolioData),
            text: '',
            html: true,
            type: 'input',
            showCancelButton: true,
            confirmButtonColor: '#8cb843',
            confirmButtonText: 'Confirm',
            closeOnConfirm: false,
            closeOnCancel: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: true,
            inputPlaceholder: 'Portfolio 1',
            inputValue: $scope.schemaName ? $scope.schemaName : null,
          },
          function (inputValue) {
            if (inputValue === false) {
              return false;
            }

            $scope.saveSchema(inputValue);
          }
        );
      }
    };

    function refreshChart() {
      var chart_data = [];
      chart_data.push(['Model', 'Percent']);

      for (var i = 0; i < $scope.batch.length; i++) {
        var percent = parseFloat(parseFloat($scope.batch[i].percent).toFixed(2));
        var tooltip =
          "<div><span class='pie-tooltip-label'>Model: " +
          $scope.batch[i].label +
          '</span></div>' +
          "<div><span class='pie-tooltip-amount'>Amount: " +
          percent +
          '%</span></div>' +
          "<div><span class='pie-tooltip-leverage'>Leverage: " +
          $scope.batch[i].selectedLeverage +
          'x</span></div>';
        var label =
          $scope.batch[i].selectedLeverage > 0
            ? $scope.batch[i].label +
              ' ' +
              $scope.batch[i].selectedLeverage +
              'x'
            : $scope.batch[i].label;
        var obj = { v: percent, f: percent + '%' };
        chart_data.push([label, obj]);
      }

      var sRemaining = parseFloat(parseFloat($scope.schemaRemaining).toFixed(2));
      if (sRemaining > 0) {
        var obj = { v: sRemaining, f: sRemaining + '%' };
        chart_data.push(['Unallocated', obj]);
      }

      $scope.chart_data = angular.copy(chart_data);

      $timeout(function () {
        drawChart(chart_data);
      }, 100);
    }

    $scope.changeLeverage = function (item) {
      calculateTotal();
    };

    $scope.initLeverageInput = function (item) {
      if (angular.isDefined(item.selectedLeverage)) {
        item.selectedLeverage = Number(item.selectedLeverage);
      } else {
        item.selectedLeverage = 1;
      }
    };

    $scope.saveSchema = function (name) {
      var payload = {},
        newPortfolio,
        count =
          typeof $scope.userSchemasF != 'undefined'
            ? $scope.userSchemasF.length + 1
            : 1;

      if (typeof name == 'undefined' || name == '') {
        name = 'Portfolio_' + count;
      }
      $scope.schemaName = name.replace(/ /g, '_');

      if ($scope.schemaNames.contains($scope.schemaName)) {
        var date = new Date(),
          datevalues = [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
          ];
        $scope.schemaName =
          $scope.schemaName +
          '_' +
          datevalues[1] +
          '_' +
          datevalues[2] +
          '_' +
          datevalues[0] +
          '_' +
          datevalues[3] +
          ':' +
          datevalues[4];
        $scope.schemaName = $scope.schemaName.replace(/ /g, '_');
      }

      payload[$scope.schemaName] = [];

      for (var i = $scope.batch.length - 1; i >= 0; i--) {
        payload[$scope.schemaName].push({
          label:
            typeof $scope.batch[i].label != 'undefined'
              ? $scope.batch[i].label
              : 'My_Schema',
          min:
            typeof $scope.batch[i].min != 'undefined'
              ? $scope.batch[i].min
              : 'N/A',
          fee:
            typeof $scope.batch[i].fee != 'undefined'
              ? $scope.batch[i].fee
              : 'N/A',
          percent: parseFloat(parseFloat($scope.batch[i].percent).toFixed(2)),
          fid:
            typeof $scope.batch[i].fid != 'undefined'
              ? $scope.batch[i].fid
              : $scope.batch[i].fid,
          guid: $scope.batch[i].guid,
          selectedLeverage: $scope.batch[i].selectedLeverage,
          startdate: Math.floor(new Date().setHours(9) / 1000), // any portfolio created gets the start time set to 9
          summary: $scope.batch[i].summary,
          value: $scope.batch[i].value,
          fundInception: $scope.batch[i].fundInception,
          benchmark: $scope.selectedBenchmark,
        });
      }

      payload[$scope.schemaName].title = $scope.schemaName;

      Builder.saveUserSchema(payload).then(function (result) {
        var data = angular.fromJson(result.data);

        if (result.data.status === 'success') {
          SweetAlert.swal(
            {
              title: 'Your new portfolio has been created!',
              text: 'Your new portfolio has been created.',
              html: true,
              type: 'success',
              allowOutsideClick: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Close',
            },
            function (isConfirm) {
              Dashboard.getUserSchemaData().then(function (result) {
                var data = angular.fromJson(result.data);

                if (
                  typeof data.result != 'undefined' &&
                  data.result !== '' &&
                  data.result !== null
                ) {
                  data.result = angular.fromJson(data.result);
                  $scope.userSchemas = data.result;
                  $scope.schemaNames = Object.keys(data.result);
                  $scope.userSchemasF = [];

                  angular.forEach(data.result, function (element, key) {
                    var el = {
                      title: key,
                      config: element,
                    };
                    $scope.userSchemasF.push(el);
                  });

                  $scope.selectedSchema = payload[$scope.schemaName];
                }

                newPortfolio = {
                  models: payload[$scope.schemaName],
                  name: $scope.schemaName,
                };
                $rootScope.$broadcast('portfolioCreated', newPortfolio);
              });
            }
          );
        } else {
        }
      });
    };

    $scope.buildPortfolio = function (pdf) {
      $scope.errorLoadingPerformance = false;
      $scope.schemaName = $scope.selectedSchema
        ? $scope.selectedSchema.title
        : undefined;

      // use this as a max-width to prevent chart from running out of its container
      $scope.maxChartWidth = jQuery('.line-chart-wrap').width() + 'px';

      var firstModel,
        modelData = {
          model_ids: [],
          leverage: [],
          percentages: [],
          benchmark: $scope.selectedBenchmark, // $scope.selectedSchema && $scope.selectedSchema.config ? ($scope.selectedSchema.config[0].benchmark || $scope.selectedBenchmark) : $scope.selectedBenchmark
        };

      try {
        $scope.selectedBenchmark =
          modelData.benchmark || 'BF6BE7BA-0CE1-40A9-8225-2354841D601C';
      } catch (e) {
        console.error(e);
      }

      $scope.showSimulatedPortfolio = !$scope.showSimulatedPortfolio;
      if (!$scope.showSimulatedPortfolio) {
        $scope.chartLoading = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
      } else {
        // if the batch hasn't changed since the last time we clicked simulated portfolio,
        // toggle the visibility of the already loaded chart.
        // otherwise, create a new chart with the new batch
        var currentBatchTitles = _.map($scope.batch, function (model) {
          return model.title;
        });

        if (true) {
          // this was here to save time when rerunning the same portfolio multiple time but that probably won't happen often
          //if ( JSON.stringify($scope.lastBatchTitles) !== JSON.stringify(currentBatchTitles) || $scope.weightsChanged){

          $scope.chartLoading = true;
          $scope.chartLoaded = false;
          $scope.weightsChanged = false;

          // make sure the model with the shortest track record comes first
          // the performance call takes the length of returns from the first model in the in the list so
          // when the first one has a longer track record than the rest, extra monthly returns are being added
          $scope.batch = _.sortBy($scope.batch, function (model) {
            return model.fundInception * -1;
          });

          angular.forEach($scope.batch, function (model, key) {
            if (
              typeof model != 'undefined' &&
              (model.guid != 'undefined' ||
                typeof model.field_fund_data_id != 'undefined' ||
                typeof model.fid != 'undefined')
            ) {
              var fid =
                typeof model.field_fund_data_id != 'undefined'
                  ? model.field_fund_data_id.und[0].value
                  : model.fid;
              model.guid = model.guid ? model.guid.toLowerCase() : null;
              var guid = model.guid;
              var leverage = model.selectedLeverage;

              modelData.leverage.push(leverage);

              if (_.isEmpty(guid)) {
                console.warn('no guid for model: ', model.label);
              } else {
                modelData.model_ids.push(guid);
              }
              // v1
              //modelData.models.push(fid);
              modelData.percentages.push(model.percent);

              $scope.modelNames[guid] = model.label;
            }
          });

          payload = _.mapObject(modelData, function (param) {
            if (angular.isArray(param)) param = param.join(',');
            if (_.isEmpty(param)) param = '';
            return param;
          });

          // if ($scope.selectedBenchmark){
          //   payload.benchmark = $scope.selectedBenchmark
          // }

          backtestParams = payload;

          // if we got different data
          performanceApi
            .portfolioPerformance(payload)
            .then(function (res) {
              // if (dataIsMissing(res)) {
              //   $scope.chartLoading = false;
              //   $scope.errorLoadingPerformance = true;
              //   $scope.showSimulatedPortfolio = !$scope.showSimulatedPortfolio;
              // } else {
              $scope.showSimulatedPortfolioBtnTxt = 'Hide';
              $scope.portfolioPayload = res.data.performance.payload;
              $scope.chartViewToggle.selected = 'Aggregate';

              var payload = res.data.performance.payload;
              var portfolioKey = res.data.percentages;
              var models = payload.models;

              $scope.portfolioModels = models;

              // portfolio
              var monthlyPerformance =
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .monthly_performance;
              var portfolioPerformance =
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .performance;
              var portfolioCalculations =
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .calculations;
              var firstModel = Object.keys(payload.models)[0]; // using this to get benchmark data
              var modelsMonthlyPerformance = res.data.modelMonthly;
              var portfolioMonthlyPerformance = res.data.portfolioMonthly;
              $scope.monthlyPerformanceStream = portfolioPerformance; // stream of monthly returns used for generating return distribution chart

              // benchmark
              var benchmarkPerformance =
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .benchmark_stream;
              var benchmarkMonthlyPerformance =
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .benchmark_monthly;
              var benchmarkCalculations =
                payload.portfolio_benchmark.calculations;
              var benchmarkMonthly =
                payload.portfolio_benchmark.monthly_performance.portfolio;

              var annualizedReturns = _.values(
                payload.portfolios[portfolioKey].windows['-1'].monthly_model
                  .calculations.annualized
              );
              var annualizedBenchmarkReturns = _.values(
                payload.portfolio_benchmark.calculations.annualized
              );

              $scope.annualizedReturns = annualizedReturns;
              $scope.annualizedBenchmarkReturns = annualizedBenchmarkReturns;

              $scope.benchmarkMonthlyPerformanceStream = benchmarkPerformance; // stream of monthly returns used for generating return distribution chart
              $scope.models = payload.models;
              $scope.yearsInPortfolio = res.data.years.length;

              try {
                var benchmarkName =
                  models[firstModel].information.benchmarks[0].name;
                $scope.benchmarkName =
                  benchmarkName.indexOf('S&P') > -1
                    ? benchmarkName
                    : 'Benchmark';
                $scope.benchmarkLongName = benchmarkName;
              } catch (e) {
                $scope.benchmarkName = 'N/A';
              }

              var performanceNumbers = {
                benchmarkStatistics:
                  payload.models[firstModel].monthly_benchmark_statistics,
                customPortfolioStatistics: portfolioCalculations,
                customPortfolioMaxDrawdowns: _.map(
                  portfolioCalculations.max_drawdowns,
                  function (drawdown, index) {
                    return drawdown;
                  }
                ),
                aggregatePerformance: _.sortBy(
                  portfolioMonthlyPerformance,
                  function (o) {
                    return -o.year;
                  }
                ),
                aggPerformanceYears: portfolioMonthlyPerformance.map(function (
                  performance
                ) {
                  return performance.year;
                }),
                modelsPerformance: {},
                benchmarkPerformance: {},
              };

              angular.extend($scope, performanceNumbers);

              $scope.modelReturnsSinceInception = _.mapObject(
                res.data.trackedYearsForModels,
                function (model) {
                  return _.sortBy(model, function (entry) {
                    return -entry.year;
                  });
                }
              );
              $scope.modelContributions =
                payload.portfolios[portfolioKey].windows[
                  '-1'
                ].monthly_model.model_contributions;
              $scope.correlationBenchmark =
                payload.portfolio_benchmark.monthly_performance.stream;

              // PG-23
              let modelPerformanceFeeLabels = {};
              for (let key in payload.models) {
                modelPerformanceFeeLabels[key] =
                  'All performance data on static models are provided directly from the model manager.';

                let matchedReportedModel = $scope.availableModels.filter(
                  (x) => x.guid == key
                );
                if (
                  matchedReportedModel.length > 0 &&
                  !matchedReportedModel[0].static
                ) {
                  modelPerformanceFeeLabels[key] = `Returns after ${moment(
                    payload.models[key].information.startedTracking * 1000
                  ).format('MM/DD/YY')} reflect simulated performance by SMArtX`;
                }
              }
              $scope.modelPerformanceFeeLabels = modelPerformanceFeeLabels;

              Builder.getPerformanceTableData(
                portfolioCalculations,
                benchmarkCalculations
              ).then(function (stats) {
                $scope.performanceStats = stats;
              });

              $scope.benchmarkYTD = {};
              $scope.modelsYTD = {};

              // get YTD values for the portfolio benchmark
              _.each(benchmarkMonthlyPerformance, function (value, year) {
                if (
                  $scope.aggPerformanceYears.indexOf(year.toString()) !== -1
                ) {
                  $scope.benchmarkYTD[year] = $scope.sumAggregateReturn(
                    value,
                    true
                  );
                }
              });

              // get YTD values for each model since inception
              _.each(
                $scope.modelReturnsSinceInception,
                function (performanceData, model) {
                  $scope.modelsYTD[model] = {};
                  _.each(performanceData, function (performance) {
                    var year = performance.year;
                    $scope.modelsYTD[model][year] = {};
                    $scope.modelsYTD[model][year].modelYTD =
                      $scope.sumAggregateReturn(performance.months, true);
                    $scope.modelsYTD[model][year].benchmarkYTD = !_.isEmpty(
                      performance.benchmark
                    )
                      ? $scope.sumAggregateReturn(performance.benchmark, true)
                      : 0;
                  });
                }
              );

              // get YTD values for the portfolio
              $scope.aggregateYTD = {};
              _.each($scope.aggregatePerformance, function (performance) {
                $scope.aggregateYTD[performance.year] =
                  $scope.sumAggregateReturn(performance.months, true);
              });

              // TODO: merge these two loops into something more efficient

              // get benchmark performance for same years that we have for aggregate
              _.each(
                payload.models[firstModel].monthly_performance.benchmark,
                function (months, year) {
                  if ($scope.aggPerformanceYears.indexOf(year) !== -1) {
                    $scope.benchmarkPerformance[year] = months;
                  }
                }
              );

              $scope.modelsMonthlyPerformance = res.data.modelMonthly;

              // get each model's performance for the current year
              _.each($scope.models, function (model, rid) {
                $scope.modelsPerformance[rid] = [];

                //var ytd = model.monthly_performance.ytd;
                var modelPerformance = model.monthly_performance.model;
                // get years in descending order
                var years = Object.keys(modelPerformance).reverse();

                _.each(years, function (year) {
                  if ($scope.aggPerformanceYears.indexOf(year) !== -1) {
                    // ignore years with no performance
                    if (modelPerformance[year] !== null) {
                      $scope.modelsPerformance[rid].push({
                        year: year,
                        months: modelPerformance[year],
                        ytd: getYTD(modelPerformance[year]),
                      });
                    }
                  }
                });
              });

              $scope.lastBatchTitles = _.map($scope.batch, function (model) {
                return model.title;
              });

              // in case this flag gets out of sync during the process of generating portfolios using different methods
              $scope.showSimulatedPortfolio = true;

              $scope.buildChart(portfolioPerformance, benchmarkPerformance);
              $timeout(function () {
                if (typeof pdf !== 'undefined' && pdf) {
                  $scope.open();
                  $scope.autoBuildPDF = false;
                  // $scope.loadingModelList = false;
                }
              }, 1000);
            })
            .catch(function (err) {
              // console.error(err);
              // toastr.error(err.message);
              Dashboard.toastError(err.message, err);
              $scope.chartLoading = false;
              $scope.errorLoadingPerformance = true;
            })
            .finally(function () {
              $timeout(function () {
                $scope.chartLoading = false;
              });
            });

          $scope.loadingModelList = false;
          for (var i = 0; i < $scope.batch.length; i++) {
            $scope.batch[i].lock = true;
          }
          $scope.updateSelectedModelTotal();
        } else {
          $scope.showSimulatedPortfolioBtnTxt = 'Hide';
          $scope.chartLoading = false;
        }
      }
    };

    function dataIsMissing(res) {
      return (
        !angular.isDefined(res.data.performance) ||
        res.data.performance === null ||
        !angular.isDefined(res.data.performance.payload.portfolios) ||
        res.data.performance.payload.portfolio_benchmark.monthly_performance ===
          null
      );
    }

    $scope.changeSchema = function (elSchema, recalculate) {
      recalculate = angular.isDefined(recalculate) ? recalculate : true;

      //Set selected schema if available
      if (typeof elSchema != 'undefined') {
        $scope.selectedSchema = elSchema;
      }

      var modelIDs = [];
      var _models = [];
      $scope.dndModels.lists['Selected Models'] = [];
      $scope.dndModels.lists['Available Models'] = _models = angular
        .copy($scope.availableModels)
        .map(function (model) {
          if (typeof elSchema != 'undefined') {
            var schemaIds = _.pluck(elSchema.config, 'guid');
            if (schemaIds.indexOf(model.guid) != -1) {
              model.selected = true;
              model.lock = true;
            }
          }

          return model;
        });
      $scope.modelNames = {};
      $scope.schemaName = $scope.selectedSchema.title;

      $scope.selectedSchema.config = $scope.selectedSchema.config.map(function (
        model
      ) {
        var reportedModel = _.find(reportedModelList, { id: model.guid });

        // if we are dealing with an item that was saved with a reported model id, look up the smartx model id based on the reported id
        // this is done because the call to create the backtest expects smartx model ids
        if (reportedModel)
          model.guid = _.find(Models.getModelList(), {
            reportedModelId: reportedModel.id,
          }).id;
        try {
          var modelName = reportedModel
            ? reportedModel.name
            : _.find(Models.getModelList(), { id: model.guid }).name;
        } catch (e) {
          console.error(e);
          debugger;
        }
        model.label = modelName;
        return model;
      });

      // only include the ones with guids in the loaded batch
      $scope.batch = _.filter($scope.selectedSchema.config, function (model) {
        if (_.isEmpty(model.guid)) {
          console.warn(
            'omitting ' +
              model.label +
              ' from portfolio batch due to missing guid'
          );
          console.info('omitted model: ', model);
        }
        return !_.isEmpty(model.guid);
      }).map(function (item) {
        var selectedM = _.find(_models, function (listModel) {
          return listModel.guid === item.guid;
        });

        if (selectedM) {
          item.fee = selectedM.fee;
          if (!item.min) item.min = selectedM.min;
        }

        if (item.fee < 0.01) item.fee *= 100;

        item.fee = item.fee * 1;
        item.min = item.min * 1;

        if (selectedM) {
          item.label = selectedM.label;
        }
        if (typeof item.fee === 'undefined') {
          if (selectedM.length) {
            item.fee = selectedM.fee * 1;
          }
        }
        return item;
      });

      var availableModels = _.pluck(
        angular.copy($scope.dndModels.lists['Available Models']),
        'guid'
      );
      // .map(function(model) {

      //   return model.guid;
      // });

      // store the model names so that we can easily look up and set the series names on the performance chart
      _.each($scope.selectedSchema.config, function (model) {
        var modelIndex = availableModels.indexOf(model.guid);

        // make sure it's not a model that's no longer in the list
        // this could happen if they saved a portfolio long ago and the model is no longer available
        if (modelIndex !== -1) {
          $scope.modelNames[model.guid] = model.label; // v2
          modelIDs.push(model.guid); // v2
          model.lock = model.lock ? model.lock : false;
          $scope.dndModels.lists['Selected Models'].push(model);
          availableModels.splice(modelIndex, 1);
          $scope.dndModels.lists['Available Models'].splice(modelIndex, 1);
        }
      });

      // set the benchmark selector to the saved benchmark
      if (
        $scope.selectedSchema.config[0] &&
        $scope.selectedSchema.config[0].benchmark
      ) {
        $scope.selectedBenchmark = $scope.selectedSchema.config[0].benchmark;
      }

      drawChart();
      if (recalculate) calculateTotal();
    };

    $scope.clearPortfolio = function () {
      $scope.resetSchemaBuilder();
      $window.document.getElementById('portfolio-name').focus();
    };

    function resetSchemaBuilder () {
      $scope.model = {};
      $scope.batch = [];
      $scope.schemaName = undefined;
      $scope.schemaTotal = 0;
      $scope.minimumInvestment = 0;
      $scope.schemaRemaining = 100;
      $scope.selectedSchema = [];
      if ($scope.dndModels.lists['Available Models'].length) {
        $scope.dndModels.lists['Available Models'] = angular.copy(
          $scope.availableModels
        );
        $scope.dndModels.lists['Selected Models'] = [];
      }

      // this will be true whenever there are selected rows in a search tab
      if (SearchFilters.getSelectedRows().length) {
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
        $scope.setSchemaAmountsEqual();
      } else {
        refreshChart();
        $scope.showSimulatedPortfolio = false;
        $scope.showSimulatedPortfolioBtnTxt = 'Show';
        $scope.chartLoading = false;
        $scope.chartLoaded = true;
      }
    }

    $scope.mapAggregateMonths = function (months) {
      // in case we don't start with 01 as the first key
      var months = angular.copy(months);
      var numMonths = Object.keys(months).length;
      var firstMonth = _.min(Object.keys(months));

      var values = [];
      var range = _.range(parseInt(firstMonth, 10), 13);

      _.each(range, function (i) {
        if (i < 10) {
          i = '0' + i;
        }
        var month = months[i];

        values.push(month);
      });
      return values;
    };

    $scope.sumAggregateReturn = function (values, parseMonths) {
      if (parseMonths) values = $scope.mapAggregateMonths(values);

      var percentAsFloat = values.reduce(function (currentTotal, newValue) {
        if (angular.isDefined(newValue)) {
          return currentTotal * (newValue / 100 + 1);
        } else {
          return currentTotal * 1;
        }
      }, 1);

      YTD = (percentAsFloat - 1) * 100;

      return YTD;
    };

    $scope.showAggregate = function () {
      // loop through the chart series
      // if it is an aggregate model series, set its visible property to true
      $scope.portfolioChart.series.forEach(function (series) {
        if (series.id !== 'Benchmark') {
          if (series.individual) {
            series.visible = false;
          } else if (!series.individual) {
            series.visible = true;
          }
        }
      });
    };

    $scope.showIndividualModels = function () {
      // loop through the chart series
      // if it is an individual model series, set its visible property to true
      $scope.portfolioChart.series.forEach(function (series) {
        if (series.id !== 'Benchmark') {
          if (series.individual) {
            series.visible = true;
          } else if (!series.individual) {
            series.visible = false;
          }
        }
      });
    };

    $scope.deleteCurrentPortfolio = function (triggeredFrom) {
      var payload = {
          title: $scope.selectedSchema.title,
        },
        formattedTitle = $scope.selectedSchema.title.replace(/_/g, ' '),
        selectedSchema = $scope.selectedSchema;

      if (triggeredFrom === 'portfolios') {
        Dashboard.getUserSchemaData().then(function (result) {
          var data = angular.fromJson(result.data);

          if (
            typeof data.result != 'undefined' &&
            data.result !== '' &&
            data.result !== null
          ) {
            data.result = angular.fromJson(data.result);
            $scope.userSchemas = data.result;
            $scope.schemaNames = Object.keys(data.result);
            $scope.userSchemasF = [];
            angular.forEach(data.result, function (element, key) {
              var el = {
                title: key,
                config: element,
              };
              $scope.userSchemasF.push(el);
            });
          }

          if (_.isEmpty($scope.userSchemasF)) {
            $scope.resetSchemaBuilder();
          } else {
            $scope.selectedSchema = $scope.userSchemasF[0];
            $scope.changeSchema($scope.selectedSchema);
          }
        });
      } else {
        SweetAlert.swal(
          {
            title: 'Delete ' + formattedTitle,
            text:
              'Are you sure?<br/>Your will not be able to recover ' +
              formattedTitle +
              '!',
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              Builder.deleteUserSchema(payload).then(function (result) {
                var data = angular.fromJson(result.data);

                if (data.status == 'success') {
                  SweetAlert.swal(
                    'Portfolio Builder',
                    'Portfolio deleted!',
                    'success'
                  );
                  Dashboard.getUserSchemaData().then(function (result) {
                    var data = angular.fromJson(result.data);

                    if (
                      typeof data.result != 'undefined' &&
                      data.result !== '' &&
                      data.result !== null
                    ) {
                      data.result = angular.fromJson(data.result);
                      $scope.userSchemas = data.result;
                      $scope.schemaNames = Object.keys(data.result);
                      $scope.userSchemasF = [];
                      angular.forEach(data.result, function (element, key) {
                        var el = {
                          title: key,
                          config: element,
                        };
                        $scope.userSchemasF.push(el);
                      });
                    }

                    if (_.isEmpty($scope.userSchemasF)) {
                      $scope.resetSchemaBuilder();
                    } else {
                      $scope.selectedSchema = $scope.userSchemasF[0];
                      $scope.changeSchema($scope.selectedSchema);
                    }

                    $rootScope.$broadcast('portfolioDeleted', selectedSchema);
                  });
                } else {
                  SweetAlert.swal(
                    'Portfolio Builder',
                    'Portfolio Could not be deleted!',
                    'error'
                  );
                }
              });
            }
          }
        );
      }
    };

    $scope.toggleChartView = function () {
      if ($scope.chartViewToggle.selected === 'Individual') {
        $scope.showIndividualModels();
      } else if ($scope.chartViewToggle.selected === 'Aggregate') {
        $scope.showAggregate();
      }
    };

    function calculateCompoundingReturns(model, benchmark, leverage) {
      var days = [],
        modelData = [],
        undefinedBench = [],
        undefinedModel = [],
        benchmarkData = [],
        startingBalance = 1000,
        startModel = 1000,
        startBenchmark = 1000,
        leverage = leverage ? leverage : 1;

      for (var day in model) {
        if (
          typeof model[day] !== 'undefined' &&
          model[day] !== null &&
          typeof benchmark[day] !== 'undefined' &&
          benchmark[day] !== null
        ) {
          var timestamp = day * 1000,
            currentModel =
              startModel +
              (parseFloat(model[day] * leverage) / 100) * startModel;
          currentBenchmark =
            startBenchmark + (parseFloat(benchmark[day]) / 100) * startBenchmark;

          modelData.push({
            x: timestamp,
            y: currentModel,
            value: model[day],
          });
          benchmarkData.push({
            x: timestamp,
            y: currentBenchmark,
            value: benchmark[day],
          });

          startModel = currentModel;
          startBenchmark = currentBenchmark;
        } else {
          if (model[day] == 'undefined') {
            undefinedModel.push([model[day], model, day]);
          } else if (benchmark[day] == 'undefined') {
            undefinedBench.push([benchmark[day], benchmark, day]);
          }
        }
      }

      // add starting point to beginning of the series'
      if (modelData.length) {
        var firstModelPoint = modelData[0].x;
        var firstBenchmarkPoint = benchmarkData[0].x;

        var newFirstModelPoint = moment(firstModelPoint)
          .subtract(1, 'month')
          .valueOf();
        var newFirstBenchmarkPoint = moment(firstBenchmarkPoint)
          .subtract(1, 'month')
          .valueOf();

        modelData.unshift([newFirstModelPoint, startingBalance]);
        benchmarkData.unshift([newFirstBenchmarkPoint, startingBalance]);
      }

      var performanceChartData = {
        portfolio: modelData,
        benchmark: benchmarkData,
      };

      return performanceChartData;
    }
    $scope.buildChart = function (model, benchmark, leverage) {
      var days = [],
        modelData = [],
        undefinedBench = [],
        undefinedModel = [],
        benchmarkData = [],
        startingBalance = 1000,
        startModel = 1000,
        startBenchmark = 1000,
        leverage = leverage ? leverage : 1;

      $scope.portfolioChart = {
        title: {
          text: null,
          stylye: {
            display: 'none',
          },
        },
      };

      for (var day in model) {
        if (
          typeof model[day] !== 'undefined' &&
          model[day] !== null &&
          typeof benchmark[day] !== 'undefined' &&
          benchmark[day] !== null
        ) {
          var timestamp = day * 1000,
            currentModel =
              startModel +
              (parseFloat(model[day] * leverage) / 100) * startModel;
          currentBenchmark =
            startBenchmark + (parseFloat(benchmark[day]) / 100) * startBenchmark;

          modelData.push({
            x: timestamp,
            y: currentModel,
            value: model[day],
          });
          benchmarkData.push({
            x: timestamp,
            y: currentBenchmark,
            value: benchmark[day],
          });

          startModel = currentModel;
          startBenchmark = currentBenchmark;
        } else {
          if (model[day] == 'undefined') {
            undefinedModel.push([model[day], model, day]);
          } else if (benchmark[day] == 'undefined') {
            undefinedBench.push([benchmark[day], benchmark, day]);
          }
        }
      }

      // add starting point to beginning of the series'
      if (modelData.length) {
        var firstModelPoint = modelData[0].x;
        var firstBenchmarkPoint = benchmarkData[0].x;

        var newFirstModelPoint = moment(firstModelPoint)
          .subtract(1, 'month')
          .valueOf();
        var newFirstBenchmarkPoint = moment(firstBenchmarkPoint)
          .subtract(1, 'month')
          .valueOf();

        modelData.unshift([newFirstModelPoint, startingBalance]);
        benchmarkData.unshift([newFirstBenchmarkPoint, startingBalance]);
      }

      $scope.performanceChartData = {
        portfolio: modelData,
        benchmark: benchmarkData,
      };

      $timeout(function () {
        $scope.portfolioChart = {
          // options: {
          chart: {
            type: 'area',
            events: {
              load: function (event) {
                var chart = this;
                $timeout(function () {
                  chart.reflow();
                });

                $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls

                $scope.chartLoading = false;
                $scope.chartLoaded = true;

                // this adds the individual series
                createSeries();
              },
              render: function (event) {},
            },
            zoomType: 'x',
          },
          colors: [
            '#3366CC',
            '#DC3912',
            '#FF9900',
            '#109618',
            '#990099',
            '#3B3EAC',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
            '#316395',
            '#994499',
            '#22AA99',
            '#AAAA11',
            '#6633CC',
            '#E67300',
            '#8B0707',
            '#329262',
            '#5574A6',
            '#3B3EAC',
          ],
          title: {
            text: null,
            style: {
              display: 'none',
            },
          },
          subtitle: {
            text:
              document.ontouchstart === undefined
                ? 'Click and drag in the plot area to zoom in'
                : 'Pinch the chart to zoom in',
          },
          credits: {
            enabled: false,
          },
          style: {
            width: jQuery('.schema-submit-wrap').width(),
          },
          yAxis: {
            title: {
              text: null,
            },
            labels: {
              align: 'right',
              format: '${value:,.0f}',
              formatter: function () {
                var percent =
                  ((this.value - startingBalance) / startingBalance) * 100;
                return percent + '%';
                //return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
              },
            },
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },
          tooltip: {
            formatter: function () {
              var s =
                '<span style=""><b>' +
                Highcharts.dateFormat('%B %e, %Y', this.x) +
                '</b></span>';

              _.each(this.points, function (point) {
                var currentBalance = Highcharts.numberFormat(
                  point.y,
                  2,
                  '.',
                  ','
                );
                var percent =
                  ((point.y - startingBalance) / startingBalance) * 100;

                s +=
                  '<br/><b><span style="color:' +
                  point.series.color +
                  '">' +
                  point.series.name +
                  ': ' +
                  '$' +
                  currentBalance +
                  '</b> (' +
                  percent.toFixed(2) +
                  '%)</span>';
              });

              return s;
            },

            crosshairs: true,
            shared: true,
          },
          // },

          xAxis: {
            tickWidth: 0,
            gridLineWidth: 1,
            type: 'datetime',
            tickAmount: 390,
          },

          legend: {
            enabled: true,
          },

          exporting: {
            enabled: false,
          },
          useHighStocks: true,
          series: [
            {
              id: 'Model Data',
              name: 'Simulated Portfolio Performance',
              type: 'line',
              individual: false, // this field is used by toggleChartview method to determine whether or not this series should be visible
              lineWidth: 2,
              connectNulls: true,
              data: modelData,
              visible: true,
              color: '#D85B08',
            },
            {
              id: 'Benchmark',
              name: $scope.benchmarkName,
              type: 'line',
              lineWidth: 2,
              connectNulls: true,
              visible: true,
              //marker: { radius: 2 },
              data: benchmarkData,
              color: '#2c557f',
            },
          ],
          func: function (chart) {
            $timeout(function () {
              chart.reflow();
            });

            $scope.portfolioChartInstance = chart; // save a reference to the chart instance for future method calls

            $scope.chartLoading = false;
            $scope.chartLoaded = true;

            // this adds the individual series
            createSeries();
          },
        };
      }, 1);
    };

    var cleanUpPreviousChart = function () {
      return $q(function (resolve, reject) {
        if ($scope) {
          resolve($scope.chart.clearChart());
        } else {
          reject('error clearing chart');
        }
      });
    };

    // this will be called when we build a basket from selected rows on the search page
    function buildBatchFromSearchRows(origin) {
      if (origin === 'portfolio-builder') {
        $scope.batch = [];
        $scope.schemaName = '';
        $scope.dndModels.lists['Available Models'] = $scope.dndModels.lists[
          'Available Models'
        ].concat($scope.dndModels.lists['Selected Models']);
        $scope.dndModels.lists['Selected Models'] = [];
        var selectedModels = angular.copy(SearchFilters.selectedModelIDs);

        if ($scope.hcv_models.length) {
          _.each(
            $scope.dndModels.lists['Available Models'],
            function (model, key) {
              if (selectedModels.indexOf(model.guid) !== -1) {
                $scope.batch.push(model);
                $scope.modelNames[model.guid] = model.label;
                model.selected = true;
              }
            }
          );

          $scope.addToList();
        } else {
          modelApi
            .getAllModels()
            //Dashboard.getSearchData()
            .then(function (result) {
              $scope.hcv_models = result.data.models;

              for (var i = $scope.hcv_models.length - 1; i >= 0; i--) {
                try {
                  var modelId = $scope.hcv_models[i].id
                    ? $scope.hcv_models[i].id
                    : null;

                  var model = {
                    label: $scope.hcv_models[i].name,
                    min:
                      Models.getMinimumById(modelId) ||
                      $scope.hcv_models[i].minimumAllocation,
                    acc_min: 0,
                    percent: 0,
                    fid: null,
                    guid: modelId,
                    selected: false,
                    selectedLeverage: 1,
                    lock: false,
                    summary: null,
                    value: null,
                    fundInception:
                      $scope.hcv_models[i].startedTracking.timestamp * 1000,
                    type: i % 2 == 0 ? 'Alternative' : 'Fixed Income',
                  };

                  if (_.isEmpty(model.guid)) {
                    console.warn('guid is missing for model: ', model.label);
                  } else {
                    $scope.dndModels.lists['Available Models'].push(model);
                  }
                } catch (e) {}
              }

              // pull out the models that were selected from the search view and add them to the new batch
              _.each(
                $scope.dndModels.lists['Available Models'],
                function (model, key) {
                  if (selectedModels.indexOf(model.guid) !== -1) {
                    $scope.batch.push(model);
                    $scope.modelNames[model.guid] = model.label;
                    model.selected = true;
                  }
                }
              );

              return Promise.resolve(SearchFilters.selectedModelIDs);
            })
            .then(function () {
              $scope.addToList();
            })
            .catch(function (err) {
              if (err) {
                throw new Error(err);
              }
            });
        }
        //}, 1000);
      }
    }

    var buildBatchFromSearchRowsCallback = {
      name: 'buildBatchFromSearchRows',
      fn: buildBatchFromSearchRows,
    };

    Dashboard.registerObserverCallback(buildBatchFromSearchRowsCallback);

    function compareTitle(a, b) {
      if (a.label.trim() < b.label.trim()) return -1;
      if (a.label.trim() > b.label.trim()) return 1;
      return 0;
    }

    var buildTooltip = function (batchItem) {
      var label = batchItem.label;
      var percent = batchItem.percent;
      var amount = $scope.minimumInvestment / percent;
      return '<div>$' + amount + '</div>';
    };

    // look up the selected models in the hcv_models array
    var getSelectedModels = function (modelIDs) {
      return function (model) {
        if (modelIDs.indexOf(model.field_fund_data_id.und[0].value) !== -1) {
          var modelIndex = $scope.hcv_models.indexOf(model);
          return modelIndex;
        }
      };
    };

    var createSeries = function (model, benchmark) {
      var modelData, leverage, startModel, performance, newSeries, models;

      models = $scope.models;

      angular.forEach(models, function (model, rid) {
        modelData = [];
        leverage = 1;
        startModel = 1000;
        startingBalance = 1000;

        var modelFromBatch = _.find($scope.batch, {
          fid: rid,
        });

        model = model.monthly_performance.stream;
        benchmark = $scope.benchmarkMonthlyPerformanceStream;

        for (var day in model) {
          if (
            typeof model[day] !== 'undefined' &&
            typeof benchmark[day] !== 'undefined' &&
            benchmark[day] !== null
          ) {
            var timestamp = day * 1000,
              currentModel =
                startModel +
                (parseFloat(model[day] * leverage) / 100) * startModel;

            modelData.push({
              x: timestamp,
              y: currentModel,
              value: model[day],
            });

            startModel = currentModel;
          }
        }

        // add starting point to beginning of the series'
        if (modelData.length) {
          var firstModelPoint = modelData[0].x;
          var newFirstModelPoint = moment(firstModelPoint)
            .subtract(1, 'month')
            .valueOf();

          modelData.unshift([newFirstModelPoint, startingBalance]);
        }

        newSeries = {
          id: rid,
          name: $scope.modelNames[rid] || rid,
          type: 'line',
          individual: true,
          lineWidth: 2,
          connectNulls: true,
          data: modelData,
          visible: false,
        };

        $scope.portfolioChart.series.push(newSeries);
      });
    };

    var redrawPie;

    redrawPie = _.debounce(function () {
      if ($scope.portfolioChart && $scope.$parent.tab.active) {
        drawChart($scope.chart_data);
      }
    }, 300);

    jQuery(window).resize(redrawPie);

    function setPortfolio() {
      if ($scope.batch.length === 0) {
        angular.forEach($scope.userSchemasF, function (value, key) {
          if (
            typeof $scope.schemaName !== 'undefined' &&
            (value.title === $scope.schemaName ||
              value.title === $scope.schemaName.replace(/\s/g, '_'))
          ) {
            $timeout(function () {
              // don't need to recalculate total if autoBuilding a PDF (calculation will happen after the performance is generated)
              // if (typeof $scope.autoBuildPDF !== 'undefined' && $scope.autoBuildPDF) {
              //   $scope.changeSchema(value, false);
              // } else {
              $scope.changeSchema(value);
              // }
            }, 10);
          }
        });
      }

      if (typeof $scope.autoBuildPDF !== 'undefined' && $scope.autoBuildPDF) {
        $timeout(function () {
          if (!$scope.chartLoading) {
            $scope.buildPortfolio($scope.autoBuildPDF);
            for (var i = 0; i < $scope.batch.length; i++) {
              $scope.batch[i].lock = $scope.batch[i].lock
                ? $scope.batch[i].lock
                : false;
            }
          }
        }, 100);
      }
    }

    // get the slice on mouseover
    function showTooltip(e) {
      var slice, selection, label, percent, amount, leverage, tooltip;

      chart.setSelection([e]);

      try {
        selection = chart.getSelection();
        sliceid = selection[0].row;
        slice = $scope.batch[sliceid];
        leverage = slice.selectedLeverage;
        label = slice.label;
        percent = slice.percent;
        amount = $scope.minimumInvestment / percent;

        tooltip =
          "<div><span class='pie-tooltip-label'>Model: " +
          label +
          '</span></div>' +
          "<div><span class='pie-tooltip-amount'>Amount: $" +
          amount +
          '</span></div>' +
          "<div><span class='pie-tooltip-leverage'>Leverage: " +
          leverage +
          'x</span></div>';

        jQuery('.google-visualization-tooltip-item-list li:eq(0)').css(
          'font-weight',
          'bold'
        );
        jQuery('.google-visualization-tooltip-item-list li:eq(1)')
          .html(tooltip)
          .css('font-family', 'Arial');
      } catch (err) {
        console.error(err);
      }
    }

    function drawChart(data_array) {
      if ($scope.tab.active) {
        var data, options;

        if (typeof document.getElementById('piechart') != 'undefined') {
          try {
            if (
              typeof data_array == 'object' &&
              typeof data_array.isTrusted == 'undefined'
            ) {
              data = google.visualization.arrayToDataTable(data_array);
            } else {
              data = google.visualization.arrayToDataTable([
                ['Model', 'Percent'],
              ]);
            }

            options = {
              title: '',
              animation: {
                duration: 1000,
                easing: 'out',
              },
              is3D: true,
              legend: {
                position: 'bottom',
              },
              pieSliceText: 'value',
              sliceVisibilityThreshold: 0.0001,
              tooltip: {
                text: 'value',
              },
            };

            chart = new google.visualization.PieChart(
              document.getElementById('piechart')
            );
            google.visualization.events.addListener(
              chart,
              'onmouseover',
              showTooltip
            );
            chart.draw(data, options);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    Array.prototype.contains = function (obj) {
      var i = this.length;
      while (i--) {
        if (this[i] === obj) {
          return true;
        }
      }
      return false;
    };

    $scope.generateReport = function () {
      $scope.open();
      Amplitude.logEvent('Portfolio Builder:Generate Report');
    };

    $scope.open = function (size) {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'portfolioReportModal.html',
        controller: 'ModalInstanceCtrl',
        size: size,
        resolve: {
          makePDF: function () {
            return $scope.makePDF;
          },
          minimumInvestment: function () {
            return $scope.minimumInvestment;
          },
          portfolioName: function () {
            return $scope.schemaName && $scope.schemaName != ''
              ? $scope.schemaName
              : null;
          },
        },
      });
    };
  },
])
  .config(function () {})
  .filter('toClassName', function () {
    return function (name) {
      return name.toLowerCase().replace(' ', '-');
    };
  })
  .filter('trackedYears', function () {
    return function (val, aggPerformanceYears) {
      var filteredObj = _.filter(val, function (val, key) {
        return aggPerformanceYears.indexOf(Number(key)) !== -1;
      });

      return filteredObj;
    };
  })
  .filter('modelTypeFilter', function () {
    return function (items, activeFilters, listName) {
      if (activeFilters.length && listName == 'Available Models') {
        var filtered = [];
        angular.forEach(items, function (el) {
          if (_.contains(activeFilters, el.type)) {
            filtered.push(el);
          }
        });

        return filtered;
      } else {
        return items;
      }
    };
  })
  .directive('validateLeverage', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        var transformedInput;

        modelCtrl.$parsers.push(function (inputValue) {
          if (inputValue !== '') {
            if (inputValue < 0) {
              transformedInput = 0.25;
            } else if (inputValue > 4) {
              transformedInput = 4;
            } else {
              transformedInput = inputValue;
            }

            if (transformedInput != inputValue) {
              modelCtrl.$setViewValue(transformedInput);
              modelCtrl.$render();
            }

            return transformedInput;
          }
        });
      },
    };
  })
  .controller(
    'ModalInstanceCtrl',
    function (
      IdentityFactory,
      $scope,
      $uibModalInstance,
      $filter,
      Dashboard,
      makePDF,
      minimumInvestment,
      portfolioName,
      EnterpriseFactory
    ) {
      var user = Dashboard.getReplicatorUser();

      $scope.advisorFeeErrorMessage = '';
      $scope.showAdvisorFeeError = false;
      $scope.advisorName = getAdvisorNameWithEnterprise(user);
      $scope.minimumInvestment = minimumInvestment;
      $scope.portfolioSizeInvalid = false;
      $scope.portfolioSizeInvalidMsg =
        'The amount must be greater than the minimum investment of ' +
        $filter('currency')($scope.minimumInvestment, '$', 0) +
        '.';

      if (portfolioName) {
        $scope.filename = portfolioName;
      }

      $scope.$watch('portfolioSize', function (newVal, oldVal) {
        if (angular.isDefined(newVal) && newVal != '' && newVal !== oldVal) {
          var parsedPortfolioSize = newVal.replace('$', '').replace(',', '');
          if (+parsedPortfolioSize < +$scope.minimumInvestment) {
            $scope.portfolioSizeInvalid = true;
            return;
          }
        }

        $scope.portfolioSizeInvalid = false;
      });

      function getAdvisorNameWithEnterprise(user) {
        if (!user) throw new Error('No user provided');

        var advisorName = user.firstName.trim() + ' ' + user.lastName.trim();
        var enterpriseName = user.enterprise ? user.enterprise.name : '';

        // if the advisor's name is in the enterprise name, use the top level enterprise name
        if (enterpriseName.indexOf(advisorName) != -1) {
          enterpriseName = user.topLevelEnterprise.name;
        }

        return advisorName + ', ' + enterpriseName;
      }

      $scope.validateFees = function () {
        let input = document.getElementById('advisorFee');

        if ($scope.wrapFee) {
          input.style.border = '';
          return true;
        }

        if (!$scope.advisorFee) {
          input.style.border = '1px solid red';
          $scope.advisorFeeErrorMessage = 'Advisor Fee is required.';
          return false;
        }

        var positiveNumberPattern = /^[+]?([0-9]*[.])?[0-9]+$/;

        if (!positiveNumberPattern.test($scope.advisorFee)) {
          input.style.border = '1px solid red';
          $scope.advisorFeeErrorMessage =
            'Invalid input. Advisor Fee must be 0 or greater.';
          return false;
        }
        input.style.border = '';
        return true;
      };

      $scope.ok = function () {
        $scope.showAdvisorFeeError = !$scope.validateFees();

        if ($scope.showAdvisorFeeError) {
          $scope.generatingReport = false;
          return;
        }

        var formData = {
          // email:            $scope.email,
          filename: $scope.filename,
          clientName: $scope.clientName,
          description: $scope.description,
          advisorName: $scope.advisorName,
          includeSummaries: $scope.includeSummaries,
          includeFees: $scope.includeFees,
          advisorFee: $scope.advisorFee,
          portfolioSize: $scope.portfolioSize,
          wrapFee: $scope.wrapFee,
          brokerFee: $scope.brokerFee,
        };

        formData.advisorFee = formData.advisorFee
          ? formData.advisorFee.replace('%', '')
          : formData.advisorFee;
        formData.brokerFee = formData.brokerFee
          ? formData.brokerFee.replace('%', '')
          : formData.brokerFee;
        formData.wrapFee = formData.wrapFee
          ? formData.wrapFee.replace('%', '')
          : formData.wrapFee;
        formData.portfolioSize = formData.portfolioSize
          ? formData.portfolioSize.replace('$', '').replace(/,/g, '')
          : formData.portfolioSize;

        $scope.generatingReport = true;
        $scope.errorGeneratingReport = false;

        makePDF(formData)
          .then(function () {
            $scope.generatingReport = false;
          })
          .catch(function () {
            $scope.generatingReport = false;
            $scope.errorGeneratingReport = true;
          });
      };

      $scope.cancel = function () {
        if ($scope.generatingReport) {
          return false;
        }
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
