angular.module('EnterpriseFactory', [])
  .factory('EnterpriseFactory', ['SmartXFactory', '$q', function(SmartXFactory, $q) {

    var userEnterprise = null,
        enterpriseRelationships = {},
        enterpriseAccess = [],
        foreignEnterpriseAccess = [],
        enterpriseLookupOptions,
        activeAccountEnterprises = [],
        enterpriseApi = SmartXFactory.getEnterprisesAPI(),
        topLevelDrupalEnterprise,
        topLevelEnterpriseId,
        contactInfo;
    
    function setTopLevelEnterpriseId(id) {
      topLevelEnterpriseId = id;
    }

    function getTopLevelEnterpriseId(){
      return topLevelEnterpriseId;
    }

    function loadEnterpriseLookup () {
      var enterpriseOptions;

      return $q.when(enterpriseApi.get())
        .then(function(res){
          var enterprise = _.property(['data','data'])(res);
          if (enterprise) {
            enterpriseOptions = [enterprise].concat(enterprise.childEnterprise);
          } else {
            enterpriseOptions = [];
          }

          setEnterpriseLookupOptions(enterpriseOptions);

          return $q.resolve(enterpriseOptions);
        })
        .catch(function(err){
          return $q.reject(err);
        });
    }

    function isMultiEnterpriseUser () {
      return (foreignEnterpriseAccess.length || enterpriseAccess.length);
    }

    function setEnterpriseLookupOptions (enterprises) {
      enterpriseLookupOptions = enterprises;
    }

    function getEnterpriseLookupOptions () {
      return enterpriseLookupOptions;
    }

    function setActiveAccountEnterprises (enterprises) {
      activeAccountEnterprises = enterprises;
    }

    function getActiveAccountEnterprises (){
      return activeAccountEnterprises;
    }
    
    function setEnterpriseAccess(enterprises) {
      enterpriseAccess = enterprises;
    }

    function getEnterpriseAccess(){
      return enterpriseAccess;
    }

    function setUserEnterprise (enterprise) {
      userEnterprise = enterprise;
    }

    function getUserEnterprise (){
      return userEnterprise;
    }

    function getEnterpriseRelationships(type){
      return enterpriseRelationships[type] || [];
    }

    function setEnterpriseRelationships(relationships){
      enterpriseRelationships = relationships;
    }

    function getForeignEnterpriseAccess(){
      return foreignEnterpriseAccess;
    }

    function setForeignEnterpriseAccess(access){
      foreignEnterpriseAccess = access;
    }

    function getEnterpriseModels (enterpriseId, params){
      return enterpriseApi.getModels(enterpriseId, params);
    }

    function getModelReport (enterpriseId) {
      return enterpriseApi.getModelReport(enterpriseId);
    }

    function getModelAllocationReport (modelId) {
      return enterpriseApi.getModelAllocationReport(modelId);
    }

    function assignRepCodes (repCodes) {
      return enterpriseApi.assignRepCodes(repCodes);
    }

    function setContactInfo (data, hasCustomBlock) {
      if (hasCustomBlock) {
        contactInfo = {
          block: data.contactBlock
        };
      } else {
        contactInfo = {
          name: data.name,
          phone: data.phone,
          email: data.email
        };
      }
    }

    function getContactInfo () {
      return contactInfo;
    }

    function setTopLevelDrupalEnterprise (enterprise) {
      topLevelDrupalEnterprise = enterprise;
    }

    function getTopLevelDrupalEnterprise () {
      return topLevelDrupalEnterprise;
    }

    return {
      getActiveAccountEnterprises: getActiveAccountEnterprises,
      setActiveAccountEnterprises: setActiveAccountEnterprises,
      
      getTopLevelDrupalEnterprise: getTopLevelDrupalEnterprise,
      setTopLevelDrupalEnterprise: setTopLevelDrupalEnterprise,

      getModelAllocationReport  : getModelAllocationReport,
      getModelReport            : getModelReport,
      setEnterpriseAccess       : setEnterpriseAccess,
      getEnterpriseAccess       : getEnterpriseAccess,

      getEnterpriseRelationships: getEnterpriseRelationships,
      setEnterpriseRelationships: setEnterpriseRelationships,

      setUserEnterprise         : setUserEnterprise,
      getUserEnterprise         : getUserEnterprise,

      getEnterpriseModels       : getEnterpriseModels,

      getForeignEnterpriseAccess: getForeignEnterpriseAccess,
      setForeignEnterpriseAccess: setForeignEnterpriseAccess,

      isMultiEnterpriseUser: isMultiEnterpriseUser,

      setContactInfo: setContactInfo,
      getContactInfo: getContactInfo,

      setTopLevelEnterpriseId: setTopLevelEnterpriseId,
      getTopLevelEnterpriseId: getTopLevelEnterpriseId,

      getEnterpriseLookupOptions: getEnterpriseLookupOptions,
      setEnterpriseLookupOptions: setEnterpriseLookupOptions,

      loadEnterpriseLookup: loadEnterpriseLookup,
      assignRepCodes: assignRepCodes

    };
  
  }]);