angular.module('AllocationService', [])
.factory('Allocations', [ 'Dashboard', 'SmartXFactory', function(Dashboard, SmartXFactory){

	var allocationApi = SmartXFactory.getAllocationAPI(),
		currentAccount,
		modelID,
		currentModel,
		modelList = [];

	function getModelList(){
		return modelList;
	}

	function setModelList(models){
		modelList = models;
	}

	function createSmaAllocation (data) {

		// make sure all SMA creation uses these two values in the payload
		data = _.extend(data, {
			trackManagerLeverage: true,
			leverage: 1
		});

		return allocationApi.create(data);
	}

	// determine whether the selected account has an existing allocation to a given model
	function hasExistingAllocation(existingAllocations, model){

		var exisintgAllocationIds,
			hasExistingAllocation;

        if (!angular.isDefined(existingAllocations)) return false;

        exisintgAllocationIds = existingAllocations.filter(function(allocation){
            return angular.isDefined(allocation.model);
        }).map(function(allocation){ return allocation.model.id; });

        hasExistingAllocation = _.contains(exisintgAllocationIds, model.id);

        return hasExistingAllocation;
    }

    function hasEnoughCash(accountAvailableCash, modelMinimum){
        if (accountAvailableCash && modelMinimum){
            if (accountAvailableCash < modelMinimum){
                return false;
            } else if (accountAvailableCash > modelMinimum) {
                return true;
            }
        } else {
            return false;
        }
		}
		
		function getHoldings (id) { return SmartXFactory.getAllocationAPI().getAllocationHoldings(id); }
		function getPerformance (id, grouping) { return SmartXFactory.getAllocationAPI().getAllocationPerformancesById(id, {grouping: grouping}); }
		function getStatistics (id) { return SmartXFactory.getAllocationAPI().getAllocationStatisticsById(id); }
		function getAllAllocations (params) { return SmartXFactory.getAllocationAPI().getAllAllocations(params); }
		function getHistory (id) { return SmartXFactory.getAllocationAPI().getAllocationHistory(id); }

	return {
		getPerformance: getPerformance,
		getHoldings		: getHoldings,
		getHistory		: getHistory,
		getStatistics	: getStatistics,
		getAllAllocations		: getAllAllocations,
		hasExistingAllocation 	: hasExistingAllocation,
		hasEnoughCash 			: hasEnoughCash,
		currentAccount 			: currentAccount,
		modelID 				: modelID,
		currentModel 			: currentModel,
		getModelList 			: getModelList,
		setModelList 			: setModelList,
		createSmaAllocation		: createSmaAllocation
	};
}]);