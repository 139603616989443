angular.module('DashboardService', [])
.factory('Dashboard', ['$http', '$sce', 'USER', 'toastr', 'DRUPAL_API_URL', function($http, $sce, USER, toastr, DRUPAL_API_URL){

    var accounts,
        totalAccountsCount,
        userAccess = {},
        tabUsername,
        replicatorUser,
        platform,
        group = {
          id : null,
          guid : null,
          name: null
        },
        features = [],
        //http://stackoverflow.com/questions/12576798/angularjs-how-to-watch-service-variables
        observerCallbacks = [],
        userAccounts = [],
        selectedSearchSort,
        pendingAccounts,
        accessAccounts = {},
        cachedAccountData = {},
        currentAccount,
        selectedAllocationModel,
        selectedAllocationAccount,
        disclaimer = null,
        performancePopoverText = "Allocation Performance is a time-weighted return on investment attributed to your allocation to a given strategy.  It will include the performance of all adjustments made to an allocation (additions and redemptions).  However, if you fully redeem from a strategy and then re-allocate to that strategy, Allocation Performance will only reflect from that new allocation onward.",
        performanceInfoPopover = $sce.trustAsHtml('<span class="allocation-performance-popover" style="color: black;">' + performancePopoverText + '</span>');

    function getTotalAccountsCount () {
      return totalAccountsCount;
    }

    function setTotalAccountsCount (count) {
      return totalAccountsCount = count;
    }

    function getPlatform(){
      return (typeof platform !== 'undefined') ? platform : {
        name: 'SMArtX',
        id: 'ad0fb0f8-6440-4611-802a-d793623e537b'
      };
    }

    function setPlatform(platformObj){
      platform = platformObj;
    }

    function getUserAccess () {
      return userAccess;
    }

    function setUserAccess (access) {
      userAccess = access;
    }

    function setActiveAccount(account){
      console.log("Setting active account: ", account);
      if (typeof account != 'undefined') currentAccount = account;
    }

    function getActiveAccount(){
      console.log("Getting active account... ", currentAccount);
      return currentAccount;
    }

    function updateBrokerageAccountEmailForPerson(guid, personId, payload){
      console.log(payload);
      return $http.post(`${DRUPAL_API_URL}/ng_api/v2/account/${guid}/${personId}/update_email`,
        jQuery.param(payload),
        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
      );
    }

    function getAccountNameByGUID(guid){
      var rtn = null;
      if(typeof accessAccounts != 'undefined' && typeof accessAccounts[guid] != 'undefined'){
        rtn = accessAccounts[guid].information.name;
      }
      return rtn;
    }

    function setAccessAccounts(accounts){
      accessAccounts = accounts;
    }

    function getAccessAccounts(){
      return accessAccounts;
    }

    function getTopModels(){

      var data = $http.get(`${DRUPAL_API_URL}/ng_api/get_top_models`);

      return data;
    }

    function getTopWelcomeModels(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/dashboard-models`);
      return data;
    }

    function getWelcomePayload(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/v2/welcome`);
      return data;
    }

    function getWelcomeResearchPayload(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/v2/welcome/research`);
      return data;
    }

    function getUserData(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/profile`);
      return data;
    }

    function getUserSchemaData(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/get_user_schema`);
      return data;
    }

    function getTabUsername(){
      return (typeof tabUsername != 'undefined') ? tabUsername : USER.username;
    }

    function getUserClientID(){
      return (typeof USER.clientID != 'undefined') ? USER.clientID : USER.username;
    }

    function getUserStorage(){
      var data = $http.get(`${DRUPAL_API_URL}/ng_api/v2/user/storage`);
      return data;
    }

    function setUserGroupStorage(data){

      if(typeof data == 'undefined'){
        debugger;
        return false;
      }

      var req = {
        method: 'POST',
        url:`${DRUPAL_API_URL}/ng_api/v2/user/storage`,
        data:  jQuery.param({
          'storage_key': (typeof tabUsername != 'undefined') ? tabUsername : getUserClientID(),
          'storage_data': data
        }),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      };

      return $http(req);
    }

    function getObserverCallbacks(){
      return observerCallbacks;
    }
    
    function registerObserverCallback(callback){

      if (observerCallbacks.indexOf(callback) === -1){
        observerCallbacks.push(callback);
      }
      //console.log(observerCallbacks);
    }

    function removeObserverCallback(cb){
      observerCallbacks.forEach(function(callback, index){
        if (callback.name === cb.name){
          observerCallbacks.splice(index, 1);
        }
      });
    }

    function notifyObservers(origin, models){

      angular.forEach(observerCallbacks, function(callback){

        if (typeof models !== 'undefined'){
          callback.fn(origin, models);
        } else {
          callback.fn(origin);
        }

      });
    }

    function updateDailyEmailOptIn(status){
      var req = {
        method: 'POST',
        url:`${DRUPAL_API_URL}/ng_api/update_daily_email_opt_in`,
        data:  jQuery.param({'status': status}),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      };

      return $http(req);
    }

    function requestWithdrawal(data){
      var req = {
            url : `${DRUPAL_API_URL}/ng_api/v2/withdrawal`,
            method : 'POST',
            data : data,
            headers: {'Content-Type': 'application/json'}
          };

      return $http(req);
    }

    function JSONtoCSV(data, filename){

      var csvString = Papa.unparse(data);
      saveCsv(csvString, filename);
    }

    // can be called directly with a csv string (ex. from an api response)
    function saveCsv (csvString, filename) {
      var blobObject = new Blob([csvString], {
        type: "text/csv;charset=utf-8;"
      });

      filename = filename ? filename : 'export.csv';
      // Internet Explorer
      if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blobObject, filename);
      } else {
          // Chrome
          var downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blobObject);
          downloadLink.download = filename;
          //downloadLink.target = '_blank';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
      }
    }

    function handleErrorResponse(res){
      if (res.data.msg){
          toastr.error(res.data.msg[0].description);
      } else {
          toastr.error("An unexpected error has occurred.");
      }
    }

    function getSearchSort(){
      return selectedSearchSort;
    }

    function setSearchSort(column){
      selectedSearchSort = column;
    }

    function setReplicatorUser(user){
      replicatorUser = user;
    }

    function getReplicatorUser(){
      return replicatorUser;
    }

    function getUserAccounts () {
      return userAccounts;
    }

    function setUserAccounts (accounts) {
      userAccounts = accounts;
    }

    function setUserFeatures (_features) {
      features = _features;
    }

    function getUserFeatures () {
      return features;
    }

    function getFeaturesByType(type){
      var groupedFeatures = _.groupBy(features, 'relationshipType');
      return groupedFeatures[type];
    }

    // customList allows a subset of the entire features list to be passed in
    // if no customList is supplied, the default will be the list of features that were set from the GET users/access response
    // example: get a list by type and use that as the customList
    function getAssignmentsByFeature (featureId, customList) {
      var items = customList || features;
      var assignmentFeatures = _.map(items, 'feature');
      return assignmentFeatures.filter(function(feature){ return feature.id === featureId; });
    }

    function getAssignmentsByOwnerId (ownerId, customList) {
      var items = customList || features;
      return items.filter(function(feature){ return feature.ownerId === ownerId; });
    }

    function normalizeName (name) {
			return name ? name.toLowerCase().replace(/ /g, '') : '';
		}
    
    function getAllowedByBroker (featureName, brokerageName) {
      var brokerFeatures = _.filter(features, function (feature) { 
        return feature.type === 'brokerage' && normalizeName(feature.feature.name) === normalizeName(featureName); 
      });
      console.log(brokerFeatures);
      var brokerageAssignment = _.find(
                                  brokerFeatures,
                                  function(feature) {
                                    return normalizeName(feature.owner.name) === normalizeName(brokerageName);
                                  }
                                );

      return brokerageAssignment ? brokerageAssignment.isActive : false;
    }

    var modelSummaries = null;
    function setModelSummaries (models){
      modelSummaries = models;
    }

    function getModelSummaries () {
      return modelSummaries;
    }

    function setCustomDisclaimer (_disclaimer) {
      disclaimer = _disclaimer;
    }

    function getCustomDisclaimer () {
      return disclaimer;
    }

    function toastError (msg, err) {
      if (msg) msg = msg.replace(/\\n/g, "<br/>");
      toastr.error(msg, 'Error', {
        allowHtml: true,
        closeButton: true,
        closeHtml: '<button class="toast-close">&times;</button>',
        timeOut: 0,
        extendedTimeOut: 0
      });

      if (err) {
        console.error(err);
      }
    }

    return {
      saveCsv: saveCsv,
      toastError: toastError,
      setModelSummaries: setModelSummaries,
      getModelSummaries: getModelSummaries,
      getAllowedByBroker: getAllowedByBroker,
      getAssignmentsByOwnerId: getAssignmentsByOwnerId,
      getAssignmentsByFeature: getAssignmentsByFeature,
      setUserFeatures: setUserFeatures,
      getUserFeatures: getUserFeatures,
      getFeaturesByType: getFeaturesByType,
      setUserAccounts: setUserAccounts,
      getUserAccounts: getUserAccounts,
      setUserAccess: setUserAccess,
      getUserAccess: getUserAccess,
      // getEnterpriseRelationships: getEnterpriseRelationships,
      // setEnterpriseRelationships: setEnterpriseRelationships,
      // enterpriseRelationships: enterpriseRelationships,
      getReplicatorUser: getReplicatorUser,
      setReplicatorUser: setReplicatorUser,
      handleErrorResponse: handleErrorResponse,
      getPlatform: getPlatform,
      setPlatform: setPlatform,

      getActiveAccount: getActiveAccount,
      setActiveAccount: setActiveAccount,
      
      requestWithdrawal: requestWithdrawal,
      // addRiaRoleToUser : addRiaRoleToUser,
      getTabUsername : getTabUsername,
      getUserClientID : getUserClientID,
      updateDailyEmailOptIn : updateDailyEmailOptIn,
      
      getTopModels : getTopModels,
      getUserData : getUserData,
      getUserSchemaData : getUserSchemaData,
      
      // getBrokerageAccountPeopleForGUID: getBrokerageAccountPeopleForGUID,
      updateBrokerageAccountEmailForPerson: updateBrokerageAccountEmailForPerson,
      
      // getSearchData : getSearchData,
      getTopWelcomeModels : getTopWelcomeModels,
      getWelcomePayload   : getWelcomePayload,
      getWelcomeResearchPayload  : getWelcomeResearchPayload,
            
      getAccountNameByGUID : getAccountNameByGUID,
      // getAccountAccess: getAccountAccess,
      accounts : accounts,
      pendingAccounts : pendingAccounts,
      setAccessAccounts: setAccessAccounts,
      getAccessAccounts: getAccessAccounts,

      // getPortfolioPerformance : getPortfolioPerformance,
      
      cachedAccountData : cachedAccountData,
      currentAccount : currentAccount,

      // these calls broadcast actions to various tabs while navigating
      // an alternative to $broadcast
      notifyObservers : notifyObservers,
      registerObserverCallback : registerObserverCallback,
      selectedAllocationModel : selectedAllocationModel,
      selectedAllocationAccount : selectedAllocationAccount,
      getObserverCallbacks : getObserverCallbacks,
      removeObserverCallback : removeObserverCallback,

      performanceInfoPopover : performanceInfoPopover,
      JSONtoCSV              : JSONtoCSV,

      getUserStorage         : getUserStorage,
      setUserGroupStorage    : setUserGroupStorage,

      getSearchSort          : getSearchSort,
      setSearchSort          : setSearchSort,
      
      setCustomDisclaimer   : setCustomDisclaimer,
      getCustomDisclaimer    : getCustomDisclaimer,

      getTotalAccountsCount: getTotalAccountsCount,
      setTotalAccountsCount: setTotalAccountsCount
    };

}]);