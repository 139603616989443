angular.module('scrollToItem', [])
.directive('scrollToModel', function() {                                                      
    return {                                                                                 
        restrict: 'A',                                                                                                                                                          
        link: function(scope, $elm, attr) {                                                   
            $elm.on('click', function(event) {

                var padding = jQuery(window).height() * 0.10;
                if (!scope.expanded){
                    $modelOffset = jQuery(event.target).offset().top; 
                    jQuery('body').animate({scrollTop: $modelOffset - padding}, 600);
                } else {
                    console.log(jQuery(event.target).prev().offset());

                    //$modelOffset = jQuery(event.target).offset().top; 
                    //jQuery('body').animate({scrollTop: $modelOffset - padding}, 600);
                }
            });                                                                              
        }                                                                                    
    }
});