angular.module('CRDViewerCtrl', [])
  .controller('CRDViewerCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'Dashboard', 'crdURL', function($scope, $rootScope, $uibModalInstance, toastr, Dashboard, crdURL) {

    var vm = this;

    vm.crdURL = crdURL;

    init();

    /////////////

    vm.cancel = cancel;

    function init() {

    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

  }]);
