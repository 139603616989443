angular.module('LiquidationService', [])
.factory('Liquidations', [ 'Dashboard', function(Dashboard, $http){

	
	var currentAccount,
		modelID,
		currentModel,
		models,
		accounts = Dashboard.accounts,
		defaultPercentages =  [
	        { value: 1, percentage: 20, selected:false },
	        { value:2, percentage: 40, selected:false },
	        { value:3, percentage: 60, selected:false },
	        { value:4, percentage: 80, selected:false },
	        { value:5, percentage: 100, selected:false }
	    ];

	function getAccountIndex(account){
		console.log(Dashboard.accounts);
		return accounts.indexOf(account);
	}

	function createNewRedemption(){}

	return {
		currentAccount 		: currentAccount,
		modelID 			: modelID,
		currentModel 		: currentModel,
		models 				: models,
		defaultPercentages 	: defaultPercentages,
		getAccountIndex 	: getAccountIndex,
		createNewRedemption : createNewRedemption
	};

}]);