/*
Deprecated
 */
var OpenBrokerage = angular.module('OpenBrokerageCtrl', []);
OpenBrokerage.controller("OpenBrokerageCtrl", function (Tabs, $timeout, $scope, $rootScope, $http, $location, $window, toastr, USER, SweetAlert, Brokerage, Dashboard, AccountSettings, DRUPAL_API_URL) {

    $scope.hcv_user = {};

    // we will store all of our form data in this object
    $scope.formData = {};
    $scope.reviewModel = {
        //'f_name': $scope.hcv_user.profile.field_profile_first_name.und.value
    };

    $scope.sideMenu = [
        { title: 'Review', complete: false, disable: false, active: true },
        { title: 'Personal', complete: false, disable: true, active: false },
        { title: 'Account Type', complete: false, disable: true, active: false },
        { title: 'Sign IMA', complete: false, disable: true, active: false },
        { title: 'IB Documents', complete: false, disable: true, active: false },
        { title: 'Submit Application', complete: false, disable: true, active: false }
    ];

    $scope.personalModel = { };
    $scope.accountTypeModel = { };
    $scope.IMAModel = { signedAllDocs : false, eSignature: '', readDocs: [] };
    $scope.IBModel = { signedAllDocs : false, readDocs: []   };
    $scope.socialModel = { };
    $scope.formSubmitted = false;
    $scope.completedIBForms = false;
    $scope.currentPage = $scope.sideMenu[0].title;
    readDocs = {};
    readDocsHCV = {};


    $scope.initForm = function(){
        Dashboard.getUserData()
        .then(function(result){
            var obj = angular.fromJson(result);
            var hcv_user = angular.fromJson(obj.data);
            var additionalData = angular.fromJson(hcv_user.profile.field_profile_additional_info.und[0].value);

            $scope.full_name = '';
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_first_name) ){
                $scope.reviewModel.f_name = hcv_user.profile.field_profile_first_name.und[0].value;
                $scope.full_name += ($scope.reviewModel.f_name + ' ');
            }

            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_last_name) ){
                $scope.reviewModel.l_name = hcv_user.profile.field_profile_last_name.und[0].value;
                $scope.full_name += $scope.reviewModel.l_name;
            }

            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_middle_name) ){
                $scope.reviewModel.m_name = hcv_user.profile.field_profile_middle_name.und[0].value;
                //$scope.full_name += ( ' ' + $scope.reviewModel.m_name.charAt(0).toUpperCase() );
            }
            $scope.full_name = $scope.full_name.replace(/ +(?= )/g,'');
            $scope.full_name = $scope.full_name.trim();

            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_address) ){
                $scope.reviewModel.primary_address1 = hcv_user.profile.field_profile_address.und[0].value;
            }        
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_address_2) ){
                $scope.reviewModel.primary_address2 = hcv_user.profile.field_profile_address_2.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_city) ){
                $scope.reviewModel.primary_city = hcv_user.profile.field_profile_city.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_state) ){
                $scope.reviewModel.primary_state = hcv_user.profile.field_profile_state.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_zip) ){
                $scope.reviewModel.primary_zip = hcv_user.profile.field_profile_zip.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_country1) ){
                $scope.reviewModel.primary_country = hcv_user.profile.field_profile_country1.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_phone_number) ){
                $scope.reviewModel.primary_phone = hcv_user.profile.field_profile_phone_number.und[0].value;
            }

            if(USER.is_ria == false){

                $scope.reviewModel.primary_employment_type = hcvSetEmploymentType( additionalData.primary_employment_type );
                $scope.reviewModel.primary_experience_education = additionalData.primary_experience_education.replace(/_/g,' ');

                if(typeof(additionalData.primary_experience_overall) != 'undefined')
                    $scope.reviewModel.primary_experience_overall = additionalData.primary_experience_overall.replace('primary_experience_overall','');
                if(typeof(additionalData.primary_experience_risk) != 'undefined')
                    $scope.reviewModel.primary_experience_risk = additionalData.primary_experience_risk.replace('primary_experience_risk','');
                if(typeof(additionalData.primary_experience_loses) != 'undefined')
                    $scope.reviewModel.primary_experience_loses = additionalData.primary_experience_loses.replace('primary_experience_loses','');
                if(typeof(additionalData.primary_experience_retirement) != 'undefined')
                    $scope.reviewModel.primary_experience_retirement = additionalData.primary_experience_retirement.replace('primary_experience_retirement','');

                
                if(typeof(additionalData.primary_financial_net_worth) != 'undefined')
                    $scope.reviewModel.primary_financial_net_worth = '$'+additionalData.primary_financial_net_worth;
                if(typeof(additionalData.primary_financial_liq_net_worth) != 'undefined')
                    $scope.reviewModel.primary_financial_liq_net_worth = '$'+additionalData.primary_financial_liq_net_worth;
                if(typeof(additionalData.primary_financial_ann_net_income) != 'undefined')
                    $scope.reviewModel.primary_financial_ann_net_income = '$'+additionalData.primary_financial_ann_net_income;
                if(typeof(additionalData.primary_financial_ann_expenses) != 'undefined')
                    $scope.reviewModel.primary_financial_ann_expenses = '$'+additionalData.primary_financial_ann_expenses;
                if(typeof(additionalData.primary_financial_total_assets) != 'undefined')
                    $scope.reviewModel.primary_financial_total_assets = '$'+additionalData.primary_financial_total_assets;

                $scope.reviewModel.primary_regulatory_question1_checkbox = additionalData.primary_regulatory_question1_checkbox;
                $scope.reviewModel.primary_regulatory_question2_checkbox = additionalData.primary_regulatory_question2_checkbox;
                $scope.reviewModel.primary_regulatory_question3_checkbox = additionalData.primary_regulatory_question3_checkbox;
                $scope.reviewModel.primary_regulatory_question4_checkbox = additionalData.primary_regulatory_question4_checkbox;
                $scope.reviewModel.primary_regulatory_question5_checkbox = additionalData.primary_regulatory_question5_checkbox;

                /*
                $scope.reviewModel.primary_employment_type = hcvFormatString(additionalData.primary_employment_type);
                $scope.reviewModel.primary_experience_overall = additionalData.experience_overall;
                $scope.reviewModel.primary_experience_risk = additionalData.experience_risk;
                $scope.reviewModel.primary_experience_loses = additionalData.experience_loses;
                $scope.reviewModel.primary_experience_retirement = additionalData.experience_retirement;
                */

                $scope.reviewModel.primary_financial_net_worth = additionalData.primary_financial_net_worth;
                $scope.reviewModel.primary_financial_liq_net_worth = additionalData.primary_financial_liq_net_worth;
                $scope.reviewModel.primary_financial_ann_net_income = additionalData.primary_financial_ann_net_income;
                $scope.reviewModel.primary_financial_ann_expenses = additionalData.primary_financial_ann_expenses;
                $scope.reviewModel.primary_financial_total_assets = additionalData.primary_financial_total_assets;
                
                $scope.reviewModel.primary_regulatory_question1_checkbox = additionalData.primary_regulatory_question1_checkbox;
                $scope.reviewModel.primary_regulatory_question2_checkbox = additionalData.primary_regulatory_question2_checkbox;
                $scope.reviewModel.primary_regulatory_question3_checkbox = additionalData.primary_regulatory_question3_checkbox;
                $scope.reviewModel.primary_regulatory_question4_checkbox = additionalData.primary_regulatory_question4_checkbox;
                $scope.reviewModel.primary_regulatory_question5_checkbox = additionalData.primary_regulatory_question5_checkbox;
            }

            //console.log($scope.reviewModel);

            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_gender) ){
                $scope.personalModel['primary_basic-gender'] = hcv_user.profile.field_profile_gender.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_date_of_birth) ){
                $scope.personalModel['dob'] = hcv_user.profile.field_profile_date_of_birth.und[0].value;
            }

            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_marital_status) ){
                $scope.personalModel['primary_basic-marital'] = hcv_user.profile.field_profile_marital_status.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_number_dependents) ){
                $scope.personalModel['primary_basic-dependents'] = hcv_user.profile.field_profile_number_dependents.und[0].value;
            }
            if( hcvObjectFieldHasData(hcv_user.profile.field_profile_country_of_birth) ){
                $scope.personalModel['country-of-birth'] = hcv_user.profile.field_profile_country_of_birth.und[0].value;
            }

            $scope.IMAModel.signedAllDocs = false;
            $scope.IBModel.signedAllDocs = false;

            /*
            */        
        });
    };

    $scope.initForm();
    


    $scope.sideMenu = [
        { title: 'Review', complete: false, disable: false, active: true },
        { title: 'Personal', complete: false, disable: true, active: false },
        { title: 'Account Type', complete: false, disable: true, active: false },
        { title: 'HedgeCoVest Documents', complete: false, disable: true, active: false },
        { title: 'IB Documents', complete: false, disable: true, active: false },
        { title: 'Submit Application', complete: false, disable: true, active: false }
    ];
    $scope.currentStep = 1;
    $scope.setCurrentStepTo = function(step){
        $scope.currentStep = step;
    };

    $scope.stepBack = function(){
        $scope.sideMenu[$scope.currentStep].active = false;
        $scope.sideMenu[$scope.currentStep-1].active = true;
        $scope.currentStep = $scope.currentStep-1;
        $scope.currentPage = $scope.sideMenu[$scope.currentStep].title;
    };

    $scope.activeDoc = 0;
    $scope.documentHCVSignatures = [
        { name: 'Investment Management Agreement', active: true, signedOn: '' },
        { name: 'Disclosure Document', active: false, signedOn: '' },
        { name: 'Authorization to Debit Fees', active: false, signedOn: '' }
    ];
    $scope.documentIBSignatures = [
        { name: 'Client FA Agreement', active: true, signedOn: '', formID: 'Form6105' },
        { name: 'Agreement Limiting Interactive Brokers Liability', active: false, signedOn: '', formID: 'Form6108' },
        { name: 'Legal Acknowledgement', active: false, signedOn: '', formID: 'Form2109' },
        { name: 'Margin Agreement- Reg-T', active: false, signedOn: '', formID: 'Form1005' },
        { name: 'Indiv/Joint/Trust', active: false, signedOn: '', formID: 'Form3007' },
        { name: 'Mutual Fund Information Electronically', active: false, signedOn: '', formID: 'Form4035' },
        { name: 'General Disclosure on Mutal Funds', active: false, signedOn: '', formID: 'Form4036' },
        { name: 'OCC Risk Disclosure', active: false, signedOn: '', formID: 'Form4003' },
        { name: 'ISE Disclosure for Option Orders Over 500 Contracts', active: false, signedOn: '', formID: 'Form3076' },
        { name: 'General Agreements/Disclosures', active: false, signedOn: '', formID: 'Form3070,Form3071,Form2192,Form3024,Form3094,Form3074,Form9130,Form4016' }
    ];

    function getSignedOnDate(){
        var currentdate = new Date();
        var mm = currentdate.getMonth()+1; //January is 0!
        var dd = currentdate.getDate();

        var yyyy = currentdate.getFullYear();
        if(dd<10){ dd='0'+dd; } 
        if(mm<10){ mm='0'+mm; } 
        var today = yyyy+'-'+mm+'-'+dd;
        var time = currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
        return (today+" "+time);
    }


    $scope.viewHCVDocument = function(idx){
        for (var i = $scope.documentHCVSignatures.length - 1; i >= 0; i--) {
            $scope.documentHCVSignatures[i].active = false;
        }
        $scope.documentHCVSignatures[idx].active = true;
    };

    $scope.signHCVDocument = function(idx){

        //console.log('Signing doc '+idx);
        var signedOn = getSignedOnDate();

        readDocsHCV[$scope.documentHCVSignatures[idx].name] = signedOn;

        //console.log('Signing doc '+idx+' at '+signedOn);
        $scope.documentHCVSignatures[idx].signedOn = $scope.documentHCVSignatures[idx].name + " signed on " + signedOn;
        $scope.documentHCVSignatures[idx].active = false;

        if(idx<$scope.documentHCVSignatures.length-1){
            $scope.documentHCVSignatures[idx+1].active = true;
        }


        //console.log('updating documentHCVSignatures');
        if( $scope.documentHCVSignatures[0].signedOn != '' && $scope.documentHCVSignatures[1].signedOn != '' && $scope.documentHCVSignatures[2].signedOn != '' ){
            $scope.IMAModel.signedAllDocs = true;
            $scope.$parent.model.signedAllDocs = true;
        }else{
            $scope.IMAModel.signedAllDocs = false;
        }

        $scope.$watch('IMAModel.eSignature', function(newVal, oldVal){
            console.log(newVal + ' | ' + oldVal);
        });

        event.preventDefault();
        return false;
    };

    $scope.viewIBDocument = function(idx){
        for (var i = $scope.documentIBSignatures.length - 1; i >= 0; i--) {
            $scope.documentIBSignatures[i].active = false;
        }
        $scope.documentIBSignatures[idx].active = true;
    };
    $scope.signIBDocument = function(event, idx){

        var signedOn = getSignedOnDate();

        event.preventDefault();
        event.stopPropagation();
        
        $scope.documentIBSignatures[idx].signedOn = $scope.documentIBSignatures[idx].name + " signed on " + signedOn;
        $scope.documentIBSignatures[idx].active = false;

        if(idx<$scope.documentIBSignatures.length-1)
            $scope.documentIBSignatures[idx+1].active = true;


        if( $scope.documentIBSignatures[idx].formID.indexOf(',') >= 0 ){
            var res = $scope.documentIBSignatures[idx].formID.split(',');

            for (var i = res.length - 1; i >= 0; i--) {
                readDocs[res[i]] = signedOn;
            }
        }else{
            readDocs[$scope.documentIBSignatures[idx].formID] = signedOn;
        }

        for (var i = $scope.documentIBSignatures.length - 1; i >= 0; i--) {
            if($scope.documentIBSignatures[i].signedOn == ''){
                //$scope.IBModel.signedAllDocs = false;
                return false;
            }
        }

        $scope.IBModel.signedAllDocs = true;
        $scope.$parent.model.signedAllDocs = true;

        $scope.completedIBForms = true;
        console.log( $scope.documentIBSignatures );

        return false;
    };

    $scope.validateStep = function(step){

        $scope.currentPage = $scope.sideMenu[0].title;

        console.log('Validateing step: '+ step);
        //debugger;

        switch(step){
            case 1:
                $scope.sideMenu[1].complete = true;
                $scope.sideMenu[1].disable = false;
                $scope.sideMenu[1].active = false;

                $scope.sideMenu[2].disable = false;
                $scope.sideMenu[2].active = true;

                $scope.syncData($scope.personalModel);
                break;
            case 2:
                $scope.sideMenu[2].complete = true;
                $scope.sideMenu[2].disable = false;
                $scope.sideMenu[2].active = false;

                $scope.sideMenu[3].disable = false;
                $scope.sideMenu[3].active = true;

                //$scope.syncData($scope.accountTypeModel);
                break;
            case 3:
                $scope.sideMenu[3].complete = true;
                $scope.sideMenu[3].disable = false;
                $scope.sideMenu[3].active = false;

                $scope.sideMenu[4].disable = false;
                $scope.sideMenu[4].active = true;

                $scope.syncData($scope.IMAModel);

                if( $scope.accountTypeModel.account_type == "ira" || $scope.accountTypeModel.account_type == "joint" || $scope.accountTypeModel.account_type == "entity"){
                    SweetAlert.swal({
                        title: "Action Needed",
                        text: "To complete the rest of your "+$scope.accountTypeModel.account_type.toUpperCase()+" application you will be redirected to InteractiveBrokers.com. Please click this link to proceed:<br/><div class='sa-button-container'><a target='_blank' href='https://gdcdyn.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=8083492&token=71472&invitedBy=YWxleHRoNTg4' style='word-wrap:break-word; margin-top: 1em;'><button class='confirm link-to-ib'>Continue to IB <span class='fa fa-external-link'></span></button></a></div><br/>",
                        type: "warning",
                        html : true,
                        showConfirmButton: false,
                        showCancelButton: false,
                        closeOnConfirm: true,
                        //showCancelButton: true,
                        },
                        function(isConfirm){

                            if (isConfirm){
                                $scope.openNewBrokerageAccount();
                                jQuery('.sweet-alert').remove();
                                var tabIndex = $scope.tabs.indexOf($scope.tab);
                                $scope.removeTab(tabIndex);
                                var lastDashTab = Tabs.getLastDashboard();
                                Tabs.setActiveTab(lastDashTab);
                                
                                // var url = "https://gdcdyn.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=8083492&token=71472&invitedBy=YWxleHRoNTg4";
                                // var win = window.open(url, '_blank');
                                // win.focus();
                            }
                    }); 
                }

                break;
            case 4:
                $scope.sideMenu[4].complete = true;
                $scope.sideMenu[4].disable = false;
                $scope.sideMenu[4].active = false;

                $scope.sideMenu[5].disable = false;
                $scope.sideMenu[5].active = true;

                // $scope.syncData($scope.IBModel);
                break;
            case 5:
                $scope.sideMenu[5].complete = true;
                $scope.sideMenu[5].disable = false;
                $scope.sideMenu[5].active = false;

                $scope.sideMenu[6].disable = false;
                $scope.sideMenu[6].active = true;

                $scope.syncData($scope.socialModel);
                break;
            case 0:
            default:
                $scope.sideMenu[0].complete = true;
                $scope.sideMenu[0].disable = false;
                $scope.sideMenu[0].active = false;

                $scope.sideMenu[1].disable = false;
                $scope.sideMenu[1].active = true;

                $scope.sideMenu[2].disable = true;
                $scope.sideMenu[3].disable = true;
                $scope.sideMenu[4].disable = true;

                $scope.syncData($scope.reviewModel);
                break;
        }
        $scope.currentStep ++;
    };
    
    $scope.syncData = function(model){
        if(jQuery.map(model, function(n, i) { return i; }).length){
            console.log('Saving Model', model);

            var url = `${DRUPAL_API_URL}/ng_api/open_brokerage_account`;
            var payload = {};

            $scope.sideMenu[$scope.currentStep].active = true;
            payload.step = $scope.sideMenu[$scope.currentStep].title;
            payload.model = model;

            var req = {
              method: 'POST',
              url: url,
              data:  jQuery.param(payload),
              headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            };

            console.log(req);
            //debugger

            $http(req)
            .then(function(result){
                var data = angular.fromJson(result.data);

                console.log(result);
                if(data.status == 'success'){
                    console.log('fromJson -> ', data);
                    toastr.success('Step saved.', 'Brokerage');
                }else{
                    console.log('Uh oh!! Could not sync:(');
                }
            });
        }
    };

    $scope.openNewBrokerageAccount = function(){
        console.log('openNewBrokerageAccount');
        $scope.formSubmitted = true;

        var url = `${DRUPAL_API_URL}/ng_api/submit_brokerage_account`;
        var payload = {
            accountTypeModel    : $scope.accountTypeModel,
            IMAModel            : $scope.IMAModel,
            IBModel             : $scope.IBModel,
            socialModel         : $scope.socialModel,
        };

        payload = {
            primary_account_type : $scope.accountTypeModel.account_type.toUpperCase(),
            primary_account_margin_type : $scope.accountTypeModel.account_margin ? 'yes' : 'no',
            primary_document_type : 'ssn',
            primary_document_type_number : $scope.socialModel.social_security,
            primary_document_issuance_country : "United States",
            client_esignature : $scope.IMAModel.eSignature,
            hcv_docs : readDocsHCV,
            ib : readDocs
        };

        var req = {
            method: 'POST',
            url: url,
            data: jQuery.param(payload),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        };

        $http(req)
        .then(function(result){
            console.log(result);
            var data = angular.fromJson(result.data);

            if(data.status == "success"){


                if ($scope.accountTypeModel.account_type === 'joint' || $scope.accountTypeModel.account_type === 'entity' || $scope.accountTypeModel.account_type === 'ira'){
                    SweetAlert.swal({
                            title: 'Almost there!',
                            text: '<div style="color: black">To complete your brokerage account application, please complete the steps on the Interactive Brokers website. If you need any help, give us a call at <a href="tel:+15618358690">(561) 835-8690</a> or send an e-mail to <address><a href="mailto:support@hedgecovest.com">support@hedgecovest.com</a></address></div>',
                            showConfirmButton: true,
                            html: true
                        }, 

                        function(isConfirm){
                            // var tabIndex = $scope.tabs.indexOf($scope.tab);
                            // $scope.removeTab(tabIndex);
                            // var lastDashTab = Tabs.getLastDashboard();
                            // Tabs.setActiveTab(lastDashTab);
                            jQuery('.sweet-alert').remove();
                            toastr.success('New brokerage account pending.', 'Brokerage');
                        });
                } else {


                    SweetAlert.swal({
                        title: "Brokerage Account Submitted!",
                        text: "In most cases, accounts are opened within 48 hours.  However, in certain scenarios, Interactive Brokers will ask for additional information on the account holders.  We will contact you if more information is needed or when the account is opened.  You can continue to use your paper trading account and/or your other accounts while Interactive Brokers reviews your application.",
                        type: "success",
                        closeOnConfirm: true,
                        },
                        function(isConfirm){
                            if (isConfirm){
                                var tabIndex = $scope.tabs.indexOf($scope.tab);
                                $scope.removeTab(tabIndex);
                                var lastDashTab = Tabs.getLastDashboard();
                                Tabs.setActiveTab(lastDashTab);
                                jQuery('.sweet-alert').remove();
                                toastr.success('New brokerage account pending.', 'Brokerage');
                            }   
                        });
                }

            }else{
                console.log('Uh oh!! Could not sync:(');
            }
        });
    };



    function hcvFormatString(elString){
        if(typeof(elString) == 'undefined' || elString.length == 0){
            return '';
        }

        var rtn = elString;
        rtn = rtn.replace(/_/g, ' ');
        rtn = rtn.replace(/-/g, ' ');

        return (rtn) ? rtn.replace(/([^\W_]+[^\s-]*) */g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
    }

    function hcvObjectFieldHasData(object){
        if( typeof(object) != 'undefined' ){
            //console.log(object);
            if( typeof(object.und) != 'undefined' ){
                if( object.und.length ){
                    if( object.und[0].value!=null ){
                        return true;
                    }
                }
            }
        }
        return false;
    }


    function hcvSetEmploymentType(type){

        switch(type){
            case 'employed':
            case 'Employed':
                return 'Employed';
                break;
            case 'self-employed':
            case 'Self-Employed':
                return 'Self-Employed';
                break;
            case 'retired':
            case 'Retired':
                return 'Retired';
                break;
            case 'unemployed':
            case 'Unemployed':
                return 'Unemployed';
                break;
            case 'student':
            case 'Student/Intern':
                return 'Student/Intern';
                break;
            case 'athometrader':
            case 'At Home Trader':
                return 'At Home Trader';
                break;
            case 'homemaker':
            case 'Homemaker':
                return 'Homemaker';
                break;
            default:
                return '';
        }
    }




    $scope.reviewModel.editingStep = [
        { isEnabled: false, editingText: 'Edit Step' },
        { isEnabled: false, editingText: 'Edit Step' },
        { isEnabled: false, editingText: 'Edit Step' },
        { isEnabled: false, editingText: 'Edit Step' },
        { isEnabled: false, editingText: 'Edit Step' }
    ];
    $scope.reviewFields = [
        {
            template: '<p>Confirm your personal information to proceed.</p><a class="edit-step pull-right" href ng-click="toggleEditStep(0, \'Review\');">Edit Step</a>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    //console.log('toogle step '+step, $scope.model);
                    //console.log(section);
                    Brokerage.setStep(step);
                    //console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            },
        },{
            template: '<div class="group-header"><h4 class="step-title pull-left">General</h4></div>'
        },{
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'f_name',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'First Name',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'm_name',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Middle Name',
                        disabled: true,
                    },
                },{
                    key: 'l_name',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                      label: 'Last Name',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                }
            ]
        },
        {   template: '<div class="group-header"><a class="edit-step pull-right" href ng-click="toggleEditStep(1, \'Review\');">Edit Step</a></div>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    console.log('toogle step '+step, $scope.model);
                    console.log(section);
                    Brokerage.setStep(step);
                    console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            }
        }, {
            template: '<div class="group-header"><h4 class="step-title pull-left">Contact</h4></div>'
        },
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_address1',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                      label: 'Address',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'primary_address2',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Address 2',
                        disabled: true,
                    },
                },{
                    key: 'primary_city',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'City',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'primary_state',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'State',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'primary_zip',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Zip',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'primary_country',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Country',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                },{
                    key: 'primary_phone',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Phone',
                        disabled: true,
                    },
                }
            ]
        },{
            template: '<div class="group-header"><h4 class="step-title pull-left">Employment</h4><a class="edit-step pull-right" href ng-click="toggleEditStep(2, \'employment\');">Edit Step</a></div>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    console.log('toogle step '+step, $scope.model);
                    console.log(section);
                    Brokerage.setStep(step);
                    console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            },
            hideExpression: function($viewValue, $modelValue, scope) {
              return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
            }
        },{
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'primary_employment_type',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Status',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
            ]
        },{
            template: '<div class="group-header"><h4 class="step-title pull-left">Experience</h4><a class="edit-step pull-right" href ng-click="toggleEditStep(3, \'experience\');">Edit Step</a></div>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    console.log('toogle step '+step, $scope.model);
                    console.log(section);
                    Brokerage.setStep(step);
                    console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            },
            hideExpression: function($viewValue, $modelValue, scope) {
              return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
            }
        },{
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'primary_experience_education',
                    className: 'col-md-12 col-xs-12 text-capitalize',
                    type: 'input',
                    templateOptions: {
                        label: 'Education',
                        //options : AccountSettings.education,
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_experience_overall',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Overall Trading Experience',
                        //options: Brokerage.overallExperienceOpts,
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_experience_risk',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Risk Experience',
                        //options: Brokerage.riskExperienceOpts,
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_experience_loses',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Loses Experience',
                        //options: Brokerage.losesExperienceOpts,
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_experience_retirement',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Retirement Experience',
                        //options: Brokerage.retirementExperienceOpts,
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
            ]
        },{
            template: '<div class="group-header"><h4 class="step-title pull-left">Financial</h4><a class="edit-step pull-right" href ng-click="toggleEditStep(4,\'financial\');">Edit Step</a></div>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    console.log('toogle step '+step, $scope.model);
                    console.log(section);
                    Brokerage.setStep(step);
                    console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            },
            hideExpression: function($viewValue, $modelValue, scope) {
              return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
            }
        },{
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'primary_financial_net_worth',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Net Worth',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_financial_liq_net_worth',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Liquid Net Worth',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_financial_ann_net_income',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Annual Net Income',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_financial_ann_expenses',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Annual Expenses',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_financial_total_assets',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Total Assets',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
            ]
        },{
            template: '<div class="group-header"><h4 class="step-title pull-left">Regulatory</h4><a class="edit-step pull-right" href ng-click="toggleEditStep(5, \'regulatory\');">Edit Step</a></div>',
            controller: /*@ngInject*/ function($scope) {
                $scope.toggleEditStep = function(step, section){
                    console.log('toogle step '+step, $scope.model);
                    console.log(section);
                    Brokerage.setStep(step);
                    console.log(Brokerage.editingStep);
                    var exists = Tabs.addTab('profile').exists;
                    if (exists){
                        Dashboard.notifyObservers('brokerage');
                    }
                    //Brokerage.editingStep = step;
                    //$scope.model.editingStep[step-1].isEnabled = !$scope.model.editingStep[step-1].isEnabled;
                    //Tabs.addTab('profile');

                };

            },
            hideExpression: function($viewValue, $modelValue, scope) {
              return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
            }
        },{
            className: 'clearfix',
            fieldGroup: [
                 {
                    key: 'primary_regulatory_question1_checkbox',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Are you or anybody in your household affiliated with a broker/dealer?',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_regulatory_question2_checkbox',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Are you affiliated with an Exchange or Self-Regulatory Organization?',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_regulatory_question3_checkbox',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Have you ever been subject of or initiated litigation, arbitration, or some type of dispute or settlement procedure with a broker/dealer?',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_regulatory_question4_checkbox',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Have you ever been subject of an investigation or proceeding by any Regulatory or Self-Regulatory Agency?',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
                 {
                    key: 'primary_regulatory_question5_checkbox',
                    className: 'col-md-12 col-xs-12',
                    type: 'input',
                    templateOptions: {
                        label: 'Are you the director, 10% shareholder, or policy-making officer of a publicly traded company?',
                        required: true,
                        disabled: true,
                        validation: {
                            show: true
                        }
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                      return (USER.is_ria == true || parseInt(USER.is_ria) == 1);
                    }
                },
            ]
        }
    ];

    
    var maxDOBAge = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

    $scope.personalFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'primary_basic-gender',
                    className: 'col-md-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Gender',
                        options: Brokerage.genderOpts,
                        required: true,
                        validation: {
                            show: true
                        }
                    }
                },{
                    key: 'dob',
                    className: 'col-md-6',
                    type: 'datepicker',
                    templateOptions: {
                        label: 'Date of Birth (Must be 18+)',
                        required: true,
                        validation: { show: true },
                        type: "text",

                        datepickerMode: 'year',
                        showWeeks: false,
                        maxDate : maxDOBAge
                    }
                },{
                    key: 'primary_basic-marital',
                    className: 'col-md-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Marital Status',
                        options: Brokerage.maritalOpts,
                        required: true,
                        validation: {
                            show: true
                        }
                    }
                },{
                    key: 'primary_basic-dependents',
                    className: 'col-md-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Number of Dependents',
                        options: Brokerage.dependentsOpts,
                        required: true,
                        validation: {
                            show: true
                        }
                    }
                },{
                    key: 'country-of-birth',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Country of birth',
                        required: true,
                        options: AccountSettings.countries
                    }
                }
            ]
        },
    ];

    
    $scope.accountTypeFields = [
        {
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'account_type',
                    className: 'col-md-12',
                    type: 'account_type',
                    /*
                    controller: function($scope){
                        $scope.processAccountType = function (name)
                        {
                            if(name == "IRA" || name == "Joint" || name == "Entity"){

                                SweetAlert.swal({
                                    title: "Action Needed",
                                    text: "The rest of your application will need to be completed on InteractiveBrokers.com. Would you like to proceed?",
                                    type: "warning",
                                    closeOnConfirm: true
                                    },
                                    function(isConfirm){
                                        var url = "https://gdcdyn.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=8083492&token=71472&invitedBy=YWxleHRoNTg4";
                                        var win = window.open(url, '_blank');
                                        win.focus();
                                        window.location.replace("/v2/dashboard");
                                });                                
                            }
                        }
                    },
                    */
                    templateOptions: {
                        label: '',
                        options: Brokerage.accountType,
                        required: true,
                        validation: {
                            show: true
                        }
                    }
                },
            ]
        },{
            template: '<h3 class="col-xs-12">Would you like to select Portfolio Margin for your HedgeCoVest account?</h3>'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'account_margin',
                    className: 'col-md-12',
                    type: 'radio',
                    templateOptions: {
                        label: 'This type of margin allows for more leverage than traditional Regulation T Margin. However, if you elect this option, your account will need to be funded with at least $125,000. Certain models available on HedgeCoVest may require or may be better replicated using portfolio margin. Please also be aware that utilizing more leverage may increase risk. Selecting Portfolio Margin for your HedgeCoVest account does not necessarily mean you will utilize the extra leverage available to you.',
                        options: Brokerage.yesNoOpts,
                        required: true,
                        validation: {
                            show: true
                        }
                    },
                    watcher: {
                      listener: function(field, newValue, oldValue, scope, stopWatching) {
                        if(newValue != '' && typeof newValue != 'undefined') {
                            console.log( newValue );
                        }
                      }
                    }
                },
            ]
        },
    ];


    $scope.IMAFields = [
        {
            template: '<h3>Please read the following 3 documents.</h3>'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'ima_docs',
                    className: 'col-md-12',
                    type: 'ima_docs',
                    validators: {
                        signedName : function($viewValue, $modelValue, scope){
                            //console.log( $viewValue + ' === '+ scope.full_name );
                            
                            return $viewValue === scope.full_name;
                        }
                    },
                    templateOptions: {
                        label: 'Read the following documents carefully',
                        options: Brokerage.imaDocs
                    }
                }
            ]
        }
    ];


    $scope.IBFields = [
        {
            template: '<h3>Please read the following Interactive Brokers documents.</h3>'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'ib_docs',
                    className: 'col-md-12',
                    type: 'ib_docs',
                    validators: {
                        signedAllDocs : function($viewValue, $modelValue, scope){
                            return scope.IBModel.signedAllDocs;
                        }
                    },
                    templateOptions: {
                        label: 'Read the following documents carefully',
                        options: Brokerage.ibDocs,
                        // required: true
                    }
                }
            ]
        }
    ];


    $scope.socialFields = [
        {
            template: '<p class="col-xs-12">We value your privacy and do not store this information.</p>'
        },{
            className: 'clearfix',
            fieldGroup: [
                {
                    key: 'social_security',
                    className: 'col-md-6 col-md-offset-3 text-center',
                    type: 'input',
                    ngModelAttrs: {
                        maskVal: {
                            'attribute': 'mask'
                        },maskCleanVal: {
                            'attribute': 'mask'
                        }
                    },
                    templateOptions: {
                        label: 'Social Security Number',
                        className: 'text-center',
                        required: true,
                        validation: { show: true },
                        maskVal: "999-99-9999",
                        maskCleanVal: true
                    }
                },{
                    key: 'international_user',
                    className: 'col-md-12',
                    type: 'checkbox',
                    templateOptions: {
                        label: 'Are you located outside the United States?'
                    }
                },{
                    key: 'identification_doc_country',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Country',
                        options: AccountSettings.countries
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        console.log('should i hide expression', (scope.model.international_user == true));
                        return scope.model.international_user != true;
                    }
                },{
                    key: 'identification_doc_type',
                    type: 'select',
                    className: 'col-md-12',
                    templateOptions: {
                        label: 'Document Type',
                        options: Brokerage.identification_doc_type
                    },
                    hideExpression: function($viewValue, $modelValue, scope) {
                        return scope.model.international_user != true;
                    }
                },
            ]
        }
    ];
});
// .module('CustomFilter', []).
// filter('capitalize', function() {
//     return function(input, all) {
//         return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
//     }
// });
