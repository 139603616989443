angular.module('HomeCtrl')
  .controller("SettingsCtrl", function($scope, $rootScope, $http, $uibModal, Dashboard, SettingsFactory, Models, Tabs, DRUPAL_API_URL) {

    var vm = this;
    var slides = vm.slides = [];
    var currIndex = 0;

    $rootScope.$on('dashboard-account-access-set', function(event, args) {

      vm.accessGroups = args.groups;
      vm.accessAccounts = args.accounts;
      vm.accessModels = args.models;

      vm.platformName = args.platform.name;

      console.log(vm.platform.name);
    });

    vm.hasAccounts = false;
    vm.sortTopModelsBy = 'YTD';

    vm.myInterval = 10000;
    vm.noWrapSlides = false;
    vm.active = 0;
    vm.settingsPayload;

    vm.models = {};
    vm.fields = {};
    vm.forms = {};

		vm.models = {
				selected: null,
				lists: {"A": [], "B": []}
		};

    function init() {

      SettingsFactory.get()
        .then(function(response) {

          if (response.data.status == 'success') {
            console.log(response);
            vm.settingsPayload = response.data.steps;

            for (var idx in vm.settingsPayload) {
              var item = vm.settingsPayload[idx];
              vm.models[item.modelId] = {};
              vm.forms[item.formId] = {};
              vm.fields[item.fieldId] = item.payload;
            }
          }
        });


		    // Generate initial model
		    for (var i = 1; i <= 3; ++i) {
		        vm.models.lists.A.push({label: "Item A" + i});
		        vm.models.lists.B.push({label: "Item B" + i});
		    }
    }

    vm.addTabForModel = function(guid) {

      if (typeof guid != 'undefined') {

        var url = `${DRUPAL_API_URL}/ng_api/model-info/${guid}`,
          req = {
            method: 'GET',
            url: url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          };

        $http(req)
          .then(function(result) {
            var model = result;
            Models.currentModel = model;
            Tabs.addTab('model');
          });
      }
    };

    // set up initial values and get some data
    init();


  });
