angular.module('AddAccountService', [])
.factory('AddAccountService', [ '$http', '$q', 'toastr', 'SmartXFactory', 'USER', 'DRUPAL_API_URL', function($http, $q, toastr, SmartXFactory, USER, DRUPAL_API_URL){

	//console.log("allocate service");
	
	var accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();


	function registerNewAccount(data){

		console.log(data);

		var deferred = $q.defer();
		var broker = 'ib';
      	var url = `${DRUPAL_API_URL}/ng_api/v2/user/account/add`;

		var skipEmail = !(USER.sendIMAEmail || true);
		var req = {
			method: 'POST',
			url: `${DRUPAL_API_URL}/ng_api/v2/user/account/add` + (skipEmail ? '?skip_email=yes' : ''),
			data:   jQuery.param(data),
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Bearer ' + SmartXFactory.getToken()
			}
		};

		console.log("Add account request: ", req);

		$http(req)
	    .success(function(res){

	    	if (res.statusCode == 200){
	    		toastr.success('Successfully created account.');
	    	} else if (res.ErrorMessage) {
	    		toastr.error(res.ErrorMessage);
	    	}

			deferred.resolve(res);

	    }).error(function(msg, code){

	    	debugger;
	        deferred.reject();
	        console.log(msg, code);

	    });

	    return deferred.promise;
	}

	function addAccount(data){

		console.log('addAccount', data);
		return accountAPI.create(payload);
	}

	function addPeople(data){
		console.log('addPeople', data);
	}

	function addDocuments(data){
		console.log('addDocuments', data);
	}

	function addFees(data){
		console.log('addFees', data);
	}

	return {
		registerNewAccount : registerNewAccount,
		addAccount				 : addAccount,
		addPeople				 	 : addPeople,
		addDocuments			 : addDocuments,
		addFees				 	 	 : addFees
	};
}]);
