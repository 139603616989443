angular.module('Directives')
.directive('smartxAccountLookup', ['$templateCache', '$filter', function($templateCache, $filter){
	// Runs during compile
	return {
		restrict: 'E',
		scope: {
			onSelect: '=onSelect',
			accounts: '=data',
			selected: '=selected'
		},
		template: $templateCache.get('lookup.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {

			$scope.th_account = {
				selected: angular.isDefined($scope.selected) ? $filter('sanitizeSmartXAccountName')($scope.selected.name, 'name') : undefined
			}

			// keep the displayed account name in sync when it changes in the parent controller
			$scope.$watch('selected', function(newVal, oldVal){
				if (angular.isDefined(newVal)){
					$scope.th_account.selected = $filter('sanitizeSmartXAccountName')(newVal.name, 'name');
					// $scope.onSelect(newVal);
				}
			});
		}
	};
}]);