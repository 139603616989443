angular.module('Directives')
.directive('assetLookup', assetLookup);

function assetLookup($templateCache){
	return {
		restrict: 'E',
		scope: {
			onAdd: '=onAdd',
			models: '=?models',
			addBtnText: '@addBtnText',
			placeholder: '=?placeholder',
			excludeSymbols: '=excludeSymbols'
		},
		bindToController: true,
		controller: assetLookupCtrl,
		controllerAs: 'vm',
		template: $templateCache.get('assetLookup.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {}
	};
}

function assetLookupCtrl($scope, $filter, toastr, PortfolioEditorFactory, USER, Dashboard, FavoriteModels){
	var vm = this;

	vm.selectedSymbol = null;
	vm.loadingSymbolResults = false;
	vm.onSymbolSelected = onSymbolSelected;
	vm.getSymbolSearchResults = getSymbolSearchResults;
	vm.selectedSecurity = null;
	FavoriteModels.getFavoriteModels()

	// this needs to be attached to scope to be called correctly within the on-enter directive
	$scope.onEnter = onEnter;

	function onEnter(item){
		if (angular.isDefined(vm.onAdd) && vm.selectedSecurity) {
			vm.onAdd(vm.selectedSecurity);
			vm.selectedSecurity = null;
			vm.selectedSymbol = null;
		}
	}

	function onSymbolSelected(item, model, label){
		vm.selectedSecurity = item;
	}

	function getSymbolSearchResults(term) {
		var len = term.length;
		var includeModels = vm.models && vm.models.length ? true : false;
			
		term = term.toLowerCase();

		vm.loadingSymbolResults = true;

		if (includeModels){
			var exactMatch;

			if (!len) return [];

			var matches = _.filter(vm.models, function(symbol) {
				var match = false;

				symbol.companyName = symbol.companyName || '';

				if (symbol.ticker.toLowerCase() == term.toLowerCase()) {
					exactMatch = symbol;
					match = true;
				} else if (len > 1 && symbol.ticker.toLowerCase().slice(0, len) == term.toLowerCase()) {
					match = true;
				} else if (len > 2 && symbol.companyName.toLowerCase().indexOf(term.toLowerCase()) != -1) {
					match = true;
				}

				return match;
			});

			if (typeof exactMatch != 'undefined') {
				var idx = matches.indexOf(exactMatch);
				matches.move(idx, 0);
			}
		}

		if (USER.apmDisabled || vm.excludeSymbols) {
			var results = matches;
			vm.loadingSymbolResults = false;
			return results.slice(0, 15).map(function(symbol){ symbol.assetType = $filter('formatStatus')(symbol.assetType); return symbol; });
		} else {
			return PortfolioEditorFactory.getSymbols(term, 'equity,mutualFund')
			.then(function(response) {
				console.log(response);
				var results = response.data.data;
				if (includeModels) results = response.data.data.concat(matches);
				return results.slice(0, 15)
				.map(function(symbol){ 
					symbol.assetType = $filter('formatStatus')(symbol.assetType); 
					symbol.isFavorite = FavoriteModels.isModelAFavorite(symbol.id)
					return symbol; 
				});
			})
			.catch(function(err){
				// toastr.error(err.message);
				Dashboard.toastError(err.message, err);
				return [];
			})
			.finally(function(){
				vm.loadingSymbolResults = false;
				$scope.$apply();
			});
		}
	}
}