/* To be deprecated */
angular.module('GroupsService', [])
.factory('Groups', ['$http', function($http){

    var group = {
        id : null,
        guid : null,
        name: null
    };

	function createGroup(name, members){

		var data = {
			name : name,
			members : members
		};

		var req = {
			method: 'POST',
			url:'/ng_api/v2/group',
			data:  data,
			headers: {'Content-Type': 'application/json'}
		};

		return $http(req);
    }

    function updateGroup(guid){
		var data = {
			name : 'my new name',
			members: ['320fbd01-724a-4337-a25c-1c248c07beb6', 'e1fb857b-bfb3-457a-89c1-41c754b7dcad', '320fbd01-724a-4337-a25c-1c248c07beb6']
		};

		var req = {
			method: 'PUT',
			url : '/ng_api/v2/group/' + guid,
			data : data,
			headers: {'Content-Type': 'application/json'}
		};

		return $http(req);
    }

    function deleteGroup(guid){
      
		var req = {
			url : '/ng_api/v2/group/' + guid,
			method : 'DELETE',
			//data : data,
			headers: {'Content-Type': 'application/json'}
		};

		return $http(req);
    }

    function getAllGroups(){
    	return $http.get('/ng_api/v2/group');
    }

    function getGroupByGuid(guids){

    	guids = angular.isArray(guids) ? guids.join(',') : guids;

    	return $http.get('/ng_api/v2/group/' + guids);
    }

    function addAccounts(group, accounts){

    	accounts = angular.isArray(accounts) ? accounts.join(',') : accounts;

    	var req = {
    		url : '/ng_api/v2/group/' + group + '/accounts/' + accounts,
    		method: 'PUT',
    		headers: {'Content-Type': 'application/json'}
    	};

    	return $http(req);
    }

    function deleteAccounts(group, accounts){

    	// convert array of accounts to comma separated list
    	// if 'accounts' is already in string form, leave it be
    	accounts = angular.isArray(accounts) ? accounts.join(',') : accounts;

    	var req = {
    		url : '/ng_api/v2/group/' + group + '/accounts/' + accounts,
    		method: 'DELETE',
    		headers: {'Content-Type': 'application/json'}
    	};

    	return $http(req);
    }

    var setAccessGroups = function(groups){
      accessGroups = groups;
    };

    var getAccessGroups = function(){
      return accessGroups;
    };

    var getGroupId = function(){
      return group.id;
    };

    var setGroupId = function(id){
      group.id = id;
    };

    var getGroupName = function(){
      return group.name;
    };

    var getGroupGUID = function(){
      return group.guid;
    };

    var setGroupGUID = function(guid){
      group.guid = guid;
    };

    var setGroupName = function(name){
      group.name = name;
    };

    var getUserGroups = function(){
      var url = '/ng_api/v2/user/groups';
      return $http.get(url, {
        url:'/ng_api/v2/group',
        data:  data,
        headers: {'Content-Type': 'application/json'}
      });

      return $http(req);
    };

    var editGroupName = function(guid){
      var data = {
        name : 'my new name',
        members: ['320fbd01-724a-4337-a25c-1c248c07beb6', 'e1fb857b-bfb3-457a-89c1-41c754b7dcad', '320fbd01-724a-4337-a25c-1c248c07beb6']
      };

      var req = {
        method: 'PUT',
        url : '/ng_api/v2/group/' + guid,
        data : data,
        headers: {'Content-Type': 'application/json'}
      };

      return $http(req);
    };

    return {
    	
    	get    			: getAllGroups,
    	create 			: createGroup,
    	update 			: updateGroup,
    	delete 			: deleteGroup,
    	getGroupByGuid  : getGroupByGuid,
    	addAccounts	    : addAccounts,
    	deleteAccounts  : deleteAccounts,
        setAccessGroups : setAccessGroups,
        getAccessGroups : getAccessGroups,
        getGroupId      : getGroupId,
        setGroupId      : setGroupId,
        getGroupName    : getGroupName,
        setGroupName    : setGroupName,
        getGroupGUID    : getGroupGUID,
        setGroupGUID    : setGroupGUID,
        getUserGroups   : getUserGroups,
        editGroupName   : editGroupName
    };

}]);