angular.module('SearchCtrl')
  .value('THROTTLE_MILLISECONDS', 250)
  .directive('searchTable', ['$templateCache', function($templateCache) {
    return {
      restrict: 'E',
      scope: {
        // search: '=',
        models: '=',
        rangeFilters: '=',
        filterModel: '=',
        data: '=data',
        searchText: '=',
        filteredModels: '=?'
      },
      template: $templateCache.get('searchTable2.tpl.html'),
      link: link,
      controller: searchTableCtrl,
      controllerAs: 'vm',
      bindToController: {
        // search: '=',
        models: '=',
        rangeFilters: '=',
        filterModel: '=',
        searchText: '=',
        filteredModels: '=?'
      }
    };

    function link(scope, elem, attr) {}


    function searchTableCtrl(Amplitude, $scope, $rootScope, $timeout, Models, $log, $modal, Dashboard, TargetWeightFactory, NgTableParams, Tabs, $filter, $templateCache, toastr, $sce, SweetAlert, SearchFilters) {
      var vm = this;

      var tableBody,
          innerContaienr,
          tableHeader,
          tableHead,
          newHeadertable,
          ngTableHeaderRow,
          ngTableBodyRow;


      vm.models = $scope.models;
      vm.columnDefs = SearchFilters.columnDefs;
      vm.updateSelectedRows = updateSelectedRows;
      vm.addModelTabForId = addModelTabForId;
      
      vm.modelFilter = {
        'title': {
          id: "text",
          placeholder: "Filter by Name",
        }
      };

      vm.tableParams = new NgTableParams({
        sorting: { ytd: "desc" },
        count: 25
      }, {
        counts: [25, 50, 100, 250],
        dataset: vm.models
      });

      $scope.$watch('vm.selectAllModels', function(newVal, oldVal) {
        if (angular.isDefined(newVal) && newVal != oldVal) {

          _.each(vm.tableParams.settings().dataset, function(row) {

            if (newVal === true) {
              row.selected = true;
            } else {
              row.selected = false;
            }
          });

          updateSelectedRows();
        }
      });

      $scope.$on('resizeTable', function(e, showingFilters){
        if (!showingFilters){
          vm.expanded = true;
        } else {
          vm.expanded = false;
        }
      });

      $scope.$watch('vm.search', function(newVal, oldVal) {
        if (angular.isDefined(newVal) && newVal != oldVal) {

          var data;
          if (newVal !== '') {
            data = vm.models.filter(function(model){
              return model.title.toLowerCase().indexOf(newVal.toLowerCase()) !== -1;
            });
          }else{
            data = vm.models;
          }
          vm.tableParams = new NgTableParams({
            sorting: { ytd: "desc" },
            count: 25
          }, {
            counts: [25, 50, 100, 250],
            dataset: data
          });

          initColumns();
        }
      });

      $scope.$on('filter-serach-for-text', function(event, args) {

        var data;
        if (!_.isEmpty(vm.filterModel)){
          data = filterData(vm.filterModel, vm.models);
        } else {
          data = vm.models;
        }
        
        if (args.text !== '') {
          data = data.filter(function(model){
            return model.title.toLowerCase().indexOf(args.text.toLowerCase()) !== -1;
          });
        }

        vm.tableParams = new NgTableParams({
          sorting: { ytd: "desc" },
          count: 25
        }, {
          counts: [25, 50, 100, 250],
          dataset: data
        });

        vm.filteredModels.length = 0;
        data.forEach(function(item){
          vm.filteredModels.push(item);
        });

        initColumns();
      });

      $scope.$on('search-data-ready', function(event, models) {               
        var data = vm.tableParams.settings().dataset || models;

        vm.tableParams = new NgTableParams({
          sorting: { ytd: "desc" },
          count: 25
        }, {
          counts: [25, 50, 100, 250],
          dataset: data
        });

        initColumns();
      });

      $scope.$on('filter-changed', function(event, filterModel) {

        console.log("updated filter model: ", filterModel);

        var filteredData = filterData(filterModel, $scope.models);

        vm.filteredModels.length = 0;
        filteredData.forEach(function(item){
          vm.filteredModels.push(item);
        });

        // TODO: apply the text filter on 'filteredData'
        // otherwise the text filter is ignored
        $timeout(function() {
          vm.tableParams.settings({
            'dataset': filteredData
          });
        }, false);
      });

      $scope.$on('table-cleared', function(event, filterModel) {

          var selectedRows = vm.tableParams.settings().dataset.filter(function(row) {
            return row.selected;
          });
          for (var row in selectedRows) {
            if (selectedRows.hasOwnProperty(row)) {
              selectedRows[row].selected = false;
            }
          }

          $scope.data.selectedRows = [];
          vm.selectAllModels = false; // clear out the header checkbox
      });

      // $scope.getMoreData = function() {
      //   $scope.data = $scope.users.slice(0, $scope.data.length + 20);
      // }

      // function initHeaders() {

      //   tableBody = angular.element(document.querySelector('#hcv-search-table-body'));
      //   innerContaienr = angular.element(document.querySelector('#hcv-search-inner-container'));
      //   tableHeader = angular.element(document.querySelector('#hcv-search-table-header'));
      //   tableHead = angular.element(document.querySelector('#hcv-search-ngtable > thead'));
      //   newHeadertable = angular.element(document.querySelector('#headertable'));
      //   ngTable = angular.element(document.querySelector('#hcv-search-ngtable'));
      //   ngTableHeaderRow = angular.element(document.querySelector('#hcv-search-ngtable thead tr'))[0];
      //   ngTableBodyRow = angular.element(document.querySelector('#hcv-search-ngtable tbody tr'))[0];

      //   var newTableHeight = innerContaienr.height() - tableHeader.height();
      //   if (false) {

      //     if (parseInt(newTableHeight) > 0) {
      //       if (newHeadertable.length) {
      //         newHeadertable[0].appendChild(tableHead[0]);
      //       }

      //       tableBody.height(newTableHeight);
      //     }


      //     for (var i = 0; i < ngTableBodyRow.children.length; i++) {
      //       var child = ngTableBodyRow.children[i];
      //       angular.element(ngTableHeaderRow.children[i]).css('width', child.clientWidth + 'px');
      //     }
      //   }

      // }

      // function addScrollListener() {

      //   ngTable.scroll(function() {
      //     tableHeader.offset({
      //       left: -1 * this.scrollLeft
      //     });
      //   });

      //   console.log('added ScrollListener');
      // }

      function initColumns() {

        vm.columnDefs = SearchFilters.columnDefs;
        vm.tableCols = [];
        for (var prop in vm.columnDefs) {
          if (vm.columnDefs.hasOwnProperty(prop)) {
            var column = vm.columnDefs[prop];
            if (typeof column.field == 'undefined') {
              column.field = 'checkbox';
              column.headerTemplateURL = 'searchHeaderCheckbox.html';
              column.show = true;
            }
            column.title = column.headerName;
            column.show = (typeof column.hide != 'undefined') ? !column.hide : true;
            if (column.show == true) {
              vm.tableCols.push(column);
            }
          }
        }
      }

      function addModelTabForId(model) {

        // currentModel is referenced in Tabs service when opening a new model tab
        Models.currentModel = model;

        Amplitude.logEvent('Model Search:Table:Open Model Details');

        $timeout(function() {
          var tab = Tabs.addTab('model');
          if (tab) $scope.$parent.$broadcast('tabSwitched::' + tab.type, tab);
        }, 1);
      }

      // if no model passed, (de)selected all
      function updateSelectedRows(model) {
        $timeout(function() {

          if (model) {
            if (!model.selected) {
              var selectedRowIndex = _.findIndex($scope.data.selectedRows, function(row){
                return row.id === model.id;
              });

              if (selectedRowIndex > -1 ) $scope.data.selectedRows.splice(selectedRowIndex, 1);
            } else {
              $scope.data.selectedRows = $scope.data.selectedRows ? $scope.data.selectedRows.concat([model]) : [ model ];
            }

            // console.log('New Selected Rows', $scope.data.selectedRows);

            $scope.$emit('row-selected', $scope.data.selectedRows);
          } else {
            var selectedRows = vm.tableParams.settings().dataset.filter(function(row) {
              return row.selected;
            });

            $scope.data.selectedRows = selectedRows;
            $scope.$emit('row-selected', $scope.data.selectedRows);
          }
        });
      }

      function valueInRange(value, range) {
        if (value >= range.min && value <= range.max) {

          return true;

        } else if (range.options.floor === range.min && range.options.ceil === range.max) {

          return true;

        } else if (range.options.floor === range.min || range.options.ceil === range.max) {

          if (range.options.floor === range.min) {
            return value <= range.max;
          } else if (range.options.ceil === range.max) {
            return value >= range.min;
          }

        } else if (range.options.floor === range.max || range.options.ceil === range.min) {

          if (range.options.floor === range.max) {

            return value <= range.max;

          } else if (range.options.ceil === range.min) {

            return value >= range.min;

          }

        } else {
          return false;
        }
      }


      function filterData(filterModel, data) {
        var filterTypes = ['fund_type', 'account_compatibility', 'fund_style_box', 'model_type', 'model_category_2', /*'model_category'*/ , 'secondary_model_type', 'sector_focus', 'asset_class', 'portfolio_construction', 'market_capitalization', 'regions'];
        var rangers = [{
            id: 'yearsTracked',
            colName: 'Track Record (in years)'
          },
          {
            id: 'cagr',
            colName: 'Avg. Annual Return'
          },
          {
            id: 'standard_deviation',
            colName: 'Standard Deviation'
          },
          {
            id: 'sortino',
            colName: 'Sortino'
          },
          {
            id: 'sharpe',
            colName: 'Sharpe'
          },
          {
            id: 'correlation',
            colName: 'Correlation'
          },
          {
            id: 'minimum_allocation',
            colName: 'Min. Allocation'
          },
          {
            id: 'management_fee',
            colName: 'Fee'
          }
        ];

        var filterTerms = {};
        var searchTextPresent = vm.searchText.length;
        var searchTextMatch = true;
        var filterPresent = (filterModel && Object.keys(filterModel).length > 0);
        if (!filterPresent && !searchTextPresent) {
          return data;
        }

        var resultOfFilter = [];

        // this loop can be placed into a function and called whenever the activeFilters array changes
        for (var i = 0; i < data.length; i++) {
          var item = data[i];

          // check for search text match
          if (searchTextPresent) {
            if (item.title.toLowerCase().indexOf($scope.searchText.toLowerCase()) !== -1) {
              searchTextMatch = true;
            } else {
              searchTextMatch = false;
            }
          }

          var rowProperties = {};

          // build an object with the model's filterable properties
          _.each(filterTypes, function(filterType) {

            if (filterType != 'rangeFilters') {
              if (!_.isEmpty(item[filterType]) && item[filterType].length) {
                rowProperties[filterType] = item[filterType];
              }
            }
          });

          var match = true;

          // check for intersection between the model's filterable properties and the current filter model
          match = _.every(filterModel, function(terms, filterType) {
            if (!terms.length || filterType == 'rangeFilters') return true;
            var commonTerms = _.intersection(rowProperties[filterType], terms);
            return commonTerms.length;
          });
          //}

          // check that the model's numeric filterable properties pass the range filters
          var passed = true;
          if (filterModel['rangeFilters'] && filterModel['rangeFilters'].length) {
            rangers.forEach(function(field) {
              var inRange = valueInRange(item[field.id], vm.rangeFilters[field.colName]);

              if (!inRange) {
                passed = false;
              }
            });
          }

          if (match && passed && searchTextMatch) {
            resultOfFilter.push(item);
          }

        }

        return resultOfFilter;
      }

      initColumns();
    }
  }]);
