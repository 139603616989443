angular.module('Directives')
.directive('infoBox', function(){
	return {
		restrict: 'E',
		scope: {
			message: '@message', // expects a string literal. use {{ }} if using a scope variable, i.e. <info-box messge="{{vm.someMessage}}" />
		},
		template: '<div class="info-block">' +
                '<div class="info" style="width: 100%; padding: 15px; background-color: rgb(44,85,127, 0.1); display: inline-flex; align-items: center; word-break: break-word;">' +
                  '<i class="fa fa-info-circle" style="font-size: 1.1em; padding-right: 12px;"></i>' +
                  '<span>{{ message }}</span>' +
                '</div>' +
              '</div><br/>'
  };
});