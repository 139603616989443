angular.module('ChartService', [])
.factory('Chart', ['$http', function($http){

	var charts = [];
	var timeframes = ["1D", "5D", "1M", "3M", "6M", "YTD", "Max"];
	var modelData = {
		"1D" : '',
		"5D" : '',
		"1M" : '',
		"3M" : '',
		"6M" : ''
	};

	function getNewSet(time){
		var endpoint = modelData[time];
		//return $http.get(endpoint);
	}

	return {
		getNewSet : getNewSet,
		timeframes : timeframes,
		modelPerformanceData : undefined
	};

}]);