angular.module('BillingModule')
.controller('AddFeeSchedulesCtrl', function ($scope, NgTableParams){
  var vm = this;

  vm.accounts = [
    {
      name: 'Account 1',
      brokerageNumber: '123456',
      feeSchedule: null,
      feeFrequency: null,
      household: null,
      billToAccount: null,
      value: 1000000
    },
    {
      name: 'Account 2',
      brokerageNumber: '123456',
      feeSchedule: null,
      household: 'Household 2',
      feeFrequency: null,
      billToAccount: 'acct1',
      value: 1000000
    },
    {
      name: 'Account 2',
      brokerageNumber: '123456',
      feeSchedule: null,
      household: null,
      feeFrequency: null,
      billToAccount: null,
      value: 1000000
    }
  ];

  vm.scheduleOptions = [
    { 
      id: null,
      name: 'Select Schedule'
    },
    {
      id: '1',
      name: 'Schedule 1'
    },
    {
      id: '2',
      name: 'Schedule 2'
    },
    {
      id: '3',
      name: 'Schedule 3'
    },
  ];

  vm.frequencyOptions = [
    {
      id: null,
      name: 'Select Frequency'
    },
    {
      id: 'frequency1',
      name: 'Frequency 1'
    },
    {
      id: 'frequency1',
      name: 'Frequency 2'
    },
    {
      id: 'frequency1',
      name: 'Frequency 3'
    },
    {
      id: 'frequency1',
      name: 'Frequency 4'
    }
  ];

  vm.households = [
    {
      id: null,
      name: 'Select Household'
    },
    {
      id: 'household1',
      name: 'IRA Household 1',
      feeSchedule: 'Schedule 1',
      billableAUM: 1000000,
      nonBillableAUM: 314000,
      accountsCount: 25
    },
    {
      id: 'ethMoonTrust',
      name: 'ETH Moon Trust',
      feeSchedule: 'Schedule 3',
      billableAUM: 100000000,
      nonBillableAUM: 314000,
      accountsCount: 25
    },
    {
      id: 'nakamotoRetirement',
      name: 'Nakamoto Retirement',
      feeSchedule: '--',
      billableAUM: 1000000,
      nonBillableAUM: 314000,
      accountsCount: 25
    }
  ];

  vm.billTo = [
    {
      id: null,
      name: 'Select Account'
    },
    {
      id: 'acct1',
      name: 'Account 1'
    }
  ]

  vm.checkboxes = {
    checked: false,
    items: {}
  };

  $scope.status = {
    showBulkOptions: false,
    isopen: false
  };

  init();

  function init () {
    vm.accountsWithoutSchedulesTable = new NgTableParams({}, {
      dataset: vm.accounts
    });
  }
});