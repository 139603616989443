angular.module('BillingService', [])
.factory('Billing', [ '$q', function($q){

  var service = this;
  var billingApi = new SmartX.BillingApi();
  var accountApi = new SmartX.AccountApi();
  var advisoryDashboard = billingApi.advisoryDashboard;
  var billingStatus = billingApi.billingStatus;
  var computeEngine = billingApi.computeEngine;
  var mock = [
    {
      "id": "b91dbda6-55dc-4053-b413-4e9ae61928fa",
      "computationDateTime": "2021-09-22T12:04:06.857",
      "periodStartDate": "2021-08-01T00:00:00",
      "periodEndDate": "2021-08-23T00:00:00",
      "feeScheduleId": "6099856f-1b58-42c8-bb5b-d147d4c19d58",
      "feeScheduleMinimum": 0,
      "feeScheduleMaximum": 0,
      "feeScheduleDetails": "",
      "billingAumBasisType": "account",
      "billingDiscountType": "blended",
      "billingFeeType": "advisoryFee",
      "billingEntityId": "c6e09cb6-8010-4819-88e1-9b67736d66c4",
      "entityAumBasis": 483665.22,
      "averageFeePercent": 0.012,
      "holdingDaysInPeriod": 16,
      "totalMarketDaysInYear": 252,
      "entityAum": 484644.3,
      "totalTradingDaysInMonth": 22,
      "lastPeriodAdvanceFeeAmount": 0,
      "thisPeriodAdvanceFeeEstimate": 0,
      "thisMonthArrearsFeeAmount": 369.2528,
      "thisPeriodArrearsFeeAmount": 0,
      "thisPeriodNetFeeAmount": 369.2528,
      "billingApprovalStatusType": "passiveApproval",
      "billingPaidStatusType": "notPaid",
      "paidByAccountId": "d830acaa-3462-4815-8358-1cd31929953c",
      "billingPeriodType": "monthly",
      "billingType": "monthlyInArrears",
      "billingMethodType": "arrears",
      "billingEntityType": "SLEEVE",
      "resultsBlob": "{\"sortedTiers\":[{\"Id\":\"7ab95a8c-8aba-4dcd-acce-8df653b2f209\",\"BillingFeeDefinitionsId\":\"6099856f-1b58-42c8-bb5b-d147d4c19d58\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.012000}],\"activeTiers\":[{\"Id\":\"7ab95a8c-8aba-4dcd-acce-8df653b2f209\",\"BillingFeeDefinitionsId\":\"6099856f-1b58-42c8-bb5b-d147d4c19d58\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.012000}],\"sortedAums\":[483665.22],\"sortedRateOrAmount\":[0.012000],\"averageFeePercent\":0.012000,\"aumBasis\":483665.22,\"aumBasisType\":\"Account\",\"discountType\":\"Blended\"}",
      "createdAt": "2021-09-22T12:04:06.98",
      "updatedAt": null,
      "createdBy": "biling.stg",
      "updatedBy": null,
      "activeBillingRecord": false,
      "adjustments": 0,
      "billingRecordsCount": 813,
      "billingRun": "2021-8",
      "adjustmentsNote": null,
      "aumDate": "2021-08-23T00:00:00",
      "accountId": "d830acaa-3462-4815-8358-1cd31929953c"
    },
    {
      "id": "08664d4b-de3d-4f26-b6ed-4ed57cdebc18",
      "computationDateTime": "2021-09-21T11:12:41.083",
      "periodStartDate": "2021-07-10T00:00:00",
      "periodEndDate": "2021-07-31T23:59:00",
      "feeScheduleId": "4096e415-97a5-493b-a0db-ef1d3586dd32",
      "feeScheduleMinimum": 0,
      "feeScheduleMaximum": 0,
      "feeScheduleDetails": "",
      "billingAumBasisType": "household",
      "billingDiscountType": "blended",
      "billingFeeType": "platformAdvFee",
      "billingEntityId": "eeef359c-d78b-4bab-a374-4e74c60193a8",
      "entityAumBasis": 310286.98,
      "averageFeePercent": 0.013306,
      "holdingDaysInPeriod": 15,
      "totalMarketDaysInYear": 252,
      "entityAum": 192349.4,
      "totalTradingDaysInMonth": 21,
      "lastPeriodAdvanceFeeAmount": 0,
      "thisPeriodAdvanceFeeEstimate": 213.27871011234075,
      "thisMonthArrearsFeeAmount": 0,
      "thisPeriodArrearsFeeAmount": 0,
      "thisPeriodNetFeeAmount": 213.27871011234075,
      "billingApprovalStatusType": "passiveApproval",
      "billingPaidStatusType": "notPaid",
      "paidByAccountId": "be3bff7e-ee33-4f27-8c71-a53889fc7f6c",
      "billingPeriodType": "monthly",
      "billingType": "monthlyInAdvance",
      "billingMethodType": "advance",
      "billingEntityType": "SLEEVE",
      "resultsBlob": "{\"sortedTiers\":[{\"Id\":\"dfa5eb46-f99b-4597-baed-e11d7ea05473\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.013500},{\"Id\":\"51b0200f-edab-4d5f-bba2-d5ef20661506\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":250000.01,\"RateOrAmount\":0.012500},{\"Id\":\"00db4948-96fe-4841-bbba-f47fd0ba8279\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":500000.01,\"RateOrAmount\":0.011500},{\"Id\":\"c9bec210-287b-4b89-bd75-7cfee2e8ecdf\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":1000000.01,\"RateOrAmount\":0.010500}],\"activeTiers\":[{\"Id\":\"dfa5eb46-f99b-4597-baed-e11d7ea05473\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.013500},{\"Id\":\"51b0200f-edab-4d5f-bba2-d5ef20661506\",\"BillingFeeDefinitionsId\":\"4096e415-97a5-493b-a0db-ef1d3586dd32\",\"BillingTierType\":1,\"ActivationLevel\":250000.01,\"RateOrAmount\":0.012500}],\"sortedAums\":[250000.01,60286.97],\"sortedRateOrAmount\":[0.013500,0.012500],\"averageFeePercent\":0.0133057057695427632832031818,\"aumBasis\":310286.98,\"aumBasisType\":null,\"discountType\":\"Blended\"}",
      "createdAt": "2021-09-21T11:12:41.24",
      "updatedAt": null,
      "createdBy": "NoUserNameForNow",
      "updatedBy": null,
      "activeBillingRecord": false,
      "adjustments": 0,
      "billingRecordsCount": 813,
      "billingRun": "2021-7",
      "adjustmentsNote": null,
      "aumDate": "2021-07-30T00:00:00",
      "accountId": "be3bff7e-ee33-4f27-8c71-a53889fc7f6c"
    },
    {
      "id": "679f139f-d90a-41ee-8faf-9263888daddb",
      "computationDateTime": "2021-09-22T13:48:05.79",
      "periodStartDate": "2021-08-01T00:00:00",
      "periodEndDate": "2021-08-11T00:00:00",
      "feeScheduleId": "d43ef1eb-ce28-4b8d-b0f5-2be55822b322",
      "feeScheduleMinimum": 0,
      "feeScheduleMaximum": 0,
      "feeScheduleDetails": "",
      "billingAumBasisType": "account",
      "billingDiscountType": "blended",
      "billingFeeType": "advisoryFee",
      "billingEntityId": "7df5d0ac-9f7b-48c3-8193-3f208399a90d",
      "entityAumBasis": 39.99,
      "averageFeePercent": 0.01,
      "holdingDaysInPeriod": 8,
      "totalMarketDaysInYear": 252,
      "entityAum": 1111.724,
      "totalTradingDaysInMonth": 22,
      "lastPeriodAdvanceFeeAmount": 0,
      "thisPeriodAdvanceFeeEstimate": 0,
      "thisMonthArrearsFeeAmount": 0,
      "thisPeriodArrearsFeeAmount": 0.352928253968254,
      "thisPeriodNetFeeAmount": 0.352928253968254,
      "billingApprovalStatusType": "disapproved",
      "billingPaidStatusType": "notPaid",
      "paidByAccountId": "b6b1e408-4e85-47c8-ae6c-98d38e00aa60",
      "billingPeriodType": "monthly",
      "billingType": "monthlyInAdvance",
      "billingMethodType": "advance",
      "billingEntityType": "SLEEVE",
      "resultsBlob": "{\"sortedTiers\":[{\"Id\":\"8e9da44f-d0b4-4ce2-8085-3bf6b47e5c25\",\"BillingFeeDefinitionsId\":\"d43ef1eb-ce28-4b8d-b0f5-2be55822b322\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.010000}],\"activeTiers\":[{\"Id\":\"8e9da44f-d0b4-4ce2-8085-3bf6b47e5c25\",\"BillingFeeDefinitionsId\":\"d43ef1eb-ce28-4b8d-b0f5-2be55822b322\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.010000}],\"sortedAums\":[39.99],\"sortedRateOrAmount\":[0.010000],\"averageFeePercent\":0.010000,\"aumBasis\":39.99,\"aumBasisType\":\"Account\",\"discountType\":\"Blended\"}",
      "createdAt": "2021-09-22T13:48:06.307",
      "updatedAt": null,
      "createdBy": "biling.stg",
      "updatedBy": null,
      "activeBillingRecord": false,
      "adjustments": 0,
      "billingRecordsCount": 813,
      "billingRun": "2021-8",
      "adjustmentsNote": null,
      "aumDate": "2021-08-11T00:00:00",
      "accountId": "b6b1e408-4e85-47c8-ae6c-98d38e00aa60"
    },
    {
      "id": "9bf704bc-7ac9-40cc-85c6-346f0c10b848",
      "computationDateTime": "2021-09-22T13:47:34.667",
      "periodStartDate": "2021-08-01T00:00:00",
      "periodEndDate": "2021-08-11T00:00:00",
      "feeScheduleId": "5b2feb0b-12b6-485e-aa98-482842c67c22",
      "feeScheduleMinimum": 0,
      "feeScheduleMaximum": 0,
      "feeScheduleDetails": "",
      "billingAumBasisType": "account",
      "billingDiscountType": "blended",
      "billingFeeType": "advisoryFee",
      "billingEntityId": "b48a5b8c-a73c-4d3e-b293-ccc2e41c23d1",
      "entityAumBasis": 150,
      "averageFeePercent": 0.0115,
      "holdingDaysInPeriod": 8,
      "totalMarketDaysInYear": 252,
      "entityAum": -525.825,
      "totalTradingDaysInMonth": 22,
      "lastPeriodAdvanceFeeAmount": 0,
      "thisPeriodAdvanceFeeEstimate": 0,
      "thisMonthArrearsFeeAmount": 0,
      "thisPeriodArrearsFeeAmount": -0.1919678571428571,
      "thisPeriodNetFeeAmount": -0.1919678571428571,
      "billingApprovalStatusType": "disapproved",
      "billingPaidStatusType": "notPaid",
      "paidByAccountId": "5e2c9910-5d56-4478-8d8c-57c8f085d5bb",
      "billingPeriodType": "monthly",
      "billingType": "monthlyInAdvance",
      "billingMethodType": "advance",
      "billingEntityType": "SLEEVE",
      "resultsBlob": "{\"sortedTiers\":[{\"Id\":\"d9eb41f2-71a4-465b-adfe-9f52cd38d9a4\",\"BillingFeeDefinitionsId\":\"5b2feb0b-12b6-485e-aa98-482842c67c22\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.011500}],\"activeTiers\":[{\"Id\":\"d9eb41f2-71a4-465b-adfe-9f52cd38d9a4\",\"BillingFeeDefinitionsId\":\"5b2feb0b-12b6-485e-aa98-482842c67c22\",\"BillingTierType\":1,\"ActivationLevel\":0.00,\"RateOrAmount\":0.011500}],\"sortedAums\":[150.0],\"sortedRateOrAmount\":[0.011500],\"averageFeePercent\":0.011500,\"aumBasis\":150.0,\"aumBasisType\":\"Account\",\"discountType\":\"Blended\"}",
      "createdAt": "2021-09-22T13:47:35.09",
      "updatedAt": null,
      "createdBy": "biling.stg",
      "updatedBy": null,
      "activeBillingRecord": false,
      "adjustments": 0,
      "billingRecordsCount": 813,
      "billingRun": "2021-8",
      "adjustmentsNote": null,
      "aumDate": "2021-08-04T00:00:00",
      "accountId": "5e2c9910-5d56-4478-8d8c-57c8f085d5bb"
    },
  ];
  
  this.getEnterpriseExclusions = function (enterpriseId, query) {
    if (!query) throw new Error('Query is required');

    return advisoryDashboard.getEnterpriseExclusions(enterpriseId, query);
  };

  this.getAccountsSummary = function(params){
    return accountApi.getAccountsSummary(params);
  };

  this.getAccountsAumInPeriod = function (enterpriseId, params) {
    return advisoryDashboard.getAccountsAumInPeriod(enterpriseId, params);
  };

  this.getAumOverview = function (enterpriseId, params) {
    return advisoryDashboard.getAumOverview(enterpriseId, params);
  };

  this.getGroupTypeAumInPeriod  = function (enterpriseId, params) {
    return advisoryDashboard.getGroupTypeAumInPeriod(enterpriseId, params);
  };

  this.getNonBillableAccountsInPeriod = function(enterpriseId, params) {
    return advisoryDashboard.getNonBillableAccountsInperiod(enterpriseId, params);
  };

  this.getAumInPeriod = function (enterpriseId, params) {
    return advisoryDashboard.getAumInPeriod(enterpriseId, params);
  };

  this.getExclusionsByFirm = function (params) {
    return advisoryDashboard.getExclusionsByFirm(params);
  };

  this.getExclusionsByAccount = function (params) {
    return advisoryDashboard.getExclusionsByAccount(params);
  };

  this.getAllFirmsAumOverview = function (params) {
    return advisoryDashboard.getAllFirmsAumOverview(params);
  };

  this.getAccountEffectiveFee = function (params) {
    return advisoryDashboard.getAccountEffectiveFee(params);
  };

  // households
  this.getAllFirmsGroupAum = function (params) {
    return advisoryDashboard.getAllFirmsGroupAum(params);
  };

  this.getAllNonBillableAccounts = function (params) {
    return advisoryDashboard.getNonBillable(params);
  };

  this.getSummary = function (params) {
    return advisoryDashboard.getSummary(params);
  };

  this.getAccountsByGroupId = function (params) {
    return advisoryDashboard.getAccountsByGroupId(params);
  };

  // fee blotter
  this.getBillingRecords = function (params) {
    return computeEngine.getBillingRecords(params);
  };

  this.getBillingRecordsByAccount = function (params) {
    return computeEngine.getBillingRecordsByAccount(params);
  };

  this.getRollupBillingRecordsByAccount = function (params) {
    return computeEngine.getRollupBillingRecordsByAccount(params);
  };

  this.getBillingRecordsByEnterprise = function (params) {
    return computeEngine.getBillingRecordsByEnterprise(params);
  };

  this.getAccountAumData = function (params) {
    return computeEngine.computeAccountAumData(params);
  };

  this.getFirstAndLastBillingRunForAccount = function (params) {
    return computeEngine.getFirstAndLastBillingRunForAccount(params);
  };
  
  this.getDateOptions = function () {
    return advisoryDashboard.getDateOptions();
  };

  this.getAccountProgramFees = function (params) {
    return advisoryDashboard.getAccountProgramFees(params);
  };
  
  this.loadDateOptions = function () {
    return $q.when(this.getDateOptions())
    .then(function(res){
      _setDateOptions(res.data);
      return $q.resolve();
    })
    .catch(function(err){
      _setDateOptions([]);
      return $q.reject(err);
    });
  };

  function _setDateOptions (dateOptions) {
    service.dateOptions = dateOptions;
  }

  // insufficient funds 
  this.getInsufficientFundsAccounts = function (params) {
    return advisoryDashboard.getInsufficientFundsAccounts(params);
  };

  return service;

}]);