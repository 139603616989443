angular.module('HarvestingModule')
.directive('outsideClick', ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {

      jQuery('body').on('click', function(e){
        if (!element[0].contains(e.target) && !jQuery(e.target).hasClass('popover-content')){
          scope.vm.isOpen = false;
        }
      });
    }
  };
}])
.filter('accountsFilter', function(){
  return function(items, inputValue){

    var out = [];

    if (angular.isArray(items)) {

      items.forEach(function(item){

        var matchesAcctNumber = item.brokerageAccountNumber && (item.brokerageAccountNumber.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
        var matchesName = item.name && (item.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
        
        if(matchesAcctNumber || matchesName){
          out.push(item);
        }
      });
    } else {
      out = items;
    }

    return out;
  };
})
.controller('HarvestingCtrl', function(FeatureFlags, SweetAlert, $scope, $timeout, NgTableParams, $q, $uibModal, toastr, $templateCache, Tabs, SmartXFactory, HarvestingFactory, Dashboard){
	var vm = this;
  var popoverVerbiage = $templateCache.get('popoverVerbiage.tpl.html'); 

	vm.title = "Tax Groups";
  vm.showingGroups = true;
  
  vm.createNewGroup = createNewGroup;
  vm.openRuleModal = openRuleModal;
  vm.openHarvestMonitor = openHarvestMonitor;
  vm.deleteGroup = deleteGroup;
  vm.deleteHarvestRule = deleteHarvestRule;
  vm.openRuleDetails = openRuleDetails; 
  vm.assignGroupToRule = assignGroupToRule;
  vm.refreshData = refreshData;
  vm.refreshTaxRules = refreshTaxRules;
  vm.assignRuleToGroup = assignRuleToGroup;
  vm.openAddAccountsModal = openAddAccountsModal;
  vm.goBack = goBack;
  vm.showHarvestRequests = showHarvestRequests;

  vm.accountGroupTable = new NgTableParams({
      count: 25,
    }, {
      dataset: [],
      counts: []
    });

  vm.noRulesAssignedTable = new NgTableParams({
      count: 25,
    }, {
      dataset: [],
      counts: []
    });

  vm.harvestRulesTable = new NgTableParams({
      count: 25,
    }, {
      dataset: [],
      counts: []
    });

  init();

  $scope.$on('refresh-tax-groups', function(event, data){
    refreshData();
  });

  function init(){
    
    var lossMonitorMock = [
        {
            "id": "f6758746-9d31-464a-ade2-13d5aca2d1b1",
            "name": "New group12345",
            "taxLossDetail": {
                "realizedLongTerm": 0,
                "realizedShortTerm": 0,
                "unrealizedLongTerm": 0,
                "unrealizedShortTerm": 0,
                "opportunities": 5,
                "lastAction": "there was no action",
                "accounts": 0
            }
        },
        {
            "id": "9bbe82d8-64b8-4c98-adf2-2e30bc8b8a4b",
            "name": "my new group",
            "taxLossDetail": {
                "realizedLongTerm": 0,
                "realizedShortTerm": 0,
                "unrealizedLongTerm": 0,
                "unrealizedShortTerm": 0,
                "opportunities": 5,
                "lastAction": "there was no action",
                "accounts": 4
            }
        },
        {
            "id": "574f2a28-a024-45f2-bd75-87c0b37a0848",
            "name": "new group test",
            "taxLossDetail": {
                "realizedLongTerm": 0,
                "realizedShortTerm": 0,
                "unrealizedLongTerm": 0,
                "unrealizedShortTerm": 0,
                "opportunities": 5,
                "lastAction": "there was no action",
                "accounts": 1
            }
        }
    ];

    // vm.accountGroupTable.settings().dataset = [{
    //       name: 'My Test Group'
    //     }, {
    //       name: 'my second test group'
    //     }];//rulesAssigned;
    //     vm.accountGroupTable.reload();

    vm.loadingGroupData = true;
    $q.all([HarvestingFactory.getTaxGroups(), lossMonitorMock ])
    .then(function(results){
      vm.loadingGroupData = false;
        var taxGroups = results[0].data.data;
        var taxLossDetails = results[1];

        var taxGroupsWithDetails = taxGroups.map(function(group){
           var id = group.id;

           taxLossDetails.forEach(function(detail){
              var groupDetailId = detail.id;

              if (groupDetailId == id){
                angular.extend(group, {taxLossDetail: detail.taxLossDetail});
              }
           });
           return group;
        });

        vm.taxGroups = taxGroupsWithDetails;

        var noRulesAssigned = [];
        var rulesAssigned = [];
        taxGroupsWithDetails.forEach(function(group){
          if (angular.isDefined(group.lotScreenRule)){
            rulesAssigned.push(group);
          } else {
            noRulesAssigned.push(group);
          }
        });

        vm.accountGroupTable.settings().dataset = rulesAssigned;
        vm.accountGroupTable.reload();

        vm.noRulesAssignedTable.settings().dataset = noRulesAssigned;
        vm.noRulesAssignedTable.reload();
    })
    .catch(function(err){
      vm.loadingGroupData = false;
      // toastr.error("Failed to load tax groups");
      // console.error(err);
      Dashboard.toastError("Failed to load tax groups", err);
    });

    HarvestingFactory.getHarvestRules()
    .then(function(res){
      console.log('rules: ', res);
      var response = res.data;
      var harvestRules = response.data;

      vm.harvestRulesTable.settings().dataset = harvestRules;
      vm.harvestRulesTable.reload();
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error loading tax rules");
      Dashboard.toastError("Failed to load tax rules", err);
    });

    // SmartXFactory.getAccounts('active')
    // .then(function(result){
    //   vm.accounts = result;
    //   console.log("Accounts: ", vm.accounts);
    // })
    // .catch(function(err){
    //   // console.error(err);
    //   // toastr.error("Error loading accounts");
    //   Dashboard.toastError("Failed to load accounts", err);
    // });
  }

  function refreshData(){
    init();
  }

  function showHarvestRequests () {
    Tabs.addTab('harvest-requests');
  }

  function openAddAccountsModal(row){

    var groupId = row.id;
    var modalInstance = $uibModal.open({
      animation: true,
      size: 'lg',
      template: $templateCache.get('assignAccountsToGroup.tpl.html'),
      resolve: {
        group: function () {
          return row;
        }
      },
      controller: function($scope, $uibModalInstance, group, $timeout, Dashboard, FeatureFlags, HarvestingFactory){

        var vm = this;
        vm.loadingAccounts = false;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.availableAccounts = [];
        vm.multipleDemo = {
          selectedPeople: []
        };
        vm.selectedAccount = {};
        vm.selectedAccounts = [];


        vm.onSelect = function ($item, $model, $label) {
          $item.harvestAllowedByBroker = FeatureFlags.getAllowedByBroker('taxharvest', $item.custodian.name);
          $item.accountTypeCanHarvest = HarvestingFactory.accountTypeCanHarvest($item);
          $item.harvestTaxes = $item.accountTypeCanHarvest && ($item.harvestAllowedByBroker ? true : false);
          $item.monitorWashSale = true;
          vm.selectedAccounts.push($item);
          vm.searchText = '';

          // clear out the input
          $scope.$broadcast('accountLookup::clear');
        };

        vm.multiselectSettings = {
          enableSearch: true, 
          searchField: 'name', 
          idProp: 'guid', 
          displayProp: 'name', 
          externalIdProp: ''
        };
        vm.customTexts = {buttonDefaultText: 'Select Accounts'};
        vm.selectedAccounts = [];

        vm.startsWith = function(symbol, viewValue) {

          console.log(symbol);

          // only pay attention when there's an information object available.
          if (symbol.information){
            var matchesBroker = (symbol.information.brokerAccountNumber.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase());
            var matchesName = (symbol.information.name.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase());
            return matchesBroker || matchesName;
          } else {
            return false;
          }
        };

        vm.accountTypeCanHarvest = HarvestingFactory.accountTypeCanHarvest;

        initRuleAssignModal();

        function initRuleAssignModal(){

          vm.loadingAccounts = true;

          Promise.all([Promise.resolve([]),HarvestingFactory.getGroupAccounts(group.id)])
          .then(function(result){
            
            var existingGroupAccounts = result[1].data.data.map(function(member){
              return member.account;
            });

            vm.availableAccounts = result[0].filter(function(account) {
              return account.internalType !== 'sma' && !_.contains(_.pluck(existingGroupAccounts, 'id'), account.id);
            })
            .map(function(account){
              var harvestAllowedByBroker = FeatureFlags.getAllowedByBroker('taxharvest', account.custodian ? account.custodian.name : account.brokerageName);
              account.harvestTaxes = HarvestingFactory.accountTypeCanHarvest(account) ? harvestAllowedByBroker : false;
              account.harvestAllowedByBroker = harvestAllowedByBroker;
              account.monitorWashSale = true;

              return account;
            });
          })
          .catch(function(err){
            // console.error(err);
            // toastr.error("Error loading accounts");
            Dashboard.toastError("Failed to load accounts", err);
          })
          .finally(function(){
            $timeout(function(){
              vm.loadingAccounts = false;
            });
          });
        }

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(accounts){

          console.log("modal closed");
          console.log("accounts to add: ", accounts);

          // var payload = {
          //   "taxGroupId": groupId,
          //   "accounts": accounts.map(function(account){ 
          //     return {
          //       accountId: account.id || account.accountId,
          //       harvestTaxes: account.harvestTaxes || false,
          //       monitorWashSale: account.monitorWashSale || false
          //     };
          //   })
          // };

          var reqs = accounts.map(function(account){ 
            var payload = {
              accountId: account.id || account.accountId,
              harvestTaxes: account.harvestTaxes || false,
              monitorWashSale: account.monitorWashSale || false
            };

            return HarvestingFactory.addAccountsToGroup(groupId, payload);
          });
      
          if (reqs.length){
            vm.addingAccounts = true;
            $q.all(reqs)
            .then(function(res){
              console.log(res);
              toastr.success("Accounts added to group.");
              refreshData();
              $uibModalInstance.close();
            })
            .catch(function(err){
              // console.error(err);
              // toastr.error(err.message || "Error adding accounts to tax group");
              Dashboard.toastError(err.message || "Failed adding accounts to tax group", err);
            })
            .finally(function(){
              $timeout(function(){
                vm.addingAccounts = false;
              });
            });
          } else {
            toastr.success("Accounts added to group.");
            refreshData();
            $uibModalInstance.close();
          }
        }

      },
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(accounts) {});
  }

  function assignRuleToGroup(rule){
    var modalInstance = $uibModal.open({
      animation: true,
      template: $templateCache.get('assignTaxRuleToGroupModal.tpl.html'),
      controller: function($uibModalInstance){

        var vm = this;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.addGroupToList = addGroupToList;
        vm.selectedGroups = [];

        initRuleAssignModal();

        function initRuleAssignModal(){

          vm.loadingRules = true;

           HarvestingFactory.getTaxGroups()
            .then(function(res){
              vm.loadingRules = false;
              var response = res.data;
              var taxGroups = response.data;

              $timeout(function() {
                vm.groups = taxGroups.filter(function(group){
                  return !angular.isDefined(group.lotScreenRule);
                });
              });
            })
            .catch(function(err){
              vm.loadingRules = false;
              // console.error(err);
              // toastr.error("Unable to load tax rules");
              Dashboard.toastError("Failed to load tax rules", err);
              return false;
            });
        }

        function addGroupToList(){

          vm.selectedGroups.push(vm.selectedGroup);
        }

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(newRule){
          $uibModalInstance.close(vm.selectedGroups);
        }

      },
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(groups) {

      console.log("Groups to assign: ", groups);

      groups.forEach(function(group){
        group = typeof group == 'string' ? JSON.parse(group) : group; // comes in as string from select value
        var payload = {
          name: group.name,
          filingStatus: group.filingStatus,
          taxDate: {
            month: group.taxYear.month,
            day: group.taxYear.day
          },
          income: 0,
          longTermGoal: group.longTermGoal,
          shortTermGoal: group.shortTermGoal,
          lotScreenRuleId: rule.id // rule id from the original rule row that was clicked
        };

        HarvestingFactory.updateTaxGroup(group.id, payload)
        .then(function(res){
          console.log("res: ", res);
          toastr.success("Rule successfully assigned.");

          refreshData();
        })
        .catch(function(err){
          // console.error(err);
          // toastr.error("An error occurred while assigning the rule. Try again.");
          Dashboard.toastError("Failed to assign rule. Try again", err);
        });
      });
    });
  }

  // function getAccountsForRule(accounts, ruleId){
  //   var promise = $q(function(resolve,reject){

  //   });

  //   return promise;
  // }

  function assignGroupToRule(group){
    var modalInstance = $uibModal.open({
      animation: true,
      template: $templateCache.get('assignTaxRuleModal.tpl.html'),
      size: 'lg',
      controller: function($uibModalInstance){

        var vm = this;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.selectedGroup = group;
        vm.selectedGroups = [];
        // vm.selectedGroup = {};
        vm.hello = hello;
        vm.startsWith = startsWith;

        initRuleAssignModal();

        function initRuleAssignModal(){

          vm.loadingRules = true;

           HarvestingFactory.getHarvestRules()
            .then(function(res){
              vm.loadingRules = false;
              var response = res.data;
              var taxRules = response.data;

              vm.rules = taxRules;
              $scope.$apply();
            })
            .catch(function(err){
              vm.loadingRules = false;
              // toastr.error("Unable to load tax rules");
              // console.error(err);
              Dashboard.toastError("Failed to load tax rules", err);
              return false;
            });
        }

        function startsWith(rule, viewValue) {

          console.log(symbol);

          return rule.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
        }

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function hello(){
          alert('hello');
        }

        function submit(newRule){
          $uibModalInstance.close(vm.selectedRuleId);
        }

      },
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(ruleId) {

      console.log("modal closed");
      console.log("assign rule to group: ", ruleId);

      var payload = {
        name: group.name,
        filingStatusId: group.filingStatusId,
        taxDate: {
          month: group.taxYear.month,
          day: group.taxYear.day
        },
        income: 0,
        longTermGoal: group.longTermGoal,
        shortTermGoal: group.shortTermGoal,
        lotScreenRuleId: ruleId
      };

      HarvestingFactory.updateTaxGroup(group.id, payload)
      .then(function(res){
        console.log("res: ", res);
        toastr.success("Rule successfully assigned.");

        refreshData();
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error("An error occurred while assigning the rule. Try again.");
        Dashboard.toastError("Failed to assign rule. Try again", err);
      });
    });
  }

  function openRuleDetails(taxRuleId, readOnly){

    // TODO: open modal with group info
    var modalInstance = $uibModal.open({
      animation: true,
      template: $templateCache.get('taxRuleModal.tpl.html'),
      size: 'lg',
      controller: function($uibModalInstance){

        var vm = this;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.readOnly = readOnly || false;
        vm.rule = {};
        vm.popoverVerbiage = popoverVerbiage;
        vm.verbiages = {
          minGainAmount: "<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. For Gains, please use a positive value in both fields. This filter limits the system from flagging small gains in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossAmount:"<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. This filter limits the system from flagging small losses in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore losses where the position is only slightly down since acquisition.</span>',
          minGainPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore gains where the position is only slightly down since acquisition.</span>'
        };
        vm.isOpen = false;


        init();

        function init(){

          vm.loadingTaxRule = true;
          HarvestingFactory.getHarvestRules(taxRuleId)
          .then(function(res){
            console.log(res);
            var response = res.data;
            var ruleDetails = response.data;

            // TODO: do this more angularly by tapping into the model $formatter
            ruleDetails.minimumGainPercent = ruleDetails.minimumGainPercent ? Math.abs(math.format(ruleDetails.minimumGainPercent * 100, { precision: 14 })) : null;
            ruleDetails.minimumLossPercent = ruleDetails.minimumLossPercent ? Math.abs(math.format(ruleDetails.minimumLossPercent * 100, { precision: 14 })) : null;
            ruleDetails.minimumPositionLoss = ruleDetails.minimumPositionLoss ? Math.abs(math.format(ruleDetails.minimumPositionLoss * 100, { precision: 14 })) : null;
            ruleDetails.minimumPositionGain = ruleDetails.minimumPositionGain ? Math.abs(math.format(ruleDetails.minimumPositionGain * 100, { precision: 14 })) : null;

            vm.rule = ruleDetails;
          })
          .catch(function(err){
            // console.error(err);
            // toastr.error('Error loading rule.');
            Dashboard.toastError("Failed to load rule", err);
          })
          .finally(function(){
            $timeout(function(){
              vm.loadingTaxRule = false;
            });
          });
        }

        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(newRule){
          if (newRule.applyToLots || newRule.applyToPositions) {

            if (!readOnly){

              var payload = angular.copy(newRule);

              // TODO: do this more angularly by tapping into the model $formatter
              payload.minimumGainPercent = newRule.minimumGainPercent / 100;
              payload.minimumLossPercent = Math.abs(newRule.minimumLossPercent / 100);
              payload.minimumPositionLoss = Math.abs(newRule.minimumPositionLoss / 100);
              payload.minimumPositionGain = newRule.minimumPositionGain / 100;
              payload.minimumLossValue = Math.abs(newRule.minimumLossValue);
              payload.minimumGainValue = Math.abs(newRule.minimumGainValue);

              vm.updatingRule = true;
      
              HarvestingFactory.updateHarvestRule(taxRuleId, payload)
              .then(function(res){
                var response = res.data;
                toastr.success("Tax rule updated");
                refreshTaxRules();
                $uibModalInstance.close(response);
              })
              .catch(function(err){
                // console.error(err);
                // toastr.error("Error updating tax rule");
                Dashboard.toastError("Failed to update tax rule", err);
              })
              .finally(function(){
                vm.updatingRule = false;
              });
            }
          } else vm.showAlert = true;
        }

      },
      controllerAs: 'vm',
    });

    modalInstance.result.then(function(newRule) {
      console.log('Result: ', newRule);
    })
    .catch(function(err) {
      console.error(err);
    });
  }

  function deleteGroup(group, index){

    var id = group.id;

    group.deleting = true;

    HarvestingFactory.deleteTaxGroup(id)
    .then(function(res){

      if (res.data.errorMessage){
        toastr.error(res.data.errorMessage);
        return;
      }

      console.log("group deleted: ", res);
      toastr.success('Group successfully deleted.');

      if (group.lotScreenRule != null){
        vm.accountGroupTable.settings().dataset.splice(index, 1);
        vm.accountGroupTable.reload();
      } else {
        vm.noRulesAssignedTable.settings().dataset.splice(index, 1);
        vm.noRulesAssignedTable.reload();
      }
    })
    .catch(function(err){
      // toastr.error('Error Deleting Group.');
      // console.error(err);
      Dashboard.toastError("Failed to delete group", err);
    })
    .finally(function() {
      group.deleting = false;
    });
  }

  function deleteHarvestRule(id, rule, index){

    // vm['deletingRule' + id] = true;

    rule.deleting = true;

    if (rule.taxGroupCount){
      SweetAlert.swal({
         title: "Are you sure?",
         text: "This rule has " + rule.taxGroupCount + " associated groups.",
         type: "warning",
         showCancelButton: true,
         confirmButtonColor: "#DD6B55",
         confirmButtonText: "Yes, delete it.",
         showLoaderOnConfirm: true,
         closeOnConfirm: false
      }, 
      function(isConfirm){ 
         // Remove the rule from each group that has it
         // delete the rule
        if (isConfirm) {
          setTimeout(function(){
            SweetAlert.swal({
              title : 'Rule was deleted.',
              type : "success"
            });
          }, 2000);

          var reqs = [];

          var taxGroupsWithRule = vm.taxGroups.filter(function(group){
            return group.lotScreenRule && group.lotScreenRule.id == id;
          });

          taxGroupsWithRule.forEach(function(group){

            var groupDetails = _.find(vm.taxGroups, {id: group.id});

            if (groupDetails){
              var payload = {
                name: groupDetails.name,
                filingStatus: groupDetails.filingStatus,
                taxDate: groupDetails.taxYear,
                income: 0,
                longTermGoal: groupDetails.longTermGoal,
                shortTermGoal: groupDetails.shortTermGoal,
                lotScreenRuleId: null,
              };

              reqs.push(HarvestingFactory.updateTaxGroup(group.id, payload));
            }
          });

          // delete the rule from all associated groups
          $q.all(reqs)
          .then(function(res){
            // delete the rule once it has been removed from all the groups
            HarvestingFactory.deleteHarvestRule(id)
            .then(function(res){
              console.log("rule deleted: ", res);
              // vm['deletingRule' + id] = false;
              if (res.data.errorMessage){
                toastr.error(res.data.errorMessage);
                return;
              }

              toastr.success('Rule successfully deleted.');
              vm.harvestRulesTable.settings().dataset.splice(index, 1);
              vm.harvestRulesTable.reload();

              // refresh groups so that ones with no rule get moved to the unassigned section
              vm.refreshData();
            })
            .catch(function(err){
              // vm['deletingRule' + id] = false;
              // toastr.error('Error Deleting rule.');
              // console.error(err);
              Dashboard.toastError("Failed to delete rule", err);
            });
          })
          .finally(function(){
            rule.deleting = false;
          });
        }
      });
    } else {
      HarvestingFactory.deleteHarvestRule(id)
      .then(function(res){
        console.log("rule deleted: ", res);
        // vm['deletingRule' + id] = false;
        if (res.data.errorMessage){
          toastr.error(res.data.errorMessage);
          return;
        }

        toastr.success('Rule successfully deleted.');
        vm.harvestRulesTable.settings().dataset.splice(index, 1);
        vm.harvestRulesTable.reload();
      })
      .catch(function(err){
        // vm['deletingRule' + id] = false;
        // toastr.error("Error deleting rule");
        // console.error(err);
        Dashboard.toastError("Failed to delete rule", err);
      })
      .finally(function(){
        rule.deleting = false;
      });
    }
  }

  function getDaysForMonth(month){

    if (month){
      var year = moment().year();
      var numDays = new Date(year, Number(month), 0).getDate(); // gets the last date of the previous month
      return _.range(1, numDays + 1);
    }
  }

  function goBack(){
    vm.title = 'Tax Groups'; 
    vm.showingSubview = false;
  }

  function createNewGroup(group){ 
    
    // if editing pass the group object to the edit tab to fill in the current info
    Tabs.addTab('editTaxGroup', null, null, {
      group: group || null,
      action: group ? 'Edit' : 'Create'
    });
  }

  function openHarvestMonitor(group){
    Tabs.addTab('harvestMonitor', null, null, { id: group.id });
  }

  function addRuleToDropdown(newRule){
    if (vm.rules) vm.rules.push(newRule);
    else vm.rules = [newRule];
  }

  function refreshTaxRules(){

    vm.loadingRules = true;
    HarvestingFactory.getHarvestRules()
    .then(function(res){
      console.log('rules: ', res);
      var response = res.data;
      var harvestRules = response.data;

      vm.loadingRules = false;
      vm.harvestRulesTable.settings().dataset = harvestRules;
      vm.harvestRulesTable.reload();
    })
    .catch(function(err){
      vm.loadingRules = false;
      // console.error(err);
      // toastr.error("Unable to load tax rules");
      Dashboard.toastError("Failed to load tax rules", err);
    });
  }

  function openRuleModal(id, action){


    var modalInstance = $uibModal.open({
      animation: true,
      template: $templateCache.get('taxRuleModal.tpl.html'),
      size: 'lg',
      resolve: {},
      controller: function($uibModalInstance){

        var vm = this;
        var rule;

        vm.cancel = cancel;
        vm.submit = submit;
        vm.rule = {};
        vm.popoverVerbiage = popoverVerbiage;
        vm.verbiages = {
          minGainAmount: "<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. For Gains, please use a positive value in both fields. This filter limits the system from flagging small gains in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossAmount:"<span>You can define a minimum dollar amount and percentage of the account for the system to flag a particular position or lot as available for harvesting. This filter limits the system from flagging small losses in terms of dollars and percentage of the account as opportunities for you to harvest.</span>",
          minLossPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore losses where the position is only slightly down since acquisition.</span>',
          minGainPercentageOnPositionOrLot: '<span>Set a minimum percent change for the entire position or individual lots. This filter allows the system to ignore gains where the position is only slightly down since acquisition.</span>'
        };



        function cancel() {
          $uibModalInstance.dismiss('cancel');
        }

        function submit(newRule){
          if (newRule.applyToLots || newRule.applyToPositions) {
            var payload = {
              "name": newRule.name,
              "minimumGainValue": newRule.minimumGainValue,
              "minimumGainPercent": newRule.minimumGainPercent / 100,
              "minimumLossValue": Math.abs(newRule.minimumLossValue),
              "minimumLossPercent": Math.abs(newRule.minimumLossPercent / 100),
              "minimumPositionLoss": Math.abs(newRule.minimumPositionLoss / 100),
              "minimumPositionGain": newRule.minimumPositionGain / 100,
              "applyToLots": newRule.applyToLots || false,
              "applyToPositions": newRule.applyToPositions || false
            };
      
            if (action == 'edit'){
      
              vm.updatingRule = true;
              HarvestingFactory.updateHarvestRule(id, payload)
              .then(function(res){
                console.log("res: ", res);
                toastr.success("Tax rule updated.");
                $uibModalInstance.close(res.data.data);
                addRuleToDropdown(newRule);
              })
              .catch(function(err){
                // console.error(err);
                // toastr.error("Error updating rule.");
                Dashboard.toastError("Failed to update rule", err);
              })
              .finally(function(){
                $timeout(function() { vm.updatingRule = false; });
              });
            } else {

              vm.creatingRule = true;
              HarvestingFactory.createHarvestRule(payload)
              .then(function(res){
                console.log("res: ", res);
      
                addRuleToDropdown(newRule);
                // refreshTaxRules();
                toastr.success("Tax rule created.");
                $uibModalInstance.close(res.data.data);
              })
              .catch(function(err){
                // console.error(err);
                // toastr.error("Error creating rule.");
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                $timeout(function(){ vm.creatingRules = false; });
              });
            }
          }
          else vm.showAlert = true;
        }

      },
      controllerAs: 'vm'
    });

    modalInstance.result.then(function(newRule) {
      if (newRule) {
        vm.harvestRulesTable.settings().dataset.unshift(newRule);
        vm.harvestRulesTable.reload();
      }
    });
  }

  function addGroup(groupData){
    var payload = {
      name: groupData.groupName,
      filingStatus: groupData.filingStatus,
      taxDate: {
        month: groupData.taxMonth,
        day: groupData.taxDay
      },
      income: 0,
      longTermGoal: groupData.longTermGoal,
      shortTermGoal: groupData.shortTermGoal
    };

    HarvestingFactory.createTaxGroup(payload)
    .then(function(res){
      console.log("res: ", res);
      toastr.success('Group successfully created.');
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error("Error creating group.");
      Dashboard.toastError("Failed to create group", err);
    });
  }
})
.controller('HarvestRequestsCtrl', function(HarvestingFactory, toastr, $timeout, NgTableParams, Dashboard, ExclusionsFactory) {
  var vm = this;

  vm.loadingHarvestRequests = false;
  vm.harvestRequestsTable = new NgTableParams({
    count: 25,
    sorting: {harvestDate: 'desc'},
    filter: {taxLotHarvestSubstitutionStatus: ''}
  }, {
    dataset: [],
    counts: []
  });

  vm.reverseHarvestSubstitutionRule = reverseHarvestSubstitutionRule;
  vm.exportData = exportData;
  vm.filterTable = filterTable;
  vm.getSummaryDetails = getSummaryDetails;
  vm.harvestRequests = [];
  vm.searchText = '';
  
  vm.statusFilterOptions = [
    {
      id: '',
      title: 'All',
    },
    {
      id: 'pastWashPeriod',
      title: 'Ready to Reverse'
    },
    {
      id: 'closed',
      title: 'Closed'
    },
    {
      id: 'inWashPeriod',
      title: 'Not Ready',
    },
    {
      id: 'cancelled',
      title: 'Cancelled'
    }
  ];

  init();

  function init() {

    vm.loadingHarvestRequests = true;
    HarvestingFactory.getHarvestRequests()
    .then(function(res){
      var harvestRequests = res.data.data;
      vm.harvestRequests = harvestRequests;
      vm.harvestRequestsTable.settings().dataset = harvestRequests;
      vm.harvestRequestsTable.reload();
    })
    .catch(function(err){
      // toastr.error(err.message);
      // console.error(err);
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      vm.loadingHarvestRequests = false;
    });
  }

  function getSummaryDetails (summary) {

    if (summary.summaryLotDetails.length) return;

    summary.loadingDetails = true;

    HarvestingFactory.getHarvestRequestDetails({ids: summary.id})
    .then(function(res){
      console.log("Harvest Request Details: ", res.data.data);
      summary.summaryLotDetails = res.data.data;
    })
    .catch(function(err){
      console.error(err.message);
    })
    .finally(function(){
      $timeout(function() {
        summary.loadingDetails = false;
      });
    });
  }

  function filterTable () {
    var term = vm.searchText;

    // if we didn't get a number, apply filter to tax group name
    // otherwise default to account number lookup
    if (isNaN(Number(term))) {
      vm.harvestRequestsTable.filter({taxGroup: {name: term}});
    } else {
      vm.harvestRequestsTable.filter({accountStubView: {brokerageAccountNumber : term}});
    }
  }

  function reverseHarvestSubstitutionRule (row) {
    row.deletingExclusion = true;
    var subId = row.accountSymbolExclusionRuleId;
    ExclusionsFactory.delete(subId)
    .then(function(result){
      console.log(result);
      toastr.success('Success');
    })
    .catch(function(err){
      // console.error(err);
      // toastr.error(err.message);
      Dashboard.toastError(err.message, err);
    })
    .finally(function(){
      $timeout(function(){
        row.deletingExclusion = false;
        init();
      });
    });
  }

  function exportData () {
    var formattedData = angular.copy(vm.harvestRequests).map(function(harvestRequest){

      // copy nested properties to top level
      harvestRequest = _.extend(harvestRequest, harvestRequest.reversalReport, harvestRequest.accountStubView, harvestRequest.accountStubView.brokerage.name, harvestRequest.accountStubView.enterprise.name, harvestRequest.gainLossSummary);
      harvestRequest.taxGroup = harvestRequest.taxGroup.name;

      delete harvestRequest.reversalReport;
      delete harvestRequest.accountStubView;
      delete harvestRequest.gainLossSummary;
      delete harvestRequest.brokerage;
      delete harvestRequest.enterprise;

      // strip ids
      _.each(harvestRequest, function(prop, key){
        if (key.toLowerCase().indexOf('id') !== -1){
          delete harvestRequest[key];
        }

        delete harvestRequest.isApm;
        delete harvestRequest.isTrading;
        delete harvestRequest.custodian;
        delete harvestRequest.holdingPeriodLabel;
      });

      return harvestRequest;
    });

    Dashboard.JSONtoCSV(formattedData, 'harvest_request_summary_' + moment().format('MM/DD/YY') + '.csv');
  }
});