var AddAccount = angular.module('AddAccountCtrl', []);
AddAccount.controller("AddAccountCtrl", function ($templateCache, $rootScope, $scope, formlyVersion, WizardHandler, ProfileService, SweetAlert, Tabs, Brokerage, Dashboard, $q, AddAccountService, $uibModal, toastr, $filter, $anchorScroll, $location) {

      var vm = this;
      var modalInstance;
      var defaultTierPayload;

      vm.flatCalculation;
      vm.feeTableCalculation;

      vm.finishWizard = finishWizard;

      vm.form = {
         step1: {},
         step2: {},
         step3: {},
         step3b: {}
      };
      vm.model1 = {};
      vm.model2IB = {};
      vm.model2Apex = {};
      vm.model3 = {};
      vm.model4 = {};
      vm.confirmationModel = {};
      vm.FormlySelectedBrokerID = {};
      //model="vm.model3" fields="vm.fields.step3IB" form="vm.forms.step3IB"
      vm.options = {
         formState: {
            step2IB_isEnabled: false,
            brokerageName: null,
            flatCalculation: null
         }
      }

      $scope.$watch('vm.options', function(newVal, oldVal){
         console.log("New vm.options value: ", newVal)
         console.log("Old vm.options value: ", oldVal)
      })

      vm.gotoTop = function(x) {
         $location.hash('add-account-form-wrap');
         $anchorScroll();
       };

      vm.exitValidation = function (form) {
         return form && !form.$invalid;
      };

      vm.brokerSelected = function (broker) {

         vm.FormlySelectedBrokerID = broker;
         // console.log( vm.forms.step2 );
      }

      vm.nextStep = function (brokerId, brokerName, link) {
         console.log($scope.options.formState);

         if (brokerName != 'Select') {
            WizardHandler.wizard().next();
         } else if (brokerId == 'ira' || brokerId == 'joint' || brokerId == 'entity') {

         }
      }

      vm.getBrokerSafeName = function (name) {

         if (name == 'tdameritrade') {
            return 'TD Ameritrade';
         } else if (name == 'interactivebrokers') {
            return 'Interactive Brokers';
         } else if (name == 'schwab') {
            return 'Charles Schwab';
         } else if (name == 'apex') {
            return 'Apex';
         }
      }

      vm.removeTab = function (index, lastDashTab) {

         console.log('lastDashTab', lastDashTab);

         Tabs.removeTab(index)
            .then(switchToLastDash(lastDashTab, index))
            .then(function (tab) {
               console.log(tab.title + " is now the active tab!");
            })
            .catch(function (err) {
               console.log(err);
               throw new Error(err);
            });

      };

      vm.helpMeChooseModal = function () {

         // modalInstance = $uibModal.open({
         //    templateUrl: 'help-me-choose.html',
         //    controller: function ($scope, $uibModalInstance) {
         //       $scope.closeModal = function () {
         //          $uibModalInstance.dismiss('cancel');
         //       };
         //    },
         //    size: 'lg'
         // });
         var url = './../sites/default/themes/hedgecovest/js/dashboardv2/assets/SMArtX%20Custodian%20Chart.pdf';
         var modalInstance = $uibModal.open({
           animation: true,
           template: $templateCache.get('pdfViewerModal.tpl.html'),
           size: 'lg',
           resolve: {
             pdfURL: function() {
               return url;
             }
           },
           controller: 'PDFViewerCtrl',
           controllerAs: 'vm'
         });
      };

      vm.fields = {
         step1: [
            {
               key: 'custodian',
               type: 'toggleRadioBtns',
               templateOptions: {
                  label: 'Select Account Brokerage Firm',
                  required: true,
                  model: vm.model1.selectAccountBroker,
                  options: [
                     {
                        name: 'TD Ameritrade',
                        value: 'tdameritrade',
                        //description: "For 40 years, we’ve been empowering clients by helping them take control of their financial lives. Today, we’re proud to continue to deliver exceptional access to education, resources, and service to help you pursue your goals with confidence. Today, TD Ameritrade provides investing and trading services for nearly six million client accounts that total more than $600 billion in assets, and custodial services for more than 4,000 independent registered investment advisors. With clients placing, on average, approximately 400,000 trades each day, it maintains the leadership position it assumed when founder Joe Ricketts opened his doors in Omaha, NE, in 1975.",
                        //buttonTxt: 'Open an Account at TDA',
                        buttonTxt: 'Open Account',
                        image: '/sites/default/themes/hedgecovest/images/tda_insti_v.png',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'Interactive Brokers',
                        value: 'interactivebrokers',
                        //description: "For 39 years the IB Group has been building electronic access trading technology that delivers real advantages to traders, investors and institutions worldwide. Interactive Brokers Group and its affiliates' equity capital exceeds $5 billion. We are the largest US electronic broker based on daily average revenue trades executing 576,000 trades per day and offer access to over 100 market centers in 24 countries. Our transparent, low commissions and financing rates, and best price executions minimize your costs to help you maximize your returns.",
                        //buttonTxt: 'Open an Account at IB',
                        buttonTxt: 'Open Account',
                        image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJcAAACXCAMAAAAvQTlLAAAAaVBMVEUAAADrKi7vKy/TJinyKy/nKS3XJirhKCzbJyv2LDC+IiWAFxk4CguvHyJ5FhhqExXIJCcPAwNdERImBwdCDA2QGhwiBgdTDxCkHSB0FRdYDxGeHB9vFBY9CwwaBAVKDQ4xCQorCAmIGBpfxrf/AAAEIklEQVR4nO2ca5OqMAyGaWi5igKKuLDi5f//yFMFSitVnDOzJB/6fjk7npmdZ5KYvE1hPc/JycnJycnJyUlpu8MmsKri7AebwaYfBqyR/6ZVlpXYMLq2HMTWazlwAP+nw8aZlEmgE3sK+BabZlKXg8xlL15h02i6iRGLFpdXhoqrxWYxVKk8ZtgohmQTG7gSbBRDAxdwn1aPrfgjkRC2e2ySF6U555Bt5E8NrXFZ7JLHFCruQmywWea65JzxHJviVUX77PtAq4fJIouHZkGrxDwv6tsrRFdsElMlkOyunnfiFMe35x3DcVCSsq4P8zoYxIBY5084zRK7jE4MCDnqh0bLA6LBRjG094fSBx8bxVRD1LvKc9tYYr/YKIb2wRgxccBmMZSqgCUFNouhsYmx+ISNYuiiDrpAax6pcxuEZ2wWXYUqfWLzqBnHEbXNgGpiTFywWXR1yolBSGhXJ51YrJwFrXmUqKVYfMdm0XURKmCM1BH8rkof/Bs2jKbOn9abpEqsVJlklPbUehOjNY+O0ZRJUlueFKZMkjqCKyeG76pvN82kHgCmEsNjupwikBJZOc7ESssklqs+Zoz3GJzndf+Z1sSwSqyMpmJinKX9p7X+YY2AVU+3V309DWCZVvr++lueq29iyWndnzmugV5iq3O1nL0I8r7OJyfG4vC4MtbxleoRneGQNjQx+T29r25d01m4JhfROzEOLYINy2DOBcHwn1Usg5VgnD+KxMLFxPj1C+IcZwrtcyvXaFPLdGz1t3X5FuKltAuiVbnUjYJRX6+bzEsOfOXt5tb2fTS3TJtMmou1t66dmCeS6xvprnqewFffBlv6ve5r7kEM1tz+tfaz+agtTHY5R9ueH8xMAlNHszrjk2tdf6tfB1xzDmJsVNcT01KMcttwCnsy4CwZVuTHShiFh3MLcqj8UAgRneqh5Hd6DPG4HrpelZnZ5fFr9yBwa7TJxLzZonPdWgsVPtc2sg1ybK5fP7ZS4XKdE2YPFirXIeO2wsLmqsIPVGhcafSusDC5mhzeFhYeV53MujsBrqINl6lW59rf2UJhoXCV/scvIRJXM7cNJLiy71K4fh6/qngELtuSiQKXdctEgev4dcBW7l9fZ3Jlrs66/8Ln8s404+V5LcU5JHX8LpPr+5zdV6WP4L+yb8AQuDZvjozYXF9lEsXf58tgKFyHZWOBc067LwYM6fy4mEkkrmYBC20PUC0EDIurCD6XPtrepKQZr6VxxIPl3/A36j41MS7wnuS2XfkNVJAhPpFfvDkdgUhwn8s8CwsVhwTj6RxD83H0eEyBwLsLL7fwkorG+wGNMKj8mkCsnppulYHnhN7pHm+VZax2VGL11PPVURkrWq82ef1fywi3az/utawuClJsBqs2V2wCJycnJycnJyen/9Q/+QcmMSfUex8AAAAASUVORK5CYII=',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'Charles Schwab',
                        value: 'schwab',
                        //description: "For 40 years, The Charles Schwab Corporation has been an advocate for individual investors and the financial professionals who serve them. Our belief in the power of investing is a perspective that's influenced our company from the beginning and creates a powerful bond between us and the investors, employers, and advisors we serve. Currently, Charles Schwab services $2.73 trillion in client assets, 1.6 million corporate retirement plan participants, 1.1 million banking accounts, and 7,000 Registered Investment Advisors.",
                        //buttonTxt: 'Open an Account at Schwab',
                        buttonTxt: 'Open Account',
                        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Charles_Schwab_Corporation_logo.png/220px-Charles_Schwab_Corporation_logo.png',
                        bootstrapSize: 'col-md-4'
                           //url: '/misc/HCV-ADV-Part-2.pdf#page=1&zoom=80' //Updated form 4/5/16
                     } /* {
                        name: 'Apex',
                        value: 'apex',
                        //description: "Apex Description Here",
                        //buttonTxt: 'Open an Account at Apex',
                        buttonTxt: 'Open Account',
                        image: "https://www.apexclearing.com/wp-content/uploads/2017/07/DWS_logo.png",                        
                        bootstrapSize: 'col-md-3'
                           //url: '/misc/HCV-ADV-Part-2.pdf#page=1&zoom=80' //Updated form 4/5/16
                     } */
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue == 'schwab') {
                        console.log(scope);
                     }

                     scope.model.custodian = newValue

                     var keys = Object.keys(scope.theFormlyForm.$$parentForm);
                     keys = keys.filter(function(currentValue, index, arr){
                       return currentValue.indexOf('formly_') != -1;
                     });

                     var keyId = keys[1];
                     var formly2 = scope.theFormlyForm.$$parentForm[keyId];
                     if(typeof formly2 == 'undefined'){ return; }

                     var formAT = formly2[keyId+'_toggleRadioBtns_accountType_0'],
                         formPM = formly2[keyId+'_radio_portfolioMarginActive_1'];

                     if (newValue == 'tdameritrade') {

                        if(typeof formPM != 'undefined'){

                           if(typeof formPM.$setViewValue == 'function'){
                              formPM.$setViewValue(false);
                              formPM.$$parseAndValidate();
                           }
                        }
                     }
                     else if (typeof formAT != 'undefined') {

                        if(typeof formAT.$setViewValue == 'function'){
                           formAT.$setViewValue(null);
                           formAT.$$parseAndValidate();
                        }
                     }
                  }
               }
            }, {
               key: 'help-link',
               type: 'help_link',
               templateOptions: {
                  options: [
                     {
                        text: 'Help me choose which broker is right for me.',
                        href: '#'
                     }
                  ]
               }
            }
         ],
         step2IB: [
            {
               key: 'accountType',
               type: 'toggleRadioBtns',
               model: vm.model2IB.accountType,
               templateOptions: {
                  label: 'Account Options',
                  required: true,
                  //	            model: vm.model2IB.accountType,
                  options: [
                     {
                        name: 'Individual',
                        value: 'individual',
                        description: 'For accounts with one person as a beneficiary. This account is taxable and has access to margin.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12 h-med',
                        urls: {
                           regT: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173246&token=67262&invitedBy=YWxleHRoNTg4',
                           pm: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173243&token=05156&invitedBy=YWxleHRoNTg4'
                        }
    					}, {
                        name: 'Joint',
                        value: 'joint',
                        description: 'For accounts with two persons as the beneficiaries. This account is taxable and has access to margin.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12 h-med',
                        urls: {
                           regT: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173276&token=07273&invitedBy=YWxleHRoNTg4',
                           pm: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173274&token=37934&invitedBy=YWxleHRoNTg4'
                        }
    					}, {
                        name: 'IRA',
                        value: 'ira',
                        description: 'Individual Retirement Accounts are for US Citizens only and are either tax-deferred or tax-exempt depending on the type of IRA. IRAs are not marginable, but Interactive Brokers does allow for funds to be immediately available upon liquidation rather than trade settlement.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12 h-med',
                        urls: {
                           regT: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173232&token=48587&invitedBy=YWxleHRoNTg4'
                        }
    					}, {
                        name: 'Organization',
                        value: 'organization',
                        //description: 'Select this account if you would like to open a brokerage account in the name of entity rather than a natural born person. This option is for hedge funds, other fund structures, small business accounts, family offices, corporations, LLCs, partnerships, or any unincorporated legal structures.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12',
                        urls: {
                           regT: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173286&token=53208&invitedBy=YWxleHRoNTg4',
                           pm: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173283&token=64847&invitedBy=YWxleHRoNTg4'
                        }
              }, {
                        name: 'Trust',
                        value: 'trust',
                        description: '',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12',
                        urls: {
                           regT_Irrevocable: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173270&token=86414&invitedBy=YWxleHRoNTg4',
                           regT_Revocable: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173294&token=72046&invitedBy=YWxleHRoNTg4',
                           regT_Testamentary: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173300&token=81920&invitedBy=YWxleHRoNTg4',
                           regT_Other: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173284&token=69081&invitedBy=YWxleHRoNTg4',
                           pm_Irrevocable: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173266&token=80273&invitedBy=YWxleHRoNTg4',
                           pm_Revocable: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173305&token=57978&invitedBy=YWxleHRoNTg4',
                           pm_Testamentary: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173298&token=24554&invitedBy=YWxleHRoNTg4g4',
                           pm_Other: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173290&token=70444&invitedBy=YWxleHRoNTg4'
                        }
              }, {
                        name: 'Fund or Pension',
                        value: 'fund',
                        description: '',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4 col-xs-12',
                        urls: {
                           all: 'https://www.interactivebrokers.com/Universal/servlet/formWelcome?&invitation_id=15173325&token=11150&invitedBy=YWxleHRoNTg4'
                        }
              }
    				]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue != '' && typeof newValue != 'undefined') {
                        if (newValue != 'trust') {
                           scope.model.trustType = undefined;
                           delete scope.model.trustType
                        }
                        if (newValue == 'ira') {
                           scope.model.portfolioMarginActive = false;
                        }
                        if (oldValue == 'ira') {
                           scope.model.portfolioMarginActive = undefined;
                        }
                        // debugger
                        if (newValue == 'organization' || newValue == 'trust' || newValue == 'fund') {
                           vm.isCompany = true;
                        } else {
                           vm.isCompany = false
                        }

                        vm.model2IB.accountType = newValue;

                        setRedirectURL(scope);
                     }
                  }
               }
            },
            {
               key: 'portfolioMarginActive',
               className: 'col-md-12 mv-50',
               type: 'radio',
               templateOptions: {
                  label: 'This type of margin allows for more leverage than traditional Regulation T Margin. However, if you elect this option, your account will need to be funded with at least $125,000. Certain models available on HedgeCoVest may require or may be better replicated using portfolio margin. Please also be aware that utilizing more leverage may increase risk. Selecting Portfolio Margin for your HedgeCoVest account does not necessarily mean you will utilize the extra leverage available to you.',
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               hideExpression: function($viewValue, $modelValue, scope) {
                  var custodian = null;


                 var keys = Object.keys(scope.theFormlyForm.$$parentForm);
                 keys = keys.filter(function(currentValue, index, arr){
                   return currentValue.indexOf('formly_') != -1;
                 });

                 var keyId = keys[0];
                 var formly1 = scope.theFormlyForm.$$parentForm[keyId];
                 var toogleKey = keyId + '_toggleRadioBtns_custodian_0';


                  if(typeof formly1 != 'undefined' && typeof formly1[toogleKey] != 'undefined'){
                     custodian = formly1[toogleKey].$modelValue;
                  }

                  return (scope.model.accountType == 'ira' || vm.model1.custodian == 'tdameritrade' || vm.model1.custodian == 'apex');
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'trustType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'trust';
               },
               templateOptions: {
                  label: 'Trust Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Irrevocable',
                        value: 'Irrevocable',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Revocable',
                              value: 'Revocable',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Testamentary',
                              value: 'Testamentary',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Other',
                              value: 'Other',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     },
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.accountSubtype = newValue
                     else delete scope.model.accountSubtype;
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'jointType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'joint' || vm.model1.custodian != 'apex';
               },
               templateOptions: {
                  label: 'Joint Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Tenants in Common',
                        value: 'jointTenantsInCommon',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                        name: 'Rights of Survivorship',
                        value: 'jointRightsOfSurvivorship',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                        name: 'Community Property',
                        value: 'jointCommunityProperty',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                        name: 'Tenants by Entirety',
                        value: 'jointTenantsByEntirety',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     },
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.accountType = newValue
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'iraType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'ira' || vm.model1.custodian != 'apex';
               },
               templateOptions: {
                  label: 'IRA Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Simple IRA',
                        value: 'iraSimple',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'Roth IRA',
                        value: 'iraRoth',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'SEP Rollover IRA',
                        value: 'iraSepRollover',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.accountType = newValue
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'IB_RedirectURL',
               type: 'input',
               templateOptions: {
                  //required: true
                  type: 'hidden',
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     console.log(newValue, scope);
                  }
               }
            }
         ], 
         step2Apex: [
            {
               key: 'accountType',
               type: 'toggleRadioBtns',
               model: vm.model2Apex.accountType,
               templateOptions: {
                  label: 'Account Options',
                  required: true,
                  options: [
                     {
                        name: 'Individual',
                        value: 'individual',
                        description: 'For accounts with one person as a beneficiary. This account is taxable and has access to margin.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3 col-xs-12 h-med',
                     }, 
                     {
                        name: 'Joint',
                        value: 'joint',
                        description: 'For accounts with two persons as the beneficiaries. This account is taxable and has access to margin.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3 col-xs-12 h-med',
                     }, 
                     {
                        name: 'IRA',
                        value: 'ira',
                        description: 'Individual Retirement Accounts are for US Citizens only and are either tax-deferred or tax-exempt depending on the type of IRA. IRAs are not marginable, but Interactive Brokers does allow for funds to be immediately available upon liquidation rather than trade settlement.',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3 col-xs-12 h-med',
                     }, 
                     {
                        name: 'Custodian',
                        value: 'custodian',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3 col-xs-12',
                     }, 
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue != '' && typeof newValue != 'undefined') {
                        if (newValue != 'trust') {
                           scope.model.trustType = undefined;
                           delete scope.model.trustType
                        }
                        if (newValue == 'ira') {
                           scope.model.portfolioMarginActive = false;
                        }
                        if (oldValue == 'ira') {
                           scope.model.portfolioMarginActive = undefined;
                        }
                        if (newValue == 'organization' || newValue == 'trust' || newValue == 'fund') {
                           vm.isCompany = true;
                        } else {
                           vm.isCompany = false
                        }

                        vm.model2Apex.accountType = newValue;

                     }
                  }
               }
            },
            {
               key: 'portfolioMarginActive',
               className: 'col-md-12 mv-50',
               type: 'radio',
               templateOptions: {
                  label: 'This type of margin allows for more leverage than traditional Regulation T Margin. However, if you elect this option, your account will need to be funded with at least $125,000. Certain models available on HedgeCoVest may require or may be better replicated using portfolio margin. Please also be aware that utilizing more leverage may increase risk. Selecting Portfolio Margin for your HedgeCoVest account does not necessarily mean you will utilize the extra leverage available to you.',
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               hideExpression: function($viewValue, $modelValue, scope) {
                  var custodian = null;


                 var keys = Object.keys(scope.theFormlyForm.$$parentForm);
                 keys = keys.filter(function(currentValue, index, arr){
                   return currentValue.indexOf('formly_') != -1;
                 });

                 var keyId = keys[0];
                 var formly1 = scope.theFormlyForm.$$parentForm[keyId];
                 var toogleKey = keyId + '_toggleRadioBtns_custodian_0';


                  if(typeof formly1 != 'undefined' && typeof formly1[toogleKey] != 'undefined'){
                     custodian = formly1[toogleKey].$modelValue;
                  }

                  return (scope.model.accountType == 'ira' || custodian == 'tdameritrade' || vm.model1.custodian == 'apex');
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'trustType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'trust';
               },
               templateOptions: {
                  label: 'Trust Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Irrevocable',
                        value: 'Irrevocable',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Revocable',
                              value: 'Revocable',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Testamentary',
                              value: 'Testamentary',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Other',
                              value: 'Other',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     },
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.trustType = newValue
                     else delete scope.model.trustType;
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'jointType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'joint' || vm.model1.custodian != 'apex';
               },
               templateOptions: {
                  label: 'Joint Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Tenants in Common',
                        value: 'TenantsInCommon',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Rights of Survivorship',
                              value: 'RightsOfSurvivorship',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Community Property',
                              value: 'CommunityProperty',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     }, {
                              name: 'Tenants by Entirety',
                              value: 'TenantsByEntirety',
                              buttonTxt: 'Select',
                              bootstrapSize: 'col-md-3'
                     },
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.accountSubtype = newValue
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'iraType',
               type: 'toggleRadioBtns',
               hideExpression: function($viewValue, $modelValue, scope) {
                  return scope.model.accountType != 'ira' || vm.model1.custodian != 'apex';
               },
               templateOptions: {
                  label: 'IRA Account Options',
                  required: true,
                  type: 'secondaryOptions',
                  options: [
                     {
                        name: 'Simple IRA',
                        value: 'Simple',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'Roth IRA',
                        value: 'Roth',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }, {
                        name: 'SEP Rollover IRA',
                        value: 'SepRollover',
                        buttonTxt: 'Select',
                        bootstrapSize: 'col-md-4'
                     }
                  ]
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue) scope.model.accountSubtype = newValue
                     setRedirectURL(scope);
                  }
               }
            },
            {
               key: 'IB_RedirectURL',
               type: 'input',
               templateOptions: {
                  //required: true
                  type: 'hidden',
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     console.log(newValue, scope);
                  }
               }
            }
         ],
         step2TDA: [
            {
               key: 'portfolioMarginActive',
               className: 'col-md-12',
               type: 'radio',
               templateOptions: {
                  label: 'This type of margin allows for more leverage than traditional Regulation T Margin. However, if you elect this option, your account will need to be funded with at least $125,000. Certain models available on HedgeCoVest may require or may be better replicated using portfolio margin. Please also be aware that utilizing more leverage may increase risk. Selecting Portfolio Margin for your HedgeCoVest account does not necessarily mean you will utilize the extra leverage available to you.',
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue != '' && typeof newValue != 'undefined') {
                        console.log(newValue);
                     }
                  }
               }
            },
            {
               key: 'portfolioMarginActive',
               className: 'col-md-12 mv-50',
               type: 'radio',
               templateOptions: {
                  label: 'This type of margin allows for more leverage than traditional Regulation T Margin. However, if you elect this option, your account will need to be funded with at least $125,000. Certain models available on HedgeCoVest may require or may be better replicated using portfolio margin. Please also be aware that utilizing more leverage may increase risk. Selecting Portfolio Margin for your HedgeCoVest account does not necessarily mean you will utilize the extra leverage available to you.',
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               hideExpression: function($viewValue, $modelValue, scope) {
                  var custodian = null;


                 var keys = Object.keys(scope.theFormlyForm.$$parentForm);
                 keys = keys.filter(function(currentValue, index, arr){
                   return currentValue.indexOf('formly_') != -1;
                 });

                 var keyId = keys[0];
                 var formly1 = scope.theFormlyForm.$$parentForm[keyId];
                 var toogleKey = keyId + '_toggleRadioBtns_custodian_0';


                  if(typeof formly1 != 'undefined' && typeof formly1[toogleKey] != 'undefined'){
                     custodian = formly1[toogleKey].$modelValue;
                  }

                  return (scope.model.accountType == 'ira' || custodian == 'tdameritrade' || vm.model1.custodian == 'apex');
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     setRedirectURL(scope);
                  }
               }
            },
         ],
         step3: [
            {
               "className": "row",
               "fieldGroup": [
                  {
                     "className": "section-label col-xs-12",
                     "template": "<hr /><div><strong>Signee 1:</strong></div>"
			         },
                  {
                     key: 'firstName',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'First Name',
                        type: 'input',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'middleName',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Middle Name',
                        type: 'input',
                        placeholder: ''
                     }
			         },
                  {
                     key: 'lastName',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Last Name',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'email',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Email Address',
                        required: true
                     }
			         },
                  {
                     key: 'email_confirmation',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     optionsTypes: ["matchField"],
                     model: vm.confirmationModel,
                     templateOptions: {
                        label: 'Confirm Email Address',
                        type: 'email',
                        placeholder: 'Please re-enter your email',
                        required: true
                     },
                     data: {
                        fieldToMatch: "email",
                        modelToMatch: vm.model3,
                        matchFieldMessage: "$viewValue + \" does not match \" + options.data.modelToMatch.email"
                     }
			         },
                  {
                     key: 'addressCountry',
                     className: 'col-md-12 col-xs-12',
                     type: 'select',
                     templateOptions: {
                        label: 'Country',
                        required: true,
                        options: ProfileService.countries,
                        valueProp: "name",
                        labelProp: "name"
                     },
			         },
                  {
                     key: 'addressStreet1',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Address 1',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'addressStreet2',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Address 2',
                        placeholder: ''
                     },
			         },
                  {
                     key: 'addressCity',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'City',
                        placeholder: '',
                        required: true
                     },
			         },
                  {
                     key: 'addressState',
                     type: 'select',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'State',
                        required: false,
                        options: ProfileService.USStates
                     },
                     expressionProperties: {
                        'templateOptions.required': 'model.addressCountry === "United States of America"'
                     }
			         },
                  {
                     key: 'addressZip',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Zip',
                        placeholder: '',
                        required: true
                     },
			         },
                  {
                     key: 'phone',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Phone',
                        type: 'tel',
                        placeholder: '',
                        required: true
                     }
         			},
         		]
            },
            {
               "className": "row",
               hideExpression: function ($viewValue, $modelValue, scope) {
                  return vm.model2IB.accountType != 'joint' && vm.model2IB.accountType != 'custodian';
               },
               "fieldGroup": [
                  {
                     "className": "section-label col-xs-12",
                     "template": "<hr /><div><strong>Signee 2:</strong></div>"
			         },
                  {
                     key: 'firstName2',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'First Name',
                        type: 'input',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'middleName2',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Middle Name',
                        type: 'input',
                        placeholder: ''
                     }
			         },
                  {
                     key: 'lastName2',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Last Name',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'email2',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Email Address',
                        required: true
                     }
			         },
                  {
                     key: 'email_confirmation2',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     optionsTypes: ["matchField"],
                     model: vm.confirmationModel,
                     templateOptions: {
                        label: 'Confirm Email Address',
                        type: 'email',
                        placeholder: 'Please re-enter your email',
                        required: true
                     },
                     data: {
                        fieldToMatch: "email2",
                        modelToMatch: vm.model3,
                        matchFieldMessage: "$viewValue + \" does not match \" + options.data.modelToMatch.email"
                     }
			         },
                  {
                     key: 'addressCountry2',
                     className: 'col-md-12 col-xs-12',
                     type: 'select',
                     templateOptions: {
                        label: 'Country',
                        required: true,
                        options: ProfileService.countries,
                        valueProp: "name",
                        labelProp: "name"
                     }
			         },
                  {
                     key: 'addressStreet12',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Address 1',
                        placeholder: '',
                        required: true
                     }
			         },
                  {
                     key: 'addressStreet22',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Address 2',
                        placeholder: ''
                     },
			         },
                  {
                     key: 'addressCity2',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'City',
                        placeholder: '',
                        required: true
                     },
			         },
                  {
                     key: 'addressState2',
                     type: 'select',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'State',
                        required: true,
                        options: ProfileService.USStates
                     },
                     expressionProperties: {
                        'templateOptions.required': 'model.addressCountry2 === "United States of America"'
                     }
			         },
                  {
                     key: 'addressZip2',
                     type: 'input',
                     className: 'col-md-4 col-xs-12',
                     templateOptions: {
                        label: 'Zip',
                        placeholder: '',
                        required: true
                     },
			         },
                  {
                     key: 'phone2',
                     type: 'input',
                     className: 'col-md-12 col-xs-12',
                     templateOptions: {
                        label: 'Phone',
                        type: 'tel',
                        placeholder: '',
                        required: true
                     }
			         },
               ]
            },
            {
               className: 'section-label col-xs-12',
               template: "<hr class='management-fees-hr' /><h3><strong>Management Fees:</strong></h3>"
            },
            {
               key: 'hasManagementFee',
               type: 'radio',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: "Do you want to automatically bill your advisory fees to your client's account?",
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               }
            },
            {
               key: 'managementFeePeriod',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: "Monthly",
               templateOptions: {
                  label: 'Payment Period',
                  required: true,
                  options: Brokerage.feePeriodOpts
               },
               hideExpression: "!model.hasManagementFee",
               expressionProperties: {
                  "templateOptions.disabled": "true"
               }
            },
            {
               key: 'managementFeeType',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: "Arrears",
               templateOptions: {
                  label: 'Fee Type',
                  required: true,
                  options: Brokerage.feeTypeOpts
               },
               hideExpression: "!model.hasManagementFee",
               expressionProperties: {
                  "templateOptions.disabled": "true"
               }
            },
            {
               className: 'section-label col-md-3 col-xs-12 text-center',
               template: "<a href ng-click='toggleTieredFees()' classs='col-xs-12 text-center' style='line-height: 80px;'>{{model.hasTieredFeeSchedule ? 'Remove' : 'Add'}} Tiered Fee Schedule</a>",
               controller: /*@ngInject*/ function ($scope) {
                  $scope.toggleTieredFees = function () {

                     console.log($scope);
                     console.log($scope.model.hasTieredFeeSchedule);
                     $scope.model.hasTieredFeeSchedule = (typeof $scope.model.hasTieredFeeSchedule == 'undefined') ? true : !$scope.model.hasTieredFeeSchedule;
                     $scope.model.managementFee = null;
                  }
               },
               hideExpression: "!model.hasManagementFee"
            },
            {
               key: 'hasTieredFeeSchedule',
               type: 'select',
               className: 'col-md-3 col-xs-12',
               defaultValue: false,
               templateOptions: {
                  label: 'Add Tiered Fee Schedule',
                  required: true,
                  options: Brokerage.yesNoOpts
               },
               hideExpression: "true"
            },
            {
               key: 'managementFee',
               type: 'input',
               className: 'col-md-3 col-xs-12 percentage-val',
               templateOptions: {
                  label: 'Management Fee',
                  placeholder: '2.5',
                  required: true
               },
               hideExpression: "!model.hasManagementFee || model.hasTieredFeeSchedule",
               validators: {
                  float: {
                     expression: function (viewValue, modelValue) {
                        var value = modelValue || viewValue;

                        var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
                        if (!floatRegex.test(value))
                           return false;

                        val = parseFloat(value);

                        if (val >= 100 || val <= 0)
                           return false

                        return !isNaN(val);
                     },
                     message: '$viewValue + " is not a valid percentage value between 0-100."'
                  }
               }
            },
            {
               key: 'managementFeeTable',
               type: 'account_fees_table',
               className: 'col-xs-12',
               model: vm.model3.managementFeeTable,
               hideExpression: "!model.hasManagementFee || !model.hasTieredFeeSchedule",
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {
                     if (newValue != '' && typeof newValue != 'undefined') {
                     }
                  }
               }
            },
            {
               key: 'isFeesCumlative',
               type: 'select',
               className: 'col-md-6 col-xs-12',
               templateOptions: {
                  label: "Would you like to use blended management fees?",

                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               },
               hideExpression: "!model.hasManagementFee || !model.hasTieredFeeSchedule",
            },
            {
               key: 'feeTableRecap',
               type: 'account_fees_table_recap',
               className: 'col-md-6 col-xs-12',
               hideExpression: "true || !model.hasManagementFee || !model.hasTieredFeeSchedule",
            },
            {
               className: 'col-md-6 col-xs-12',
               type: 'what_are_blended_rates',
               hideExpression: "true || !model.hasManagementFee || !model.hasTieredFeeSchedule"
            }
         ],
         step3b: [
            {
               key: 'company',
               type: 'input',
               className: 'col-xs-12',
               templateOptions: {
                  label: 'Account Title',
                  type: 'input',
                  placeholder: '',
                  required: true
               }
            },
            {
               key: 'firstName',
               type: 'input',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Signer\'s First Name',
                  type: 'input',
                  placeholder: '',
                  required: true
               }
            },
            {
               key: 'middleName',
               type: 'input',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Signer\'s Middle Name',
                  type: 'input',
                  placeholder: ''
               }
            },
            {
               key: 'lastName',
               type: 'input',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Signer\'s Last Name',
                  placeholder: '',
                  required: true
               }
            },
            {
               key: 'email',
               type: 'input',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: 'Signer\'s Email Address',
                  required: true
               }
            },
            {
               key: 'email_confirmation',
               type: 'input',
               className: 'col-md-12 col-xs-12',
               optionsTypes: ["matchField"],
               model: vm.confirmationModel,
               templateOptions: {
                  label: 'Confirm Email Address',
                  type: 'email',
                  placeholder: 'Please re-enter your email',
                  required: true
               },
               data: {
                  fieldToMatch: "email",
                  modelToMatch: vm.model3,
                  matchFieldMessage: "$viewValue + \" does not match \" + options.data.modelToMatch.email"
               }
            },
            {
               key: 'addressCountry',
               className: 'col-md-12 col-xs-12',
               type: 'select',
               templateOptions: {
                  label: 'Country',
                  required: true,
                  options: ProfileService.countries,
                  labelProp: 'name',
                  valueProp: 'name',
               },
               watcher: {
                  listener: function (field, newValue, oldValue, scope, stopWatching) {}
               }
            },
            {
               key: 'addressStreet1',
               type: 'input',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: 'Address 1',
                  placeholder: '',
                  required: true
               }
            },
            {
               key: 'addressStreet2',
               type: 'input',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: 'Address 2',
                  placeholder: ''
               },
            },
            {
               key: 'addressCity',
               type: 'input',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'City',
                  placeholder: '',
                  required: true
               },
            },
            {
               key: 'addressState',
               type: 'select',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'State',
                  required: false,
                  options: ProfileService.USStates
               },
               expressionProperties: {
                  'templateOptions.required': 'model.addressCountry === "United States of America"'
               }
            },
            {
               key: 'addressZip',
               type: 'input',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Zip',
                  placeholder: '',
                  required: true
               },
            },
            {
               key: 'phone',
               type: 'input',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: 'Phone',
                  type: 'tel',
                  placeholder: '',
                  required: true
               }
            },
            {
               key: 'hasManagementFee',
               type: 'radio',
               className: 'col-md-12 col-xs-12',
               templateOptions: {
                  label: "Do you want to automatically bill your advisory fees to your client's account?",
                  options: Brokerage.yesNoOpts,
                  required: true,
                  validation: {
                     show: true
                  }
               }
            },
            {
               key: 'managementFee',
               type: 'input',
               className: 'col-md-4 col-xs-12 percentage-val',
               templateOptions: {
                  label: 'Management Fee',
                  placeholder: '2.5',
                  required: true
               },
               hideExpression: "!model.hasManagementFee",
               validators: {
                  float: {
                     expression: function (viewValue, modelValue) {
                        var value = modelValue || viewValue;

                        var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
                        if (!floatRegex.test(value))
                           return false;

                        val = parseFloat(value);

                        if (val >= 100 || val <= 0)
                           return false

                        return !isNaN(val);
                     },
                     message: '$viewValue + " is not a valid percentage value between 0-100."'
                  }
               }
            },
            {
               key: 'managementFeePeriod',
               type: 'select',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Payment Period',
                  required: true,
                  options: Brokerage.feePeriodOpts
               },
               hideExpression: "!model.hasManagementFee"
            },
            {
               key: 'managementFeeType',
               type: 'select',
               className: 'col-md-4 col-xs-12',
               templateOptions: {
                  label: 'Fee Type',
                  required: true,
                  options: Brokerage.feeTypeOpts
               },
               hideExpression: "true"
            }
         ]
      };

      function setRedirectURL(scope) {


         if (vm.model1.custodian == 'interactivebrokers' && scope.model.accountType != '' && typeof scope.model.portfolioMarginActive != 'undefined') {

            // add url for any non trust account
            if (!scope.model.accountType.match(/trust/)) {
               for (var i = scope.fields[0].templateOptions.options.length - 1; i >= 0; i--) {
                  var option = scope.fields[0].templateOptions.options[i];

                  if (option.value == scope.model.accountType && scope.model.accountType != 'fund') {
                     scope.model.IB_RedirectURL = (scope.model.portfolioMarginActive) ? option.urls.pm : option.urls.regT;
                     return;
                  } else if (option.value == scope.model.accountType && scope.model.accountType == 'fund') {
                     scope.model.IB_RedirectURL = option.urls.all;
                     return
                  }
               }
            } else {
               for (var i = scope.fields[0].templateOptions.options.length - 1; i >= 0; i--) {
                  var option = scope.fields[0].templateOptions.options[i];
                  if (option.value == scope.model.accountType && typeof scope.model.trustType != 'undefined') {

                     if (scope.model.portfolioMarginActive) {
                        switch (scope.model.trustType.toLowerCase()) {
                        case 'irrevocable':
                           scope.model.IB_RedirectURL = option.urls.regT_Irrevocable;
                           break;
                        case 'revocable':
                           scope.model.IB_RedirectURL = option.urls.regT_Revocable;
                           break;
                        case 'testamentary':
                           scope.model.IB_RedirectURL = option.urls.regT_Testamentary;
                           break;
                        case 'other':
                           scope.model.IB_RedirectURL = option.urls.regT_Other;
                           break;
                        default:
                           scope.model.IB_RedirectURL = undefined;
                           debugger
                        }
                     } else {
                        switch (scope.model.trustType.toLowerCase()) {
                        case 'irrevocable':
                           scope.model.IB_RedirectURL = option.urls.pm_Irrevocable;
                           break;
                        case 'revocable':
                           scope.model.IB_RedirectURL = option.urls.pm_Revocable;
                           break;
                        case 'testamentary':
                           scope.model.IB_RedirectURL = option.urls.pm_Testamentary;
                           break;
                        case 'other':
                           scope.model.IB_RedirectURL = option.urls.pm_Other;
                           break;
                        default:
                           scope.model.IB_RedirectURL = undefined;
                           debugger
                        }
                     }
                  }
               }
            }
         }
      }

      // function definition
      function finishWizard(email) {
         SweetAlert.swal({
               title: "Brokerage Account",
               text: "Creating account for " + email + " in background.",
            },
            function () {

               // TODO: clean up and make it so that adding a new main tab still deletes the profile tab
               if (typeof lastDashTab == 'undefined' || Tabs.getTabs().indexOf(lastDashTab) === -1) {

                  lastDashTab = undefined;
                  Tabs.getTabs().forEach(function (tab) {
                     if (tab.type === 'accountsSummary') {
                        lastDashTab = tab;
                     }
                  });

                  if (typeof lastDashTab == 'undefined') {
                     Tabs.addTab('accountsSummary');
                  }

               }

               var formPayload = angular.merge({}, vm.model1, vm.model2IB, vm.model2TDA, vm.model3);
               var platform = Dashboard.getPlatform();

               formPayload.platform = platform;

               if (formPayload.accountSubtype){
                  formPayload.accountType = formPayload.accountType + formPayload.accountSubtype;
               }

               console.log(formPayload);
               
               AddAccountService.registerNewAccount(formPayload)
                  .then(function (payload) {

                     console.log(payload);
                     debugger
                     $rootScope.$broadcast('refresh-accounts-summary');
                  });

               var activeTab = $scope.$parent.tab;
               var tabs = Tabs.getTabs();
               var tabIndex = tabs.indexOf(activeTab);
               vm.removeTab(tabIndex, lastDashTab);
            });
      }

      var switchToLastDash = function (lastDashTab, index) {

         // create a closure so that lastDashTab is accessible in the context of the 'then' method
         return function () {

            // return a promise in case we want to keep chaining
            var promise = $q(function (resolve, reject) {

               if (lastDashTab) {
                  resolve(Tabs.setActiveTab(lastDashTab));
               } else {
                  reject("Couldn't switch to last dashboard tab");
               }
            });

            return promise;
         };
      };

      vm.reloadData = function() {

         vm.flatCalculation = Brokerage.getFlatCalculation();
         vm.feeTableCalculation = Brokerage.getFeeTableCalculation();
      }


      vm.feeTableData = defaultTierPayload = [{
               feeAmount: null,
               startingLimit: 0,
               endingLimit: null
		}, {
               feeAmount: null,
               startingLimit: null,
               endingLimit: null
		}];

      vm.feesTableErrorMsg;
      vm.feeTableCalculation = '';
      vm.isCompany = false;
      vm.showTieredFees = false;
      vm.feeTableBlendedTotal = null;
      vm.feeTableFlatTotal = null;



      vm.addNewFeeRow = function (row) {
         //		debugger;

         var endingLimit = (typeof row.endingLimit == 'undefined') ? 0 : parseFloat(row.endingLimit) + 0.01;

         console.log(vm.feeTableData);
         //		debugger
         if ((typeof row.feeAmount == 'undefined' || typeof row.endingLimit == 'undefined' || typeof row.startingLimit == 'undefined') || (row.feeAmount == null || row.endingLimit == null || row.startingLimit == null)) {
            vm.feesTableErrorMsg = "Incomplete row information."
            return;
         } else {
            vm.feesTableErrorMsg = undefined;
         }

         vm.feeTableData.push({
            feeAmount: null,
            startingLimit: endingLimit,
            endingLimit: null
         });
      }

      vm.removeFeeRow = function (index) {
         //		debugger;
         vm.feeTableData.splice(index, 1);
         calculateRates();
      }

      function calculateRates() {
         vm.feeTableCalculation = '';
         var blendedTotal = 0;

         for (var i = 0; i < vm.feeTableData.length; i++) {

            var row = vm.feeTableData[i];
            var fee = parseFloat(row.feeAmount) / 100;
            var startingLimit = (isNaN(row.startingLimit)) ? Number(row.startingLimit.replace(/[^0-9\.]+/g, "")) : row.startingLimit;
            var endingLimit = (isNaN(row.endingLimit)) ? Number(row.endingLimit.replace(/[^0-9\.]+/g, "")) : row.endingLimit;

            row.blendedCalculation = '';
            if(isRowValid(row)){
               console.log( row );

            }else if(i > 0 && row.feeAmount && row.startingLimit){
//                           debugger;
               if(i == vm.feeTableData.length-1){
                  var lastRow = vm.feeTableData[i-1];
                  var lastRowEndingLimit = (isNaN(lastRow.endingLimit)) ? Number(lastRow.endingLimit.replace(/[^0-9\.]+/g, "")) : lastRow.endingLimit;
                  endingLimit = startingLimit + ((lastRowEndingLimit - lastRow.startingLimit) / 2);
               }
            }

            var _fee = fee * 100;

            var _total = ((endingLimit-startingLimit) * fee);
                blendedTotal += _total;

            if(i == 0){
               row.blendedCalculation += '<table class="col-xs-12">';
            }
            row.blendedCalculation += '<tr>';
            row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-left">($' + abbreviateNumber(endingLimit, 0) + ' - ' + abbreviateNumber(startingLimit, 0) + ')' + '</td>';
            row.blendedCalculation += '<td>x</td>';
            row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('number')(_fee, 3) + '%</td>';
            row.blendedCalculation += '<td>=</td>';
            row.blendedCalculation += '<td class="no-margin-lr no-padding-lr text-right">' + $filter('currency')(_total) + '</td>';
            row.blendedCalculation += '</tr>';

            if(i<vm.feeTableData.length - 1){ row.blendedCalculation += '<br/>'; }
            else if(i==vm.feeTableData.length -1){
               row.blendedCalculation += '</table><br/><br/><table class="col-xs-12">';

               row.blendedCalculation += '<tr><td class="text-right col-xs-6">Total Fees:</td><td class="text-right">' + $filter('currency')(blendedTotal) + '</td></tr>';
               row.blendedCalculation += '<tr><td class="text-right col-xs-6">Total Assets:</td><td class="text-left"><span class="operator">÷</span><span class="pull-right">' + abbreviateNumber(Number(endingLimit), 0) + '</span></td></tr>';
               row.blendedCalculation += '<tr class="no-padding"><td colspan="2" class="text-right no-padding">================ </td></tr>';
               row.blendedCalculation += '<tr><td class="text-right col-xs-6">Blended Rate:</td><td class="text-right">' + $filter('number')((Number(blendedTotal) / Number(endingLimit) * 100), 3) + '%</td></tr>';

               row.blendedCalculation += '</table>';
            }

            vm.feeTableBlendedAmount = Number(endingLimit);
            vm.feeTableCalculation += row.blendedCalculation;
            Brokerage.setFeeTableCalculation(vm.feeTableCalculation);


            vm.flatCalculation = '<table class="col-xs-12"><tr class="text-right">';
            vm.flatCalculation += '<td class="col-xs-3 text-right">' + abbreviateNumber(endingLimit, 0) + '</td>';
            vm.flatCalculation += '<td class="text-right col-xs-1">x</td>';
            vm.flatCalculation += '<td class="text-right col-xs-3">' + $filter('number')(_fee, 3) + '%</td>';
            vm.flatCalculation += '<td class="text-right col-xs-1">=</td>';
            vm.flatCalculation += '<td class="text-right col-xs-4">' + $filter('currency')((endingLimit * fee)) + '</td>';
            vm.flatCalculation += '</tr></table>';

            Brokerage.setFlatCalculation(vm.flatCalculation);
         }
      }

      function isRowValid(row) {

         if( row.feeAmount != null && row.startingLimit != null && row.endingLimit != null ){
            var startingVal = (isNaN(row.startingLimit)) ? Number(row.startingLimit.replace(/[^0-9\.]+/g, "")) : row.startingLimit;
            var endingVal = (isNaN(row.endingLimit)) ? Number(row.endingLimit.replace(/[^0-9\.]+/g, "")) : row.endingLimit;

            if( row.feeAmount != '' && startingVal >= 0 && endingVal >= 0 ){ return true; }
         }
         return false;
      }

      vm.validateRow = function (row, index, model) {
         calculateRates();

         vm.feesTableErrorMsg = undefined;
         row.amountError = false;
         row.feeError = false;

         vm.feeTableFlatRate = undefined;
         var startingLimit = parseFloat(row.startingLimit);
         var endingLimit = parseFloat(row.endingLimit);
         var feeAmount = parseFloat(row.feeAmount);

         if (startingLimit > 0 && endingLimit > 0) {
            if (startingLimit >= endingLimit) {
               vm.feesTableErrorMsg = "Ending Limit must be greater than the Starting limit.";
               row.amountError = true;
            }

         }

         if (hasValidFeeAmount(feeAmount)) {
            vm.feesTableErrorMsg = "Enter a fee amount between 0.01% - 99.99%.";
            row.feeError = true;
         }


         if (typeof vm.feeTableData[index + 1] != 'undefined' && vm.feeTableData[index].endingLimit != null) {

            vm.feeTableData[index + 1].startingLimit = (typeof vm.feeTableData[index].endingLimit == 'undefined') ? 0 : Number(vm.feeTableData[index].endingLimit.replace(/[^0-9\.]+/g, "")) + 0.01;

         } else {
            if (vm.feeTableData[index].endingLimit != null && vm.feeTableData[index].startingLimit != null && vm.feeTableData[index].feeAmount != null) {

               console.log(vm.feeTableData[index]);
               vm.addNewFeeRow(vm.feeTableData[index]);

            }
         }

         if(typeof model != 'undefined'){
            model.tieredSchedule = vm.feeTableData;
         }
      }

      vm.toggleTieredFees = function () {
         vm.showTieredFees = !vm.showTieredFees;
      }


      function abbreviateNumber(number, fractionSize) {

         if (number === null) return null;
         if (number === 0) return "0";

         if (!fractionSize || fractionSize < 0)
            fractionSize = 1;

         var abs = Math.abs(number);
         var rounder = Math.pow(10, fractionSize);
         var isNegative = number < 0;
         var key = '';
         var powers = [
            {
               key: "Q",
               value: Math.pow(10, 15)
                            },
            {
               key: "T",
               value: Math.pow(10, 12)
                            },
            {
               key: "B",
               value: Math.pow(10, 9)
                            },
            {
               key: "M",
               value: Math.pow(10, 6)
                            },
            {
               key: "K",
               value: 1000
                            }
         ];

         for (var i = 0; i < powers.length; i++) {

            var reduced = abs / powers[i].value;

            reduced = Math.round(reduced * rounder) / rounder;

            if (reduced >= 1) {
               abs = reduced;
               key = powers[i].key;
               break;
            }
         }

         return (isNegative ? '-' : '') + abs + key;
      }


      function hasValidFeeAmount(feeAmount) {
         return (isNaN(feeAmount) || feeAmount == null || feeAmount >= 100 || feeAmount < 0.001);
      }
   })
   .directive('format', ['$filter', function ($filter) {
      return {
         require: '?ngModel',
         link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;

            ctrl.$formatters.unshift(function (a) {
               return $filter(attrs.format)(ctrl.$modelValue)
            });

            elem.bind('blur', function (event) {

               var plainNumber = Math.abs(elem.val().replace(/[^\d|\-+|\.+]/g, ''));
               if (plainNumber > 0) {
                  if (attrs.format == 'currency') {
                     elem.val($filter(attrs.format)(plainNumber, ''));
                  } else {
                     elem.val($filter(attrs.format)(plainNumber));
                  }
               }
            });
         }
      };
}]);