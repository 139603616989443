import { WebStorageStateStore, UserManager, Log } from 'oidc-client';
angular.module('Identity', [])
.factory('IdentityFactory', function($http, $location, $rootScope, IDS_URL){

  var activeUser;
  var logoutRedirect;

  var prodDomains = [
    'smartx.managedaccounts.io',
    'smartx.smartxadvisory.com',
    'live-hedgecovest.pantheonsite.io',
    'portal.managedaccounts.io',
    'test-hedgecovest.pantheonsite.io',
    'advisoryplatform.americanportfolios.com',
    'beta.smartxadvisory.com'
  ];
  var isProd = prodDomains.indexOf(window.location.host) > -1;

  var config = {
    // authority: 'http://localhost:5000/',
    // authority: /* window.location.host.indexOf('localhost') > -1 ? "http://localhost:5000" : */ "https://identity-uat.smartxadvisory.com/", // to access the locally running .NET application
    // authority: "http://192.168.3.131:5000",
    authority: IDS_URL, // isProd ? 'https://identity.smartxadvisory.com/' : 'https://ids.svc.qa.smartx.us/',
    client_id: "smartx-clientapi-webclient",
    redirect_uri: window.location.origin + "/identity/callback.html",
    response_type: "id_token token",
    scope: "offline_access openid email profile smartx_profile roles",
    post_logout_redirect_uri : window.location.origin + "/login",
    revokeAccessTokenOnSignout: true,

    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + "/identity/silent-renew.html",

    accessTokenExpiringNotificationTime: 60,
    silentRequestTimeout: 10000,

    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    checkSessionInterval: 2000,
    
    userStore: new WebStorageStateStore({ store: window.localStorage })
  };

  console.log("Oidc config: ", config);

  var idClient = new UserManager(config);

  console.log(idClient);

  // enable logging from oidc-client
  Log.logger = console;

  idClient.events.addAccessTokenExpiring(function(){
    console.log("token expiring...");
  });

  // token needs to be reset when silent renew completes
  idClient.events.addUserLoaded(function(user){
    console.log("User session was updated...");
    SmartX.Router.setToken(user.access_token);

    // listen for this event in a controller as a way to access the updated token
    // useful for keeping the token fresh inside of custom elements that need to make API calls (token can be passed in as a prop)
    $rootScope.$broadcast('smx-token-updated', user.access_token);

    // alternative that could be used directly by custom elements if we don't want to depend on an angular controller
    // can be listened to with the vanillaJS addEventListener
    // var event = new CustomEvent("smx-token-updated", { bubbles: true, detail: vm.token + Math.random() });
    // document.dispatchEvent(event);
  });
  
  function getUser () {
    return idClient.getUser();
  }

  function getActiveUser () {
    return activeUser;
  }

  function getPasswordLink () {
    return config.authority + 'Manage/ChangePassword';
  }

  function getProfileLink () {
    return config.authority + 'Manage'; // ?ReturnUrl=' + encodeURIComponent(window.location.origin + '/v3/dashboard');
  }

  function setActiveUser (user) {
    console.log("Setting authenticated user", user);
    activeUser = user;
  }

  function getProfile () {
    return $http.get(config.authority + 'connect/userinfo', {
      headers: {
        Authorization: 'Bearer ' + SmartX.Router.getToken()
      }
    });
  }

  function updateProfile (profile) {

  }

  function login (params) {
    idClient.signinRedirect(params);
  }

  function logout (params) {
    if (logoutRedirect) {
      var logout = { post_logout_redirect_uri: logoutRedirect.trim() };
      params = params ? _.extend(params, logout) : logout;
    }

    var urlParams = $location.search();
    var jumpto = urlParams.jumpto;
    var extraQuery;

    if (jumpto === 'account'){
      var jumptoAccount;
      if (urlParams.accountId) {
        jumptoAccount = urlParams.accountId;
        window.localStorage.setItem('smxJumptoAccountId', jumptoAccount);
      } else if (urlParams.accountNumber) {
        jumptoAccount = urlParams.accountNumber;
        window.localStorage.setItem('smxJumptoAccountNumber', jumptoAccount);

      }

      extraQuery = 'jumpto=account';
    }

    if (extraQuery){

      // figure out if we need the jumpto params to be prefixed with a ? or &
      // if the logout url already has some params, use &jumpto
      // if it doesn't use ?jumpto
      if (logoutRedirect) {
        var hasQuery = params.post_logout_redirect_uri.indexOf('?') > -1;
        if (hasQuery) {
          params.post_logout_redirect_uri += ('&' + extraQuery);
        } else {
          params.post_logout_redirect_uri += ('?' + extraQuery);
        }
      } else {
        params = {
          post_logout_redirect_uri:  config.post_logout_redirect_uri += ('?' + extraQuery)
        };
      }
    }



    idClient.signoutRedirect(params);
  }

  function setLogoutRedirect (url) {
    logoutRedirect = url;
  }

	return {
    getUser: getUser,
    getProfileLink: getProfileLink,
    setActiveUser: setActiveUser,
    getActiveUser: getActiveUser,
    getProfile: getProfile,
    updateProfile: updateProfile,
    login: login,
    logout: logout,
    setLogoutRedirect: setLogoutRedirect,
    getPasswordLink: getPasswordLink
	};
});