angular.module('Amplitude', [])
.service('Amplitude', function(){

	function logEvent (event, eventProperties) {

        if (angular.isDefined(amplitude)) {

            try {
                amplitude.getInstance().logEvent(event, eventProperties);
            } catch (e) {
                console.error(e);
            }
        } else {
            console.log("Amplitude is not available");
            return false;
        }
    }

	return {
        logEvent: logEvent
    };	
});