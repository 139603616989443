angular.module("templates", []).run(["$templateCache", function($templateCache) {
  function requireAll(requireContext) {
    
    return requireContext.keys().map(function(val){
        return {
            // tpl will hold the value of your html string because thanks to wepbpack "raw-loader" **important**
            tpl:requireContext(val),

            //name is just the filename
            name : val.split('/').pop()
        };
    });
  } 

  let templates = requireAll(require.context('./../templates', true, /\.html$/));
  templates.forEach(function (template) {
    $templateCache.put(template.name, template.tpl);
  });

}]);