angular.module('SecurityAdministrator')
.controller('SecurityAdminMoveUserCtrl', securityAdminMoveUserCtrl);

function securityAdminMoveUserCtrl (userApi, selectedUsers, EnterpriseFactory, toastr, $q, $timeout, $uibModalInstance){
  var vm = this;

  vm.selectedUsers = selectedUsers;
  vm.loadingEnterprises = false;
  vm.updating = false;
  vm.onEnterpriseSelected = onEnterpriseSelected;
  vm.percentComplete = 0;
  vm.processed = 0;
  vm.max = 100;
  vm.submit = submit;
  vm.close = close;
  vm.cancel = cancel;

  init();

  function init() {
    vm.loadingEnterprises = true;
    EnterpriseFactory.loadEnterpriseLookup()
      .then(function(enterprises){
        vm.enterprisesList = enterprises.map(function(e){
          return {
            id: e.id,
            name: e.name
          };
        });
      })
      .catch(function(err){
        console.error(err);
        toastr.error(err.message);
      })
      .finally(function(){
        vm.loadingEnterprises = false;
      });
  }

  function onEnterpriseSelected (item) {
    vm.selectedEnterprise = item;
  }

  function close () {
    $uibModalInstance.close('complete');
  }

  function cancel () {
    $uibModalInstance.close('dismiss');
  }

  function submit () {
    
    vm.errors = [];
    vm.noErrors = true;

    vm.percentComplete = 0;
    vm.succeeded = 0;
    vm.processed = 0;

    vm.completed = false;
    vm.updating = false;

    if (angular.isDefined(vm.selectedEnterprise) && !_.isEmpty(vm.selectedEnterprise)) {

      vm.updating = true; 

      selectedUsers.forEach(function(user, index){
        var username = user.userName;
        var payload = {
          enterpriseId: vm.selectedEnterprise.id
        };
        
        // for testing 
        // var req = (index + 1) % 2 ? $q.reject : $q.resolve;
        // req()
        
        $q.when(userApi.update(user.id, payload))
        .then(function(res){
          console.log("update result: ", res);
          // throw new Error('test error');
          vm.succeeded++;
        })
        .catch(function(err){
          console.error(err);
          // $timeout(function() {
            vm.noErrors = false;
            vm.errors.push({
              username: username,
              status: "failed",
              message: err ? err.message : '' // 'Error from item ' + index
            });
          // }, 3000 * index);

        })
        .finally(function(){
          // $timeout(function() {
            vm.processed++;
            vm.percentComplete = (vm.processed / vm.selectedUsers.length) * 100;
            vm.completed = vm.processed === vm.selectedUsers.length;
            if (vm.completed) {
              vm.updating = false;
              vm.completeWithoutErrors = vm.noErrors;
            }
          // }, 3000 * index);
        });
      });
    }
  }

}