// angular.module('sanitizeSmartXAccountName', [])
angular.module('Filters')
.filter('sanitizeSmartXAccountName', ['$filter', function ($filter) {
  return function (str, type) {

    var whitelistedUsers = ['alex@hedgecovest.com'];
    var whitelistedAccounts = ['MATTHEW KEMP', '925364460', 'Sunshine 511 Holdings', 'U2096529', 'JOHN MCGEHEE IRA', '925364465'];
    // JOHN MCGEHEE IRA 925364465
    // Sunshine 511 Holdings U2096529

    if (whitelistedUsers.indexOf(Drupal.settings.username) == -1) {
      return str;
    }

    if (type === 'name') {
      str = whitelistedAccounts.indexOf(str) == -1 ? 'Demo Account' : str;
    } else if (type === 'number') {
      str = whitelistedAccounts.indexOf(str) == -1 ? 'FAKE' + Math.floor(100000 + Math.random() * 900000) : str;
    }

    return str;
  };
}]);
