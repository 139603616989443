angular.module('SecurityAdministrator')
.controller('SecurityAdminMoveAccountCtrl', securityAdminMoveAccountCtrl);

function securityAdminMoveAccountCtrl (EnterpriseFactory, $q, SmartXFactory, Dashboard, $uibModalInstance, selectedAccounts, currentPage, toastr) {

  var vm = this;
  var accountApi = SmartXFactory.getAccountAPI();
  
  vm.selectedAccounts = selectedAccounts;
  vm.loading = false;
  vm.saving = false;
  vm.selectedEnterprise = null;

  vm.errors = [];
  vm.submit = submit;
  vm.cancel = cancel;
  vm.reset = reset;
  vm.handleChildSelect = handleChildSelect;

  init();

  function init() {
    vm.loading = true;
    $q.when(EnterpriseFactory.loadEnterpriseLookup())
    .then(function(enterprises){
      vm.enterprisesList = enterprises;
      // if (vm.enterprisesTable){
      //   vm.enterprisesTable.settings().dataset = enterprises;
      //   vm.enterprisesTable.reload();
      // }
    })
    .catch(function(err){
      console.error(err);
      toastr.error(err.message);
    })
    .finally(function(){
      vm.loading = false;
    });
  }

  function handleChildSelect (item) {
    vm.selectedEnterprise = item;
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function reset() {
    var userNameInput = document.getElementById('userName');
    vm.newUser = null;
    vm.success = false;
    vm.newUserForm = {};
    userNameInput.focus();
    userNameInput.scrollIntoView();
  }

  function submit(enterprise) {

    if (_.isEmpty(enterprise)) return false;

    if (vm.complete && !vm.errors.length){
      return $uibModalInstance.close(true);
    }
    
    vm.saving = true;
    vm.complete = false;
    vm.completed = 0;
    vm.errors = [];

    var payload = vm.selectedAccounts.map(function(account){
      return {
        accountId: account.id,
        enterpriseId: enterprise.id
      };
    });

    // debug failure messages
    // payload = payload.concat([{
    //   accountId: '2c0eed95-b1cd-4e7e-b5d2-012592816544',
    //   enterpriseId: '2c0eed95-b1cd-4e7e-b5d2-012592816545'
    // }]);

    $q.when(accountApi.bulkUpdateAccountEnterprise(payload))
    .then(function(res){
      vm.complete = true;
      vm.errors = res.data.filter(function(result){
        return !result.status; 
      })
      .map(function(error){
        error.account = _.find(vm.currentPage, {id: error.id});
        return error;
      });
    })
    .catch(function(err){
      toastr.error(err.message, {
        timeOut: 0,
        extendedTimeOut: 0
      });
    })
    .finally(function(){
      vm.saving = false;
    });

  }
}