angular.module('Directives')
.directive('benchmarkLookup', function() {

  return {
    restrict: 'E',
    templateUrl: 'benchmarkLookup.tpl.html',
    controllerAs: 'vm',
    // bindToController: true,
    scope: {
      onSelect: '=onSelect'
    },
    link: function($scope, iElm, iAttrs, controller) {},
    controller: function($scope, $rootScope) {
      var vm = this;
      console.log($scope.onSelect)

      vm.categories = [{
        value: 'FI',
        text: 'Fixed Income'
      }, {
        value: 'EQUITY',
        text: 'Equity'
      }, {
        value: 'ALTERNATIVE',
        text: 'Alternative'
      }, {
        value: 'MAC',
        text: 'Multi-Asset Class'
      }, {
        value: 'SUSTAINABILITY',
        text: 'Substainability'
      }, {
        value: 'THEMATIC',
        text: 'Thematic'
      }]

      vm.selectedCategory = ''
      vm.selectedSubCategory = ''

      var subCategories = {
        'FI': [{
          value: 'category1',
          text: 'Category 1'
        }],
        'EQUITY': [],
        'ALERTNATIVE': [],
        'MAC': [],
        'THEMATIC': []
      }
      
      var subSubs = {
        'category1': [
          {
            value: 'Asia Cash'
          },{
            value: 'Asia Core'
          }]
      }
      
      vm.setSubCategory = function (sub) {
        vm.selectedSubCategory = sub
      }
      
      vm.setCategory = function(category) {
        vm.selectedCategory = category
      }

      vm.onBenchmarkSelected = function (item) {
        vm.isOpen = false
        vm.selectedBenchmark = item
        
        if ($scope.onSelect) $scope.onSelect(item)
        else $rootScope.$broadcast('benchmarkSelected', item)
      }
      
      $scope.$watch('vm.selectedSubCategory', function(newVal, oldVal) {
        if (newVal) {
          console.log('Switching to ', newVal.value)
          vm.subSubs = subSubs[newVal.value]
          console.log(vm.subSubs)
        }
      })

      $scope.$watch('vm.selectedCategory', function(newVal, oldVal) {
        if (newVal) {
          var category = newVal.value
          vm.subCategories = subCategories[category]
        }
      })
    }
  }
})